import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec, Node, Schema } from 'prosemirror-model'
import {
  liftListItem,
  sinkListItem,
  splitListItem,
} from 'prosemirror-schema-list'
import { schema } from 'prosemirror-schema-basic'
import { addListNodes, wrapInList } from 'prosemirror-schema-list'
import { keymap } from 'prosemirror-keymap'
import { IconTaskList } from '@arco-iconbox/react-aidb-v2'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
interface ITaskListProps {
  name?: string
  TaskList?: string
  done?: boolean
}

const PLUGIN_NAME = 'task_list'

class TaskListSchema extends Inline<ITaskListProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'span'
  constructor(editor: SylApi, props) {
    super(editor, props)
    events.emit(ACTIONS.SAVE_PIC_EDITOR)
  }
  public attrs = {
    done: { default: false },
  }

  public toDOM = (node: Node) => {
    const { done } = node.attrs
    return [
      'div',
      {
        'data-type': 'todo_item',
        'data-done': done.toString(),
      },
      [
        'span',
        {
          class: 'todo-checkbox todo-checkbox-unchecked',
          contenteditable: 'false',
        },
      ],
      [
        'span',
        {
          class: 'todo-checkbox todo-checkbox-checked',
          contenteditable: 'false',
        },
      ],
      ['span', { class: 'todo-content' }, 0],
    ] as DOMOutputSpec
  }

  public parseDOM = [
    {
      priority: 51, // Needs higher priority than other nodes that use a "li" tag
      tag: '[data-type="todo_item"]',
      getAttrs(dom) {
        return {
          done: dom.getAttribute('data-done') === 'true',
        }
      },
    },
  ]
}

class TaskListController extends SylController<ITaskListProps> {
  public name = PLUGIN_NAME
  constructor(editor: SylApi, props) {
    super(editor, props)
    this.editor.root.addEventListener('click', (e) =>
      this.handleClickOn(e, this.editor),
    )
    events.on(ACTIONS.MOD_CTRL_9_TASK, this.addTodo)
  }

  editorWillUnmount(): void {
    events.off(ACTIONS.MOD_CTRL_9_TASK, this.addTodo)
  }
  public todoItemSpec = {
    attrs: {
      done: { default: false },
    },
    content: 'paragraph block*',
    toDOM(node) {
      const { done } = node.attrs
      return [
        'div',
        {
          'data-type': 'todo_item',
          'data-done': done.toString(),
        },
        [
          'span',
          {
            class: 'todo-checkbox todo-checkbox-unchecked',
            contenteditable: 'false',
          },
        ],
        [
          'span',
          {
            class: 'todo-checkbox todo-checkbox-checked',
            contenteditable: 'false',
          },
        ],
        ['div', { class: 'todo-content' }, 0],
      ] as DOMOutputSpec
    },
    parseDOM: [
      {
        priority: 51, // Needs higher priority than other nodes that use a "li" tag
        tag: '[data-type="todo_item"]',
        getAttrs(dom) {
          return {
            done: dom.getAttribute('data-done') === 'true',
          }
        },
      },
    ],
  }
  public todoListSpec = {
    group: 'block',
    content: 'todo_item+ | list_item+',
    toDOM(node) {
      return [
        'ul',
        {
          'data-type': 'todo_list',
        },
        0,
      ] as DOMOutputSpec
    },
    parseDOM: [
      {
        priority: 51, // Needs higher priority than other nodes that use a "ul" tag
        tag: '[data-type="todo_list"]',
      },
    ],
  }

  public nodes = addListNodes(
    schema.spec.nodes,
    'paragraph block*',
    'block',
  ).append({
    todo_item: this.todoItemSpec,
    todo_list: this.todoListSpec,
  })

  public mySchema = new Schema({
    nodes: this.nodes,
    marks: schema.spec.marks,
  })

  public handleClickOn(e, editor) {
    const nodePos = editor.view.posAtDOM(e.target.parentElement)
    const node = editor.view.domAtPos(nodePos)
    if (e.target.classList.contains('todo-checkbox')) {
      // editor.view.dispatch(
      //   this.toggleTodoItemAction(this.editor.view.state, nodePos, node),
      // )

      e.target.parentElement.dataset.done =
        e.target.parentElement.dataset.done == 'true' ? 'false' : 'true'
      const tr = editor.view.state.tr
      editor.view.dispatch(tr)
      events.emit(ACTIONS.SAVE_TASKLIST_EDITOR, {
        content: editor.getHTML({ layerType: 'test' }).replace(/<p><\/p>/g, ''),
      })
    }
  }

  public toggleTodoItemAction(state, pos, todoItemNode) {
    return state.tr.setNodeMarkup(pos, null, {
      done: true,
    })
  }

  public addTodo = () => {
    sinkListItem(this.mySchema.nodes.todo_item)
    return true
  }

  public demo = () => {
    return true
  }
  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    tooltip: PLUGIN_NAME,

    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div>
          <IconTaskList />
        </div>
      )
    },
  }
}

class TaskListPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = TaskListController
  // public keymap = keymap(TaskListController.prototype.todoItemKeymap)
  public Schema = TaskListSchema
}

export { TaskListPlugin }
