import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconParaphrase } from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'

interface RewriteProps {
  name: string
}

const PLUGIN_NAME = 'rewrite'

class RewriteSchema extends Inline<RewriteProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class RewriteController extends SylController<RewriteProps> {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.TO_EDITOR, this.replaceHandle)
  }

  editorWillUnmount() {
    events.off(ACTIONS.TO_EDITOR, this.replaceHandle)
  }

  public updateDocHandle = (_editor: SylApi) => {
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    // this.clickFromRewriteEditor(selectedContent);
    this.clickFromEditor(selectedContent, DocBtnType.rewriteSingle, nodeInfo)
  }

  public clickFromEditor = (selectedContent, btnType, nodeInfo) => {
    events.emit(ACTIONS.FROM_REWRITE_CLICK_EDITOR, {
      setSelectContent: selectedContent,
      barActiveIndex: btnType,
      nodeInfo: nodeInfo,
    })
  }

  public replaceHandle = (payload) => {
    // Get the style of the old text
    const { nodeInfo, content } = payload
    if (!nodeInfo) return
    const oldTextNode = this.editor.view.state.doc.nodeAt(nodeInfo.index)
    const oldTextStyle = oldTextNode.marks

    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      this.editor.view.state.schema.text(content, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    // this.removeHighlightAndTip();
    events.emit(ACTIONS.FROM_EDITOR, {
      setSelectContent: null,
      barActiveIndex: payload.type,
      nodeInfo: null,
    })
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    // type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div
          className="rewriteBox iconBox"
          onClick={() => this.updateDocHandle(_editor)}>
          <IconParaphrase
            className="iconParaphrase icon"
            useCurrentColor={true}></IconParaphrase>
          <span>改写</span>
        </div>
      )
    },
    value: [],
  }
}

class RewritePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = RewriteController
  public Schema = RewriteSchema
}

export { RewritePlugin }
