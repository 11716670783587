import Request from '@/utils/request'
import {
  API_POST_GET_DOCUMENT_DETAIL,
  API_POST_UPLOAD_FILE,
  API_POST_TRANSLATE,
  API_POST_SAVE_COMMENT,
  API_POST_GET_COMMENT_LIST,
  API_POST_DELETE_COMMENT_LIST,
  API_POST_EDIT_COMMENT_LIST,
  API_POST_SET_COMMENT_READ,
  API_POST_SAVE_BY_TEACHER,
  API_POST_DOC_VERSION_LIST,
  API_POST_TEXT_REWRITING,
  API_POST_TEXT_CORRECTION,
  API_POST_DOCUMENT_SHARE_LINK,
  API_POST_OPEN_DOCUMENT_SHARE,
  API_POST_REFRESH_DOCUMENT_SHARE_LINK,
  API_POST_CLOSE_DOCUMENT_SHARE,
  API_POST_GET_DOCUMENT_BY_SHARE,
  API_POST_GET_GPTDATA_BY_MESSAGE,
  API_POST_OFFICIAL_PROOF_READING,
  API_POST_TEXT_MODERATION,
  API_POST_DOC_COMMENT_LIST_BY_PARAGRAPH,
} from './endpoints'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { EDITOR_REVIEW_HISTORY_RECORD_REQUEST_COUNT } from '../config/editor-config'

export function getDocumentDetail({
  uuid,
}: // 私信的内容
  {
    uuid?: string
  }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_GET_DOCUMENT_DETAIL, { ...params, ...(GetQueryValue('teamUuid') ? { teamUuid: GetQueryValue('teamUuid') } : {}) })
}

export function getUploadFile(file) {
  const params = removeEmpty({
    file,
  })
  return Request.post(API_POST_UPLOAD_FILE, { ...params })
}

/* 翻译 */

export function getTranslate({
  from,
  q,
  to,
}: {
  from: string
  q: string
  to: string
}) {
  const params = removeEmpty({
    from,
    q,
    to,
  })
  return Request.post(API_POST_TRANSLATE, { ...params })
}

/* 保存文档批注 */
export function saveComment({
  className,
  content,
  uuid,
  isParagraph,
  commentUuid,
}: {
  className: string
  content: string
  uuid: string
  isParagraph?: string
  commentUuid?: string
}) {
  const params = removeEmpty({
    className,
    content,
    uuid,
    isParagraph,
    commentUuid,
  })
  return Request.post(API_POST_SAVE_COMMENT, { ...params })
}

/* 获取文档批注列表 */
export function getCommentList({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_GET_COMMENT_LIST, { ...params })
}

/* 删除文档批注 */
export function deleteComment({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_DELETE_COMMENT_LIST, { ...params })
}

/* 编辑文档批注 */
export function editComment({
  className,
  content,
  uuid,
}: {
  className: string
  content: string
  uuid: string
}) {
  const params = removeEmpty({
    className,
    content,
    uuid,
  })
  return Request.post(API_POST_EDIT_COMMENT_LIST, { ...params })
}

/* 文档批注已读 */
export function setCommentRead({ uuids }: { uuids: string[] }) {
  const params = removeEmpty({
    uuids,
  })
  return Request.post(API_POST_SET_COMMENT_READ, { ...params })
}

/* 获取文档历史版本 */
export function getDocVersionList({ uuid, pageIndex }: { uuid: string, pageIndex: number }) {
  const params = removeEmpty({
    uuid,
    pageSize: EDITOR_REVIEW_HISTORY_RECORD_REQUEST_COUNT, //  每页多少条
    pageIndex, //  第几页
    orderDirect: 'asc', //  排序方式
  })
  return Request.post(API_POST_DOC_VERSION_LIST, { ...params })
}

export function saveByTeacher({
  content,
  title,
  uuid,
  approvalStatus,
}: {
  approvalStatus?: number
  content?: string
  title?: string
  uuid?: string
}) {
  const params = {
    approvalStatus,
    content,
    uuid,
    title,
  }
  return Request.post(API_POST_SAVE_BY_TEACHER, { ...params })
}

/* 获取文档某个段落的批注列表 */
export function getCommentListByParagraph({
  className,
  uuid,
}: {
  className: string
  uuid: string
}) {
  const params = {
    className,
    uuid,
  }
  return Request.post(API_POST_DOC_COMMENT_LIST_BY_PARAGRAPH, { ...params })
}

export function textRewriting({ text }: { text?: string }) {
  const params = {
    text,
  }
  return Request.post(API_POST_TEXT_REWRITING, { ...params })
}

/* 基础校对 */
export function textCorrection({ text }: { text?: string }) {
  const params = {
    text,
  }
  return Request.post(API_POST_TEXT_CORRECTION, { ...params })
}

/* 公文校对 */
export function officialCorrection({ text }: { text?: string }) {
  const params = {
    text,
  }
  return Request.post(API_POST_OFFICIAL_PROOF_READING, { ...params })
}

/* 敏感词校对 */
export function tactfulCorrection({
  content,
  service,
}: {
  content?: string
  service: string
}) {
  const params = {
    content,
    service,
  }
  return Request.post(API_POST_TEXT_MODERATION, { ...params })
}

/* 获取文档分享链接 */
export function getDocShareLink({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_DOCUMENT_SHARE_LINK, { ...params })
}

/* 打开文档分享 */
export function openDocShare({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_OPEN_DOCUMENT_SHARE, { ...params })
}

/* 刷新文档分享链接 */
export function refreshDocShareLink({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_REFRESH_DOCUMENT_SHARE_LINK, { ...params })
}

/* 关闭文档分享 */
export function closeDocShare({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_CLOSE_DOCUMENT_SHARE, { ...params })
}

/* 根据文档链接获取文档内容 */
export function getDocByShare({
  uuid,
  docUuid,
}: {
  uuid: string
  docUuid?: string
}) {
  const params = removeEmpty({
    uuid,
    docUuid,
  })
  return Request.post(API_POST_GET_DOCUMENT_BY_SHARE, { ...params })
}

/* 根据messageId获取文档内容 */
export function getToolboxShareShare({ uuid }) {
  const params = removeEmpty({
    messageId: uuid,
  })
  return Request.post(API_POST_GET_GPTDATA_BY_MESSAGE, { ...params })
}
