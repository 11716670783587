import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import style from './index.module.less'
import { SylApi, EventChannel } from '@syllepsis/adapter'
import { getDocumentDetail, saveDocument } from '@/model/reference'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import WritingMobile from '../writingMobile'
import HeaderMod from '@/components/DocEditMobile/headerMod'
import ToolsMod from '@/components/DocEditMobile/toolsMod'
import VConsole from 'vconsole'
import TopBar from '@/components/DocEditMobile/topBar'
import Drawer from '@/components/Drawer'
import { showDrawer, showModal } from '@/share/actions'
import Modal from '@/components/Modal'
import { ApprovalStatus, CollectState, DocVersionType } from '@/helpers/enums'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { Message } from '@arco-design/web-react'
const DOcEditMobile = () => {
  // var vConsole = new VConsole()
  // vConsole.destroy()
  const [docContent, setDocContent] = useState<string>('')
  const [docTitle, setDocTitle] = useState<string>('')
  const [uuid, setUuid] = useState<string>()
  const [isAndroid, setIsAndroid] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [isAutoSave, setIsAutoSave] = useState(false)
  const [defaultContent, setDefaultContent] = useState<any>()
  const [selectContent, setSelectContent] = useState<any>()
  const [nodeInfo, setNodeInfo] = useState<any>()
  const [isCollect, setIsCollect] = useState<number>(CollectState.noCollect)
  const [isEditTitle, setIsEditTitle] = useState(false)
  const id = GetQueryValue('uuid')
  const titleRef = useRef()
  const editTitleRef = useRef()
  const contentRef = useRef()
  const editContentRef = useRef()
  const uuidRef = useRef()
  const history = useHistory()
  var page = document.querySelector('#page') as HTMLElement
  var currentInput = null // 当前聚焦的输入框
  var fixedEle = document.querySelector('#fixed') as HTMLElement
  var originHeight = window.innerHeight
  let timerContent,
    autoTimeContent,
    timerTitle,
    autoTimerTitle,
    isContentHtmlTimer
  useEffect(() => {
    // localStorage.setItem('userStatus', 'login')
    // document.cookie = 'access_token=' + GetQueryValue('token')
    getDocDetailHandle()
    isPhone()
    // sendMessage()
  }, [])

  useEffect(() => {
    if (!isEditTitle) return
    if (timerTitle) clearTimeout(timerTitle)
    if (autoTimerTitle) clearTimeout(autoTimerTitle)
    setIsAutoSave(true)
    console.log('ssssss')
    timerTitle = setTimeout(() => {
      saveDocHandle(
        editContentRef.current ? editContentRef.current : contentRef.current,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.SilentSave,
      )
      setIsEditTitle(false)
    }, 3000)
    autoTimerTitle = setTimeout(() => {
      saveDocHandle(
        editContentRef.current ? editContentRef.current : contentRef.current,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.AutoSave,
      )
      setIsEditTitle(false)
    }, 1000 * 60 * 5)
    document.title = editTitleRef.current
      ? editTitleRef.current + ' - 悟智AI'
      : titleRef.current + ' - 悟智AI'
    setIsEditTitle(false)
  }, [editTitleRef.current])

  // function sendMessage() {
  //   uni.postMessage({
  //     data: {
  //       action: 'messagehhhh',
  //     },
  //   })
  // }

  const isPhone = () => {
    const u = navigator.userAgent
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
      setIsAndroid(true)
    }
  }

  const getDocDetailHandle = async () => {
    if (!id) return
    const res = await getDocumentDetail({
      uuid: id,
    })
    if (res.data.success) {
      setDocContent(res.data.data.content)
      setDocTitle(res.data.data.title)
      setIsCollect(res.data.data.isCollect)
      titleRef.current = res.data.data.title
      contentRef.current = res.data.data.content
    }
  }

  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    const handler = (e) => {
      if (!window.visualViewport) return
      setViewport({
        width: window.visualViewport.width,
        height: window.visualViewport.height,
      })
    }

    window.visualViewport.addEventListener('resize', handler)
    window.visualViewport.addEventListener('scroll', handler)

    return () => {
      window.visualViewport.removeEventListener('resize', handler)
      window.visualViewport.removeEventListener('scroll', handler)
    }
  }, [])

  const toolClickHandle = (toolObj) => {
    setVisible(true)
    showDrawer('ToolDrawer', {
      toolObj: toolObj.item,
      nodeInfo: nodeInfo,
      defaultContent: defaultContent,
      selectContent: selectContent,
    })
  }

  const [hideBottom, setHideBottom] = useState<boolean>(false)

  useEffect(() => {
    monitorSoftKeyboard((isUp) => {
      if (isUp) {
        setHideBottom(true)
      } else {
        setHideBottom(false)
      }
    })
  }, [])

  // 滚动手势时收起软键盘
  const stopMove = () => {
    if (currentInput) {
      currentInput.blur()
    }
  }

  const setFixedBottom = () => {
    fixedEle.style.bottom =
      originHeight - window.visualViewport.height - window.pageYOffset + 'px'
  }
  const handleScroll = () => {
    page.scrollTop =
      originHeight - window.visualViewport.height - window.pageYOffset
    setFixedBottom()
  }

  const handleFocusin = (e) => {
    var el = e || window.event
    currentInput = el.target
    // window.scrollTo({
    //   top: e.view.scrollY + 20,
    //   behavior: 'smooth',
    // })
    setFixedBottom() // 从一个输入框聚焦到另一个输入框时，因为上一个聚焦的输入框因为失焦导致top置为0了，如果新聚焦的输入框不会触发webview平移，则沿用当时的位移就好了
    // 添加滚动监听，为了软键盘出现 以及 从一个聚焦输入框聚焦到另外一个输入框时， 重新定位fixed元素
    window.addEventListener('scroll', handleScroll)
    // 监听移动手势： 在软键盘出现后，如果想要滚动，则收起软键盘，解绑webview的滚动监听事件
    // window.addEventListener('touchmove', stopMove)

    // 这里主要是应付每次第一次聚焦输入框出现软键盘时时没有发生webview平移时（如输入框本身就在页面很上面），手动调整定位，因为没有发生平移，就不能依赖监听scroll事件进行调整定位。
    // 可能有人想着干脆去掉监听scroll，把定位调整的都通过这个定时器解决，
    // 但是这么做的话，当从一个输入框聚集到另一个输入框，由于这个变化过程比较快，这个定时器反应比较慢，所以就会出现定位延后调整的视觉感受，有种滞后感，体验不好
    // 利用scroll调整就比较丝滑。虽然最终还是会执行到这个定时器，但是此时执行的结果都已经调整好了，所以不会有任何影响。
    setTimeout(() => {
      setFixedBottom()
    }, 1000)
  }

  const handleFocusout = (e) => {
    currentInput = null
    window.removeEventListener('touchmove', stopMove)
    window.removeEventListener('scroll', handleScroll)
    fixedEle.style.bottom = 0 + 'px'
  }

  page && page.addEventListener('focusin', handleFocusin)
  page && page.addEventListener('focusout', handleFocusout)

  const saveHandle = (approvalStatus) => {
    if (approvalStatus == ApprovalStatus.ToSubmit) {
      saveDocHandle(
        editContentRef.current ? editContentRef.current : contentRef.current,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.ManualSave,
      )
    } else {
      showModal('ConfirmMobileModal', {
        title: '  发布文档',
        content: `确认是否发布文档？`,
        onConfirm: async () => {
          saveDocHandle(
            editContentRef.current
              ? editContentRef.current
              : contentRef.current,
            editTitleRef.current ? editTitleRef.current : titleRef.current,
            ApprovalStatus.PendingApproval,
            false,
            null,
            DocVersionType.PublishSave,
          )
        },
      })
    }
  }

  const moreHandle = () => {
    showDrawer('MoreDrawer', {
      isCollect: isCollect,
      uuid: id,
    })
  }

  const editContentHandle = (content) => {
    editContentRef.current = content
    if (timerContent) clearTimeout(timerContent)
    if (autoTimeContent) clearTimeout(autoTimeContent)
    timerContent = setTimeout(() => {
      saveDocHandle(
        content,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.SilentSave,
      )
    }, 2000)
    autoTimeContent = setTimeout(() => {
      saveDocHandle(
        content,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.AutoSave,
      )
    }, 1000 * 60 * 5)
  }

  const editTitleHandle = (title) => {
    editTitleRef.current = title
    setIsEditTitle(true)
  }

  const saveDocHandle = async (
    content,
    title,
    approvalStatus,
    isBack = false,
    message,
    docVersionType,
  ) => {
    console.log('uuuuuuuuu')
    if (
      docVersionType === DocVersionType.ManualSave ||
      docVersionType === DocVersionType.PublishSave
    ) {
      events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    }
    if (!content && !title) return
    if (!isAutoSave) {
      const res = await saveDocument({
        title: !!title ? title : '未命名',
        approvalStatus: approvalStatus,
        content: content ? content : '',
        docType: 0,
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        fileLevel: 1,
        fileType: 0,
        docStatus: 0,
        uuid: uuidRef.current ? uuidRef.current : GetQueryValue('uuid'),
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        docVersionType: docVersionType,
      })
      if (res.data.success) {
        setIsAutoSave(false)
        uuidRef.current = res.data.data
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '保存成功',
        })
        if (!GetQueryValue('uuid')) {
          const urlQuery = removeEmpty({
            uuid: uuidRef.current,
            filePath: GetQueryValue('filePath')
              ? GetQueryValue('filePath')
              : '',
            folderUuid: GetQueryValue('folderUuid')
              ? GetQueryValue('folderUuid')
              : '',
            from: GetQueryValue('from') ? GetQueryValue('from') : '',
            title: GetQueryValue('title') ? GetQueryValue('title') : '',
            collectType: GetQueryValue('collectType')
              ? GetQueryValue('collectType')
              : '',
            collectDocUuid: GetQueryValue('collectDocUuid')
              ? GetQueryValue('collectDocUuid')
              : '',
            collectFoldUuid: GetQueryValue('collectFoldUuid')
              ? GetQueryValue('collectFoldUuid')
              : '',
          }) as any
          history.replace({
            pathname: '/docEditMobile',
            search: '?' + new URLSearchParams(urlQuery).toString(),
          })
          // if (!isBack && approvalStatus != 1) {
          //   location.reload()
          // }
        }
      }

      if (!res.data.success) {
        setIsAutoSave(false)

        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '保存失败',
        })
      }
      setUuid(res.data.data)
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '正在保存',
      })
    }
  }

  const getContentDocHandle = (contentStructure) => {
    const contentArr = contentStructure.doc.content
    const textArray = []
    let nodeIndex = -1
    let headerNum = 0
    contentArr.forEach((obj, index) => {
      if (
        (obj.type === 'paragraph' || obj.type === 'header') &&
        !!obj.content
      ) {
        let text = ''
        nodeIndex += 1
        obj.content.forEach((contentObj) => {
          if (contentObj.type === 'text') {
            text = text + contentObj.text
          } else if (contentObj.type === 'link') {
            text = text + contentObj.attrs.text.trim()
          }
        })
        textArray.push({ text: text, nodeIndex: nodeIndex })
      } else if (obj.type === 'ordered_list') {
        let text = ''
        obj.content.forEach((listItem) => {
          listItem?.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
              nodeIndex += 1
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (obj.type === 'bullet_list') {
        let text = ''
        obj.content.forEach((listItem) => {
          listItem.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
              nodeIndex += 1
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (obj.type === 'block_quote') {
        let text = ''

        obj.content.forEach((listItem) => {
          listItem.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            nodeIndex += 1
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (!obj.content) {
        nodeIndex += 1
      }
    })
    contentArr.forEach((obj) => {
      if (obj.type === 'header') {
        headerNum += 1
      }
    })

    setDefaultContent(textArray)
  }

  const getSelectContent = (content, nodeINfo) => {
    setSelectContent(content)
    setNodeInfo(nodeINfo)
    console.log(content, 'content')
  }

  return (
    // <div>
    //   <TopBar isShowBack={false}></TopBar>
    //   <div className={style.contentBox} id="mobileBox">
    //     <HeaderMod docTitle={docTitle}></HeaderMod>
    //     <WritingMobile
    //       docContent={docContent}
    //       viewHeight={viewport.height}
    //       hideBottom={hideBottom}
    //       isAndroid={isAndroid}></WritingMobile>
    //   </div>
    //   {/* <div
    //     style={{
    //       position: 'fixed',
    //       bottom: viewport.height,
    //       height: '100px',
    //       width: '100%',
    //       background: 'red',
    //     }}></div> */}
    //   {isAndroid && !hideBottom && (
    //     <ToolsMod onToolClick={toolClickHandle}></ToolsMod>
    //   )}
    //   {!isAndroid && <ToolsMod onToolClick={toolClickHandle}></ToolsMod>}
    //   <Drawer
    //     width={332}
    //     height={332}
    //     title={<span>Basic Information </span>}
    //     visible={visible}
    //     placement={'bottom'}
    //     onOk={() => {
    //       setVisible(false)
    //     }}
    //     onCancel={() => {
    //       setVisible(false)
    //     }}>
    //     <div>Here is an example text. </div>
    //     <div>Here is an example text.</div>
    //   </Drawer>
    // </div>
    <div className={style.page} id="page">
      <TopBar
        isShowBack={false}
        isCollect={isCollect}
        onClickSave={saveHandle}
        onClickMore={moreHandle}></TopBar>
      <div className={style.contentBox} id="mobileBox">
        <HeaderMod
          docTitle={docTitle}
          onSaveTitle={editTitleHandle}></HeaderMod>
        <WritingMobile
          docContent={docContent}
          viewHeight={viewport.height}
          hideBottom={hideBottom}
          isAndroid={isAndroid}
          editContent={editContentHandle}
          getContentDoc={getContentDocHandle}
          onSelectContent={getSelectContent}
        />
      </div>
      {/* <div
        style={{
          position: 'fixed',
          bottom: viewport.height,
          height: '100px',
          width: '100%',
          background: 'red',
        }}></div> */}
      {isAndroid && !hideBottom && (
        <ToolsMod onToolClick={toolClickHandle}></ToolsMod>
      )}
      {!isAndroid && <ToolsMod onToolClick={toolClickHandle}></ToolsMod>}
      <div className={style.prefixPlaceholder} id="prefixPlaceholder"></div>
      <div className={style.wrap} id="wrap"></div>
      <div className={style.fixed} id="fixed"></div>
      <div className={style.suffixPlaceholder} id="suffixPlaceholder"></div>
      <Drawer></Drawer>
      <Modal></Modal>
    </div>
  )
}

export default DOcEditMobile

export const monitorSoftKeyboard = (callback) => {
  const originalHeight =
    document.documentElement.clientHeight || document.body.clientHeight
  window.addEventListener('resize', () => {
    //键盘弹起与隐藏都会引起窗口的高度发生变化
    const resizeHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    // 10 的数量差是因为IOS大屏手机特殊原因，弹起软键盘再恢复的大小和一开始的有落差
    if (Math.abs(resizeHeight - originalHeight) < 10) {
      document.body.setAttribute('style', 'overscroll-behavior-y: auto;')
      // false 软键盘收起
      callback(false)
    } else {
      document.body.setAttribute('style', 'overscroll-behavior-y: none;')
      // true 软键盘弹起
      callback(true)
    }
  })
}
