import React, { useEffect, useMemo } from 'react'
import PickItem from './PickItem'
import { Radio } from '@arco-design/web-react'

const RadioGroup = Radio.Group

function PickList(props) {
  const theme = props.theme
  const list = props.list ? props.list : []
  const uniqueData = (parsedList) => {
    const uniqueTitles = new Set()
    const filteredData = []

    parsedList.forEach((item) => {
      if (!uniqueTitles.has(item.title || item.description)) {
        uniqueTitles.add(item.title || item.description)
        filteredData.push(item)
      }
    })

    return filteredData
  }
  const filtreList = useMemo(() => uniqueData(list), [list])
  console.log(props.checked?.length)

  useEffect(() => {
    // 检查是否已有选中项
    if (props.checked?.length == 0 && filtreList?.length > 0) {
      // 默认选择第一项
      if (props.curStep == 1) {
        props.changeChecked(filtreList[0].title)
      } else if (props.curStep == 2) {
        props.changeChecked(filtreList[0].description)
      }
    }
  }, [filtreList])

  return (
    <div>
      <RadioGroup
        direction="vertical"
        value={props.checked}
        onChange={(value) => {
          props.changeChecked(value)
          console.log(value, 'selectedItems')
        }}
        style={{ display: 'block' }}>
        {filtreList.map((item, index) => {
          // 渲染提取的标题内容
          return (
            <PickItem
              theme={theme}
              key={index}
              id={index}
              content={item.title || item.description}
              checked={props.checked}
              onEditIntro={props.onEditIntro}
              onEditTitle={props.onEditTitle}
              curStep={props.curStep}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

export default PickList
