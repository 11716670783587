import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style/step-header-mod.module.less'
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconClear,
  IconErrorTip,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '@/components/BorderBtn'
import { Message, Popconfirm, Tooltip } from '@arco-design/web-react'
import { showModal } from '@/share/actions'
import { DeleteAgent } from '@/model/reference'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { GetQueryValue } from '@/utils/common'
import { IconQuestionCircle } from '@arco-design/web-react/icon'

function StepHeaderMod(props) {
  const history = useHistory()

  const submitAgentModal = () => {
    showModal('SaveAgentModal')
  }
  const handleBack = () => {
    history.push('/aiAgent?agentMylist')
  }
  return (
    <div className={`${styles.header} ${styles[props.theme]}`}>
      <div className={styles.header_left}>
        <div
          className={styles.backIcon}
          onClick={() => {
            handleBack()
          }}>
          <IconArrowLeft />
        </div>
        <span>编辑智能体</span>
      </div>
      <div className={styles.header_right}>
        <BorderBtn
          theme={props.theme}
          onClick={() => {
            window.open(
              'https://portal.wuz.com.cn/documentShare?uuid=fe60f7df-ad4e-45d1-9cd2-885f64488920&selectUuid=66cb0d07-853b-41a8-adee-571acdec6d65',
            )
          }}
          iconLeft={<IconQuestionCircle />}
          text={'常见问题'}
        />
        {/* <IconQuestionCircle /> */}
        {GetQueryValue('id')?.length > 0 && (
          <BorderBtn
            onClick={() => {
              submitAgentModal()
            }}
            iconLeft={<IconCheck />}
            text={'发布'}
            gradient={true}></BorderBtn>
        )}
      </div>
    </div>
  )
}

export default StepHeaderMod
