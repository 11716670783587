import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec, Fragment, Slice } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { EditorView } from 'prosemirror-view'

interface PasteProps {
  name: string
}

const PLUGIN_NAME = 'paste'

class PasteSchema extends Inline<PasteProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class PasteController extends SylController<PasteProps> {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    // events.on(ACTIONS.TO_EDITOR, this.replaceHandle);
  }

  public removeMarksFromNode(node) {
    console.log(node, 'node', node.isText, node.isInline)
    if (node.isText) {
      // 移除 comment mark
      const marksWithoutComment = node.marks.filter(
        (mark) => mark.type.name !== 'comment',
      )
      return node.mark(marksWithoutComment)
    } else if (node.isInline) {
      // 移除 comment mark
      const newContent = this.removeCommentMarksFromFragment(node.content)
      const marksWithoutComment = node.marks.filter(
        (mark) => mark.type.name !== 'comment',
      )
      // return node.copy(newContent, node.attrs, marksWithoutComment)
      // Remove hasComment class from node.attrs.class
      const updatedAttrs = {
        ...node.attrs,
        class:
          this.removeClass(node.attrs.class, 'hasComment') &&
          this.removeClass(node.attrs.class, 'hasComment').trim(),
      }
      return node.type.createAndFill(
        updatedAttrs,
        newContent,
        marksWithoutComment,
      )
    } else {
      // 移除 comment mark
      const newContent = this.removeCommentMarksFromFragment(node.content)
      // return node.copy(newContent)

      // Remove hasComment class from node.attrs.class
      const updatedAttrs = {
        ...node.attrs,
        class: this.removeClass(node.attrs.class, 'hasComment')?.trim(),
      }
      return node.type.createAndFill(updatedAttrs, newContent)
    }
  }

  // 辅助函数：从字符串中移除指定的类名
  private removeClass(classNames, classNameToRemove) {
    return String(classNames)
      ?.split(' ')
      .filter((cls) => cls !== classNameToRemove)
      .join(' ')
  }

  // 定义一个辅助函数来清除 Fragment 中的所有 marks
  public removeCommentMarksFromFragment(fragment) {
    const newContent = []
    fragment.forEach((node) => {
      newContent.push(this.removeMarksFromNode(node))
    })
    return Fragment.fromArray(newContent)
  }

  public eventHandler = {
    transformPasted: (editor: SylApi, slice: Slice) => {
      const newSlice = new Slice(
        this.removeCommentMarksFromFragment(slice.content),
        slice.openStart,
        slice.openEnd,
      )
      // events.emit(ACTIONS.COMMENT_BUBBLE_DISPATCH)
      return newSlice
    },
    handleKeyDown: (editor: SylApi, view: EditorView, event: KeyboardEvent) => {
      if (!editor.enable) return false
      if (
        (event.key === 'Enter' && event.keyCode === 13) ||
        event.which === 13
      ) {
        events.emit(ACTIONS.COMMENT_BUBBLE_DISPATCH)
      }
      return false
    },
    // handleClick: (
    //   editor: SylApi,
    //   view: EditorView,
    //   pos: number,
    //   event: MouseEvent,
    // ) => {
    //   console.log(pos, 'pos')
    //   // 使用定时器防止点击其他的按钮不生效，例如：点击图片对齐时被挤掉
    //   setTimeout(() => {
    //     editor.setSelection({ index: pos })
    //   }, 10)
    //   return false
    // },
  }
}

class PastePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = PasteController
  public Schema = PasteSchema
}

export { PastePlugin }
