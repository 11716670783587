import React, { useEffect, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import './style/index.less'

const Celebrate = () => {
  const { width, height } = useWindowSize()

  useEffect(() => {
    // 来到这个页面固定时间返回
    setTimeout(() => {
      window.location.replace('/userCenter?activeTab=order')
    }, 3500)
  }, [])

  return (
    <div className="celebrateOut">
      <Confetti
        width={width}
        height={height}
        colors={['#ff6903']}
        tweenDuration={2900}
      />
      <div className="text">
        <div className="svg">
          <svg
            width="91px"
            height="92px"
            viewBox="0 0 91 92"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
              id="页面-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd">
              <g id="支付成功" transform="translate(-143.000000, -246.000000)">
                <g id="编组-2" transform="translate(107.000000, 248.000000)">
                  <g id="编组" transform="translate(36.000000, 0.000000)">
                    <path
                      d="M80,49.3274 C80,71.4184 62.091,89.3274 40,89.3274 C17.909,89.3274 0,71.4184 0,49.3274 C0,27.2364 17.909,9.3274 40,9.3274 C62.091,9.3274 80,27.2364 80,49.3274"
                      id="Fill-1"
                      fill="#FF6A00"></path>
                    <path
                      d="M80,49.3274 C80,71.4184 62.091,89.3274 40,89.3274 C17.909,89.3274 0,71.4184 0,49.3274 C0,27.2364 17.909,9.3274 40,9.3274 C62.091,9.3274 80,27.2364 80,49.3274"
                      id="Fill-1备份"
                      fill="#FF6A00"></path>
                    <path
                      d="M24,48.137 L34.237,59.18 C35.036,60.043 36.334,60.043 37.133,59.18 L56,38.827"
                      id="Stroke-3"
                      stroke="#FFFFFF"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                    <line
                      x1="64.4891"
                      y1="0"
                      x2="64.4891"
                      y2="8.5"
                      id="Stroke-5"
                      stroke="#FF6A00"
                      strokeWidth="3.5"
                      opacity="0.75"
                      strokeLinecap="round"></line>
                    <line
                      x1="80.5836"
                      y1="20.8627"
                      x2="89.0836"
                      y2="20.8627"
                      id="Stroke-7"
                      stroke="#FF6A00"
                      strokeWidth="3.5"
                      opacity="0.25"
                      strokeLinecap="round"></line>
                    <line
                      x1="79.9804"
                      y1="7.3222"
                      x2="73.9704"
                      y2="13.3322"
                      id="Stroke-9"
                      stroke="#FF6A00"
                      strokeWidth="3.5"
                      opacity="0.5"
                      strokeLinecap="round"></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <p>恭喜您，开通成功 !</p>
        <a>正在返回中...</a>
      </div>
    </div>
  )
}

export default Celebrate
