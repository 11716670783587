import React from 'react';
import ReactDOM from 'react-dom';

import './RLLoading.css';

const LoadingNode = (props) => {
  const { content } = props;
  return (
    <div className="loading-mask-container">
      <div className="loading-content">
        <div className="loading-cir-la" />
        <div className="loading-cir-la-content">
          {content ? content : '正在加载'}
        </div>
      </div>
    </div>
  );
};

class Loading extends React.Component {
  constructor(props) {
    super(props);
    let element = document.createElement('div');
    element.id = 'other-root';
    this.state = {
      element,
    };
  }

  open = (data) => {
    const { element } = this.state;
    document.body.appendChild(element); // 将指定的DOM类型的节点加到document.body的末尾
    ReactDOM.render(<LoadingNode content={data.content} />, element);
  };
  close = () => {
    const { element } = this.state;
    element.remove();
  };
}

const RLLoading = new Loading();
export default RLLoading;
