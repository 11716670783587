import { GetTeamTreeList, getFoldList } from '@/model/reference'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { Breadcrumb } from '@arco-design/web-react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu, Button, Space } from '@arco-design/web-react'
import styles from './style/index.module.less'
import Item from '@arco-design/web-react/es/Breadcrumb/item'
import { set } from 'lodash'
import { indent, insertBreak } from '@udecode/plate'
import { uuid } from '@syllepsis/access-react/dist/es/modules/toolbar/utils'
import { IconArrowLeft } from '@arco-iconbox/react-aidb-v2'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { useLocation } from 'react-router-dom'

function BreadItem(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  const BreadcrumbItem = Breadcrumb.Item
  const [breadList, setBreadList] = useState([])
  const history = useHistory()
  const [resData, setResData] = useState([])
  const [rootDirectory, setRootDirectory] = useState([])
  const [arr, setArr] = useState([])
  const locationSearch = useLocation()

  useEffect(() => {
    if (
      locationSearch.hash == '#draw' ||
      locationSearch.hash == '#template' ||
      locationSearch.hash == '#vip'
    ) {
      return
    } else {
      getFoldListHandle()
    }
  }, [locationSearch])

  let data = []
  const fun = (arrList, ids) => {
    return arrList.map((item) => {
      const result = {
        title: item.title,
        uuid: item.uuid,
        children: '',
      }

      if (item.children && item.children.length > 0) {
        result.children = fun(item.children, ids)
      }

      if (item.uuid === ids) {
        // 更新子级下拉菜单数据
        setArr(item.children)
      }

      return result
    })
  }

  const overHandle = (value) => {
    fun(resData, value.uuid)
  }

  const dropList =
    arr && arr.length > 0 ? (
      <Menu className={'custom-dropdown'}>
        {
          <div className={styles['dropdownList']}>
            {arr.map((item, index) => {
              let newBreadList = []
              breadList.map((ite, index) => {
                newBreadList.push(ite.title)
              })
              return (
                <Menu.Item
                  className={
                    newBreadList.includes(item.title)
                      ? styles['dropdownList-item']
                      : styles['dropdownList-item1']
                  }
                  key={item.index}
                  onClick={() =>
                    hoverHandle(item.uuid ? item.uuid : '', item.title)
                  }>
                  {item.title.length > 10
                    ? item.title.slice(0, 12) + '...'
                    : item.title}
                </Menu.Item>
              )
            })}
          </div>
        }
      </Menu>
    ) : (
      <Menu></Menu>
    )

  const dropLists = (
    <Menu>
      {rootDirectory.map((item, index) => {
        let newBreadList = []
        breadList.map((ite, index) => {
          newBreadList.push(ite.title)
        })
        return (
          <Menu.Item
            className={
              newBreadList.includes(item.title)
                ? styles['dropdownList-item']
                : styles['dropdownList-item1']
            }
            key={item.index}
            onClick={() => {
              hoverClickIntelligenceHandle(
                item.uuid ? item.uuid : '',
                item.title,
              )
            }}>
            {item.title.length > 10
              ? item.title.slice(0, 12) + '...'
              : item.title}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const hoverIntelligenceHandle = (value, arr) => {
    value.map((item, index) => {
      arr[index] = {}
      arr[index].title = item.title
      arr[index].uuid = item.uuid
      setRootDirectory(arr)
    })
  }

  const hoverClickIntelligenceHandle = (uuid, title) => {
    let foldUuid = ''
    const urlQuery = removeEmpty({
      filePath: title,
      listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
      teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      uuid: uuid ? uuid : '',
      folderUuid: foldUuid ? foldUuid : '',
    }) as any
    history.push({
      pathname: '/intelligentDocument',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }

  const hoverHandle = (uuid, title) => {
    let foldUuid = ''
    breadList &&
      breadList.map((item, index) => {
        foldUuid = breadList[index].uuid
      })

    const urlQuery = removeEmpty({
      filePath: title,
      listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
      teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      uuid: uuid ? uuid : '',
      folderUuid: foldUuid ? foldUuid : '',
    }) as any
    history.push({
      pathname: '/intelligentDocument',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }

  const goBreadFoldHandle = (foldUuid, uuid, title) => {
    const urlQuery = removeEmpty({
      filePath: title,
      listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
      teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      uuid: uuid ? uuid : '',
      folderUuid: foldUuid ? foldUuid : '',
    }) as any
    history.push({
      pathname: '/intelligentDocument',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }

  const getFoldListHandle = async () => {
    let res
    if (localStorage.getItem('docTypeKey') === '共享文档') {
      res = await GetTeamTreeList()
    } else {
      res = await getFoldList()
    }
    setResData(res.data.data)

    const breadList = findTitleUuidHierarchy(
      res.data.data,
      GetQueryValue('uuid'),
    )

    setBreadList(breadList)
    let titleString = ''

    breadList.forEach(function (bread) {
      titleString += '/' + bread.title
    })
    // 设置默认值为/
    titleString += '/'
    titleString = titleString ? titleString : '/'
    localStorage.setItem('breadTitles', titleString)

    // console.log(res.data.data,"res");

    let date = res.data.data
    let idd = GetQueryValue('uuid')
    funD(date, dataD, idd)
  }
  let dataD = []
  const funD = (arrList, resArr, ids) => {
    arrList.map((item, index) => {
      resArr[index] = {}
      resArr[index].title = item.title
      resArr[index].uuid = item.uuid
      let arrs = []
      if (item.children && item.children.length > 0) {
        resArr[index].children = arrs
        funD(item.children, arrs, ids)
      }
    })
  }

  function findTitleUuidHierarchy(data, targetUuid) {
    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i]
      if (currentItem.uuid === targetUuid) {
        const hasChildren =
          currentItem.children && currentItem.children.length > 0

        const parentHierarchy = currentItem.parentId
          ? findTitleUuidHierarchy(
              data,
              data.find((item) => item.id === currentItem.parentId),
            )
          : []

        return [
          {
            title: currentItem.title,
            uuid: currentItem.uuid,
            hasChildren: hasChildren, // Add the hasChildren property
          },
          ...parentHierarchy,
        ]
      } else if (currentItem.children) {
        const childHierarchy = findTitleUuidHierarchy(
          currentItem.children,
          targetUuid,
        )

        if (childHierarchy.length) {
          return [
            {
              title: currentItem.title,
              uuid: currentItem.uuid,
              hasChildren: true, // Since it has children below
            },
            ...childHierarchy,
          ]
        }
      }
    }

    return []
  }

  // 返回根目录
  const goHomeHandle = () => {
    const urlQuery = removeEmpty({
      listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
    }) as any

    history.push({
      pathname: '/intelligentDocument',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }

  // 返回上一级
  const goBackHandle = () => {
    // 确保breadList中至少有两个项，因为最后一个项是当前页面的
    if (breadList.length > 2) {
      // 使用上一级文件夹的UUID，保留folderUuid和filePath
      const urlQuery = removeEmpty({
        filePath: breadList[breadList.length - 2].title, // 使用上一级文件夹的标题
        uuid: breadList[breadList.length - 2].uuid,
        folderUuid: breadList[breadList.length - 3].uuid, // 使用上一级文件夹的UUID
        teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      }) as any

      // 导航到上一级文件夹
      history.push({
        pathname: '/intelligentDocument',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
    } else if (breadList.length === 2) {
      const urlQuery = removeEmpty({
        filePath: breadList[0].title, // 使用上一级文件夹的标题
        uuid: breadList[0].uuid,
        teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      }) as any

      // 导航到上一级文件夹
      history.push({
        pathname: '/intelligentDocument',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
    } else if (breadList.length === 1) {
      goHomeHandle()
    }
  }

  return (
    <div className={`${styles['breadBox']} ${styles[theme]}`}>
      <div className={styles['titleBack']} onClick={goBackHandle}>
        <IconArrowLeft />
      </div>
      <Breadcrumb
        style={{ fontSize: 13, display: 'flex' }}
        separator={<span>{'/'}</span>}>
        <Space className="dropdown-demo">
          <Dropdown
            droplist={dropLists}
            position="bl"
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }>
            <Button type="text" onClick={goHomeHandle}>
              <span
                style={{
                  cursor: 'pointer',
                  color: '#9f9f9f',
                  marginRight: '4px',
                  marginLeft: '4px',
                }}
                onMouseOver={() => {
                  hoverIntelligenceHandle(resData, data)
                }}>
                {localStorage.getItem('docTypeKey') == '共享文档'
                  ? '共享文档'
                  : '我的文档'}
              </span>
            </Button>
          </Dropdown>
        </Space>

        {breadList.length != 0 &&
          breadList.map((i, index) => {
            return (
              <div key={index}>
                <Space className="dropdown-demo">
                  <Dropdown
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    onVisibleChange={() => {
                      overHandle(i)
                    }}
                    droplist={dropList}
                    key={index}
                    position="bl"
                    trigger="hover">
                    <Button
                      type="text"
                      onClick={() =>
                        goBreadFoldHandle(
                          index >= 1 && breadList[index - 1].uuid
                            ? breadList[index - 1].uuid
                            : '',
                          i.uuid ? i.uuid : '',
                          i.title,
                        )
                      }>
                      <BreadcrumbItem key={i + index}>
                        <span
                          id="span"
                          className={
                            i.uuid === GetQueryValue('uuid')
                              ? styles['breadcrumbItem-span1']
                              : styles['breadcrumbItem-span']
                          }>
                          {i.title.length > 10
                            ? i.title.slice(0, 12) + '...'
                            : i.title}
                        </span>
                      </BreadcrumbItem>
                    </Button>
                  </Dropdown>
                </Space>
              </div>
            )
          })}
      </Breadcrumb>
    </div>
  )
}

export default BreadItem
