import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Style from 'styled-components'
import { showModal } from '@/share/actions'
import { GetQueryValue } from '@/utils/common'
import { ApprovalStatus } from '@/helpers/enums'
import { getDocShareLink, getDocVersionList } from '@/model/documentEdit'
import { Tooltip, Message } from '@arco-design/web-react'
import IconMsgSuccess from '@/assets/newImgs/msg-success.svg'
import {
  IconRecapHistory,
  IconSaveDocument,
  IconUploadDocument,
  IconDownload,
  IconClose,
} from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import {
  IconErrorTip,
  IconShare,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import IconSaveRotate from '@/assets/newImgs/save.svg'
import IconSaveRotate2 from '@/assets/newImgs/rotate-save.svg'
import { switchDocTitle } from '@/helpers/switchProject'

const TitleBox = Style.div`
  position:relative;
  input:focus{
    background:#eeeeee;
    border-radius: 4px;
  }
  .titleContent{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 -1.5em;
    
    
  }
  .btnGroup{
    display:flex;
  }
  .textSaveBtn {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    font-size: 22px;
    z-index: 5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; 
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    svg {
      fill: #000;
    }
  }
  .textSaveBtn:hover{
    background-color:black;
    
    svg{
      fill:white;
    }
  }
  .commentBtn{
    svg{
      fill: #000;
    }
  }
  .commentBtn:hover{
    svg{
      fill: #fff;
    }
  }
 
  .inputFocus:hover{
    cursor: pointer;
    // pointer-events: none;
  }

  .inputFocus:focus{
    pointer-events: all;
    background:  #eee;
  }
  .iconRotate{
    width: 40px;
    height: 40px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor:  not-allowed;
  }
  .rotate{
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    width: 8px;
    height: 8px;
    animation: rotate 1s linear infinite;
    svg{
      
        display: block;
        fill: currentColor;
    
    }
    @keyframes rotate {
      0% {
          transform:translate(-50%, -50%) rotate(0deg)
      }
      100% {
          transform:translate(-50%, -50%) rotate(360deg)
      }
  }
}
 
  
  .mask {
    background: linear-gradient(180deg,#fff 20%,rgba(255,255,255,0) 100%);
    margin-right: 10px;
    height: 40px;
    left: 0;
    pointer-events: all;
    position: absolute;
    right: 0;
    bottom: -40px;
    text-align: center;
    z-index: 2;
  }
`

type Props = {
  value: string
  isComment: boolean
  isAutoSave: boolean
  onSubmit?: (approvalStatus) => void
  onCommentSubmit?: () => void
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setInputTitle?: React.Dispatch<React.SetStateAction<string>>
}

const Title = (props: Props) => {
  const { value, onSubmit, setTitle, isComment, isAutoSave } = props
  const [inputLen, setInputLen] = useState(value)
  const inputRef = useRef(null)
  const uuid = GetQueryValue('uuid')
  const onSave = (e) => {
    setTitle(e.target.value)
    // setInputTitle(e.target.value)
    setInputLen(e.target.value)
  }

  useEffect(() => {
    setInputLen(value)
  }, [value])

  useEffect(() => {
    const input = document.querySelector('.inputFocus')
    if (!inputLen) {
      inputRef.current.style.width = 120 + 'px'
    } else {
      var len = 0
      for (var i = 0; i < inputLen?.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(inputLen[i])) {
          len += 1.4
        } else {
          len += 1
        }
      }
      // inputRef.current.style.width = (len + 1) * 10 + 'px'
      inputRef.current.style.width =
        (len + 1) * (/[\u4e00-\u9fa5]/.test(inputLen[i]) ? 6 : 16) + 'px'
    }
    if (input.getBoundingClientRect().width >= 565) {
      inputRef.current.style.whiteSpace = 'nowrap'
      inputRef.current.style.overflow = 'hidden'
      inputRef.current.style.textOverflow = 'ellipsis'
    }
  }, [inputLen])

  useEffect(() => {
    const input = document.querySelector('.inputFocus')
    if (!value) {
      inputRef.current.width = 120 + 'px'
    } else {
      var len = 0
      for (var i = 0; i < value?.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(value[i])) {
          len += 1.4
        } else {
          len += 1
        }
      }
      // inputRef.current.style.width = (len + 1) * 10 + 'px'
      inputRef.current.style.width =
        (len + 1) * (/[\u4e00-\u9fa5]/.test(value[i]) ? 6 : 16) + 'px'
    }

    if (input.getBoundingClientRect().width >= 565 && !isComment) {
      inputRef.current.style.width = 565 + 'px'
      inputRef.current.style.whiteSpace = 'nowrap'
      inputRef.current.style.overflow = 'hidden'
      inputRef.current.style.textOverflow = 'ellipsis'
      inputRef.current.addEventListener('touchmove', function (e) {
        e.preventDefault() // 阻止默认行为，即禁止滑动
      })
    }
  }, [value])

  const reviewHandle = async () => {
    showModal('ReviewModal', {
      title: '回顾',
      // versionList: res.data.data,
    })

    // const res = await getDocVersionList({ uuid: GetQueryValue('uuid') })
    // if (res.data.success && res.data.data.length != 0) {
    // } else if (res.data.success && res.data.data.length == 0) {
    //   Message.success({
    //     icon: <IconSuccessTip useCurrentColor={false} />,
    //     content: '目前没有历史版本',
    //   })
    // }
  }

  function getDownLoad() {
    const positionId = localStorage.getItem('positionId')
    const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null
    // 导出word
    const uuid = GetQueryValue('uuid')
    axios
      .post(
        '/api/api-aidb/document/htmlToWord',
        { uuid, docVersionId: 0, isTeacher: isTeacher },
        { responseType: 'blob' },
      )
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        const match = contentDisposition.match(
          /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
        )
        let filename
        if (match && match[1]) {
          filename = decodeURIComponent(match[1])
        } else {
          filename = 'report.doc'
        }
        const fileData = new Blob([res.data], { type: 'application/msword' })
        const fileURL = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = filename
        link.click()
      })
  }
  //网页标题
  useEffect(() => {
    document.title = inputRef?.current?.value
      ? inputRef?.current?.value + ' - ' + switchDocTitle()
      : switchDocTitle()
  }, [onSave])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      events.emit(ACTIONS.INPUT_ENTER)
    }
  }

  const shareHandle = async () => {
    const res = await getDocShareLink({ uuid: GetQueryValue('uuid') })
    // const res = await getDocShareLink({
    //   uuid: 'e9592dc5-660b-4871-9a49-1964ebb62f04',
    // })

    if (res.data.success) {
      const postData = {
        url:
          window.location.origin +
          '/documentShare?uuid=' +
          res.data.data.documentShareLink,
        // url: 'https://portal.wuz.com.cn/documentShare?uuid=592a8c05-03f0-4695-b734-27d1d7387958',
      }
      showModal('ShareLinkModal', {
        title: '分享',
        id: GetQueryValue('uuid'),
        isOpenShare: res.data.data.isOpenShare,
        documentShareLink: postData,
      })

      // try {
      //   const response = await fetch(
      //     'https://cors.v-dk.com/https://go.wuz.com.cn/api/set.php',
      //     {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //       },
      //       body: JSON.stringify(postData),
      //     },
      //   )

      //   if (response.ok) {
      //     // 请求成功
      //     const previewData = await response.json()
      //     if (previewData.success) {
      //       showModal('ShareLinkModal', {
      //         title: '分享',
      //         id: GetQueryValue('uuid'),
      //         isOpenShare: res.data.data.isOpenShare,
      //         documentShareLink: previewData.content.url,
      //       })
      //     }
      //   } else {
      //     // 请求失败
      //     console.error('set.php 请求失败')
      //   }
      // } catch (error) {
      //   console.error('set.php 请求出错', error)
      // }
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: res.data.msg,
      })
    }
  }

  return (
    <TitleBox>
      <div className="titleContent">
        <input
          ref={inputRef}
          onChange={onSave}
          defaultValue={value}
          style={{
            width: isComment && '100%',
            backgroundColor: isComment && 'white',
            fontSize: '20px',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 10px',
          }}
          className={`inputFocus`}
          type="text"
          placeholder="请输入标题"
          onKeyDown={handleKeyDown}
          disabled={isComment}></input>
        {!isComment ? (
          <div className="btnGroup">
            {isAutoSave ? (
              <div className="iconRotate">
                <IconSaveRotate />
                <div className={'rotate'}>
                  <IconSaveRotate2 />
                </div>
              </div>
            ) : (
              <Tooltip
                className={'customTooltip'}
                position="bottom"
                trigger="hover"
                content={'保存文档'}>
                <div
                  className="textSaveBtn"
                  onClick={() => onSubmit(ApprovalStatus.ToSubmit)}>
                  <IconSaveDocument useCurrentColor={true} className="icon" />
                </div>
              </Tooltip>
            )}

            <Tooltip
              className={'submitTooltip'}
              position="bottom"
              trigger="hover"
              content={'发布文档'}>
              <div
                className="textSaveBtn"
                onClick={() => onSubmit(ApprovalStatus.PendingApproval)}>
                <IconUploadDocument useCurrentColor={true} className="icon" />
              </div>
            </Tooltip>
            <Tooltip
              className={'submitTooltip'}
              position="bottom"
              trigger="hover"
              content={'分享'}>
              <div className="textSaveBtn" onClick={shareHandle}>
                <IconShare useCurrentColor={true} className="icon" />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="btnGroup">
            <Tooltip
              className={'customTooltip'}
              position="bottom"
              trigger="hover"
              content={'回顾历史版本'}>
              <div className="textSaveBtn" onClick={reviewHandle}>
                <IconRecapHistory useCurrentColor={true} className="icon" />
              </div>
            </Tooltip>
            <Tooltip
              className={'customTooltip'}
              position="bottom"
              trigger="hover"
              content={'导出当前版本 Word'}>
              <div className="textSaveBtn" onClick={getDownLoad}>
                <IconDownload useCurrentColor={true} className="icon" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={'mask'}></div>
    </TitleBox>
  )
}

export default React.forwardRef(Title)
