import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import { switchProject } from '@/helpers/switchProject'
import { getCurrentTime } from '@/utils/toolFunction'
import AgentImg from '@/assets/newImgs/aiagent.svg'
import { v1 } from 'uuid'
import request from '@/utils/request'
import useStorage from '@/utils/useStorage'
import Markdown from '../../components/Markdown'
import ModalCustom from '../../components/ModalCustom'
import SharePop from '../../components/SharePop'
import BorderBtn from '../../components/BorderBtn'
import IconBtn from '../../components/IconBtn'
import PromptMod from './PromptMod'
import SetMod from './set'
import { showModal } from '@/share/actions'
import { Decrypt, Encrypt } from './key'
import { Drag } from '../../utils/drag'
import { plugFlowError } from '../../utils/plugFlowError'
import TextMod from './TextMod'
import EntryListMod from './EntryListMod'
import Copy from '../../components/Copy'
import PluginLibrary from './pluginLibrary'
import IconFace1 from '../../assets/imgs/chat-face1.svg'
import IconFace2 from '../../assets/imgs/chat-face2.svg'
import IconAdd from '../../assets/imgs/i-add.svg'
import IconFav from '../../assets/imgs/i-fav.svg'
import {
  Input,
  Divider,
  Select,
  Spin,
  Button,
  Message,
  List,
  Radio,
  Typography,
  Upload,
  Modal,
  Form,
  Drawer,
  Popconfirm,
  Trigger,
  Tooltip,
  Dropdown,
  Menu,
  Space,
} from '@arco-design/web-react'
import {
  IconCopy,
  IconEdit,
  IconPoweroff,
  IconRefresh,
  IconSync,
  IconRobot,
  IconLoading,
  IconPlus,
  IconDelete,
  IconMessage,
  IconSwap,
  IconRedo,
  IconFilePdf,
  IconDriveFile,
  IconStarFill,
  IconLeft,
  IconDownCircle,
  IconFullscreen,
  IconFullscreenExit,
  IconSettings,
} from '@arco-design/web-react/icon'
import {
  IconClose,
  IconSignOut,
  IconVip,
  IconMyOrder,
} from '@arco-iconbox/react-aidb'
import {
  IconLink,
  IconScrollDown,
  IconSmartAssistantLine,
  IconCheck,
  IconPromptLibrary,
  IconSend,
  IconClosure,
  IconWastePaper,
  IconReplace,
  IconUpload,
  IconStop,
  IconShare,
  IconDownload,
  IconMoreEdits,
  IconSuccessTip,
  IconErrorTip,
  IconWarnTip,
  IconDialogue,
  IconFillIn,
  IconLayout,
  IconHelp,
  IconAgent,
  IconCollectFill,
  IconClear,
} from '@arco-iconbox/react-aidb-v2'

import './style/chatGPT.less'
import styles from './style/index.module.less'
import menuStyles from '../../components/HeaderBar/style/index.module.less'
import { isNumber, set } from 'lodash'

import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from '../../store'
import { GetQueryValue } from '@/utils/common'
import { OptionInfo } from '@arco-design/web-react/es/Select/interface'
import { ShowAgentDetail } from '@/model/reference'
import copy from 'copy-to-clipboard'
import UserAvatar from '@/assets/UserAvatar'

const TextArea = Input.TextArea
const FormItem = Form.Item
const RadioGroup = Radio.Group
const Option = Select.Option
const { Text } = Typography

let sendProve = false
let suspend = false
let lastClickTime = 0
let cancelUpload = false
let cancelUrl = false
let isRoll = true
let time = 0
// 第一次渲染之后才允许更新
let isGetItemCurrentListIndex = false

// api前缀
const api = switchProject('DFZ') ? '/api/api-core' : '/api/api-aidb'

// 颜色变量
const color = switchProject('DFZ') ? '#ac1e1e' : '#ff6500'

// 助理名全局修改
let XIAO_WU_35 = switchProject('DFZ') ? '助理小京' : '小悟 3.5'
let XIAO_WU_40 = switchProject('DFZ') ? '助理小方' : '小悟 4.0'
let XIAO_ZHI = switchProject('DFZ') ? '助理小志' : '小智一言'
let XIAO_ZHI_QW = switchProject('DFZ') ? '小智千问' : '小智千问'

// 移动端适配宽度
// let mobileWidth = 680
let mobileWidth = 911

function setSendProve(params: boolean) {
  sendProve = params
}
interface ModRef {
  handleCancel: () => void
  assistantConfigChange: (
    path: Array<string>,
    value: number | boolean | string,
  ) => object
}
// interface ChatGPTInterface {
//   useCurrentColor: boolean
//   special: boolean
// }
function ChatGPT(props) {
  const { special } = props

  const history = useHistory()
  const location = useLocation()
  // 设置信息
  const [assistantConfigInfo, setAssistantConfigInfo] = useState<any>({})
  // console.log(assistantConfigInfo)
  const [userInfo, setUserInfo] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [answerLoading, setAnswerLoading] = useState(false)
  const [sharingConversationsItem, setSharingConversationsItem] = useState({})
  // title——>当前对话名称 user——> gptVersion——>当前对话使用的接口版本(gpt35,) chatHistory——>当前对话记录
  const [drawerChatHistory, setDrawerChatHistory] = useState<any>([
    // {
    //   title: '新建对话',
    //   createTime: getCurrentAllTime(),
    //   gptVersion: 'gpt35',
    //   // gptVersion:
    //   //   configInfo.interface &&
    //   //   configInfo.interface.INTERFACE_DEFAULT_MODEL === 'XIAO_WU_35'
    //   //     ? 'gpt35'
    //   //     : configInfo.interface &&
    //   //       configInfo.interface.INTERFACE_DEFAULT_MODEL === 'XIAO_WU_40'
    //   //     ? 'gpt40'
    //   //     : configInfo.interface &&
    //   //       configInfo.interface.INTERFACE_DEFAULT_MODEL === 'XIAO_ZHI'
    //   //     ? 'wenxin'
    //   //     : 'gpt35',
    //   chatHistory: [
    //     // {
    //     //   user: 'robot',
    //     //   time: getCurrentTime(),
    //     //   description: XIAO_WU_35, // 此次对话助理名称
    //     //   interface: 'gpt35',  // 此次对话使用的接口
    //     //   content:
    //     //     '您好！我是' + XIAO_WU_35 + '，我的主要功能包括回答各种问题、提供准确可信的信息、语音交互、多语言支持和学习能力。\n\n我的优点是可以处理多个任务，例如翻译、生成文本、摘要、代码等，您可以随时向我提出您的问题，我会尽力为您解决。\n\n同时，我也支持多轮连续对话，帮助您更好地获取所需信息。如果您遇到任何问题，可以直接询问我。',
    //     // },
    //   ],
    // },
  ])

  // 计时
  const [displayPause, setDisplayPause] = useState(false)

  // viewed on mobile or desktop browser
  // 助理宽度
  const [width, setWidth] = useState<number>()
  // 屏幕宽度
  // const [screenWidth, setScreenWidth] = useState<number>()
  const isMobile = width <= 768

  const [textQ, setTextQ] = useState('')
  const [placeholder, setPlaceholder] = useState('请输入您的问题...')
  const [messageId, setMessageId] = useState('')
  const [conversationId, setConversationId] = useState('')
  const [messageId40, setMessageId40] = useState('')
  const [conversationId40, setConversationId40] = useState('')
  const [pluginName, setPluginName] = useState('')
  const [pluginId, setPluginId] = useState('')

  const [fileList, setFileList] = useState<any>([])
  // 文件类别 指令库——>by 网址抓取——>wz 文档上传——>wd 为空代表没有进入任何文件
  const [currentFileCategory, setCurrentFileCategory] = useState<string>('')
  const [uri, setUri] = useState('')
  const [url, setUrl] = useState('')
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [disabledUpload, setDisabledUpload] = useState(false)
  const [cancelUpload1, setCancelUpload1] = useState(1)
  const [isBottom, setIsBottom] = useState(false)
  const [isSpin, setIsSpin] = useState(true)
  const [sharingConversationsVisible, setSharingConversationsVisible] =
    useState(false)
  const [form] = Form.useForm()

  // 切换对话显示
  const [drawerVisible, setDrawerVisible] = useState(false)
  // 切换对话中时间轴
  const [timeDifferences, setTimeDifferences] = useState([])
  // 当前在第几个对话中
  const [currentListIndex, setCurrentListIndex] = useState(0)
  // 每次加载更多展示多少条数据
  const [loadMoreNum, setLoadMoreNum] = useState(5)
  const [loadMorePage, setLoadMorePage] = useState(1)

  // 对话列表中用户选择的对话
  const [dialogueListVisibleDelete, setDialogueListVisibleDelete] =
    useState(false)
  const [dialogueListIndex, setDialogueListIndex] = useState(-1)
  const [dialogueListVisibleRename, setDialogueListVisibleRename] =
    useState(false)
  const [dialogueListVisibleTitleValue, setDialogueListVisibleTitleValue] =
    useState('')

  // 切换助理（切换对话中使用）gpt35——>gpt3.5接口 wenxin——>wenxin接口 gpt40——>gpt4.0接口
  const [assistantInterface, setAssistantInterface] = useState('wenxin')
  // 当前接口名称
  const [assistantInterfaceName, setAssistantInterfaceName] = useState(XIAO_ZHI)

  const outContentRef = useRef(null)
  const botListRef = useRef(null)
  const msgInputRef = useRef(null)
  const TSKInputRef = useRef(null)
  const tagsRef = useRef(null)
  const setModRef = useRef<ModRef>()
  const refWrapper = useRef(null)

  //指令库
  const [visiblePrompt, setVisiblePrompt] = useState(false)
  const [list, setList] = useState<any>([])
  const [classifications, seClassifications] = useState<any>([])
  const [prompt, setPrompt] = useState<any>('')
  const [fileId, setFileId] = useState<any>(-1)
  const [active, setActive] = useState([-1, false])
  const [fileInfo, setFileInfo] = useState({ fileName: '文件解析失败' })
  // tags左右滑动函数
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  // 当前系统环境
  const [dev, setDev] = useState('pc')

  const [typeKey, setTypeKey] = useState<any>(
    GetQueryValue('typeKey') ? GetQueryValue('typeKey') : '1',
  )

  //设置是否为智能体
  const [isAgent, setIsAgent] = useState(false)
  const [isAgentInit, setIsAgentInit] = useState(false)

  const theme = useSelector((state: GlobalState) => state.theme)

  const dispatch = useDispatch()

  // 设置变量
  const CONTENT_FONT_SIZE =
    assistantConfigInfo.font && assistantConfigInfo.font.CONTENT_FONT_SIZE
  const TEXTAREA_FONT_SIZE =
    assistantConfigInfo.font && assistantConfigInfo.font.TEXTAREA_FONT_SIZE
  const INTERFACE_CONNECT_LAST_TALK =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.INTERFACE_CONNECT_LAST_TALK
  const INTERFACE_HK_NODE =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.INTERFACE_HK_NODE
  const INTERFACE_DEFAULT_MODEL =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.INTERFACE_DEFAULT_MODEL
  const INTERFACE_OUTLINES =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.websiteCrawlingAndFileUpload
      .INTERFACE_OUTLINES
  const INTERFACE_QUESTIONS =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.websiteCrawlingAndFileUpload
      .INTERFACE_QUESTIONS
  const INTERFACE_TAGS =
    assistantConfigInfo.interface &&
    assistantConfigInfo.interface.websiteCrawlingAndFileUpload.INTERFACE_TAGS
  const IS_MEMORY_OPEN =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.dialogueList.IS_MEMORY_OPEN
  const IS_OPEN_DIALOGUE_LIST =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.dialogueList.IS_OPEN
  const IS_FULL_SCREEN =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.fullScreen.IS_FULL_SCREEN
  const IS_FULL_SCREEN_FIX =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.fullScreen.IS_FULL_SCREEN_FIX
  const CHAT_AREA_WIDTH =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.screenWidth.CHAT_AREA_WIDTH
  const TEXTAREA_AREA_WIDTH =
    assistantConfigInfo.screen &&
    assistantConfigInfo.screen.screenWidth.TEXTAREA_AREA_WIDTH

  useEffect(() => {
    setAssistantInterfaceName(returnInterfaceName(assistantInterface))
  }, [assistantInterface])

  useEffect(() => {
    if (theme == 'light') {
      dispatch({
        type: 'update-theme',
        payload: { theme: 'light' },
      })
    }
    if (theme == 'dark') {
      dispatch({
        type: 'update-theme',
        payload: { theme: 'dark' },
      })
    }
  }, [theme])

  useEffect(() => {
    document.title = '智能助理 - 悟智AI'
  }, [])

  // 当页面显示时判断是否还在状态并且助理历史记录显示
  useEffect(() => {
    request
      .post('/api/api-permission/employee/getUserInfo', { uid: '' })
      .then((res) => {
        if (res.data.code == '500200') {
          const userAgent = navigator.userAgent
          let result = res.data.data

          setUserInfo(result)

          try {
            // 判断用户之前在第几个对话
            let listIndex = 0
            // 历史记录
            let drawerChatHistories = []
            // if (!window.localStorage.getItem('drawerChatHistory')) return

            try {
              try {
                listIndex = JSON.parse(
                  window.localStorage.getItem('currentListIndex'),
                )
                  ? JSON.parse(window.localStorage.getItem('currentListIndex'))
                  : 0
                drawerChatHistories = JSON.parse(
                  Decrypt(
                    window.localStorage.getItem(
                      `${result.id}-drawerChatHistory`,
                    ),
                  ), //加密
                  // window.localStorage.getItem(`${result.id}-drawerChatHistory`), //不加密
                )
                if (listIndex > drawerChatHistories.length - 1) listIndex = 0
              } catch (error) {
                listIndex = 0
                drawerChatHistories = [
                  {
                    title: '新建对话',
                    createTime: getCurrentAllTime(),
                    gptVersion: assistantInterface,
                    chatHistory: [],
                  },
                ]
              }

              if (listIndex) setCurrentListIndex(listIndex)

              console.log(drawerChatHistories, listIndex)

              if (drawerChatHistories && drawerChatHistories.length) {
                // 先创建副本以免修改原始状态
                const newDrawerChatHistory = [...drawerChatHistories]
                // 获取URL中的参数
                const params = new URLSearchParams(
                  new URL(window.location.href).search,
                ).get('q')

                // 获取到当前对话的最后一个对话记录
                let lastCurrentConversation =
                  newDrawerChatHistory[listIndex]?.chatHistory[
                    newDrawerChatHistory[listIndex]?.chatHistory?.length - 1
                  ]

                for (let i = 0; i < newDrawerChatHistory.length; i++) {
                  const chatHistory = newDrawerChatHistory[i]?.chatHistory
                  const chatHistoryGptVersion = newDrawerChatHistory[i]
                  const userAgent = navigator.userAgent

                  // 如果本地对话中没有gptVersion记录，则给出默认的数据，避免项目更新用户报错
                  if (!chatHistoryGptVersion.gptVersion) {
                    newDrawerChatHistory[i].gptVersion = assistantInterface
                  }
                  // 如果本地对话中没有createTime记录，则给出默认的数据，避免项目更新用户报错
                  if (!chatHistoryGptVersion.createTime) {
                    newDrawerChatHistory[i].createTime = getCurrentAllTime()
                  }

                  // 移动端默认只能使用wenxin
                  if (
                    !(userAgent.indexOf('micromessenger') !== -1) &&
                    /Mobile|Android|iP(ad|hone)/i.test(userAgent)
                  ) {
                    if (
                      /Android/i.test(userAgent) &&
                      !/Browser/i.test(userAgent) &&
                      !/wechat/i.test(userAgent)
                    ) {
                      // 安卓端
                      newDrawerChatHistory[i].gptVersion = 'wenxin'
                      setAssistantInterface('wenxin')
                    }
                  }

                  for (let j = 0; j < chatHistory.length; j++) {
                    const item = chatHistory[j]

                    if (item.summary && item.summary.isError === 2) {
                      item.summary = {
                        isError: 1,
                        data: '获取摘要失败，点击刷新重新生成',
                      }
                    }
                    if (
                      item.outlinesArray &&
                      item.outlinesArray.isError === 2
                    ) {
                      item.outlinesArray = {
                        isError: 1,
                        data: '生成大纲失败，点击刷新重新生成！',
                      }
                    }
                    if (
                      item.questionsArray &&
                      item.questionsArray.isError === 2
                    ) {
                      item.questionsArray = {
                        isError: 1,
                        data: '获取相关问题失败，点击刷新重新生成！',
                      }
                    }
                    if (item.tagsArray && item.tagsArray.isError === 2) {
                      item.tagsArray = {
                        isError: 1,
                        data: '获取关键字失败，点击刷新重新生成！',
                      }
                    }
                  }
                }

                // 更新状态
                setDrawerChatHistory(newDrawerChatHistory)

                console.log(newDrawerChatHistory[listIndex]?.gptVersion)

                //设置智能体状态
                if (newDrawerChatHistory[listIndex]?.type == 'agent') {
                  setIsAgent(true)
                  console.log(newDrawerChatHistory[listIndex]?.chatHistory)
                  if (
                    newDrawerChatHistory[listIndex]?.chatHistory?.length == 1
                  ) {
                    setIsAgentInit(true)
                  } else {
                    setIsAgentInit(false)
                  }
                  const vipLevel =
                    localStorage.getItem('vipLevel') == '2'
                      ? XIAO_WU_35
                      : XIAO_WU_40
                  const vipName =
                    localStorage.getItem('vipLevel') == '2' ? 'gpt40' : 'gpt35'
                  returnToDefaultInterface(vipLevel)
                  returnInterfaceName(vipName)
                }

                setAssistantInterface(
                  newDrawerChatHistory[listIndex].gptVersion,
                )

                //智能体更新数据初始化
                if (GetQueryValue('agentUuid')?.length > 0) {
                  //智能体查看详情
                  ShowAgentDetail({ uuid: GetQueryValue('agentUuid') }).then(
                    (res) => {
                      const detail = res.data.data
                      agentOperation(
                        newDrawerChatHistory,
                        detail,
                        GetQueryValue('userName'),
                      )
                    },
                  )
                }

                //这里每次进入请求智能体判断有无更新记录，要给一个定时器，否则会和添加智能体抢进程，导致重复添加
                setTimeout(() => {
                  agentUpdateLocaldata(newDrawerChatHistory)
                }, 500)
                // 第一次渲染之后才允许更新
                setTimeout(() => {
                  isGetItemCurrentListIndex = true
                  setIsSpin(false)
                  scrollBotList()
                }, 100)

                // 若url带参数，则自动发送一次
                if (
                  newDrawerChatHistory?.length == 1 &&
                  newDrawerChatHistory[0]?.chatHistory?.length == 0 &&
                  params
                ) {
                  doApi(params)
                } else if (
                  params &&
                  lastCurrentConversation.sendMessage != params
                ) {
                  // 如果最后已经发送过，则不再发送
                  doApi(params)
                }
              } else {
                setDrawerChatHistory([])
                setIsSpin(false)
              }
            } catch (error) {
              console.log(error)

              setIsSpin(false)

              try {
                window.localStorage.getItem(`${result.id}-drawerChatHistory`) &&
                  JSON.parse(
                    Decrypt(
                      window.localStorage.getItem(
                        `${result.id}-drawerChatHistory`,
                      ),
                    ), //加密
                    // window.localStorage.getItem(
                    //   `${result.id}-drawerChatHistory`,
                    // ),  //不加密
                  )
              } catch (error) {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '历史记录读取失败！',
                })
              }
              return
            }
          } catch (error) {
            setIsSpin(false)
            console.log('error', error)
          }
        }
      })
      .catch((err) => {
        setIsSpin(false)
        console.log(err)
      })
  }, [])

  // 重反到最后一个对话id
  useEffect(() => {
    if (INTERFACE_CONNECT_LAST_TALK) {
      switchToLastId(drawerChatHistory, currentListIndex)
    } else {
      setMessageId('')
      setConversationId('')
      setMessageId40('')
      setConversationId40('')
    }
  }, [INTERFACE_CONNECT_LAST_TALK])

  // 环境判断，屏幕实时监控，
  useEffect(() => {
    // 判断当前环境
    getDeviceType()

    window.addEventListener('resize', handleWindowSizeChange)

    // 屏幕宽度监控
    outContentRef.current && setWidth(outContentRef.current.offsetWidth)

    // 页面显示查询token是否失效
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState == 'visible') {
        focusMsgInput()
        request.post('/api/api-permission/employee/getUserInfo', {
          uid: '',
        })
      }
    })

    // 将localStorage中的值放到输入框中
    localStorage.getItem('ls') &&
    localStorage.getItem('ls') != 'undefined' &&
    localStorage.getItem('ls') != null
      ? setTextQ(localStorage.getItem('ls'))
      : ''

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  // Input聚焦
  useEffect(() => {
    focusMsgInput()
  }, [msgInputRef.current])

  useEffect(() => {
    if (isGetItemCurrentListIndex) {
      window.localStorage.setItem(
        'currentListIndex',
        JSON.stringify(currentListIndex),
      )
    }
  }, [currentListIndex])

  // 对话列表拖动的js
  // useEffect(() => {
  //   setTimeout(() => {
  //     Drag('contentList', 'outList', 'contentList', [], dialogueListDrag)
  //   }, 0)
  // }, [drawerVisible, drawerChatHistory])

  // 将文档传过来的值添加到输入框
  useEffect(() => {
    if (props.selectContentPop) {
      setTextQ(props.selectContentPop)
    }
  }, [props.selectContentPop])

  // 指令库列表
  useEffect(() => {
    let listArr = []
    let project = switchProject('DFZ') ? 'dfz' : 'aidb'

    new Promise((resolve, reject) => {
      request
        .get('https://support.wuz.com.cn/wp-json/acf/v3/options/options')
        .then(async (res) => {
          if (JSON.stringify(res.data.acf) != '{}') {
            const acf = res.data.acf
            // const promptsCatArray = acf['prompts_cat'].split('\r\n')
            // const promptsCatNameArray = acf['prompts_cat_name'].split('\r\n')
            const promptsCatArray = acf.others
              .filter((item) => item.project == project)[0]
              .prompts_cat_include.split('\r\n')
            const promptsCatNameArray = acf.others
              .filter((item) => item.project == project)[0]
              .prompts_cat_name_include.split('\r\n')

            promptsCatArray.forEach((item, index) => {
              listArr.push({
                id: index,
                title: item,
                category: promptsCatNameArray[index],
                children: [],
              })
            })
            await (listArr.length && resolve(''))
          }
        })
    }).then(() => {
      request
        .get(
          'https://support.wuz.com.cn/wp-json/acf/v3/prompt?per_page=9999&order=asc',
        )
        .then((res) => {
          const result = res.data

          if (JSON.stringify(result.acf) != '{}') {
            result.forEach((item1, index1) => {
              item1.acf.cat.forEach((item2, index2) => {
                listArr.forEach((item3, index3) => {
                  if (item2 == item3.category) {
                    item3.children.push({
                      id: index1,
                      title: item1.acf.title,
                      content: item1.acf.prompt,
                      info: item1.acf.info,
                    })
                  }
                })
              })
            })

            listArr.forEach((item, index) => {
              item.id = index
              item.children.forEach((item1, index1) => {
                item1.id = index1
              })
            })

            setList(listArr)
          }
        })
    })
  }, [])

  // chatHistory存储
  useEffect(() => {
    let timer = null

    if (!userInfo.id) return
    // 定时器逻辑
    const handleTimer = () => {
      window.localStorage.setItem(
        `${userInfo.id}-drawerChatHistory`,
        Encrypt(drawerChatHistory), //加密
        // JSON.stringify(drawerChatHistory),  //不加密
      )
      // 将classification值返回成数组
      drawerChatHistory.length &&
        seClassifications(
          drawerChatHistory[currentListIndex].chatHistory.reduce(
            (acc, item) => {
              if (item.classification) {
                return [...acc, [item.classification, item.fileId]] // 如果有classification属性，则将其值和fileId组合为一个数组，并添加到结果数组中
              }
              return acc
            },
            [],
          ),
        )
    }
    // 创建防抖函数
    const debouncedHandleTimer = debounce(handleTimer, 100)

    // 设置定时器
    timer = setTimeout(debouncedHandleTimer, 0)

    // 清除定时器
    return () => {
      clearTimeout(timer)
    }
  }, [drawerChatHistory, currentListIndex])

  // 当输入文字是存储
  useEffect(() => {
    localStorage.setItem('ls', textQ)
  }, [textQ])

  //  判断当前滚动条是否在最底部
  useEffect(() => {
    let newLoadMorePage = loadMorePage
    const handleScroll = debounce((event) => {
      const element = event.target
      if (
        element.scrollHeight - ~~element.scrollTop <
          element.clientHeight + 50 &&
        element.scrollHeight - ~~element.scrollTop > element.clientHeight - 50
      ) {
        setIsBottom(false)
      } else {
        setIsBottom(true)
      }

      // 自动加载历史记录
      if (
        botListRef?.current?.scrollTop <=
        botListRef?.current?.clientTop + 50
      ) {
        let clientHeightOld = botListRef.current.scrollHeight
        newLoadMorePage++

        setLoadMorePage(newLoadMorePage)
        setTimeout(() => {
          let clientHeightNew = botListRef.current.scrollHeight
          botListRef.current.scrollTop = clientHeightNew - clientHeightOld
        }, 10)
      }
    }, 100)

    botListRef.current &&
      botListRef.current.addEventListener('scroll', handleScroll)

    return () => {
      botListRef.current &&
        botListRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [botListRef.current, loadMorePage])

  // 当用户向上翻动时不自动跳到底部
  useEffect(() => {
    if (!botListRef.current) return
    function handleScroll() {
      const element = botListRef.current
      // 判断滚动位置和容器高度是否相等，表示滚动到底部
      if (
        element.scrollHeight - ~~element.scrollTop <
          element.clientHeight + 50 &&
        element.scrollHeight - ~~element.scrollTop > element.clientHeight - 50
      ) {
        // 执行滚动到底部后的逻辑
        // console.log('滚动到底部')
        isRoll = true
      } else {
        // console.log('没有滚动到底部')
        isRoll = false
      }
    }
    const element = botListRef.current
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [drawerChatHistory])

  // 根据不同的tag更变TextArea中的placeholder
  useEffect(() => {
    let fileId = Number(active[0])

    if (pluginId) {
      setPlaceholder(
        `当前正在基于 [ 插件库 ] 下「 ${pluginName} 」回答您的问题...`,
      )
    } else if (fileId < 0 || !active[1]) {
      setPlaceholder('请输入您的问题...')
    } else {
      try {
        let findItem = findFileIdByItem(fileId)
        let fileName = findItem.fileName
        let fileCategory = ''

        switch (findItem.fileCategory) {
          case 'wd':
            fileCategory = '文档'
            break
          case 'wz':
            fileCategory = '网址'
            break
          case 'by':
            fileCategory = '指令库'
            break

          default:
            break
        }

        setPlaceholder(
          `当前正在基于 [ ${fileCategory} ] 下「 ${fileName} 」回答您的问题...`,
        )
      } catch (error) {
        console.log(error)
      }
    }
  }, [active, pluginId])

  // 查找到当前选中的文件信息
  useEffect(() => {
    let findItem = findFileIdByItem(Number(active[0]))
    if (pluginName) {
      setFileInfo({ fileName: pluginName })
    } else if (findItem && findItem.hasOwnProperty('fileName')) {
      setFileInfo(findItem)
    }
  }, [active, pluginName])

  useEffect(() => {
    if (pluginId && active[1]) {
      setActive([-1, false])
    }
  }, [pluginId])

  // 根据当前对话drawerChatHistory形成时间轴，返回
  useEffect(() => {
    try {
      // 当前的时间
      const currentTime = new Date(getCurrentAllTime().replace(/-/g, '/'))
      // 所有对话中的时间
      let dateArray = []

      // 将所有对话中的时间按顺序放入dateArray
      drawerChatHistory.forEach((item, index) => {
        dateArray.push(item.createTime)
      })

      // 将所有对话中的时间与当前的时间做对比，并返回与当前时间相差的信息数组
      const timeDiff = dateArray.map((dateString) => {
        const date = new Date(dateString.replace(/-/g, '/'))

        if (isNaN(date.getTime())) {
          return '无效日期'
        }

        // 判断时间是否早于当前时间
        const isEarlier = date.getTime() < currentTime.getTime()
        const timeDiff = Math.abs(currentTime.getTime() - date.getTime())
        const days = Math.floor(timeDiff / (1000 * 3600 * 24))
        // console.log(dateString.split(' ')[0].split('-')[2])

        if (days === 0) {
          if (
            dateString.split(' ')[0].split('-')[2] ===
            getCurrentAllTime().split(' ')[0].split('-')[2]
          ) {
            return '今日'
            // return 'today'
          } else {
            return '近期'
            // return 'recently'
          }
        } else if (days > 0 && days <= 3) {
          return '近期'
          // return 'recently'
        } else if (days > 3 && days <= 7) {
          return '三天前'
          // return 'three days ago'
        } else if (days > 7 && days <= 30) {
          return '一周前'
          // return 'a week ago'
        } else if (days > 30 && days <= 90) {
          return '一个月前'
          // return 'a month ago'
        } else if (days > 90 && days <= 365) {
          return '三个月前'
          // return 'three months ago'
        } else if (days > 365 && days <= 1095) {
          return '一年前'
          // return 'a year ago'
        } else if (days > 1095) {
          return '三年前'
          // return 'three years ago'
        }
      })

      // 去除连续相同的值，只保留第一个
      const removeDuplicates = (arr) => {
        let result = []
        let count = 0

        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === arr[i - 1] && count > 0) {
            result.push('')
          } else {
            result.push(arr[i])
            if (arr[i] === arr[i + 1]) {
              count++
            } else {
              count = 0
            }
          }
        }

        return result
      }

      // console.log(timeDiff, removeDuplicates(timeDiff))
      setTimeDifferences(removeDuplicates(timeDiff))
    } catch (error) {}
  }, [drawerChatHistory])

  // 初始化判断是否打开对话列表
  useEffect(
    () =>
      dev === 'pc' &&
      !special &&
      IS_MEMORY_OPEN &&
      setDrawerVisible(IS_OPEN_DIALOGUE_LIST),
    [IS_OPEN_DIALOGUE_LIST],
  )

  //智能体详情

  //如果是智能体则push一个聊天列表记录到侧边栏

  // 获取当前所有时间
  function getCurrentAllTime() {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')
    const currentDay = currentDate.getDate().toString().padStart(2, '0')
    const currentHour = currentDate.getHours().toString().padStart(2, '0')
    const currentMinute = currentDate.getMinutes().toString().padStart(2, '0')
    const currentSecond = currentDate.getSeconds().toString().padStart(2, '0')

    return (
      currentYear +
      '-' +
      currentMonth +
      '-' +
      currentDay +
      ' ' +
      currentHour +
      ':' +
      currentMinute +
      ':' +
      currentSecond
    )
    // return '2023-05-13 15:59:08'
  }

  // 判断当前环境
  function getDeviceType() {
    const userAgent = navigator.userAgent
    const isWeixin = userAgent.indexOf('micromessenger') !== -1

    if (isWeixin) {
      // 微信端
      if ((window as any).__wxjs_environment === 'miniprogram') {
        // 微信小程序
        setDev('wxChat')
      } else {
        // 微信浏览器
        setDev('wxBrowser')
      }
    } else {
      if (/Mobile|Android|iP(ad|hone)/i.test(userAgent)) {
        // 移动端
        if (
          /Android/i.test(userAgent) &&
          !/Browser/i.test(userAgent) &&
          !/wechat/i.test(userAgent)
        ) {
          // 安卓app
          setDev('androidApp')
        } else {
          // 移动端browser
          setDev('mobileBrowser')
        }
      } else {
        // PC端browser
        setDev('pc')
      }
    }
  }

  // 判断是否为aidb，且为gpt4.0接口，且为SVIP用户 （返回true则表示报错，即非svip用户）
  const isSvip = (model: string) => {
    try {
      if (
        (model === 'XIAO_WU_40' || model === 'gpt40') &&
        Number(localStorage.getItem('vipLevel')) != 2 &&
        !switchProject('DFZ')
      ) {
        showModal('VipContentModal')
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '您还不是 SVIP 用户，请开通 SVIP 继续使用！',
        })
        // throw '您还不是 SVIP 用户，请开通 SVIP 继续使用！'
        throw new Error('您还不是 SVIP 用户，请开通 SVIP 继续使用！')
      }
    } catch (error) {
      return true
    }
  }

  // 回到默认接口
  const returnToDefaultInterface = (model) => {
    if (!isSvip(model)) {
      if (model === 'XIAO_WU_35') {
        setAssistantInterface('gpt35')
        return 'gpt35'
      } else if (model === 'XIAO_WU_40') {
        setAssistantInterface('gpt40')
        return 'gpt40'
      } else if (model === 'XIAO_ZHI') {
        setAssistantInterface('wenxin')
        return 'wenxin'
      } else if (model === 'XIAO_ZHI_QW') {
        setAssistantInterface('tyqw')
        return 'tyqw'
      }
    } else {
      return model
    }
  }

  // 返回接口名称
  const returnInterfaceName = (assistantInterface) => {
    if (!isSvip(assistantInterface)) {
      if (assistantInterface === 'gpt35') {
        return XIAO_WU_35
      } else if (assistantInterface === 'gpt40') {
        return XIAO_WU_40
      } else if (assistantInterface === 'wenxin') {
        return XIAO_ZHI
      } else if (assistantInterface === 'tyqw') {
        return XIAO_ZHI_QW
      }
    } else {
      return assistantInterface
    }
  }

  // 实时监控智能助理宽度
  function handleWindowSizeChange() {
    outContentRef.current && setWidth(outContentRef.current.offsetWidth)
  }

  // 回答异常
  function catchGPTError(eventSource) {
    setLoading(false)
    setTimeout(() => {
      suspend = false
      setDisplayPause(false)
    }, 10)

    // 加载完最终滚动一次
    scrollBotList()
    if (eventSource) {
      try {
        eventSource.close()
      } catch (error) {}
    }
    focusMsgInput()
  }

  // 聊天滚到底部
  function scrollBotList(force = 0) {
    setTimeout(() => {
      if (!botListRef || !botListRef.current || botListRef.current == null)
        return

      // console.log(
      //   botListRef.current.scrollTop + botListRef.current.offsetHeight
      // );
      // console.log(botListRef.current.scrollHeight);
      // console.log('---------------');

      // // 仅适用于 pc
      // if (!isMobile) {

      // 差距小于 N 的时候，才认为你还处在底部，会自动滚动，否则则视为你在上方，则不继续滚动
      // 即改为如果没有上划，则一直自动换行，有上划则停止滚动。这样方便上划不强制滚下来，也方便需要看的时候自动换行
      // 且不是 force 的情况
      botListRef.current.scrollTop = botListRef.current.scrollHeight
      // if (
      //   botListRef.current.scrollHeight -
      //     (botListRef.current.scrollTop + botListRef.current.offsetHeight) <
      //     100 &&
      //   force == 0
      // ) {
      //   botListRef.current.scrollTop = botListRef.current.scrollHeight
      // } else if (force == 1) {
      //   botListRef.current.scrollTop = botListRef.current.scrollHeight
      // }
      // } else {
      //   console.log('isMobile, scrollTop now.')
      //   // botListRef.current.scrollTop = botListRef.current.scrollHeight;
      //   document.getElementById('botList').scrollTop = document.getElementById('botList').scrollHeight
      //   console.log("document.getElementById('botList').scrollTop: " + document.getElementById('botList').scrollTop)
      //   // console.log('botListRef.current.scrollTop: ' + botListRef.current.scrollTop)
      //   // console.log('botListRef.current.scrollHeight: ' + botListRef.current.scrollHeight)
      // }
    }, 0)
  }

  // 始终保持loadMoreNum条记录
  function keepRecord() {
    return new Promise((resolve, reject) => {
      setLoadMorePage(1)
      resolve('')
    })
  }

  // tags回滚到末尾
  function tagsRefList() {
    if (tagsRef.current) {
      tagsRef.current.scrollLeft = tagsRef.current.scrollWidth
    }
  }

  // tags回滚到指定地方 ——>index：第几个tag
  function tagsReturnTo(index) {
    if (tagsRef.current) {
      tagsRef.current.scrollLeft = 150 * index
    }
  }

  // 输入框聚焦
  function focusMsgInput() {
    if (dev != 'pc') return
    if (msgInputRef.current) {
      msgInputRef.current.focus()
    }
  }

  // 防抖
  function debounce(func, delay) {
    let timerId

    return function (...args) {
      clearTimeout(timerId)

      timerId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  // 发送streamMessage，并返回gpt返回的值
  function sendStreamMessage(input: string, output: string) {
    if (drawerChatHistory[currentListIndex].title !== '新建对话') return

    let sendMes =
      '请将下文取一个尽可能短的标题，直接出内容，不要给任何其他冗余信息，不超过十五个字。内容为：' +
      input +
      '。' +
      output

    request
      .post(
        process.env.REACT_APP_CHATGPT_URL + '/streamMessage',
        JSON.stringify({
          message: sendMes,
          stream: false,
        }),
      )
      .then((res) => {
        console.log('返回:', res)
        if (res.status == 200 && res.data.response) {
          setDrawerChatHistoryData(
            currentListIndex,
            'title',
            res.data.response.replace(/^"*|"*$/g, ''),
          )
        }
      })
      .catch((err) => {
        console.log('err:', err)
      })
  }

  // 给drawerChatHistory添加数据
  function updateDrawerChatHistoryData(index, newChat) {
    if (drawerChatHistory.length) {
      setDrawerChatHistory((prevState) => {
        const updatedState = [...prevState]
        updatedState[index].chatHistory.push(newChat)
        return updatedState
      })
    } else {
      setDrawerChatHistory((prevState) => {
        const updatedState = [...prevState]
        updatedState.push({
          title: '新建对话',
          createTime: getCurrentAllTime(),
          gptVersion: assistantInterface,
          chatHistory: [],
        })
        updatedState[index].chatHistory.push(newChat)
        return updatedState
      })
    }
  }

  // 给drawerChatHistory中的子项某个位置添加数据
  function pushDrawerChatHistory(
    newProperty: string,
    chatHistoryData,
    Index = -1,
  ) {
    // 先创建副本以免修改原始状态
    const newDrawerChatHistory = [...drawerChatHistory]
    let lastItemIndex

    if (newDrawerChatHistory[currentListIndex]) {
      if (Index > -1) {
        lastItemIndex = Index
      } else {
        lastItemIndex =
          newDrawerChatHistory[currentListIndex].chatHistory.length - 1
      }
      newDrawerChatHistory[currentListIndex].chatHistory[lastItemIndex][
        newProperty
      ] = chatHistoryData
      // 更新状态
      setDrawerChatHistory(newDrawerChatHistory)
    }
  }

  // 修改drawerChatHistory中某属性值
  function setDrawerChatHistoryData(index, params, data) {
    setDrawerChatHistory((prevState) => {
      const newDrawerChatHistory = [...prevState]
      newDrawerChatHistory[index] = {
        ...newDrawerChatHistory[index],
        [params]: data,
      }
      return newDrawerChatHistory
    })
  }

  // 删除drawerChatHistory某一个数据
  function delDrawerChatHistoryData(index) {
    const newDrawerChatHistory = [...drawerChatHistory]
    newDrawerChatHistory[currentListIndex].chatHistory.splice(index, 1)
    setDrawerChatHistory(newDrawerChatHistory)
  }

  // 给文件添加fileId方便tags高亮不会出错
  function findLastFileId(index) {
    let chatHistory = drawerChatHistory.length
      ? drawerChatHistory[index].chatHistory
      : []
    let id = 0

    for (let item of chatHistory) {
      if (item.fileId && item.fileId !== '' && item.fileId !== undefined) {
        id = item.fileId + 1
      } else if (item.fileId === 0) {
        id = 1
      }
    }

    return id ? id : 0
  }

  // 查找drawerChatHistory里面的某项中的chatHistory是否包含uri等于我的uri，如果有返回这一项，如果没有，则返回false
  function findIsHaveMeUri(uri) {
    if (!drawerChatHistory.length) return false
    const foundItem = drawerChatHistory[currentListIndex].chatHistory.find(
      (chat) => chat.uri == uri,
    )

    if (foundItem) {
      return foundItem
    } else {
      return false
    }
  }

  // 通过相应的fileId查找记录中对应的item并返回
  function findFileIdByItem(id: number) {
    if (!drawerChatHistory[currentListIndex]) return
    return drawerChatHistory[currentListIndex].chatHistory.find(
      (chat) =>
        !isNaN(chat.fileId) &&
        chat.fileId !== '' &&
        chat.fileId >= 0 &&
        chat.fileId == id,
    )
  }

  // 生成随机字符串
  function generateRandomString(length) {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  // 切换时将重回到最后一个对话信息
  function switchToLastId(drawerChatHistory, index) {
    // 当前对话
    let currentConversation = [
      ...drawerChatHistory[index].chatHistory,
    ].reverse()

    for (let i = 0; i < currentConversation.length; i++) {
      const item = currentConversation[i]
      if (item.conversationId && item.messageId) {
        setMessageId(item.messageId)
        setConversationId(item.conversationId)
        break
      }
    }

    for (let i = 0; i < currentConversation.length; i++) {
      const item = currentConversation[i]
      if (item.conversationId40 && item.messageId40) {
        setMessageId40(item.messageId40)
        setConversationId40(item.conversationId40)
        break
      }
    }
  }

  // 对话列表拖动
  function dialogueListDrag(dragged, drag) {
    // console.log(currentListIndex, dragged, drag)
    if (!isNumber(dragged) || !isNumber(drag) || dragged == drag) return

    const temp = drawerChatHistory[dragged]
    drawerChatHistory.splice(dragged, 1, drawerChatHistory[drag])
    drawerChatHistory.splice(drag, 1, temp)

    if (dragged == currentListIndex) {
      setCurrentListIndex(drag)
    } else if (drag == currentListIndex) {
      setCurrentListIndex(dragged)
    }

    setDrawerChatHistory([...drawerChatHistory])
  }

  const summaryCallback = (summaryUrl, index, uri) => {
    request
      .get(`${summaryUrl}?uri=${uri}`)
      .then((res) => {
        const result = res.data

        if (result.code == '500200') {
          pushDrawerChatHistory(
            'summary',
            { isError: 0, data: result.data.summary },
            index,
          )
        } else {
          result.msg &&
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: result.msg,
            })
          pushDrawerChatHistory(
            'summary',
            { isError: 1, data: '获取摘要失败，点击刷新重新生成' },
            index,
          )
        }
      })
      .catch((error) => {
        pushDrawerChatHistory(
          'summary',
          { isError: 1, data: '获取摘要失败，点击刷新重新生成' },
          index,
        )
        console.log(error)
      })
  }

  const outlinesCallback = (outlinesUrl, index, uri) => {
    request
      .get(`${outlinesUrl}?uri=${uri}`)
      .then((res) => {
        console.log(res)
        let result = res.data

        if (result.code == '500200') {
          pushDrawerChatHistory(
            'outlinesArray',
            { isError: 0, data: result.data.outlines.split('\n') },
            index,
          )
        } else {
          result.msg &&
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: result.msg,
            })
          pushDrawerChatHistory(
            'outlinesArray',
            { isError: 1, data: '生成大纲失败，点击刷新重新生成！' },
            index,
          )
        }
      })
      .catch((error) => {
        pushDrawerChatHistory(
          'outlinesArray',
          { isError: 1, data: '生成大纲失败，点击刷新重新生成！' },
          index,
        )
        console.log(error)
      })
  }

  const questionsCallback = (questionsUrl, index, uri) => {
    request
      .get(`${questionsUrl}?uri=${uri}`)
      .then((res) => {
        // console.log(res)
        let result = res.data

        if (result.code == '500200') {
          pushDrawerChatHistory(
            'questionsArray',
            { isError: 0, data: result.data.questions.split('\n') },
            index,
          )
        } else {
          result.msg &&
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: result.msg,
            })
          pushDrawerChatHistory(
            'questionsArray',
            { isError: 1, data: '获取相关问题失败，点击刷新重新生成！' },
            index,
          )
        }
      })
      .catch((error) => {
        pushDrawerChatHistory(
          'questionsArray',
          { isError: 1, data: '获取相关问题失败，点击刷新重新生成！' },
          index,
        )
        console.log(error)
      })
  }

  const tagsCallback = (tagsUrl, index, uri) => {
    request
      .get(`${tagsUrl}?uri=${uri}`)
      .then((res) => {
        console.log(res)
        let result = res.data

        if (result.code == '500200') {
          pushDrawerChatHistory(
            'tagsArray',
            { isError: 0, data: result.data.tags.split('\n') },
            index,
          )
        } else {
          result.msg &&
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: result.msg,
            })
          pushDrawerChatHistory(
            'tagsArray',
            { isError: 1, data: '获取关键字失败，点击刷新重新生成！' },
            index,
          )
        }
      })
      .catch((error) => {
        pushDrawerChatHistory(
          'tagsArray',
          { isError: 1, data: '获取关键字失败，点击刷新重新生成！' },
          index,
        )
        console.log(error)
      })
  }

  //智能体新建对话
  function agentOperation(newDrawerChatHistory, detail, userName) {
    // 设置VIP
    const vipLevel =
      localStorage.getItem('vipLevel') == '2' ? XIAO_WU_35 : XIAO_WU_40
    const vipName = localStorage.getItem('vipLevel') == '2' ? 'gpt40' : 'gpt35'
    returnToDefaultInterface(vipLevel)
    returnInterfaceName(vipName)
    setAssistantInterfaceName(vipLevel)
    setAssistantInterface(vipName)

    // 获取当前agentUuid和templateId
    const currentAgentUuid = GetQueryValue('agentUuid')
    const currentTemplateId = GetQueryValue('templateId')

    // 查找是否存在相同的templateId
    const existingAgentIndex = newDrawerChatHistory.findIndex(
      (agent) => agent.templateId == currentTemplateId,
    )

    // 删除路由上的参数
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('agentUuid')
    searchParams.delete('templateId')
    searchParams.delete('userName')
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    // 如果存在相同的templateId，则设置当前列表索引并返回
    if (existingAgentIndex !== -1) {
      setCurrentListIndex(existingAgentIndex)
      console.log(newDrawerChatHistory[existingAgentIndex]?.chatHistory?.length)

      if (newDrawerChatHistory[existingAgentIndex]?.chatHistory?.length == 1) {
        setIsAgentInit(true)
      }
      return
    }

    // 更新conversationId和messageId
    if (drawerChatHistory) {
      setConversationId('')
      setMessageId('')
    }
    console.log(detail)

    // 插入新的聊天记录
    newDrawerChatHistory.unshift({
      title: detail.name,
      templateId: detail.templateId,
      templateUuid: detail.templateUuid,
      type: 'agent',
      faceImg: detail.faceImg,
      instruct: detail.instructions,
      info: detail.description,
      conversationStarters: detail.conversationStarters || ['', '', ''],
      agentUuid: currentAgentUuid,
      createTime: getCurrentAllTime(),
      gptVersion: vipName,
      publishType: detail.type,
      userName: userName,
      isCollect: detail.isCollected,
      chatHistory: [
        {
          user: 'robot',
          time: getCurrentTime(),
          description: detail.name,
          interface: dev == 'androidApp' ? 'wenxin' : vipName,
          content: '您好！我是' + detail.name + detail.description,
          type: 'agent',
          faceImg: detail.faceImg,
        },
      ],
    })

    // 更新DrawerChatHistory
    setDrawerChatHistory(newDrawerChatHistory)
    setIsAgent(true)
    setIsAgentInit(true)
    setCurrentListIndex(0)
  }

  //初次进入判断智能体更新
  function agentUpdateLocaldata(data: any[]) {
    // 首先判断是否存在 type 为 'agent' 的项
    const hasAgent = data.some((item) => item.type === 'agent')

    if (!hasAgent) {
      // 如果没有 'agent' 项，则终止函数执行
      console.log('No agent found')
      return
    }
    const oldData = data
    console.log(oldData, 'old')

    // 设置VIP
    const vipLevel =
      localStorage.getItem('vipLevel') === '2' ? XIAO_WU_35 : XIAO_WU_40
    const vipName = localStorage.getItem('vipLevel') === '2' ? 'gpt40' : 'gpt35'
    returnToDefaultInterface(vipLevel)
    returnInterfaceName(vipName)
    // setAssistantInterfaceName(vipLevel)
    // setAssistantInterface(vipName)

    // 遍历 data 数组，筛选出含有 templateUuid 和 agentUuid 的对象
    const filteredData = data.filter(
      (item) => item.templateUuid && item.agentUuid,
    )

    // 使用 Promise.all 处理所有异步请求
    const updatePromises = filteredData.map((item, index) => {
      const originalIndex = data.indexOf(item) // 获取原始索引
      return ShowAgentDetail({ templateUuid: item.templateUuid })
        .then((res) => {
          const detail = res.data.data
          // 处理接口返回的 success 为 false 且 msg 为 '智能体不存在' 的情况
          if (!res.data.success && res.data.msg === '智能体不存在') {
            return { ...item, agentNotExist: true, originalIndex } // 标记为 agentNotExist 并保留原始索引
          }

          // 对比接口返回的 uuid 和本地存储的 agentUuid
          if (
            detail.uuid !== item.agentUuid ||
            detail.isCollected != item.isCollect
          ) {
            if (data[currentListIndex].type === 'agent') {
              setIsAgent(true)
              setIsAgentInit(true)
            }
            debouncedShowMessage()

            return {
              ...item,
              title: detail.name,
              templateId: detail.templateId,
              templateUuid: detail.templateUuid,
              type: 'agent',
              faceImg: detail.faceImg,
              instruct: detail.instructions,
              info: detail.description,
              conversationStarters: detail.conversationStarters || ['', '', ''],
              agentUuid: detail.uuid,
              createTime: getCurrentAllTime(),
              gptVersion: vipName,
              publishType: detail.type,
              isCollect: detail.isCollected,
              chatHistory: [
                {
                  user: 'robot',
                  time: getCurrentTime(),
                  description: detail.name,
                  interface: 'androidApp' === 'androidApp' ? 'wenxin' : vipName,
                  content: '您好！我是' + detail.name + detail.description,
                  type: 'agent',
                  faceImg: detail.faceImg,
                },
              ],
              originalIndex, // 保留原始索引
            }
          }
          return { ...item, originalIndex } // 返回原始 item，并保留原始索引
        })
        .catch((err) => {
          console.error(
            `Error fetching detail for templateUuid: ${item.templateUuid}`,
            err,
          )
          return { ...item, originalIndex } // 返回原始 item，即使请求失败，并保留原始索引
        })
    })

    // 判断两个对象是否相等的函数
    const areObjectsEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    // 等待所有异步操作完成并一次性更新状态
    Promise.all(updatePromises)
      .then((updatedItems) => {
        console.log(updatedItems, 'updatedItems')
        console.log(oldData, 'oldData')

        const hasUpdates = updatedItems.some(
          (item, index) => !areObjectsEqual(item, data[item.originalIndex]),
        )
        if (hasUpdates) {
          console.log(drawerChatHistory)

          setDrawerChatHistory((prevData) => {
            // 创建一个新数组，更新旧数据并保留未更新的条目
            const newData = [...prevData]
            updatedItems.forEach((updatedItem) => {
              newData[updatedItem.originalIndex] = updatedItem
            })

            delAgent(newData)
            return newData
          })
        } else {
          console.log('No updates found')
        }
      })
      .catch((err) => {
        console.error('Error updating local data:', err)
      })
  }

  //过滤掉已经下架的智能体
  const delAgent = (newData) => {
    const filteredData = newData.filter(
      (item) =>
        (item.publishType !== 1 ||
          item.userName == localStorage.getItem('userName')) &&
        !item.agentNotExist,
    )
    console.log(newData, 'newdata')
    console.log(filteredData, 'filteredData')

    if (newData.length != filteredData.length) {
      setDrawerChatHistory(filteredData)
      if (filteredData[0].type === 'agent') {
        setCurrentListIndex(0)
        setIsAgent(true)
        if (newData[0]?.chatHistory?.length == 1) {
          setIsAgentInit(true)
        } else {
          setIsAgentInit(false)
        }
      } else {
        setIsAgent(false)
        setIsAgentInit(false)
      }
    }
  }

  // 定义防抖函数
  const debouncedShowMessage = debounce(() => {
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '智能体数据已更新',
    })
  }, 500)

  //分享链接

  const getDoShareLinkHandle = async (linkUuid) => {
    try {
      const response = await fetch(
        'https://cors.v-dk.com/https://go.wuz.com.cn/api/set.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(linkUuid),
        },
      )

      if (response.ok) {
        const previewData = await response.json()
        copy(previewData.content.url)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '已复制分享链接',
        })
      } else {
        // 请求失败
        console.error('set.php 请求失败')
      }
    } catch (error) {
      console.error('set.php 请求出错', error)
    }
  }

  /**
   * 消息发送 ——> message：发送的消息数据 prefix：发送消息时需要携带的前缀  isReanswer：当发送消息时是否不需要发送问题消息到消息记录  reanswerObj：包含之前到记录信息
   * */
  function doApi(message, prefix?: string, isReanswer?: boolean, reanswerObj?) {
    let url
    let meTime = getCurrentTime()
    let tmpText = ''
    let sendMessage = message ? message : textQ
    let code503 = false
    let flag = true

    if (reanswerObj) {
      if (reanswerObj.type === 'GPT35') {
        url = '/streamMessage'
      } else if (reanswerObj.type === 'GPT40') {
        url = '/streamMessageGPT4'
      }
    } else {
      if (assistantInterface === 'gpt35') {
        url = '/streamMessage'
      } else if (assistantInterface === 'gpt40') {
        url = '/streamMessageGPT4'
      }
    }
    // 始终保持loadMoreNum条记录
    keepRecord().then(() => {
      setLoading(true)
      if (!isReanswer) {
        const tmpMe = {
          user: 'user',
          time: meTime,
          description: '我',
          interface: assistantInterface,
          content: sendMessage,
          reanswer:
            assistantInterface === 'gpt35'
              ? {
                  type: 'GPT35', // 刷新重答时走GPT35接口
                  params: {
                    prompt,
                    prefix,
                    messageId: messageId,
                    conversationId: conversationId,
                  },
                }
              : assistantInterface === 'gpt40'
              ? {
                  type: 'GPT40', // 刷新重答时走GPT40接口
                  params: {
                    prompt,
                    prefix,
                    messageId40: messageId40,
                    conversationId40: conversationId40,
                  },
                }
              : {},
        }

        setIsAgentInit(false)
        updateDrawerChatHistoryData(currentListIndex, tmpMe)
      }
      setTextQ('')
      scrollBotList()

      /// new start
      const opts = isReanswer
        ? {
            openWhenHidden: true,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
              reanswerObj.type === 'GPT35'
                ? {
                    message: reanswerObj.params.prompt
                      ? reanswerObj.params.prompt + sendMessage
                      : reanswerObj.params.prefix
                      ? reanswerObj.params.prefix + sendMessage
                      : '' + sendMessage,
                    conversationId: reanswerObj.params.conversationId,
                    parentMessageId: reanswerObj.params.messageId,
                    stream: true,
                    ...(isAgent && {
                      agentUuid: drawerChatHistory[currentListIndex].agentUuid,
                    }), // 条件地包括 agentUuid
                  }
                : reanswerObj.type === 'GPT40'
                ? {
                    message: reanswerObj.params.prompt
                      ? reanswerObj.params.prompt + sendMessage
                      : reanswerObj.params.prefix
                      ? reanswerObj.params.prefix + sendMessage
                      : '' + sendMessage,
                    conversationId: reanswerObj.params.conversationId40,
                    parentMessageId: reanswerObj.params.messageId40,
                    stream: true,
                    ...(isAgent && {
                      agentUuid: drawerChatHistory[currentListIndex].agentUuid,
                    }), // 条件地包括 agentUuid
                  }
                : {},
            ),
          }
        : {
            // deal with: In the process of onmessage, switch to another pages, it will resend the request. ref: https://github.com/Azure/fetch-event-source/issues/17
            openWhenHidden: true,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
              assistantInterface === 'gpt35'
                ? {
                    message: prompt
                      ? prompt + sendMessage
                      : prefix
                      ? prefix + sendMessage
                      : '' + sendMessage,
                    parentMessageId: messageId,
                    conversationId,
                    stream: true,
                    ...(isAgent && {
                      agentUuid: drawerChatHistory[currentListIndex].agentUuid,
                    }), // 条件地包括 agentUuid
                  }
                : assistantInterface === 'gpt40'
                ? {
                    message: prompt
                      ? prompt + sendMessage
                      : prefix
                      ? prefix + sendMessage
                      : '' + sendMessage,
                    parentMessageId: messageId40,
                    conversationId: conversationId40,
                    stream: true,
                    ...(isAgent && {
                      agentUuid: drawerChatHistory[currentListIndex].agentUuid,
                    }), // 条件地包括 agentUuid
                  }
                : {},
            ),
          }

      try {
        // let reply = '';
        const controller = new AbortController()
        const eventSource = fetchEventSource(
          process.env.REACT_APP_CHATGPT_URL + url,
          {
            ...opts,
            signal: controller.signal,
            onclose() {
              // if the server closes the connection unexpectedly,
              // 此处还是要开，如果连接瞬间被关闭(如网络错误，或抛{"data":{"id":0,"text":"[OUT_OF_DAY_FLOW]"}}等)
              // 会触发这个事件，否则不 throw 的话不会进入 onerror(), 则后续的一系列错误判定和 retry 都不会执行
              // Message.error('接口发生错误或超时未响应，请稍后重试#04')
              catchGPTError(false)
              // trigger onerror()
              throw new Error(
                `Failed to send message. Server closed the connection unexpectedly.`,
              )
            },
            onerror(error) {
              let currentMessageId
              let currentConversationId
              // 服务器或网络错误处理

              // setErrorMessage(t['register.form.register.errMsg']);
              console.log('error', error)
              // Message.error({
              //   icon: <IconErrorTip useCurrentColor={false} />,
              //   content: '接口请求错误！',
              // })
              // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');

              if (reanswerObj) {
                if (reanswerObj.type === 'GPT35') {
                  currentMessageId = reanswerObj.params.messageId
                  currentConversationId = reanswerObj.params.conversationId
                } else if (reanswerObj.type === 'GPT40') {
                  currentMessageId = reanswerObj.params.messageId40
                  currentConversationId = reanswerObj.params.conversationId40
                }
              } else {
                if (assistantInterface === 'gpt35') {
                  currentMessageId = messageId
                  currentConversationId = conversationId
                } else if (assistantInterface === 'gpt40') {
                  currentMessageId = messageId40
                  currentConversationId = conversationId40
                }
              }

              // ! catch sse bug which needed.
              request
                .post(
                  process.env.REACT_APP_CHATGPT_URL + url,
                  JSON.stringify({
                    message: sendMessage,
                    parentMessageId: currentMessageId,
                    conversationId: currentConversationId,
                    stream: true,
                    ...(isAgent && {
                      agentUuid: drawerChatHistory[currentListIndex].agentUuid,
                    }), // 条件地包括 agentUuid
                  }),
                )
                .then((res) => {
                  // const { success, error, message } = res.data;
                  console.log('第二接口返回:', res)

                  // 第一个接口请求失败，将这个接口的数据返回
                  try {
                    // 查找 JSON 对象的开始位置
                    let start = res.data.indexOf('{')
                    // 查找 JSON 对象的结束位置
                    let end = res.data.lastIndexOf('}') + 1
                    // 提取 JSON 字符串部分
                    let jsonStr = res.data.substring(start, end)
                    // 解析 JSON 字符串为对象
                    let obj = JSON.parse(jsonStr)

                    if (JSON.stringify(obj) != '{}') {
                      // console.log('xx', obj)
                      if (obj.response && !obj.response.includes('error')) {
                        setDrawerChatHistory((prevDrawerChatHistory) => {
                          const updatedDrawerChatHistory = [
                            ...prevDrawerChatHistory,
                          ]

                          if (reanswerObj) {
                            if (reanswerObj.type === 'GPT35') {
                              updatedDrawerChatHistory[
                                currentListIndex
                              ].chatHistory.push({
                                user: 'robot',
                                time: getCurrentTime(),
                                description: XIAO_WU_35,
                                interface: 'gpt35',
                                content: obj.response.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                                messageId: obj.messageId ? obj.messageId : '',
                                conversationId: obj.conversationId
                                  ? obj.conversationId
                                  : '',
                                fileId: fileId > -1 ? fileId : '',
                                sendMessage,
                              })
                            } else if (reanswerObj.type === 'GPT40') {
                              currentMessageId = reanswerObj.params.messageId40
                              currentConversationId =
                                reanswerObj.params.conversationId40
                            }
                          } else {
                            if (assistantInterface === 'gpt35') {
                              updatedDrawerChatHistory[
                                currentListIndex
                              ].chatHistory.push({
                                user: 'robot',
                                time: getCurrentTime(),
                                description: XIAO_WU_40,
                                interface: 'gpt40',
                                content: obj.response.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                                messageId: obj.messageId ? obj.messageId : '',
                                conversationId: obj.conversationId
                                  ? obj.conversationId
                                  : '',
                                fileId: fileId > -1 ? fileId : '',
                                sendMessage,
                              })
                            } else if (assistantInterface === 'gpt40') {
                              updatedDrawerChatHistory[
                                currentListIndex
                              ].chatHistory.push({
                                user: 'robot',
                                time: getCurrentTime(),
                                description: assistantInterfaceName,
                                interface: 'gpt35',
                                content: obj.response.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                                messageId40: obj.messageId ? obj.messageId : '',
                                conversationId40: obj.conversationId
                                  ? obj.conversationId
                                  : '',
                                fileId: fileId > -1 ? fileId : '',
                                sendMessage,
                              })
                            }
                          }

                          return updatedDrawerChatHistory
                        })
                        setTimeout(() => {
                          // 加载完最终滚动一次
                          scrollBotList()
                        }, 200)
                      } else {
                        throw new Error()
                      }
                      if (obj.conversationId) {
                        if (assistantInterface === 'gpt35') {
                          setConversationId(obj.conversationId)
                        } else if (assistantInterface === 'gpt40') {
                          setConversationId40(obj.conversationId)
                        }
                      } else {
                        throw new Error()
                      }

                      if (obj.messageId) {
                        if (assistantInterface === 'gpt35') {
                          setMessageId(obj.conversationId)
                        } else if (assistantInterface === 'gpt40') {
                          setMessageId40(obj.conversationId)
                        }
                      } else {
                        throw new Error()
                      }
                    }
                  } catch (error) {
                    // console.log('error', error)
                    // Message.error({
                    //    icon: <IconErrorTip useCurrentColor={false} />,
                    //   content: '接口请求错误！',
                    // })
                  }

                  setTimeout(() => {
                    suspend = false
                    setDisplayPause(false)
                  }, 10)

                  const error = res.data
                  console.log(error.data)
                  // todo 流控等提示
                  if (error.data.text) {
                    console.log(error.data.text)

                    // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                    // 异常预处理
                    plugFlowError(
                      eventSource,
                      catchGPTError,
                      error.data.text,
                      error.data.id,
                    )
                  }
                })
                .catch((err) => {
                  console.log('err:', err)
                })

              catchGPTError(eventSource)
              throw error
            },
            onmessage(message) {
              // { data: 'Hello', event: '', id: '', retry: undefined }
              // setToggleCursor(true);
              // console.log('返回：' + JSON.stringify(message))
              // console.log('返回id：' + message.id)
              // console.log('返回data：' + message.data)
              // console.log('返回event：' + message.event)
              // console.log('part return message：')
              // console.log(message)
              // console.log('------------------------------')

              // if (message.data == '[DONE]') {

              // 倒数第二句话，即全文
              // 等待解决 workaround，早晚摘除 message.data !== '[DONE]' 后重新捋逻辑
              if (message.event == 'result' && message.data !== '[DONE]') {
                tmpText = JSON.parse(message.data).response
              } else {
                if (message.data && JSON.parse(message.data).code == '503') {
                  console.log('message.data 503:', message.data)
                  code503 = true
                } else {
                  tmpText =
                    message.data == '[DONE]'
                      ? tmpText
                      : tmpText +
                        message.data.substring(1, message.data.length - 1)
                }
              }

              // 当开始打印字体的时候，将按钮改变为可暂停状态，并且禁止滚动条滚动
              if (!displayPause && !suspend) setDisplayPause(true)

              let tmpChatting

              if (reanswerObj) {
                if (reanswerObj.type === 'GPT35') {
                  tmpChatting = {
                    user: 'robot',
                    time: getCurrentTime(),
                    description:
                      drawerChatHistory[currentListIndex].type === 'agent'
                        ? drawerChatHistory[currentListIndex].title
                        : XIAO_WU_35,
                    interface: 'gpt35',
                    content: tmpText
                      .replaceAll('\\n', ' \n ')
                      .replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
                    sendMessage, // 去转义，否则 chatting 会直出 \n
                    meTime, // 去转义，否则 chatting 会直出 \n
                    messageId: reanswerObj.params.messageId,
                    conversationId: reanswerObj.params.conversationId,
                    ...(drawerChatHistory[currentListIndex]?.type ===
                      'agent' && {
                      faceImg: drawerChatHistory[currentListIndex].faceImg,
                      type: 'agent',
                    }),
                  }
                } else if (reanswerObj.type === 'GPT40') {
                  tmpChatting = {
                    user: 'robot',
                    time: getCurrentTime(),
                    description:
                      drawerChatHistory[currentListIndex].type === 'agent'
                        ? drawerChatHistory[currentListIndex].title
                        : XIAO_WU_40,
                    interface: 'gpt40',
                    content: tmpText
                      .replaceAll('\\n', ' \n ')
                      .replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
                    sendMessage, // 去转义，否则 chatting 会直出 \n
                    meTime, // 去转义，否则 chatting 会直出 \n
                    messageId40: reanswerObj.params.messageId40,
                    conversationId40: reanswerObj.params.conversationId40,
                    ...(drawerChatHistory[currentListIndex]?.type ===
                      'agent' && {
                      faceImg: drawerChatHistory[currentListIndex].faceImg,
                      type: 'agent',
                    }),
                  }
                }
              } else {
                if (assistantInterface === 'gpt35') {
                  tmpChatting = {
                    user: 'robot',
                    time: getCurrentTime(),
                    description:
                      drawerChatHistory[currentListIndex].type === 'agent'
                        ? drawerChatHistory[currentListIndex].title
                        : assistantInterfaceName,
                    interface: assistantInterface,
                    content: tmpText
                      .replaceAll('\\n', ' \n ')
                      .replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
                    sendMessage, // 去转义，否则 chatting 会直出 \n
                    meTime, // 去转义，否则 chatting 会直出 \n
                    messageId: JSON.parse(message.data).messageId
                      ? JSON.parse(message.data).messageId
                      : '',
                    conversationId: JSON.parse(message.data).conversationId
                      ? JSON.parse(message.data).conversationId
                      : '',
                    fileId: fileId > -1 ? fileId : '',
                    ...(drawerChatHistory[currentListIndex]?.type ===
                      'agent' && {
                      faceImg: drawerChatHistory[currentListIndex].faceImg,
                      type: 'agent',
                    }),
                  }
                } else if (assistantInterface === 'gpt40') {
                  tmpChatting = {
                    user: 'robot',
                    time: getCurrentTime(),
                    description:
                      drawerChatHistory[currentListIndex].type === 'agent'
                        ? drawerChatHistory[currentListIndex].title
                        : assistantInterfaceName,
                    interface: assistantInterface,
                    content: tmpText
                      .replaceAll('\\n', ' \n ')
                      .replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
                    sendMessage, // 去转义，否则 chatting 会直出 \n
                    meTime, // 去转义，否则 chatting 会直出 \n
                    messageId40: JSON.parse(message.data).messageId
                      ? JSON.parse(message.data).messageId
                      : '',
                    conversationId40: JSON.parse(message.data).conversationId
                      ? JSON.parse(message.data).conversationId
                      : '',
                    fileId: fileId > -1 ? fileId : '',
                    ...(drawerChatHistory[currentListIndex]?.type ===
                      'agent' && {
                      faceImg: drawerChatHistory[currentListIndex].faceImg,
                      type: 'agent',
                    }),
                  }
                }
              }

              // 异常预处理
              plugFlowError(
                eventSource,
                catchGPTError,
                message.data,
                message.id,
              )

              // 倒数第二条时给 done 逻辑
              if (message.event == 'result' || suspend || code503) {
                setTimeout(() => {
                  suspend = false
                  setDisplayPause(false)
                }, 50)

                controller.abort()
                // console.log(message)

                // [X]catchGPTError(eventSource) 这里照顾到移动端，不统一处理了

                setLoading(false)

                if (!isMobile) focusMsgInput()

                // 结束时再设置相关 id
                const tmpJson = JSON.parse(message.data)

                if (tmpChatting.content != '') {
                  setDrawerChatHistory((prevDrawerChatHistory) => {
                    const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                    if (flag) {
                      updatedDrawerChatHistory[
                        currentListIndex
                      ].chatHistory.push(tmpChatting)
                      flag = false
                    } else {
                      updatedDrawerChatHistory[currentListIndex].chatHistory[
                        updatedDrawerChatHistory[currentListIndex].chatHistory
                          .length - 1
                      ] = tmpChatting
                    }
                    return updatedDrawerChatHistory
                  })
                  setTimeout(() => {
                    // 加载完最终滚动一次
                    scrollBotList()
                    // 始终保持loadMoreNum条记录
                    keepRecord()
                  }, 200)

                  // console.log(tmpJson)

                  if (tmpJson.conversationId) {
                    if (assistantInterface === 'gpt35') {
                      console.log(
                        'gpt3.5的conversationId：' + tmpJson.conversationId,
                      )
                      setConversationId(tmpJson.conversationId)
                      pushDrawerChatHistory(
                        'conversationId',
                        tmpJson.conversationId,
                      )
                    } else if (assistantInterface === 'gpt40') {
                      console.log(
                        'gpt4.0的conversationId：' + tmpJson.conversationId,
                      )
                      setConversationId40(tmpJson.conversationId)
                      pushDrawerChatHistory(
                        'conversationId40',
                        tmpJson.conversationId,
                      )
                    }
                  }

                  if (tmpJson.messageId) {
                    if (assistantInterface === 'gpt35') {
                      console.log('gpt3.5的messageId：' + tmpJson.messageId)
                      setMessageId(tmpJson.messageId)
                      pushDrawerChatHistory('messageId', tmpJson.messageId)
                    } else if (assistantInterface === 'gpt40') {
                      console.log('gpt4.0的messageId：' + tmpJson.messageId)
                      pushDrawerChatHistory('messageId40', tmpJson.messageId)
                      setMessageId40(tmpJson.messageId)
                    }
                  }

                  if (tmpJson.conversationId && tmpJson.messageId) {
                    request
                      .post(api + '/chatGPT/chatSave', {
                        input: sendMessage,
                        output: tmpJson.response,
                        messageId: tmpJson.messageId,
                        conversationId: tmpJson.conversationId,
                        type: 'openai',
                        model:
                          assistantInterface === 'gpt35'
                            ? 'gpt-3.5'
                            : assistantInterface === 'gpt40'
                            ? 'gpt-4.0'
                            : '',
                        ...(isAgent && {
                          agentId:
                            drawerChatHistory[currentListIndex].templateId,
                        }), // 只有 isAgent 为 true 时才添加 agentUuid
                      })
                      .then((res) => {
                        // console.log(res)
                      })
                  }

                  // 若未修改当前对话名称，则自动修改
                  sendStreamMessage(sendMessage, tmpJson.response)
                } else {
                  Message.error({
                    icon: <IconErrorTip useCurrentColor={false} />,
                    content: '系统错误，请稍后重试！',
                  })
                }
                return
              } else if (message.data !== '[DONE]') {
                // 如果用户向上滑动则不更新
                if (!isRoll) return
                // 更新数据
                setDrawerChatHistory((prevDrawerChatHistory) => {
                  const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                  if (flag) {
                    updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                      tmpChatting,
                    )
                    flag = false
                  } else {
                    updatedDrawerChatHistory[currentListIndex].chatHistory[
                      updatedDrawerChatHistory[currentListIndex].chatHistory
                        .length - 1
                    ] = tmpChatting
                  }
                  return updatedDrawerChatHistory
                })

                scrollBotList()
              }
            },
          },
        )
        // console.log(reply);
      } catch (err) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '接口发生错误或超时未响应，请稍后重试#05',
        })
        catchGPTError(false)
        console.log('ERROR', err)
      }
    })
  }

  // wenxin接口发送
  function doWenXinApi(message, prefix?: string, isReanswer?: boolean) {
    let tmpText = ''
    let meTime = getCurrentTime()
    let sendMessage = message ? message : textQ
    let randomString = generateRandomString(18)
    let sendList = []
    let code503 = false
    let flag = true

    setTextQ('')
    setLoading(true)
    scrollBotList()

    if (!isReanswer) {
      const tmpMe = {
        user: 'user',
        time: meTime,
        description: '我',
        interface: 'wenxin',
        content: sendMessage,
        reanswer: {
          type: 'WENXIN', // 刷新重答时走WENXIN接口
        },
      }

      updateDrawerChatHistoryData(currentListIndex, tmpMe)
    }

    // 整理数据
    drawerChatHistory[currentListIndex].chatHistory.forEach((item, index) => {
      if (item.interface == 'wenxin') {
        sendList.push({
          role: item.user == 'user' ? 'user' : 'assistant',
          content: item.content,
        })
      }
      // drawerChatHistory更新不及时，因此需要自行push
      if (index == drawerChatHistory[currentListIndex].chatHistory.length - 1) {
        sendList.push({
          role: 'user',
          content: prompt
            ? prompt + sendMessage
            : prefix
            ? prefix + sendMessage
            : sendMessage,
        })
      }
    })

    // 对sendList进行整理，始终保持一问一答
    function spliceSendList(list) {
      let resultArr = [...list]
      let i = 0

      while (i < resultArr.length) {
        let currentRole = resultArr[i].role

        if (currentRole === 'user') {
          let startIndex = i
          let endIndex = i + 1
          while (
            endIndex < resultArr.length &&
            resultArr[endIndex].role === 'user'
          ) {
            endIndex++
          }
          let deleteCount = endIndex - startIndex - 1
          resultArr.splice(startIndex, deleteCount)
        } else if (currentRole === 'assistant') {
          let startIndex = i
          let endIndex = i + 1
          while (
            endIndex < resultArr.length &&
            resultArr[endIndex].role === 'assistant'
          ) {
            endIndex++
          }
          let deleteCount = endIndex - startIndex - 1
          resultArr.splice(startIndex, deleteCount)
        }

        i++
      }

      return resultArr
    }

    const opts = {
      // deal with: In the process of onmessage, switch to another pages, it will resend the request. ref: https://github.com/Azure/fetch-event-source/issues/17
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: spliceSendList(sendList),
        model: 'ERNIE-Speed-128K',
        stream: true,
        user_id: userInfo.uid,
      }),
    }

    try {
      // let reply = '';
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        process.env.REACT_APP_WENXIN_URL + '/ernieForStream',
        {
          ...opts,
          signal: controller.signal,
          onclose() {
            // if the server closes the connection unexpectedly,
            // 此处还是要开，如果连接瞬间被关闭(如网络错误，或抛{"data":{"id":0,"text":"[OUT_OF_DAY_FLOW]"}}等)
            // 会触发这个事件，否则不 throw 的话不会进入 onerror(), 则后续的一系列错误判定和 retry 都不会执行
            // Message.error('接口发生错误或超时未响应，请稍后重试#04')
            catchGPTError(false)
            // trigger onerror()
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            // 服务器或网络错误处理

            // setErrorMessage(t['register.form.register.errMsg']);
            console.log('error', error)
            // Message.error({
            //   icon: <IconErrorTip useCurrentColor={false} />,
            //   content: '接口请求错误！',
            // })
            // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');

            // ! catch sse bug which needed.
            request
              .post(
                process.env.REACT_APP_WENXIN_URL + '/ernieForStream',
                JSON.stringify({
                  messages: spliceSendList(sendList),
                  model: 'ERNIE-Speed-128K',
                  stream: true,
                  user_id: userInfo.uid,
                }),
              )
              .then((res) => {
                const data = res.data
                console.log('第二接口返回:', data)

                // 第一个接口请求失败，将这个接口的数据返回
                try {
                  // 使用正则表达式提取 result 值
                  const regex = /"result":"([^"]+)"/g
                  const matches = data.matchAll(regex)

                  // 将匹配到的结果连接起来
                  let concatenatedResult = ''
                  for (const match of matches) {
                    concatenatedResult += match[1]
                  }

                  console.log(concatenatedResult)

                  if (concatenatedResult != '') {
                    // console.log('xx', obj)
                    setDrawerChatHistory((prevDrawerChatHistory) => {
                      const updatedDrawerChatHistory = [
                        ...prevDrawerChatHistory,
                      ]
                      updatedDrawerChatHistory[
                        currentListIndex
                      ].chatHistory.push({
                        user: 'robot',
                        time: getCurrentTime(),
                        description: isReanswer
                          ? XIAO_ZHI
                          : assistantInterfaceName,
                        interface: 'wenxin',
                        content: concatenatedResult.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                        messageId: randomString,
                        conversationId: randomString,
                        sendMessage,
                        meTime,
                      })
                      return updatedDrawerChatHistory
                    })
                    setTimeout(() => {
                      // 加载完最终滚动一次
                      scrollBotList()
                    }, 200)
                  } else {
                    throw new Error()
                  }
                } catch (error) {
                  // console.log('error', error)
                  // Message.error({
                  //    icon: <IconErrorTip useCurrentColor={false} />,
                  //   content: '接口请求错误！',
                  // })
                }

                setTimeout(() => {
                  suspend = false
                  setDisplayPause(false)
                }, 10)

                // todo 流控等提示
                if (data.data.text) {
                  console.log(data.data.text)
                  // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                  // 异常预处理
                  plugFlowError(
                    eventSource,
                    catchGPTError,
                    data.data.text,
                    data.data.id,
                  )
                }
              })
              .catch((err) => {
                console.log('err:', err)
              })

            catchGPTError(eventSource)
            throw error
          },
          onmessage(message) {
            const messages = JSON.parse(message.data)
            console.log(messages, 'wenxin++++')

            const messagesId = messages.id
            const messagesResult = messages.result
            // { data: 'Hello', event: '', id: '', retry: undefined }
            // setToggleCursor(true);
            // console.log(message, messages, messagesId, messagesResult)
            // console.log('------------------------------')

            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText += messagesResult
            } else {
              if (messages && messages.code == '503') {
                console.log('message.data 503:', messages)
                code503 = true
              } else {
                tmpText += messagesResult
              }
            }
            // console.log(tmpText)

            // 当开始打印字体的时候，将按钮改变为可暂停状态，并且禁止滚动条滚动
            if (!displayPause && !suspend) setDisplayPause(true)

            let tmpChatting = {
              user: 'robot',
              time: getCurrentTime(),
              description: isReanswer ? XIAO_ZHI : assistantInterfaceName,
              interface: 'wenxin',
              content: tmpText.replaceAll('\\n', ' \n ').replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
              sendMessage, // 去转义，否则 chatting 会直出 \n
              meTime, // 去转义，否则 chatting 会直出 \n
              messageId: messagesId,
              conversationId: messagesId,
            }

            // 异常预处理
            plugFlowError(eventSource, catchGPTError, message.data, message.id)

            // 倒数第二条时给 done 逻辑
            if (messages.is_end || suspend || code503) {
              setTimeout(() => {
                suspend = false
                setDisplayPause(false)
              }, 50)

              controller.abort()
              // console.log(message)

              // [X]catchGPTError(eventSource) 这里照顾到移动端，不统一处理了

              setLoading(false)

              if (!isMobile) focusMsgInput()

              // 结束时save
              if (tmpChatting.content != '') {
                setDrawerChatHistory((prevDrawerChatHistory) => {
                  const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                  if (flag) {
                    updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                      tmpChatting,
                    )
                    flag = false
                  } else {
                    updatedDrawerChatHistory[currentListIndex].chatHistory[
                      updatedDrawerChatHistory[currentListIndex].chatHistory
                        .length - 1
                    ] = tmpChatting
                  }
                  return updatedDrawerChatHistory
                })
                setTimeout(() => {
                  // 加载完最终滚动一次
                  scrollBotList()
                  // 始终保持loadMoreNum条记录
                  keepRecord()
                }, 200)

                request
                  .post(api + '/chatGPT/chatSave', {
                    input: sendMessage,
                    output: tmpText,
                    messageId: messagesId,
                    conversationId: messagesId,
                    type: 'openai',
                    model: 'wenxin',
                  })
                  .then((res) => {
                    console.log(res)
                  })

                // 若未修改当前对话名称，则自动修改
                sendStreamMessage(sendMessage, tmpText)
              } else {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '系统错误，请稍后重试！',
                })
              }
              return
            } else {
              // 如果用户向上滑动则不更新
              if (!isRoll) return
              // 更新数据
              setDrawerChatHistory((prevDrawerChatHistory) => {
                const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                if (flag) {
                  updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                    tmpChatting,
                  )
                  flag = false
                } else {
                  updatedDrawerChatHistory[currentListIndex].chatHistory[
                    updatedDrawerChatHistory[currentListIndex].chatHistory
                      .length - 1
                  ] = tmpChatting
                }
                return updatedDrawerChatHistory
              })

              scrollBotList()
            }
          },
        },
      )
    } catch (err) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '接口发生错误或超时未响应，请稍后重试#05',
      })
      catchGPTError(false)
      console.log('ERROR', err)
    }
  }

  // tyqw接口发送(通义千问)
  function doQWApi(message, prefix?: string, isReanswer?: boolean) {
    let tmpText = ''
    let meTime = getCurrentTime()
    let sendMessage = message ? message : textQ
    let randomString = generateRandomString(18)
    let sendList = []
    let code503 = false
    let flag = true

    setTextQ('')
    setLoading(true)
    scrollBotList()

    if (!isReanswer) {
      const tmpMe = {
        user: 'user',
        time: meTime,
        description: '我',
        interface: 'tyqw',
        content: sendMessage,
        reanswer: {
          type: 'TYQW', // 刷新重答时走TYQW接口
        },
      }

      updateDrawerChatHistoryData(currentListIndex, tmpMe)
    }

    // 整理数据
    drawerChatHistory[currentListIndex].chatHistory.forEach((item, index) => {
      if (item.interface == 'tyqw') {
        sendList.push({
          role: item.user == 'user' ? 'user' : 'assistant',
          content: item.content,
        })
      }
      // drawerChatHistory更新不及时，因此需要自行push
      if (index == drawerChatHistory[currentListIndex].chatHistory.length - 1) {
        sendList.push({
          role: 'user',
          content: prompt
            ? prompt + sendMessage
            : prefix
            ? prefix + sendMessage
            : sendMessage,
        })
      }
    })

    // 对sendList进行整理，始终保持一问一答
    function spliceSendList(list) {
      let resultArr = [...list]
      let i = 0

      while (i < resultArr.length) {
        let currentRole = resultArr[i].role

        if (currentRole === 'user') {
          let startIndex = i
          let endIndex = i + 1
          while (
            endIndex < resultArr.length &&
            resultArr[endIndex].role === 'user'
          ) {
            endIndex++
          }
          let deleteCount = endIndex - startIndex - 1
          resultArr.splice(startIndex, deleteCount)
        } else if (currentRole === 'assistant') {
          let startIndex = i
          let endIndex = i + 1
          while (
            endIndex < resultArr.length &&
            resultArr[endIndex].role === 'assistant'
          ) {
            endIndex++
          }
          let deleteCount = endIndex - startIndex - 1
          resultArr.splice(startIndex, deleteCount)
        }

        i++
      }

      return resultArr
    }

    const opts = {
      // deal with: In the process of onmessage, switch to another pages, it will resend the request. ref: https://github.com/Azure/fetch-event-source/issues/17
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        input: { messages: spliceSendList(sendList) },
        model: 'qwen-turbo',
        // stream: true,
        // user_id: userInfo.uid,
      }),
    }

    try {
      // let reply = '';
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        process.env.REACT_APP_TYQW_URL + 'Stream',
        {
          ...opts,
          signal: controller.signal,
          onclose() {
            // if the server closes the connection unexpectedly,
            // 此处还是要开，如果连接瞬间被关闭(如网络错误，或抛{"data":{"id":0,"text":"[OUT_OF_DAY_FLOW]"}}等)
            // 会触发这个事件，否则不 throw 的话不会进入 onerror(), 则后续的一系列错误判定和 retry 都不会执行
            // Message.error('接口发生错误或超时未响应，请稍后重试#04')
            catchGPTError(false)
            // trigger onerror()
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            // 服务器或网络错误处理

            // setErrorMessage(t['register.form.register.errMsg']);
            console.log('error', error)
            // Message.error({
            //   icon: <IconErrorTip useCurrentColor={false} />,
            //   content: '接口请求错误！',
            // })
            // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');

            // ! catch sse bug which needed.
            request
              .post(
                process.env.REACT_APP_TYQW_URL,
                JSON.stringify({
                  input: { messages: spliceSendList(sendList) },
                  model: 'qwen-turbo',
                  // stream: true,
                  // user_id: userInfo.uid,
                }),
              )
              .then((res) => {
                const data = res.data
                console.log('第二接口返回:', data)
                console.log('第二接口返回内容:', data.data.output.text)
                // 第一个接口请求失败，将这个接口的数据返回
                try {
                  console.log('111122------')

                  // 使用正则表达式提取 result 值
                  const regex = /"result":"([^"]+)"/g
                  const matches = data.matchAll(regex)

                  // 将匹配到的结果连接起来
                  let concatenatedResult = ''
                  for (const match of matches) {
                    concatenatedResult += match[1]
                  }

                  if (concatenatedResult != '') {
                    console.log('+++')
                    // console.log('xx', obj)
                    setDrawerChatHistory((prevDrawerChatHistory) => {
                      const updatedDrawerChatHistory = [
                        ...prevDrawerChatHistory,
                      ]
                      updatedDrawerChatHistory[
                        currentListIndex
                      ].chatHistory.push({
                        user: 'robot',
                        time: getCurrentTime(),
                        description: isReanswer
                          ? XIAO_ZHI_QW
                          : assistantInterfaceName,
                        interface: 'tyqw',
                        content: concatenatedResult.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                        messageId: randomString,
                        conversationId: randomString,
                        sendMessage,
                        meTime,
                      })
                      return updatedDrawerChatHistory
                    })
                    setTimeout(() => {
                      // 加载完最终滚动一次
                      scrollBotList()
                    }, 200)
                  } else {
                    throw new Error()
                  }
                } catch (error) {
                  // console.log('error', error)
                  // Message.error({
                  //    icon: <IconErrorTip useCurrentColor={false} />,
                  //   content: '接口请求错误！',
                  // })
                }

                setTimeout(() => {
                  suspend = false
                  setDisplayPause(false)
                }, 10)

                // todo 流控等提示
                if (data.data.text) {
                  console.log(data.data.text)
                  // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                  // 异常预处理
                  plugFlowError(
                    eventSource,
                    catchGPTError,
                    data.data.text,
                    data.data.id,
                  )
                }
              })
              .catch((err) => {
                console.log('err:', err)
              })

            catchGPTError(eventSource)
            throw error
          },
          onmessage(message) {
            const messages = JSON.parse(message.data)
            console.log(messages, 'message++')

            const messagesId = messages.request_id
            const messagesResult = messages.output.text
            // console.log(message, messages, messagesId, messagesResult)
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = messagesResult
              console.log(tmpText, 'tmpText+++++')
            } else {
              if (messages && messages.code == '503') {
                console.log('message.data 503:', messages)
                code503 = true
              } else {
                tmpText = messagesResult
              }
            }
            // console.log(tmpText)

            // 当开始打印字体的时候，将按钮改变为可暂停状态，并且禁止滚动条滚动
            if (!displayPause && !suspend) setDisplayPause(true)

            let tmpChatting = {
              user: 'robot',
              time: getCurrentTime(),
              description: isReanswer ? XIAO_ZHI_QW : assistantInterfaceName,
              interface: 'tyqw',
              content: tmpText, // 去转义，否则 chatting 会直出 \n
              sendMessage, // 去转义，否则 chatting 会直出 \n
              meTime, // 去转义，否则 chatting 会直出 \n
              messageId: messagesId,
              conversationId: messagesId,
            }

            // 异常预处理
            plugFlowError(eventSource, catchGPTError, message.data, message.id)

            // 倒数第二条时给 done 逻辑
            if (
              messages.is_end ||
              suspend ||
              code503 ||
              messages.output.finish_reason == 'stop'
            ) {
              setTimeout(() => {
                suspend = false
                setDisplayPause(false)
              }, 50)

              controller.abort()
              // console.log(message)

              // [X]catchGPTError(eventSource) 这里照顾到移动端，不统一处理了

              setLoading(false)

              if (!isMobile) focusMsgInput()
              console.log(tmpChatting, '---+++')

              // 结束时save
              if (tmpChatting.content != '') {
                setDrawerChatHistory((prevDrawerChatHistory) => {
                  const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                  if (flag) {
                    updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                      tmpChatting,
                    )
                    flag = false
                  } else {
                    updatedDrawerChatHistory[currentListIndex].chatHistory[
                      updatedDrawerChatHistory[currentListIndex].chatHistory
                        .length - 1
                    ] = tmpChatting
                  }
                  return updatedDrawerChatHistory
                })
                setTimeout(() => {
                  // 加载完最终滚动一次
                  scrollBotList()
                  // 始终保持loadMoreNum条记录
                  keepRecord()
                }, 200)
                console.log('发送记录------')

                request
                  .post(api + '/chatGPT/chatSave', {
                    input: sendMessage,
                    output: tmpText,
                    messageId: messagesId,
                    conversationId: messagesId,
                    type: 'openai',
                    model: 'tyqw',
                  })
                  .then((res) => {
                    console.log(res)
                  })

                // 若未修改当前对话名称，则自动修改
                // sendStreamMessage(sendMessage, tmpText)
              } else {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '系统错误，请稍后重试！',
                })
              }
              return
            } else {
              // 如果用户向上滑动则不更新
              if (!isRoll) return
              // 更新数据
              setDrawerChatHistory((prevDrawerChatHistory) => {
                const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                if (flag) {
                  updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                    tmpChatting,
                  )
                  flag = false
                } else {
                  updatedDrawerChatHistory[currentListIndex].chatHistory[
                    updatedDrawerChatHistory[currentListIndex].chatHistory
                      .length - 1
                  ] = tmpChatting
                }
                return updatedDrawerChatHistory
              })

              scrollBotList()
            }
          },
        },
      )
    } catch (err) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '接口发生错误或超时未响应，请稍后重试#05',
      })
      catchGPTError(false)
      console.log('ERROR', err)
    }
  }

  /*
      根据uri或者感兴趣话题发送回答请求  ————千万不要动里面任何一个参数
      answer：提问信息
      uri：根据文件生成的uri
      name：具体是哪个文件使用的此方法，主要用于指令库
      isReanswer：为true时直接发送请求，不用发送Me的消息
   */
  async function doUriApi(
    fileId: number,
    answer?: string,
    uri?: string,
    isReanswer?: boolean,
  ) {
    // 非推流
    // let answerUrl =
    //   INTERFACE_HK_NODE && currentFileCategory == 'wz'
    //     ? api + '/chatWebHK/answer'
    //     : api + '/chatWeb/answer'
    // 推流
    let answerUrl =
      INTERFACE_HK_NODE && currentFileCategory == 'wz'
        ? api + '/chatWebHK/answerStream'
        : api + '/chatWeb/answerStream'
    let meTime = getCurrentTime()
    let query = answer ? answer : textQ
    let tmpText = ''
    let code503 = false
    let flag = true
    let randomString = generateRandomString(18)
    let app = 'kuaidu'

    if (loading || answerLoading) return

    // 始终保持loadMoreNum条记录
    keepRecord().then(() => {
      setTextQ('')
      setLoading(true)
      scrollBotList()

      if (!isReanswer) {
        const tmpMe = {
          user: 'user',
          time: meTime,
          description: '我',
          interface: 'gpt35',
          // 这里不要再换行了，会打乱 md
          content: query,
          reanswer: {
            type: 'ANSWER', // 刷新重答时走ANSWER接口
            params: {
              uri,
            },
          },
        }

        updateDrawerChatHistoryData(currentListIndex, tmpMe)
      }

      const opts = {
        openWhenHidden: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          uri,
          is_stream: true,
          app,
        }),
      }

      // 推流
      try {
        // let reply = '';
        const controller = new AbortController()
        const eventSource = fetchEventSource(answerUrl, {
          ...opts,
          signal: controller.signal,
          onclose() {
            // if the server closes the connection unexpectedly,
            // 此处还是要开，如果连接瞬间被关闭(如网络错误，或抛{"data":{"id":0,"text":"[OUT_OF_DAY_FLOW]"}}等)
            // 会触发这个事件，否则不 throw 的话不会进入 onerror(), 则后续的一系列错误判定和 retry 都不会执行
            // Message.error('接口发生错误或超时未响应，请稍后重试#04')
            catchGPTError(false)
            // trigger onerror()
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            // 服务器或网络错误处理

            // setErrorMessage(t['register.form.register.errMsg']);
            console.log('error', error)
            // Message.error({
            //   icon: <IconErrorTip useCurrentColor={false} />,
            //   content: '接口请求错误！',
            // })
            // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');

            // ! catch sse bug which needed.
            request
              .post(
                answerUrl,
                JSON.stringify({
                  query,
                  uri,
                  is_stream: true,
                  app,
                }),
              )
              .then((res) => {
                // const { success, error, message } = res.data;
                console.log('第二接口返回:', res)

                // 第一个接口请求失败，将这个接口的数据返回
                try {
                  // 查找 JSON 对象的开始位置
                  let start = res.data.indexOf('{')
                  // 查找 JSON 对象的结束位置
                  let end = res.data.lastIndexOf('}') + 1
                  // 提取 JSON 字符串部分
                  let jsonStr = res.data.substring(start, end)
                  // 解析 JSON 字符串为对象
                  let obj = JSON.parse(jsonStr)

                  if (JSON.stringify(obj) != '{}') {
                    // console.log('xx', obj)
                    if (obj.response && !obj.response.includes('error')) {
                      setDrawerChatHistory((prevDrawerChatHistory) => {
                        const updatedDrawerChatHistory = [
                          ...prevDrawerChatHistory,
                        ]

                        updatedDrawerChatHistory[
                          currentListIndex
                        ].chatHistory.push({
                          user: 'robot',
                          time: getCurrentTime(),
                          description: assistantInterfaceName,
                          interface: 'gpt35',
                          content: obj.response.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                          messageId: randomString,
                          conversationId: randomString,
                          fileId: fileId > -1 ? fileId : '',
                          sendMessage: query,
                        })

                        return updatedDrawerChatHistory
                      })
                      setTimeout(() => {
                        // 加载完最终滚动一次
                        scrollBotList()
                      }, 200)
                    } else {
                      throw new Error()
                    }
                  }
                } catch (error) {
                  // console.log('error', error)
                  // Message.error({
                  //    icon: <IconErrorTip useCurrentColor={false} />,
                  //   content: '接口请求错误！',
                  // })
                }

                setTimeout(() => {
                  suspend = false
                  setDisplayPause(false)
                }, 10)

                const error = res.data
                console.log(error.data)
                // todo 流控等提示
                if (error.data.text) {
                  console.log(error.data.text)

                  // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                  // 异常预处理
                  plugFlowError(
                    eventSource,
                    catchGPTError,
                    error.data.text,
                    error.data.id,
                  )
                }
              })
              .catch((err) => {
                console.log('err:', err)
              })

            catchGPTError(eventSource)
            throw error
          },
          onmessage(message) {
            // { data: 'Hello', event: '', id: '', retry: undefined }
            // setToggleCursor(true);
            console.log('返回：' + JSON.stringify(message))
            // console.log('返回id：' + message.id)
            // console.log('返回data：' + message.data)
            // console.log('返回event：' + message.event)
            // console.log('part return message：')
            // console.log(tmpText)
            // console.log('------------------------------')

            // if (message.data == '[DONE]') {

            // 倒数第二句话，即全文
            // 等待解决 workaround，早晚摘除 message.data !== '[DONE]' 后重新捋逻辑
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = JSON.parse(message.data).response
            } else {
              if (message.data && JSON.parse(message.data).code == '503') {
                console.log('message.data 503:', message)
                code503 = true
              } else {
                tmpText =
                  message.data == '[DONE]'
                    ? tmpText
                    : tmpText +
                      message.data.substring(1, message.data.length - 1)
              }
            }

            // 当开始打印字体的时候，将按钮改变为可暂停状态，并且禁止滚动条滚动
            if (!displayPause && !suspend) setDisplayPause(true)

            let tmpChatting = {
              user: 'robot',
              time: getCurrentTime(),
              description: fileInfo.fileName,
              interface: 'gpt35',
              sendMessage: query,
              meTime,
              content: tmpText.replaceAll('\\n', ' \n ').replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
              messageId: randomString,
              conversationId: randomString,
            }

            // 异常预处理
            plugFlowError(eventSource, catchGPTError, message.data, message.id)

            // 倒数第二条时给 done 逻辑
            if (message.event == 'result' || suspend || code503) {
              setTimeout(() => {
                suspend = false
                setDisplayPause(false)
              }, 50)

              controller.abort()
              // console.log(message)

              // [X]catchGPTError(eventSource) 这里照顾到移动端，不统一处理了

              setLoading(false)

              if (!isMobile) focusMsgInput()

              // 结束时再设置相关 id
              const tmpJson = JSON.parse(message.data)

              if (tmpChatting.content != '') {
                setDrawerChatHistory((prevDrawerChatHistory) => {
                  const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                  if (flag) {
                    updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                      tmpChatting,
                    )
                    flag = false
                  } else {
                    updatedDrawerChatHistory[currentListIndex].chatHistory[
                      updatedDrawerChatHistory[currentListIndex].chatHistory
                        .length - 1
                    ] = tmpChatting
                  }
                  return updatedDrawerChatHistory
                })
                setTimeout(() => {
                  // 加载完最终滚动一次
                  scrollBotList()
                  // 始终保持loadMoreNum条记录
                  keepRecord()
                }, 200)

                request
                  .post(api + '/chatGPT/chatSave', {
                    input: query,
                    output: tmpJson.response,
                    messageId: randomString,
                    conversationId: randomString,
                    type: 'openai',
                    model: 'gpt-3.5',
                  })
                  .then((res) => {
                    // console.log(res)
                  })

                // 若未修改当前对话名称，则自动修改
                sendStreamMessage(query, tmpJson.response)
              } else {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '系统错误，请稍后重试！',
                })
              }
              return
            } else if (message.data !== '[DONE]') {
              // 如果用户向上滑动则不更新
              if (!isRoll) return
              // 更新数据
              setDrawerChatHistory((prevDrawerChatHistory) => {
                const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                if (flag) {
                  updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                    tmpChatting,
                  )
                  flag = false
                } else {
                  updatedDrawerChatHistory[currentListIndex].chatHistory[
                    updatedDrawerChatHistory[currentListIndex].chatHistory
                      .length - 1
                  ] = tmpChatting
                }
                return updatedDrawerChatHistory
              })

              scrollBotList()
            }
          },
        })
        // console.log(reply);
      } catch (err) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '接口发生错误或超时未响应，请稍后重试#05',
        })
        catchGPTError(false)
        console.log('ERROR', err)
      }

      // 非推流
      // request
      //   .post(
      //     answerUrl,
      //     JSON.stringify({
      //       query,
      //       uri,
      //     }),
      //   )
      //   .then((res) => {
      //     console.log(res)
      //     const result = res.data
      //     setTextQ('')

      //     if (result.code == '500200') {
      //       const tmpChatting = {
      //         user: 'robot',
      //         time: getCurrentTime(),
      //         description: findFileIdByItem(fileId).fileName.split('.')[0],
      //         interface: 'gpt35',
      //         sendMessage: query,
      //         meTime,
      //         content: result.data.answer.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
      //         messageId: randomString,
      //       }

      //       updateDrawerChatHistoryData(currentListIndex, tmpChatting)

      //       setLoading(false)

      //       // 加载完最终滚动一次
      //       scrollBotList()

      //       // 始终保持loadMoreNum条记录
      //       keepRecord()

      //       // 记录保存
      //       request
      //         .post(api + '/chatGPT/chatSave', {
      //           input: query,
      //           output: result.data.answer,
      //           messageId: randomString,
      //           conversationId: randomString,
      //           type: 'openai',
      //           model: 'gpt-3.5',
      //         })
      //         .then((res) => {
      //           console.log(res)
      //         })

      //       // 若未修改当前对话名称，则自动修改
      //       sendStreamMessage(query, result.data.answer)
      //     } else {
      //       Message.error({
      //         icon: <IconErrorTip useCurrentColor={false} />,
      //         content: result.msg,
      //       })
      //       setTextQ('')
      //       setLoading(false)
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err)

      //     Message.error({
      //       icon: <IconErrorTip useCurrentColor={false} />,
      //       content: '回答异常！',
      //     })
      //     setLoading(false)
      //   })
      //   .finally(() => {
      //     //
      //   })
    })
  }

  /*
      根据app发送回答请求
      answer：提问信息
      app：插件库具体插件的id
      isReanswer：为true时直接发送请求，不用发送Me的消息
   */
  async function doAppApi(answer?: string, app?: string, isReanswer?: boolean) {
    // 非推流
    // let answerUrl =
    //   INTERFACE_HK_NODE && currentFileCategory == 'wz'
    //     ? api + '/chatWebHK/answer'
    //     : api + '/chatWeb/answer'
    // 推流
    let answerUrl =
      INTERFACE_HK_NODE && currentFileCategory == 'wz'
        ? api + '/chatWebHK/answerStream'
        : api + '/chatWeb/answerStream'
    let meTime = getCurrentTime()
    let query = answer ? answer : textQ
    let tmpText = ''
    let code503 = false
    let flag = true
    let randomString = generateRandomString(18)

    if (loading || answerLoading) return

    // 始终保持loadMoreNum条记录
    keepRecord().then(() => {
      setTextQ('')
      setLoading(true)
      scrollBotList()

      if (!isReanswer) {
        const tmpMe = {
          user: 'user',
          time: meTime,
          description: '我',
          interface: 'gpt35',
          // 这里不要再换行了，会打乱 md
          content: query,
          reanswer: {
            type: 'PLUGIN', // 刷新重答时走PLUGIN接口
            params: {
              uri: app,
            },
          },
        }

        updateDrawerChatHistoryData(currentListIndex, tmpMe)
      }

      const opts = {
        openWhenHidden: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          app,
          is_stream: true,
        }),
      }

      // 推流
      try {
        // let reply = '';
        const controller = new AbortController()
        const eventSource = fetchEventSource(answerUrl, {
          ...opts,
          signal: controller.signal,
          onclose() {
            // if the server closes the connection unexpectedly,
            // 此处还是要开，如果连接瞬间被关闭(如网络错误，或抛{"data":{"id":0,"text":"[OUT_OF_DAY_FLOW]"}}等)
            // 会触发这个事件，否则不 throw 的话不会进入 onerror(), 则后续的一系列错误判定和 retry 都不会执行
            // Message.error('接口发生错误或超时未响应，请稍后重试#04')
            catchGPTError(false)
            // trigger onerror()
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            // 服务器或网络错误处理

            // setErrorMessage(t['register.form.register.errMsg']);
            console.log('error', error)
            // Message.error({
            //   icon: <IconErrorTip useCurrentColor={false} />,
            //   content: '接口请求错误！',
            // })
            // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');

            // ! catch sse bug which needed.
            request
              .post(
                answerUrl,
                JSON.stringify({
                  query,
                  app,
                  is_stream: true,
                }),
              )
              .then((res) => {
                // const { success, error, message } = res.data;
                console.log('第二接口返回:', res)

                // 第一个接口请求失败，将这个接口的数据返回
                try {
                  // 查找 JSON 对象的开始位置
                  let start = res.data.indexOf('{')
                  // 查找 JSON 对象的结束位置
                  let end = res.data.lastIndexOf('}') + 1
                  // 提取 JSON 字符串部分
                  let jsonStr = res.data.substring(start, end)
                  // 解析 JSON 字符串为对象
                  let obj = JSON.parse(jsonStr)

                  if (JSON.stringify(obj) != '{}') {
                    // console.log('xx', obj)
                    if (obj.response && !obj.response.includes('error')) {
                      setDrawerChatHistory((prevDrawerChatHistory) => {
                        const updatedDrawerChatHistory = [
                          ...prevDrawerChatHistory,
                        ]

                        updatedDrawerChatHistory[
                          currentListIndex
                        ].chatHistory.push({
                          user: 'robot',
                          time: getCurrentTime(),
                          description: assistantInterfaceName,
                          interface: 'gpt35',
                          content: obj.response.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
                          messageId: randomString,
                          conversationId: randomString,
                          fileId: fileId > -1 ? fileId : '',
                          sendMessage: query,
                        })

                        return updatedDrawerChatHistory
                      })
                      setTimeout(() => {
                        // 加载完最终滚动一次
                        scrollBotList()
                      }, 200)
                    } else {
                      throw new Error()
                    }
                  }
                } catch (error) {
                  // console.log('error', error)
                  // Message.error({
                  //    icon: <IconErrorTip useCurrentColor={false} />,
                  //   content: '接口请求错误！',
                  // })
                }

                setTimeout(() => {
                  suspend = false
                  setDisplayPause(false)
                }, 10)

                const error = res.data
                console.log(error.data)
                // todo 流控等提示
                if (error.data.text) {
                  console.log(error.data.text)

                  // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                  // 异常预处理
                  plugFlowError(
                    eventSource,
                    catchGPTError,
                    error.data.text,
                    error.data.id,
                  )
                }
              })
              .catch((err) => {
                console.log('err:', err)
              })

            catchGPTError(eventSource)
            throw error
          },
          onmessage(message) {
            // { data: 'Hello', event: '', id: '', retry: undefined }
            // setToggleCursor(true);
            // console.log('返回：' + JSON.stringify(message))
            // console.log('返回id：' + message.id)
            // console.log('返回data：' + message.data)
            // console.log('返回event：' + message.event)
            // console.log('part return message：')
            // console.log(tmpText)
            // console.log('------------------------------')

            // if (message.data == '[DONE]') {

            // 倒数第二句话，即全文
            // 等待解决 workaround，早晚摘除 message.data !== '[DONE]' 后重新捋逻辑
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = JSON.parse(message.data).response
            } else {
              if (message.data && JSON.parse(message.data).code == '503') {
                console.log('message.data 503:', message)
                code503 = true
              } else {
                tmpText =
                  message.data == '[DONE]'
                    ? tmpText
                    : tmpText +
                      message.data.substring(1, message.data.length - 1)
              }
            }

            // 当开始打印字体的时候，将按钮改变为可暂停状态，并且禁止滚动条滚动
            if (!displayPause && !suspend) setDisplayPause(true)

            let tmpChatting = {
              user: 'robot',
              time: getCurrentTime(),
              description: fileInfo.fileName,
              interface: 'gpt35',
              sendMessage: query,
              meTime,
              content: tmpText.replaceAll('\\n', ' \n ').replaceAll('\\', ''), // 去转义，否则 chatting 会直出 \n
              messageId: randomString,
              conversationId: randomString,
            }

            // 异常预处理
            plugFlowError(eventSource, catchGPTError, message.data, message.id)

            // 倒数第二条时给 done 逻辑
            if (message.event == 'result' || suspend || code503) {
              setTimeout(() => {
                suspend = false
                setDisplayPause(false)
              }, 50)

              controller.abort()
              // console.log(message)

              // [X]catchGPTError(eventSource) 这里照顾到移动端，不统一处理了

              setLoading(false)

              if (!isMobile) focusMsgInput()

              // 结束时再设置相关 id
              const tmpJson = JSON.parse(message.data)

              if (tmpChatting.content != '') {
                setDrawerChatHistory((prevDrawerChatHistory) => {
                  const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                  if (flag) {
                    updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                      tmpChatting,
                    )
                    flag = false
                  } else {
                    updatedDrawerChatHistory[currentListIndex].chatHistory[
                      updatedDrawerChatHistory[currentListIndex].chatHistory
                        .length - 1
                    ] = tmpChatting
                  }
                  return updatedDrawerChatHistory
                })
                setTimeout(() => {
                  // 加载完最终滚动一次
                  scrollBotList()
                  // 始终保持loadMoreNum条记录
                  keepRecord()
                }, 200)

                console.log(tmpJson)

                request
                  .post(api + '/chatGPT/chatSave', {
                    input: query,
                    output: tmpJson.response,
                    messageId: randomString,
                    conversationId: randomString,
                    type: 'openai',
                    model: 'gpt-3.5',
                  })
                  .then((res) => {
                    // console.log(res)
                  })

                // 若未修改当前对话名称，则自动修改
                sendStreamMessage(query, tmpJson.response)
              } else {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '系统错误，请稍后重试！',
                })
              }
              return
            } else if (message.data !== '[DONE]') {
              // 如果用户向上滑动则不更新
              if (!isRoll) return
              // 更新数据
              setDrawerChatHistory((prevDrawerChatHistory) => {
                const updatedDrawerChatHistory = [...prevDrawerChatHistory]
                if (flag) {
                  updatedDrawerChatHistory[currentListIndex].chatHistory.push(
                    tmpChatting,
                  )
                  flag = false
                } else {
                  updatedDrawerChatHistory[currentListIndex].chatHistory[
                    updatedDrawerChatHistory[currentListIndex].chatHistory
                      .length - 1
                  ] = tmpChatting
                }
                return updatedDrawerChatHistory
              })

              scrollBotList()
            }
          },
        })
        // console.log(reply);
      } catch (err) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '接口发生错误或超时未响应，请稍后重试#05',
        })
        catchGPTError(false)
        console.log('ERROR', err)
      }

      // request
      //   .post(
      //     answerUrl,
      //     JSON.stringify({
      //       query,
      //       app,
      //     }),
      //   )
      //   .then((res) => {
      //     console.log(res)
      //     const result = res.data
      //     setTextQ('')

      //     if (result.code == '500200') {
      //       const tmpChatting = {
      //         user: 'robot',
      //         time: getCurrentTime(),
      //         description: pluginName,
      //         interface: 'gpt35',
      //         sendMessage: query,
      //         meTime,
      //         content: result.data.answer.replaceAll('\\n', ' \n '), // 去转义，否则 chatting 会直出 \n
      //         messageId: randomString,
      //       }

      //       updateDrawerChatHistoryData(currentListIndex, tmpChatting)

      //       setLoading(false)

      //       // 加载完最终滚动一次
      //       scrollBotList()

      //       // 始终保持loadMoreNum条记录
      //       keepRecord()

      //       // 记录保存
      //       request
      //         .post(api + '/chatGPT/chatSave', {
      //           input: query,
      //           output: result.data.answer,
      //           messageId: randomString,
      //           conversationId: randomString,
      //           type: 'openai',
      //           model: 'gpt-3.5',
      //         })
      //         .then((res) => {
      //           console.log(res)
      //         })

      //       // 若未修改当前对话名称，则自动修改
      //       sendStreamMessage(query, result.data.answer)
      //     } else {
      //       Message.error({
      //         icon: <IconErrorTip useCurrentColor={false} />,
      //         content: result.msg,
      //       })
      //       setTextQ('')
      //       setLoading(false)
      //     }
      //   })
      //   .catch(() => {
      //     Message.error({
      //       icon: <IconErrorTip useCurrentColor={false} />,
      //       content: '回答异常！',
      //     })
      //     setLoading(false)
      //   })
      //   .finally(() => {
      //     //
      //   })
    })
  }

  // 根据uri生成
  function upLoadFile(uri, fileName, fileCategory) {
    let summaryUrl =
      INTERFACE_HK_NODE && fileCategory == 'wz'
        ? api + '/chatWebHK/summary'
        : api + '/chatWeb/summary'
    let outlinesUrl =
      INTERFACE_HK_NODE && fileCategory == 'wz'
        ? api + '/chatWebHK/outlines'
        : api + '/chatWeb/outlines'
    let questionsUrl =
      INTERFACE_HK_NODE && fileCategory == 'wz'
        ? api + '/chatWebHK/questions'
        : api + '/chatWeb/questions'
    let tagsUrl =
      INTERFACE_HK_NODE && fileCategory == 'wz'
        ? api + '/chatWebHK/tags'
        : api + '/chatWeb/tags'
    let lastItemIndex = drawerChatHistory.length
      ? drawerChatHistory[currentListIndex].chatHistory.length
      : 0
    let lastFileId = findLastFileId(currentListIndex)

    const tmpChatting = {
      user: 'robot',
      time: getCurrentTime(),
      description: assistantInterfaceName,
      interface: 'gpt35',
      content: '', // 去转义，否则 chatting 会直出 \n
      fileName,
      fileCategory,
      sendMessage: fileName.split('.')[0],
      fileId: lastFileId,
    }
    // setChatHistory((chatHistory) => [...chatHistory, tmpChatting])
    updateDrawerChatHistoryData(currentListIndex, tmpChatting)
    setFileId(lastFileId)
    pushDrawerChatHistory('uri', uri)
    setMessageId('')
    setConversationId('')
    setMessageId40('')
    setConversationId40('')
    scrollBotList()
    setTimeout(() => {
      tagsRefList()
    }, 100)

    /*
      数据格式：{ isError: 0/1/2(0：成功  1：失败 2：继续骨架屏) , data: '数据' }
    **/
    pushDrawerChatHistory('summary', { isError: 2, data: '' }, lastItemIndex)
    summaryCallback(summaryUrl, lastItemIndex, uri)

    if (INTERFACE_OUTLINES) {
      pushDrawerChatHistory(
        'outlinesArray',
        { isError: 2, data: '' },
        lastItemIndex,
      )
      outlinesCallback(outlinesUrl, lastItemIndex, uri)
    }

    if (INTERFACE_QUESTIONS) {
      pushDrawerChatHistory(
        'questionsArray',
        { isError: 2, data: '' },
        lastItemIndex,
      )
      questionsCallback(questionsUrl, lastItemIndex, uri)
    }

    if (INTERFACE_TAGS) {
      pushDrawerChatHistory(
        'tagsArray',
        { isError: 2, data: '' },
        lastItemIndex,
      )
      tagsCallback(tagsUrl, lastItemIndex, uri)
    }
  }

  // 点击刷新
  function refreshST(item, index) {
    const summaryUrl = INTERFACE_HK_NODE
      ? api + '/chatWebHK/summary'
      : api + '/chatWeb/summary'
    const outlinesUrl = INTERFACE_HK_NODE
      ? api + '/chatWebHK/outlines'
      : api + '/chatWeb/outlines'
    const questionsUrl = INTERFACE_HK_NODE
      ? api + '/chatWebHK/questions'
      : api + '/chatWeb/questions'
    const tagsUrl = INTERFACE_HK_NODE
      ? api + '/chatWebHK/tags'
      : api + '/chatWeb/tags'
    const { uri, summary, outlinesArray, questionsArray, tagsArray } = item

    setAnswerLoading(true)

    if (summary.isError === 1) {
      pushDrawerChatHistory('summary', { isError: 2, data: '' }, index)
      summaryCallback(summaryUrl, index, uri)
    }
    if (INTERFACE_OUTLINES && outlinesArray.isError === 1) {
      pushDrawerChatHistory('outlinesArray', { isError: 2, data: '' }, index)
      outlinesCallback(outlinesUrl, index, uri)
    }
    if (INTERFACE_QUESTIONS && questionsArray.isError === 1) {
      pushDrawerChatHistory('questionsArray', { isError: 2, data: '' }, index)
      questionsCallback(questionsUrl, index, uri)
    }
    if (INTERFACE_TAGS && tagsArray.isError === 1) {
      pushDrawerChatHistory('tagsArray', { isError: 2, data: '' }, index)
      tagsCallback(tagsUrl, index, uri)
    }
  }

  // force：3：可以不需要textQ直接发送
  function sendMsg(force = 0, message?: string, prefix?: string) {
    // todo 先行回车似乎还有问题，先行回车会出来一个回车，第二次可发送，应该屏蔽第一次回车，之后任何空换行或空内容也不允许发送

    // 兼容发送按钮
    if (force != 3) {
      if (loading || !textQ || (!sendProve && force !== 1)) {
        return false
      }
    }

    // 发送瞬间滚动一次
    scrollBotList()
    // focusMsgInput();
    keepRecord().then(() => {
      if (switchProject('DFZ') && pluginName) {
        doAppApi(textQ, pluginId)
      } else if (uri) {
        doUriApi(fileId, textQ, uri)
      } else {
        if (assistantInterface === 'gpt35' || assistantInterface === 'gpt40') {
          message ? doApi(message, prefix) : doApi(textQ)
        } else if (assistantInterface === 'wenxin') {
          message ? doWenXinApi(message, prefix) : doWenXinApi(textQ)
        } else if (assistantInterface === 'tyqw') {
          message ? doQWApi(message, prefix) : doQWApi(textQ)
        }
      }
    })

    return
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13 && e.shiftKey === true) {
      // e.preventDefault(); //阻止默认行为,会有兼容问题
      console.log('组合键SHIFT+换行，不放行')
      // 其他代码
      setSendProve(false)
      setDisplayPause(false)
    } else if (e.keyCode === 13 && e.shiftKey === false) {
      console.log('纯回车键，放行')
      setSendProve(true)
    }
    // else {
    //   setSendProve(false)
    //   setDisplayPause(false)
    // }
  }

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  }

  const isUrl = (url) => {
    var pattern = /^https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+/
    return pattern.test(url)
  }

  // 上传网址url
  const upDownUrlForm = () => {
    form.validate().then((res) => {
      if (res.url == url) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '此网页已抓取成功，请输入其他网页！',
        })
        setConfirmLoading(false)
        return
      }

      if (isUrl(res.url)) {
        let chatWebUrl = INTERFACE_HK_NODE
          ? api + '/chatWebHK/crawlerUrl'
          : api + '/chatWeb/crawlerUrl'

        setVisible(false)
        setConfirmLoading(true)
        setAnswerLoading(true)
        setDisabledUpload(true)
        cancelUrl = true

        request
          .post(chatWebUrl, { url: res.url })
          .then((resolve) => {
            let result = resolve.data
            console.log(result)

            if (result.code == '500200') {
              if (!cancelUrl) return
              setConfirmLoading(false)
              setVisible(false)
              setDisabledUpload(false)
              setAnswerLoading(false)
              setUri(result.data.uri)
              cancelUrl = false
              setCurrentFileCategory('wz')
              if (findIsHaveMeUri(result.data.uri)) {
                tagsReturnTo(findIsHaveMeUri(result.data.uri).fileId)
                setActive([findIsHaveMeUri(result.data.uri).fileId, true])
                Message.warning({
                  icon: <IconWarnTip useCurrentColor={false} />,
                  content: '此链接已上传，已切换到此对话！',
                })
                return
              }
              form.setFieldsValue({
                url: '',
              })
              setUrl(res.url)
              upLoadFile(result.data.uri, result.data.title, 'wz')
              tagsReturnTo(findIsHaveMeUri(result.data.uri).fileId)
              setActive([findIsHaveMeUri(result.data.uri).fileId, true])
              Message.success({
                icon: <IconSuccessTip useCurrentColor={false} />,
                content: 'URL解析成功！',
              })
            } else {
              setUrl('')
              setConfirmLoading(false)
              setDisabledUpload(false)
              setAnswerLoading(false)
              setAnswerLoading(false)
              cancelUrl = false
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: result.msg,
              })
            }
          })
          .catch((err) => {
            setUrl('')
            setConfirmLoading(false)
            setDisabledUpload(false)
            setAnswerLoading(false)
            setAnswerLoading(false)
            cancelUrl = false
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '网络异常，请重新上传！',
            })
          })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '网页链接有误，请输入正确的链接！',
        })
        setConfirmLoading(false)
      }
    })
  }

  // 指令库点击——>index_1：一级菜单index index_2：二级菜单index sign：1（正常加载tag）2：点击复制回调 3：点击使用回调 4：使用并发送 info：当前选择的tag的info信息
  const promptSend = (index_1, index_2, sign, info) => {
    let NewPrompt = list[index_1].children[index_2].content
    let classificationIn = index_1 + '-' + index_2
    let lastFileId = findLastFileId(currentListIndex)
    let flag = false
    // console.log(index_1, index_2)

    if (pluginId) {
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: '正在使用插件库，请清除后再尝试使用！',
      })
      return
    }
    setPrompt(NewPrompt)
    setUri('')
    setVisiblePrompt(false)
    setMessageId('')
    setConversationId('')
    setMessageId40('')
    setConversationId40('')
    setCurrentFileCategory('by')
    setTimeout(() => {
      focusMsgInput()
    }, 100)

    // 如果有则不再添加
    classifications.forEach((item, index) => {
      if (item[0] == classificationIn) {
        sign == 1
          ? Message.warning({
              icon: <IconWarnTip useCurrentColor={false} />,
              content: '此对话已有，已切换到此对话！',
            })
          : sign == 2
          ? Message.warning({
              icon: <IconWarnTip useCurrentColor={false} />,
              content: '此对话已有，已复制并切换到此对话！',
            })
          : sign != 4
          ? setTextQ(info)
          : ''
        setActive([item[1], true])
        tagsReturnTo(index)
        flag = true
        return
      }
    })

    if (flag) return

    drawerChatHistory.length &&
      drawerChatHistory[currentListIndex].chatHistory.forEach((item) => {
        if (item.fileName && item.fileName != '' && item.fileName == info) {
          Message.warning({
            icon: <IconWarnTip useCurrentColor={false} />,
            content: '此对话已有，已切换到此对话！',
          })
          setActive([item.fileId, true])
          tagsReturnTo(item.fileId)
          flag = true
          return
        }
      })

    if (flag) return

    if (sign == 2) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '已复制',
      })
    }
    if (sign == 3) {
      setTextQ(info)
      setVisiblePrompt(false)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '已使用',
      })
    }

    const tmpChatting = {
      user: 'robot',
      time: getCurrentTime(),
      description: assistantInterfaceName,
      interface: 'gpt35',
      content: `我已切换到 ${list[index_1].title} - ${list[index_1].children[index_2].title}，请开始您的对话吧！ \n 您可以参考以下内容： \n ${list[index_1].children[index_2].info}`, // 去转义，否则 chatting 会直出 \n
      sendMessage: list[index_1].children[index_2].title,
      prompt: NewPrompt,
      fileName: list[index_1].children[index_2].title,
      fileCategory: 'by',
      classification: index_1 + '-' + index_2,
      fileId: lastFileId,
      isShow: sign == 4 ? false : true,
    }

    // setMessageId('')
    // setConversationId('')
    // setMessageId40('')
    // setConversationId40('')
    setFileId(lastFileId)
    setActive([lastFileId, true])
    if (sign == 4) {
      updateDrawerChatHistoryData(currentListIndex, tmpChatting)
      setVisiblePrompt(false)
      sendMsg(3, list[index_1].children[index_2].info, NewPrompt)
    } else {
      updateDrawerChatHistoryData(currentListIndex, tmpChatting)
    }
    scrollBotList()
    setTimeout(() => {
      tagsRefList()
    }, 100)
  }

  // tags左右滑动函数开始
  const handleMouseDown = (e) => {
    setIsDragging(true)
    time = Date.now()
    setStartX(e.pageX)
    setScrollLeft(e.currentTarget.scrollLeft)
  }
  const handleMouseMove = (e) => {
    if (!isDragging || e.buttons !== 1) return
    const x = e.pageX
    const walk = (x - startX) * 1.2 // 调整滑动速度
    e.currentTarget.scrollLeft = scrollLeft - walk
  }
  const handleMouseUp = () => {
    if (Date.now() - time < 500) {
      setIsDragging(false)
    } else {
      setTimeout(() => {
        setIsDragging(false)
      }, 50)
    }
  }
  // tags左右滑动函数结束
  //退出登录
  const [_, setUserStatus] = useStorage('userStatus')
  function logout() {
    setUserStatus('logout')
    document.cookie =
      'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    request.get('/api-sso/login/loginOut').then((res) => {
      console.log(res)
    })
    localStorage.removeItem('templateData')
    localStorage.removeItem('selectTem')
    window.location.href = '/login'
  }

  type Props = {
    text: string
    mes?: string
  }
  // 复制按钮
  const CopyButton: React.FC<Props> = ({ text, mes }) => {
    return (
      <Copy
        text={text}
        // theme={theme}
        onCopy={() => {
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '已复制',
          })
        }}
        content={
          <IconBtn
            text={dev == 'pc' ? mes : '复制'}
            iconLeft={<IconCopy />}></IconBtn>
        }
      />
    )
  }

  // 判断是否是Object
  function isObject(obj) {
    if (typeof obj === 'object' && obj !== null && !(obj instanceof Array)) {
      return true
    } else {
      return false
    }
  }

  // 生成脑图
  const generatingBrainMaps = (uri) => {
    if (switchProject('DFZ')) {
      window.open(`https://mind-map.v-dk.com/api/?uri=${uri}&lite=1`, '_blank')
    } else {
      window.open(`https://mind-map.wuz.com.cn/api/?uri=${uri}`, '_blank')
    }
  }
  // 知识图谱
  const knowledgeGraph = (uri) => {
    if (switchProject('DFZ')) {
      window.open(
        `https://mind-map.v-dk.com/api/?uri=${uri}&type=kg&lite=1`,
        '_blank',
      )
    } else {
      window.open(
        `https://mind-map.wuz.com.cn/api/?uri=${uri}&type=kg`,
        '_blank',
      )
    }
  }

  // 对话列表中每个对话
  const DialogueListForEachConversation = ({ title, index }) => {
    const [value, setValue] = useState<any>(title)

    return (
      <Tooltip content={title}>
        <Input
          placeholder="请输入对话名..."
          value={value}
          onChange={(value) => {
            setValue(value)
          }}
          onBlur={(event) => {
            const inputValue = event.target.value
            if (inputValue && inputValue !== title) {
              setDrawerChatHistoryData(index, 'title', inputValue)
              Message.success({
                icon: <IconSuccessTip useCurrentColor={false} />,
                content: '修改成功！',
              })
            } else {
              setDrawerChatHistoryData(index, 'title', title)
            }
          }}
          onPressEnter={(event) => {
            const inputValue = event.target.value
            if (inputValue && inputValue !== title) {
              setDrawerChatHistoryData(index, 'title', inputValue)
              Message.success({
                icon: <IconSuccessTip useCurrentColor={false} />,
                content: '修改成功！',
              })
            } else {
              setDrawerChatHistoryData(index, 'title', title)
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      </Tooltip>
    )
  }

  // 切换助理下拉框配置
  const switchAssistantDropdownBox = [
    {
      value: 'gpt35',
      extraInterface: 'XIAO_WU_35',
      extraValue: XIAO_WU_35,
      starNum: 4,
      icon: <IconCollectFill />,
    },
    {
      value: 'gpt40',
      extraInterface: 'XIAO_WU_40',
      extraValue: XIAO_WU_40,
      starNum: 5,
      icon: <IconCollectFill />,
    },
    {
      value: 'wenxin',
      extraInterface: 'XIAO_ZHI',
      extraValue: XIAO_ZHI,
      starNum: 3,
      icon: <IconCollectFill />,
    },
    {
      value: 'tyqw',
      extraInterface: 'XIAO_ZHI_QW',
      extraValue: XIAO_ZHI_QW,
      starNum: 3,
      icon: <IconCollectFill />,
    },
  ]

  return (
    <div
      className="outContent"
      ref={outContentRef}
      style={
        IS_FULL_SCREEN
          ? {
              position: 'absolute',
              backgroundColor: '#FFF',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 999,
            }
          : {}
      }>
      {isSpin ? (
        <Spin
          tip="正在加载历史记录..."
          loading
          style={{ background: theme == 'dark' ? '#19191a' : 'white' }}></Spin>
      ) : (
        // 智能助理
        // <div className="outContentIn">
        <div className={`outContentIn ${styles[theme]}`}>
          {/* 顶部 */}
          <div
            className="contentTop"
            // style={{ marginTop: dev == 'androidApp' || dev == 'otherApp' ? '50px' : '' }}
          >
            <div className={`contentTopIcon ${styles['listWrap']}`}>
              {/* 顶部左侧按钮 */}
              {dev === 'pc' && (
                <>
                  <div className="contentTopIconLeft">
                    <div className="leftIcon">
                      <IconLayout
                        onClick={() =>
                          // 记住每次点击后的状态
                          setDrawerVisible((prevState) => {
                            if (
                              dev === 'pc' &&
                              !special &&
                              IS_MEMORY_OPEN &&
                              !prevState !== IS_OPEN_DIALOGUE_LIST
                            ) {
                              setModRef.current &&
                                setModRef.current.assistantConfigChange(
                                  ['screen', 'dialogueList', 'IS_OPEN'],
                                  !prevState,
                                )
                            }

                            return !prevState
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {/* 顶部右侧按钮 */}
              <div className="contentTopIconRight">
                {dev === 'pc' ? (
                  <>
                    {/* 助理设置 */}
                    <div
                      className={`setBtn ${styles['clearBtn']} ${styles[theme]}`}>
                      <BorderBtn
                        theme={theme}
                        width="158"
                        iconLeft={<IconSettings />}
                        onClick={() =>
                          setModRef.current && setModRef.current.handleCancel()
                        }
                        text={'助理设置'}></BorderBtn>
                    </div>
                    {/* 切换助理 */}
                    <div
                      className={`assistantSet ${styles['clearBtn']} ${styles[theme]}`}>
                      <BorderBtn
                        theme={theme}
                        width="158"
                        padding="0"
                        text={
                          <Select
                            disabled={isAgent}
                            dropdownMenuClassName={`${styles[theme]}`}
                            dropdownMenuStyle={{ width: '158px' }}
                            value={assistantInterface}
                            prefix={
                              <IconSmartAssistantLine
                                fill={isAgent ? '#c9cdd4' : ''}
                              />
                            }
                            bordered={false}
                            getPopupContainer={(triggerNode: HTMLElement) =>
                              triggerNode.parentNode as HTMLElement
                            }
                            onChange={(value, option: OptionInfo) => {
                              if (isSvip(value)) return

                              // 将本地记录更改
                              setDrawerChatHistory((drawerChatHistory) => {
                                const newDrawerChatHistory = [
                                  ...drawerChatHistory,
                                ]
                                newDrawerChatHistory[
                                  currentListIndex
                                ].gptVersion = value
                                return newDrawerChatHistory
                              })
                              setAssistantInterface(value)
                              setTimeout(() => {
                                focusMsgInput()
                              }, 100)
                            }}>
                            <Option
                              key={0}
                              extra={{ interface: 'XIAO_WU_35' }}
                              value={'gpt35'}>
                              {XIAO_WU_35}
                            </Option>
                            <Option
                              key={1}
                              extra={{ interface: 'XIAO_WU_40' }}
                              value={'gpt40'}>
                              {XIAO_WU_40}
                            </Option>
                            <Option
                              key={2}
                              extra={{ interface: 'XIAO_ZHI' }}
                              value={'wenxin'}>
                              {XIAO_ZHI}
                            </Option>
                            <Option
                              key={3}
                              extra={{ interface: 'XIAO_ZHI_QW' }}
                              value={'tyqw'}>
                              {XIAO_ZHI_QW}
                            </Option>
                          </Select>
                        }></BorderBtn>
                    </div>
                  </>
                ) : (
                  <div className="contentTopIconIn">
                    <div className="contentTopIconIn1">
                      {/* 返回 */}
                      <IconLeft
                        onClick={() => {
                          window.history.back()
                        }}
                      />
                    </div>
                    <div className="contentTopIconIn2">
                      {/* 切换助理对话 */}
                      <IconLayout
                        onClick={() => setDrawerVisible(!drawerVisible)}
                      />
                      {/* 切换助理 */}
                      <Space size="large" className="toolbox-dropdown-menu">
                        <Dropdown
                          droplist={
                            <Menu
                              onClickMenuItem={(key) => {
                                setAssistantInterface(key)
                                // 将本地记录更改
                                setDrawerChatHistory((drawerChatHistory) => {
                                  const newDrawerChatHistory = [
                                    ...drawerChatHistory,
                                  ]
                                  newDrawerChatHistory[
                                    currentListIndex
                                  ].gptVersion = key
                                  return newDrawerChatHistory
                                })
                                setTimeout(() => {
                                  focusMsgInput()
                                }, 100)
                              }}>
                              <Menu.Item key="gpt35" className="menuItem">
                                {XIAO_WU_35}
                                {assistantInterface === 'gpt35' && (
                                  <IconCheck />
                                )}
                              </Menu.Item>
                              <Menu.Item key="gpt40" className="menuItem">
                                {XIAO_WU_40}
                                {assistantInterface === 'gpt40' && (
                                  <IconCheck />
                                )}
                              </Menu.Item>
                              <Menu.Item key="wenxin" className="menuItem">
                                {XIAO_ZHI}
                                {assistantInterface === 'wenxin' && (
                                  <IconCheck />
                                )}
                              </Menu.Item>
                              <Menu.Item key="tyqw" className="menuItem">
                                {XIAO_ZHI_QW}
                                {assistantInterface === 'tyqw' && <IconCheck />}
                              </Menu.Item>
                            </Menu>
                          }
                          position="br"
                          trigger="click">
                          <IconSmartAssistantLine />
                        </Dropdown>
                      </Space>
                      {/* 指令库 */}
                      <IconPromptLibrary
                        useCurrentColor={true}
                        onClick={() => {
                          list.length ? setVisiblePrompt(true) : ''
                        }}
                      />
                      {/* 下拉框 */}
                      <Space size="large" className="toolbox-dropdown-menu">
                        <Dropdown
                          droplist={
                            <Menu
                              onClickMenuItem={(key) => {
                                if (key === 'logout') {
                                  logout()
                                } else if (key === 'order') {
                                  window.location.href =
                                    '/userCenter?activeTab=order'
                                } else if (key === 'vip') {
                                  window.location.href = '/vip'
                                } else if (key === 'toolbox') {
                                  window.location.href = '/toolbox/index'
                                } else if (key === 'set') {
                                  setModRef.current &&
                                    setModRef.current.handleCancel()
                                } else {
                                  Message.warning({
                                    icon: (
                                      <IconWarnTip useCurrentColor={false} />
                                    ),
                                    content: `You clicked ${key}`,
                                  })
                                }
                              }}>
                              <Menu.Item key="toolbox">
                                <IconSmartAssistantLine
                                  className={menuStyles['dropdown-icon']}
                                />
                                智能助理
                              </Menu.Item>
                              <Menu.Item key="order">
                                <IconMyOrder
                                  className={menuStyles['dropdown-icon']}
                                />
                                我的订单
                              </Menu.Item>
                              {/* <Menu.Item key="vip" style={{ color }}>
                              <IconVip
                                className={menuStyles['dropdown-icon']}
                              />
                              VIP 会员
                            </Menu.Item> */}
                              <Menu.Item key="set">
                                <IconSettings
                                  className={menuStyles['dropdown-icon']}
                                />
                                助理设置
                              </Menu.Item>
                              <Divider style={{ margin: '4px 0' }} />
                              <Menu.Item key="logout">
                                <IconPoweroff
                                  className={menuStyles['dropdown-icon']}
                                />
                                退出登录
                              </Menu.Item>
                            </Menu>
                          }
                          position="br"
                          trigger="click">
                          <IconMoreEdits />
                        </Dropdown>
                      </Space>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* 设置窗口 */}
            <SetMod
              isAgent={isAgent}
              width={dev == 'pc' ? '500px' : '95%'}
              ref={setModRef}
              updateAssistantConfigInfo={setAssistantConfigInfo}
            />
          </div>
          {/* 中间底部聊天记录 */}
          <div className="contentMidTop">
            {/* 对话详情 */}
            <div
              className="contentMidTopIn"
              id={`${theme == 'dark' ? 'darkcontentMidTop' : ''}`}>
              {!drawerChatHistory[currentListIndex]?.chatHistory?.length ||
              isAgentInit ? (
                <div
                  className={styles.contain}
                  style={{
                    marginLeft:
                      dev === 'pc' && width < 1220 && !special && drawerVisible
                        ? '281px'
                        : '',
                  }}>
                  <div className={styles['intelligentAssistantPage']}>
                    <div className={styles.page_inner}>
                      <div className={styles['h80']}></div>
                      {/*介绍*/}
                      <TextMod
                        initialValues={drawerChatHistory[currentListIndex]}
                        isAgentInit={isAgentInit}
                      />
                      {/*入口*/}
                      <EntryListMod
                        theme={theme}
                        isAgentInit={isAgentInit}
                        doApi={doApi}
                        initialValues={drawerChatHistory[currentListIndex]}
                        promptSend={promptSend}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`${styles['listWrap']} ${styles['mask']} ${
                      isBottom ? styles['listWrapShow'] : ''
                    }`}
                    style={{
                      marginLeft:
                        dev === 'pc' &&
                        width < 1220 &&
                        !special &&
                        drawerVisible
                          ? '281px'
                          : '',
                    }}>
                    <div className={styles['list']} ref={botListRef}>
                      {/* 历史记录 */}
                      {drawerChatHistory.length &&
                      drawerChatHistory[currentListIndex].chatHistory &&
                      drawerChatHistory[currentListIndex].chatHistory.length
                        ? drawerChatHistory[currentListIndex].chatHistory.map(
                            (item, index) => {
                              if (
                                index >=
                                  drawerChatHistory[currentListIndex]
                                    .chatHistory.length -
                                    loadMorePage * loadMoreNum &&
                                item.isShow != false
                              ) {
                                return (
                                  <div key={index + Date.now()}>
                                    {item.user === 'user' && (
                                      <div
                                        className={`${styles['chatItem']} ${styles[theme]}`}
                                        style={
                                          IS_FULL_SCREEN
                                            ? {
                                                maxWidth: `${CHAT_AREA_WIDTH}%`,
                                              }
                                            : {}
                                        }>
                                        {dev == 'pc' ? (
                                          <>
                                            <div className={styles['face']}>
                                              <UserAvatar
                                                name={localStorage.getItem(
                                                  'userName',
                                                )}></UserAvatar>
                                            </div>
                                            <div
                                              className={`${styles['text']} ${styles[theme]}`}>
                                              <div
                                                className={`${styles['title']} ${styles[theme]}`}>
                                                <div className={styles['name']}>
                                                  {item.description}
                                                </div>
                                                <div
                                                  className={
                                                    styles['poi']
                                                  }></div>
                                                <div className={styles['date']}>
                                                  {item.time}
                                                </div>
                                              </div>
                                              <div
                                                className={styles['content']}
                                                style={{
                                                  fontSize: CONTENT_FONT_SIZE,
                                                }}>
                                                {item.content}
                                              </div>
                                              {/* 按钮 */}
                                              <div
                                                className={`${styles['userBut']} ${styles[theme]}`}>
                                                <div className={styles['copy']}>
                                                  <CopyButton
                                                    mes={
                                                      width > mobileWidth
                                                        ? '复制'
                                                        : ''
                                                    }
                                                    text={item.content}
                                                  />
                                                </div>
                                                <div className={styles['edit']}>
                                                  <IconBtn
                                                    text={
                                                      dev == 'pc' &&
                                                      width > mobileWidth
                                                        ? '编辑'
                                                        : ''
                                                    }
                                                    iconLeft={<IconEdit />}
                                                    onClick={() => {
                                                      setTextQ(item.content)
                                                      focusMsgInput()
                                                      scrollBotList()
                                                    }}></IconBtn>
                                                </div>
                                                <div
                                                  className={`${styles['reanswer']}`}>
                                                  <IconBtn
                                                    text={
                                                      dev == 'pc' &&
                                                      width > mobileWidth
                                                        ? '刷新'
                                                        : ''
                                                    }
                                                    iconLeft={<IconSync />}
                                                    onClick={() => {
                                                      if (
                                                        item.reanswer.type ==
                                                        'ANSWER'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doUriApi(
                                                              item.fileId,
                                                              item.content,
                                                              item.reanswer
                                                                .params.uri,
                                                              true,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                        'PLUGIN'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doAppApi(
                                                              item.content,
                                                              item.reanswer
                                                                .params.uri,
                                                              true,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                          'GPT35' ||
                                                        item.reanswer.type ==
                                                          'GPT40'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doApi(
                                                              item.content,
                                                              '',
                                                              true,
                                                              item.reanswer,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                        'WENXIN'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doWenXinApi(
                                                              item.content,
                                                              '',
                                                              item.reanswer,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                        'TYQW'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doQWApi(
                                                              item.content,
                                                              '',
                                                              item.reanswer,
                                                            )
                                                      }
                                                    }}></IconBtn>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className={styles['chatItemTop']}>
                                              <div className={styles['face']}>
                                                <IconFace1 />
                                              </div>
                                              <div className={styles['text']}>
                                                <div
                                                  className={`${styles['title']} ${styles[theme]}`}>
                                                  <div
                                                    className={styles['name']}>
                                                    {item.description}
                                                  </div>
                                                  <div
                                                    className={
                                                      styles['poi']
                                                    }></div>
                                                  <div
                                                    className={styles['date']}>
                                                    {item.time}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={styles['chatItemBot']}>
                                              <div
                                                className={styles['content']}>
                                                {item.content}
                                              </div>
                                              {/* 按钮 */}
                                              <div
                                                className={styles['userBut']}>
                                                <div className={styles['copy']}>
                                                  <CopyButton
                                                    text={item.content}
                                                  />
                                                </div>
                                                <div className={styles['edit']}>
                                                  <IconBtn
                                                    text={
                                                      dev == 'pc' &&
                                                      width > mobileWidth
                                                        ? '编辑'
                                                        : ''
                                                    }
                                                    iconLeft={<IconEdit />}
                                                    onClick={() => {
                                                      setTextQ(item.content)
                                                      focusMsgInput()
                                                      scrollBotList()
                                                    }}></IconBtn>
                                                </div>
                                                <div
                                                  className={`${styles['reanswer']} ${styles[theme]}`}>
                                                  <IconBtn
                                                    text={
                                                      dev == 'pc' &&
                                                      width > mobileWidth
                                                        ? '刷新'
                                                        : ''
                                                    }
                                                    iconLeft={<IconSync />}
                                                    onClick={() => {
                                                      console.log(item)
                                                      if (
                                                        item.reanswer.type ==
                                                        'ANSWER'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doUriApi(
                                                              item.fileId,
                                                              item.content,
                                                              item.reanswer
                                                                .params.uri,
                                                              true,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                          'GPT35' ||
                                                        item.reanswer.type ==
                                                          'GPT40'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doApi(
                                                              item.content,
                                                              '',
                                                              true,
                                                              item.reanswer,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                        'WENXIN'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doWenXinApi(
                                                              item.content,
                                                              '',
                                                              item.reanswer,
                                                            )
                                                      } else if (
                                                        item.reanswer.type ==
                                                        'TYQW'
                                                      ) {
                                                        loading || answerLoading
                                                          ? Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '正在回答中...',
                                                            })
                                                          : doQWApi(
                                                              item.content,
                                                              '',
                                                              item.reanswer,
                                                            )
                                                      }
                                                    }}></IconBtn>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {item.user === 'robot' && (
                                      <div
                                        className={`${styles['chatItem']} ${styles['robot']}`}
                                        style={
                                          IS_FULL_SCREEN
                                            ? {
                                                maxWidth: `${CHAT_AREA_WIDTH}%`,
                                              }
                                            : {}
                                        }>
                                        {dev == 'pc' ? (
                                          <>
                                            {item.type == 'agent' ? (
                                              <div className={styles['face']}>
                                                {item?.faceImg ? (
                                                  <img
                                                    src={item?.faceImg}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <AgentImg />
                                                )}
                                              </div>
                                            ) : (
                                              <div className={styles['face']}>
                                                <IconFace2 />
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <div className={styles['robotTop']}>
                                            {item.type == 'agent' ? (
                                              <div className={styles['face']}>
                                                {item?.faceImg ? (
                                                  <img
                                                    src={item?.faceImg}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <AgentImg />
                                                )}
                                              </div>
                                            ) : (
                                              <div className={styles['face']}>
                                                <IconFace2 />
                                              </div>
                                            )}
                                            <div
                                              className={`${styles['title']} ${styles[theme]}`}>
                                              <div className={styles['name']}>
                                                {item.description}
                                              </div>
                                              <div
                                                className={styles['poi']}></div>
                                              <div className={styles['date']}>
                                                {item.time}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className={`${styles['text']} ${styles[theme]}`}>
                                          {dev == 'pc' && (
                                            <div
                                              className={`${styles['title']} ${styles[theme]}`}>
                                              <div className={styles['name']}>
                                                {item.description}
                                              </div>
                                              <div
                                                className={styles['poi']}></div>
                                              <div className={styles['date']}>
                                                {item.time}
                                              </div>
                                            </div>
                                          )}
                                          <div
                                            className={`${styles['answer']} ${styles[theme]}`}
                                            style={{
                                              fontSize: CONTENT_FONT_SIZE,
                                            }}>
                                            {/* 文本展示 */}
                                            {/* <div
                                              className={`${styles['content']}`}></div> */}
                                            <Markdown
                                              theme={theme}
                                              content={item.content}
                                              isCodeCopyBtn={true}
                                              blogPreStyle={
                                                IS_FULL_SCREEN
                                                  ? {
                                                      maxWidth: 'none',
                                                    }
                                                  : {}
                                              }
                                            />
                                            {/* 文件详情及按钮展示 */}
                                            <div className="interest">
                                              {item.summary ||
                                              item.outlinesArray ||
                                              item.questionsArray ||
                                              item.tagsArray ? (
                                                <>
                                                  <div className="screen">
                                                    {/* summary */}
                                                    {isObject(item.summary) &&
                                                    item.summary.isError ===
                                                      0 ? (
                                                      <div>
                                                        <p>
                                                          {item.summary.data}
                                                        </p>
                                                      </div>
                                                    ) : isObject(
                                                        item.summary,
                                                      ) &&
                                                      item.summary.isError ===
                                                        1 ? (
                                                      <div>
                                                        <p>
                                                          {item.summary.data}
                                                        </p>
                                                      </div>
                                                    ) : isObject(
                                                        item.summary,
                                                      ) &&
                                                      item.summary.isError ===
                                                        2 ? (
                                                      <>
                                                        <p className="lastChildNodeTop"></p>
                                                        <p className="lastChildNodeTop"></p>
                                                        <p className="lastChildNodeTop"></p>
                                                        <p className="lastChildNodeTop"></p>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}

                                                    {/* 大纲： */}
                                                    {INTERFACE_OUTLINES &&
                                                    isObject(
                                                      item.outlinesArray,
                                                    ) &&
                                                    item.outlinesArray
                                                      .isError === 0 ? (
                                                      <>
                                                        <p className="title">
                                                          大纲：
                                                        </p>
                                                        <div className="screenButton">
                                                          {item.outlinesArray.data.map(
                                                            (itemIn, indexIn) =>
                                                              itemIn !== '' && (
                                                                <Tooltip
                                                                  key={indexIn}
                                                                  content={
                                                                    itemIn
                                                                  }>
                                                                  <Button
                                                                    type="primary"
                                                                    onClick={() => {
                                                                      doUriApi(
                                                                        item.fileId,
                                                                        itemIn,
                                                                        item.uri,
                                                                      )
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          CONTENT_FONT_SIZE,
                                                                      }}>
                                                                      {itemIn}
                                                                    </span>
                                                                  </Button>
                                                                </Tooltip>
                                                              ),
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_OUTLINES &&
                                                      isObject(
                                                        item.outlinesArray,
                                                      ) &&
                                                      item.outlinesArray
                                                        .isError === 1 ? (
                                                      <>
                                                        <p className="title">
                                                          大纲：
                                                        </p>
                                                        <div className="screenButton">
                                                          {
                                                            item.outlinesArray
                                                              .data
                                                          }
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_OUTLINES &&
                                                      isObject(
                                                        item.outlinesArray,
                                                      ) &&
                                                      item.outlinesArray
                                                        .isError === 2 ? (
                                                      <>
                                                        <p className="title">
                                                          大纲：
                                                        </p>
                                                        <div className="lastChildNodeBot">
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}

                                                    {/* 相关问题： */}
                                                    {INTERFACE_QUESTIONS &&
                                                    isObject(
                                                      item.questionsArray,
                                                    ) &&
                                                    item.questionsArray
                                                      .isError === 0 ? (
                                                      <>
                                                        <p className="title">
                                                          相关问题：
                                                        </p>
                                                        <div className="screenButton">
                                                          {item.questionsArray.data.map(
                                                            (itemIn, indexIn) =>
                                                              itemIn !== '' && (
                                                                <Tooltip
                                                                  key={indexIn}
                                                                  content={
                                                                    itemIn
                                                                  }>
                                                                  <Button
                                                                    type="primary"
                                                                    onClick={() => {
                                                                      doUriApi(
                                                                        item.fileId,
                                                                        itemIn,
                                                                        item.uri,
                                                                      )
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          CONTENT_FONT_SIZE,
                                                                      }}>
                                                                      {itemIn}
                                                                    </span>
                                                                  </Button>
                                                                </Tooltip>
                                                              ),
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_QUESTIONS &&
                                                      isObject(
                                                        item.questionsArray,
                                                      ) &&
                                                      item.questionsArray
                                                        .isError === 1 ? (
                                                      <>
                                                        <p className="title">
                                                          相关问题：
                                                        </p>
                                                        <div className="screenButton">
                                                          {
                                                            item.questionsArray
                                                              .data
                                                          }
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_QUESTIONS &&
                                                      isObject(
                                                        item.questionsArray,
                                                      ) &&
                                                      item.questionsArray
                                                        .isError === 2 ? (
                                                      <>
                                                        <p className="title">
                                                          相关问题：
                                                        </p>
                                                        <div className="lastChildNodeBot">
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}

                                                    {/* 关键字： */}
                                                    {INTERFACE_TAGS &&
                                                    isObject(item.tagsArray) &&
                                                    item.tagsArray.isError ===
                                                      0 ? (
                                                      <>
                                                        <p className="title">
                                                          关键字：
                                                        </p>
                                                        <div className="screenButton">
                                                          {item.tagsArray.data.map(
                                                            (itemIn, indexIn) =>
                                                              itemIn !== '' && (
                                                                <Tooltip
                                                                  key={indexIn}
                                                                  content={
                                                                    itemIn
                                                                  }>
                                                                  <Button
                                                                    type="primary"
                                                                    onClick={() => {
                                                                      doUriApi(
                                                                        item.fileId,
                                                                        itemIn,
                                                                        item.uri,
                                                                      )
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          CONTENT_FONT_SIZE,
                                                                      }}>
                                                                      {itemIn}
                                                                    </span>
                                                                  </Button>
                                                                </Tooltip>
                                                              ),
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_TAGS &&
                                                      isObject(
                                                        item.tagsArray,
                                                      ) &&
                                                      item.tagsArray.isError ===
                                                        1 ? (
                                                      <>
                                                        <p className="title">
                                                          关键字：
                                                        </p>
                                                        <div className="screenButton">
                                                          {item.tagsArray.data}
                                                        </div>
                                                      </>
                                                    ) : INTERFACE_TAGS &&
                                                      isObject(
                                                        item.tagsArray,
                                                      ) &&
                                                      item.tagsArray.isError ===
                                                        2 ? (
                                                      <>
                                                        <p className="title">
                                                          关键字：
                                                        </p>
                                                        <div className="lastChildNodeBot">
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                          <p className="lastChildNodeBotIn"></p>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}

                                                    {/* 生成脑图 */}
                                                    <div className="title">
                                                      🧠 生成脑图：
                                                      <div className="screenButton">
                                                        <Button
                                                          type="primary"
                                                          onClick={() =>
                                                            generatingBrainMaps(
                                                              item.uri,
                                                            )
                                                          }>
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                CONTENT_FONT_SIZE,
                                                            }}>
                                                            点击查看
                                                          </span>
                                                        </Button>
                                                      </div>
                                                    </div>

                                                    {/* 知识图谱 */}
                                                    <div className="title">
                                                      📚 知识图谱：
                                                      <div className="screenButton">
                                                        <Button
                                                          type="primary"
                                                          onClick={() =>
                                                            knowledgeGraph(
                                                              item.uri,
                                                            )
                                                          }>
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                CONTENT_FONT_SIZE,
                                                            }}>
                                                            点击查看
                                                          </span>
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                            {/* 按钮 */}
                                            <div
                                              className={`${styles['tools']}`}>
                                              {/* 左边按钮 */}
                                              <div
                                                className={`${styles['left']} ${styles[theme]}`}>
                                                {item.content !== '' ? (
                                                  <>
                                                    {/* 编辑 */}
                                                    <IconBtn
                                                      theme={theme}
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '编辑'
                                                          : ''
                                                      }
                                                      iconLeft={<IconEdit />}
                                                      onClick={() => {
                                                        setTextQ(item.content)
                                                        focusMsgInput()
                                                        scrollBotList()
                                                      }}></IconBtn>
                                                    {/* 复制 */}
                                                    <Copy
                                                      text={item.content}
                                                      onCopy={() => {
                                                        Message.success({
                                                          icon: (
                                                            <IconSuccessTip
                                                              useCurrentColor={
                                                                false
                                                              }
                                                            />
                                                          ),
                                                          content: '已复制',
                                                        })
                                                      }}
                                                      content={
                                                        <IconBtn
                                                          theme={theme}
                                                          text={
                                                            dev == 'pc' &&
                                                            width > mobileWidth
                                                              ? '复制'
                                                              : ''
                                                          }
                                                          iconLeft={
                                                            <IconCopy />
                                                          }></IconBtn>
                                                      }
                                                    />

                                                    {dev == 'pc' ? (
                                                      <IconBtn
                                                        theme={theme}
                                                        text={
                                                          dev == 'pc' &&
                                                          width > mobileWidth
                                                            ? '添加'
                                                            : ''
                                                        }
                                                        onClick={() => {
                                                          request
                                                            .post(
                                                              api +
                                                                '/document/save',
                                                              JSON.stringify({
                                                                approvalStatus: 1,
                                                                content:
                                                                  item.content
                                                                    .split('\n')
                                                                    .filter(
                                                                      (line) =>
                                                                        line.trim() !==
                                                                        '',
                                                                    )
                                                                    .map(
                                                                      (
                                                                        line,
                                                                        index,
                                                                      ) =>
                                                                        `<p>${line}</p >`,
                                                                    )
                                                                    .join(''),
                                                                docStatus: 0,
                                                                docType: 0,
                                                                docWords: null,
                                                                filePath: '/',
                                                                fileType: 0,
                                                                folderUuid: '',
                                                                title:
                                                                  item.sendMessage,
                                                                uuid: '',
                                                              }),
                                                            )
                                                            .then((res) => {
                                                              if (
                                                                res.data.code ==
                                                                '500200'
                                                              ) {
                                                                const newUrl =
                                                                  window
                                                                    .location
                                                                    .origin
                                                                window.open(
                                                                  `${newUrl}/documentEdit?uuid=${res.data.data}
                                                                `,
                                                                )
                                                                // window.location.href = `/documentEdit?uuid=${res.data.data}`
                                                                Message.success(
                                                                  {
                                                                    icon: (
                                                                      <IconSuccessTip
                                                                        useCurrentColor={
                                                                          false
                                                                        }
                                                                      />
                                                                    ),
                                                                    content:
                                                                      '添加成功！',
                                                                  },
                                                                )
                                                              } else {
                                                                Message.error({
                                                                  icon: (
                                                                    <IconErrorTip
                                                                      useCurrentColor={
                                                                        false
                                                                      }
                                                                    />
                                                                  ),
                                                                  content:
                                                                    '添加失败！',
                                                                })
                                                              }
                                                            })
                                                            .catch((err) => {
                                                              Message.error({
                                                                icon: (
                                                                  <IconErrorTip
                                                                    useCurrentColor={
                                                                      false
                                                                    }
                                                                  />
                                                                ),
                                                                content:
                                                                  '添加失败！',
                                                              })
                                                            })
                                                        }}
                                                        iconLeft={
                                                          <IconAdd />
                                                        }></IconBtn>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : isObject(item.summary) &&
                                                  item.summary.isError === 0 ? (
                                                  <>
                                                    <IconBtn
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '编辑'
                                                          : ''
                                                      }
                                                      iconLeft={<IconEdit />}
                                                      onClick={() => {
                                                        setTextQ(
                                                          item.summary.data,
                                                        )
                                                        focusMsgInput()
                                                        scrollBotList()
                                                      }}></IconBtn>
                                                    <CopyButton
                                                      text={item.summary.data}
                                                    />
                                                    {dev == 'pc' ? (
                                                      <IconBtn
                                                        text={
                                                          dev == 'pc' &&
                                                          width > mobileWidth
                                                            ? '添加'
                                                            : ''
                                                        }
                                                        onClick={() => {
                                                          request
                                                            .post(
                                                              api +
                                                                '/document/save',
                                                              JSON.stringify({
                                                                approvalStatus: 1,
                                                                content:
                                                                  item.summary.data
                                                                    .split('\n')
                                                                    .filter(
                                                                      (line) =>
                                                                        line.trim() !==
                                                                        '',
                                                                    )
                                                                    .map(
                                                                      (
                                                                        line,
                                                                        index,
                                                                      ) =>
                                                                        `<p>${line}</p >`,
                                                                    )
                                                                    .join(''),
                                                                docStatus: 0,
                                                                docType: 0,
                                                                docWords: null,
                                                                filePath: '/',
                                                                fileType: 0,
                                                                folderUuid: '',
                                                                title:
                                                                  item.sendMessage,
                                                                uuid: '',
                                                              }),
                                                            )
                                                            .then((res) => {
                                                              if (
                                                                res.data.code ==
                                                                '500200'
                                                              ) {
                                                                const newUrl =
                                                                  window
                                                                    .location
                                                                    .origin
                                                                window.open(
                                                                  `${newUrl}/documentEdit?uuid=${res.data.data}
                                                              `,
                                                                )
                                                                // window.location.href = `/documentEdit?uuid=${res.data.data}`
                                                                Message.success(
                                                                  {
                                                                    icon: (
                                                                      <IconSuccessTip
                                                                        useCurrentColor={
                                                                          false
                                                                        }
                                                                      />
                                                                    ),
                                                                    content:
                                                                      '添加成功！',
                                                                  },
                                                                )
                                                              } else {
                                                                Message.error({
                                                                  icon: (
                                                                    <IconErrorTip
                                                                      useCurrentColor={
                                                                        false
                                                                      }
                                                                    />
                                                                  ),
                                                                  content:
                                                                    '添加失败！',
                                                                })
                                                              }
                                                            })
                                                            .catch((err) => {
                                                              Message.error({
                                                                icon: (
                                                                  <IconErrorTip
                                                                    useCurrentColor={
                                                                      false
                                                                    }
                                                                  />
                                                                ),
                                                                content:
                                                                  '添加失败！',
                                                              })
                                                            })
                                                        }}
                                                        iconLeft={
                                                          <IconAdd />
                                                        }></IconBtn>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                              {/* 右边按钮 */}
                                              <div
                                                className={`${styles['right']} ${styles[theme]}`}>
                                                {(item.content !== '' &&
                                                  item.messageId &&
                                                  item.conversationId) ||
                                                (item.content !== '' &&
                                                  item.messageId40 &&
                                                  item.conversationId40) ||
                                                item.fileCategory == 'by' ? (
                                                  <>
                                                    <IconBtn
                                                      theme={theme}
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '重返'
                                                          : ''
                                                      }
                                                      onClick={() => {
                                                        focusMsgInput()
                                                        item.fileCategory
                                                          ? setCurrentFileCategory(
                                                              item.fileCategory,
                                                            )
                                                          : setCurrentFileCategory(
                                                              '',
                                                            )
                                                        if (
                                                          item.fileCategory ==
                                                          'by'
                                                        ) {
                                                          setUri('')
                                                          setPrompt(item.prompt)
                                                          if (
                                                            prompt ==
                                                            item.prompt
                                                          ) {
                                                            Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '已在当前对话中！',
                                                            })
                                                          } else {
                                                            setMessageId('')
                                                            setConversationId(
                                                              '',
                                                            )
                                                            setMessageId40('')
                                                            setConversationId40(
                                                              '',
                                                            )
                                                            setFileId(
                                                              item.fileId,
                                                            )
                                                            tagsReturnTo(
                                                              item.fileId,
                                                            )
                                                            setActive([
                                                              item.fileId,
                                                              true,
                                                            ])
                                                            Message.success({
                                                              icon: (
                                                                <IconSuccessTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '已回到该对话！',
                                                            })
                                                          }
                                                        } else {
                                                          if (
                                                            (messageId ==
                                                              item.messageId &&
                                                              conversationId ==
                                                                item.conversationId) ||
                                                            (messageId40 ==
                                                              item.messageId40 &&
                                                              conversationId40 ==
                                                                item.conversationId40)
                                                          ) {
                                                            Message.warning({
                                                              icon: (
                                                                <IconWarnTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '已在当前对话中！',
                                                            })
                                                          } else {
                                                            setUri('')
                                                            setPrompt('')
                                                            setFileId(
                                                              item.fileId,
                                                            )
                                                            if (
                                                              item.messageId &&
                                                              item.conversationId
                                                            ) {
                                                              setMessageId(
                                                                item.messageId,
                                                              )
                                                              setConversationId(
                                                                item.conversationId,
                                                              )
                                                            } else if (
                                                              item.messageId40 &&
                                                              item.conversationId40
                                                            ) {
                                                              setMessageId(
                                                                item.messageId40,
                                                              )
                                                              setConversationId(
                                                                item.conversationId40,
                                                              )
                                                            }
                                                            Message.success({
                                                              icon: (
                                                                <IconSuccessTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '已回到该对话！',
                                                            })
                                                          }
                                                          setActive([-1, false])
                                                        }
                                                      }}
                                                      iconLeft={
                                                        <IconRedo />
                                                      }></IconBtn>
                                                  </>
                                                ) : (isObject(item.summary) &&
                                                    item.summary.isError ===
                                                      1) ||
                                                  (isObject(
                                                    item.outlinesArray,
                                                  ) &&
                                                    item.outlinesArray
                                                      .isError === 1) ||
                                                  (isObject(
                                                    item.questionsArray,
                                                  ) &&
                                                    item.questionsArray
                                                      .isError === 1) ||
                                                  (isObject(item.tagsArray) &&
                                                    item.tagsArray.isError ===
                                                      1) ? (
                                                  <>
                                                    <IconBtn
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '刷新'
                                                          : ''
                                                      }
                                                      disable={loading}
                                                      iconLeft={<IconRefresh />}
                                                      onClick={() => {
                                                        refreshST(item, index)
                                                      }}></IconBtn>
                                                  </>
                                                ) : (isObject(item.summary) &&
                                                    item.summary.isError ===
                                                      0) ||
                                                  (isObject(
                                                    item.outlinesArray,
                                                  ) &&
                                                    item.outlinesArray
                                                      .isError === 0) ||
                                                  (isObject(
                                                    item.questionsArray,
                                                  ) &&
                                                    item.questionsArray
                                                      .isError === 0) ||
                                                  (isObject(item.tagsArray) &&
                                                    item.tagsArray.isError ===
                                                      0) ? (
                                                  <>
                                                    {/* 回到文件 */}
                                                    <IconBtn
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '重返'
                                                          : ''
                                                      }
                                                      disabled={loading}
                                                      disable={loading}
                                                      iconLeft={<IconRedo />}
                                                      onClick={() => {
                                                        focusMsgInput()
                                                        item.fileCategory
                                                          ? setCurrentFileCategory(
                                                              item.fileCategory,
                                                            )
                                                          : setCurrentFileCategory(
                                                              '',
                                                            )
                                                        setPrompt('')
                                                        setMessageId('')
                                                        setConversationId('')
                                                        setMessageId40('')
                                                        setConversationId40('')
                                                        if (
                                                          item.uri &&
                                                          item.uri != uri
                                                        ) {
                                                          setUri(item.uri)
                                                          tagsReturnTo(
                                                            item.fileId,
                                                          )
                                                          setActive([
                                                            item.fileId,
                                                            true,
                                                          ])
                                                          Message.success({
                                                            icon: (
                                                              <IconSuccessTip
                                                                useCurrentColor={
                                                                  false
                                                                }
                                                              />
                                                            ),
                                                            content:
                                                              '已回到此文件，请继续提问吧！',
                                                          })
                                                        } else {
                                                          Message.warning({
                                                            icon: (
                                                              <IconWarnTip
                                                                useCurrentColor={
                                                                  false
                                                                }
                                                              />
                                                            ),
                                                            content:
                                                              '已在当前对话中！',
                                                          })
                                                        }
                                                      }}></IconBtn>
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                                {/* 收藏 */}
                                                {item.messageId ||
                                                item.messageId40 ? (
                                                  item.isCollect ? (
                                                    <IconBtn
                                                      theme={theme}
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '取消收藏'
                                                          : ''
                                                      }
                                                      onClick={() => {
                                                        request
                                                          .post(
                                                            api +
                                                              '/chatGPT/cancelChatCollect',
                                                            {
                                                              messageIds: [
                                                                item.messageId ||
                                                                  item.messageId40,
                                                              ],
                                                            },
                                                          )
                                                          .then((res) => {
                                                            console.log(res)

                                                            if (
                                                              res.data.code ==
                                                              '500200'
                                                            ) {
                                                              pushDrawerChatHistory(
                                                                'isCollect',
                                                                false,
                                                                index,
                                                              )
                                                              Message.success({
                                                                icon: (
                                                                  <IconSuccessTip
                                                                    useCurrentColor={
                                                                      false
                                                                    }
                                                                  />
                                                                ),
                                                                content:
                                                                  '取消收藏成功！',
                                                              })
                                                            } else {
                                                              Message.error({
                                                                icon: (
                                                                  <IconErrorTip
                                                                    useCurrentColor={
                                                                      false
                                                                    }
                                                                  />
                                                                ),
                                                                content:
                                                                  '取消收藏失败！',
                                                              })
                                                            }
                                                          })
                                                          .catch((err) => {
                                                            Message.error({
                                                              icon: (
                                                                <IconErrorTip
                                                                  useCurrentColor={
                                                                    false
                                                                  }
                                                                />
                                                              ),
                                                              content:
                                                                '网络连接失败，请重试！',
                                                            })
                                                          })
                                                      }}
                                                      iconLeft={
                                                        <IconStarFill />
                                                      }
                                                      style={{
                                                        color,
                                                      }}></IconBtn>
                                                  ) : (
                                                    <IconBtn
                                                      theme={theme}
                                                      text={
                                                        dev == 'pc' &&
                                                        width > mobileWidth
                                                          ? '收藏'
                                                          : ''
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          item.messageId ||
                                                          item.messageId40
                                                        ) {
                                                          request
                                                            .post(
                                                              api +
                                                                '/chatGPT/chatCollect',
                                                              {
                                                                messageId:
                                                                  item.messageId ||
                                                                  item.messageId40,
                                                              },
                                                            )
                                                            .then((res) => {
                                                              console.log(res)

                                                              if (
                                                                res.data.code ==
                                                                '500200'
                                                              ) {
                                                                pushDrawerChatHistory(
                                                                  'isCollect',
                                                                  true,
                                                                  index,
                                                                )
                                                                Message.success(
                                                                  {
                                                                    icon: (
                                                                      <IconSuccessTip
                                                                        useCurrentColor={
                                                                          false
                                                                        }
                                                                      />
                                                                    ),
                                                                    content:
                                                                      '收藏成功！',
                                                                  },
                                                                )
                                                              } else {
                                                                Message.error({
                                                                  icon: (
                                                                    <IconErrorTip
                                                                      useCurrentColor={
                                                                        false
                                                                      }
                                                                    />
                                                                  ),
                                                                  content:
                                                                    '收藏失败！',
                                                                })
                                                              }
                                                            })
                                                            .catch((err) => {
                                                              Message.error({
                                                                icon: (
                                                                  <IconErrorTip
                                                                    useCurrentColor={
                                                                      false
                                                                    }
                                                                  />
                                                                ),
                                                                content:
                                                                  '网络连接失败，请重试！',
                                                              })
                                                            })
                                                        }
                                                      }}
                                                      iconLeft={
                                                        <IconFav />
                                                      }></IconBtn>
                                                  )
                                                ) : (
                                                  ''
                                                )}
                                                {/* 分享 */}
                                                {(item.messageId ||
                                                  item.messageId40) &&
                                                item.content &&
                                                item.sendMessage &&
                                                item.meTime != '' ? (
                                                  <IconBtn
                                                    theme={theme}
                                                    text={
                                                      dev == 'pc' &&
                                                      width > mobileWidth
                                                        ? '分享'
                                                        : ''
                                                    }
                                                    iconLeft={
                                                      dev == 'pc' ? (
                                                        <IconShare />
                                                      ) : (
                                                        <IconDownload />
                                                      )
                                                    }
                                                    onClick={() => {
                                                      setSharingConversationsItem(
                                                        item,
                                                      )
                                                      setSharingConversationsVisible(
                                                        true,
                                                      )
                                                    }}></IconBtn>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              }
                            },
                          )
                        : ''}
                    </div>
                  </div>
                  {/* 回到当前位置 */}
                  {isBottom && width < mobileWidth ? (
                    <div className="downCircle">
                      <IconDownCircle
                        onClick={() => {
                          scrollBotList()
                          setIsBottom(false)
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {/* 加载中 */}
                  {loading && (
                    <div className={styles['loading']}>
                      努力加载中
                      <div className={styles['loading-poi']}></div>
                      <div className={styles['loading-poi']}></div>
                      <div className={styles['loading-poi']}></div>
                    </div>
                  )}

                  {/* 对话分享框 */}
                  <SharePop
                    drawerChatHistory={drawerChatHistory}
                    currentListIndex={currentListIndex}
                    theme={theme}
                    width={dev == 'pc' ? '' : '90%'}
                    sharingConversationsItem={sharingConversationsItem}
                    visible={sharingConversationsVisible}
                    close={() => setSharingConversationsVisible(false)}
                  />
                </>
              )}
            </div>
            {/* 对话列表 */}
            <div className="drawer-assistant-list-content" ref={refWrapper}>
              <Drawer
                title={null}
                closeIcon={null}
                className="drawer-assistant-list"
                visible={drawerVisible}
                mask={false}
                autoFocus={false}
                focusLock={false}
                placement={'left'}
                getPopupContainer={() => refWrapper && refWrapper.current}
                footer={null}
                onOk={() => {
                  setDrawerVisible(false)
                }}
                onCancel={() => {
                  setDrawerVisible(false)
                }}>
                <div className={`drawerContent  ${styles[theme]}`}>
                  {/* 左边关闭对话按钮 */}
                  {/* <div
                    className="closeButton"
                    onClick={() => {
                      setDrawerVisible(false)
                    }}>
                    <IconArrowLeft />
                  </div> */}

                  {/* 顶部按钮 */}
                  <div className="drawerContentTop">
                    {/* 新建对话 */}
                    <Button
                      id="createNewTalk"
                      className={`newButton ${
                        !loading && !answerLoading
                          ? ''
                          : 'disabled-button-style'
                      }`}
                      size="large"
                      type="outline"
                      icon={<IconPlus />}
                      disabled={!loading && !answerLoading ? false : true}
                      onClick={() => {
                        const currentTime = new Date().getTime()

                        if (currentTime - lastClickTime > 1000) {
                          if (!loading && !answerLoading) {
                            // 清空form值
                            form.setFieldsValue({
                              url: '',
                            })
                            if (dev == 'androidApp') {
                              setAssistantInterface('wenxin')
                            } else {
                              // 回到默认接口
                              returnToDefaultInterface(INTERFACE_DEFAULT_MODEL)
                            }
                            setIsAgent(false)
                            setIsAgentInit(false)
                            setPluginName('')
                            setPluginId('')
                            setCurrentListIndex(0)
                            setFileList([])
                            setUri('')
                            setUrl('')
                            setMessageId('')
                            setConversationId('')
                            setMessageId40('')
                            setConversationId40('')
                            setPrompt('')
                            setActive([-1, false])
                            setDisabledButton(false)
                            setDisabledUpload(false)
                            const newDrawerChatHistory = [...drawerChatHistory]
                            newDrawerChatHistory.unshift({
                              title: '新建对话',
                              createTime: getCurrentAllTime(),
                              gptVersion:
                                dev == 'androidApp'
                                  ? 'wenxin'
                                  : returnToDefaultInterface(
                                      INTERFACE_DEFAULT_MODEL,
                                    ), // 移动端默认只能使用wenxin
                              chatHistory: [
                                {
                                  user: 'robot',
                                  time: getCurrentTime(),
                                  description: returnInterfaceName(
                                    returnToDefaultInterface(
                                      INTERFACE_DEFAULT_MODEL,
                                    ),
                                  ),
                                  interface:
                                    dev == 'androidApp' ? 'wenxin' : 'gpt35',
                                  content:
                                    '您好！我是' +
                                    returnInterfaceName(
                                      returnToDefaultInterface(
                                        INTERFACE_DEFAULT_MODEL,
                                      ),
                                    ) +
                                    '，我的主要功能包括回答各种问题、提供准确可信的信息、语音交互、多语言支持和学习能力。\n\n我的优点是可以处理多个任务，例如翻译、生成文本、摘要、代码等，您可以随时向我提出您的问题，我会尽力为您解决。\n\n同时，我也支持多轮连续对话，帮助您更好地获取所需信息。如果您遇到任何问题，可以直接询问我。',
                                },
                              ],
                            })
                            setDrawerChatHistory(newDrawerChatHistory)
                            width < mobileWidth && setDrawerVisible(false)
                            Message.success({
                              icon: <IconSuccessTip useCurrentColor={false} />,
                              content: '添加成功！',
                            })
                            focusMsgInput()
                          } else {
                            Message.error({
                              icon: <IconErrorTip useCurrentColor={false} />,
                              content: '助理正在运行中，请稍后...',
                            })
                          }
                          lastClickTime = currentTime
                        } else {
                          Message.error({
                            icon: <IconErrorTip useCurrentColor={false} />,
                            content: '您的速度太快了...',
                          })
                        }
                      }}>
                      新建对话
                    </Button>
                    {/* {isAgent && (
                      <Button
                        className={`newButton ${
                          !loading && !answerLoading
                            ? ''
                            : 'disabled-button-style'
                        }`}
                        size="large"
                        type="outline"
                        style={{ width: '30px' }}
                        icon={<IconAgent />}
                        disabled={!loading && !answerLoading ? false : true}
                        onClick={() => {
                          const currentTime = new Date().getTime()

                          if (currentTime - lastClickTime > 1000) {
                            if (!loading && !answerLoading) {
                              // 清空form值
                              form.setFieldsValue({
                                url: '',
                              })
                              if (dev == 'androidApp') {
                                setAssistantInterface('wenxin')
                              } else {
                                // 回到默认接口
                                returnToDefaultInterface(
                                  INTERFACE_DEFAULT_MODEL,
                                )
                              }
                              setPluginName('')
                              setPluginId('')
                              setCurrentListIndex(0)
                              setFileList([])
                              setUri('')
                              setUrl('')
                              setMessageId('')
                              setConversationId('')
                              setMessageId40('')
                              setConversationId40('')
                              setPrompt('')
                              setActive([-1, false])
                              setDisabledButton(false)
                              setDisabledUpload(false)
                              const newDrawerChatHistory = [
                                ...drawerChatHistory,
                              ]
                              newDrawerChatHistory.unshift({
                                title: '新建智能体对话',
                                createTime: getCurrentAllTime(),
                                gptVersion:
                                  dev == 'androidApp'
                                    ? 'wenxin'
                                    : returnToDefaultInterface(
                                        INTERFACE_DEFAULT_MODEL,
                                      ), // 移动端默认只能使用wenxin
                                chatHistory: [
                                  {
                                    user: 'robot',
                                    time: getCurrentTime(),
                                    description: returnInterfaceName(
                                      returnToDefaultInterface(
                                        INTERFACE_DEFAULT_MODEL,
                                      ),
                                    ),
                                    interface:
                                      dev == 'androidApp' ? 'wenxin' : 'gpt35',
                                    content:
                                      '您好！我是' +
                                      returnInterfaceName(
                                        returnToDefaultInterface(
                                          INTERFACE_DEFAULT_MODEL,
                                        ),
                                      ) +
                                      '，我的主要功能包括回答各种问题、提供准确可信的信息、语音交互、多语言支持和学习能力。\n\n我的优点是可以处理多个任务，例如翻译、生成文本、摘要、代码等，您可以随时向我提出您的问题，我会尽力为您解决。\n\n同时，我也支持多轮连续对话，帮助您更好地获取所需信息。如果您遇到任何问题，可以直接询问我。',
                                  },
                                ],
                              })
                              setDrawerChatHistory(newDrawerChatHistory)
                              width < mobileWidth && setDrawerVisible(false)
                              Message.success({
                                icon: (
                                  <IconSuccessTip useCurrentColor={false} />
                                ),
                                content: '添加成功！',
                              })
                              focusMsgInput()
                            } else {
                              Message.error({
                                icon: <IconErrorTip useCurrentColor={false} />,
                                content: '助理正在运行中，请稍后...',
                              })
                            }
                            lastClickTime = currentTime
                          } else {
                            Message.error({
                              icon: <IconErrorTip useCurrentColor={false} />,
                              content: '您的速度太快了...',
                            })
                          }
                        }}>
                        新建智能体对话
                      </Button>
                    )} */}
                    {/* 删除对话 */}
                    <Popconfirm
                      autoFocus={false}
                      position="bottom"
                      disabled={!loading && !answerLoading ? false : true}
                      title={'您确定要删除所有对话吗？'}
                      icon={null}
                      onOk={() => {
                        if (
                          window.localStorage.getItem(
                            `${userInfo.id}-drawerChatHistory`,
                          )
                        ) {
                          setCurrentListIndex(0)
                          // 清空form值
                          form.setFieldsValue({
                            url: '',
                          })
                          setIsAgentInit(false)
                          setPluginName('')
                          setPluginId('')
                          setUri('')
                          setUrl('')
                          setPrompt('')
                          setMessageId('')
                          setConversationId('')
                          setMessageId40('')
                          setConversationId40('')
                          setFileList([])
                          setActive([-1, false])
                          setIsAgentInit(false) //修改智能体状态
                          setIsAgent(false) //修改智能体状态
                          if (dev == 'androidApp') {
                            setAssistantInterface('wenxin')
                          } else {
                            // 回到默认接口
                            returnToDefaultInterface(INTERFACE_DEFAULT_MODEL)
                          }
                          window.localStorage.removeItem(
                            `${userInfo.id}-drawerChatHistory`,
                          )
                          setDrawerChatHistory([
                            {
                              title: '新建对话',
                              createTime: getCurrentAllTime(),
                              gptVersion:
                                dev === 'androidApp'
                                  ? 'wenxin'
                                  : returnToDefaultInterface(
                                      INTERFACE_DEFAULT_MODEL,
                                    ), // 移动端默认只能使用wenxin
                              chatHistory: [
                                {
                                  user: 'robot',
                                  time: getCurrentTime(),
                                  description: returnInterfaceName(
                                    returnToDefaultInterface(
                                      INTERFACE_DEFAULT_MODEL,
                                    ),
                                  ),
                                  interface:
                                    dev == 'androidApp' ? 'wenxin' : 'gpt35',
                                  content:
                                    '您好！我是' +
                                    returnInterfaceName(
                                      returnToDefaultInterface(
                                        INTERFACE_DEFAULT_MODEL,
                                      ),
                                    ) +
                                    '，我的主要功能包括回答各种问题、提供准确可信的信息、语音交互、多语言支持和学习能力。\n\n我的优点是可以处理多个任务，例如翻译、生成文本、摘要、代码等，您可以随时向我提出您的问题，我会尽力为您解决。\n\n同时，我也支持多轮连续对话，帮助您更好地获取所需信息。如果您遇到任何问题，可以直接询问我。',
                                },
                              ],
                            },
                          ])
                          width < mobileWidth && setDrawerVisible(false)
                          Message.success({
                            icon: <IconSuccessTip useCurrentColor={false} />,
                            content: '删除成功！',
                          })
                        } else {
                          Message.error({
                            icon: <IconErrorTip useCurrentColor={false} />,
                            content: '删除失败！',
                          })
                        }
                      }}>
                      <Button
                        id="createNewTalk"
                        className={`delButton ${
                          !loading && !answerLoading
                            ? ''
                            : 'disabled-button-style'
                        }`}
                        size="large"
                        type="outline"
                        icon={<IconWastePaper />}
                        disabled={!loading && !answerLoading ? false : true}
                      />
                    </Popconfirm>
                  </div>

                  {/* 对话列表 */}
                  <div className="drawerContentBottom">
                    {drawerChatHistory.length > 0 &&
                      drawerChatHistory.map((item, index) => {
                        if (!item) return

                        return (
                          <div key={index}>
                            {/* 时间轴 */}
                            {timeDifferences[index] && (
                              <span className="timeDifferences">
                                {timeDifferences[index]}
                              </span>
                            )}
                            <div
                              className={`drawerContentBottomInner ${
                                currentListIndex === index &&
                                'currentDrawerContentBottomInner'
                              }`}
                              onClick={(e) => {
                                if (
                                  index != currentListIndex &&
                                  !loading &&
                                  !answerLoading
                                ) {
                                  // 清空form值
                                  form.setFieldsValue({
                                    url: '',
                                  })

                                  setPluginName('')
                                  setPluginId('')
                                  setFileList([])
                                  setCurrentListIndex(index)
                                  width < mobileWidth && setDrawerVisible(false)
                                  setIsBottom(false)
                                  scrollBotList()
                                  setUri('')
                                  setUrl('')
                                  setPrompt('')
                                  setMessageId('')
                                  setConversationId('')
                                  setMessageId40('')
                                  setConversationId40('')
                                  setActive([-1, false])
                                  setDisabledButton(false)
                                  setDisabledUpload(false)
                                  setAssistantInterface(
                                    drawerChatHistory[index].gptVersion,
                                  )
                                  if (
                                    drawerChatHistory[index].type == 'agent'
                                  ) {
                                    setIsAgent(true)
                                    if (
                                      drawerChatHistory[index].chatHistory
                                        ?.length > 1
                                    ) {
                                      console.log('已有记录')
                                      setIsAgentInit(false)
                                    } else {
                                      setIsAgent(true)
                                      setIsAgentInit(true)
                                    }
                                  } else {
                                    setIsAgent(false)
                                    setIsAgentInit(false)
                                  }
                                  // 重返到最后一个对话id
                                  INTERFACE_CONNECT_LAST_TALK &&
                                    switchToLastId(drawerChatHistory, index)
                                  Message.success({
                                    icon: (
                                      <IconSuccessTip useCurrentColor={false} />
                                    ),
                                    content: '切换成功！',
                                  })
                                  focusMsgInput()
                                }
                              }}>
                              {item?.type == 'agent' ? (
                                <div className="leftAvatar">
                                  {item?.faceImg ? (
                                    <img src={item?.faceImg} alt="" />
                                  ) : (
                                    <AgentImg />
                                  )}
                                </div>
                              ) : (
                                <div className="icon">
                                  <IconDialogue />
                                </div>
                              )}

                              <div className="title">
                                <div className="titleOut">
                                  {/* 标题 */}
                                  {/* <DialogueListForEachConversation
                                    title={item.title}
                                    index={index}
                                  /> */}
                                  <span className="talkTitle">
                                    {item.title}
                                  </span>
                                  {/* 接口 */}
                                  {item.type == 'agent' ? (
                                    ''
                                  ) : (
                                    <span className="botVersion">
                                      {returnInterfaceName(item.gptVersion)}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div
                                className="set"
                                onClick={(e) => {
                                  e.stopPropagation() // 阻止事件冒泡
                                }}>
                                {/* 修改对话名称 */}
                                {/* <Popconfirm
                                  autoFocus={false}
                                  position="top"
                                  title={
                                    <Input
                                      // allowClear
                                      placeholder="请输入对话名..."
                                      defaultValue={item.title}
                                      onChange={(e) => {
                                        inputValue = e
                                      }}
                                    />
                                  }
                                  icon={null}
                                  onOk={(e) => {
                                    if (inputValue) {
                                      setDrawerChatHistoryData(
                                        index,
                                        'title',
                                        inputValue,
                                      )
                                      Message.success({
                                        icon: (
                                          <IconSuccessTip
                                            useCurrentColor={false}
                                          />
                                        ),
                                        content: '修改成功！',
                                      })
                                    } else {
                                      Message.error({
                                        icon: (
                                          <IconErrorTip
                                            useCurrentColor={false}
                                          />
                                        ),
                                        content: '请输入新的对话名称！',
                                      })
                                    }
                                  }}>
                                  <Tooltip content="重命名">
                                    <IconFillIn
                                      className={`icon2 ${
                                        !loading && !answerLoading
                                          ? ''
                                          : 'disabled-color-style'
                                      }`}
                                      onClick={(e) => {
                                        e.stopPropagation() // 阻止事件冒泡
                                      }}
                                    />
                                  </Tooltip>
                                </Popconfirm> */}
                                {/* 删除对话 */}
                                {/* <Popconfirm
                                  autoFocus={false}
                                  position="top"
                                  title="您确定要删除吗？"
                                  icon={null}
                                  onOk={(e) => {
                                    e.stopPropagation() // 阻止事件冒泡

                                    const newDrawerChatHistory = [
                                      ...drawerChatHistory,
                                    ]
                                    newDrawerChatHistory.splice(index, 1)

                                    if (drawerChatHistory.length > 1) {
                                      setDrawerChatHistory(newDrawerChatHistory)
                                      if (
                                        currentListIndex ===
                                          drawerChatHistory.length - 1 &&
                                        index === drawerChatHistory.length - 1
                                      ) {
                                        setAssistantInterface(
                                          drawerChatHistory[index - 1]
                                            .gptVersion,
                                        )
                                        setCurrentListIndex(index - 1)
                                      } else if (index === currentListIndex) {
                                        setAssistantInterface(
                                          drawerChatHistory[index + 1]
                                            .gptVersion,
                                        )
                                        setCurrentListIndex(index)
                                      } else if (currentListIndex > index) {
                                        setAssistantInterface(
                                          drawerChatHistory[currentListIndex]
                                            .gptVersion,
                                        )
                                        setCurrentListIndex(
                                          currentListIndex - 1,
                                        )
                                      } else {
                                        setAssistantInterface(
                                          drawerChatHistory[currentListIndex]
                                            .gptVersion,
                                        )
                                        setCurrentListIndex(currentListIndex)
                                      }
                                    } else {
                                      // 只剩最后一个记录的时候应该关闭切换
                                      width < mobileWidth &&
                                        setDrawerVisible(false)
                                      if (dev == 'androidApp') {
                                        setAssistantInterface('wenxin')
                                      } else {
                                        // 回到默认接口
                                        returnToDefaultInterface(
                                          INTERFACE_DEFAULT_MODEL,
                                        )
                                      }
                                      setDrawerChatHistory([
                                        {
                                          title: '新建对话',
                                          createTime: getCurrentAllTime(),
                                          gptVersion:
                                            dev == 'androidApp'
                                              ? 'wenxin'
                                              : assistantInterface,
                                          chatHistory: [],
                                        },
                                      ])
                                    }
                                    // 只剩最后一个记录的时候应该关闭切换
                                    // drawerChatHistory.length
                                    //   ? setDrawerVisible(false)
                                    //   : ''

                                    // 清空form值
                                    form.setFieldsValue({
                                      url: '',
                                    })

                                    setPluginName('')
                                    setPluginId('')
                                    setUri('')
                                    setUrl('')
                                    setPrompt('')
                                    setMessageId('')
                                    setConversationId('')
                                    setMessageId40('')
                                    setConversationId40('')
                                    setFileList([])
                                    setActive([-1, false])
                                    setDisabledButton(false)
                                    setDisabledUpload(false)
                                    scrollBotList()
                                    Message.success({
                                      icon: (
                                        <IconSuccessTip
                                          useCurrentColor={false}
                                        />
                                      ),
                                      content: '删除成功！',
                                    })
                                    focusMsgInput()
                                  }}>
                                  <Tooltip content="删除对话">
                                    <IconWastePaper
                                      className={`icon3 ${
                                        !loading && !answerLoading
                                          ? ''
                                          : 'disabled-fill-style'
                                      }`}
                                      onClick={(e) => {
                                        e.stopPropagation() // 阻止事件冒泡
                                      }}
                                    />
                                  </Tooltip>
                                </Popconfirm> */}
                                <Dropdown
                                  trigger={['click']}
                                  position={'bottom'}
                                  droplist={
                                    <Menu>
                                      {/* 重命名 */}
                                      {!item?.templateId && (
                                        <Menu.Item
                                          key="1"
                                          onClick={() => {
                                            setDialogueListVisibleTitleValue(
                                              item.title,
                                            )
                                            setDialogueListIndex(index)
                                            setDialogueListVisibleRename(true)
                                          }}>
                                          <span className="setMenuItem">
                                            <IconFillIn className="icon" />
                                            重命名
                                          </span>
                                        </Menu.Item>
                                      )}
                                      {item?.templateId &&
                                        item?.publishType != 1 && (
                                          <Menu.Item
                                            key="3"
                                            onClick={() => {
                                              getDoShareLinkHandle({
                                                url: `https://portal.wuz.com.cn/toolbox/index?agentUuid=${
                                                  item.agentUuid
                                                }&templateId=${
                                                  item.templateId
                                                }&userName=${localStorage.getItem(
                                                  'userName',
                                                )}`,
                                              })
                                            }}>
                                            <span className="setMenuItem">
                                              <IconShare className="icon" />
                                              分享智能体
                                            </span>
                                          </Menu.Item>
                                        )}
                                      {/* 删除对话 */}
                                      <Menu.Item
                                        key="2"
                                        onClick={() => {
                                          setDialogueListVisibleDelete(true)
                                          setDialogueListIndex(index)
                                        }}>
                                        <span className="setMenuItem">
                                          <IconWastePaper className="icon" />
                                          {item?.templateId
                                            ? '删除智能体'
                                            : '删除对话'}
                                        </span>
                                      </Menu.Item>
                                    </Menu>
                                  }>
                                  <IconMoreEdits
                                    className={`iconIn ${
                                      !loading && !answerLoading
                                        ? ''
                                        : 'disabled-color-style'
                                    }`}
                                  />
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <Modal
                    className={`${styles[theme]}`}
                    style={{ width: '300px' }}
                    title={
                      <span
                        className="deleteDialogueList"
                        style={{ position: 'relative', bottom: '-13px' }}>
                        您确定要删除当前对话吗？
                      </span>
                    }
                    visible={dialogueListVisibleDelete}
                    onOk={() => {
                      const newDrawerChatHistory = [...drawerChatHistory]
                      newDrawerChatHistory.splice(dialogueListIndex, 1)

                      if (drawerChatHistory.length > 1) {
                        setDrawerChatHistory(newDrawerChatHistory)
                        if (
                          currentListIndex === drawerChatHistory.length - 1 &&
                          dialogueListIndex === drawerChatHistory.length - 1
                        ) {
                          setAssistantInterface(
                            drawerChatHistory[dialogueListIndex - 1].gptVersion,
                          )
                          setCurrentListIndex(dialogueListIndex - 1)
                        } else if (dialogueListIndex === currentListIndex) {
                          setAssistantInterface(
                            drawerChatHistory[dialogueListIndex + 1].gptVersion,
                          )
                          setCurrentListIndex(dialogueListIndex)
                        } else if (currentListIndex > dialogueListIndex) {
                          setAssistantInterface(
                            drawerChatHistory[currentListIndex].gptVersion,
                          )
                          setCurrentListIndex(currentListIndex - 1)
                        } else {
                          setAssistantInterface(
                            drawerChatHistory[currentListIndex].gptVersion,
                          )
                          setCurrentListIndex(currentListIndex)
                        }
                      } else {
                        // 只剩最后一个记录的时候应该关闭切换
                        width < mobileWidth && setDrawerVisible(false)
                        if (dev == 'androidApp') {
                          setAssistantInterface('wenxin')
                        } else {
                          // 回到默认接口
                          returnToDefaultInterface(INTERFACE_DEFAULT_MODEL)
                        }

                        setDrawerChatHistory([
                          {
                            title: '新建对话',
                            createTime: getCurrentAllTime(),
                            gptVersion:
                              dev == 'androidApp'
                                ? 'wenxin'
                                : assistantInterface,
                            chatHistory: [
                              {
                                user: 'robot',
                                time: getCurrentTime(),
                                description: returnInterfaceName(
                                  returnToDefaultInterface(
                                    INTERFACE_DEFAULT_MODEL,
                                  ),
                                ),
                                interface:
                                  dev == 'androidApp' ? 'wenxin' : 'gpt35',
                                content:
                                  '您好！我是' +
                                  returnInterfaceName(
                                    returnToDefaultInterface(
                                      INTERFACE_DEFAULT_MODEL,
                                    ),
                                  ) +
                                  '，我的主要功能包括回答各种问题、提供准确可信的信息、语音交互、多语言支持和学习能力。\n\n我的优点是可以处理多个任务，例如翻译、生成文本、摘要、代码等，您可以随时向我提出您的问题，我会尽力为您解决。\n\n同时，我也支持多轮连续对话，帮助您更好地获取所需信息。如果您遇到任何问题，可以直接询问我。',
                              },
                            ],
                          },
                        ])
                      }
                      // 只剩最后一个记录的时候应该关闭切换
                      // drawerChatHistory.length
                      //   ? setDrawerVisible(false)
                      //   : ''

                      // 清空form值
                      form.setFieldsValue({
                        url: '',
                      })

                      setPluginName('')
                      setPluginId('')
                      setUri('')
                      setUrl('')
                      setPrompt('')
                      setMessageId('')
                      setConversationId('')
                      setMessageId40('')
                      setConversationId40('')
                      setFileList([])
                      setActive([-1, false])
                      setDisabledButton(false)
                      setDisabledUpload(false)
                      scrollBotList()
                      Message.success({
                        icon: <IconSuccessTip useCurrentColor={false} />,
                        content: '删除成功！',
                      })

                      focusMsgInput()
                      setDialogueListVisibleDelete(false)
                      if (
                        newDrawerChatHistory[currentListIndex]?.type != 'agent'
                      ) {
                        console.log(
                          newDrawerChatHistory[currentListIndex]?.type,
                        )
                        setIsAgent(false)
                        setIsAgentInit(false)
                      } else {
                        setIsAgent(true)
                        if (
                          newDrawerChatHistory[currentListIndex].chatHistory
                            ?.length > 1
                        ) {
                          setIsAgentInit(false)
                        } else {
                          setIsAgentInit(true)
                        }
                      }
                    }}
                    onCancel={() => setDialogueListVisibleDelete(false)}
                    autoFocus={false}
                    focusLock={true}></Modal>

                  <Modal
                    className={`${styles[theme]}`}
                    style={{ width: '300px' }}
                    title={<span className="deleteDialogueList">重命名</span>}
                    visible={dialogueListVisibleRename}
                    onOk={(e) => {
                      if (dialogueListVisibleTitleValue) {
                        setDrawerChatHistoryData(
                          dialogueListIndex,
                          'title',
                          dialogueListVisibleTitleValue,
                        )
                        Message.success({
                          icon: <IconSuccessTip useCurrentColor={false} />,
                          content: '修改成功！',
                        })
                      } else {
                        Message.error({
                          icon: <IconErrorTip useCurrentColor={false} />,
                          content: '请输入新的对话名称！',
                        })
                      }
                      setDialogueListVisibleRename(false)
                      setDialogueListVisibleTitleValue('')
                    }}
                    onCancel={() => setDialogueListVisibleRename(false)}
                    autoFocus={false}
                    focusLock={true}>
                    <Input
                      className="inputDialogueList"
                      // allowClear
                      value={dialogueListVisibleTitleValue}
                      onChange={(value) => {
                        setDialogueListVisibleTitleValue(value)
                      }}
                      placeholder="请输入对话名..."
                    />
                  </Modal>

                  {/* 免责声明 */}
                  <p className="disclaimer">
                    对话内容由 AI 生成，仅供参考
                    <IconHelp
                      className="disclaimerIcon"
                      onClick={() => window.open('https://go.wuz.com.cn/5526')}
                    />
                  </p>
                </div>
              </Drawer>
            </div>
          </div>
          {/* 中间底部按钮 */}
          <div
            // className="contentMidBut"
            className={`contentMidBut ${styles[theme]}`}
            style={
              IS_FULL_SCREEN
                ? {
                    maxWidth: 'none',
                    width: `${TEXTAREA_AREA_WIDTH}%`,
                    padding: '0',
                  }
                : { padding: width > mobileWidth ? '0 1rem' : '' }
            }>
            <div className={`${styles['top']} ${styles[theme]}`}>
              {/* 指令库、插件库 */}
              {dev == 'pc' && (
                <div className={`${styles['left']} ${styles[theme]}`}>
                  {switchProject('DFZ') && (
                    <PluginLibrary
                      pluginName={pluginName}
                      setPluginName={setPluginName}
                      pluginId={pluginId}
                      setPluginId={setPluginId}
                    />
                  )}
                  {/* <BorderBtn
                    theme={theme}
                    disable={!loading && !answerLoading ? false : true}
                    onClick={() => {
                      list.length ? setVisiblePrompt(true) : ''
                    }}
                    primary={true}
                    ACTIVE_COLOR_1={true}
                    iconLeft={<IconPromptLibrary useCurrentColor={true} />}
                    text={'指令库'}
                  /> */}
                </div>
              )}
              {/*指令库*/}
              <ModalCustom
                title={'指令库'}
                theme={theme}
                width={dev == 'pc' ? '' : '90%'}
                visible={visiblePrompt}
                close={() => setVisiblePrompt(false)}
                content={
                  <PromptMod
                    theme={theme}
                    list={list}
                    promptSend={promptSend}
                    visiblePrompt={visiblePrompt}
                    copyOrUse={textQ ? true : false}
                    mobile={dev == 'pc'}
                  />
                }
              />
              {/* 回到当前位置 */}
              <div className="middle">
                {isBottom && width > mobileWidth ? (
                  <BorderBtn
                    theme={theme}
                    iconLeft={<IconScrollDown />}
                    onClick={() => {
                      scrollBotList()
                      setIsBottom(false)
                    }}
                    text={'回到当前位置'}></BorderBtn>
                ) : (
                  ''
                )}
              </div>
              {/* 右边所有按钮 */}
              <div
                className={`contentMidButRight ${styles['right']} ${styles[theme]}`}>
                {/* 网址链接 */}
                {!isAgent && (
                  <>
                    {confirmLoading ? (
                      <>
                        <div
                          className={`tag_item ${styles['tag_item']} ${styles[theme]}`}>
                          <div
                            className={`${styles['tag_icon']}`}
                            style={{ marginRight: '3px' }}>
                            <IconLoading style={{ fill: 'none' }} />
                          </div>
                          <div className={`${styles['tag_title']}`}>抓取中</div>
                          <div className={`${styles['tag_close']}`}>
                            <IconClosure
                              onClick={(event) => {
                                event.stopPropagation()
                                setConfirmLoading(false)
                                setVisible(false)
                                setDisabledUpload(false)
                                setAnswerLoading(false)
                                setUrl('')
                                cancelUrl = false
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <BorderBtn
                        // disable={disabledButton}
                        theme={theme}
                        disable={
                          (!loading && !answerLoading) || !disabledButton
                            ? false
                            : true
                        }
                        onClick={() => {
                          setVisible(true)
                          setTimeout(() => {
                            TSKInputRef.current && TSKInputRef.current.focus()
                          }, 100)
                        }}
                        iconLeft={<IconLink />}
                        text={switchProject('DFZ') ? '帮我读网页' : '网址抓取'}
                      />
                    )}
                    <Modal
                      style={{ width: dev == 'pc' ? '435px' : '90%' }}
                      className={`modal-aidb type2 ${styles[theme]}`}
                      title={
                        <div className={'modal-aidb-title'}>
                          {switchProject('DFZ') ? '帮我读网页' : '网址抓取'}
                        </div>
                      }
                      visible={visible}
                      maskClosable={false}
                      footer={
                        <>
                          {confirmLoading ? (
                            <div className={'modal-aidb-bot-btns'}>
                              <div
                                className={
                                  'modal-aidb-btn color full gradient'
                                }>
                                <span>
                                  <IconLoading
                                    style={{ marginRight: '1rem' }}
                                  />
                                  抓取中
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={'modal-aidb-bot-btns'}
                              onClick={() => upDownUrlForm()}>
                              <div
                                className={
                                  'modal-aidb-btn color full gradient'
                                }>
                                <span>抓取</span>
                              </div>
                            </div>
                          )}
                        </>
                      }
                      onCancel={() => setVisible(false)}>
                      <div
                        className={'modal-aidb-content'}
                        style={{ padding: '16px 20px 0 20px' }}>
                        <Form
                          {...formItemLayout}
                          form={form}
                          wrapperCol={{
                            style: { width: '100%' },
                          }}>
                          <FormItem
                            field="url"
                            rules={[{ required: true }]}
                            style={{ marginBottom: '0' }}>
                            <Input
                              ref={TSKInputRef}
                              placeholder="输入您要抓取的网址（新闻、报告、B站...）"
                              onPressEnter={() => upDownUrlForm()}
                            />
                          </FormItem>
                        </Form>
                      </div>
                    </Modal>
                  </>
                )}

                {/* 文件上传 */}
                {!isAgent && (
                  <Upload
                    // disabled={cancelUpload}
                    disabled={
                      (!loading && !answerLoading) || cancelUpload
                        ? false
                        : true
                    }
                    multiple
                    // accept=".docx,.doc,.txt,.pdf"
                    fileList={fileList}
                    // limit={1}
                    showUploadList={false}
                    customRequest={({ file, onSuccess }) => {
                      const formData = new FormData()
                      const fileType =
                        file.name.split('.')[file.name.split('.').length - 1]
                      formData.append('file', file)

                      if (!['docx', 'doc', 'txt', 'pdf'].includes(fileType)) {
                        setFileList(fileList[fileList.length - 1])
                        Message.error({
                          icon: <IconErrorTip useCurrentColor={false} />,
                          content:
                            '文件格式不正确，当前只支持.docx .doc .txt .pdf格式！',
                        })
                        return
                      } else if (file.size >= 1048576 * 64) {
                        setFileList(fileList[fileList.length - 1])
                        Message.error({
                          icon: <IconErrorTip useCurrentColor={false} />,
                          content: '当前不支持超过 64MB 的文件！',
                        })
                        return
                      }

                      cancelUpload = true
                      setCancelUpload1(cancelUpload1 + 1)
                      setDisabledButton(true)
                      setAnswerLoading(true)

                      request
                        .post(api + '/chatWeb/uploadFile', formData)
                        .then((response) => {
                          const data = response.data

                          if (data.code == '500200') {
                            if (!cancelUpload) return
                            setCurrentFileCategory('wd')
                            // 如果记录中有，则不上传
                            if (findIsHaveMeUri(data.data.uri)) {
                              tagsReturnTo(
                                findIsHaveMeUri(data.data.uri).fileId,
                              )
                              setActive([
                                findIsHaveMeUri(data.data.uri).fileId,
                                true,
                              ])
                              setUri(findIsHaveMeUri(data.data.uri).uri)
                              onSuccess(data)
                              setDisabledButton(false)
                              cancelUpload = false
                              setCancelUpload1(cancelUpload1 + 1)
                              setAnswerLoading(false)
                              Message.warning({
                                icon: <IconWarnTip useCurrentColor={false} />,
                                content: '此文件已上传，正在切换此文件！',
                              })
                              return
                            }

                            let lastFileId = findLastFileId(currentListIndex)

                            setUri(data.data.uri)
                            onSuccess(data)
                            setDisabledButton(false)
                            tagsReturnTo(lastFileId)
                            upLoadFile(data.data.uri, file.name, 'wd')
                            cancelUpload = false
                            setAnswerLoading(false)
                            setActive([lastFileId, true])
                            setCancelUpload1(cancelUpload1 + 2)
                          } else {
                            setFileList(fileList[fileList.length - 1])
                            setDisabledButton(false)
                            setAnswerLoading(false)
                            cancelUpload = false
                            setCancelUpload1(cancelUpload1 + 3)
                            Message.error({
                              icon: <IconErrorTip useCurrentColor={false} />,
                              content: data.msg,
                            })
                          }
                        })
                        .catch((error) => {
                          console.log('error', error)

                          setFileList(fileList[fileList.length - 1])
                          setDisabledButton(false)
                          setAnswerLoading(false)
                          cancelUpload = false
                          setCancelUpload1(cancelUpload1 + 4)
                          Message.error({
                            icon: <IconErrorTip useCurrentColor={false} />,
                            content: '文件抓取失败！',
                          })
                        })
                    }}
                    onChange={(e, d) => {
                      setFileList(e)
                    }}
                    onProgress={(file) => {
                      setFileList((v) => {
                        return v.map((x) => {
                          return x.uid === file.uid ? file : x
                        })
                      })
                    }}>
                    <div className={`cs ${styles[theme]}`}>
                      <Tooltip content="点击或拖拽至此处上传，支持.docx .txt .pdf文件">
                        <div
                          style={{
                            border: theme == 'dark' ? '1px solid #2c2c2c' : '',
                          }}
                          className={`tag_item ${
                            disabledUpload
                              ? 'uploadFileTrue'
                              : styles['tag_item']
                          } ${styles[theme]}`}>
                          <div
                            className={`${
                              disabledUpload ? 'tag_icon' : styles['tag_icon']
                            }`}
                            style={{ marginRight: '3px' }}>
                            {cancelUpload ? (
                              <IconLoading style={{ fill: 'none' }} />
                            ) : (
                              <IconUpload />
                            )}
                          </div>
                          {cancelUpload ? (
                            <>
                              <div
                                className={`${
                                  disabledUpload
                                    ? 'tag_title'
                                    : styles['tag_title']
                                }`}>
                                上传中
                              </div>
                              <div
                                className={`${
                                  disabledUpload
                                    ? 'tag_close'
                                    : styles['tag_close']
                                }`}>
                                <IconClosure
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    cancelUpload = false
                                    setCancelUpload1(cancelUpload1 + 1)
                                    setDisabledButton(false)
                                    setAnswerLoading(false)
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              className={`${
                                loading || answerLoading || disabledUpload
                                  ? 'tag_close'
                                  : styles['tag_close']
                              } ${styles[theme]}`}>
                              {switchProject('DFZ') ? '帮我读文档' : '文档上传'}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </Upload>
                )}
                <BorderBtn
                  disable={
                    uri == '' &&
                    url == '' &&
                    prompt == '' &&
                    fileList.length == 0 &&
                    ((messageId == '' && messageId40 == '') ||
                      (conversationId == '' && conversationId40 == '')) &&
                    !active[1]
                      ? true
                      : false
                  }
                  onClick={() => {
                    // 清空form值
                    form.setFieldsValue({
                      url: '',
                    })

                    setUri('')
                    setUrl('')
                    setPrompt('')
                    setMessageId('')
                    setConversationId('')
                    setMessageId40('')
                    setConversationId40('')
                    setFileList([])
                    setActive([-1, false])
                    setDisabledButton(false)
                    setDisabledUpload(false)
                    setCurrentFileCategory('')
                    const updatedHistory = [...drawerChatHistory]
                    updatedHistory[currentListIndex].chatHistory = []
                    setDrawerChatHistory(updatedHistory)
                    if (drawerChatHistory[currentListIndex].type == 'agent') {
                      setIsAgentInit(true)
                    } else {
                      setIsAgentInit(false)
                    }
                    Message.success({
                      icon: <IconSuccessTip useCurrentColor={false} />,
                      content: '清空成功',
                    })
                  }}
                  theme={theme}
                  iconLeft={<IconClear />}
                  text={'清空对话'}
                />
                {/* 重置对话 */}
                <BorderBtn
                  disable={
                    uri == '' &&
                    url == '' &&
                    prompt == '' &&
                    fileList.length == 0 &&
                    ((messageId == '' && messageId40 == '') ||
                      (conversationId == '' && conversationId40 == '')) &&
                    !active[1]
                      ? true
                      : false
                  }
                  onClick={() => {
                    // 清空form值
                    form.setFieldsValue({
                      url: '',
                    })

                    setUri('')
                    setUrl('')
                    setPrompt('')
                    setMessageId('')
                    setConversationId('')
                    setMessageId40('')
                    setConversationId40('')
                    setFileList([])
                    setActive([-1, false])
                    setDisabledButton(false)
                    setDisabledUpload(false)
                    setCurrentFileCategory('')
                    Message.success({
                      icon: <IconSuccessTip useCurrentColor={false} />,
                      content: '重置成功！',
                    })
                  }}
                  theme={theme}
                  iconLeft={<IconSync />}
                  text={'重置对话'}
                />
              </div>
            </div>
          </div>
          {/* 底部TextArea和tags */}
          <div
            // className="contentBut"
            className={`${'contentBut'} ${styles[theme]}`}
            style={
              IS_FULL_SCREEN
                ? {
                    maxWidth: 'none',
                    width: `${TEXTAREA_AREA_WIDTH}%`,
                    padding: '0',
                  }
                : { padding: width > mobileWidth ? '0 1rem' : '' }
            }>
            {/* TextArea */}
            <div className={`${styles['textareaWrap']} ${styles[theme]}`}>
              <TextArea
                className={`${styles['textarea']} ${styles[theme]}`}
                ref={msgInputRef}
                // disabled={loading}
                // autoSize={{ minRows: 4, maxRows: 4 }}
                onPressEnter={sendMsg}
                value={textQ}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(value) => {
                  value !== '\n' ? setTextQ(value) : setTextQ('')
                  // scrollBotList()
                }}
                onBlur={(event) => {
                  dev === 'mobileBrowser' && window.scroll(0, 0)
                }}
                placeholder={placeholder}
                style={{
                  fontSize: TEXTAREA_FONT_SIZE,
                }}
              />
              {/* 发送按钮 */}
              <div
                className={`${styles['sendBtn']} ${styles[theme]}`}
                onClick={() => (displayPause ? (suspend = true) : sendMsg(1))}>
                {displayPause ? (
                  <div className={styles['icon']}>
                    <IconStop
                      className={styles['iconStop']}
                      useCurrentColor={true}
                    />
                  </div>
                ) : (
                  <div className={styles['icon']}>
                    <IconSend useCurrentColor={false} />
                  </div>
                )}
              </div>
            </div>
            {/* tags */}
            <div
              className={`tags ${styles['tags']}`}
              style={{
                cursor: drawerChatHistory[currentListIndex].chatHistory.some(
                  (item) =>
                    item.fileName !== '' &&
                    item.fileCategory !== '' &&
                    (item.fileCategory == 'by' ||
                      item.fileCategory == 'wz' ||
                      item.fileCategory == 'wd'),
                )
                  ? 'grab'
                  : 'auto',
              }}
              ref={tagsRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
              {drawerChatHistory.length
                ? drawerChatHistory[currentListIndex].chatHistory.map(
                    (item, index) => {
                      return item.fileName !== '' &&
                        item.fileCategory !== '' ? (
                        item.fileCategory == 'by' ? (
                          <div
                            className={`${
                              active[1] && item.fileId == active[0]
                                ? styles['tag_item_active']
                                : styles['tag_item']
                            }`}
                            key={index}>
                            <Tooltip content={item.fileName}>
                              <div
                                className={styles['tag_title']}
                                onClick={(e) => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  setMessageId('')
                                  setConversationId('')
                                  setMessageId40('')
                                  setConversationId40('')
                                  focusMsgInput()
                                  if (item.fileId == active[0]) {
                                    setActive([item.fileId, !active[1]])
                                    if (active[1]) {
                                      setPrompt('')
                                      setCurrentFileCategory('')
                                    } else {
                                      setPrompt(item.prompt)
                                      setCurrentFileCategory('by')
                                    }
                                  } else {
                                    setUri('')
                                    setPrompt(item.prompt)
                                    setActive([item.fileId, true])
                                    setCurrentFileCategory('by')
                                  }
                                }}>
                                {item.fileName}
                              </div>
                            </Tooltip>
                            <div
                              className={styles['tag_close']}
                              onClick={(event) => {
                                setTimeout(() => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  delDrawerChatHistoryData(index)
                                  setPrompt('')
                                  setCurrentFileCategory('')
                                  if (item.fileId == active[0]) {
                                    setMessageId('')
                                    setConversationId('')
                                    setMessageId40('')
                                    setConversationId40('')
                                    setActive([-1, false])
                                  }
                                  Message.success({
                                    icon: (
                                      <IconSuccessTip useCurrentColor={false} />
                                    ),
                                    content: '删除成功！',
                                  })
                                }, 10)
                              }}>
                              <IconClosure />
                            </div>
                          </div>
                        ) : item.fileCategory == 'wz' ? (
                          <div
                            className={`${
                              active[1] && item.fileId == active[0]
                                ? styles['tag_item_active']
                                : styles['tag_item']
                            }`}
                            key={index}>
                            <div className={styles['tag_icon']}>
                              <IconLink />
                            </div>
                            <Tooltip content={item.fileName}>
                              <div
                                className={styles['tag_title']}
                                onClick={() => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  setMessageId('')
                                  setConversationId('')
                                  setMessageId40('')
                                  setConversationId40('')
                                  focusMsgInput()
                                  if (item.fileId == active[0]) {
                                    setActive([item.fileId, !active[1]])
                                    if (active[1]) {
                                      setUri('')
                                      setCurrentFileCategory('')
                                    } else {
                                      setUri(item.uri)
                                      setCurrentFileCategory('wz')
                                    }
                                  } else {
                                    setPrompt('')
                                    setUri(item.uri)
                                    setActive([item.fileId, true])
                                    setCurrentFileCategory('wz')
                                  }
                                }}>
                                {item.fileName}
                              </div>
                            </Tooltip>
                            <div
                              className={styles['tag_close']}
                              onClick={() => {
                                setTimeout(() => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  if (uri == item.uri) {
                                    setUri('')
                                    setUrl('')
                                    setActive([-1, false])
                                    form.setFieldsValue({
                                      url: '',
                                    })
                                  }
                                  setCurrentFileCategory('')
                                  delDrawerChatHistoryData(index)
                                  Message.success({
                                    icon: (
                                      <IconSuccessTip useCurrentColor={false} />
                                    ),
                                    content: '删除成功！',
                                  })
                                }, 10)
                              }}>
                              <IconClosure />
                            </div>
                          </div>
                        ) : item.fileCategory == 'wd' ? (
                          <div
                            className={`${
                              active[1] && item.fileId == active[0]
                                ? styles['tag_item_active']
                                : styles['tag_item']
                            }`}
                            key={index}>
                            <div className={styles['tag_icon']}>
                              <IconFilePdf style={{ fill: 'none' }} />
                            </div>
                            <Tooltip content={item.fileName}>
                              <div
                                className={styles['tag_title']}
                                onClick={() => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  setMessageId('')
                                  setConversationId('')
                                  setMessageId40('')
                                  setConversationId40('')
                                  focusMsgInput()
                                  if (item.fileId == active[0]) {
                                    setActive([item.fileId, !active[1]])
                                    if (active[1]) {
                                      setUri('')
                                      setCurrentFileCategory('')
                                    } else {
                                      setUri(item.uri)
                                      setCurrentFileCategory('wd')
                                    }
                                  } else {
                                    setPrompt('')
                                    setUri(item.uri)
                                    setActive([item.fileId, true])
                                    setCurrentFileCategory('wd')
                                  }
                                }}>
                                {item.fileName}
                              </div>
                            </Tooltip>
                            <div
                              className={styles['tag_close']}
                              onClick={() => {
                                setTimeout(() => {
                                  if (isDragging) return
                                  if (pluginId) {
                                    Message.warning({
                                      icon: (
                                        <IconWarnTip useCurrentColor={false} />
                                      ),
                                      content:
                                        '正在使用插件库，请清除后再尝试使用！',
                                    })
                                    return
                                  }
                                  if (uri == item.uri) {
                                    setUri('')
                                    setActive([-1, false])
                                  }
                                  delDrawerChatHistoryData(index)
                                  setCurrentFileCategory('')
                                  Message.success({
                                    icon: (
                                      <IconSuccessTip useCurrentColor={false} />
                                    ),
                                    content: '删除成功！',
                                  })
                                }, 10)
                              }}>
                              <IconClosure />
                            </div>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )
                    },
                  )
                : ''}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatGPT
