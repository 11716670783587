import React from 'react';
import styles from './style/prompt-title.module.less'
import { IconInfoCircle, IconPlus, IconMinus } from '@arco-design/web-react/icon';
import { Tooltip, Typography } from '@arco-design/web-react';
import IconBtn from "../../../components/IconBtn"; import {
    IconWastePaper
} from "@arco-iconbox/react-aidb-v2";
function PromptTitle(props) {
    function del() {
        if (props.del) {
            props.del()
        }
    }
    function plus() {
        if (props.plus) {
            props.plus()
        }
    }
    function minus() {
        if (props.minus) {
            props.minus()
        }
    }
    return (
        <div className={styles.title}>
            <div className={styles.left}>
                <div className={styles.text}>
                    {props.title}
                </div>
                {
                    props.tooltip &&
                    <div className={styles.icon}>
                        <Tooltip color={'#222222'} content={props.tooltip}>
                            <IconInfoCircle />
                        </Tooltip>

                    </div>
                }

            </div>
            <div className={styles.right}>
                {
                    props.showPlus &&
                    <div className={styles.btn}
                        onClick={() => { plus() }}
                    ><IconPlus /></div>
                }
                {
                    props.showMinus &&
                    <div className={styles.btn}
                        onClick={() => { minus() }}
                    ><IconMinus /></div>
                }
                {
                    props.showDel &&
                    <div className={styles.btn_del}
                        onClick={() => { del() }}
                    >
                        <IconWastePaper />
                    </div>
                }
            </div>
        </div>
    );
}

export default PromptTitle;
