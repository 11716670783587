import React, { useEffect, useState } from 'react'
import styles from './style/entry-list-mod.module.less'

import Icon1 from '../../assets/imgs/ia-i1.svg'
import Icon2 from '../../assets/imgs/ia-i2.svg'
import Icon3 from '../../assets/imgs/ia-i3.svg'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { IconArrowRight } from '@arco-iconbox/react-aidb-v2'

function EntryListMod(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  const { initialValues, doApi, isAgentInit } = props
  const [propsIsAgent, setPropsIsAgent] = useState(isAgentInit)
  const [randomGuides, setRandomGuides] = useState([])

  const list = [
    {
      icon: <Icon1 />,
      title: '文本扩写',
      desc: 'AI 为您提供丰富的内容扩写',
      id: [5, 12],
      path: '',
    },
    {
      icon: <Icon2 />,
      title: '集思广益',
      desc: 'AI 为您的雅思写作给出打分与依据',
      id: [2, 0],
      path: '',
    },
    {
      icon: <Icon3 />,
      title: '小红书笔记',
      desc: 'AI 为您智能生成小红书的写作风格',
      id: [0, 1],
      path: '',
    },
  ]

  const sendMessage = (id) => {
    props.promptSend(id[0], id[1], 4)
  }

  useEffect(() => {
    setPropsIsAgent(isAgentInit)
  }, [isAgentInit])

  // 随机渲染引导示例，只在首次渲染时执行一次
  useEffect(() => {
    function getRandomElements(arr, count) {
      const shuffled = arr?.slice().sort(() => 0.5 - Math.random())
      return shuffled?.slice(0, count)
    }
    if (initialValues?.conversationStarters) {
      setRandomGuides(getRandomElements(initialValues.conversationStarters, 3))
    }
  }, [initialValues])

  return (
    <>
      {propsIsAgent && propsIsAgent ? (
        <div className={`${styles['contentInner']} ${styles[theme]}`}>
          {randomGuides?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  doApi(item)
                }}
                className={`${styles['item']} ${styles[theme]}`}
                key={index}>
                <div className={styles['text']}>
                  <div className={styles['name']}>{item}</div>
                  <div className={styles['desc']}>
                    去试试
                    <IconArrowRight />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        ''
        // <div className={`${styles['list']} ${styles[theme]}`}>
        //   {list.map((item, index) => {
        //     return (
        //       <div
        //         className={styles['item']}
        //         key={index}
        //         onClick={() => sendMessage(item.id)}>
        //         <div className={styles['icon']}>{item.icon}</div>
        //         <div className={styles['text']}>
        //           <div className={styles['name']}>{item.title}</div>
        //           <div className={styles['desc']}>{item.desc}</div>
        //         </div>
        //       </div>
        //     )
        //   })}
        // </div>
      )}
    </>
  )
}

export default EntryListMod
