import React from 'react'
import { WebsocketProvider } from 'y-websocket'
import { debounce } from 'lodash'
import { events } from '@/helpers/event-emitter'
import { Message } from '@arco-design/web-react'
import {
  IconSuccessTip,
  IconErrorTip,
  IconWarnTip,
} from '@arco-iconbox/react-aidb-v2'
import EditorInitializationLoading from '@/components/Loading/RLLoading'
import {
  chronology,
  refreshChronology,
  clearChronology,
} from '../utils/chronologicalDisconnection'

class SyncedWebsocketProvider extends WebsocketProvider {
  // 当前操作是否是本地用户
  public isLocalUser = false
  // 协同成员在线状态列表
  public members = []
  // 保存SyncedWebsocketProvider实例，防止重复连接
  private static instance: SyncedWebsocketProvider | null = null
  // 传来的props数据
  private configInfo = {
    ydoc: null,
    yXmlFragment: null,
    connectUrl: '',
    uniqueConnectPath: '',
    editorRef: null,
    docContent: '',
    userInfo: {},
    RANDOM_COLOR: '',
  }
  // 更新store数据
  private dispatch
  // 当前用户、协同成员、在线状态、操作所有信息
  private yjsEditorCoordinationInfo
  // 自定义游标函数
  private cursorBuilder
  // 协同成员操作列表
  private memberMap = new Map()
  // 当某一用户掉线后，使用用户都会收到更新通知，更新后其他用户会刷新定时器，因此使用此变量来判断是否是当前用户的更新
  private offlineClientID

  constructor(configInfo) {
    const {
      dispatch,
      yjsEditorCoordinationInfo,
      ydoc,
      yXmlFragment,
      connectUrl,
      uniqueConnectPath,
      editorRef,
      docContent,
      userInfo,
      RANDOM_COLOR,
    } = configInfo

    // 连接到父类公共演示服务器（不在生产中！）
    super(connectUrl, uniqueConnectPath, ydoc)

    // 用于更新store
    this.dispatch = dispatch
    // 将 yjsEditorCoordinationInfo 保存为类的实例属性
    this.yjsEditorCoordinationInfo = yjsEditorCoordinationInfo
    // 将 configInfo 保存为类的实例属性
    this.configInfo = configInfo

    /**
     * 以下实现如何从网络提供程序检索感知 CRDT。
     * 可以设置一些传播到所有用户的属性。
     * 定义属性"user"来设置当前用户的名称和首选颜色。
     */
    // All of our network providers implement the awareness crdt  所有的网络提供商都实施了感知crdt
    const awareness = this.awareness

    // 开始计时器
    chronology(this.overtime)

    // 初始化成员列表，将所有成员设为离线
    this.initializeMembers(yjsEditorCoordinationInfo.employeeList.employeeList)

    /**
     * 监听Awareness状态的变化
     * You can observe when a user updates their awareness information  可以观察用户何时更新其意识信息
     * Whenever somebody updates their awareness information, 每当有人更新他们的意识信息时，
     * we log all awareness information from all users. 我们记录所有用户的所有意识信息。
     */
    awareness.on('change', this.onChange)

    /**
     * You can think of your own awareness information as a key-value store.  你可以把你自己的认知信息看作一个关键的价值存储。
     * We update our "user" field to propagate relevant user information. 我们更新“user”字段以传播相关的用户信息。
     */
    awareness.setLocalStateField('user', {
      id: userInfo.id,
      // Define a print name that should be displayed 定义应显示的打印名称
      name: userInfo.name,
      // Define a color that should be associated to the user:  定义应与用户关联的颜色
      color: RANDOM_COLOR, // 应该是十六进制颜色
    })

    /**
     * 事件处理程序在每次事务之前调用。
     */
    // ydoc.on('beforeTransaction', function (transaction, ydoc) {
    //   // console.log(transaction, ydoc)
    // })

    /**
     * 当文档更新时，将触发该 "update" 事件。
     */
    ydoc.on('afterTransaction', this.onDocumentUpdate)
    // ydoc.on('update', this.onDocumentUpdate)

    /**
     * This function receives the remote users "user" awareness state.
     * 自定义游标构建器来覆盖默认的 Widget dom
     */
    // this.cursorBuilder = (user) => {
    //   const cursor = document.createElement('span')
    //   cursor.classList.add('ProseMirror-yjs-cursor')
    //   cursor.setAttribute('style', `border-color: ${user.color}`)
    //   const userDiv = document.createElement('div')
    //   userDiv.setAttribute('style', `background-color: ${user.color}`)
    //   userDiv.insertBefore(document.createTextNode(user.name), null)
    //   cursor.insertBefore(userDiv, null)
    //   return cursor
    // }

    /**
     * 监测用户连接情况
     */
    this.on('status', this.onStatusChange)

    /**
     * 当已建立与数据库的连接并加载所有可用内容时，将触发该 "synced" 事件。当尚无可用内容时，也会触发该事件。
     */
    this.on('synced', this.onSynced)

    // yjs销毁时的回调
    this.destroy = this.onDestroy

    // 添加全局点击事件监听器
    document.addEventListener('click', this.onGlobalClick)
  }

  /**
   * 确保yjs只有一次连接，防止重复连接
   * */
  public static getInstance(configInfo): SyncedWebsocketProvider {
    if (!SyncedWebsocketProvider.instance) {
      SyncedWebsocketProvider.instance = new SyncedWebsocketProvider(configInfo)
    }
    return SyncedWebsocketProvider.instance
  }

  /**
   * 处理连接状态变化的事件
   *
   * event.status 的三个状态总结：
   * connected: Yjs 已成功连接到 WebSocket 服务器
   * connecting: Yjs 正在尝试重新连接到 WebSocket 服务器
   * disconnected: Yjs 已断开与 WebSocket 服务器的连接
   * */
  onStatusChange = (event) => {
    // console.log(event.status)

    // 关闭编辑器 Loading 状态
    EditorInitializationLoading.close()

    if (event.status === 'connected') {
      // console.log('Yjs 已成功连接到 WebSocket 服务器')
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '已成功连接到协同文档',
      })
    } else if (event.status === 'connecting') {
      // console.log('Yjs 正在尝试重新连接到 WebSocket 服务器')
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: '正在尝试重新连接到协同文档，若长时间连接不上，请手动刷新！',
      })
    } else if (event.status === 'disconnected') {
      // console.log('Yjs 已断开与 WebSocket 服务器的连接')
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '已断开与协同文档的连接',
      })
    }
  }

  /**
   * 加载完成后的回调
   * */
  onSynced = (event: boolean) => {
    const { yXmlFragment, editorRef, docContent } = this.configInfo
    const editor = editorRef.current

    // console.log(
    //   yXmlFragment.toString(),
    //   '____________________________',
    //   docContent,
    //   '____________________________',
    //   editor.isEmpty,
    // )
    // 只有当yXmlFragment文档和编辑器为空的时候才设置默认值，避免覆盖其他客户端的更改。
    if (yXmlFragment.toString() === '' && editor.isEmpty) {
      editor.setHTML(`${docContent}`)
      events.emit('GET_WORD_COUNT_EDITOR')
    } else {
      // 关闭编辑器 Loading 状态
      EditorInitializationLoading.close()
    }
  }

  /**
   * 监听Awareness状态的变化
   * @params added: (clientID)[] - 新增上线的客户端ID
   * @params updated: (clientID)[] - 更新信息的客户端ID
   * @params removed: (clientID)[] - 下线的客户端ID
   * */
  onChange = ({ added, updated, removed }) => {
    const states = this.awareness.getStates()
    // 本地用户的ID
    const localUserId = this.yjsEditorCoordinationInfo.userInfo.id

    added.forEach((clientID) => {
      const state = states.get(clientID)
      if (state?.user) {
        // console.log(`用户 ${state.user.name} 已上线`, this.members)
        this.memberMap.set(clientID, state.user)
        this.updateMember(state.user, true)

        Message.warning({
          icon: <IconWarnTip useCurrentColor={false} />,
          content: `用户 ${state.user.name} 已上线`,
          duration: 1500,
        })
      }
    })

    updated.forEach((clientID) => {
      const state = states.get(clientID)
      if (state?.user) {
        this.memberMap.set(clientID, state.user)
        this.updateMember(state.user, true)
        // console.log(`用户 ${state.user.name} 更新了信息`)

        // 判断当前用户，如果是当前用户，则刷新定时器
        if (clientID === this.awareness.clientID) {
          // 刷新定时器
          refreshChronology(this.overtime)
        }
      }

      // 获取存储的数据
      const stateMap = this.awareness.getStates().get(clientID)

      // 查找修改了哪个用户的权限，并刷新该用户页面
      if (
        stateMap.modifiedPersonnelEmployeeInfo &&
        JSON.stringify(stateMap.modifiedPersonnelEmployeeInfo.data) !== '{}' &&
        stateMap.modifiedPersonnelEmployeeInfo.data.id ===
          this.yjsEditorCoordinationInfo.userInfo.id
      ) {
        // 处理权限更新
        // console.log('权限更新:', stateMap.modifiedPersonnelEmployeeInfo)
        window.location.reload()
      }

      // const userState = this.awareness.getStates().get(clientID)
      // console.log(`用户 ${clientID} 更新了信息, 新状态:`, userState)
    })

    removed.forEach((clientID) => {
      const user = this.memberMap.get(clientID)
      if (user && user.id !== localUserId) {
        this.offlineClientID = clientID
        this.updateMember(user, false)
        // console.log(``用户 ${user.name} 已下线`, this.members, removed)

        Message.warning({
          icon: <IconWarnTip useCurrentColor={false} />,
          content: `用户 ${user.name} 已下线`,
          duration: 1500,
        })
      }
    })

    // 使用防抖函数进行 dispatch 调用，更新所有协同成员及其在线状态
    this.debouncedDispatch({
      type: 'yjs-editor-coordination-info',
      payload: {
        yjsEditorCoordinationInfo: {
          isOnline: this.members,
        },
      },
    })

    // 输出当前所有协同成员及其在线状态
    // console.log('当前协同成员:', this.members)
    // we log all awareness information from all users. 我们记录所有用户的所有意识信息。
    // console.log(Array.from(this.awareness.getStates().values()))
  }

  /**
   * 全局点击事件监听，用于更新超时时间
   * */
  onGlobalClick = () => {
    console.log(this.offlineClientID, this.awareness.clientID)

    // if (this.offlineClientID === this.awareness.clientID) {
    //   console.warn('当前用户已掉线，点击事件不触发 update 事件')
    //   return
    // }

    // 获取当前文档对象和触发更新
    const { ydoc } = this.configInfo
    ydoc.transact(() => {
      // 创建一个空的事务
    })
  }

  /**
   * 更新成员列表
   * @params user: 用户信息
   * @params isOnline: 用户在线状态
   * */
  updateMember(user, isOnline) {
    if (!this.members || !Array.isArray(this.members)) {
      // console.error('members 数组不存在或无效')
      return
    }

    const index = this.members.findIndex((member) => member.id === user.id)
    if (index !== -1) {
      // 如果成员存在，则更新其在线状态
      this.members[index].online = isOnline
    } else {
      // 如果成员不存在，这是一个错误，因为它应该在初始化时存在
      console.warn(`成员 ${user.name} 不在初始化列表中`)
    }
  }

  /**
   * 初始化成员列表，将所有成员设为离线
   * @params employeeList: 成员列表
   */
  initializeMembers(employeeList) {
    if (!employeeList || !Array.isArray(employeeList)) {
      // console.error('Invalid employeeList provided')
      return
    }

    // 初始化将当前用户客户端ID赋给offlineClientID
    this.offlineClientID = this.awareness.clientID

    // 初始化将客户端ID更新到store
    this.dispatch({
      type: 'yjs-editor-coordination-info',
      payload: {
        yjsEditorCoordinationInfo: {
          clientID: this.awareness.clientID,
        },
      },
    })

    // 初始化默认所有成员离线
    this.members = employeeList.map((employee) => ({
      id: employee.employeeId,
      name: employee.employeeName,
      // 初始状态设为false
      online: false,
    }))
  }

  /**
   * 超时操作
   * */
  overtime = () => {
    console.log('当前用户已超时！')

    // 移除当前用户的 awareness 状态
    const localUserId = this.yjsEditorCoordinationInfo.userInfo.id
    this.awareness.setLocalState(null)

    // 更新本地成员状态
    this.updateMember({ id: localUserId }, false)

    // 通知其他客户端
    this.dispatch({
      type: 'yjs-editor-coordination-info',
      payload: {
        yjsEditorCoordinationInfo: {
          isOnline: this.members,
        },
      },
    })

    // this.disconnect()
    this.destroy()
    this.configInfo.ydoc.destroy()
  }

  /**
   * 文档更新回调
   * @param {import('yjs').Transaction} transaction - 事务对象
   * @param {import('yjs').YDoc} ydoc - yjs文档对象
   */
  onDocumentUpdate = (transaction, ydoc) => {
    // 如果 transaction 没有实际内容修改，那么即使是本地操作，我们也不更新 isLocalUser
    const hasContentChange =
      transaction.changed.size > 0 || transaction?.steps?.length > 0

    /**
     * 主要实现：判断当前操作是否是本地用户
     * */
    if (hasContentChange) {
      if (transaction.local) {
        // const localState = this.awareness.getLocalState()
        // console.log('本地用户信息:', localState)
        this.isLocalUser = true
      } else {
        // const changedUsers = Array.from(this.awareness.getStates().entries())
        // console.log('远程用户操作:', changedUsers)
        this.isLocalUser = false
      }
      // 默认认为操作是本地的
      // this.isLocalUser = transaction.local
    } else {
      // 如果没有内容变更，表示只是光标位置的更新
      // this.isLocalUser = false
    }

    /**
     * 检查 transaction 是否由本地用户触发，并且判断上一个掉线用户是否是当前用户，如果不是将clientIDTui改为当前用户
     * */
    if (transaction.local && this.offlineClientID === ydoc.clientID) {
      // 更新计时器
      refreshChronology(this.overtime)
    } else {
      this.offlineClientID = ydoc.clientID
    }
  }

  /**
   * yjs断连回调
   */
  onDestroy = () => {
    console.log('检测到yjs已经断开连接！')
    // 销毁后将当前用户视角下所有用户设置为离线
    this.members = this.yjsEditorCoordinationInfo.employeeList.employeeList.map(
      (employee) => ({
        id: employee.employeeId,
        name: employee.employeeName,
        // 初始状态设为false
        online: false,
      }),
    )
    clearChronology()

    // 移除全局点击事件监听器
    document.removeEventListener('click', this.onGlobalClick)
  }

  /**
   * 为 dispatch 创建一个防抖包装函数，延迟为1秒
   */
  debouncedDispatch = debounce((action) => {
    this.dispatch(action)
  }, 1000)
}

export default SyncedWebsocketProvider
