import * as Y from 'yjs'

class yDoc extends Y.Doc {
  public yXmlFragment = null

  constructor(uniqueConnectPath) {
    super()
    // 将Y.XmlFragment定义为顶级类型
    this.yXmlFragment = this.getXmlFragment(uniqueConnectPath)

    // 是否在此文档实例上启用垃圾回收。设置为 doc.gc = false 禁用垃圾回收并能够恢复旧内容。
    this.gc = true
  }
}

export default yDoc
