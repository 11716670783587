import React, { lazy, Suspense } from 'react'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

const MODAL_COMPONENTS = {
  AddToFoldModal: lazy(() => import('./addToFold-modal')),
  CreateDocFoldModal: lazy(() => import('./create-doc-fold-modal')),
  RenameModal: lazy(() => import('./rename-doc-fold-modal')),
  ConfirmModal: lazy(() => import('./confirm-modal')),
  ConfirmMobileModal: lazy(() => import('./confirm-mobile-modal')),
  ReviewModal: lazy(() => import('./review-modal')),
  AttributeModal: lazy(() => import('./attribute-modal')),
  ExaminationReportModal: lazy(() => import('./examination-report-modal')),
  EditUserModal: lazy(() => import('./edit-user-modal')),
  EditEmailModal: lazy(() => import('./edit-eamil-modal')),
  EditPasswordModal: lazy(() => import('./edit-password-modal')),
  KeyMapModal: lazy(() => import('./keyMap-modal')),
  ShareLinkModal: lazy(() => import('./share-link-modal')),
  AddTagsModal: lazy(() => import('./add-tags-modal')),
  CollectChatModal: lazy(() => import('./collect-chat-modal')),
  ImageModal: lazy(() => import('./image-modal')),
  OrderDetailModal: lazy(() => import('./order-detail-modal')),
  BindPhoneModal: lazy(() => import('./bind-phone-modal')),
  BindUserWxModal: lazy(() => import('./bind-user-wx-modal')),
  PayMantModal: lazy(() => import('./paymant-modal')),
  VipContentModal: lazy(() => import('./vip-content-modal/index')),
  TemplateModal: lazy(() => import('./template-modal')),
  AiDrawModal: lazy(() => import('./ai-draw-modal')),
  FolderCoverModal: lazy(() => import('./folder-cover-modal')),
  AddTemplateModal: lazy(() => import('./add-template-modal')),
  AddAgentModal: lazy(() => import('./add-agent-modal')),
  SaveAgentModal: lazy(() => import('./save-agent-modal')),
  AddTeamModal: lazy(() => import('./add-team-modal')),
  AIAgentCreateImg: lazy(() => import('./ai-agent-createimg-modal')),
  // 修改记录弹窗
  ModifyRecordModal: lazy(() => import('./modify-record-modal')),
}

const initialModals = []

type State = {
  type: string
  props: any
}

export default class ModalContainer extends React.Component {
  state = {
    modals: initialModals,
  }

  componentDidMount() {
    events.on(ACTIONS.SHOW_MODAL, this.showModal)
    events.on(ACTIONS.HIDE_MODAL, this.hideModal)
    events.on(ACTIONS.UPDTAE_MODAL, this.updateModal)
  }

  componentWillUnmount() {
    events.off(ACTIONS.SHOW_MODAL, this.showModal)
    events.off(ACTIONS.HIDE_MODAL, this.hideModal)
    events.off(ACTIONS.UPDTAE_MODAL, this.updateModal)
  }

  showModal = (payload: State) => {
    this.setState({
      modals: this.state.modals.concat(payload),
    })
  }

  // 更新弹框数据
  updateModal = (payload: State & { modalIndex?: number }) => {
    const modals = this.state.modals || []
    const index = payload.modalIndex || null
    modals.forEach((item) => {
      if (item.type === payload.type && (index === null || index === item)) {
        item.props = payload.props
      }
    })
    this.setState({
      modals: [...modals],
    })
  }

  hideModal = (data: { type: string; modalIndex?: number }) => {
    const type = data.type
    const index = data.modalIndex || null

    // 多个同类型弹窗
    const sameModals = this.state.modals.filter(
      (modal) => modal.type === type,
    ).length
    if (typeof index === 'number' && sameModals > 1) {
      this.setState({
        modals: type
          ? this.state.modals.filter((_, i) => i !== index)
          : initialModals,
      })

      return
    }

    this.setState({
      modals: type
        ? this.state.modals.filter((modal) => modal.type !== type)
        : initialModals,
    })
  }
  render() {
    const Targets = this.state.modals.map(({ type, props }) => ({
      SpecificModal: MODAL_COMPONENTS[type],
      props,
      type,
    }))

    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <Suspense fallback={<></>}>
        {Targets.map(({ SpecificModal, type, props }, index) => (
          <SpecificModal
            key={type + index}
            isOpen={true}
            hide={() => this.hideModal({ type, modalIndex: index })}
            modalProps={props}
          />
        ))}
      </Suspense>
    )
  }
}
