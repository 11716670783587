import React, { useEffect, useState } from 'react'
import style from './style/proofNumsMod.module.less'
import { ProofSelectType, ProofType } from '@/helpers/enums'
import { IconCheck } from '@arco-iconbox/react-aidb-v2'

interface ProofNumsItem {
  title: string
  num: number
  type: any
  boldColor: string
  color: string
}

interface Props {
  list: ProofNumsItem[]
  activeType: number | string
  onChangeProofType: (type: number) => void
}

const ProofNumsMod = (props: Props) => {
  const { list, activeType, onChangeProofType } = props
  return (
    <div className={style.wrapper}>
      {list.map((i) => {
        return (
          <div
            key={i.title}
            className={`${style.item} ${
              activeType == i.type && style.activeType
            }`}
            style={{
              borderColor: activeType == i.type ? i.boldColor : '#EFEFEF',
            }}
            onClick={() => onChangeProofType(i.type)}>
            <div
              className={style.title}
              style={{ color: activeType == i.type && i.boldColor }}>
              {i.title}
            </div>
            <div className={style.tip}>
              <div
                className={style.num}
                style={{
                  backgroundColor: activeType == i.type ? i.boldColor : i.color,
                }}>
                {/* <IconCheck fill="#fff" /> */}
                {i.num == 0 ? <IconCheck fill="#fff" /> : i.num}
              </div>
              <span>
                发现<span style={{ padding: '0 4px' }}>{i.num}</span>处问题
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProofNumsMod
