import React from 'react'
import { SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { IconAnnotation } from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import CommentMod from '../../component/commentMod'
import ReactDOM from 'react-dom'
import {
  IconAddComments,
  IconErrorTip,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import { Message } from '@arco-design/web-react'

const PLUGIN_NAME = 'commentText'

class CommentBubble {
  private dom: HTMLElement
  private yjsEditorCoordinationInfo
  private visible = false
  private editor
  private from
  private to
  private class
  constructor(mountEle: HTMLElement, props) {
    this.yjsEditorCoordinationInfo = props.yjsEditorCoordinationInfo
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.dom.id = 'commentParaBubble'
    mountEle.appendChild(this.dom)
  }

  public show = (
    permission: number,
    top: number,
    paragraphSign: string,
    isFirst: boolean,
    editor,
    from: number,
    to: number,
    commentList: any,
  ) => {
    this.editor = editor
    this.from = from
    this.to = to
    this.visible = true
    this.class = paragraphSign
    this.dom.style.right =
      localStorage.getItem('isFullDisplay') == 'true' ||
      localStorage.getItem('showRight') == 'true'
        ? `0`
        : '-340px'
    this.dom.style.top = `${top}px`
    this.dom.style.display = 'block'
    this.dom.style.background = '#fff'

    ReactDOM.render(
      <CommentMod
        permission={permission}
        domId="commentParaBubble"
        key={paragraphSign + Date.now()}
        paragraphSign={paragraphSign}
        isFirst={isFirst}
        onFirstSubmit={this.firstSubmitHandle}
        commentClassList={commentList}></CommentMod>,
      this.dom,
    )
  }

  public firstSubmitHandle = (isFirst) => {
    const state = this.editor.view.state
    const tr = state.tr
    tr.addMark(
      this.from,
      this.to,
      state.schema.marks.comment.create({
        class: this.class + ' ' + 'hl',
      }),
    )
    this.editor.view.dispatch(tr)
    this.hide()
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '批注成功',
    })
    document
      .querySelector('.button-group')
      .setAttribute('style', 'display:none')
    events.emit(ACTIONS.SAVE_PIC_EDITOR)
    events.emit(ACTIONS.COMMENT_BUBBLE_DISPATCH)
  }

  public hide = () => {
    this.dom.style.display = 'none'
    this.visible = false
  }

  get isVisible() {
    return this.visible
  }
}

class CommentTextController extends SylController {
  public name = PLUGIN_NAME
  private isComment = true
  private curClass
  private newClass
  private commentParaBubble: CommentBubble
  constructor(editor: SylApi, props) {
    super(editor, props)
    this.commentParaBubble = new CommentBubble(this.editor.root, this.props)
    events.on(ACTIONS.CAN_NEXT_COMMENT, this.nextComment)
    document.addEventListener('click', (event) => {
      // 判断点击事件是否发生在 myDiv 之外
      var myDiv = document.getElementById('commentParaBubble')
      var targetNode = event.target as HTMLElement
      if (
        myDiv &&
        !myDiv.contains(targetNode) &&
        !targetNode.classList.contains('aidb-icon-add-comments') &&
        !targetNode.classList.contains('commentBoxBtn') &&
        targetNode.tagName != 'path'
      ) {
        this.commentParaBubble.hide()
      }
    })
  }

  editorWillUnmount(): void {
    events.on(ACTIONS.CAN_NEXT_COMMENT, this.nextComment)
  }
  private nextComment = (payload) => {
    this.isComment = payload.isComment
  }

  private handleChildrensCommentId = (_editor: SylApi) => {
    if (!this.isComment) {
      // Message.warning('您还有一条未批注');
      // return;
      events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
        id: this.newClass,
        className: this.curClass,
      })
    }

    const state = _editor.view.state

    const { $from, $to } = state.selection
    const tr = state.tr

    // check if the selection has a comment Mark
    const hasCommentMark = state.doc.rangeHasMark(
      $from.pos,
      $to.pos,
      state.schema.marks.comment,
    )

    const uniqueId = Date.now() // TODO: use uuid or add user id to make it unique
    this.curClass = 'comment-' + uniqueId
    this.newClass = this.curClass
    if (hasCommentMark) {
      // iterate through the selection marks
      for (let start = $from.pos; start < $to.pos; start++) {
        const _marks = _editor.view.state.doc
          .resolve(start)
          .marksAcross(_editor.view.state.doc.resolve(start + 1))
        const commentMark = _marks.find((mark) => mark.type.name === 'comment')
        if (commentMark) {
          const oldClass = commentMark.attrs.class || ''

          // prepend oldClass !!!
          this.newClass = oldClass + ' ' + this.curClass
          tr.removeMark(start, start + 1, commentMark)
          tr.addMark(
            start,
            start + 1,
            state.schema.marks.comment.create({
              class: this.newClass + ' ' + 'hl',
            }),
          )
        } else {
          tr.addMark(
            start,
            start + 1,
            state.schema.marks.comment.create({
              class: this.newClass + ' ' + 'hl',
            }),
          )
        }
      }
    } else {
      tr.addMark(
        $from.pos,
        $to.pos,
        state.schema.marks.comment.create({
          class: this.newClass + ' ' + 'hl',
        }),
      )
    }

    this.isComment = false
    // events.emit(ACTIONS.FROM_COMMENT_CLICK_EDITOR, {
    //   setSelectContent: '',
    //   barActiveIndex: DocBtnType.annotate,
    //   nodeInfo: '',
    // })

    // events.emit(ACTIONS.ADD_EDITOR_COMMENT, {
    //   id: this.newClass,
    //   class: this.curClass,
    //   content: state.doc.textBetween($from.pos, $to.pos),
    //   setSelectContent: '',
    //   barActiveIndex: DocBtnType.annotate,
    //   nodeInfo: '',
    // })
    _editor.view.dispatch(tr)
  }

  public OpenCommentBubble = (_editor: SylApi) => {
    // console.log(this.isComment)
    // if (!this.isComment) {
    //   events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
    //     id: this.newClass,
    //     className: this.curClass,
    //   })
    // }

    const state = _editor.view.state

    const { $from, $to } = state.selection
    const { doc } = _editor.view.state

    const selectedSlice = doc.slice($from.pos, $to.pos)
    const { left: headLeft, bottom: headBottom } = this.editor.view.coordsAtPos(
      $from.pos,
    )

    const hasCommentMark = state.doc.rangeHasMark(
      $from.pos,
      $to.pos,
      state.schema.marks.comment,
    )

    const {
      top: editorTop,
      left: editorLeft,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const computedLeft = headLeft - editorLeft
    const computedTop = headBottom - editorTop
    const uniqueId = Date.now() // TODO: use uuid or add user id to make it unique
    const curClass = 'comment-' + uniqueId
    if (hasCommentMark) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '不支持叠加批注',
      })
      return
    }
    if (selectedSlice.content.childCount > 1) {
      this.commentParaBubble.show(
        this.props.permission,
        computedTop,
        curClass,
        true,
        this.editor,
        $from.pos,
        $from.pos + selectedSlice.content.firstChild.nodeSize,
        [],
      )
    } else {
      this.commentParaBubble.show(
        this.props.permission,
        computedTop,
        curClass,
        true,
        this.editor,
        $from.pos,
        $to.pos,
        [],
      )
    }

    const tr = state.tr
    this.isComment = false
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    // type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div
          className="commentBoxBtn"
          onClick={() => this.OpenCommentBubble(_editor)}>
          <IconAddComments />
        </div>
      )
    },
    value: [],
  }
}

class CommentTextPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = CommentTextController
}

export { CommentTextPlugin }
