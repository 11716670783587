import { switchProject } from '@/helpers/switchProject'

export interface MenuInterface {
  font: {
    CONTENT_FONT_SIZE: number
    TEXTAREA_FONT_SIZE: number
  }
  interface: {
    INTERFACE_CONNECT_LAST_TALK: boolean
    INTERFACE_HK_NODE: boolean
    INTERFACE_DEFAULT_MODEL:
      | 'XIAO_WU_35'
      | 'XIAO_WU_40'
      | 'XIAO_ZHI'
      | 'XIAO_ZHI_QW'
    websiteCrawlingAndFileUpload: {
      INTERFACE_OUTLINES: boolean
      INTERFACE_QUESTIONS: boolean
      INTERFACE_TAGS: boolean
    }
  }
  screen: {
    dialogueList: {
      IS_MEMORY_OPEN: boolean
      IS_OPEN: boolean
    }
    fullScreen: {
      IS_FULL_SCREEN: boolean
      IS_FULL_SCREEN_FIX: boolean
    }
    screenWidth: {
      CHAT_AREA_WIDTH: number
      TEXTAREA_AREA_WIDTH: number
    }
  }
  other: object
}
// 冻结对象，不可被修改
function deepFreeze(object: MenuInterface) {
  var propNames = Object.getOwnPropertyNames(object)

  for (let name of propNames) {
    let value = object[name]

    object[name] =
      value && typeof value === 'object' ? deepFreeze(value) : value
  }

  return Object.freeze(object)
}

export const getConfig: MenuInterface = deepFreeze({
  font: {
    // 内容字体大小
    CONTENT_FONT_SIZE: 14,
    // textarea字体大小
    TEXTAREA_FONT_SIZE: 14,
  },
  interface: {
    // 初识对接
    INTERFACE_CONNECT_LAST_TALK: true,
    // 海外节点
    INTERFACE_HK_NODE: false,
    // 默认助理接口
    INTERFACE_DEFAULT_MODEL: switchProject('DFZ') ? 'XIAO_WU_40' : 'XIAO_ZHI',
    // 网址抓取 / 文件上传
    websiteCrawlingAndFileUpload: {
      INTERFACE_OUTLINES: true,
      INTERFACE_QUESTIONS: true,
      INTERFACE_TAGS: true,
    },
  },
  screen: {
    dialogueList: {
      // 是否初始打开对话列表
      IS_MEMORY_OPEN: true,
      IS_OPEN: false,
    },
    fullScreen: {
      // 切换全屏
      IS_FULL_SCREEN: false,
      // 记住全屏
      IS_FULL_SCREEN_FIX: false,
    },
    screenWidth: {
      // 聊天区宽度
      CHAT_AREA_WIDTH: 90,
      // 输入框宽度
      TEXTAREA_AREA_WIDTH: 90,
    },
  },
  other: {},
})
