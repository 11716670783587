import { SylApi, Inline, SylController, SylPlugin } from '@syllepsis/adapter'

interface DisableAttrs {
  class: string
}

const PLUGIN_NAME = 'disable'

class DisableSchema extends Inline<DisableAttrs> {
  public name = PLUGIN_NAME
}

class DisableController extends SylController {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
  }

  public keymap = {
    Backspace: () => {
      return true
    },
    Enter: () => {
      return true
    },
    Delete: () => {
      return true
    },
    'mod-j': () => this.handleCtrlK(),
  }

  private handleCtrlK = () => {
    console.log('ooo')
    return true
  }
}

class DisablePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = DisableController
  public Schema = DisableSchema
}

export { DisablePlugin }
