import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import React, { useEffect } from 'react'
import style from './style/proofTactfulItem.module.less'
interface riskWordsItem {
  keyword: string
  position: number
}

interface Props {
  riskTips: string
  words: string
  nodeIndex: string
  expand: boolean
  content: string
  indexNum: number
  contentComplete: string
  onExpand: (nodeIndex: number) => void
  riskWords: riskWordsItem[]
}

const ProffTactfulItem = (props: Props) => {
  const {
    riskTips,
    riskWords,
    words,
    indexNum,
    expand,
    nodeIndex,
    content,
    contentComplete,
    onExpand,
  } = props
  const uniqueKeywordsSet = new Set()

  useEffect(() => {
    if (expand) {
      events.emit(ACTIONS.ADD_EDITOR_TIP_PROOF_TACTFUL, {
        riskWords: riskWords,
        nodeIndex: nodeIndex,
        content: content,
        contentComplete: contentComplete,
      })
    }
  }, [expand])

  const concatenatedKeywords = riskWords.reduce(
    (accumulator, currentKeyword, index) => {
      const keywordToAdd = currentKeyword.keyword

      // Check if the keyword is not already in the Set
      if (!uniqueKeywordsSet.has(keywordToAdd)) {
        // Add the keyword to the Set and the accumulator
        uniqueKeywordsSet.add(keywordToAdd)

        accumulator +=
          keywordToAdd + (index === riskWords.length - 1 ? '' : '、')
      }

      return accumulator
    },
    '',
  )

  const expandHandle = () => {
    onExpand(indexNum)
  }

  return (
    <div
      className={style.item}
      style={{ border: expand && '2px solid #E33E4A' }}
      onClick={expandHandle}>
      <p className={style.riskTips}>{riskTips}</p>
      <p className={style.concatenatedKeywords}>{concatenatedKeywords}</p>
    </div>
  )
}

export default ProffTactfulItem
