import React, { useState, useRef, useEffect } from 'react'
import styles from './index.module.less'
import Style from 'styled-components'
import getDefaultModule from './lib/module'
import { SylApi, EventChannel } from '@syllepsis/adapter'
import '@syllepsis/plugin-basic/assets/style.css'
import { SylEditor } from '@syllepsis/access-react'
import defaultPlugins from './lib/plugin'
import { getSelectionInfo } from './lib/utils'
const SylEditorStyle = Style.div<{
  btnWidth: string
  btnHeight: string
  isShare: boolean
}>`
   overflow-y: scroll;
  overflow-x: hidden;
`

interface Props {
  docContent?: string
  isAndroid: boolean
  hideBottom: boolean
  docTitle?: string
  viewHeight: number
  showDocEdit?: boolean
  editTitle?: React.Dispatch<React.SetStateAction<string>>
  editInputTitle?: React.Dispatch<React.SetStateAction<string>>
  editContent: React.Dispatch<React.SetStateAction<string>>
  changeContent?: React.Dispatch<React.SetStateAction<string>>
  getContentDoc?: any
  onSubmit?: (approvalStatus: number) => void
  isComment?: boolean
  btnWidth?: number
  btnHeight?: number
  changeContentContent?: React.Dispatch<React.SetStateAction<number>>
  contentLen?: number
  isShare?: boolean
  changeHeader?: (header: any) => void
  isAutoSave?: boolean
  onSelectContent: (selectContent: string, nodeInfo: any) => void
}

const WritingMobile = (props: Props) => {
  const {
    docTitle,
    docContent,
    viewHeight,
    isAndroid,
    hideBottom,
    showDocEdit,
    editTitle,
    editContent,
    changeContent,
    changeContentContent,
    getContentDoc,
    onSubmit,
    // editInputTitle,
    isComment,
    btnWidth,
    btnHeight,
    contentLen,
    isShare,
    changeHeader,
    isAutoSave,
    onSelectContent,
  } = props
  const EditorContainerRef = useRef<HTMLDivElement>(null)
  const ToolBarMountRef = useRef(document.createElement('div'))
  const [plugins] = useState(defaultPlugins)
  const [module, setModule] = useState(
    getDefaultModule(ToolBarMountRef.current, 0),
  )
  const editorRef = useRef<SylApi>(null)

  // const handleScroll = () => {
  //   document.querySelector('#toolbarContainer').setAttribute(
  //     'style',
  //     `
  //     width: 100%;
  //     left: 0;
  //     overflow-x: scroll;
  //     position: fixed;
  //     display: none;
  //     `,
  //   )
  // }

  // window.addEventListener('scroll', handleScroll)
  useEffect(() => {
    const container = EditorContainerRef.current
    ToolBarMountRef.current.id = `toolbarContainer`
    container.appendChild(ToolBarMountRef.current)

    document.querySelector('#toolbarContainer').setAttribute(
      'style',
      `
        width: 100%;
        left: 0;
        overflow-x: scroll;
        position: fixed;
        display:'block';
        bottom:0
        `,
    )
  }, [])
  // useEffect(() => {
  //   const container = EditorContainerRef.current
  //   ToolBarMountRef.current.id = `toolbarContainer`
  //   container.appendChild(ToolBarMountRef.current)
  //   if (isAndroid) {
  //     document.querySelector('#toolbarContainer').setAttribute(
  //       'style',
  //       `
  //       width: 100%;
  //       left: 0;
  //       overflow-x: scroll;
  //       position: fixed;
  //       display:${isAndroid && hideBottom ? 'block' : 'none'};
  //       bottom:${isAndroid && hideBottom ? '0' : viewHeight + 'px'}
  //       `,
  //     )
  //   } else {
  //     document.querySelector('#toolbarContainer').setAttribute(
  //       'style',
  //       `
  //       width: 100%;
  //       left: 0;
  //       overflow-x: scroll;
  //       position: fixed;
  //       z-index:100;
  //       display:${viewHeight == window.innerHeight ? 'none' : 'block'};
  //       bottom:${viewHeight - 70 + 'px'};
  //       `,
  //     )
  //   }
  // }, [viewHeight])

  useEffect(() => {
    const editor = editorRef.current
    docContent && editor.setHTML(`${docContent}`)
    getContentDoc(editor.getContent())
  }, [docContent])

  useEffect(() => {
    const editor = editorRef.current
    editor.on(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
    editor.on(EventChannel.LocalEvent.SELECTION_CHANGED, handleSelectChange)
    return () => {
      editor.off(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
      editor.off(EventChannel.LocalEvent.SELECTION_CHANGED, handleSelectChange)
    }
  }, [])

  const handleSelectChange = () => {
    const editor = editorRef.current
    const { selectedContent, nodeInfo } = getSelectionInfo(editor)
    onSelectContent(selectedContent, nodeInfo)
  }

  const handleTextChange = () => {
    const editor = editorRef.current
    // changeContentContent(editor.getText().length)
    getContentDoc(editor.getContent())
    editContent(editor.getHTML({ layerType: 'test' }))
  }

  return (
    <div
      ref={EditorContainerRef}
      id="editor"
      style={{ height: isAndroid ? 'calc(100vh - 210px)' : 'auto' }}>
      <SylEditorStyle
        id="sylEditorBox"
        btnWidth={btnWidth}
        btnHeight={btnHeight}
        isShare={isShare}
        style={{
          position: 'relative',
          height: isAndroid ? 'calc(100vh - 210px)' : 'auto',
          paddingBottom: window.innerHeight / 2 + 'px',
        }}>
        <SylEditor
          placeholder="请输入内容，您的文档将会自动保存。"
          getEditor={(editor) => {
            editorRef.current = editor
          }}
          plugins={plugins}
          module={module}
          disable={isShare}
          adjust-position="false"
        />
      </SylEditorStyle>
    </div>
  )
}

export default WritingMobile
