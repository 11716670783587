/**
 * 编辑器所有配置信息
 */


/**
 * 编辑器布局
*/
// 编辑器布局拉伸默认宽度
export const EDITOR_LAYOUT_STRETCH_DEFAULT_WIDTH = 300
// 编辑器布局拉伸最大宽度
export const EDITOR_LAYOUT_STRETCH_MAX_WIDTH = 600
// 编辑器布局拉伸最小宽度
export const EDITOR_LAYOUT_STRETCH_MIN_WIDTH = 200

/**
 * 编辑器修改记录
*/
// 编辑器修改记录每次请求条数
export const EDITOR_MODIFY_RECORD_REQUEST_COUNT = 20
// 编辑器修改记录diff视图对比class类名
export const EDITOR_MODIFY_RECORD_DIFF_CLASS_NAME = {
  class: 'version-diff-all',
  beforeClass: 'version-diff-before',
  afterClass: 'version-diff-after',
}
// 编辑器修改记录diff视图对比颜色
export const EDITOR_MODIFY_RECORD_DIFF_COLOR = {
  added: '#1CB075',
  addedBackgroundColor: '#D8FFD8',
  removed: '#ac1e1e',
  removedBackgroundColor: '#FFDFDF',
  unchanged: '#000000',
  unchangedBackgroundColor: '#FFF',
}

/**
 * 编辑器插件
*/
// 编辑器 EmojiPlugin 插件需要去掉的表情 aria-label
export const EDITOR_EMOJI_PLUGIN_REMOVE_ARIA_LABEL = [
  'flag-tw'
]

/**
 * 其他
*/
// 编辑器回顾历史记录每次请求条数
export const EDITOR_REVIEW_HISTORY_RECORD_REQUEST_COUNT = 20