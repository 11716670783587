import React, { useState, useRef, useEffect } from 'react'
import styles from './index.module.less'
import Style from 'styled-components'
import { SylApi, EventChannel } from '@syllepsis/adapter'
import Title from './lib/component/title'
import defaultPlugins from './lib/plugin'
import getDefaultModule from './lib/module'
import commentPlugins from './lib/plugin-comment'
import getCommentModule from './lib/module-comment'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { SylEditor } from '@syllepsis/access-react'
import { locale } from './lib/utils/index'
import '@syllepsis/plugin-basic/assets/style.css'
const SylEditorStyle = Style.div<{
  btnWidth: string
  btnHeight: string
  isShare: boolean
}>`
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 30px;
  margin-bottom: 30px;
  
  .syl-editor{
    font-size:17px;
    color:#545454;
    // font-weight: 500;
    font-family: sans-serif !important;
    width:${({ isShare }) => isShare && '700px'};
    margin:${({ isShare }) => isShare && '0 auto'};
    .tableWrapper{
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
        height: 6px;
      }
    }
    .ProseMirror{
      width:720px;
      padding-top:0;
      padding-bottom:40px;
      border:none;
      blockquote {
        background-color: #f9f9f9;
        border-left: 4px solid #ccc;
        margin: 0 0 20px;
        padding: 10px 20px;
      }
      /* 标题样式 */
      h1 {
        font-size: 36px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      h2 {
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      h3 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      /* 段落样式 */
      p {
        margin-bottom: 15px;
        line-height: 1.5;
      }
    
      /* 引用样式 */
      blockquote {
        margin: 0 0 20px;
        padding: 10px 20px;
        border-left: 4px solid #ccc;
        background-color: #f9f9f9;
            p {
              line-height: 1.2;
            }

            p:last-child {
              margin-bottom: 0;
            }
      }
    
      /* 无序列表样式 */
      ul {
        margin-bottom: 15px;
        list-style-type: disc;
        padding-left: 40px;
      }
    
      /* 有序列表样式 */
      ol {
        margin-bottom: 15px;
        list-style-type: decimal;
        padding-left: 40px;
      }
    
      /* 链接样式 */
      a {
        color: #e67023;
        text-decoration: none;
      }
    
      a:hover {
        text-decoration: underline;
      }
    
      /* 表格样式 */
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 15px;
      }
    
      th,
      td {
        padding: 8px;
        border: 1px solid #ccc;
      }
    
      th {
        background-color: #f2f2f2;
      }
    
      /* 图像样式 */
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
      }
    
      /* 代码块样式 */
      pre {
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 4px;
        overflow-x: auto;
      }
    
      code {
        font-family: Consolas, Monaco, monospace;
      }
    }
  }
 
  .toolbar{
    background-color:#000;
    position: absolute;
    bottom: 0;
  }
  
  .syl-toolbar-tool.active .syl-toolbar-button{
    background:#fff;
  }

  .syl-toolbar-button > div span {
    font-size: 14px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .syl-toolbar-inline> *:not(:last-child) {
    margin:0;
  }
  .syl-toolbar-inline> *:not(:first-child) .syl-toolbar-button:before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    background-color: #e4e4e4;
    height: 24px;
    margin-top: -12px;
    z-index:-1;
  }

  .syl-toolbar-inline .syl-toolbar-tool:hover{
    border-radius:24px;
  }
 
  .syl-toolbar-inline{
    background-color:#fff;
    padding:0;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.2)!important;
    .syl-toolbar-tool{
       .syl-toolbar-button{
        margin:0;
       }
       &:hover{
        background-color:#fff!important;
        border-radius: 4px!important;
       }
       .syl-toolbar-button {
        height:48px;
        width:88px;
        width: ${({ btnWidth }) => (btnWidth ? btnWidth + 'px' : '88px')};
        height: ${({ btnHeight }) => (btnHeight ? btnHeight + 'px' : '48px')};
        border:none;
         min-width: 0; 
         min-height: 0;
         line-height: 0;
         .iconBox{
          justify-content: center;
          height: 48px;
          line-height: 48px;
          display: flex;
          .icon{
            width:20px;
            height:20px;
            margin: auto 6px;
            display: inline-block !important;
          }
          .iconParaphrase{
            color:#1EB980;
          }
          .iconComment{
            color:#FF6A00;
          }
          .iconTranslate{
            color:#874CC3;
          }
         }
        .rewriteBox:hover{
         border-radius:4px 0  0 4px;
          background-color:#1EB980;
          color:#fff;
          .iconParaphrase{
            color:#fff;
          }
         }
         .commentBox{
          // color:#DAC4B6;
          // height:38px;
          box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
          border-radius:4px;
          // border: 1px solid #DAC4B6;
          .iconContinue{
            // color:#DAC4B6;
          }
         }
         .commentBox:hover{
          // background-color:#FF6A00;
          // color:#fff;;
          border-radius:4px;
          .iconContinue{
            // color:#fff;
          }
         }
         
         .toolsBox:hover{
          border-radius:0 4px 4px 0;
          background-color:#f1f1f1;
          }
         .translateBox:hover{
          .syl-toolbar-inline> *:not(:first-child) .syl-toolbar-button:before{
            background-color:#874CC3;
          }
          background-color:#874CC3;
          color:#fff;
          .iconTranslate{
            color:#fff;
          }
         }
       }
       
    }
  }
  
`

interface Props {
  docContent?: string
  docTitle?: string
  showDocEdit?: boolean
  editTitle?: React.Dispatch<React.SetStateAction<string>>
  editInputTitle?: React.Dispatch<React.SetStateAction<string>>
  editContent?: React.Dispatch<React.SetStateAction<string>>
  changeContent?: React.Dispatch<React.SetStateAction<string>>
  getContentDoc?: any
  onSubmit?: (approvalStatus: number) => void
  isComment?: boolean
  btnWidth?: number
  btnHeight?: number
  changeContentContent?: React.Dispatch<React.SetStateAction<number>>
  contentLen?: number
  isShare?: boolean
  changeHeader?: (header: any) => void
  isAutoSave?: boolean
}

const Writing = (props: Props) => {
  const {
    docTitle,
    docContent,
    showDocEdit,
    editTitle,
    editContent,
    changeContent,
    changeContentContent,
    getContentDoc,
    onSubmit,
    // editInputTitle,
    isComment,
    btnWidth,
    btnHeight,
    contentLen,
    isShare,
    changeHeader,
    isAutoSave,
  } = props
  const EditorContainerRef = useRef<HTMLDivElement>(null)
  const ToolBarMountRef = useRef(document.createElement('div'))
  const TitleRef = useRef(null)
  const editorRef = useRef<SylApi>(null)
  const [plugins] = useState(
    !isComment || !isShare ? defaultPlugins : commentPlugins,
  )
  const [module] = useState(
    !isComment ? getDefaultModule(ToolBarMountRef.current) : getCommentModule(),
  )
  const [wordCount, setWordCount] = useState<any>(0)
  const headerListRef = useRef([])
  let scrollTop, editorHeight
  useEffect(() => {
    const container = EditorContainerRef.current
    ToolBarMountRef.current.id = 'toolbarContainer'
    container.appendChild(ToolBarMountRef.current)
    document
      .querySelector('#toolbarContainer')
      .setAttribute(
        'style',
        `position: absolute;display:${
          isShare && 'none'
        };bottom:0;border:none;font-family: sans-serif ;width:720px`,
      )
  }, [])

  useEffect(() => {
    const setEditorMaxHeight = () => {
      editorHeight = +getComputedStyle(
        document.querySelector('.syl-editor'),
      ).height.replace('px', '')
      document
        .querySelector('.ProseMirror')
        .setAttribute('style', `min-height: ${editorHeight}px; `)
    }
    setEditorMaxHeight()
    window.addEventListener('resize', setEditorMaxHeight)
    return () => {
      window.removeEventListener('resize', setEditorMaxHeight)
    }
  }, [])

  useEffect(() => {
    const sylEditor = document.getElementsByClassName('syl-editor')[0]
    sylEditor.addEventListener('scroll', () => {
      scrollTop = document.getElementsByClassName('syl-editor')[0].scrollTop
    })
  }, [scrollTop])

  useEffect(() => {
    const editor = editorRef.current
    docContent && editor.setHTML(`${docContent}`)
    const pureText = String(docContent).replace(/<[^>]+>/g, '')
    setWordCount(docContent ? pureText.length : 0)
    if (docContent && !isComment) {
      getContentDoc(editor.getContent())
      changeContentContent(editor.getText().length)
    }
    headerListRef.current = generateTableOfContents(editor)
    // if (docContent && isComment && isShare) {
    //   changeHeader(generateTableOfContents(editor))
    // }
    //
  }, [docContent])

  useEffect(() => {
    const editor = editorRef.current
    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const handleScroll = () => {
      const scrollHeight = sylEditorBox.scrollTop
      processArray(headerListRef.current, scrollHeight)
    }
    sylEditorBox.addEventListener('scroll', handleScroll)

    return () => {
      sylEditorBox.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const checkRange = (value, obj1, obj2) => {
    return value + 136 >= obj1.headTop && value + 136 <= obj2.headTop
  }

  const checkRangeLast = (value, obj1) => {
    return value + 136 >= obj1.headTop
  }
  const processArray = (data, value) => {
    for (let i = 0; i < data.length; i++) {
      const currentObj = data[i]
      const nextObj = data[i + 1]
      if (i === data.length - 1) {
        if (checkRangeLast(value, currentObj)) {
          events.emit(ACTIONS.NAV_SCROLL_EDITOR, {
            obj: currentObj,
            data: data,
          })
        }
      } else {
        if (checkRange(value, currentObj, nextObj)) {
          events.emit(ACTIONS.NAV_SCROLL_EDITOR, {
            obj: currentObj,
            data: data,
          })
        }
      }
    }
  }

  useEffect(() => {
    events.on(ACTIONS.COMMENT, commentHandle)
    events.on(ACTIONS.TO_EDITOR_UNDERLINE, commentLineHandle)
    events.on(ACTIONS.CANCEL_COMMENT, cancelCommentHandle)
    return () => {
      events.off(ACTIONS.COMMENT, commentHandle)
      events.off(ACTIONS.TO_EDITOR_UNDERLINE, commentLineHandle)
      events.off(ACTIONS.CANCEL_COMMENT, cancelCommentHandle)
    }
  }, [])

  const handleTextChange = () => {
    if (isComment) return
    const editor = editorRef.current
    setWordCount(editor.getText().length)
    changeContentContent(editor.getText().length)
    editContent(editor.getHTML({ layerType: 'test' }))
    getContentDoc(editor.getContent())
    changeContent(editor.getHTML())
  }

  useEffect(() => {
    const editor = editorRef.current
    editor.on(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
    return () => {
      editor.off(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
    }
  }, [])

  useEffect(() => {
    if (isComment) {
      const editor = editorRef.current
      const handleTextChange = () => {
        changeContent(editor.getHTML())
      }
      editor.on(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)

      return () => {
        editor.off(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
      }
    }
  }, [])

  const titleHandle = (text) => {
    editTitle(text)
  }

  const cancelCommentHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.background
    const tr = editor.view.state.tr.removeMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create({ color: 'rgb(255, 229, 212)' }),
    )
    editor.view.dispatch(tr)
  }

  // const inputTitleHandle = (title) => {
  //   editInputTitle(title)
  // }

  useEffect(() => {
    if (isComment && wordCount != 0) {
      const editor = editorRef.current
      editor.on(EventChannel.LocalEvent.TEXT_CHANGED, () => {
        if (contentLen != editor.getText().length) {
          editor.undo()
        }
      })
    }
    localStorage.setItem('wordCount', wordCount)
  }, [wordCount])

  const commentHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.background
    const tr = editor.view.state.tr.addMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create({ color: 'rgb(255, 229, 212)' }),
    )
    editor.view.dispatch(tr)
  }

  const commentLineHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.underline
    const tr = editor.view.state.tr.addMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create(),
    )
    editor.view.dispatch(tr)
  }

  const generateTableOfContents = (editor) => {
    const headings = []
    const state = editor.view.state
    const root = state.doc
    root.descendants((node, pos) => {
      if (node.type.name === 'header' && node.content.content.length > 0) {
        const headingText = node.content?.content[0].text
        const headingLevel = node.attrs.level
        const { bottom: headTop } = editor.view.coordsAtPos(pos)
        const { top: headBottom } = editor.view.coordsAtPos(
          pos + headingText.length,
        )
        headings.push({
          text: headingText,
          level: headingLevel,
          pos,
          headTop: headTop,
          headBottom: headBottom,
        })
      }
    })

    return headings
  }

  return (
    <div
      ref={EditorContainerRef}
      className={`${styles.editorContainer} ${
        !showDocEdit && !isShare && styles.onlyDoc
      } ${isComment && styles['isComment']}`}>
      {!isShare && (
        <Title
          ref={TitleRef}
          value={docTitle}
          setTitle={titleHandle}
          // setInputTitle={inputTitleHandle}
          onSubmit={onSubmit}
          isComment={isComment}
          isAutoSave={isAutoSave}
        />
      )}

      <SylEditorStyle
        style={{
          height: `${
            // isComment ? 'calc(100vh - 130px)' : 'calc(100vh - 224px)'
            isComment && !isShare
              ? 'calc(100vh - 130px)'
              : isShare
              ? 'calc(100vh - 160px)'
              : 'calc(100vh - 154px)'
          }`,
          position: 'relative',
        }}
        id="sylEditorBox"
        btnWidth={btnWidth}
        btnHeight={btnHeight}
        isShare={isShare}>
        <SylEditor
          placeholder="请输入内容，您的文档将会自动保存。"
          getEditor={(editor) => {
            editorRef.current = editor
          }}
          plugins={plugins}
          module={module}
          locale={locale}
          disable={isShare}
        />
      </SylEditorStyle>
      {/* <div
        className={
          styles[!isComment ? 'maskBottom' : 'maskCommentBottom']
        }></div> */}
      {!isShare && <span className={styles.wordCount}>{wordCount}字</span>}
    </div>
  )
}

export default Writing
