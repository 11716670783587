export const ACTIONS = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  UPDTAE_MODAL: 'UPDTAE_MODAL',
  SHOW_DRAWER: 'SHOW_DRAWER',
  HIDE_DRAWER: 'HIDE_DRAWER',
  UPDATE_DRAWER: 'UPDATE_DRAWER',
  FROM_EDITOR: 'FROM_EDITOR',
  FROM_PROOF_EDITOR: 'FROM_PROOF_EDITOR',
  FROM_REWRITE_CLICK_EDITOR: 'FROM_REWRITE_CLICK_EDITOR',
  FROM_TRANS_CLICK_EDITOR: 'FROM_TRANS_CLICK_EDITOR',
  FROM_TOOLS_CLICK_EDITOR: 'FROM_TOOLS_CLICK_EDITOR',
  FROM_COMMENT_CLICK_EDITOR: 'FROM_COMMENT_CLICK_EDITOR',
  FROM_CHATGPT_CLICK_EDITOR: 'FROM_CHATGPT_CLICK_EDITOR',
  FROM_CONTINUE_CLICK_EDITOR: 'FROM_CONTINUE_CLICK_EDITOR',
  FROM_REDUCE_CLICK_EDITOR: 'FROM_REDUCE_CLICK_EDITOR',
  FROM_DIRECTIVES_CLICK_EDITOR: 'FROM_DIRECTIVES_CLICK_EDITOR',
  TO_EDITOR: 'TO_EDITOR',
  ADD_EDITOR_TIP: 'ADD_EDITOR_TIP',
  ADD_EDITOR_TIP_PROOF: 'ADD_EDITOR_TIP_PROOF',
  ADD_EDITOR_TIP_PROOF_M: 'ADD_EDITOR_TIP_PROOF_M',
  ADD_EDITOR_TIP_PROOF_OFFICIAL: 'ADD_EDITOR_TIP_PROOF_OFFICIAL',
  ADD_EDITOR_TIP_PROOF_TACTFUL: 'ADD_EDITOR_TIP_PROOF_TACTFUL',
  REMOVE_EDITOR_TIP: 'REMOVE_EDITOR_TIP',
  REMOVE_EDITOR_TIP_OFFICIAL: 'REMOVE_EDITOR_TIP_OFFICIAL',
  REPLACE_TO_EDITOR_TIP: 'REPLACE_TO_EDITOR_TIP',
  REPLACE_ALL_EDITOR: 'REPLACE_ALL_EDITOR', //替换全部
  ADD_REWRITE_EDITOR_TIP: 'ADD_REWRITE_EDITOR_TIP',
  REMOVE_REWRITE_EDITOR_TIP: 'REMOVE_REWRITE_EDITOR_TIP',
  REPLACE_REWRITE_TO_EDITOR_TIP: 'REPLACE_REWRITE_TO_EDITOR_TIP',
  REPLACE_CHANGE: 'REPLACE_CHANGE', //提醒要替换的内容已经被替换掉了
  REMOVE_COMMENT_HL_EDITOR: 'REMOVE_COMMENT_HL_EDITOR',
  REMOVE_COMMENT_BUBBLE: 'REMOVE_COMMENT_BUBBLE', //删除评论气泡
  COMMENT_BUBBLE_HIGH_LIGHT: 'COMMENT_BUBBLE_HIGH_LIGHT', //批注气泡高亮
  FORMAT_CREATE_COMMENT: 'FORMAT_CREATE_COMMENT',
  COMMENT_BUBBLE_HEADER_SHOW: 'COMMENT_BUBBLE_HEADER_SHOW',
  COLLAPSE_ALL_ANNOTATIONS: 'COLLAPSE_ALL_ANNOTATIONS', //  一键展开所有批注
  CONTINUE_EDITOR: 'CONTINUE_EDITOR', //编辑器扩写
  CONTINUE_ADOPT_EDITOR: 'CONTINUE_ADOPT_EDITOR', //编辑器扩写采用
  DIRECTIVES_ADOPT_EDITOR: 'DIRECTIVES_ADOPT_EDITOR', //编辑器指令采用（编辑器添加文字）
  CLOSE_DIRECTIVES_EDITOR: 'CLOSE_DIRECTIVES_EDITOR', //关闭辑器指令
  OPEN_DIRECTIVES_EDITOR: 'OPEN_DIRECTIVES_EDITOR', //打开编辑指令
  DIRECTIVES_SUBMIT_EDITOR: 'DIRECTIVES_SUBMIT_EDITOR', //指令快捷发送
  DIRECTIVES_ENTER_INNER: 'DIRECTIVES_ENTER_INNER', //回车进入指令输入
  CONTINUE_CANCEL_EDITOR: 'CONTINUE_CANCEL_EDITOR', //编辑器采用
  PROOF_REPLACE_ALL: 'PROOF_REPLACE_ALL', //校对替换全部
  REPLY: 'REPLY',
  COMMENT: 'COMMENT',
  CANCEL_COMMENT: 'CANCEL_COMMENT',
  ADD_COMMENT: 'ADD_COMMENT',
  ADD_EDITOR_COMMENT: 'ADD_EDITOR_COMMENT',
  REMOVE_COMMENT_EDITOR: 'REMOVE_COMMENT_EDITOR',
  REMOVE_COMMENT_EDITBOX: 'REMOVE_COMMENT_EDITBOX', //删除单个批注
  REMOVE_ALL_COMMENT_EDITBOX: 'REMOVE_ALL_COMMENT_EDITBOX', //删除单个批注
  TO_EDITOR_UNDERLINE: 'TO_EDITOR_UNDERLINE',
  CAN_NEXT_COMMENT: 'CAN_NEXT_COMMENT',
  DELETE_DOC: 'DELETE_DOC', //移至废纸篓
  RENAME_DOCUMENT: 'RENAME_DOCUMENT', //重命名文档/文件夹
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT', //移动
  COPY_DOCUMENT: 'COPY_DOCUMENT', //生成副本
  RETURN_DOCUMENT: 'RETURN_DOCUMENT', //还原文档
  DELETE_DOCUMENT: 'DELETE_DOCUMENT', //删除文档
  TOOL_SELECT: 'TOOL_SELECT', //工具选中
  SAVE_PIC_EDITOR: 'SAVE_PIC_EDITOR', //编辑器内容保存
  SAVE_TASKLIST_EDITOR: 'SAVE_TASKLIST_EDITOR', //编辑器任务列表保存
  SUBMIT_DOC_HANDEL: 'SUBMIT_DOC_HANDEL', //提交文档处理
  MOD_S_SAVE: 'MOD_S_SAVE', //保存快捷键
  MOD_P_SAVE: 'MOD_P_SAVE', //发布快捷键
  MOD_Z_UNDO: 'MOD_Z_UNDO', //撤销快捷键
  MOD_Z_REDO: 'MOD_Z_REDO', //回退快捷键
  MOD_CTRL_9_TASK: 'MOD_CTRL_9_TASK', //任务列表快捷键
  MOD_INNER_TOOL: 'MOD_INNER_TOOL', //斜杠mod+/ 打开
  EDIT_USERINFO: 'EDIT_USERINFO',
  INPUT_ENTER: 'INPUT_ENTER', //编辑器标题回车快捷键
  CANCEL_COLLECT: 'CANCEL_COLLECT', //单个取消收藏
  COLLECT_FALSE: 'COLLECT_FALSE', //取消收藏（文档）
  COLLECT_TURE: 'COLLECT_TURE', //添加收藏（文档）
  NAV_CLICk_EDITOR: 'NAV_CLICk_EDITOR', //编辑器点击目录
  NAV_SCROLL_EDITOR: 'NAV_SCROLL_EDITOR', ////编辑器目录和滚动栏跟随
  NAV_LIST_EDITOR: 'NAV_LIST_EDITOR', ////编辑器目录和滚动栏跟随
  GET_WORD_COUNT_EDITOR: 'GET_WORD_COUNT_EDITOR',
  ADD_TAG: 'ADD_TAG', //新增标签
  DELETE_TAG: 'DELETE_TAG', //删除标签
  EDIT_TAG: 'EDIT_TAG', //编辑标签
  GET_DOC_TAG: 'GET_DOC_TAG', //根据标签列表筛选
  ADD_DOC_TAG: 'ADD_DOC_TAG', //给文档添加标签
  DOC_CHECK_TAG: 'DOC_CHECK_TAG', //选中文档标签筛选
  COLLECT_EDIT_RETURN: 'COLLECT_EDIT_RETURN', //编辑器文件夹返回
  RETURN_TOP_EDITOR: 'RETURN_TOP_EDITOR', //返回顶部
  TEM_SENDMSG: 'TEM_SENDMSG', //分布发送请求全文写作
  TEM_SHOW_BUTTON: 'TEM_SHOW_BUTTON', //控制按钮显示隐藏 单个模版库
  TEM_SCROLLTOP: 'TEM_SCROLLTOP', //控制模版库滚动
  TEM_OUTLINEBTN: 'TEM_OUTLINEBTN', //前往下一步大纲按钮
  RESET_AIIMG_BASE64: 'RESET_AIIMG_BASE64', //清空并删除上传图片
  BASE64URL: 'BASE64URL', //存储原生url用于重新生成
  ADVANCED_SEARCH_SCREEN_RETURN: 'ADVANCED_SEARCH_SCREEN_RETURN', // 高级搜索回屏
  TEMALLMARKDOWN: 'TEMALLMARKDOWN', //导入markdown直接生成全文
  COMMENT_BUBBLE: 'COMMENT_BUBBLE', //批注气泡
  ILLUSTRATE_BUBBLE: 'ILLUSTRATE_BUBBLE', //说明气泡
  ILLUSTRATE_BUBBLE_SUBMIT: 'ILLUSTRATE_BUBBLE_SUBMIT', //说明气泡提交
  ILLUSTRATE_BUBBLE_EDIT_STATUS: 'ILLUSTRATE_BUBBLE_EDIT_STATUS', //说明气泡修改状态
  ILLUSTRATE_BUBBLE_HIDE: 'ILLUSTRATE_BUBBLE_HIDE', //说明气泡修隐藏
  COMMENT_BUBBLE_DISPATCH: 'COMMENT_BUBBLE_DISPATCH', //批注气泡更新
  TREE_UUID_CLEAR: 'TREE_UUID_CLEAR', //清空tree菜单checkbox
  TREE_UUID_CHECK_ALL: 'TREE_UUID_CHECK_ALL', //全选树菜单checkbox
  CLOSE_ADD_PEOPLE_MODAL: 'CLOSE_ADD_PEOPLE_MODAL', //关闭添加成员modal
  CLOSE_TEAM_MODAL: 'CLOSE_TEAM_MODAL', //关闭协同modal
  EDITPAGE_APPROVASTATUS: 'EDITPAGE_APPROVASTATUS', //关闭协同modal
  AIAGENT_DETAIL_DATA: 'AIAGENT_DETAIL_DATA', //AI智能体详情数据
  AIAGENT_CREATE_IMG: 'AIAGENT_CREATE_IMG', //AI智能体生成图片
  AIAGENT_TAB_TYPE: 'AIAGENT_TAB_TYPE', //智能体切换type
  AIAGENT_SUBMIT: 'AIAGENT_SUBMIT', //发布智能体前先发送一个测试草稿
  CHECKED_IDARR_STASTUS: 'CHECKED_IDARR_STASTUS', //拖拽勾选状态管理
  SHARE_DOC_SIDE_CLICK: 'SHARE_DOC_SIDE_CLICK', //分享页侧边栏点击切换文档
  GET_DOC_TREE_LIST: 'GET_DOC_TREE_LIST', //树形列表更新列表数据
}
