import React, { useEffect, useState } from 'react'
import styles from './style/share-header.module.less'
import BorderBtn from '../../components/BorderBtn'
import {
  IconClosure,
  IconDownload,
  IconErrorTip,
  IconLink,
  IconList,
  IconMoreEdits,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import { Dropdown, Menu, Message, Tooltip } from '@arco-design/web-react'
// import copy from 'copy-to-clipboard'
import Copy from '../Copy'
// import LogoSvg from '@/assets/newImgs/logo.svg'
import LogoSvg from '@/assets/logo.svg'
import { ContentType } from '@/helpers/enums'
import { GetQueryValue } from '@/utils/common'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
interface Props {
  title?: string
  comeFrom?: string
  gmtModified?: string
  foldList?: any
  onChangeSide?: (isOpenSide: boolean) => void
  docUuid?: string
  docContent?: string
  isExportPdf?: boolean
}

const ShareHeader = (props: Props) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const {
    title,
    comeFrom,
    gmtModified,
    onChangeSide,
    foldList,
    docUuid,
    docContent,
    isExportPdf,
  } = props
  const [shareUrl, setShareUrl] = useState('')
  const [isOpenSide, setIsOpenSide] = useState(true)
  const [isHasSide, setIsHasSide] = useState(false)
  useEffect(() => {
    let isShow =
      foldList &&
      foldList[0]?.children?.length > 0 &&
      foldList[0]?.fileType == ContentType.fold

    setIsHasSide(isShow)
  }, [foldList])

  useEffect(() => {
    getDoShareLinkHandle({
      url:
        window.location.origin +
        '/documentShare?uuid=' +
        GetQueryValue('uuid') +
        `${
          GetQueryValue('selectUuid')
            ? '&selectUuid=' + GetQueryValue('selectUuid')
            : ''
        }`,
    })
  }, [GetQueryValue('selectUuid')])

  const btnClickHandle = () => {
    window.open(window.location.origin + '/home', '_blank')
  }
  const copyHandle = () => {
    // Message.success({
    //   icon: <IconSuccessTip useCurrentColor={false} />,
    //   content: '复制成功',
    // })
  }
  const getDoShareLinkHandle = async (linkUuid) => {
    try {
      const response = await fetch(
        'https://cors.v-dk.com/https://go.wuz.com.cn/api/set.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(linkUuid),
        },
      )

      if (response.ok) {
        const previewData = await response.json()
        // copy(previewData.content.url)
        console.log(response)

        setShareUrl(previewData.content.url)
      } else {
        // 请求失败
        console.error('set.php 请求失败')
      }
    } catch (error) {
      console.error('set.php 请求出错', error)
    }
  }

  const changeSideHandle = (e) => {
    e.preventDefault()
    setIsOpenSide(!isOpenSide)
    onChangeSide(isOpenSide)
  }

  const exportHandle = () => {
    if (docContent.length == 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: `导出内容为空`,
      })
      return
    } else {
      const uuid = GetQueryValue('uuid')
      axios
        .post(
          `/api/api-aidb/document/htmlToPdfByShare`,
          { uuid: uuid, docUuid: docUuid },
          { responseType: 'blob' },
        )
        .then((res) => {
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.pdf`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '导出 PDF 成功',
          })
        })
        .catch((err) => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 PDF 错误`,
          })
        })
    }
  }

  const dropList = (
    <Menu className={'custom-dropdown'}>
      <div className={'dropdown-list'}>
        <Menu.Item
          key="1"
          // onClick={copyHandle}
          style={{ justifyContent: 'center' }}>
          <Copy
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            text={shareUrl}
            onCopy={() => {
              shareUrl
                ? Message.success({
                    icon: <IconSuccessTip useCurrentColor={false} />,
                    content: '复制成功',
                  })
                : Message.error({
                    icon: <IconErrorTip useCurrentColor={false} />,
                    content: '复制失败',
                  })
            }}
            content={
              <>
                复制链接
                <span style={{ marginLeft: '20px', lineHeight: '0' }}>
                  <IconLink style={{ fill: theme == 'dark' && '#fff' }} />
                </span>
              </>
            }
          />
        </Menu.Item>
        {isExportPdf ? (
          <Menu.Item
            key="2"
            onClick={exportHandle}
            style={{ justifyContent: 'center' }}>
            导出 PDF
            <span style={{ marginLeft: '20px', lineHeight: '0' }}>
              <IconDownload style={{ fill: theme == 'dark' && '#fff' }} />
            </span>
          </Menu.Item>
        ) : (
          ''
        )}
      </div>
    </Menu>
  )
  return (
    <div className={`${styles.headerBox} ${styles[theme]}`}>
      <div className={styles.logo}>
        <LogoSvg onClick={btnClickHandle} />
        {isHasSide && (
          <span className={styles.menuIcon} onClick={changeSideHandle}>
            {isOpenSide ? <IconList /> : <IconClosure />}
          </span>
        )}
      </div>
      <div className={styles.header}>
        <div className={styles.left}>
          {title && (
            <div className={styles.titleBox}>
              <Tooltip position="bottom" trigger="hover" content={title}>
                <span className={styles.title}>{title}</span>
              </Tooltip>

              <Dropdown droplist={dropList} position="bl" trigger={'click'}>
                <div
                  className={`${styles.more} ${styles.iconMore}`}
                  onClick={copyHandle}>
                  <IconMoreEdits />
                </div>
              </Dropdown>
            </div>
          )}
          {gmtModified && (
            <div className={styles.info}>
              {/* <span>来源：{comeFrom}</span> */}
              <span>最新修改：{gmtModified}</span>
            </div>
          )}
        </div>
        <div className={styles.right}>
          <BorderBtn
            width={170}
            height={44}
            gradient={true}
            text={'前往使用「悟智AI」'}
            onClick={btnClickHandle}
          />
        </div>
      </div>
    </div>
  )
}

export default ShareHeader
