import {
  DocHandleType,
  CollectType,
  KeyMapType,
  RewriteSelectType,
  SortType,
  exportType,
  ProofSelectType,
} from '@/helpers/enums'

export const MENUS = [
  {
    id: 'menu.home',
    path: '/home',
    glyph: 'menu1',
    title: '控制台',
    headerVisible: true,
    children: [],
  },
  {
    id: 'menu.intelligentDocument',
    path: '/intelligentDocument',
    glyph: 'menu2',
    title: '智能文档',
    headerVisible: true,
    children: [],
  },

  {
    id: 'menu.chatgpt',
    path: '/toolbox/index',
    glyph: 'menu3',
    title: '智能助理',
    headerVisible: true,
    children: [],
  },
  {
    id: 'menu.collect',
    path: '/collect',
    glyph: 'menu4',
    title: '收藏夹',
    headerVisible: true,
    children: [],
  },
  {
    id: 'menu.waste',
    path: '/waste',
    glyph: 'menu5',
    title: '废纸篓',
    headerVisible: true,
    children: [],
  },
]

export const CollectMenu = [
  // { title: '全部收藏', key: CollectType.all },
  // { title: '佳句摘要', key: CollectType.summary },
  // { title: '精彩范文', key: CollectType.essay },
  // { title: '政府工作报告', key: CollectType.governmentReport },
  // { title: '经典书籍', key: CollectType.classicBook },
  { title: '文档收藏', key: CollectType.doc },
  { title: '助理收藏', key: CollectType.assistant },
]

export const DocHandleMenu = [
  { title: '分享', key: DocHandleType.share },
  { title: '收藏', key: DocHandleType.addToCollect },
  { title: '移至废纸篓', key: DocHandleType.moveToWaste },
  { title: '移动', key: DocHandleType.move },
  { title: '创建副本', key: DocHandleType.createCopy },
  { title: '重命名', key: DocHandleType.rename },
  { title: '添加标签', key: DocHandleType.addTags },
]

export const SortMenu = [
  { title: '编辑时间', key: SortType.editTime },
  { title: '创建时间', key: SortType.createTime },
]

export const RewriteSelectMenu = [
  { title: '只看新增', key: RewriteSelectType.onlyAdd },
  { title: '只看删除', key: RewriteSelectType.onlyDel },
  { title: '显示全部', key: RewriteSelectType.all },
]

export const ProofSelectMenu = [
  { title: '通用校对', key: ProofSelectType.basicProof },
  { title: '公文校对', key: ProofSelectType.officialProof },
  { title: '敏感词', key: ProofSelectType.tactfulProof },
]

export const KeyMapMenu = [
  { title: '基本操作', key: KeyMapType.basic },
  { title: '高级操作', key: KeyMapType.advanced },
  { title: '段落及文本', key: KeyMapType.paraAndText },
  { title: 'Markdown', key: KeyMapType.markdown },
]

export const KeyBasic = [
  {
    title: '文本编辑',
    keyList: [
      {
        title: '保存文档',
        keyMap: ['⌘', 'S'],
      },
      {
        title: '粘贴',
        keyMap: ['⌘', 'V'],
      },
      {
        title: '纯文本粘贴',
        keyMap: ['Shift', '⌘', 'V'],
      },
    ],
  },
  {
    title: '其他',
    keyList: [
      {
        title: '发布文档',
        keyMap: ['⌘', 'P'],
      },
    ],
  },
]

export const KeyBasicWin = [
  {
    title: '文本编辑',
    keyList: [
      {
        title: '保存文档',
        keyMap: ['Ctrl', 'S'],
      },
      {
        title: '粘贴',
        keyMap: ['Ctrl', 'V'],
      },
      {
        title: '纯文本粘贴',
        keyMap: ['Shift', 'Ctrl', 'V'],
      },
    ],
  },
  {
    title: '其他',
    keyList: [
      {
        title: '发布文档',
        keyMap: ['Ctrl', 'P'],
      },
    ],
  },
]

export const KeyAdvanced = [
  {
    title: '功能调用',
    keyList: [
      // {
      //   title: '使用指令',
      //   keyMap: ['/'],
      // },
      {
        title: '快速激活、提交指令',
        keyMap: ['Shift', 'Enter'],
      },
      {
        title: '打开当前段落批注',
        keyMap: ['ctrl', 'e'],
      },
      {
        title: '打开所有批注',
        keyMap: ['ctrl', 'Shift', 'e'],
      },
    ],
  },
]

export const KeyAdvancedWin = [
  {
    title: '功能调用',
    keyList: [
      {
        title: '使用指令',
        keyMap: ['/'],
      },
      {
        title: '快速激活、提交指令',
        keyMap: ['Shift', 'Enter'],
      },
    ],
  },
]

export const KeyParaAndText = [
  {
    title: '段落样式',
    keyList: [
      {
        title: 'Aa',
        keyMap: ['⌘', 'Ctrl', '0'], //和保存重复
      },
      {
        title: 'H1 一级标题',
        keyMap: ['⌘', 'Ctrl', '1'],
      },
      {
        title: 'H2 二级标题',
        keyMap: ['⌘', 'Ctrl', '2'],
      },
      {
        title: 'H3 三级标题',
        keyMap: ['⌘', 'Ctrl', '3'],
      },
      {
        title: 'H4 四级标题',
        keyMap: ['⌘', 'Ctrl', '4'],
      },
      {
        title: 'H5 五级标题',
        keyMap: ['⌘', 'Ctrl', '5'],
      },
      {
        title: 'H6 六级标题',
        keyMap: ['⌘', 'Ctrl', '6'],
      },
      {
        title: '选中整段',
        tip: '编辑器内',
        keyMap: ['Esc'],
      },
      {
        title: '收起右侧栏',
        tip: '编辑器外',
        keyMap: ['Esc'],
      },
    ],
  },
  {
    title: '行内样式',
    keyList: [
      {
        title: '加粗',
        keyMap: ['⌘', 'B'], //和保存重复
      },
      {
        title: '斜体',
        keyMap: ['⌘', 'I'], //和保存重复
      },
      {
        title: '下划线',
        keyMap: ['⌘', 'U'], //和保存重复
      },
      // {
      //   title: '删除线',
      //   keyMap: ['⌘', 'S'], //和保存重复
      // },
      // {
      //   title: '链接',
      //   keyMap: ['⌘', 'B'],
      // },
      // {
      //   title: '行内代码',
      //   keyMap: ['⌘', 'B'],
      // },
      // {
      //   title: '对其方式',
      //   keyMap: ['⌘', 'B'],
      // },
    ],
  },
]

export const KeyParaAndTextWin = [
  {
    title: '段落样式',
    keyList: [
      {
        title: 'Aa',
        keyMap: ['Ctrl', 'alt', '0'], //和保存重复
      },
      {
        title: 'H1 一级标题',
        keyMap: ['Ctrl', 'alt', '1'],
      },
      {
        title: 'H2 二级标题',
        keyMap: ['Ctrl', 'alt', '2'],
      },
      {
        title: 'H3 三级标题',
        keyMap: ['Ctrl', 'alt', '3'],
      },
      {
        title: 'H4 四级标题',
        keyMap: ['Ctrl', 'alt', '4'],
      },
      {
        title: 'H5 五级标题',
        keyMap: ['Ctrl', 'alt', '5'],
      },
      {
        title: 'H6 六级标题',
        keyMap: ['Ctrl', 'alt', '6'],
      },
      {
        title: '选中整段',
        tip: '编辑器内',
        keyMap: ['Esc'],
      },
      {
        title: '收起右侧栏',
        tip: '编辑器外',
        keyMap: ['Esc'],
      },
    ],
  },
  {
    title: '行内样式',
    keyList: [
      {
        title: '加粗',
        keyMap: ['Ctrl', 'B'], //和保存重复
      },
      {
        title: '斜体',
        keyMap: ['Ctrl', 'I'], //和保存重复
      },
      {
        title: '下划线',
        keyMap: ['Ctrl', 'U'], //和保存重复
      },
      // {
      //   title: '删除线',
      //   keyMap: ['⌘', 'S'], //和保存重复
      // },
      // {
      //   title: '链接',
      //   keyMap: ['⌘', 'B'],
      // },
      // {
      //   title: '行内代码',
      //   keyMap: ['⌘', 'B'],
      // },
      // {
      //   title: '对其方式',
      //   keyMap: ['⌘', 'B'],
      // },
    ],
  },
]

export const KeyMarkdown = [
  {
    title: '段落样式',
    keyList: [
      {
        title: 'H1 大标题',
        keyMap: ['#', 'Space'],
      },
      {
        title: 'H2 大标题',
        keyMap: ['##', 'Space'],
      },
      {
        title: 'H3 大标题',
        keyMap: ['###', 'Space'],
      },
      {
        title: '引用',
        keyMap: ['>', 'Space'],
      },
      {
        title: '无序列表',
        keyMap: ['*', 'Space'],
      },
      {
        title: '有序列表',
        keyMap: ['1.', 'Space'],
      },
      // {
      //   title: '检查列表',
      //   keyMap: ['', 'Space'],
      // }, 不支持
      {
        title: '代码块',
        keyMap: ['```', 'Enter'],
      },
      {
        title: '分割线',
        keyMap: ['***', 'Space'],
      },
    ],
  },
  {
    title: '行内样式',
    keyList: [
      {
        title: '加粗',
        keyMap: ['**文本**', 'Space'],
      },
      {
        title: '斜体',
        keyMap: ['*文本*', 'Space'],
      },
      {
        title: '删除线',
        keyMap: ['~~文本~~', 'Space'],
      },
    ],
  },
]

export const TextStreamGPTError = [
  { text: '[TOO_MANY_REQUESTS]', message: '请勿频繁请求#00-3' },
  {
    text: '[OUT_OF_MIN_FLOW]',
    message: '1分钟内免费次数已用完，请稍后再试或开通会员',
  },
  {
    text: '[OUT_OF_DAY_FLOW]',
    message: '今日免费次数已用完，请明日再试或开通会员',
  },
  {
    text: '[OUT_OF_MIN_FLOW_VIP]',
    message: '1分钟内 VIP 次数已用完，请稍后再试#00-3',
  },
  {
    text: '[OUT_OF_DAY_FLOW_VIP]',
    message: '今日 VIP 次数已用完，请明日再试#00-4',
  },
  { text: '[ERROR]', message: '接口返回错误，请稍后再试#01-2' },
]

export const InnerTool = [
  {
    categoryTitle: '条目',
    toolList: [
      {
        title: '🍠 小红书文案',
      },
      {
        title: '💬 充当中翻英备份',
      },
      {
        title: '💬 充当英翻中备份',
      },
      {
        title: '💻 充当Linux终端备份',
      },
      {
        title: '💡充当前端智能思路助手备份',
      },
      {
        title: '💻 充当JavaScript控制台备份',
      },
    ],
  },
]

export const ExportBtn = [
  {
    key: exportType.word,
    title: '导出 Word',
    type: 'Word',
    interfaceName: 'htmlToWord',
  },
  {
    key: exportType.pdf,
    title: '导出 PDF',
    type: 'PDF',
    interfaceName: 'htmlToPdf',
  },
  {
    key: exportType.txt,
    title: '导出 TXT',
    type: 'TXT',
    interfaceName: 'htmlToTxt',
  },
  {
    key: exportType.markdown,
    title: '导出 MarkDown',
    type: 'MarkDown',
    interfaceName: 'htmlToMarkDown',
  },
  // {
  //   key: exportType.ppt,
  //   title: '导出 PPT',
  // },
  // {
  //   key: exportType.html,
  //   title: '导出 HTML',
  //   type: 'html',
  //   interfaceName: 'txtToHtml',
  // },
]
