import React, { useEffect, useRef, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import style from './index.module.less'
import Writing from '../writingTem'
import { GetQueryValue, removeEmpty, throttle } from '@/utils/common'
import { getDocumentDetail } from '@/model/documentEdit'
import { saveDocument } from '@/model/reference'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { useHistory } from 'react-router-dom'
import { ApprovalStatus, DocBtnType, DocVersionType } from '@/helpers/enums'
import { Message } from '@arco-design/web-react'
import { showModal } from '@/share/actions'
import Modal from '@/components/Modal'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
const DocumentEditPage = () => {
  const [type, setType] = useState<number>()
  const [showDocEdit, setShowDocEdit] = useState(false)
  const [defaultDocContent, setDefaultDocContent] = useState()
  const [uuid, setUuid] = useState<string>()
  const [docType, setDocType] = useState()
  const [isEditContent, setIsEditContent] = useState(false)
  const [isEditTitle, setIsEditTitle] = useState(false)
  const [editContent, setEditContent] = useState()
  const [selectContent, setSelectContent] = useState()
  const [nodeInfo, setNodeInfo] = useState()
  const [canEdit, setCanEdit] = useState(false)
  // const [docApprovalList, setDocApprovalList] = useState([])
  const [defaultContent, setDefaultContent] = useState<any>()
  const [contentCount, setContentCount] = useState()
  const [isAutoSave, setIsAutoSave] = useState(false)
  const infoRef = useRef()
  const editTitleRef = useRef()
  const contentRef = useRef()
  const editContentRef = useRef()
  const id = GetQueryValue('uuid')
  const history = useHistory()
  let timer, timerTitle
  useEffect(() => {
    getDocDetailHandle()
    // getDocApprovalListHandle()
  }, [])

  useEffect(() => {
    events.on(ACTIONS.FROM_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_REWRITE_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_TRANS_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_TOOLS_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_CHATGPT_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.ADD_EDITOR_COMMENT, editorHandle)
    events.on(ACTIONS.SAVE_PIC_EDITOR, editorSavePicHandle)

    return () => {
      events.off(ACTIONS.FROM_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_REWRITE_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_TRANS_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_TOOLS_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_CHATGPT_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.ADD_EDITOR_COMMENT, editorHandle)
      events.off(ACTIONS.SAVE_PIC_EDITOR, editorSavePicHandle)
    }
  }, [])

  useEffect(() => {
    if (type !== DocBtnType.proof || showDocEdit == false) {
      events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    }
  }, [type, showDocEdit])

  useEffect(() => {
    document.title = GetQueryValue('title')
      ? GetQueryValue('title') + ' - 悟智AI'
      : '悟智AI'
  }, [])

  useEffect(() => {
    if (!isEditTitle) return
    if (timerTitle) clearTimeout(timerTitle)
    setIsAutoSave(true)
    timerTitle = setTimeout(() => {
      setIsAutoSave(false)
      saveDocHandle(
        isEditContent ? editContent : contentRef.current,
        editTitleRef.current ? editTitleRef.current : infoRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.AutoSave,
      )
      setIsEditTitle(false)
    }, 2000)
  }, [isEditTitle])

  const editorSavePicHandle = () => {
    saveDocHandle(
      isEditContent ? editContent : defaultDocContent,
      editTitleRef.current ? editTitleRef.current : infoRef.current,
      ApprovalStatus.ToSubmit,
      false,
      null,
      DocVersionType.SilentSave,
    )
  }

  const getDocDetailHandle = async () => {
    if (!id) return
    const res = await getDocumentDetail({
      uuid: id,
    })
    if (res.data.success) {
      setDefaultDocContent(res.data.data.content)
      infoRef.current = res.data.data.title
      contentRef.current = res.data.data.content
      setDocType(res.data.data.docType)
      // if (res.data.data.approvalStatus == ApprovalStatus.Reviewed) {
      //   setShowDocEdit(true)
      //   setCanEdit(true)
      //   setType(DocBtnType.annotate)
      // }
    }
  }

  const editorHandle = (payload) => {
    if (payload.barActiveIndex) {
      setType(payload.barActiveIndex)
      setShowDocEdit(true)
      setCanEdit(true)
    }
    setSelectContent(payload.setSelectContent)
    setNodeInfo(payload.nodeInfo)
  }

  //监听改变内容
  const editContentHandle = (editContent) => {
    setEditContent(editContent)
    setIsEditContent(true)
  }

  const changeContentContent = (contentCount) => {
    setContentCount(contentCount)
  }

  //返回保存
  const goBack = (approvalStatus = ApprovalStatus.ToSubmit) => {
    saveDocHandle(
      isEditContent ? editContent : contentRef.current,
      editTitleRef.current ? editTitleRef.current : infoRef.current,
      approvalStatus,
      true,
      approvalStatus == ApprovalStatus.ToSubmit ? null : '发布文档成功',
      // null,
      approvalStatus == ApprovalStatus.ToSubmit
        ? DocVersionType.ManualSave
        : DocVersionType.PublishSave,
    )

    // console.log(approvalStatus, 'approvalStatus')
    // if (approvalStatus == ApprovalStatus.ToSubmit) {
    //   Message.error({
    //      icon: <IconErrorTip useCurrentColor={false} />,
    //     content: '请先输入内容',
    //   })
    // }
    let content = isEditContent ? editContent : contentRef.current
    let title = editTitleRef.current ? editTitleRef.current : infoRef.current
    if (
      approvalStatus == ApprovalStatus.ToSubmit &&
      !content &&
      !title &&
      !GetQueryValue('uuid')
    ) {
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
      }) as any

      history.push({
        pathname: '/intelligentDocument',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      location.reload()
    }
  }

  //保存
  const saveDocHandle = async (
    content,
    title,
    approvalStatus,
    isBack = false,
    message,
    docVersionType,
  ) => {
    if (!content && !title && !GetQueryValue('uuid')) return
    const res = await saveDocument({
      title: !!title ? title : '未命名',
      approvalStatus: approvalStatus,
      content: content ? content : '',
      docType: docType,
      filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '/',
      fileLevel: 1,
      fileType: GetQueryValue('fileType')
        ? Number(GetQueryValue('fileType'))
        : 0,
      docStatus: 0,
      uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : uuid,
      folderUuid: GetQueryValue('filePath') ? GetQueryValue('folderUuid') : '',
      docVersionType: docVersionType,
      docWords: isEditContent
        ? contentCount
        : String(content).replace(/<[^>]+>/g, '').length,
    })

    if (res.data.success && message) {
      setIsAutoSave(false)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: message,
      })
    }
    setUuid(res.data.data)
    if (!GetQueryValue('uuid')) {
      const urlQuery = removeEmpty({
        uuid: res.data.data,
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
      }) as any
      history.replace({
        pathname: '/docTemEditor',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      if (!isBack && approvalStatus != 1) {
        location.reload()
      }
    }

    if (approvalStatus == ApprovalStatus.ToSubmit && isBack) {
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
      }) as any

      history.push({
        pathname: '/intelligentDocument',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      location.reload()
    }

    // if (approvalStatus == ApprovalStatus.PendingApproval && isBack) {
    //   const urlQuery = removeEmpty({
    //     filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
    //     listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
    //     uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
    //   }) as any
    //   history.push({
    //     pathname: '/intelligentWriting',
    //     search: '?' + new URLSearchParams(urlQuery).toString(),
    //   })
    // }
  }

  //标题自动保存 监听标题改变
  const editTitleHandle = (title) => {
    editTitleRef.current = title
    setIsEditTitle(true)
  }

  //内容自动保存
  const changeContentHandle = (content) => {
    editContentRef.current = content
    setIsAutoSave(true)
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      console.log('pppp')
      setIsAutoSave(false)
      saveDocHandle(
        content,
        editTitleRef.current ? editTitleRef.current : infoRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.AutoSave,
      )
    }, 2000)
  }

  //提交保存
  const submitDocHandle = (approvalStatus) => {
    if (!GetQueryValue('uuid')) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '该文档不存在',
      })
      return
    }
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    const content = isEditContent ? editContent : contentRef.current
    const title = editTitleRef.current ? editTitleRef.current : infoRef.current

    if (!content && !title) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请先输入内容',
      })
      return
    }
    if (approvalStatus == ApprovalStatus.PendingApproval) {
      showModal('ConfirmModal', {
        title: '  发布文档',
        content: `确认是否发布文档？`,
        onConfirm: async () => {
          goBack(approvalStatus)
        },
      })
    } else {
      setIsAutoSave(true)
      saveDocHandle(
        isEditContent ? editContent : defaultDocContent,
        editTitleRef.current ? editTitleRef.current : infoRef.current,
        approvalStatus,
        false,
        '保存成功',
        DocVersionType.ManualSave,
      )
    }
  }

  const closeEditBoxHandle = () => {
    setShowDocEdit(false)
  }

  const getContentDocHandle = (contentStructure) => {
    const contentArr = contentStructure.doc.content
    const textArray = []
    let nodeIndex = -1
    // 解析数据并获取text属性值
    console.log(contentArr, '')
    contentArr.forEach((obj, index) => {
      if (
        (obj.type === 'paragraph' || obj.type === 'header') &&
        !!obj.content
      ) {
        let text = ''
        obj.content.forEach((contentObj) => {
          if (contentObj.type === 'text') {
            text = text + contentObj.text
            nodeIndex += 1
          }
        })
        textArray.push({ text: text, nodeIndex: nodeIndex })
      } else if (obj.type === 'ordered_list') {
        let text = ''
        obj.content.forEach((listItem) => {
          listItem.content.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
              // nodeIndex += 1
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (obj.type === 'bullet_list') {
        let text = ''
        nodeIndex += 1
        obj.content.forEach((listItem) => {
          listItem.content.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (!obj.content) {
        nodeIndex += 1
      }
    })
    setDefaultContent(textArray)
  }

  const keyMap = {
    SAVE_RELEASE: ['command+p', 'ctrl+p'],
    SHOW_KEY_MAP: ['command+h', 'ctrl+h'],
    SAVE_DOC: ['command+s', 'ctrl+s'],
    UNDO: ['command+z', 'ctrl+z'],
    REDO: ['command+shift+z', 'ctrl+shift+z'],
  }
  const saveKeyHandle = (e) => {
    e.preventDefault()
    if (id) {
      events.emit(ACTIONS.MOD_S_SAVE)
    }
  }

  const showKeyMapHandle = (e) => {
    e.preventDefault()
    showModal('KeyMapModal', {
      title: '快捷键',
    })
  }

  const undoKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_Z_UNDO)
  }

  const redoKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_Z_REDO)
  }

  const releaseKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_P_SAVE)
  }

  const handlers = {
    SAVE_RELEASE: throttle(releaseKeyHandle, 5000),
    SHOW_KEY_MAP: showKeyMapHandle,
    UNDO: undoKeyHandle,
    REDO: redoKeyHandle,
    SAVE_DOC: throttle(saveKeyHandle, 5000),
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <div className={style['workspace-layout']}>
        <div
          className={`${style['documentsEdit-contain']} ${
            !showDocEdit ? style['onlyDoc'] : ''
          }`}>
          <div
            className={`${style['doc-left']} ${
              showDocEdit && style['doc-showEdit-left']
            }`}>
            <Writing
              docTitle={infoRef.current}
              docContent={defaultDocContent}
              editTitle={editTitleHandle}
              editContent={editContentHandle}
              changeContent={changeContentHandle}
              showDocEdit={showDocEdit}
              onSubmit={submitDocHandle}
              getContentDoc={getContentDocHandle}
              changeContentContent={changeContentContent}
              isAutoSave={isAutoSave}
            />
          </div>
        </div>
        <Modal></Modal>
      </div>
    </GlobalHotKeys>
  )
}

export default DocumentEditPage
