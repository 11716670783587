import { switchProject } from '@/helpers/switchProject'

/** AI写作 */
const REFERENCE_BASE = switchProject('DFZ') ? '/api/api-core' : '/api/api-aidb'

/* 获取文档列表 */
export const API_POST_DOCUMENT_LIST = `${REFERENCE_BASE}/document/getDocumentList`

/* 文档移到废纸篓 */
export const API_POST_REMOVE_DOCUMENT = `${REFERENCE_BASE}/document/scrapDocument`

/* 重命名文档 */
export const API_POST_RENAME_DOCUMENT = `${REFERENCE_BASE}/document/rename`

/* 新建文档 */
export const API_POST_SAVE_DOCUMENT = `${REFERENCE_BASE}/document/save`

/* 新建文档协同接口 */
export const API_POST_SAVE_SYNERGIA = `${REFERENCE_BASE}/synergia/save`

/* 移动文件夹 */
export const API_POST_MOVE_DOCUMENT = `${REFERENCE_BASE}/document/move`

/* 获取文档详情页 */
export const API_POST_GET_DOCUMENT_DETAIL = `${REFERENCE_BASE}/document/getDocumentDetail`

/* 获取学生列表 */
export const API_POST_GET_DOCUMENT_GETSTULIST = `${REFERENCE_BASE}/document/getStuList`

/* 获取学生文档列表 */
export const API_POST_GET_DOCUMENT_GETSTUPOSTLIST = `${REFERENCE_BASE}/document/getStuPostList`

/* 获取废纸篓列表 */
export const API_POST_GETWASTEBASKETLIST = `${REFERENCE_BASE}/document/getWasteBasketList`

/* 清空废纸篓 */
export const API_POST_DOCUMENT_EMPTY = `${REFERENCE_BASE}/document/empty`

/* 删除废纸篓文档 */
export const API_POST_CLEAR_DOCUMENT = `${REFERENCE_BASE}/document/delete`

/* 还原文档 */
export const API_POST_RETURN_DOCUMENT = `${REFERENCE_BASE}/document/scrapDocument`

/* 文件夹路径 */
export const API_POST_FOLD_LIST = `${REFERENCE_BASE}/document/getFolderList`

/* 文档生成副本 */
export const API_POST_MAKE_COPY_DOCUMENT = `${REFERENCE_BASE}/document/makeCopyDocument`

/* 审批文档*/
export const API_POST_APPROVALDOCUMENT = `${REFERENCE_BASE}/document/approvalDocument`

/* 获取审批文档 */
export const API_POST_GET_DOC_APPROVAL_LIST = `${REFERENCE_BASE}/document/getDocumentApprovalList`

/* 搜索AI写作文档列表 */
export const API_POST_SEARCH_DOCUMENT_LIST = `${REFERENCE_BASE}/document/searchDocumentList`

/* 保存文档批注 */
export const API_POST_SAVE_COMMENT = `${REFERENCE_BASE}/document/saveComment`

/* 获取批注列表 */
export const API_POST_GET_COMMENT_LIST = `${REFERENCE_BASE}/document/getDocumentCommentListPage`

/* 删除批注 */
export const API_POST_DELETE_COMMENT_LIST = `${REFERENCE_BASE}/document/deleteComment`

/* 编辑批注 */
export const API_POST_EDIT_COMMENT_LIST = `${REFERENCE_BASE}/document/editComment`

/* 批注已读 */
export const API_POST_SET_COMMENT_READ = `${REFERENCE_BASE}/document/setCommentIsRead`

/* 老师保存文档 */
export const API_POST_SAVE_BY_TEACHER = `${REFERENCE_BASE}/document/saveByTeacher`

/* 获取文档某个段落的批注列表 */
export const API_POST_DOC_COMMENT_LIST_BY_PARAGRAPH = `${REFERENCE_BASE}/document/getDocumentCommentListByParagraph`

/* 获取历史版本 */
export const API_POST_DOC_VERSION_LIST = `${REFERENCE_BASE}/document/getDocumentVersionList`

/* 上传图片 */
export const API_POST_UPLOAD_FILE = `${REFERENCE_BASE}/upload/uploadFile`

/* 文本翻译 */
export const API_POST_TRANSLATE = `${REFERENCE_BASE}/language_generation/textTrans`

/* 文本改写 */
export const API_POST_TEXT_REWRITING = `${REFERENCE_BASE}/xf/textRewriting`

/* 公文校对 */
export const API_POST_OFFICIAL_PROOF_READING = `${REFERENCE_BASE}/xf/officialProofreading`

/* 公文校对 */
export const API_POST_TEXT_MODERATION = `${REFERENCE_BASE}/document/textModeration`

/* 文本纠错 */
export const API_POST_TEXT_CORRECTION = `${REFERENCE_BASE}/language_understanding/textCorrection`

/* 工具，关键词提取 */
export const API_POST_KEY_WORDS = `${REFERENCE_BASE}/language_understanding/txtKeywordsExtraction`

/* 工具，文本摘要 */
export const API_POST_TEXT_ABSTRACT = `${REFERENCE_BASE}/language_generation/newsSummary`

/* 工具，文章标签 */
export const API_POST_TEXT_KEY = `${REFERENCE_BASE}/language_generation/keyword`

/* 工具，标题推荐 */
export const API_POST_TITLE_KEY = `${REFERENCE_BASE}/language_generation/titlepredictor`

/* 工具，反向词典 */
export const API_POST_REVERES = `${REFERENCE_BASE}/tsing/wantWordsZh`

/* 工具，据意查句 */
export const API_POST_SEARCH_SENTENCE = `${REFERENCE_BASE}/tsing/authSearch`

/* 工具，导出word */
export const API_POST_HTMLWORD = `${REFERENCE_BASE}/document/htmlToWord`

/* 工具，导出报告 */
export const API_POST_WRITING_REPORT = `${REFERENCE_BASE}/tsing/writeWarning`

/* 工具，情感分析 */
export const API_POST_SENTIMENT_CLASSIFY = `${REFERENCE_BASE}/language_understanding/sentimentClassify`

/* 工具，文档属性 */
export const API_POST_GET_DOCUMENT_PROPERTY = `${REFERENCE_BASE}/document/getDocumentProperty`

/* 获取用户个人信息 */
export const API_POST_GET_USERINFO = '/api/api-permission/employee/getUserInfo'

/* 修改个人信息 */
export const API_POST_EDIT_USERINFO =
  '/api/api-permission/employee/modifyUserInfo'

/* 修改密码 */
export const API_POST_EDIT_PASSWORD = '/api/api-sso/login/resetPwd'

/* 发送邮箱验证码 */
export const API_POST_SEND_EMAIL = '/api/api-sso/login/sendEmail'

/* 智能推荐文档 */
export const API_POST_RECOMMENDLIST = `${REFERENCE_BASE}/document/getRecommendDocumentList`

/* 收藏文档 */
export const API_POST_DOCUMENT_COLLECT = `${REFERENCE_BASE}/document/documentCollect`

/* 取消收藏文档 */
export const API_POST_CANCEL_DOCUMENT_COLLECT = `${REFERENCE_BASE}/document/cancelDocumentCollect`

/* 获取收藏列表文档 */
export const API_POST_DOCUMENT_COLLECT_LIST = `${REFERENCE_BASE}/document/getDocumentCollectListPage`

/* 搜索收藏列表文档 */
export const API_POST_SEARCH_COLLECT_LIST = `${REFERENCE_BASE}/document/getDocumentCollectSearchListPage`

/* 获取文档分享链接 */
export const API_POST_DOCUMENT_SHARE_LINK = `${REFERENCE_BASE}/document/getDocumentShareLink`

/* 打开文档分享 */
export const API_POST_OPEN_DOCUMENT_SHARE = `${REFERENCE_BASE}/document/openDocumentShare`

/* 刷新文档分享链接 */
export const API_POST_REFRESH_DOCUMENT_SHARE_LINK = `${REFERENCE_BASE}/document/refreshDocumentShareLink`

/* 关闭文档分享 */
export const API_POST_CLOSE_DOCUMENT_SHARE = `${REFERENCE_BASE}/document/closeDocumentShare`

/* 根据文档分享链接获取内容 */
export const API_POST_GET_DOCUMENT_BY_SHARE = `${REFERENCE_BASE}/document/getDocumentByShareUUID`

/* 根据文档分享链接获取内容 */
export const API_POST_GET_GPTDATA_BY_MESSAGE = `${REFERENCE_BASE}/chatGPT/getChatGptDataByMessageID`

/* 获取标签列表 */
export const API_POST_GET_DOCUMENT_TAGLIST = `${REFERENCE_BASE}/document/getDocumentTagList`

/* 新建文档标签*/
export const API_POST_ADD_DOC_TAG = `${REFERENCE_BASE}/document/addTag`

/* 保存 */
export const API_POST_SAVE_TAG = `${REFERENCE_BASE}/document/saveTag`

/* 修改标签 */
export const API_POST_EDIT_TAG = `${REFERENCE_BASE}/document/editTag`

/* 删除标签 */
export const API_POST_DELETE_TAG = `${REFERENCE_BASE}/document/deleteTag`

/* 获取收藏列表标签 */
export const API_POST_COLLECT_LIST_TAG = `${REFERENCE_BASE}/document/getCollectDocumentTagList`

/* 获取聊天记录收藏列表 */
export const API_POST_CHAT_LIST_COLLECT = `${REFERENCE_BASE}/chatGPT/chatCollectQueryPage`

/* 取消收藏聊天记录 */
export const API_POST_CANCEL_CHAT = `${REFERENCE_BASE}/chatGPT/cancelChatCollect`

/* 文本处理GPT */
export const API_POST_TEXT_STREAM_GPT = `${REFERENCE_BASE}/chatGPT/textStreamGPT`
// export const API_POST_TEXT_STREAM_GPT = `${REFERENCE_BASE}/chatGPT/streamMessage'

/* 记录type4chatgpt接口 */
export const API_POST_CHATGPT_SAVE = `${REFERENCE_BASE}/chatGPT/chatSave`

/* 发布文档 */
export const API_POST_PUBLISH_DOCUMENT = `${REFERENCE_BASE}/document/publishDocument`

/* 手机号发送验证码 */
export const API_POST_SEND_SMS_CODE = '/api/api-sso/login/sendSMSCode'

/* 修改邮箱 */
export const API_POST_MODIFY_EMAIL = '/api/api-sso/login/modifyEmail'

/* 修改手机号 */
export const API_POST_MODIFY_PHONE = '/api/api-sso/login/modifyPhone'

/* 手机号注册 */
export const API_POST_REGISTER_PHONE = '/api/api-sso/login/registerByPhone'

/* 邮箱注册 */
export const API_POST_REGISTER_EMAIL = '/api/api-sso/login/register'

/* 手机号登录 */
export const API_POST_PHONE_LOGIN = '/api/api-sso/login/loginSmsCode'

/* 邮箱登录 */
export const API_POST_EMAIL_LOGIN = '/api/api-sso/login/pcLogin'

/* 获取登陆二维码 */
export const API_POST_CREATE_QRCODE = '/api/api-sso/wx/createQrcodeByTicket'

/* 轮询查二维码是否失效 */
export const API_POST_QUERY_QRCODE = '/api/api-sso/wx/queryPollQrcode'

/* 轮询查用户登陆状态 */
export const API_POST_QUERY_QRCODE_LOGIN =
  '/api/api-sso/wx/queryPollWxUserLogin'

/* 绑定微信手机号 */
export const API_POST_WX_BIND_PHONE = '/api/api-sso/wx/bindPhone'

/* 跳过绑定微信手机号 */
export const API_POST_WX_JUMP_TO = '/api/api-sso/wx/jump'

/* 微信手机号解除绑定 */
export const API_POST_WX_UNBIND = '/api/api-sso/wx/unbind'

/* 阿里云人机校验证码 */
export const API_POST_ALIYUN_SMSCODRE = '/api/api-sso/login/aliSmsCode'

/* ai绘图提交 */
export const API_POST_SUBMIT_AIIMG = `${REFERENCE_BASE}/submit/imagine`

/* ai绘图根据id查询结果 */
export const API_POST_AIIMG_FETCH_ID = `${REFERENCE_BASE}/submit/fetch/{id}`

/* ai绘图重新生成大图U1V1 */
export const API_POST_AIIMG_SIMPLE_CHANGE = `${REFERENCE_BASE}/submit/simpleChange`

/* ai绘图重新生成图片 */
export const API_POST_AIIMG_AGAIN = `${REFERENCE_BASE}/submit/change`

/* 收藏ai绘图 */
export const API_POST_AIIMG_COOLECT = `${REFERENCE_BASE}/submit/imageCollect`

/* 取消收藏ai绘图 */
export const API_POST_AIIMG_CANCEL_COOLECT = `${REFERENCE_BASE}/submit/cancelImageCollect`

/* 查询ai绘图收藏列表 */
export const API_POST_AIIMG_LISTPAGE = `${REFERENCE_BASE}/submit/imageCollectQueryPage`

/* 卡密验证码 */
export const API_POST_AIIMG_CARMI = `${REFERENCE_BASE}/pay/payForCard`

/* 查询ai图生图 */
export const API_POST_AIIMG_SUBMIT_BLEND = `${REFERENCE_BASE}/submit/blend`

/* 查询ai图生图 */
export const API_POST_AIIMG_SUBMIT_DESCRIBE = `${REFERENCE_BASE}/submit/describe`

/* 获取用户token（小程序） */
export const API_POST_GET_USER_TOKEN = '/api/api-sso/login/getUserToken'

/* 获取用户手机号（小程序） */
export const API_POST_GET_USER_PHONE = '/api/api-sso/login/getUserPhone'

/* 个人中心绑定微信 */
export const API_POST_BIND_USER_WX = '/api/api-sso/wx/bind'

/* 第三方使用网站应用授权登录链接 */
export const API_POST_WX_OAUTH_LINK = '/api/api-sso/wx/wxOauthLink'

/* 生成邀请二维码 */
export const API_POST_LOGIN_INVITATION_QRCODE =
  '/api/api-sso/login/getInvitationQrcode'

/* 重新生成SVZ */
export const API_POST_AIIMG_ACTION = `${REFERENCE_BASE}/submit/action`

/* 更换封面 */
export const API_POST_DOCUMENT_REPLACECOVER = `${REFERENCE_BASE}/document/replaceCover`

/* 另存为自定义模版 */
export const API_POST_SAVE_DOC_TEM = `${REFERENCE_BASE}/document/saveAsDocumentTemplate`

/* 获取自定义模版列表 */
export const API_POST_GET_MY_TEM_LIST = `${REFERENCE_BASE}/document/getMyTemplateList`

/* 获取自定义文档列表 */
export const API_POST_DOC_LIST_BY_TREE = `${REFERENCE_BASE}/document/getDocumentListByTree`

/* 移动文档 */
export const API_POST_DOC_MOVE = `${REFERENCE_BASE}/document/move`

/* 移动文档排序 */
export const API_POST_DOC_MOVE_SORT = `${REFERENCE_BASE}/document/moveSort`

/* 记录用户来源 */
export const API_POST_USER_SOURCE = `${REFERENCE_BASE}/document/sourceOfUser`

/* 模版说明列表 */
export const API_POST_GET_INSTRUCTION_TEMPLATE_LIST = `${REFERENCE_BASE}/instruction/getInstructionTemplateList`

/* 删除模版说明列表 */
export const API_POST_INSTRUCTION_DELETE = `${REFERENCE_BASE}/instruction/delete`

/* 根据段落className和文档uuid获取段落模板说明列表 */
export const API_POST_GET_INSTRUCTION_TEMPLATE_VALUE_LIST = `${REFERENCE_BASE}/instruction/getTemplateValueList`

/* 保存段落模版 */
export const API_POST_INSTRUCTION_SAVE = `${REFERENCE_BASE}/instruction/save`

/* 编辑模版 */
export const API_POST_INSTRUCTION_UPDATE = `${REFERENCE_BASE}/instruction/update`
/* 所有智能体列表 */
export const API_GET_ALL_AGENT = `${REFERENCE_BASE}/agent/showAllAgent`

/* 新建智能体 */
export const API_POST_CREATE_AGENT = `${REFERENCE_BASE}/agent/generateAgentTemplate`

/* 保存智能体草稿 */
export const API_POST_SAVE_AGENT_DRAFT = `${REFERENCE_BASE}/agent/updateAgentDraft`

/* 我的智能体列表 */
export const API_POST_MY_AGENT_LIST = `${REFERENCE_BASE}/agent/showMyAgentList?timestamp=${new Date().getTime()}`

/* 删除智能体 */
export const API_POST_DELETE_AGENT = `${REFERENCE_BASE}/agent/deleteAgent`

/* 查看智能体详情 */
export const API_POST_AGENT_DETAIL = `${REFERENCE_BASE}/agent/showAgentDetail`

/* 查看智能体类型列表 */
export const API_POST_AGENT_SHOW_TYPE_LIST = `${REFERENCE_BASE}/agent/showAllAgentTypeList`

/* 发布智能体 */
export const API_POST_AGENT_PLUBLISH = `${REFERENCE_BASE}/agent/publishAgent`

/* 获取分享协同文档列表 */
export const API_POST_GET_TEAM_LIST = `${REFERENCE_BASE}/synergia/getSynergiaDocumentList`

/* 协同管理项目成员 */
export const API_POST_ADD_TEAM_PEOPLE = `${REFERENCE_BASE}/synergia/addSynergiaEmployee`

/* 获取协同管理成员列表 */
export const API_POST_GET_SYNERGIA_LIST = `${REFERENCE_BASE}/synergia/getSynergiaEmployeeList`

/* 修改协同管理成员权限 */
export const API_POST_EDIT_TEAM_PEOPLE_AUTHORITY = `${REFERENCE_BASE}/synergia/modifySynergiaEmployeePermission`

/* 取消协同人员 */
export const API_POST_CANCEL_TEAM_PEOPLE = `${REFERENCE_BASE}/synergia/cancelSynergia`

/* 获取协同管理分享链接 */
export const API_POST_SYNERGIA_SHARE_LINK = `${REFERENCE_BASE}/synergia/getSynergiaShareLink`

/* 刷新协同管理分享链接 */
export const API_POST_REFRESH_SHARE_LINK = `${REFERENCE_BASE}/synergia/refreshSynergiaShareLink`

/* 根据分享链接获取协同管理内容 */
export const API_POST_GET_PROJECT_CONTENT = `${REFERENCE_BASE}/synergia/getSynergiaProjectByShareUUID`

/* 重命名共享文档 */
export const API_POST_GET_TEAM_RENAME_DOC = `${REFERENCE_BASE}/synergia/rename`

/* 搜索共享文档 */
export const API_POST_GET_SEARCH_TEAM_LIST = `${REFERENCE_BASE}/synergia/searchSynergiaDocumentList`

/* 根据文档uuid获取当前共享文件夹列表 */
export const API_POST_GET_TEAM_FOLDER_LIST_BY_UUID = `${REFERENCE_BASE}/synergia/getSynergiaFolderListByUUID`

/* 获取分享协同文档列表-树形结构 */
export const API_POST_GET_TEAM_TREE_LIST = `${REFERENCE_BASE}/synergia/getSynergiaDocumentListByTree`

/* 更换共享文档封面 */
export const API_POST_TEAM_DOCUMENT_REPLACECOVER = `${REFERENCE_BASE}/synergia/replaceCover`

/* 移动共享文件夹 */
export const API_POST_TEAM_MOVE_DOCUMENT = `${REFERENCE_BASE}/synergia/move`

/* 获取智能文档文件夹列表 */
export const API_POST_DOC_FOLDER_LIST = `${REFERENCE_BASE}/document/getFolderList`

/* 取消发布文档 */
export const API_POST_CANCEL_DOC_PUBLISH = `${REFERENCE_BASE}/document/cancelPublish`

/* 生成智能体头像 */
export const API_POST_AGENT_AVTAR = `${REFERENCE_BASE}/agent/generateAgentImage`

/* 生成智能体内容/保存智能体草稿 */
export const API_POST_CREATE_AGENT_DARFT = `${REFERENCE_BASE}/agent/createAgentContent`

/* 获取我的智能体收藏列表 */
export const API_POST_GET_MY_COLLECT_AGENT = `${REFERENCE_BASE}/agent/getCollectAgentList`

/* 收藏智能体 */
export const API_POST_COLLECT_AGENT = `${REFERENCE_BASE}/agent/collectAgent`

/* 取消收藏智能体 */
export const API_POST_CANCEL_COLLECT_AGENT = `${REFERENCE_BASE}/agent/removeCollectAgent`

/* 获取被协同分享的成员状态(查看是否有更新) */
export const API_POST_GET_TEAM_PEOPLE_STATUS_DOC = `${REFERENCE_BASE}/synergia/getEmployeeStatusBeingSynergiaed`

/* 删除被协同分享的成员状态(查看是否有更新) */
export const API_POST_DELETE_TEAM_PEOPLE_STATUS_DOC = `${REFERENCE_BASE}/synergia/removeEmployeeStatusBeingSynergiaed`

/* 提交编辑器修改记录 */
export const ADD_DOCUMENT_MODIFY_RECORD = `${REFERENCE_BASE}/document/addDocumentModifyRecord`

/* 获取编辑器修改记录 */
export const PAGE_QUERY_ADD_DOCUMENT_MODIFY_RECORD = `${REFERENCE_BASE}/document/pageQueryDocumentModifyRecord`

/* 查询文档贡献度 */
export const QUERY_DOCUMENT_CONTRIBUTION = `${REFERENCE_BASE}/document/queryDocumentContribution`

/* 查询文档贡献度 */
export const QUERY_FOLDER_CONTRIBUTION = `${REFERENCE_BASE}/document/queryFolderContribution`

/* 查询文档贡献度 */
export const GET_FOLDER_PROPERTY = `${REFERENCE_BASE}/document/getFolderProperty`