import React from 'react'
import style from './style/headerMod.module.less'
interface Props {
  docTitle?: string
  onSaveTitle: (title: string) => void
}

const HeaderMod = (props: Props) => {
  const { docTitle, onSaveTitle } = props
  const changeTitle = (e) => {
    onSaveTitle(e.target.value)
  }
  return (
    <div className={style.content}>
      <input
        defaultValue={docTitle}
        type="text"
        placeholder="请输入标题"
        onChange={changeTitle}
        style={{
          width: '100%',
          backgroundColor: 'white',
          fontSize: '20px',
          fontWeight: 'bold',
          border: 'none',
          padding: '10px 10px',
          cursor: 'inherit',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}></input>
    </div>
  )
}

export default HeaderMod
