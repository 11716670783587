import React from 'react'
import styles from './style/prompt-down-select.module.less'
import PromptTitle from './PromptTitle'
import { Select, Message } from '@arco-design/web-react'
const Option = Select.Option

function PromptDownSelect(props) {
  const options = props.options
  const [value, setValue] = React.useState(30)
  function del() {
    if (props.del) {
      props.del()
    }
  }
  return (
    <div>
      <PromptTitle
        title={props.title}
        tooltip={props.tooltip}
        showDel={props.showDel}
        showPlus={props.showPlus}
        del={() => {
          del()
        }}
        showMinus={props.showMinus}
      />
      <div className={styles.select}>
        <Select
          allowClear
          onChange={(value) => {
            props.onInputChange(value)
          }}
          dropdownMenuClassName={'g-select-menu'}
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }>
          {options.map((option, index) => (
            <Option key={option.title} value={option.title}>
              {option.title}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default PromptDownSelect
