import React, { useEffect, useRef, useState } from 'react'
import styles from './style/side-mod.module.less'
import IconFile from '../../assets/imgs/doc-list-i.svg'
import IconFolder from '../../assets/imgs/folder-small.svg'
import LogoSvg from '@/assets/newImgs/logo.svg'
import FolderIcon from '@/assets/newImgs/folder-small.svg'
import DocIcon from '@/assets/newImgs/doc-list-i.svg'
import {
  IconArrowDown,
  IconDoubleArrowLeft,
  IconDoubleArrowRight,
} from '@arco-iconbox/react-aidb-v2'
import { Transition } from 'react-transition-group'
import {
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
} from '../Animate/toggle'
import { Tooltip } from '@arco-design/web-react'
import { useHistory } from 'react-router-dom'
import { GetQueryValue } from '@/utils/common'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

interface Props {
  foldList: any
  currentId?: string
  onItemClick?: (item: any) => void
  toggleSide?: (close: boolean) => void
  isShowSide?: (show?: boolean) => void
}

const SideMod = (props: Props) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const history = useHistory()
  const { foldList, onItemClick, currentId, toggleSide, isShowSide } = props
  const [mulu, setMulu] = useState(props.foldList)
  const [curMulu, setCurMulu] = useState(currentId)

  useEffect(() => {
    setMulu(props.foldList)
    // let isShow = mulu[0]?.children?.length > 0
    // isShowSide(isShow)
  }, [props.foldList])

  useEffect(() => {
    const updatedMulu = setDocumentAndParentsShow(foldList, currentId)
    setMulu(updatedMulu)
  }, [])

  const setDocumentAndParentsShow = (data, currentId) => {
    let hasMatch = false
    const updatedMulu = data.map((item) => {
      if (item.uuid === currentId) {
        hasMatch = true
        return { ...item, show: true }
      }
      if (item.children && item.children.length > 0) {
        const updatedChildren = setDocumentAndParentsShow(
          item.children,
          currentId,
        )
        if (updatedChildren.some((child) => child.show)) {
          hasMatch = true
          return { ...item, show: true, children: updatedChildren }
        }
      }
      return item
    })
    return hasMatch
      ? updatedMulu.map((item) => ({ ...item, show: item.show || false }))
      : updatedMulu
  }

  useEffect(() => {
    events.on(ACTIONS.SHARE_DOC_SIDE_CLICK, clickTitle)
    return () => {
      events.off(ACTIONS.SHARE_DOC_SIDE_CLICK, clickTitle)
    }
  }, [])

  const clickTitle = (item) => {
    event.preventDefault() // 防止默认的导航行为
    if (item.children && item.children.length > 0) {
      //折叠
      item.show = !item.show
      setMulu([...mulu])
    } else {
      //选中
      onItemClick(item)
      if (item.fileType != 1) {
        // 选中当前active
        setCurMulu(item.uuid)
        const urlQuery = {
          uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
          selectUuid: item.uuid,
          editorScrollPosition: 0,
        } as any
        history.push({
          pathname: '/documentShare',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })
        // location.reload()
      }
    }
  }

  const getTree = (data) => {
    return data.map((item, index) => {
      return (
        <div key={index} className={styles.item}>
          <div
            className={`
                    ${curMulu === item.uuid ? styles.active : ''} 
                    ${styles.title}
                    ${
                      (!item.children && item.fileType == 0) ||
                      (item.children?.length === 0 && item.fileType == 0)
                        ? styles.leaf
                        : ''
                    }
                    `}
            style={{
              cursor: `${
                item.fileType == 1 && !item.children ? 'not-allowed' : 'pointer'
              }`,
            }}
            onClick={() => {
              clickTitle(item)
            }}>
            <div className={`${item.show ? styles.show : ''} ${styles.arrow} `}>
              {item.children && item.children.length > 0 ? (
                <IconArrowDown />
              ) : (
                <IconArrowDown style={{ opacity: 0, display: 'none' }} />
              )}
            </div>
            <div
              className={`${styles.left} ${
                styles[
                  item.fileType === 1
                    ? item.children && item.children.length > 0
                      ? ''
                      : 'leftIconEmpty'
                    : ''
                ]
              } `}>
              {item.fileType == 1 ? (
                <Tooltip
                  className={'custom-tooltip'}
                  position="right"
                  trigger="hover"
                  content={item.title}>
                  <div className={styles['leftA']}>
                    <div className={styles.icon}>
                      {item.fileType == 1 ? <FolderIcon /> : <DocIcon />}
                    </div>
                    <div className={styles.text}>{item.title}</div>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  className={'custom-tooltip'}
                  position="right"
                  trigger="hover"
                  content={item.title}>
                  <a
                    href={`/documentShare?uuid=${GetQueryValue(
                      'uuid',
                    )}&selectUuid=${item.uuid}`}
                    className={styles['leftA']}>
                    <div className={styles.icon}>
                      {item.fileType == 1 ? <FolderIcon /> : <DocIcon />}
                    </div>
                    <div className={styles.text}>{item.title}</div>
                  </a>
                </Tooltip>
              )}
            </div>
          </div>

          {item.children && item.children.length > 0 ? (
            <Transition
              in={item.show}
              unmountOnExit
              onEnter={onEnter}
              onEntering={onEntering}
              onEntered={onEntered}
              onExit={onExit}
              onExiting={onExiting}
              onExited={onExited}
              style={{ overflow: 'initial' }}>
              <div
                className={styles.children_wrap}
                style={{ overflow: 'initial' }}>
                <div
                  className={`${styles['children' + index]} ${styles.children}`}
                  style={{ overflow: 'initial' }}>
                  {getTree(item.children)}
                </div>
              </div>
            </Transition>
          ) : null}
        </div>
      )
    })
  }

  const [close, setClose] = React.useState(
    localStorage.getItem('leftIsopen') == 'true',
  )
  function toggle() {
    const newClose = !close
    setClose(newClose)
    toggleSide(newClose)
    localStorage.setItem('leftIsopen', JSON.stringify(newClose))
  }

  const btnClickHandle = () => {
    window.open(window.location.origin + '/home', '_blank')
  }

  return (
    <div className={`${styles.sideMod} ${styles[theme]}`}>
      {/* <div className={styles.logo} onClick={btnClickHandle}>
        <LogoSvg />
      </div> */}
      {mulu[0]?.children?.length > 0 && (
        <React.Fragment>
          <Tooltip
            className={'custom-tooltip'}
            position="bottom"
            trigger="hover"
            content={close ? '展开左侧栏' : '收起左侧栏'}>
            <div
              className={`${close ? styles.close : ''} ${styles.toggle}`}
              onClick={() => {
                toggle()
              }}>
              {close && <IconDoubleArrowRight />}
              {!close && <IconDoubleArrowLeft />}
            </div>
          </Tooltip>
          <div className={`${close ? styles.close : ''} ${styles.mulu}`}>
            {getTree(mulu)}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default SideMod
