import request from '@/utils/request'
import React, { useState, useEffect } from 'react'
import { Message } from '@arco-design/web-react'
import wx from 'weixin-js-sdk'

declare let WeixinJSBridge: any
declare let document: any

const WxJSAPICallback = () => {
  const ua = navigator.userAgent.toLowerCase()
  const [text, setText] = useState('正在拉起微信支付...')

  useEffect(() => {
    getENVIR()
  }, [])
  // 判断当前环境——>这里是代码入口
  const getENVIR = () => {
    const isWeixin = ua.indexOf('micromessenger') !== -1
    const isInApp = /(^|;\s)app\//.test(ua)

    if (isWeixin) {
      if ((window as any).__wxjs_environment === 'miniprogram') {
        // 微信小程序
        wxGoPay()
      } else {
        // 微信浏览器
        goPay()
      }
    } else {
      if (!isInApp) {
        // browser
        goPay()
      } else {
        // app
        goPay()
      }
    }
  }

  // 生成随机字符串
  function randomString(len) {
    len = len || 32
    const $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    const maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd.toString()
  }

  //从1970年开始的毫秒数然后截取10位变成 从1970年开始的秒数
  function timest() {
    let tmp = Date.parse(new Date().toString()).toString()
    tmp = tmp.substr(0, 10)
    return tmp
  }

  // 获取openId
  const getOpenId = () => {
    return new Promise((resolve, reject) => {
      window.location.href.toString().split('?') &&
        window.location.href
          .toString()
          .split('?')
          .forEach((item) => {
            if (item.toString().indexOf('openid') != -1) {
              resolve(item.split('=')[1])
            }
          })
    })
  }

  // 获取cookie
  // const getCookie = (cname) => {
  //   const name = cname + "=";
  //   const ca = document.cookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     const c = ca[i].trim();
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  // }

  // 获取所有的cookie
  const getAllCookie = () => {
    const allstr = document.cookie

    return allstr
  }

  // 删除订单
  function delList(id, remInfo) {
    request
      .post('/api/api-aidb/apply/deleteApply', { orderNo: id })
      .then((res) => {
        Message.success(remInfo)
        // 删除成功清除prepayId和订单号
        localStorage.removeItem('prepayId')
        localStorage.removeItem('orderNo')
        setText(remInfo)
        setTimeout(() => {
          window.location.replace('/vip')
        }, 500)
      })
      .finally(() => {
        setText(remInfo)
        setTimeout(() => {
          window.location.replace('/userCenter?activeTab=order')
        }, 500)
      })
  }

  //数据埋点支付触发
  function pointCheck(payId) {
    if (
      localStorage.getItem('bd_vid_by') !== '' &&
      localStorage.getItem('bd_vid_by') !== null
    ) {
      request
        .post(
          'https://cors.v-dk.com/https://ocpc.baidu.com/ocpcapi/api/uploadConvertData',
          {
            token:
              'fNTSXZtws4Z5YMf1yRKPEKW7CXPeclST@CzAQEdOtUQsyzOdGDSmu3ibwOr9N9xIu',
            conversionTypes: [
              {
                logidUrl: `https://www.wuz.com.cn/?bd_vid=${localStorage.getItem(
                  'bd_vid_by',
                )}`,
                newType: 10,
              },
            ],
          },
        )
        .then((res) => {
          // 登录成功
          if (res.data.header.desc == 'success') {
            //清除token
            localStorage.removeItem('bd_vid_by')
            setTimeout(() => {
              window.location.replace('/celebrate')
            }, 1000)
          }
        })
    } else if (
      localStorage.getItem('wwadsCode_by') !== '' &&
      localStorage.getItem('wwadsCode_by') !== null
    ) {
      request
        .post('https://cors.v-dk.com/https://portal.wuz.com.cn/wwads/report/', {
          clickCode: localStorage.getItem('wwadsCode_by'),
          convertId: payId,
        })
        .then((res) => {
          if (res.data.message == 'Success') {
            localStorage.removeItem('wwadsCode_by')
            setTimeout(() => {
              window.location.replace('/celebrate')
            }, 1000)
          }
        })
    } else {
      setTimeout(() => {
        window.location.replace('/celebrate')
      }, 1000)
    }
  }

  // 拉起支付
  function onBridgeReady(prepay_id) {
    const appId = 'wx335bf1398dfd21bb'
    const timeStamp = timest()
    const nonceStr = randomString(32)
    const Package = `prepay_id=${prepay_id}`
    request
      .post('/api/api-aidb/pay/getJsApiSign', {
        nonceStr,
        prepayId: prepay_id,
        timeStamp,
      })
      .then((res) => {
        const paySign = res.data.data

        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId, //公众号ID，由商户传入
            timeStamp, //时间戳，自1970年以来的秒数
            nonceStr, //随机串
            package: Package,
            signType: 'HMAC-SHA256', //微信签名方式
            paySign, //微信签名
          },
          function (res) {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Message.success('支付成功！')
              const payId = 'payId' + localStorage.getItem('orderNo')
              pointCheck(payId)
              // 清除prepayId和订单号
              localStorage.removeItem('prepayId')
              localStorage.removeItem('orderNo')
              setText('支付成功！')
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              Message.error('取消支付成功！')
              // 将prepayId放入本地缓存
              localStorage.setItem('prepayId', JSON.stringify(prepay_id))
              setText('取消支付成功！')
              setTimeout(() => {
                window.location.replace('/userCenter?activeTab=order')
              }, 1000)
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              Message.error('网络异常，请重试！')
              // 将prepayId放入本地缓存
              localStorage.setItem('prepayId', JSON.stringify(prepay_id))
              setText('网络异常，请重试！')
              setTimeout(() => {
                window.location.replace('/userCenter?activeTab=order')
              }, 1000)
            }
          },
        )
      })
      .catch((err) => {
        delList(
          JSON.parse(localStorage.getItem('orderNo')),
          '获取签名失败，请重新下单！',
        )
      })
  }

  // 获取支付所用的参数
  const goPay = () => {
    // 判断是否在微信浏览器
    if (ua.indexOf('micromessenger') != -1) {
      // 获取openid
      new Promise((resolve, reject) => {
        window.location.href.toString().split('?') &&
          window.location.href
            .toString()
            .split('?')
            .forEach((item) => {
              if (item.toString().indexOf('openid') != -1) {
                resolve(item.split('=')[1])
              }
            })
      })
        .then((res) => {
          // 已经获取到没有prepayId
          if (localStorage.getItem('prepayId') != null) {
            // 已经有prepayId
            if (typeof WeixinJSBridge == 'undefined') {
              if (document.addEventListener) {
                document.addEventListener(
                  'WeixinJSBridgeReady',
                  onBridgeReady,
                  false,
                )
              } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
              }
            } else {
              onBridgeReady(JSON.parse(localStorage.getItem('prepayId')))
            }
          } else {
            // 没有prepayId 并 获取PrepayId
            request
              .post('/api/api-aidb/pay/createPrepayId', {
                openId: res,
                orderNo: JSON.parse(localStorage.getItem('orderNo')),
              })
              .then((res: any) => {
                if (res.data.code == '500200') {
                  // 成功获取
                  if (typeof WeixinJSBridge == 'undefined') {
                    if (document.addEventListener) {
                      document.addEventListener(
                        'WeixinJSBridgeReady',
                        onBridgeReady,
                        false,
                      )
                    } else if (document.attachEvent) {
                      document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                      document.attachEvent(
                        'onWeixinJSBridgeReady',
                        onBridgeReady,
                      )
                    }
                  } else {
                    onBridgeReady(res.data.data)
                  }
                } else {
                  // 获取失败
                  delList(
                    JSON.parse(localStorage.getItem('orderNo')),
                    '获取PrepayId失败，请重新支付！',
                  )
                  setTimeout(() => {
                    window.location.replace('/userCenter?activeTab=order')
                  }, 1000)
                }
              })
              .catch((err) => {
                delList(
                  JSON.parse(localStorage.getItem('orderNo')),
                  '获取PrepayId失败，请重新支付！',
                )
                setTimeout(() => {
                  window.location.replace('/userCenter?activeTab=order')
                }, 1000)
              })
          }
        })
        .catch((err) => {
          // 没有获取到openid
          delList(
            JSON.parse(localStorage.getItem('orderNo')),
            '获取openid失败，请重新下单！',
          )
          setTimeout(() => {
            window.location.replace('/userCenter?activeTab=order')
          }, 1000)
        })
    } else {
      Message.error('请移至微信浏览器端支付！')
      setText('请移至微信浏览器端支付！')
      setTimeout(() => {
        window.location.replace('/vip')
      }, 1000)
    }
  }

  // 微信小程序支付
  const wxGoPay = () => {
    alert('1111')
    // const payData = { orderId: JSON.parse(localStorage.getItem("orderNo")), openId: getOpenId() };
    // const payDataStr = JSON.stringify(payData);

    getOpenId().then((res) => {
      // alert(res)
      //  对应小程序项目里建立的wePay.js的路径
      const url = `../wePay/wePay?cookie=${encodeURIComponent(
        getAllCookie(),
      )}&orderNo=${encodeURIComponent(
        JSON.parse(localStorage.getItem('orderNo')),
      )}&openid=${res}`

      // 跳转到微信小程序页面，然后在小程序内完成支付代码操作
      wx.miniProgram.navigateTo({
        url,
        success: function (res) {
          // alert('成功！' + getAllCookie())
        },
        fail: function (err) {
          alert('失败！' + err)
        },
      })
    })
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20%',
        }}>
        <p
          style={{
            fontSize: '1.2rem',
          }}>
          {text}
        </p>
      </div>
    </>
  )
}

export default WxJSAPICallback
