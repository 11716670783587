import { Form, Input, Button, Space, Message } from '@arco-design/web-react'
import React, { useEffect, useState } from 'react'
import useLocale from '@/utils/useLocale'
import locale from './locale'
import styles from '@/style/index.module.less'
import style from '../login/styles/index.module.less'
import md5 from 'md5'
import { Grid } from '@arco-design/web-react'
const Row = Grid.Row
const Col = Grid.Col
import IconMsgSuccess from '@/assets/newImgs/msg-success.svg'
import Aliyun from '../../components/AliyunCheck/aliyunPhone'
// 封装axios请求
let timeChange
import request from '@/utils/request'
import { IconClose } from '@arco-iconbox/react-aidb'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { useHistory } from 'react-router-dom'
import {
  GetUserSource,
  registerEmail,
  registerPhone,
  sendEmail,
  sendSMSCode,
} from '@/model/reference'

export default function LoginForm() {
  const history = useHistory()
  const FormItem = Form.Item
  const [form] = Form.useForm()
  const emailState = Form.useFormState('email', form)
  const [inviteCode, setInviteCode] = useState<string>()
  const [errorMessage] = useState('')
  const initialValue = localStorage.getItem('email') || ''
  const [emailPhoneBtn, setEmailPhoneBtn] = useState(true)
  useEffect(() => {
    form.setFieldsValue({ email: initialValue })
  }, [])
  const t = useLocale(locale)
  //倒计时
  const [time, setTime] = useState(60)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [btnContent, setBtnContent] = useState('获取验证码')
  useEffect(() => {
    setInviteCode(window.location.href.split('invitationCode=')[1])
  })
  useEffect(() => {
    if (inviteCode) {
      form.setFieldsValue({ inviteCode })
    }
  }, [inviteCode, form])
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`)
    } else {
      clearInterval(timeChange)
      setBtnDisabled(false)
      setTime(60)
      setBtnContent('获取验证码')
    }
  }, [time])

  // 验证邮箱格式是否正确
  function isEmailValid(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email)
  }

  // 验证手机号格式是否正确
  function isPhoneValid(phone) {
    const phonePattern = /^1[3456789]\d{9}$/
    return phonePattern.test(phone)
  }

  //监听手机号或邮箱
  useEffect(() => {
    console.log(form?.getFieldsValue().email)
    const pattern = /^1[3456789]\d{9}$/
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (form?.getFieldsValue()?.email?.includes('@')) {
      if (!regex.test(form?.getFieldsValue().email)) {
        setEmailPhoneBtn(true)
      } else {
        setEmailPhoneBtn(false)
      }
    } else {
      if (!pattern.test(form?.getFieldsValue().email)) {
        setEmailPhoneBtn(true)
      } else {
        setEmailPhoneBtn(false)
      }
    }
  }, [form?.getFieldsValue().email])
  console.log(emailPhoneBtn)

  //发送验证码
  const getPhoneCaptcha = async () => {
    const { email } = form.getFieldsValue()
    const isEmail = email.includes('@')
    const isPhone = !isEmail

    if (isEmail) {
      if (!isEmailValid(email)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请输入正确的邮箱地址',
        })
        return
      }
    } else if (isPhone) {
      if (!isPhoneValid(email)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请输入正确的手机号',
        })
        return
      }
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请先选择接收验证码方式',
      })
      return
    }
    setBtnDisabled(true)
    setBtnContent('发送中...')
    const sendFunction = isEmail ? sendEmail : sendSMSCode
    try {
      const res = await sendFunction({
        type: 1,
        email: isEmail ? email : undefined,
        areaCode: isPhone ? '86' : undefined,
        phone: isPhone ? email : undefined,
      })

      const { success, msg } = res.data
      if (success) {
        timeChange = setInterval(() => setTime((t) => --t), 1000)
        setBtnDisabled(true)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '验证码发送成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: msg || '验证码发送失败',
        })
      }
    } catch (error) {
      setBtnDisabled(false)
      setBtnContent('获取验证码')
      if (error.response) {
        let errorMsg = ''
        if (error.response.data.status === 400) {
          errorMsg = error.response.data.errors
            .map((err) => err.defaultMessage)
            .join(' ')
        } else if (error.response.data.status === 500) {
          errorMsg = error.response.data.message
          if (
            errorMsg === '该账号已存在，请联系系统管理员' &&
            !localStorage.getItem('loginParams')
          ) {
            const loginParams = {
              userName: email?.trim(),
              password: '',
              privacyChecked: false,
            }

            localStorage.setItem('loginParams', JSON.stringify(loginParams))
          }
        }
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `${error.response.data.status} - ${errorMsg}`,
        })
      }
    }
  }

  //数据埋点注册触发
  function pointCheck(uid) {
    if (
      localStorage.getItem('bd_vid_reg') !== '' &&
      localStorage.getItem('bd_vid_reg') !== null
    ) {
      request
        .post(
          'https://cors.v-dk.com/https://ocpc.baidu.com/ocpcapi/api/uploadConvertData',
          {
            token:
              'fNTSXZtws4Z5YMf1yRKPEKW7CXPeclST@CzAQEdOtUQsyzOdGDSmu3ibwOr9N9xIu',
            conversionTypes: [
              {
                logidUrl: `https://www.wuz.com.cn/?bd_vid=${localStorage.getItem(
                  'bd_vid_reg',
                )}`,
                newType: 25,
              },
            ],
          },
        )
        .then((res) => {
          // 登录成功
          if (res.data.header.desc == 'success') {
            localStorage.removeItem('bd_vid_reg')
            afterRegisterSuccess()
          }
        })
    } else if (
      localStorage.getItem('wwadsCode_reg') !== '' &&
      localStorage.getItem('wwadsCode_reg') !== null
    ) {
      request
        .post('https://cors.v-dk.com/https://portal.wuz.com.cn/wwads/report/', {
          clickCode: localStorage.getItem('wwadsCode_reg'),
          convertId: uid,
        })
        .then((res) => {
          if (res.data.message == 'Success') {
            localStorage.removeItem('wwadsCode_reg')
            afterRegisterSuccess()
          }
        })
    } else if (
      localStorage.getItem('utm_source_reg') !== '' &&
      localStorage.getItem('utm_source_reg') !== null
    ) {
      GetUserSource({
        utmSource: localStorage.getItem('utm_source_reg'), //来源
        type: 1, //注册操作,
        employeeId: uid, //用户id
      })
        .then((res) => {
          if (res.data.success) {
            localStorage.removeItem('utm_source_reg')
            afterRegisterSuccess()
          }
        })
        .catch(() => {
          afterRegisterSuccess()
        })
    } else {
      // 登录成功
      afterRegisterSuccess()
    }
  }

  function onLogin() {
    history.push('/login')
  }

  //注册
  function addRegister() {
    const { email } = form.getFieldsValue()
    const isEmail = email.includes('@')
    const passwordMd5 = md5(
      form.getFieldsValue().password?.trim() + 'avc154*gtd45rge3',
    ).toUpperCase()
    const repeatPasswordMd5 = md5(
      form.getFieldsValue().repeatPassword?.trim() + 'avc154*gtd45rge3',
    ).toUpperCase()

    form.validate().then((res) => {
      const requestData = {
        password: passwordMd5,
        repeatPassword: repeatPasswordMd5,
        email: '',
        invitationCode: inviteCode,
        emailCode: '',
        phone: '',
        code: '',
      }

      if (isEmail) {
        requestData.email = res.email
        requestData.emailCode = res.emailCode
      } else {
        requestData.phone = res.email
        requestData.code = res.emailCode
      }

      const registerFunction = isEmail ? registerEmail : registerPhone

      registerFunction(requestData)
        .then((res1) => {
          const { success } = res1.data
          console.log(res1.data.data.name)
          const uid = res1.data.data.id
          if (success === true) {
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: '注册成功',
            })
            const loginParams = {
              userName: email?.trim(),
              password: '',
              privacyChecked: false,
            }
            localStorage.setItem('loginParams', JSON.stringify(loginParams))
            pointCheck(uid)
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorMsg = ''
            if (error.response.data.status === 400) {
              errorMsg = error.response.data.errors
                .map((err) => err.defaultMessage)
                .join(' ')
            } else if (error.response.data.status === 500) {
              errorMsg = error.response.data.message
              if (
                errorMsg === '该账号已存在，请联系系统管理员' &&
                !localStorage.getItem('loginParams')
              ) {
                const loginParams = {
                  userName: email?.trim(),
                  password: '',
                  privacyChecked: false,
                }

                localStorage.setItem('loginParams', JSON.stringify(loginParams))
              }
            }

            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: `${error.response.data.status} - ${errorMsg}`,
            })
          }
        })
    })
  }

  //跳转到登录页
  function afterRegisterSuccess() {
    setTimeout(() => {
      history.push('/login')
    }, 500)
  }

  //阿里云校验通过回调
  const handleBizResult = (bizResult) => {
    if (bizResult === true) {
      // 在这里定义父组件中校验通过的操作
      // getPhoneCaptcha()
      timeChange = setInterval(() => setTime((t) => --t), 1000)
      setBtnDisabled(true)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '验证码发送成功',
      })
    }
  }
  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>
        {t['register.form.register.title']}
      </div>
      <div className={styles['login-form-sub-title']}></div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <div className={styles.registerBtn}>
        <Form className={styles['login-form']} layout="vertical" form={form}>
          <FormItem
            field="email"
            triggerPropName="change"
            rules={[
              {
                required: true,
                message: '邮箱/手机号不能为空',
              },
              {
                validator: (v, cb) => {
                  const pattern = /^1[3456789]\d{9}$/
                  const regex =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                  if (v.includes('@')) {
                    if (!regex.test(v)) {
                      return cb('请输入正确的邮箱')
                    }
                  } else {
                    if (v.length >= 11) {
                      if (!pattern.test(v)) {
                        return cb('请输入正确的手机号')
                      }
                    }
                  }
                },
              },
            ]}
            className={styles.email}>
            <Input placeholder="邮箱/手机号" />
          </FormItem>

          <Row className="grid-demo">
            <Col flex="auto">
              <FormItem
                field="emailCode"
                rules={[{ required: true, message: '验证码不能为空' }]}
                className={styles.email}>
                <Input
                  placeholder="验证码"
                  // ref={inputCaptchaRef}
                />
              </FormItem>
            </Col>
            <Col flex="100px">
              <Button
                disabled={
                  btnDisabled ||
                  emailState?.validateStatus !== 'success' ||
                  emailPhoneBtn
                }
                id="aliyunButton"
                // onClick={getPhoneCaptcha}
                className={styles.registerEamilCode}
                style={{ position: 'absolute' }}>
                {btnContent}
              </Button>
            </Col>
          </Row>

          {/* <FormItem
            field="name"
            rules={[{ required: true, message: '用户名不能为空' }]}>
            <Input placeholder="用户名" />
          </FormItem> */}
          <FormItem
            field="password"
            rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password placeholder="密码" visibilityToggle={false} />
          </FormItem>
          <FormItem
            field="repeatPassword"
            rules={[
              {
                validator: (v, cb) => {
                  if (!v) {
                    return cb('确认密码不能为空')
                  } else if (form.getFieldValue('password') !== v) {
                    return cb('两次密码输入不相符')
                  }
                  cb(null)
                },
              },
            ]}>
            <Input.Password visibilityToggle={false} placeholder="确认密码" />
          </FormItem>
          <FormItem field="inviteCode">
            <Input placeholder="邀请码（非必填）" />
          </FormItem>
          <Space size={16} direction="vertical">
            <div className={styles['login-form-password-actions']}>
              {/* <Link>{t['register.form.forgetPassword']}</Link> */}
            </div>
            <div className={styles.btn}>
              <Button type="primary" long onClick={addRegister}>
                {t['register.form.register']}
              </Button>
              {/* <Button
                onClick={onLogin}
                type="text"
                long
                className={styles['login-form-register-btn']}>
                {t['register.form.login']}
              </Button> */}
              <div className={style.registerBtn}>
                <span className={style.text1}>已有账号?</span>
                <span className={style.text2} onClick={onLogin}>
                  立即登录
                </span>
              </div>
            </div>
          </Space>
        </Form>
      </div>
      <Aliyun
        onSuccessCallback={handleBizResult}
        phoneType={1}
        usernamePhone={form?.getFieldsValue().email}></Aliyun>
    </div>
  )
}
