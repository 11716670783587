import React, { useState } from 'react'
import styles from './style/toolHeaderMod.module.less'
import {
  IconClosure,
  IconCheck,
  IconArrowDown,
  IconReplace,
  IconCopy,
  IconArrowLeft,
  IconCollect,
  IconCollectFill,
  IconClear,
  IconBaseSync,
  IconProcessAll,
} from '@arco-iconbox/react-aidb-v2'
import { useHistory } from 'react-router-dom'
import {
  Dropdown,
  Menu,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from '@arco-design/web-react'
// import { ReactComponent as IconProcessAll } from '../../assets/imgs/i-process-all.svg'
import IconBtn from '../../components/IconBtn'
import {
  CollectType,
  DocBtnType,
  DropdownType,
  ProofSelectType,
  ProofTactfulType,
  RewriteSelectType,
} from '@/helpers/enums'
import DropMenu from '../Ui/drop-menu'
import { CollectMenu, ProofSelectMenu, RewriteSelectMenu } from '@/share/config'
import palette from '@/helpers/palette'
import SearchMod from '@/pages/intelligentDocument/SearchMod'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { GetQueryValue, removeEmpty } from '@/utils/common'
// import SearchMod from '../intelligentDocument/SearchMod'
const TabPane = Tabs.TabPane
interface Props {
  title: string
  type: number
  proofShowType: number
  proofTactfulType: string
  searchInput?: string
  showBackTool?: boolean
  collectFoldId?: string
  isLoading?: boolean
  isTransLoading?: boolean
  isWriteLoading?: boolean
  isContinueLoading?: boolean
  isCorrectLoading?: boolean
  isReduceLoading?: boolean
  isDirectivesLoading?: boolean
  isToolsLoading?: boolean
  showAllReplace?: boolean
  isProofCommentLoading: boolean
  isProofOfficialLoading: boolean
  isProofAiLoading: boolean
  isProofAdLoading: boolean
  isProofMultilLangLoading: boolean
  theme?: any

  closeTool?: () => void
  onChangeOrder?: (changeOrder: number) => void
  onChangeProofOrder?: (changeOrder: number) => void
  onSearch?: (searchText?: string) => void
  onToolBack?: () => void
  onRefresh?: () => void
  onStop?: (stopType) => void
  onCollectType?: (collectType: number) => void
  onReplaceAll?: () => void
  onCopyAll?: () => void
  onCorrectAll?: () => void
}

const ToolHeaderMod = (props: Props) => {
  const {
    searchInput,
    isLoading,
    proofShowType,
    isWriteLoading,
    isTransLoading,
    isContinueLoading,
    isCorrectLoading,
    isReduceLoading,
    isDirectivesLoading,
    isToolsLoading,
    isProofCommentLoading,
    isProofOfficialLoading,
    isProofAiLoading,
    isProofAdLoading,
    isProofMultilLangLoading,
    type,
    showAllReplace,
    proofTactfulType,
    onCopyAll,
    onReplaceAll,
    onCollectType,
    onRefresh,
    onChangeOrder,
    onChangeProofOrder,
    onSearch,
    onStop,
    onCorrectAll,
    theme,
  } = props
  const history = useHistory()
  const [activeKey, setActiveKey] = useState(
    Number(GetQueryValue('collectType')) || CollectType.doc,
  )
  const [rewriteActiveKey, setRewriteActiveKey] = useState(
    RewriteSelectType.onlyAdd,
  )
  const [proofActiveKey, setProofActiveKey] = useState(
    ProofSelectType.basicProof,
  )
  //关闭
  function closeTool() {
    props.closeTool()
  }

  //收藏

  const menuClick = (key) => {
    setActiveKey(key)
    onCollectType(key)
    const urlQuery = removeEmpty({
      filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
      listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
      uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
      folderUuid: GetQueryValue('folderUuid')
        ? GetQueryValue('folderUuid')
        : '',
      title: GetQueryValue('title') ? GetQueryValue('title') : '',
      from: GetQueryValue('from') ? GetQueryValue('from') : '',
      collectType: key,
    }) as any

    history.replace({
      pathname: '/documentEdit',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }

  const menuRewriteClick = (orderType) => {
    setRewriteActiveKey(orderType)
    onChangeOrder(orderType)
  }

  const menuProofClick = (orderType) => {
    setProofActiveKey(orderType)
    onChangeProofOrder(orderType)
  }

  return (
    <div className={`${styles.tool_header_mod} ${styles[theme]}`}>
      <div className={styles.left}>
        {/*返回*/}
        {/* {(GetQueryValue('collectFoldUuid') ||
          GetQueryValue('collectDocUuid')) && (
          <div className={styles.back} onClick={onToolBack}>
            <IconArrowLeft />
          </div>
        )} */}
        {/*标题*/}
        {type !== DocBtnType.collect && type !== DocBtnType.proof && (
          <div className={styles.title}>{props.title}</div>
        )}

        {props.type === DocBtnType.collect && (
          <DropMenu
            menuList={CollectMenu}
            onMenuClick={menuClick}
            activeKey={activeKey}
            fontSize={18}
            fontWeight={'bold'}
            isSelect={true}
            dropdownType={DropdownType.titleDown}
            isHasDivider={true}
            // iconNode={<IconOrder />}
          />
        )}

        {type === DocBtnType.proof && (
          <Tabs
            activeTab={String(proofActiveKey)}
            onChange={(orderType) => menuProofClick(orderType)}>
            {ProofSelectMenu.map((i) => {
              return <TabPane key={i.key} title={<>{i.title}</>}></TabPane>
            })}
          </Tabs>
        )}

        {/* {props.type === DocBtnType.proof && (
          <div style={{ marginLeft: '15px', lineHeight: '36px' }}>
            <DropMenu
              menuList={ProofSelectMenu}
              onMenuClick={(orderType) => menuProofClick(orderType)}
              activeKey={proofActiveKey}
              fontSize={14}
              fontColor={palette.black70}
              isSelect={true}
              iconSize={20}
              iconColor={palette.black70}
              dropdownType={DropdownType.titleDown}
              isHasDivider={true}
            />
          </div>
        )} */}

        {/*收藏*/}
        {/* {props.showFav && (
          <div
            className={`${isFav ? styles.faved : ''} ${styles.fav}`}
            onClick={() => {
              setIsFav(!isFav)
            }}>
            {!isFav && <IconCollect />}
            {isFav && <IconCollectFill />}
          </div>
        )} */}
        {/*改写分类选择*/}
        {(props.type === DocBtnType.rewrite ||
          props.type === DocBtnType.rewriteSingle ||
          props.type === DocBtnType.reduce ||
          props.type === DocBtnType.reduceSingle) && (
          <div style={{ marginLeft: '15px', lineHeight: '36px' }}>
            <DropMenu
              menuList={RewriteSelectMenu}
              onMenuClick={(orderType) => menuRewriteClick(orderType)}
              activeKey={rewriteActiveKey}
              fontSize={14}
              fontColor={palette.black70}
              isSelect={true}
              iconSize={20}
              iconColor={palette.black70}
              dropdownType={DropdownType.titleDown}
              isHasDivider={true}
            />
          </div>
        )}
      </div>
      <div className={styles.right}>
        {props.type === DocBtnType.proof &&
          proofActiveKey === ProofSelectType.basicProof &&
          !isCorrectLoading && (
            <div className={styles.correct} onClick={onCorrectAll}>
              <IconProcessAll />
            </div>
          )}
        {showAllReplace && (
          <div className={styles.btn_wrap}>
            {props.type === DocBtnType.translate && !isTransLoading && (
              <IconBtn
                theme={theme}
                onClick={onReplaceAll}
                text={'替换全部'}
                color={'toBlack'}
                iconLeft={<IconReplace />}
              />
            )}

            {((props.type === DocBtnType.translate && !isTransLoading) ||
              (props.type === DocBtnType.reduce && !isReduceLoading) ||
              (props.type === DocBtnType.rewrite && !isWriteLoading)) && (
              <IconBtn
                theme={theme}
                onClick={onCopyAll}
                text={'复制全部'}
                color={'toBlack'}
                iconLeft={<IconCopy />}
              />
            )}
          </div>
        )}
        {/* {props.isParagraph && props.title === '改写' && (
          <div className={styles.btn_wrap}>
            <IconBtn
              text={'替换全部'}
              color={'toBlack'}
              iconLeft={<IconReplace />}
            />
            <IconBtn
              text={'复制全部'}
              color={'toBlack'}
              iconLeft={<IconCopy />}
            />
          </div>
        )}
        {props.isParagraph && props.title === '翻译' && (
          <div className={styles.btn_wrap}>
            <IconBtn
              text={'替换全部'}
              color={'toBlack'}
              bg={true}
              iconLeft={<IconReplace />}
            />
            <IconBtn
              text={'复制全部'}
              color={'toBlack'}
              bg={true}
              iconLeft={<IconCopy />}
            />
          </div>
        )}
        {props.showClear && props.title === '助理' && (
          <div className={styles.btn_wrap}>
            <IconBtn
              text={'清除聊天记录'}
              color={'toBlack'}
              bg={true}
              iconLeft={<IconClear />}
            />
          </div>
        )} */}
        {props.type === DocBtnType.collect && (
          <div className={styles.search}>
            <SearchMod
              theme={theme}
              fixedWidth={true}
              placeholder={'搜索收藏夹'}
              onSearch={onSearch}
              searchInp={searchInput}
            />
          </div>
        )}
        {/* {type == DocBtnType.proof && isCorrectLoading ? (
          <Tooltip position="bottom" trigger="hover" content={'正在刷新'}>
            <div className={`${styles.rotate}`}>
              <IconBaseSync />
            </div>
          </Tooltip>
        ) : (
          type == DocBtnType.proof && (
            <Tooltip position="bottom" trigger="hover" content={'全部刷新'}>
              <div
                className={`${styles.close} `}
                onClick={() => {
                  onRefresh()
                }}>
                <IconBaseSync />
              </div>
            </Tooltip>
          )
        )} */}
        {/* {type == DocBtnType.proof ||
        type == DocBtnType.rewrite ||
        (type == DocBtnType.translate && isTransLoading) ||
        type == DocBtnType.rewriteSingle ||
        type == DocBtnType.translateSingle ||
        type == DocBtnType.continuationSingle ||
        type == DocBtnType.tools */}
        {(type == DocBtnType.proof &&
          proofShowType == ProofSelectType.basicProof &&
          isCorrectLoading) ||
        (type == DocBtnType.proof &&
          proofShowType == ProofSelectType.officialProof &&
          isProofOfficialLoading) ||
        (type == DocBtnType.proof &&
          proofShowType == ProofSelectType.tactfulProof &&
          proofTactfulType == ProofTactfulType.commentProof &&
          isProofCommentLoading) ||
        (type == DocBtnType.proof &&
          proofShowType == ProofSelectType.tactfulProof &&
          proofTactfulType == ProofTactfulType.aiProof &&
          isProofAiLoading) ||
        (type == DocBtnType.proof &&
          proofShowType == ProofSelectType.tactfulProof &&
          proofTactfulType == ProofTactfulType.adProof &&
          isProofAdLoading) ||
        (type == DocBtnType.proof &&
          proofShowType == ProofSelectType.tactfulProof &&
          proofTactfulType == ProofTactfulType.multilLangProof &&
          isProofMultilLangLoading) ||
        (type == DocBtnType.rewrite && isWriteLoading) ||
        (type == DocBtnType.rewriteSingle && isWriteLoading) ||
        (type == DocBtnType.translate && isTransLoading) ||
        (type == DocBtnType.translateSingle && isTransLoading) ||
        (type == DocBtnType.reduce && isReduceLoading) ||
        (type == DocBtnType.reduceSingle && isReduceLoading) ||
        (type == DocBtnType.continuation && isContinueLoading) ||
        (type == DocBtnType.continuationSingle && isContinueLoading) ? (
          <Tooltip position="bottom" trigger="hover" content={'停止'}>
            <div
              className={`${styles.rotate}`}
              onClick={() => {
                onStop(type)
              }}>
              <IconBaseSync />
            </div>
          </Tooltip>
        ) : (
          ((type == DocBtnType.proof &&
            proofShowType == ProofSelectType.basicProof) ||
            (type == DocBtnType.proof &&
              proofShowType == ProofSelectType.officialProof) ||
            (type == DocBtnType.proof &&
              proofShowType == ProofSelectType.tactfulProof &&
              proofTactfulType == ProofTactfulType.commentProof) ||
            (type == DocBtnType.proof &&
              proofShowType == ProofSelectType.tactfulProof &&
              proofTactfulType == ProofTactfulType.aiProof) ||
            (type == DocBtnType.proof &&
              proofShowType == ProofSelectType.tactfulProof &&
              proofTactfulType == ProofTactfulType.adProof) ||
            (type == DocBtnType.proof &&
              proofShowType == ProofSelectType.tactfulProof &&
              proofTactfulType == ProofTactfulType.multilLangProof) ||
            type == DocBtnType.rewrite ||
            type == DocBtnType.translate ||
            type == DocBtnType.rewriteSingle ||
            type == DocBtnType.translateSingle ||
            type == DocBtnType.reduce ||
            type == DocBtnType.reduceSingle ||
            type == DocBtnType.continuationSingle) && (
            <Tooltip position="bottom" trigger="hover" content={'全部刷新'}>
              <div
                className={`${styles.close} `}
                onClick={() => {
                  onRefresh()
                }}>
                <IconBaseSync />
              </div>
            </Tooltip>
          )
        )}

        {type == DocBtnType.directives && isDirectivesLoading && (
          <Tooltip position="bottom" trigger="hover" content={'点击停止'}>
            <div
              className={`${styles.rotate}`}
              onClick={() => {
                onStop(type)
              }}>
              <IconBaseSync />
            </div>
          </Tooltip>
        )}

        <div
          className={styles.close}
          onClick={() => {
            closeTool()
          }}>
          <IconClosure />
        </div>
      </div>
    </div>
  )
}

export default ToolHeaderMod
