import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styles from './style/topBar.module.less'
import {
  IconArrowLeft,
  IconCollect,
  IconSave,
  IconUploadDocument,
  IconShare,
  IconHotKey,
  IconMoreEdits,
  IconCollectFill,
  IconArrowRight,
  IconSuccessTip,
  IconErrorTip,
} from '@arco-iconbox/react-aidb-v2'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  Dropdown,
  Menu,
  Message,
  Tooltip,
} from '@arco-design/web-react'
import IconSaveRotate from '@/assets/newImgs/save.svg'
import IconSaveRotate2 from '@/assets/newImgs/rotate-save.svg'
import { ApprovalStatus } from '@/helpers/enums'
import { getDocShareLink } from '@/model/documentEdit'
import { GetQueryValue, removeEmpty, throttle } from '@/utils/common'
import { showModal } from '@/share/actions'
import {
  getCancelCollect,
  getDocumentCollect,
  getUserInfo,
  removeDocument,
} from '@/model/reference'
import axios from 'axios'
import SubMenu from '@arco-design/web-react/es/Menu/sub-menu'
import { ExportBtn } from '@/share/config'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
interface Props {
  isShowBack: boolean
  title?: string
  isAutoSave?: boolean
  isCollect?: number
  uuid?: string
  gmtModifyDate?: string
  showRight?: boolean
  isImmerseDisplay?: boolean
  isFullDisplay?: boolean
  onChangeTitle?: (title: string) => void
  onClickSave?: (approvalStatus: number) => void
  onGoBack?: (isImmerseDisplay: boolean) => void
  onFullDisplay?: () => void
  onImmerseDisplay?: () => void
  onExitImmerseDisplay?: () => void
  onVersionModal?: () => void
  onAttributeModal?: () => void
  onClickMore: () => void
  ref?: any
  theme?: any
}

const TopBar = forwardRef((props: Props, ref) => {
  const {
    isShowBack,
    title,
    isAutoSave,
    onChangeTitle,
    onClickSave,
    uuid,
    showRight,
    isCollect,
    gmtModifyDate,
    isFullDisplay,
    isImmerseDisplay,
    onGoBack,
    onVersionModal,
    onAttributeModal,
    onFullDisplay,
    onImmerseDisplay,
    onExitImmerseDisplay,
    onClickMore,
    theme,
  } = props
  const [isCollectVal, setIsCollectVal] = useState(isCollect)
  const [titleVal, setTitleVal] = useState(title)
  const exportArr = useRef([])
  const inputRef = useRef(null)
  const history = useHistory()

  useEffect(() => {
    setTitleVal(title)
  }, [title])

  useEffect(() => {
    setIsCollectVal(isCollect)
  }, [isCollect])

  const inputChangeHandle = (e) => {
    setTitleVal(e.target.value)
    throttle(onChangeTitle(e.target.value), 5000)
  }

  const shareHandle = async () => {
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    const res = await getDocShareLink({ uuid: GetQueryValue('uuid') })
    if (res.data.success) {
      const postData = {
        url:
          window.location.origin +
          '/documentShare?uuid=' +
          res.data.data.documentShareLink,
      }
      showModal('ShareLinkModal', {
        title: '分享',
        id: GetQueryValue('uuid'),
        isOpenShare: res.data.data.isOpenShare,
        documentShareLink: postData,
      })
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: res.data.msg,
      })
    }
  }

  const keyMapHandle = () => {
    showModal('KeyMapModal', {
      title: '快捷键',
    })
  }

  // 收藏文档
  const collectHandle = async () => {
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    if (isCollectVal == 0) {
      const res = await getDocumentCollect({ uuid: uuid })
      if (res.data.data) {
        setIsCollectVal(1)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '收藏文档成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '收藏文档失败',
        })
      }
    } else {
      const res = await getCancelCollect({ uuids: [uuid] })
      if (res.data.data) {
        setIsCollectVal(0)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '取消收藏成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '取消收藏文档失败',
        })
      }
    }
  }

  const removeDocumentHandle = async (event) => {
    event.stopPropagation()
    const res = await removeDocument({
      docStatus: 1,
      uuids: [uuid],
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '移至废纸篓成功',
        duration: 1000,
      })
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
      }) as any

      setTimeout(() => {
        history.push({
          pathname: '/intelligentDocument',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })

        location.reload()
      }, 1000)
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '移至废纸篓失败',
      })
    }
  }

  const exportHandle = async (type, interfaceName) => {
    console.log(type, 'type11', interfaceName)
    const res = await getUserInfo({ uid: '' })
    const vipEndDate = new Date(res.data.data.vipEndDate)
    const currentDate = new Date()

    if (currentDate > vipEndDate) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请开通Vip会员，方可导出',
      })
    } else {
      const positionId = localStorage.getItem('positionId')
      const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null
      const uuid = GetQueryValue('uuid')
      console.log(exportArr.current.includes(type), 'yyyy')
      if (exportArr.current.includes(type)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `正在导出 ${type}中`,
        })
        return
      }
      exportArr.current = [...exportArr.current, type]

      axios
        .post(
          `/api/api-aidb/document/${interfaceName}`,
          { uuid, docVersionId: 0 },
          { responseType: 'blob' },
        )
        .then((res) => {
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.${type}`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          exportArr.current = exportArr.current.filter((i) => i != type)
          // setExportArr(exportArr.filter((i) => i == type))
          console.log(exportArr.current, '1')
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `导出 ${type} 成功`,
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 ${type} 错误`,
          })
        })
    }
  }

  const immerseDisplayHandle = () => {
    if (isAutoSave) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '正在保存',
      })
    } else {
      onImmerseDisplay()
    }
  }

  const dropList = (
    <Menu className={'custom-dropdown'} theme={theme}>
      <div className={'dropdown-list'}>
        <Menu.Item key="1" onClick={collectHandle}>
          {isCollectVal == 1 ? '取消收藏' : '添加到收藏'}
        </Menu.Item>
        <Menu.Item key="2" onClick={removeDocumentHandle}>
          移至废纸篓
        </Menu.Item>
        <SubMenu key={'7'} title={'导出'}>
          {ExportBtn.map(({ key, title, type, interfaceName }) => {
            return (
              <Menu.Item
                key={`5-${key}`}
                onClick={() => exportHandle(type, interfaceName)}>
                {title}
              </Menu.Item>
            )
          })}
        </SubMenu>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className={'dropdown-list'}>
        <Menu.Item key="5" onClick={onFullDisplay}>
          {!isFullDisplay ? '全宽显示' : '标宽显示'}
        </Menu.Item>
        <Menu.Item key="6" onClick={immerseDisplayHandle}>
          演示模式
        </Menu.Item>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className={'dropdown-list'}>
        <Menu.Item key="3" onClick={onVersionModal}>
          历史版本
        </Menu.Item>
        <Menu.Item key="4" onClick={onAttributeModal}>
          文档属性
        </Menu.Item>
      </div>

      {uuid && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <div className={'dropdown-list'}>
            <p>最近修改：</p>
            <p>{gmtModifyDate}</p>
          </div>
        </>
      )}
    </Menu>
  )

  return (
    <div className={`${styles.header} ${styles[theme]}`}>
      <div className={styles.left}>
        {isShowBack && (
          <>
            {isImmerseDisplay ? (
              <div className={styles.icon} onClick={onExitImmerseDisplay}>
                <IconArrowLeft />
              </div>
            ) : (
              <Tooltip
                position="bottom"
                trigger="hover"
                content={isImmerseDisplay ? '退出演示模式' : '返回'}>
                <div
                  className={styles.icon}
                  onClick={() => onGoBack(isImmerseDisplay)}>
                  <IconArrowLeft />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {!isImmerseDisplay && (
        <div className={styles.right}>
          <div
            className={styles.icon}
            onClick={() => onClickSave(ApprovalStatus.ToSubmit)}>
            <img
              src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/icon-cloud-ref.svg"
              alt=""
            />
          </div>

          <div
            style={{ cursor: isAutoSave ? 'not-allowed' : 'pointer' }}
            className={styles.icon}
            onClick={
              isAutoSave
                ? null
                : () => onClickSave(ApprovalStatus.PendingApproval)
            }>
            <img
              src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/icon-upload-b.svg"
              alt=""
            />
          </div>

          {/* <Dropdown
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
            droplist={dropList}
            position="br"
            // popupVisible={true}
            trigger={'click'}> */}
          <div
            className={`${styles.more} ${styles.icon}`}
            onClick={onClickMore}>
            <img
              src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/icon-menu-b.svg"
              alt=""
            />
          </div>
          {/* </Dropdown> */}
        </div>
      )}
    </div>
  )
})

export default TopBar
