import React, { useEffect, useRef } from 'react'
import { aliyunSmsCode } from '@/model/reference'
import request from '@/utils/request'
let captcha
import { Button, Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import { GetQueryValue } from '@/utils/common'
function CaptchaComponent({ onSuccessCallback, phoneType, usernamePhone }) {
  const PhoneNumber = useRef(usernamePhone)
  const Type = useRef(1)
  const areaCode = useRef('')
  const phoneType1 = useRef('')
  const emailType = useRef('')
  useEffect(() => {
    const maskElement = document.getElementById('aliyunCaptcha-mask')
    const popupElement = document.getElementById('aliyunCaptcha-window-popup')

    if (maskElement && popupElement) {
      maskElement.remove()
      popupElement.remove()
    }

    const loadAndInitScript = () => {
      const script = document.createElement('script')
      script.src =
        'https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js'
      script.async = true
      script.onload = initCaptcha
      document.head.appendChild(script)
    }

    loadAndInitScript()
  }, [])

  // const loadAndInitScript = () => {
  //   const script = document.createElement('script')
  //   script.src =
  //     'https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js'
  //   script.async = true
  //   script.onload = initCaptcha
  //   document.head.appendChild(script)
  // }

  const initCaptcha = async () => {
    initAliyunCaptcha({
      SceneId: 'zn6y4dss', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: 'kh0001', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#aliyunButton', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    })
  }
  // 弹出式
  useEffect(() => {
    // ...（现有的 useEffect 代码）
    PhoneNumber.current = usernamePhone
    if (PhoneNumber.current) {
      if (PhoneNumber.current.includes('@')) {
        Type.current = 2
        areaCode.current = undefined
        phoneType1.current = undefined
        emailType.current = phoneType
      } else {
        Type.current = 1
        areaCode.current = '86'
        phoneType1.current = phoneType
        emailType.current = undefined
      }
      if (phoneType == '找回密码') {
        if (PhoneNumber.current.includes('@')) {
          Type.current = 2
          areaCode.current = undefined
          phoneType1.current = undefined
          emailType.current = 2
        } else {
          Type.current = 1
          areaCode.current = '86'
          phoneType1.current = 3
          emailType.current = undefined
        }
      }
      if (phoneType == '修改手机号邮箱') {
        if (PhoneNumber.current.includes('@')) {
          Type.current = 2
          areaCode.current = undefined
          phoneType1.current = undefined
          emailType.current = 3
        } else {
          Type.current = 1
          areaCode.current = '86'
          phoneType1.current = 4
          emailType.current = undefined
        }
      }
    }
  }, [usernamePhone])
  // 绑定验证码实例函数。该函数为固定写法，无需修改
  function getInstance(instance) {
    captcha = instance
  }
  const captchaVerifyCallback = async (captchaVerifyParam) => {
    // if (GetQueryValue('activeTab') == 'account') {
    //   return
    // } else {
    const result = await new Promise((resolve, reject) => {
      request
        .post('/api/api-sso/login/aliSmsCode', {
          captchaVerifyParam: captchaVerifyParam,
          username: PhoneNumber.current, //手机号/邮箱/用户名
          userType: Type.current, //请求类型（1-手机发送验证码、2-邮箱发送验证码、3、用户名密码登录））
          areaCode: areaCode.current,
          phoneType: phoneType1.current,
          emailType: emailType.current,
        })
        .then((res) => {
          console.log(res.data)
          // 假设你的结果中的数据包含在 res.data 中
          const result = res.data
          resolve({
            captchaResult: res.data.data.captchaVerifyResult,
            bizResult: result.success,
          })
        })
        .catch((error) => {
          console.log(error.response)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: error.response.data.message,
          })
          resolve({
            captchaResult: true,
            bizResult: error.response.status == 200 ? true : false,
            errorMessage: error.response.data.message,
          })
        })
    })
    // 2.构造标准返回参数
    console.log(result)
    const verifyResult = {
      // captchaResult: result.captchaVerifyResult, // 验证码验证是否通过，boolean类型，必选
      captchaResult: true, // 验证码验证是否通过，boolean类型，必选
      bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
    }
    return verifyResult
    // }
  }

  const onBizResultCallback = (bizResult) => {
    onSuccessCallback(bizResult)
  }

  return (
    <div>
      {/* 在需要显示验证码的地方 */}
      <div id="captcha-element"></div>
    </div>
  )
}

export default CaptchaComponent
