import React, { useEffect } from 'react'
import styles from './style/index.module.less'
import { GetQueryValue } from '@/utils/common'
import { Spin } from '@arco-design/web-react'
import request from '@/utils/request'

function MiniPhoneGo(props) {
  useEffect(() => {
    localStorage.setItem('userStatus', 'login')
    // document.cookie = 'access_token=' + GetQueryValue('token')
    // window.location.href = '/toolbox/index'
    // window.location.href = '/userCenter'
  }, [])

  request
    .post('/api/api-sso/login/getUserPhone', {
      encryptedData: GetQueryValue('encryptedData'),
      iv: GetQueryValue('iv'),
      userToken: GetQueryValue('userToken'),
    })
    .then((res) => {
      localStorage.setItem('userStatus', 'login')
      window.location.href = '/toolbox/index'
    })
  return (
    <div className={styles.loginBox}>
      <Spin tip="登录中..." loading></Spin>
    </div>
  )
}

export default MiniPhoneGo
