import React, { useEffect, useRef, useState } from 'react'
import styles from '../templatePage/style/tem-form-mod.module.less'
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Tooltip,
  Radio,
  Message,
} from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import { FormInstance } from '@arco-design/web-react/es/Form'
import IconFromValue from '@/assets/newImgs/form-value.svg'
import {
  IconArrowDown,
  IconArrowUp,
  IconErrorTip,
} from '@arco-iconbox/react-aidb-v2'
import request from '@/utils/request'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { getChatGptSave } from '@/model/reference'
import { useHistory } from 'react-router-dom'
import { showModal } from '@/share/actions'

const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea

function WriteFormMod(props) {
  const {
    onUpdateCurStep,
    templateData,
    jumpTo,
    onSendSuccessChange,
    onSetAllLoading,
    onResult,
    theme,
    membershipType,
    uid,
  } = props
  const curStep = props.curStep
  const formRef = useRef<FormInstance>()
  const avdenRef = useRef<FormInstance>()
  const dataRef = useRef<any>()
  const heightdataRef = useRef<any>()
  const [editTexValue, setEditTexValue] = useState({})
  const text = useRef('')
  const textQ = useRef('')
  const [conversationId, setConversationId] = useState('')
  const [messageId, setMessageId] = useState('')
  const [isCollapsed, setIsCollapsed] = useState(true)
  const history = useHistory()

  useEffect(() => {
    events.on(ACTIONS.TEM_SENDMSG, temSendMsg)

    return () => {
      events.off(ACTIONS.TEM_SENDMSG, temSendMsg)
    }
  })
  useEffect(() => {
    events.on(ACTIONS.TEMALLMARKDOWN, markdownSend)

    return () => {
      events.off(ACTIONS.TEMALLMARKDOWN, markdownSend)
    }
  })

  function markdownSend() {
    const title = localStorage.getItem('selectTitle')
    const description = localStorage.getItem('selectIntro')
    const outline = localStorage.getItem('selectOutLine')
    avdenRef.current.validate().then(async (values) => {
      heightdataRef.current = values

      const mergedData = {
        title,
        description,
        outline,
      }
      const mergedData1 = {
        ...heightdataRef.current,
      }
      const baseText = templateData.step[4].prompt
      const baseText1 = templateData.Heightprompt
      // 使用正则表达式匹配`${}`格式的内容，并替换成相应的变量值
      const replacedText = baseText.replace(
        /\$\{([^}]+)\}/g,
        (match, placeholder) => {
          // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
          return mergedData[placeholder] || '' // 如果找不到对应的值，用空字符串替换
        },
      )
      const replacedText1 = baseText1.replace(
        /\$\{([^}]+)\}/g,
        (match, placeholder) => {
          // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
          return mergedData1[placeholder] || '' // 如果找不到对应的值，用空字符串替换
        },
      )
      textQ.current =
        replacedText +
        replacedText1.replace(
          /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu,
          '',
        )
      doApi(5)
      console.log(textQ.current)
    })
  }
  function temSendMsg(payload) {
    formRef.current.validate().then(async (values) => {
      dataRef.current = values
      console.log(dataRef.current)

      const text_more = editTexValue //创作内容
      const title = localStorage.getItem('selectTitle')
      const description = localStorage.getItem('selectIntro')
      const outline = localStorage.getItem('selectOutLine')
      avdenRef.current.validate().then(async (values) => {
        heightdataRef.current = values

        const mergedData = {
          ...dataRef.current,
          ...text_more,
          title,
          description,
          outline,
        }
        const mergedData1 = {
          ...heightdataRef.current,
        }
        const baseText = templateData.step[payload.curId].prompt
        const baseText1 = templateData.Heightprompt
        // 使用正则表达式匹配`${}`格式的内容，并替换成相应的变量值
        const replacedText = baseText.replace(
          /\$\{([^}]+)\}/g,
          (match, placeholder) => {
            // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
            return mergedData[placeholder] || '' // 如果找不到对应的值，用空字符串替换
          },
        )
        const replacedText1 = baseText1.replace(
          /\$\{([^}]+)\}/g,
          (match, placeholder) => {
            // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
            return mergedData1[placeholder] || '' // 如果找不到对应的值，用空字符串替换
          },
        )
        textQ.current =
          replacedText +
          replacedText1.replace(
            /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu,
            '',
          )
        doApi(payload.curId + 1)
        console.log(replacedText)
      })
    })
  }
  //提交表单
  function clickSubmit() {
    formRef.current.validate().then(async (values) => {
      dataRef.current = values
      console.log(dataRef.current)

      const text_more = editTexValue //创作内容
      avdenRef.current.validate().then(async (values) => {
        heightdataRef.current = values
        const title = localStorage.getItem('selectTitle')
        const mergedData = {
          ...dataRef.current,
          ...text_more,
          title,
        }
        const mergedData1 = { ...heightdataRef.current }
        const baseText =
          templateData.step[curStep == 0 || curStep == 1 ? 0 : curStep - 1]
            .prompt
        const baseText1 = templateData.Heightprompt
        // 使用正则表达式匹配`${}`格式的内容，并替换成相应的变量值
        const replacedText = baseText.replace(
          /\$\{([^}]+)\}/g,
          (match, placeholder) => {
            // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
            return mergedData[placeholder] || '' // 如果找不到对应的值，用空字符串替换
          },
        )
        const replacedText1 = baseText1.replace(
          /\$\{([^}]+)\}/g,
          (match, placeholder) => {
            // match 为完整匹配的占位符，placeholder 为去除 ${} 后的占位符内容
            return mergedData1[placeholder] || '' // 如果找不到对应的值，用空字符串替换
          },
        )
        textQ.current =
          replacedText +
          replacedText1.replace(
            /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu,
            '',
          )
        doApi(curStep == 0 || curStep == 1 ? 1 : curStep)
        console.log(replacedText)
      })
    })
  }

  //发送请求
  const [sendSuccess, setSendSuccess] = useState(true)
  let suspend = false
  function catchGPTError(tooltip) {
    setSendSuccess(true)
    onSendSuccessChange(true)
    onResult(true)
    events.emit(ACTIONS.TEM_OUTLINEBTN, 'true')
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: tooltip,
    })
  }

  function codeError503() {
    setSendSuccess(true)
    onSendSuccessChange(true)
    onResult(true)
    events.emit(ACTIONS.TEM_OUTLINEBTN, 'true')
  }
  function doApi(curId) {
    if (curId == 3) {
      events.emit(ACTIONS.TEM_OUTLINEBTN, 'false')
    }
    console.log(curId)
    let scrolled = false
    let tmpText = ''
    setSendSuccess(false)
    onSetAllLoading(false)
    onSendSuccessChange(false)
    onResult(false)

    const opts = {
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: textQ.current,
        conversationId: '',
        parentMessageId: '',
        stream: true,
        type: curId == 4 || curId == 5 ? 4 : '',
      }),
    }
    //标题简介走原生接口不推流
    if (curId == 1 || curId == 2) {
      request
        .post(
          process.env.REACT_APP_CHATGPT_URL +
            (curId == 5 || curId == 4
              ? '/textStreamGPT'
              : membershipType == 2
              ? '/streamMessageGPT4'
              : '/streamMessage'),
          JSON.stringify({
            message: textQ.current,
            // message: '你好',
            conversationId: '',
            parentMessageId: '',
            stream: false,
            type: curId == 4 || curId == 5 ? 4 : '',
          }),
        )
        .then((res) => {
          console.log(res.data)

          const error = res.data
          // todo 流控等提示
          if (error.conversationId == undefined) {
            // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

            // 异常预处理
            if (error.data.text == '[TOO_MANY_REQUESTS]') {
              catchGPTError('请勿频繁请求#00-3')
              return
            }

            if (error.data.text == '[OUT_OF_3H_FLOW]') {
              // 获取当前时间
              const currentTime = new Date()

              // 计算3小时后的时间
              const retryTime = new Date(
                currentTime.getTime() + 3 * 60 * 60 * 1000,
              )

              // 格式化时间
              const hours = retryTime.getHours().toString().padStart(2, '0')
              const minutes = retryTime.getMinutes().toString().padStart(2, '0')

              // 创建新的错误信息
              const newErrorMessage = `对话次数已经达到上限，请在 ${hours}:${minutes} 后重试。`

              catchGPTError(newErrorMessage)
              return
            }

            if (error.data.text == '[OUT_OF_MIN_FLOW]') {
              catchGPTError(
                '1分钟内免费次数已用完，请稍后再试或开通会员#00-1#00-3',
              )
              setTimeout(() => {
                showModal('VipContentModal')
                window.location.hash = 'vip'
              }, 1000)
              return
            }

            if (error.data.text == '[OUT_OF_DAY_FLOW]') {
              catchGPTError('今日免费次数已用完，请明日再试或开通会员#00-2')
              setTimeout(() => {
                showModal('VipContentModal')
                window.location.hash = 'vip'
              }, 1000)
              return
            }

            if (error.data.text == '[OUT_OF_MIN_FLOW_VIP]') {
              catchGPTError('1分钟内 VIP 次数已用完，请稍后再试#00-3')
              return
            }

            if (error.data.text == '[OUT_OF_DAY_FLOW_VIP]') {
              catchGPTError('今日 VIP 次数已用完，请明日再试#00-4')
              return
            }

            if (error.data.text == '[ERROR]' && error.data.id == 0) {
              catchGPTError('描述含有敏感词，请调整描述语句后再试#01-3')
              return
            }
            //备用
            if (error.data.text == '[ERROR]') {
              catchGPTError('接口返回 错误，请稍后再试#01-2')
              return
            }
          } else {
            setTimeout(() => {
              suspend = false
            }, 10)
            setSendSuccess(true)
            onSendSuccessChange(true)
            onResult(true)
            jumpTo(curId, 'form')
            onUpdateCurStep(res.data.response || res.data.result)
          }
        })
        .catch((error) => {
          console.log(error.response)
          if (error.response && error.response.status === 500) {
            // 只在状态码为500时触发错误提示
            catchGPTError('接口连接错误,请重新尝试#012')
            setSendSuccess(true)
            onSendSuccessChange(true)
            onResult(true)
          }
        })
    } else {
      try {
        const controller = new AbortController()
        const eventSource = fetchEventSource(
          process.env.REACT_APP_CHATGPT_URL +
            (curId == 5 || curId == 4
              ? '/textStreamGPT'
              : membershipType == 2
              ? '/streamMessageGPT4'
              : '/streamMessage'),
          {
            ...opts,
            signal: controller.signal,
            onclose() {
              catchGPTError('服务器连接错误,请重新尝试#01')
              throw new Error(
                `Failed to send message. Server closed the connection unexpectedly.`,
              )
            },
            onerror(error) {
              console.log(error)

              // 服务器或网络错误处理
              // Message.error('接口返回错误，请重试。如多次失败请尝试重新登录#10');
              request
                .post(
                  process.env.REACT_APP_CHATGPT_URL +
                    (curId == 5 || curId == 4
                      ? '/textStreamGPT'
                      : membershipType == 2
                      ? '/streamMessageGPT4'
                      : '/streamMessage'),
                  JSON.stringify({
                    message: textQ.current,
                    // message: '你好',
                    conversationId: '',
                    parentMessageId: '',
                    stream: true,
                    type: curId == 4 || curId == 5 ? 4 : '',
                  }),
                )
                .then((res) => {
                  // const { success, error, message } = res.data;
                  setTimeout(() => {
                    suspend = false
                  }, 10)

                  const error = res.data
                  // todo 流控等提示
                  if (error?.data?.text) {
                    // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                    // 异常预处理
                    if (error.data.text == '[TOO_MANY_REQUESTS]') {
                      catchGPTError('请勿频繁请求#00-3')
                      return
                    }

                    if (error.data.text == '[OUT_OF_3H_FLOW]') {
                      // 获取当前时间
                      const currentTime = new Date()

                      // 计算3小时后的时间
                      const retryTime = new Date(
                        currentTime.getTime() + 3 * 60 * 60 * 1000,
                      )

                      // 格式化时间
                      const hours = retryTime
                        .getHours()
                        .toString()
                        .padStart(2, '0')
                      const minutes = retryTime
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')

                      // 创建新的错误信息
                      const newErrorMessage = `对话次数已经达到上限，请在 ${hours}:${minutes} 后重试。`

                      catchGPTError(newErrorMessage)
                      return
                    }

                    if (error.data.text == '[OUT_OF_MIN_FLOW]') {
                      catchGPTError(
                        '1分钟内免费次数已用完，请稍后再试或开通会员#00-1#00-3',
                      )
                      setTimeout(() => {
                        showModal('VipContentModal')
                        window.location.hash = 'vip'
                      }, 1000)
                      return
                    }

                    if (error.data.text == '[OUT_OF_DAY_FLOW]') {
                      catchGPTError(
                        '今日免费次数已用完，请明日再试或开通会员#00-2',
                      )
                      setTimeout(() => {
                        showModal('VipContentModal')
                        window.location.hash = 'vip'
                      }, 1000)
                      return
                    }

                    if (error.data.text == '[OUT_OF_MIN_FLOW_VIP]') {
                      catchGPTError('1分钟内 VIP 次数已用完，请稍后再试#00-3')
                      return
                    }

                    if (error.data.text == '[OUT_OF_DAY_FLOW_VIP]') {
                      catchGPTError('今日 VIP 次数已用完，请明日再试#00-4')
                      return
                    }
                    if (error.data.text == '[ERROR]' && error.data.id == 0) {
                      catchGPTError('描述含有敏感词，请调整描述语句后再试#01-3')
                      return
                    }
                    //备用
                    if (error.data.text == '[ERROR]') {
                      catchGPTError('接口返回错误，请稍后再试#01-2')
                      return
                    }
                  }
                })
              // catchGPTError('服务器连接错误,请重新尝试#02')
              throw error
            },
            onmessage(message) {
              console.log(message)
              if (
                message.event == 'error' &&
                message.data ==
                  '{"code":503,"error":"There was an error communicating with ChatGPT."}'
              ) {
                codeError503()
                return
              }
              if (curId == 4 || curId == 5) {
                events.emit(ACTIONS.TEM_SCROLLTOP)
              }
              // 倒数第二句话，即全文
              // 等待解决 workaround，早晚摘除 message.data !== '[DONE]' 后重新捋逻辑
              if (message.event == 'result' && message.data !== '[DONE]') {
                tmpText = JSON.parse(message.data).response
              } else {
                tmpText =
                  message.data == '[DONE]'
                    ? tmpText
                    : tmpText +
                      message.data.substring(1, message.data.length - 1)
              }

              text.current = tmpText.replaceAll('\\n', ' \n ')

              if (curId == 4 || curId == 5) {
                if (
                  text.current.includes(
                    'code":503,"error":"There was an error communicating with ChatGPT.',
                  )
                ) {
                  codeError503()
                  return
                } else {
                  setSendSuccess(true)
                  onSendSuccessChange(true)
                  jumpTo(4, 'form')
                  onUpdateCurStep(text.current)
                }
              } else if (curId == 3) {
                if (
                  text.current.includes(
                    'code":503,"error":"There was an error communicating with ChatGPT.',
                  )
                ) {
                  codeError503()
                  return
                } else {
                  setSendSuccess(true)
                  onSendSuccessChange(true)
                  jumpTo(curId, 'form')
                  onUpdateCurStep(text.current)
                }
              }

              if (message.data == '[ERROR]') {
                catchGPTError('接口返回错误，请稍后重试#02')
                return
              }

              console.log(message)

              // 倒数第二条时给 done 逻辑
              if (message.event == 'result' || message.data == '[DONE]') {
                console.log(curId, '走你')
                onResult(true)

                if (curId == 3) {
                  events.emit(ACTIONS.TEM_OUTLINEBTN, 'true')
                }
                setSendSuccess(true)
                console.log('成功')
                onSetAllLoading(true)
                controller.abort()
                setTimeout(() => {
                  // 加载完最终滚动一次
                  if (curId == 4 || curId == 5) {
                    events.emit(ACTIONS.TEM_SCROLLTOP)
                  }
                }, 200)
                // 结束时再设置相关 id
                const tmpJson = JSON.parse(message.data)
                if (tmpJson.conversationId) {
                  setConversationId(tmpJson.conversationId)
                }

                if (tmpJson.messageId) {
                  setMessageId(tmpJson.messageId)
                }
                console.log(tmpJson, 'tmpJson')

                if (curId == 4 || curId == 5) {
                  console.log('---------')

                  getChatGptSave({
                    conversationId: tmpJson.conversationId,
                    input: textQ.current,
                    messageId: tmpJson.messageId,
                    model: 'gpt-3.5',
                    output: text.current,
                    type: 'openai',
                  })
                }
                if (
                  text.current.includes(
                    'code":503,"error":"There was an error communicating with ChatGPT.',
                  )
                ) {
                  codeError503()
                  return
                } else {
                  if (curId == 3 || curId == 4 || curId == 5) {
                    return
                  } else {
                    setSendSuccess(true)
                    onSendSuccessChange(true)
                    jumpTo(curId, 'form')
                    onUpdateCurStep(tmpJson.response)
                  }
                }
                setSendSuccess(true)
                onSendSuccessChange(true)

                return
              } else if (message.data !== '[DONE]') {
                // 倒数第二条之前给 stream 逻辑
                if (!scrolled) {
                  if (curId == 4 || curId == 5) {
                    events.emit(ACTIONS.TEM_SCROLLTOP)
                  }
                  scrolled = true
                }
              }
            },
          },
        )
      } catch (err) {
        catchGPTError('接口发生错误或超时未响应，请稍后重试#05')
        setSendSuccess(true)
        onSendSuccessChange(true)
        onSetAllLoading(true)
        onResult(true)
        if (curId == 3) {
          events.emit(ACTIONS.TEM_OUTLINEBTN, 'true')
        }
      }
    }
  }

  // 点击按钮触发展开/折叠
  const togglePanel = () => {
    setIsCollapsed(!isCollapsed)
  }

  // 高级设置默认值
  const initialValues = {}
  templateData.Heightcommon.forEach((field) => {
    initialValues[field.name] = field.default
  })

  // 渲染表单默认值
  let FrominitialValues = {}
  templateData.step[0].field.forEach((item) => {
    if (item.type === 'select') {
      FrominitialValues[item.name] = item.default_value
    } else {
      FrominitialValues[item.name] = ''
    }
  })

  // 点击提示按钮设置默认值
  function handleInputDefault(e) {
    formRef.current.setFieldValue(e.name, e.default_value)
  }
  // 柯里化函数，接收 field 作为参数，返回处理默认值的函数
  const handleButtonClickCurried = (field) => () => {
    console.log(field) // 这里可以访问到 field
    console.log(field.default_value) // 这里可以访问到默认值

    // 在这里处理设置默认值的逻辑
    setEditTexValue((prevValues) => ({
      ...prevValues,
      [field.name]: field.default_value,
    }))
  }

  return (
    <div className={`${styles.write_form} ${styles[theme]}`}>
      <div
        className={`${curStep >= 1 ? styles.pb : ''}  ${styles.content_wrap}`}>
        <div className={styles.content}>
          <div className={styles.wp}>
            <div className={styles.title}>
              {templateData.step[curStep].name}
            </div>
            {templateData && (
              <Form
                layout={'vertical'}
                size={'large'}
                requiredSymbol={{ position: 'end' }}
                ref={formRef}
                initialValues={FrominitialValues}>
                {templateData.step[0]?.field?.map((field) => (
                  <Form.Item
                    tooltip={field.info}
                    key={field.filed_name}
                    label={field.filed_name}
                    field={field.name}
                    rules={
                      field.required
                        ? [
                            {
                              required: true,
                              message: `请输入${field.filed_name}`,
                            },
                          ]
                        : []
                    }>
                    {field.type === 'input' ? (
                      <Input
                        style={{ paddingTop: '8px', paddingBottom: '8px' }}
                        placeholder={field.placeholder}
                        suffix={
                          <Tooltip content="示例">
                            <div
                              className={`${styles['renderIcon']} ${styles['renderIconIpt']}`}
                              onClick={() => handleInputDefault(field)}>
                              <IconFromValue />
                            </div>
                          </Tooltip>
                        }
                      />
                    ) : field.type === 'textarea' ? (
                      <div style={{ position: 'relative' }}>
                        <Input.TextArea
                          value={editTexValue[field.name] || ''}
                          style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            minHeight: 100,
                            paddingRight: '42px',
                          }}
                          autoSize
                          placeholder={field.placeholder}
                          onChange={(e) => {
                            setEditTexValue({
                              ...editTexValue,
                              [field.name]: e,
                            })
                          }}
                        />
                        <Tooltip content="示例">
                          <div
                            className={`${styles['renderIcon']} ${styles['renderIconTex']}`}
                            onClick={handleButtonClickCurried(field)}>
                            <IconFromValue />
                          </div>
                        </Tooltip>
                      </div>
                    ) : field.type === 'select' ? (
                      <Select>
                        {' '}
                        {/* Set the default value here */}
                        {field.value.split('\r\n').map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : null}
                  </Form.Item>
                ))}
              </Form>
            )}
            {/* 高级设置 */}
            {templateData.is_use_common_field ? (
              <div>
                <div className={styles['panel']} onClick={togglePanel}>
                  <span className={styles['left']}>高级设置</span>
                  <span className={styles['right']}>
                    {isCollapsed ? <IconArrowDown /> : <IconArrowUp />}
                  </span>
                </div>
                <div
                  className={`${styles['panel-content']} ${
                    isCollapsed ? styles['open'] : ''
                  }`}>
                  <Form
                    layout={'vertical'}
                    size={'large'}
                    requiredSymbol={{ position: 'end' }}
                    initialValues={initialValues}
                    ref={avdenRef}>
                    {templateData.Heightcommon.map((field) => (
                      <Form.Item
                        key={field.filed_name}
                        label={field.filed_name}
                        field={field.name}
                        rules={
                          field.required
                            ? [
                                {
                                  required: true,
                                  message: `请输入${field.filed_name}`,
                                },
                              ]
                            : []
                        }>
                        {field.type === 'radio' ? (
                          <Radio.Group>
                            {field.value.split('\r\n').map((option) => (
                              <Radio key={option} value={option}>
                                {option}
                              </Radio>
                            ))}
                          </Radio.Group>
                        ) : field.type === 'select' ? (
                          <Select>
                            {field.value.split('\r\n').map((option) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        ) : null}
                      </Form.Item>
                    ))}
                  </Form>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={`${styles.bot_btn} ${styles.poss}`}>
              <div
                className={`${styles['wp']} ${
                  !sendSuccess ? styles['disable'] : ''
                }`}>
                <div className={styles.btn}>
                  <BorderBtn
                    onClick={() => {
                      clickSubmit()
                    }}
                    theme={theme}
                    gradient={curStep == 0}
                    color={curStep == 1 || curStep == 2 || curStep == 3}
                    size={'big'}
                    text={
                      sendSuccess ? (
                        curStep === 0 ? (
                          '生成标题'
                        ) : curStep === 1 ? (
                          '生成更多标题'
                        ) : curStep === 2 ? (
                          '生成更多简介'
                        ) : curStep === 3 ? (
                          '生成更多大纲'
                        ) : (
                          ''
                        )
                      ) : (
                        <div
                          className={`${styles['loading']} ${
                            curStep == 1 || curStep == 2 || curStep == 3
                              ? styles['isMore']
                              : ''
                          }`}>
                          努力加载中
                          <div className={styles['loading-poi']}></div>
                          <div className={styles['loading-poi']}></div>
                          <div className={styles['loading-poi']}></div>
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WriteFormMod
