import React, { useEffect, useState } from 'react'
import styles from './style/text-mod.module.less'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { GetQueryValue } from '@/utils/common'
import { IconArrowRight } from '@arco-iconbox/react-aidb-v2'
import AgentImg from '@/assets/newImgs/aiagent.svg'
function TextMod(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  const { initialValues, isAgentInit } = props
  const [propsIsAgent, setPropsIsAgent] = useState(isAgentInit)
  useEffect(() => {
    setPropsIsAgent(isAgentInit)
  }, [props])

  return (
    <>
      {propsIsAgent && propsIsAgent ? (
        <div className={`${styles['contenPrewView']} ${styles[theme]}`}>
          <div className={styles['contentWarp']}>
            <div className={styles['avatar']}>
              {initialValues.faceImg ? (
                <img src={initialValues.faceImg} alt="" />
              ) : (
                <AgentImg />
              )}
            </div>
            <div className={styles['text']}>
              <div className={styles['title']}>{initialValues.title}</div>
              <div className={styles['desc']}>{initialValues.info}</div>
            </div>
          </div>
        </div>
      ) : (
        ''
        // <div className={`${styles['text']} ${styles[theme]}`}>
        //   <div className={styles['title']}>欢迎与智能助理聊天</div>
        //   <div className={styles['desc']}>
        //     您可以从输入您的需求开始，通过聊天来完成您的工作。
        //   </div>
        //   <div className={styles['tips']}>
        //     您可以点击下方的示例进行体验，或者点击左下方的指令库以获取灵感。
        //   </div>
        // </div>
      )}
    </>
  )
}

export default TextMod
