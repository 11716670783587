import React, { useEffect, useState } from 'react'
import styles from './style/index.module.less'
import { Menu, Popover, Tooltip } from '@arco-design/web-react'
import {
  IconArrowLeft,
  IconBaseSync,
  IconBell,
  IconGift,
} from '@arco-iconbox/react-aidb-v2'
import IconVip1 from '../../assets/imgs/vip-head1.svg'
import IconVip2 from '../../assets/imgs/vip-head2.svg'
import IconFace1 from '../../assets/imgs/avatr.svg'
import IconFace2 from '../../assets/newImgs/black-face.svg'
import BreadItem from '../BreadItem'
import IconEmptyCard from '@/assets/newImgs/emptyCard.svg'
import IconMonth from '@/assets/newImgs/monthCard.svg'
import IconSeaon from '@/assets/newImgs/SeasonCard.svg'
import IconYear from '@/assets/newImgs/yearCard.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { GlobalState } from '../../store'
import request from '@/utils/request'
import useStorage from '@/utils/useStorage'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import UserAvatar from '@/assets/UserAvatar'
function Header(props) {
  const [arrowLeft, setArrowLeft] = useState('')
  const [_, setUserStatus] = useStorage('userStatus')
  const theme = useSelector((state: GlobalState) => state.theme)
  const dispatch = useDispatch()
  const history = useHistory()
  const locationSearch = useLocation()
  useEffect(() => {
    if (location.href.includes('userCenter')) {
      setArrowLeft('user')
    } else if (
      location.href.includes('agentMylist') ||
      location.href.includes('agentPrewView')
    ) {
      setArrowLeft('agent')
    } else if (location.href.includes('intelligentDocument')) {
      if (location.search === '') {
        setArrowLeft('intelligentDocument-search')
      } else {
        setArrowLeft('intelligentDocument')
      }
    } else {
      setArrowLeft('')
    }
    if (
      localStorage.getItem('tabSwitch') == 'tree' &&
      location.pathname == '/intelligentDocument'
    ) {
      setArrowLeft('')
    }
  }, [locationSearch, localStorage.getItem('tabSwitch')])

  function onMenuItemClick(key) {
    event.preventDefault()
    if (key === 'logout') {
      setUserStatus('logout')
      axios
        .get(`/api-sso/login/loginOut?timestamp=${new Date().getTime()}`, {
          headers: {
            Cookie: `access_token=${document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
              '$1',
            )}`,
          },
        })
        .then((res) => {
          localStorage.removeItem('templateData')
          localStorage.removeItem('selectTem')
          document.cookie =
            'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          history.push('/login')
        })
    } else if (key === 'account') {
      history.push('/userCenter?activeTab=account')
    } else if (key === 'membership') {
      history.push('/userCenter?activeTab=membership')
    } else if (key === 'order') {
      history.push('/userCenter?activeTab=order')
    }
  }
  let iconComponent
  let vipLevel = localStorage.getItem('vipLevel')
  if (vipLevel == '1') {
    iconComponent = (
      <a href="/userCenter?activeTab=membership">
        <IconMonth onClick={() => onMenuItemClick('membership')} />
      </a>
    )
  } else if (vipLevel == '2') {
    iconComponent = (
      <a href="/userCenter?activeTab=membership">
        <IconYear onClick={() => onMenuItemClick('membership')} />
      </a>
    )
  } else {
    iconComponent = (
      <a href="/userCenter?activeTab=membership">
        <IconEmptyCard onClick={() => onMenuItemClick('membership')} />
      </a>
    )
  }
  //下拉菜单渲染结构
  const btn3_content = (
    <div className={styles['btn-popover-list']}>
      <div
        key="membership"
        className={`${styles['item']} ${styles['itemBefor']}`}>
        <div className={styles['vipItem']}>
          {localStorage.getItem('userName')}
          {iconComponent}
        </div>
        <div className={styles['vipItem1']}>
          我的会员：
          {localStorage.getItem('vipEndDate') != 'null'
            ? localStorage.getItem('vipEndDate')
            : '暂未开通'}
        </div>
      </div>
      <a
        href="/userCenter?activeTab=account"
        key="account"
        style={{ display: 'block' }}
        className={`${styles['item']}`}
        onClick={() => onMenuItemClick('account')}>
        个人中心
      </a>
      <a
        href="/userCenter?activeTab=order"
        key="order"
        style={{ display: 'block' }}
        className={`${styles['item']} `}
        onClick={() => onMenuItemClick('order')}>
        我的订单
      </a>
      <div
        key="logout"
        className={styles['item']}
        onClick={() => onMenuItemClick('logout')}>
        退出登录
      </div>
    </div>
  )

  const handleToAgent = () => {
    dispatch({
      type: 'update-agentTab',
      payload: { agentTabIndex: 'home' },
    })
    history.push(`/aiAgent`)
    setArrowLeft('')
  }
  const handleGoHome = () => {
    setArrowLeft('')
    history.push('/home')
  }
  const handleGo = () => {
    event.preventDefault()
    window.open('https://support.wuz.com.cn/award-research-form/')
  }
  return (
    <div className={`${styles['header']} ${styles[theme]}`}>
      {arrowLeft === 'user' ? (
        <div className={styles.back} onClick={handleGoHome}>
          <div className={styles.backIcon}>
            <IconArrowLeft />
          </div>
          <div className={styles.backText}>返回首页</div>
        </div>
      ) : (
        ''
      )}
      {arrowLeft === 'agent' ? (
        <div className={styles.back} onClick={handleToAgent}>
          <div className={styles.backIcon}>
            <IconArrowLeft />
          </div>
          <div className={styles.backText}>返回</div>
        </div>
      ) : (
        ''
      )}
      {arrowLeft === 'intelligentDocument' ? (
        <div className={styles.back}>
          {' '}
          <BreadItem></BreadItem>
        </div>
      ) : arrowLeft === 'intelligentDocument-search' ? (
        <div className={styles.back} style={{ display: 'none' }}>
          {' '}
          <BreadItem></BreadItem>
        </div>
      ) : (
        ''
      )}
      {/*同步更新*/}
      <div className={`${styles['headerRight']} `}>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }
          className={'custom-tooltip'}
          position="bottom"
          trigger="hover"
          content="同步更新">
          <div className={styles['icon']} onClick={() => location.reload()}>
            <IconBaseSync />
          </div>
        </Tooltip>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }
          className={'custom-tooltip'}
          position="bottom"
          trigger="hover"
          content="有奖调研">
          <a
            href="https://support.wuz.com.cn/award-research-form/"
            className={styles['icon']}
            onClick={handleGo}>
            <IconGift />
          </a>
        </Tooltip>
        <Popover
          className={styles[theme == 'dark' ? 'headPover' : '']}
          position="bottom"
          content={btn3_content}>
          <div className={styles['user']}>
            <a
              href="/userCenter?activeTab=account" // 链接地址
              className={styles['user']}
              onClick={() => onMenuItemClick('account')}>
              <div className={styles['face']}>
                <UserAvatar
                  name={localStorage.getItem('userName')}></UserAvatar>
              </div>
            </a>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default Header
