const onEnter = (el) => {
  el.style.transition = '0.3s max-height ease'
  el.style.maxHeight = '0px'
  el.style.overflow = 'hidden'
}

const onEntering = (el) => {
  el.style.maxHeight = el.scrollHeight + 'px'
}

const onEntered = (el) => {
  el.style.transition = ''
  el.style.maxHeight = ''
}

const onExit = (el) => {
  el.style.overflow = 'hidden'
  el.style.maxHeight = el.scrollHeight + 'px'
}

const onExiting = (el) => {
  if (el.scrollHeight !== 0) {
    el.style.transition = '0.3s max-height ease'
    el.style.maxHeight = '0px'
  }
}

const onExited = (el) => {
  el.style.transition = ''
  el.style.maxHeight = ''
}

export { onEnter, onEntering, onEntered, onExit, onExiting, onExited }
