import React from 'react'
import multiavatar from '@multiavatar/multiavatar'

const UserAvatar = (params) => {
  // 生成 SVG 代码并编码为 Base64
  const svgCode = multiavatar(params.name)
  const base64Data = btoa(svgCode)
  const svgUrl = `data:image/svg+xml;base64,${base64Data}`

  return <img src={svgUrl} alt="avatar" />
}

export default UserAvatar
