import React from 'react'
import styles from './styles/pick-item.module.less'
import { Message, Radio } from '@arco-design/web-react'
import { IconCheck } from '@arco-design/web-react/icon'
import copy from 'copy-to-clipboard'
import {
  IconCopy,
  IconEdit,
  IconErrorTip,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import IconBtn from '@/components/IconBtn'
function PickItem(props) {
  const { onEditTitle, onEditIntro, curStep, theme } = props
  function copyContent(inputContent) {
    copy(inputContent)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }
  function handleEdit() {
    if (curStep == 1) {
      onEditTitle(props.content + ' ')
    } else {
      onEditIntro(props.content + ' ')
    }
  }

  return (
    <div className={`${styles.item} ${styles[theme]}`}>
      <Radio value={props.content} checked={props.content === props.checked}>
        {({ checked }) => {
          return (
            <div className={`${styles.inner} ${checked ? styles.checked : ''}`}>
              <div className={styles.text}>{props.content}</div>
              <div className={styles.radioWrap}>
                <div className={styles.icon}>
                  <IconCheck />
                </div>
              </div>
            </div>
          )
        }}
      </Radio>
      <div className={styles.iconRightBtn}>
        <div className={styles.iconCopy}>
          {' '}
          <IconBtn
            text={'复制'}
            iconLeft={<IconCopy />}
            onClick={() => copyContent(props.content)}
          />
        </div>
        <div className={styles.iconCopy}>
          {' '}
          <IconBtn text={'编辑'} iconLeft={<IconEdit />} onClick={handleEdit} />
        </div>
      </div>
    </div>
  )
}

export default PickItem
