import React, { useState, useEffect, useRef } from 'react'
import {
  Message,
  Popover,
  Select,
  Tag,
  Button,
  Divider,
  Radio,
  Tooltip,
} from '@arco-design/web-react'
import { IconBook, IconStar, IconDelete } from '@arco-design/web-react/icon'
import {
  IconPlus,
  IconArrowUp,
  IconErrorTip,
  IconPlugIn,
} from '@arco-iconbox/react-aidb-v2'

import './style/pluginLibrary.less'

const ButtonGroup = Button.Group
const RadioGroup = Radio.Group
const Option = Select.Option

const PluginLibrary = (props) => {
  const { pluginName, setPluginName, pluginId, setPluginId } = props

  const [data, setData] = useState<Array<any>>([
    {
      title: '北京日报',
      id: 'bjrb',
      icon: require('../../assets/newImgs/bjrb.png'),
      tooltip:
        '您好，我是北京日报小助手，精通《北京日报》2000-2023年的所有内容，欢迎向我提问相关问题哦~',
    },
    {
      title: '光明日报',
      id: 'gmrb',
      icon: require('../../assets/newImgs/gmrb.png'),
      tooltip:
        '您好，我是光明日报小助手，精通《光明日报》1985-2023年的所有内容，欢迎向我提问相关问题哦~',
    },
    {
      title: '人民日报',
      id: 'rmrb',
      icon: require('../../assets/newImgs/rmrb.png'),
      tooltip:
        '您好，我是人民日报小助手，精通《人民日报》1946-2023年的所有内容，欢迎向我提问相关问题哦~',
    },
    {
      title: '经济日报',
      id: 'jjrb',
      icon: require('../../assets/newImgs/jjrb.png'),
      tooltip:
        '您好，我是经济日报小助手，精通《经济日报》2008-2023年的所有内容，欢迎向我提问相关问题哦~',
    },
    {
      title: '中国青年报',
      id: 'zgqnb',
      icon: require('../../assets/newImgs/qnrb.png'),
      tooltip:
        '您好，我是中国青年报小助手，精通《中国青年报》2005-2023年的所有内容，欢迎向我提问相关问题哦~',
    },
  ])

  return (
    <div className="pluginLibraryContent">
      <Popover
        className="pluginLibraryPopover"
        trigger="click"
        title={null}
        position="tl"
        content={
          <Select
            className="pluginLibrarySelect"
            dropdownMenuClassName="pluginLibrarySelect-dropdownMenu"
            placeholder="请选择插件..."
            showSearch={{ retainInputValue: true }}
            allowClear={true}
            autoWidth={true}
            bordered={false}
            defaultActiveFirstOption={false}
            mode="multiple"
            // size="large"
            arrowIcon={<IconArrowUp />}
            removeIcon={<IconDelete />}
            triggerProps={{ position: 'tl' }}
            // defaultValue={['Beijing']}
            value={pluginName && [pluginName]}
            onSelect={(title, menu) => {
              setPluginName(title)
              setPluginId(menu.extra.id)
            }}
            onDeselect={() => {
              setPluginName('')
              setPluginId('')
            }}
            onClear={() => {
              setPluginName('')
              setPluginId('')
            }}
            renderFormat={(option, value) => {
              return option ? (
                <span>
                  {/* <IconBook className="icon" /> */}
                  <img className="icon" src={option.extra.icon} alt="" />
                </span>
              ) : (
                value
              )
            }}
            renderTag={(props) => {
              const { label, value, closable, onClose } = props
              return (
                <Tag
                  className="pluginLibraryTag"
                  closable={closable}
                  onClose={onClose}>
                  {label}
                </Tag>
              )
            }}
            dropdownRender={(menu) => (
              <div className="pluginLibrarySelect-dropdownRender">
                <div className="pluginLibrarySelect-dropdownRender-header">
                  <span>
                    已选用{pluginName ? 1 : 0}/{data.length}个插件
                  </span>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className="pluginLibrarySelect-dropdownRender-foot">
                  {menu}
                </div>
              </div>
            )}>
            {data.map((item, index) => (
              <Option
                value={item.title}
                extra={{ id: item.id, icon: item.icon }}
                key={index}>
                <div className="optionOut">
                  <div className="iconOut">
                    <span
                      className="icon"
                      style={{
                        // backgroundImage: `url(${item.icon})`,
                        backgroundImage: `url(${item.icon})`,
                      }}></span>
                  </div>
                  <Tooltip content={item.tooltip}>
                    <span className="title">{item.title}</span>
                  </Tooltip>
                </div>
              </Option>
            ))}
            {/* <RadioGroup
              className="optionContent"
              onChange={(title, menu) => {
                setPluginName(title)
              }}>
              {data.map((item, index) => (
                <Radio value={item.title} key={index}>
                  <div className="optionOut">
                    <span className="icon">{item.icon}</span>
                    <span className="title">{item.title}</span>
                  </div>
                </Radio>
              ))}
            </RadioGroup> */}
          </Select>
        }>
        <ButtonGroup>
          <Button type="outline" icon={<IconPlugIn className="iconPlus" />}>
            插件库
          </Button>
          <Button type="outline" icon={<IconPlus className="iconPlus" />} />
        </ButtonGroup>
      </Popover>
    </div>
  )
}

export default PluginLibrary
