/**
 * 编辑器yjs协同配置
*/

// 协同权限
export const COLLABORATION_PERMISSION = {
  // 阅读权限
  read_permission: 3,
  // 编辑权限
  edit_permission: 2,
  // 管理权限
  control_permission: 1,
}