import React from 'react'
import styles from './style/prompt-textarea.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'

const TextArea = Input.TextArea

function PromptTextarea(props) {
  const { placeholder, title, tooltip, value, onChange, disable } = props

  const handleTextareaChange = (event) => {
    onChange(event)
  }

  return (
    <div>
      <PromptTitle
        title={title}
        tooltip={tooltip}
        showPlus={false}
        showMinus={false}
      />
      <div className={styles.textarea}>
        <TextArea
          className={'g-input'}
          placeholder={placeholder}
          value={value}
          disabled={disable}
          onChange={handleTextareaChange}
        />
      </div>
    </div>
  )
}

export default PromptTextarea
