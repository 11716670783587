import React, { useEffect, useRef, useState } from 'react'
import styles from './style/tem-item.module.less'
import { Input, Message } from '@arco-design/web-react'
import IconBtn from '../../components/IconBtn'
import {
  IconEdit,
  IconCopy,
  IconCheck,
  IconSuccessTip,
  IconErrorTip,
  IconGenerate,
  IconBrainMap,
} from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '../../components/BorderBtn'
import copy from 'copy-to-clipboard'
import IconAdd from '@/assets/imgs/i-add.svg'
import { saveDocument } from '@/model/reference'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Markdown from '../../components/Markdown'
import { GetQueryValue } from '@/utils/common'

const TextArea = Input.TextArea

function TemItem(props) {
  const { content, showBotButtons, theme, messageId } = props
  const markDownRef = useRef<any>()
  const [inputContent, setInputContent] = useState(content)
  const textareaRef = useRef(null)

  // 使用 useEffect 实现在 content 发生变化时更新 inputContent 的值
  React.useEffect(() => {
    setInputContent(content)
  }, [content])

  function inputChange(e) {
    setInputContent(e)
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight
    }
  }

  const [edit, setEdit] = React.useState(false)
  function copyContent(inputContent) {
    copy(inputContent)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  //添加文章到编辑器
  function handleAdd() {
    const markDownContent = markDownRef.current?.innerHTML // 获取 div 内容
    console.log(markDownContent)

    saveDocument({
      approvalStatus: 1,
      content: markDownContent,
      docStatus: 0,
      docType: 0,
      filePath: '/',
      fileType: 0,
      folderUuid: '',
      title: content.split('\n')[0],
      uuid: '',
      docVersionType: 1,
    })
      .then((res) => {
        if (res.data.success == true && res.data.data) {
          const newUrl = window.location.origin
          window.open(
            `${newUrl}/documentEdit?uuid=${res.data.data}&title=${
              content.split('\n')[0]
            }`,
          )
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '添加失败，请重新尝试',
          })
        }
      })
      .catch(() => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '添加失败，请重新尝试',
        })
      })
  }

  //ai添加全文脑图
  function handleAddAllText() {
    const newUrl = window.location.origin
    window.open(
      `${newUrl}/TemplatePageAll/TemAllText?id=715&tab=0&source=${messageId}`,
    )
  }
  function handleToMindMap() {
    window.open(`https://mind-map.v-dk.com/?source=${messageId}`)
  }
  return (
    <div className={`${styles.item} ${styles[theme]}`}>
      {/* <div className={`${styles.input} ${edit ? styles.edit : ''}`}>
        <Input.TextArea
          autoSize
          ref={textareaRef}
          className={'g-input'}
          disabled={!edit}
          // maxLength={9999}
          onChange={(e) => {
            inputChange(e)
          }}
          value={inputContent}
        />
      </div> */}
      <div
        className={`${styles.item} ${styles[theme]} ${styles.markdown}`}
        ref={markDownRef}>
        <Markdown theme={theme} content={content} isCodeCopyBtn={true} />
      </div>
      {showBotButtons ? (
        <div className={styles.bot}>
          <div className={styles.count}>{inputContent.length} 字</div>
          {!edit && (
            <div className={styles.btn}>
              {GetQueryValue('id') == '1050' && (
                <IconBtn
                  text={'一键扩写全文'}
                  iconLeft={<IconGenerate />}
                  onClick={handleAddAllText}
                />
              )}
              {GetQueryValue('id') == '1050' && (
                <IconBtn
                  text={'查看思维导图'}
                  iconLeft={<IconBrainMap />}
                  onClick={handleToMindMap}
                />
              )}
              <IconBtn
                text={'添加到编辑器'}
                iconLeft={<IconAdd />}
                onClick={handleAdd}
              />
              <IconBtn
                text={'复制'}
                iconLeft={<IconCopy />}
                onClick={() => copyContent(inputContent)}
              />
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default TemItem
