export enum ContentType {
  /** 文档 */
  doc = 0,

  /** 文件夹 */
  fold = 1,

  /** 助理 */
  assistant = 2,
}

export enum PageType {
  waste = 0,
  intelligentDoc = 1,
  collect = 2,
  collectEdit = 3,
}

export enum DocBtnType {
  /* 校对 */
  proof = 1,
  /* 改写 */
  rewrite = 2 || 22,
  /* 单个改写 */
  rewriteSingle = 22,
  /* 扩写 */
  continuation = null,
  /* 扩写 */
  continuationSingle = 33,
  /* 翻译 */
  translate = 4,
  /* 单个翻译 */
  translateSingle = 44,
  /* 素材 */
  material = 5,
  /* 收藏 */
  collect = 6,
  /* 审核意见 */
  comments = 7,
  /* 批注 */
  annotate = 8,
  // 助理
  assistant = 9,
  //工具
  tools = 10,
  //属性
  attribute = 11,
  //版本
  version = 12,
  //降重
  reduce = 13,
  //降重
  reduceSingle = 14,
  //指令
  directives = 15,
}

export enum DocVersionType {
  SilentSave = 0,
  AutoSave = 1,
  ManualSave = 2,
  PublishSave = 3,
}

export enum ApprovalStatus {
  /* 待提交 */
  ToSubmit = 1,
  /* 待审核 */
  PendingApproval = 2,
  /* 已审核 */
  Reviewed = 3,
}

export enum ToolsBtn {
  /* 待提交 */
  titleRecommend = 1,
  /* 待审核 */
  ReverseDic = 2,
  /* 据意查句 */
  SearchSentence = 3,
  /* 文本摘要 */
  TextSummary = 4,
  /* 文本标签 */
  TextLabel = 5,
  /* 关键词抽取 */
  KeywordObtain = 6,
}

export enum ProofType {
  puncProof = 0,
  grammarProof = 1,
  wordProof = 2,
}

export enum ProofOfficialType {
  lanAndTextProof = 0,
  termAndNounProof = 1,
  tactfulAndNormativeProof = 2,
}

export enum ProofTactfulType {
  commentProof = 'comment_detection',
  aiProof = 'ai_art_detection',
  adProof = 'ad_compliance_detection',
  multilLangProof = 'comment_multilingual_pro',
}

export enum CollectType {
  all = 0,
  summary = 1,
  essay = 2,
  governmentReport = 3,
  classicBook = 4,
  doc = 5,
  assistant = 6,
}

export enum DocHandleType {
  share = 0,
  addToCollect = 1,
  moveToWaste = 2,
  move = 3,
  createCopy = 4,
  rename = 5,
  addTags = 6,
}

export enum DropdownType {
  moreDown = 0,
  iconDown = 1,
  titleDown = 2,
}

export enum SortType {
  editTime = 0,
  createTime = 1,
}

export enum KeyMapType {
  basic = 0,
  paraAndText = 1,
  markdown = 2,
  advanced = 3,
}

export enum RewriteSelectType {
  onlyAdd = 0,
  onlyDel = 1,
  all = 2,
}

export enum ProofSelectType {
  basicProof = 0,
  officialProof = 1,
  tactfulProof = 2,
}

export enum TextStreamGPT {
  translate = 1,
  reduce = 2,
  continuation = 3,
  template = 4,
}

export enum EditWidthType {
  standard = 1,
  expand = 2,
}

export enum exportType {
  word = 1,
  txt = 2,
  markdown = 3,
  ppt = 4,
  html = 5,
  pdf = 6,
}

export enum CollectState {
  noCollect = 0,
  collected = 1,
}
