import auth, { AuthParams } from '@/utils/authentication'
import { useEffect, useMemo, useState } from 'react'

export type IRoute = AuthParams & {
  name: string
  key: string
  // 当前页是否展示面包屑
  breadcrumb?: boolean
  children?: IRoute[]
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean
}

export const routes: IRoute[] = [
  {
    name: '控制台',
    key: 'home',
  },
  {
    name: '智能文档',
    key: 'intelligentDocument',
  },
  {
    name: '智能体',
    key: 'aiAgent',
  },
  {
    name: '个人中心',
    key: 'personalCenter',
    children: [
      {
        name: '我的资料',
        key: 'personalCenter/user',
      },
      {
        name: '我的订单',
        key: 'personalCenter/orderList',
      },
    ],
  },
  {
    name: '工具',
    key: 'toolbox',
    children: [
      {
        name: '写作助理',
        key: 'toolbox/index',
      },
    ],
  },
  {
    name: 'vip',
    key: '/vip',
  },
  {
    name: '写作',
    key: 'writing',
  },

  {
    name: '智能助理（新）',
    key: 'chatGpt',
  },
  {
    name: '个人',
    key: 'userCenter',
  },
  {
    name: '消息通知',
    key: 'message',
  },
  {
    name: '收藏夹',
    key: 'collect',
  },
  {
    name: '废纸篓（新）',
    key: 'waste',
  },

  {
    name: '系统管理',
    key: 'systemManagement',
    children: [
      {
        name: '菜单管理',
        key: 'systemManagement/menuManagement',
      },
      {
        name: '角色管理',
        key: 'systemManagement/roleManagement',
      },
      {
        name: '员工管理',
        key: 'systemManagement/employeeManagement',
      },
    ],
  },

  {
    name: '模板库',
    key: 'templatePage/GolbalTem',
  },
]
export const miniPhoneroutes: IRoute[] = [
  {
    name: '工具',
    key: 'toolbox',
    children: [
      {
        name: '写作助理',
        key: 'toolbox/index',
      },
    ],
  },
  {
    name: '控制台',
    key: 'home',
  },
  {
    name: '智能文档',
    key: 'intelligentDocument',
  },
  {
    name: '个人中心',
    key: 'personalCenter',
    children: [
      {
        name: '我的资料',
        key: 'personalCenter/user',
      },
      {
        name: '我的订单',
        key: 'personalCenter/orderList',
      },
    ],
  },
  // {
  //   name: '文献参考',
  //   key: 'reference',
  // },
  // {
  //   name: '文献详情页',
  //   key: 'referenceDetail/:referenceId',
  // },
  // {
  //   name: '消息通知',
  //   key: 'message',
  // },
  {
    name: 'vip',
    key: '/vip',
  },
  {
    name: '写作',
    key: 'writing',
  },

  {
    name: '智能助理（新）',
    key: 'chatGpt',
  },
  {
    name: '个人',
    key: 'userCenter',
  },
  {
    name: '消息通知',
    key: 'message',
  },
  {
    name: '收藏夹',
    key: 'collect',
  },
  {
    name: '废纸篓（新）',
    key: 'waste',
  },

  {
    name: '系统管理',
    key: 'systemManagement',
    children: [
      {
        name: '菜单管理',
        key: 'systemManagement/menuManagement',
      },
      {
        name: '角色管理',
        key: 'systemManagement/roleManagement',
      },
      {
        name: '员工管理',
        key: 'systemManagement/employeeManagement',
      },
    ],
  },

  {
    name: '模板库',
    key: 'templatePage/GolbalTem',
  },
]
export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`
    if (path === itemPath) {
      return item.name
    } else if (item.children) {
      return getName(path, item.children)
    }
  })
}

export const generatePermission = (role: string) => {
  const actions = role === 'admin' ? ['*'] : ['read']
  const result = {}
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions
      })
    }
  })
  return result
}

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return []
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route
      let visible = true
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission)
      }

      if (!visible) {
        continue
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] }
        filterRoute(route.children, newRoute.children)
        if (newRoute.children.length) {
          arr.push(newRoute)
        }
      } else {
        arr.push({ ...route })
      }
    }

    return arr
  }

  const [permissionRoute, setPermissionRoute] = useState(
    window.screen.width < 450 ? miniPhoneroutes : routes,
  )

  useEffect(() => {
    if (window.screen.width < 450) {
      const newRoutes = filterRoute(miniPhoneroutes)
      setPermissionRoute(newRoutes)
    } else {
      const newRoutes = filterRoute(routes)
      setPermissionRoute(newRoutes)
    }
    // const newRoutes = filterRoute(routes)
    // setPermissionRoute(newRoutes)
  }, [JSON.stringify(userPermission)])

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0]
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key
      return firstRoute
    }
    return ''
  }, [permissionRoute])

  return [permissionRoute, defaultRoute]
}

export default useRoute
