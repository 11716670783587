export default {
  primary: '#FF6500',
  primaryDFZ: '#9e2d26',
  primaryHover: '#FF6A00',
  primaryFF65: '#FF6500',
  primaryFdc: '#fdc800',
  primaryFE8: '#FE8124',
  primaryD36: '#FF7300',
  primaryFF7: '#FF7300',
  primaryFF2: '#FFB200',
  primary1E6: '#FFF1E6',
  red: '#ff4a3b',
  hightLight: '#f4523b',
  hightLight234: '#f75234',
  hightLight23B: '#F4523B',
  hightLightF5: '#FDF6F5',
  hightLightA25: '#FF1A25',
  hightLight71A: '#FF471A',
  link: '#0088CC',
  homeMark: '#b6ccc6',
  price: '#ea4335',
  sectionBgc: '#f1f1f1',
  holderBgc: '#EBF1EF',
  courseBorder: '#EAF0EE',
  courseColor: '#EA3553',
  grayA: '#AAAAAA',
  grayB: '#BBBBBB',
  grayC: '#BBBBBB',
  grayD: '#DDDDDD',
  grayE: '#EEEEEE',
  grayB4: '#B4B4B4',
  grayDe: '#DEDEDE',
  grayD8: '#D8D8D8',
  grayE0: 'E0E0E0',
  grayE4: '#e4e4e4',
  grayE5: '#E5E5E5',
  grayE6: '#E6E6E6',
  grayE7: '#E7E7E7',
  grayBg: '#F9FAFA',
  grayEa: '#EAEAEA',
  grayEb: '#EBEBEB',
  grayEF: '#EFEFEF',
  grayE9: '#E9E9E9',
  disabledGray: '#E8E8E8',
  gray3F: '#3F3F3F',
  gray3E: '#3E3E3E',
  grayF2: '#F2F2F2',
  grayF4: '#F4F4F4',
  grayF5: '#F5F5F5',
  grayF345: '#F3F4F5',
  grayF8: '#F8F8F8',
  grayF9: '#F9F9F9',
  gray8F: '#8F8F8F',
  gray9F: '#9F9F9F',
  grayFb: '#FbFbFb',
  brown875: '#875427',
  memberColor: '#BD8B63',
  playerPlaceholder: '#191b1f',
  black: '#000',
  black4: '#444444',
  black5: '#555555',
  black7: '#777777',
  black70: '#707070',
  black10: 'rgba(0 , 0, 0, .1)',
  black20: 'rgba(0 , 0, 0, .20)',
  black25: 'rgba(0 , 0, 0, .25)',
  black40: 'rgba(0 , 0, 0, .40)',
  black65: 'rgba(0 , 0, 0, .65)',
  black85: 'rgba(0 , 0, 0, .85)',
  black90: 'rgba(0 , 0, 0, .90)',
  black2: '#222222',
  more: '#d67b33',
  error: '#f14538',
  success: '#60CB34',
  grayED: '#EDEDED',
  gray8: '#888888',
  grayD9: '#D9D9D9',
  grayBA: '#BABABA',
  grayFA: '#FAFAFA',
  gray9: '#999999',
  white: '#fff',
  white10: 'rgba(255, 255, 255, 0.10)',
  white12: 'rgba(255, 255, 255, 0.12)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white40: 'rgba(255, 255, 255, 0.40)',
  white65: 'rgba(255, 255, 255, 0.65)',
  white80: 'rgba(255, 255, 255, 0.80)',
  white85: 'rgba(255, 255, 255, 0.85)',
  white90: 'rgba(255, 255, 255, 0.90)',
  black222: '#222',
  black555: '#555',
  black000: '#000',
  proof: '#ffebe8',
  proofBold: '#e33e4a',
  rewrite: '#e0f8ec',
  rewriteBold: '#1eb980',
  continue: '#daedff',
  continueBold: '#3c6ddd',
  trans: '#f2e4ff',
  transBold: '#874cc3',
  reduce: '#D9F5F6',
  reduceBold: '#159D9D',
  directives: '#FFE6FB',
  directivesBold: '#C437AE',
  tool: '#f1f1f1',
  word: 'linear-gradient(135deg, #FF381E 0%, #FF5600 51%, #FF6E2D 100%);',
}
