import React, { useEffect, useRef, useState } from 'react'
import styles from './style/index.module.less'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import StepHeaderMod from './StepHeaderMod'
import { GlobalState } from '@/store'
import LeftFormMod from './leftFromMod'
import RightFromMod from './rightFromMod'
import EmptyMod from './emptyMod'
import Modal from '@/components/Modal/index'
import { GetQueryValue } from '@/utils/common'
import {
  AgentAvatar,
  CreateAgentDarft,
  SaveAgent,
  ShowAgentDetail,
} from '@/model/reference'
import { showModal } from '@/share/actions'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { IconErrorTip, IconWarnTip } from '@arco-iconbox/react-aidb-v2'
import { Message } from '@arco-design/web-react'

function AgentDetail(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  const [type, setType] = useState('')
  const history = useHistory()
  const location = useLocation()
  const [avatar, setAvatar] = useState('')
  const [imgLoading, setImgLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    title: '',
    instruct: '',
    info: '',
    conversationStarters: ['', '', ''],
    id: '',
  })

  const initialValuesRef = useRef(initialValues)

  useEffect(() => {
    if (GetQueryValue('id')?.length > 0) {
      showDetail()
    } else {
      showModal('AddAgentModal')
    }
  }, [])
  useEffect(() => {
    document.title = '智能体 - 悟智AI'
  }, [])

  //生成的模版内容
  useEffect(() => {
    events.on(ACTIONS.AIAGENT_DETAIL_DATA, saveDraft)
    return () => {
      events.off(ACTIONS.AIAGENT_DETAIL_DATA, saveDraft)
    }
  }, [])

  useEffect(() => {
    initialValuesRef.current = initialValues
    console.log('Initial Values Updated:', initialValues)
  }, [initialValues])

  //保存草稿
  const saveDraft = (params) => {
    //如果有id则不新建了，如果没有则新建一次
    if (GetQueryValue('id')?.length > 0) {
      console.log(1)
    } else {
      CreateAgentDarft().then((res) => {
        console.log(res.data.data.uuid)
        history.push(`/agentDetail?id=${res.data.data.uuid}`)
      })
    }

    //保存初始化值
    setInitialValues((prevState) => ({
      ...prevState,
      title: params.data.title,
      instruct: params.data.prompt,
      info: params.data.opening,
      conversationStarters: params.data.questions,
    }))
    handleCreateImg(params.data.title)
  }

  //设置头像接口
  const handleCreateImg = (params) => {
    setImgLoading(true)
    if (params.length > 0) {
      AgentAvatar({
        prompt: '简洁、矢量、' + params,
      }).then((res) => {
        if (res.data.success) {
          setAvatar(res.data.data)
          setImgLoading(false)
          if (initialValuesRef.current.title.length > 0) {
            save(res.data.data)
          }
        } else {
          setImgLoading(false)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
    } else {
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: '请先填写智能体名称',
      })
    }
  }

  //查看详情
  const showDetail = () => {
    ShowAgentDetail({ templateUuid: GetQueryValue('id') }).then((res) => {
      const detail = res.data.data
      setInitialValues({
        title: detail.name,
        instruct: detail.instructions,
        info: detail.description,
        conversationStarters: detail.conversationStarters || ['', '', ''],
        id: detail.templateId,
      })
      setAvatar(detail.faceImg)
      if (detail?.name?.length > 0 && detail?.instructions?.length > 0) {
        setType('MyAgent')
      }
    })
  }

  //保存草稿
  const save = (img) => {
    SaveAgent({
      name: initialValuesRef.current.title,
      description: initialValuesRef.current.info,
      faceImg: img,
      instructions: initialValuesRef.current.instruct,
      uuid: GetQueryValue('id'),
      conversationStarters: initialValuesRef.current.conversationStarters,
    }).then((res) => {
      if (res.data.success != true) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: res.data.msg,
        })
      }
    })
  }

  return (
    <div className={`${styles.writePage} ${styles[theme]}`}>
      <StepHeaderMod theme={theme} />
      <div className={styles.content}>
        <div className={styles.content_left}>
          <LeftFormMod
            avatar={avatar}
            showDetail={showDetail}
            initialValues={initialValues}
            theme={theme}
            id={GetQueryValue('id')}
            handleCreateImg={handleCreateImg}
            imgLoading={imgLoading}
          />
        </div>
        <div className={styles.content_right}>
          {type == 'MyAgent' ? (
            <RightFromMod
              avatar={avatar}
              initialValues={initialValues}
              theme={theme}
            />
          ) : (
            <EmptyMod></EmptyMod>
          )}
        </div>
      </div>
      <Modal></Modal>
    </div>
  )
}

export default AgentDetail
