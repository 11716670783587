import React from 'react'
import { useState, useEffect } from 'react'
import styles from './style/doc-text-item.module.less'
import {
  Dropdown,
  Menu,
  Divider,
  Checkbox,
  Message,
} from '@arco-design/web-react'
import Tags from './Tags'
import {
  IconMoreEdits,
  IconCollectFill,
  IconSuccessTip,
  IconErrorTip,
  IconShow,
  IconExport,
  IconMagicWand,
  IconShare,
  IconCollect,
  IconMove,
  IconEditLabel,
  IconEdit,
  IconUploadDocument,
  IconWastePaper,
  IconRecover,
  IconPlus,
} from '@arco-iconbox/react-aidb-v2'
import IconFile from '../../assets/imgs/doc-list-i.svg'
import IconFileBlack from '../../assets/imgs/doc-list-i-black.svg'
import IconFolder from '../../assets/imgs/folder-small.svg'
import IconBook from '../../assets/imgs/book-list.svg'
import IconSentence from '../../assets/imgs/sentence-list.svg'
import IconArticle from '../../assets/imgs/article-list.svg'
import IconAssistant from '../../assets/imgs/assistant-list.svg'
import ModalCustom from '../ModalCustom'
import AddTags from '../../pages/intelligentDocument/AddTags'
import { PageType } from '@/helpers/enums'
import { useHistory } from 'react-router-dom'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { showModal } from '@/share/actions'
import {
  GetDocFolderList,
  GetProjectShareContent,
  GetTeamTreeList,
  getCancelChat,
  getCancelCollect,
  getDocumentCollect,
  getDocumentDetail,
  getPublishDocument,
  getUserInfo,
  removeDocument,
} from '@/model/reference'
import request from '@/utils/request'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { getDocShareLink } from '@/model/documentEdit'
import CollectModal from '../Modal/collect-modal'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { switchProject } from '@/helpers/switchProject'
import SubMenu from '@arco-design/web-react/es/Menu/sub-menu'
import { ExportBtn } from '@/share/config'
interface Props {
  onClickMask?: (checked: boolean, id: string, index?: number) => void
  type?: string | number
  id?: any
  hideCheckbox?: string | boolean
  title?: string
  theme?: any
  wasteDate?: string
  isFav?: boolean
  date?: string
  content?: string
  tags?: string[]
  hideBottom?: boolean
  isComplete?: boolean
  uuid?: any
  uuids?: string[]
  pageType?: number
  head?: boolean
  hideEdit?: boolean
  showContent?: boolean
  dateText?: string
  surplusDay?: string
  searchInput?: string
  handleCheckbox?: (id) => void
  onCheckAll?: any
  allCheck?: any
  titleDefault?: any
  isCollect?: any
  searchType?: any
  folderUuid?: any
  filePath?: any
  tagsList?: any
  collectType?: any
  outCollectList?: boolean
  onToCollectEdit?: (
    uuid: string,
    type: number | string,
    outCollectList: boolean,
  ) => void //编辑器收藏卡片点击返回事件
  contentDefault?: any
  index?: number
  folderColor?: any
  fileType?: any
  teamUuid?: any
  permission?: any
}
function DocTextItem(props: Props) {
  const {
    onClickMask,
    id,
    isComplete,
    uuids = [],
    pageType,
    handleCheckbox,
    titleDefault,
    allCheck,
    onCheckAll,
    isCollect,
    outCollectList,
    onToCollectEdit,
    index,
    theme,
    folderColor,
    fileType,
    teamUuid,
    permission,
  } = props

  const history = useHistory()
  const [isHead] = useState(props.head)
  const [checked, setChecked] = useState(false)
  const [iscollect, setIscollect] = useState(isCollect)
  const dispatch = useDispatch()
  const [exportLoading, setExportLoading] = useState(false)
  useEffect(() => {
    setChecked(uuids.some((_) => _ == id))
  }, [uuids.some((_) => _ == id)])

  const clickMaskHandle = (e) => {
    setChecked(!checked)
    onClickMask(!checked, id, index)
  }

  useEffect(() => {
    setChecked(false)
  }, [isComplete])

  const allCheckHandle = () => {
    setChecked(!checked)
    onCheckAll(!checked)
  }
  //重命名
  const renameDocumentHandle = (event) => {
    event.stopPropagation()
    showModal('RenameModal', {
      title: '重命名',
      defaultName: titleDefault || props.title,
      id: props.uuid,
      teamUuid: props.teamUuid,
    })
  }

  // 移动至废纸篓
  const removeDocumentHandle = async (event) => {
    event.stopPropagation()
    const res = await removeDocument({
      docStatus: 1,
      uuids: [props.uuid],
    })
      .then((res) => {
        if (res.data.success == true) {
          events.emit(ACTIONS.DELETE_DOC, {
            id: props.uuid,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '移至废纸篓成功',
          })
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '移至废纸篓失败',
        })
      })
  }

  // 移动
  const addFoldHandle = (event) => {
    event.stopPropagation()
    getFoldList()
  }
  function traverse(data) {
    if (Array.isArray(data)) {
      return data.map(traverse)
    } else if (data.children) {
      return {
        title: data.title,
        id: data.uuid,
        children: traverse(data.children),
      }
    } else {
      return {
        title: data.title,
        id: data.uuid,
      }
    }
  }
  function getFoldList() {
    const docFolderList =
      localStorage.getItem('docTypeKey') == '共享文档'
        ? GetTeamTreeList
        : GetDocFolderList
    docFolderList().then((res) => {
      const folderList = traverse(res.data.data)
      // 添加根目录对象
      const root_node = {
        title: '根目录',
        id: '',
        children: folderList,
      }
      showModal('AddToFoldModal', {
        title: '移动',
        foldList: [root_node], // 将根目录对象作为foldList参数传递
        id: props.uuid,
      })
    })
  }

  const [visible1, setVisible1] = useState(false)
  // 进入文件夹
  const goFoldHandle = (type) => {
    event.preventDefault() // 防止默认的导航行为
    if (location.href.includes('waste')) return
    if (props.collectType == 2) {
      setVisible1(true)
    } else {
      if (PageType.collectEdit == pageType) {
        onToCollectEdit(id, type, outCollectList)
      } else {
        if (props.type === 1) {
          if (localStorage.getItem('docTypeKey') == '共享文档') {
            const urlQuery = removeEmpty({
              filePath: props.title,
              uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
              folderUuid: GetQueryValue('filePath')
                ? GetQueryValue('uuid')
                : props.uuid,
              teamUuid: GetQueryValue('teamUuid')
                ? GetQueryValue('teamUuid')
                : props.teamUuid,
              permission: props.permission,
            }) as any
            history.push({
              pathname: '/intelligentDocument',
              search: '?' + new URLSearchParams(urlQuery).toString(),
            })
            // location.reload()
          } else {
            const urlQuery = removeEmpty({
              filePath: props.title,
              uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
              folderUuid: GetQueryValue('filePath')
                ? GetQueryValue('uuid')
                : props.uuid,
              teamUuid: GetQueryValue('teamUuid')
                ? GetQueryValue('teamUuid')
                : props.teamUuid,
              // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
              ...(localStorage.getItem('docTypeKey') == '共享文档'
                ? { permission: props.permission }
                : {}),
            }) as any
            history.push({
              pathname: '/intelligentDocument',
              search: '?' + new URLSearchParams(urlQuery).toString(),
            })
            // location.reload()
          }
        } else if (props.type === 0 && props.searchType != true) {
          const urlQuery = removeEmpty({
            filePath: GetQueryValue('filePath')
              ? GetQueryValue('filePath')
              : '',
            uuid: props.uuid,
            folderUuid: GetQueryValue('filePath') ? GetQueryValue('uuid') : '',
            title: props.title,
            from: location.pathname.substring(1),
            fileType: props.fileType,
            teamUuid: GetQueryValue('teamUuid')
              ? GetQueryValue('teamUuid')
              : props.teamUuid,
            // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
            ...(localStorage.getItem('docTypeKey') == '共享文档'
              ? { permission: props.permission }
              : {}),
          }) as any
          const queryString = '?' + new URLSearchParams(urlQuery).toString()
          window.location.href = '/documentEdit' + queryString
        } else if (props.type === 0 && props.searchType == true) {
          const urlQuery = removeEmpty({
            uuid: props.uuid,
            folderUuid: props.folderUuid,
            title: props.title,
            filePath: props.filePath,
            from: location.pathname.substring(1),
            fileType: props.fileType,
            teamUuid: GetQueryValue('teamUuid')
              ? GetQueryValue('teamUuid')
              : props.teamUuid,
            // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
            ...(localStorage.getItem('docTypeKey') == '共享文档'
              ? { permission: props.permission }
              : {}),
          }) as any
          const queryString = '?' + new URLSearchParams(urlQuery).toString()
          window.location.href = '/documentEdit' + queryString
        }
      }
    }
  }
  // 分享
  function hanldeShare() {
    getDocShareLink({ uuid: props.uuid }).then((res) => {
      if (res.data.success) {
        const postData = {
          url:
            window.location.origin +
            '/documentShare?uuid=' +
            res.data.data.documentShareLink,
        }
        showModal('ShareLinkModal', {
          fileType: props.type,
          title: '分享',
          id: props.uuid,
          isOpenShare: res.data.data.isOpenShare,
          documentShareLink: postData,
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: res.data.msg,
        })
      }
    })
  }
  // 是否隐藏复选框
  const [hideCheckbox] = useState(props.hideCheckbox)

  const deleteDocHandle = () => {
    events.emit(ACTIONS.DELETE_DOCUMENT, {
      id: [id],
    })
  }

  const returnDocHandle = () => {
    events.emit(ACTIONS.RETURN_DOCUMENT, {
      id: [id],
    })
  }
  //更换封面
  const handleCoverChange = () => {
    showModal('FolderCoverModal', {
      Modaltitle: '更换封面',
      uuid: props.uuid,
      title: props.title,
      folderColor: folderColor,
    })
    window.location.hash = 'FolderCoverModal'
  }

  //协同管理
  const handleAddPeople = () => {
    showModal('AddTeamModal', {
      uuid: props.uuid,
      fileType: props.fileType,
      currentPermission: props.permission,
      innerType: props.permission == 1 ? false : true,
    })
  }
  const dropList = (
    <Menu className={'custom-dropdown drop-icon'}>
      {pageType == PageType.intelligentDoc ? (
        <React.Fragment>
          <div className={'dropdown-list'}>
            {props.type == 1 && (
              <Menu.Item key="0" onClick={handleCoverChange}>
                <IconMagicWand /> 更换封面
              </Menu.Item>
            )}
            <Menu.Item key="1" onClick={hanldeShare}>
              <IconShare /> 分享
            </Menu.Item>
            <Menu.Item key="1" onClick={handleAddPeople}>
              <IconPlus />
              协同管理
            </Menu.Item>
            <Menu.Item key="2" onClick={handleCollect}>
              {iscollect ? (
                <>
                  <IconCollect /> 取消收藏
                </>
              ) : (
                <>
                  <IconCollect /> 收藏
                </>
              )}
            </Menu.Item>
            {localStorage.getItem('docTypeKey') != '共享文档' && (
              <Menu.Item key="3" onClick={addFoldHandle}>
                <IconMove /> 移动
              </Menu.Item>
            )}
            {localStorage.getItem('docTypeKey') != '共享文档' && (
              <Menu.Item
                key="4"
                onClick={() => {
                  setShowAddTags(true)
                }}>
                <IconEditLabel />{' '}
                {props.tags?.length > 0 ? '编辑标签' : '添加标签'}
              </Menu.Item>
            )}
            {/* <Menu.Item key="5">创建副本</Menu.Item> */}
            <Menu.Item key="6" onClick={renameDocumentHandle}>
              <IconEdit /> 重命名
            </Menu.Item>
            {props.type == 1 && (
              <Menu.Item
                style={{ marginBottom: '8px' }}
                disabled={exportLoading}
                key="11"
                onClick={handelDocToZip}>
                <IconExport />
                导出文件夹
              </Menu.Item>
            )}
            {props.type == 0 && (
              <SubMenu
                key={'7'}
                className={'export-drop'}
                style={{ justifyContent: 'flex-start' }}
                title={
                  <>
                    <IconExport
                      style={{ fontSize: '19px', marginLeft: '-3px' }}
                    />
                    导出
                  </>
                }>
                {ExportBtn.map(({ key, title, type, interfaceName }) => {
                  return (
                    <Menu.Item
                      disabled={exportLoading}
                      key={`5-${key}`}
                      onClick={() => exportHandle(type, interfaceName)}>
                      {title}
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )}
            {props.type == 0 ? (
              <Menu.Item
                style={{ marginBottom: '8px' }}
                key="10"
                onClick={handleSaveDoc}>
                <IconUploadDocument /> 发布文档
              </Menu.Item>
            ) : (
              ''
            )}
          </div>
          <Divider style={{ margin: '4px 0' }} />
          <div className={'dropdown-list'}>
            <Menu.Item
              disabled={permission == 1 ? false : true}
              style={{ marginTop: '7px', opacity: permission == 1 ? '' : 0.5 }}
              key="7"
              onClick={removeDocumentHandle}>
              <IconWastePaper /> 移至废纸篓
            </Menu.Item>
          </div>{' '}
        </React.Fragment>
      ) : pageType === PageType.collect ? (
        <React.Fragment>
          <div className={'dropdown-list'}>
            <Menu.Item key="2" onClick={cancleCollect}>
              <IconCollect /> 取消收藏
            </Menu.Item>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={'dropdown-list'}>
            <Menu.Item key="8" onClick={returnDocHandle}>
              <IconRecover /> 还原文档
            </Menu.Item>
            <Menu.Item key="3" onClick={deleteDocHandle}>
              <IconWastePaper />
              删除文档
            </Menu.Item>
          </div>
        </React.Fragment>
      )}
    </Menu>
  )

  //添加标签
  const [showAddTags, setShowAddTags] = React.useState(false)

  //发布文档
  function handleSaveDoc() {
    getPublishDocument({
      uuid: props.uuid,
      docVersionType: 3,
    }).then((res) => {
      console.log(res)
      if (res.data.success == true) {
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '发布文档成功',
        })
      }
    })
  }

  //重命名
  const [showRename, setShowRename] = React.useState(false)
  function handleCloseModal() {
    setShowAddTags(false)
    events.emit(ACTIONS.ADD_DOC_TAG)
  }
  // 收藏文档
  function handleCollect() {
    setIscollect(!iscollect)
    if (iscollect == false) {
      getDocumentCollect({ uuid: props.uuid })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_TURE, {
            id: props.uuid,
            isCollect: 1,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '收藏文档成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '收藏文档失败',
          })
        })
    } else {
      getCancelCollect({ uuids: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_FALSE, {
            id: props.uuid,
            isCollect: 0,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    }
  }
  // 取消收藏
  function cancleCollect() {
    if (props.collectType == 1) {
      getCancelCollect({ uuids: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.CANCEL_COLLECT, {
            id: [props.uuid],
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    } else if (props.collectType == 2) {
      getCancelChat({ messageIds: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.CANCEL_COLLECT, {
            id: [props.uuid],
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    }
  }

  //快速预览
  const handleShowEdit = (e) => {
    e.stopPropagation()
    event.preventDefault() // 防止默认的导航行为
    if (localStorage.getItem('docTypeKey') == '共享文档') {
      GetProjectShareContent({
        teamUuid: props.teamUuid,
        uuid: props.uuid,
      }).then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'update-showQuick',
            payload: { showQuick: true },
          })
          dispatch({
            type: 'update-detailData',
            payload: { detailData: res.data.data[0] },
          })
          dispatch({
            type: 'update-tagsList',
            payload: { tagsList: props.tags },
          })
        }
      })
    } else {
      getDocumentDetail({ uuid: props.uuid }).then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'update-showQuick',
            payload: { showQuick: true },
          })
          dispatch({
            type: 'update-detailData',
            payload: { detailData: res.data.data },
          })
          dispatch({
            type: 'update-tagsList',
            payload: { tagsList: props.tags },
          })
        }
      })
    }
  }

  //导出文件夹
  function handelDocToZip() {
    setExportLoading(true)
    axios
      .post(
        '/api/api-aidb/document/folderToZip',
        { uuid: props.uuid },
        { responseType: 'blob' },
      )
      .then((res) => {
        setExportLoading(false)
        const contentDisposition = res.headers['content-disposition']
        const match = contentDisposition.match(
          /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
        )
        let filename
        if (match && match[1]) {
          filename = decodeURIComponent(match[1])
        } else {
          filename = 'report.doc'
        }
        const fileData = new Blob([res.data], { type: 'application/msword' })
        const fileURL = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = filename
        link.click()
        Message.success({
          id: 'need_update',
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: `导出文件夹成功`,
        })
      })
      .catch(() => {
        setExportLoading(false)
        Message.error({
          id: 'need_update',
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `导出文件夹错误`,
        })
      })
  }

  //文档导出/word/pdf/markdown
  const exportHandle = async (type, interfaceName) => {
    setExportLoading(true)
    const res = await getUserInfo({ uid: '' })
    const vipEndDate = new Date(res.data.data.vipEndDate)
    const currentDate = new Date()

    if (currentDate > vipEndDate && !switchProject('DFZ')) {
      Message.error({
        id: 'need_update',
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请开通Vip会员，方可导出',
      })
      setExportLoading(false)
    } else {
      const positionId = localStorage.getItem('positionId')
      const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null

      axios
        .post(
          `/api/api-aidb/document/${interfaceName}`,
          { uuid: props.uuid, docVersionId: 0 },
          { responseType: 'blob' },
        )
        .then((res) => {
          setExportLoading(false)
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.${type}`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          Message.success({
            id: 'need_update',
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `导出 ${type} 成功`,
          })
        })
        .catch(() => {
          setExportLoading(false)
          Message.error({
            id: 'need_update',
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 ${type} 错误`,
          })
        })
    }
  }
  //导出message提示
  useEffect(() => {
    if (exportLoading) {
      Message.loading({
        id: 'need_update',
        content: '正在导出中',
        duration: 100000,
      })
    }
  }, [exportLoading])

  const setHref = (type) => {
    if (GetQueryValue('typeKey') == '2') {
      return
    } else {
      if (location.pathname == '/waste') {
        return '/waste'
      } else {
        if (type == 1) {
          // 第一个 urlQuery 对象
          const urlQuery1 = removeEmpty({
            filePath: props.title,
            uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
            folderUuid: GetQueryValue('filePath')
              ? GetQueryValue('uuid')
              : props.uuid,
            teamUuid: GetQueryValue('teamUuid')
              ? GetQueryValue('teamUuid')
              : props.teamUuid,
            // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
            ...(localStorage.getItem('docTypeKey') == '共享文档'
              ? { permission: props.permission }
              : {}),
          }) as any

          // 第二个 urlQuery 对象
          const urlQuery2 = removeEmpty({
            filePath: props.title,
            uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
            folderUuid: GetQueryValue('filePath')
              ? GetQueryValue('uuid')
              : props.uuid,
          }) as any

          // 获取本地存储中的 docTypeKey
          const docTypeKey = localStorage.getItem('docTypeKey')

          // 判断 docTypeKey 并设置 href
          if (docTypeKey === '共享文档') {
            return `?${new URLSearchParams(urlQuery1).toString()}`
          } else {
            return `?${new URLSearchParams(urlQuery2).toString()}`
          }
        } else {
          const urlQuery = removeEmpty({
            filePath: GetQueryValue('filePath')
              ? GetQueryValue('filePath')
              : '',
            listMode: GetQueryValue('listMode')
              ? GetQueryValue('listMode')
              : '',
            uuid: props.uuid,
            folderUuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
            teamUuid: GetQueryValue('teamUuid')
              ? GetQueryValue('teamUuid')
              : props.teamUuid,
            title: props.title,
            from: location.pathname.substring(1),
            fileType: props.fileType,
            // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
            ...(localStorage.getItem('docTypeKey') == '共享文档'
              ? { permission: props.permission }
              : {}),
          }) as any
          return `/documentEdit?${new URLSearchParams(urlQuery).toString()}`
        }
      }
    }
  }

  return (
    <div className={styles[theme]}>
      {isHead && (
        <div className={`${styles['item']} ${styles['head']}`}>
          <div className={`${styles['itemCon']} ${styles['title']} `}>
            <div className={`${styles['titleWrap']} `}>
              <div className={styles['title']}>名称</div>
            </div>
            <div
              className={`${styles['date']} ${
                props.hideEdit ? styles['tar'] : ''
              }`}>
              {props.dateText ? props.dateText : '创建时间'}
            </div>
            {!props.hideEdit && <div className={styles['edit']}>编辑</div>}
          </div>
        </div>
      )}
      {!isHead && (
        <div className={`${styles['item']}  `}>
          {!hideCheckbox && (
            <div
              className={`${styles['checkboxCell']} ${
                checked ? styles['show'] : ''
              }`}>
              <Checkbox checked={checked} onChange={clickMaskHandle}></Checkbox>
            </div>
          )}
          <div className={styles['itemCon']}>
            <a
              className={`${styles['titleWrap']}  `}
              href={setHref(props.type)}
              onClick={() => goFoldHandle(props.type)}>
              <div className={`${styles['titleWrap']}  `}>
                <div
                  className={`${styles['title']} ${
                    props.showContent ? styles['withContent'] : ''
                  }`}>
                  <div
                    className={`${styles['icon']} ${
                      styles[
                        props.type == 1
                          ? folderColor
                            ? folderColor
                            : 'yellow'
                          : ''
                      ]
                    }`}>
                    {(props.type === 0 || props.type === 2) && <IconFile />}
                    {props.type === 'book' && <IconBook />}
                    {props.type === 'sentence' && <IconSentence />}
                    {props.type === 'article' && <IconArticle />}
                    {props.type === 'assistant' && <IconAssistant />}
                  </div>
                  <div className={`${styles['text']} `}>
                    <div className={`${styles['text_tit']} `}>
                      <div
                        className={`${styles['text_tit_content']} `}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          marginTop:
                            !props.content && props.searchType ? '12px' : '0px',
                        }}
                        dangerouslySetInnerHTML={{ __html: props.title }}></div>
                      {iscollect == 1 ? (
                        <div className={styles['text_icon']}>
                          <IconCollectFill fill="#ff6500" />
                        </div>
                      ) : (
                        ''
                      )}
                      {window.location.search !== '?typeKey=2' &&
                        props.type != 1 && (
                          <div
                            className={styles['show']}
                            onClick={handleShowEdit}>
                            <IconShow />
                          </div>
                        )}
                    </div>
                    {props.showContent && (
                      <div className={styles['text_content']}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.content,
                          }}></div>
                      </div>
                    )}
                  </div>
                </div>
                {/*标签*/}
                {props.tags && props.tags.length > 0 && (
                  <Tags tags={props.tags} />
                )}
              </div>
            </a>
            <div
              className={`${styles['date']} ${
                props.hideEdit ? styles['tar'] : ''
              }`}>
              {props.date}
            </div>
            {!props.hideEdit && (
              <div className={styles['edit']}>
                {permission != 3 && (
                  <Dropdown
                    droplist={dropList}
                    position="br"
                    trigger={'click'}
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }>
                    <div className={styles['icon']}>
                      <IconMoreEdits color="black" useCurrentColor={true} />
                    </div>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <CollectModal
        visible={visible1}
        title={props.titleDefault}
        content={props.contentDefault}
        date={props.date}
        uuid={props.uuid}
        close={() => {
          setVisible1(false)
        }}
      />
      <ModalCustom
        theme={theme}
        maskClosable={false}
        title={props.tags?.length > 0 ? '编辑标签' : '添加标签'}
        visible={showAddTags}
        close={handleCloseModal}
        content={
          <AddTags
            theme={theme}
            tagsList={props.tagsList}
            uuid={props.uuid}
            tags={props.tags}
          />
        }
      />
    </div>
  )
}

export default DocTextItem
