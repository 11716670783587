import { SylApi } from '@syllepsis/adapter'
import { TextSelection } from 'prosemirror-state'

// Check if a given character is a period
const isDelimiter = (char: string) =>
  char === '.' ||
  char === '。' ||
  char === '?' ||
  char === '？' ||
  char === '!' ||
  char === '！' ||
  char === ';' ||
  char === '；'

// Check if a given position is the end of a Node
const isEndOfNode = (pos: number, _editor: SylApi) => {
  const resolvedPos = _editor.view.state.doc.resolve(pos)
  const endOfNodePos = resolvedPos.end(resolvedPos.depth)
  return pos === endOfNodePos
}

// Expand the selection to the delimiter or the end of the line
const getTextSelection2Delimiter = (_editor: SylApi) => {
  const state = _editor.view.state
  const { $from, $to } = state.selection
  const maxPos = state.doc.nodeSize - 2

  // Find the next period or the end of node from the current selection endpoint
  let nextDelimiter = $to.pos
  while (
    nextDelimiter < maxPos &&
    !isDelimiter(state.doc.textBetween(nextDelimiter, nextDelimiter + 1)) &&
    !isEndOfNode(nextDelimiter, _editor)
  ) {
    nextDelimiter++
  }

  const $end = state.doc.resolve(nextDelimiter + 1)
  const newTextSelection = TextSelection.between($from, $end)
  return newTextSelection
}

// Get current selection info
const getSelectionInfo = (_editor: SylApi) => {
  const selection = _editor.getSelection()
  const nodeInfo = {
    length: selection.length,
    index: selection.index,
  }
  const selectedContent = _editor.getText(nodeInfo)
  return {
    nodeInfo,
    selectedContent,
  }
}

//获取用户选中区域
const getTextSelection = (_editor: SylApi) => {
  const state = _editor.view.state
  const { $from, $to } = state.selection
  const textSelection = TextSelection.between($from, $to)
  return textSelection
}

const locale = {
  header1: {
    tooltip: '一级标题',
  },
  header2: {
    tooltip: '二级标题',
  },
  header3: {
    tooltip: '三级标题',
  },
  bold: {
    tooltip: '粗体',
  },
  italic: {
    tooltip: '斜体',
  },
  underline: {
    tooltip: '下划线',
  },
  strike: {
    tooltip: '删除线',
  },
  sup: {
    tooltip: '上标',
  },
  sub: {
    tooltip: '下标',
  },
  block_quote: {
    tooltip: '引用',
  },
  code_block: {
    tooltip: '代码块',
  },
  bullet_list: {
    tooltip: '无序列表',
  },
  ordered_list: {
    tooltip: '有序列表',
  },
  background: {
    tooltip: '背景颜色',
  },
  color: {
    tooltip: '字体颜色',
  },
  hr: {
    tooltip: '分割线',
  },
  video: {
    tooltip: '视频',
  },
  image: {
    tooltip: '图片',
    placeholder: '请输入图片描述',
    left: '居左',
    center: '居中',
    right: '居右',
  },
  link: {
    tooltip: '链接',
    editLinkTitle: '修改链接',
    insertLinkTitle: '插入链接',
    textPlaceholder: '请输入链接文本',
    linkPlaceholder: '请输入链接地址',
    cancelText: '取消',
    okText: '确定',
  },
  emoji: {
    tooltip: '表情',
  },
  redo: {
    tooltip: '重做',
  },
  undo: {
    tooltip: '撤销',
  },
  format_clear: {
    tooltip: '清除格式',
  },
  table: {
    tooltip: '表格',
    menuTip: '选择表格行列数',
    cut: '剪切',
    copy: '复制',
    paste: '粘贴',
    mergeCells: '合并单元格',
    splitCell: '拆分单元格',
    addColumnBefore: '左边插入列',
    addColumnAfter: '右边插入列',
    addRowBefore: '上边插入行',
    addRowAfter: '下边插入行',
    deleteRow: '删除当前行',
    deleteColumn: '删除当前列',
    deleteTable: '删除表格',
  },
}

export {
  getTextSelection2Delimiter,
  getSelectionInfo,
  getTextSelection,
  locale,
}
