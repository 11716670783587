import React, { useEffect, useRef, useState } from 'react'
import './style/chatgpt.less'
import { KeepAlive } from 'react-activation'
import style from './style/docEditBox.module.less'
import {
  CollectType,
  ContentType,
  DocBtnType,
  ProofOfficialType,
  ProofSelectType,
  ProofTactfulType,
  ProofType,
  RewriteSelectType,
  TextStreamGPT,
} from '@/helpers/enums'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import EmptyView from '@/components/Empty'
import { nonEmptyArray, textStreamGPTError } from '@/utils/common'
import Tools from './tools'
import ChatGPT from '../../pages/toolbox/index'
import { CorrectEditItem, EditorItem } from './editItem'

import { textCorrection, textRewriting } from '@/model/documentEdit'
import ToolHeaderMod from './toolHeaderMod'
import CollectMod from './collectMod'
import { Message, Spin } from '@arco-design/web-react'
import { getTextStreamGPT } from '@/model/reference'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import copy from 'copy-to-clipboard'
import request from '@/utils/request'
import { showModal } from '@/share/actions'
import ScrollToBottom from 'react-scroll-to-bottom'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import palette from '@/helpers/palette'
import DirectivesMod from './directivesMod'
import ProofNumsMod from './proofNumsMod'
import ProofOfficialMod from './proofOfficialMod'
import ProofTactfulMod from './proofTactfulMod'
interface Props {
  type: number
  selectContent?: string
  isNewDoc?: boolean
  nodeInfo?: any
  defaultContent?: any
  onClose: () => void
  docContent?: string
  docTitle?: string
  text?: string
  theme?: any
  onChangeType: (type: number) => void
  onIsLoading?: (isLoading: boolean) => void
  onTransToolLoading?: (isLoading: boolean, isHasList: boolean) => void
  onWriteToolLoading?: (isLoading: boolean, isHasList: boolean) => void
  onCorrectToolLoading?: (isLoading: boolean, isHasList: boolean) => void
  onContinueToolLoading?: (isLoading: boolean, isHasList: boolean) => void
  onReduceToolLoading?: (isLoading: boolean, isHasList: boolean) => void
  onDirectivesToolLoading?: (isLoading: boolean, isHasList: boolean) => void
}

const EditPageBox = (props: Props) => {
  const {
    type,
    selectContent,
    nodeInfo,
    onClose,
    docTitle,
    defaultContent,
    onChangeType,
    onIsLoading,
    onCorrectToolLoading,
    onWriteToolLoading,
    onTransToolLoading,
    onContinueToolLoading,
    onReduceToolLoading,
    onDirectivesToolLoading,
    theme,
  } = props
  const [editTranslateList, setEditTranslateList] = useState([])
  const [editRewriteList, setEditRewriteList] = useState([])
  const [continuationList, setContinuationList] = useState([])
  const [editReduceList, setEditReduceList] = useState([])
  const [continuationTmp, setContinuationTmp] = useState('')
  const [puncNum, setPuncNum] = useState<number>(0)
  const [wordNum, setWordNum] = useState<number>(0)
  const [grammarNum, setGrammarNum] = useState<number>(0)
  const [proofWordList, setProofWordList] = useState([])
  const [proofPuncList, setProofPuncList] = useState([])
  const [proofGrammarList, setProofGrammarList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isWriteLoading, setIsWriteLoading] = useState(false)
  const [isTransLoading, setIsTransLoading] = useState(false)
  // const [isCorrectLoading, setIsCorrectLoading] = useState(false)
  const [isProofBasicLoading, setIsProofBasicLoading] = useState(false)
  const [isProofOfficialLoading, setIsProofOfficialLoading] = useState(false)
  const [isProofCommentLoading, setIsProofCommentLoading] = useState(false)
  const [isProofAiLoading, setIsProofAiLoading] = useState(false)
  const [isProofAdLoading, setIsProofAdLoading] = useState(false)
  const [isProofMultilLangLoading, setIsProofMultilLangLoading] =
    useState(false)
  const [isContinueLoading, setIsContinueLoading] = useState(false)
  const [isReduceLoading, setIsReduceLoading] = useState(false)
  const [isReplaceChange, setIsReplaceChange] = useState(false)
  const [isDirectivesLoading, setIsDirectivesLoading] = useState(false)
  const [isToolsLoading, setIsToolsLoading] = useState(false)
  const [transNum, setTransNum] = useState(0)
  const [showBackTool, setShowBackTool] = useState(false)
  const [collectType, setCollectType] = useState<number>(CollectType.doc)
  const [rewriteShowType, setRewriteShowType] = useState(
    RewriteSelectType.onlyAdd,
  )
  const [reduceShowType, setReduceShowType] = useState(
    RewriteSelectType.onlyAdd,
  )
  const [proofShowType, setProofShowType] = useState(ProofSelectType.basicProof)
  const [proofType, setProofType] = useState(ProofType.puncProof)

  const [proofTactfulType, setProofTactfulType] = useState<string>(
    ProofTactfulType.commentProof,
  )
  const proofListRef = useRef([])
  const proofOfficialListRef = useRef([])
  const typeRef = useRef(null)
  const proofShowTypeRef = useRef(ProofSelectType.basicProof)
  const proofTypeRef = useRef(ProofType.puncProof)
  const [searchInput, setSearchInput] = useState('')
  const [directivesTagList, setDirectivesTagList] = useState([])
  const [directivesInpValue, setDirectivesInpValue] = useState<string>()
  const [directivesStartPos, setDirectivesStartPos] = useState<number>()
  const [directivesTagVal, setDirectivesTagVal] = useState([])
  const [isHasDirectivesList, setIsHasDirectivesList] = useState<boolean>()
  const [proofOfficialData, setProofOfficialData] = useState<any>()
  const [commentProofList, setCommentProofList] = useState<any>()
  const [aiProofList, setAiProofList] = useState([])
  const [adProofList, setAdProofList] = useState([])
  const [multilLangProofList, setMultilLangProofList] = useState([])
  const stopCorrectRef = useRef(false)
  const stopProofOfficialRef = useRef(false)
  const stopProofCommentRef = useRef(false)
  const stopProofAiRef = useRef(false)
  const stopProofAdRef = useRef(false)
  const stopProofMultilLangRef = useRef(false)
  const stopWriteRef = useRef(false)
  const stopTransRef = useRef(false)
  const stopReduceRef = useRef(false)
  const stopWritSingleRef = useRef(false)
  const stopTransSingleRef = useRef(false)
  const stopReduceSingleRef = useRef(false)
  const stopContinueRef = useRef(false)
  const stopDirectivesRef = useRef(false)
  let title = ''
  let dotColor
  let styleType
  useEffect(() => {
    typeRef.current = type
    if (
      type == DocBtnType.proof &&
      proofWordList.length == 0 &&
      proofPuncList.length == 0 &&
      proofGrammarList.length == 0
    ) {
      getCorrect(defaultContent)
      setProofType(ProofType.puncProof)
    }
    // if (
    //   type == DocBtnType.proof &&
    //   proofShowType == ProofSelectType.officialProof
    // ) {
    //   stopCorrectRef.current = true
    // }
    if (type == DocBtnType.rewrite && editRewriteList.length == 0) {
      getWriteHandle(defaultContent)
      stopWriteRef.current = false
      stopWritSingleRef.current = true
    }
    if (type == DocBtnType.translate && editTranslateList.length == 0) {
      setEditTranslateList([])
      stopTransRef.current = false
      stopTransSingleRef.current = false
      getTransHandle(defaultContent)
    }
    if (type == DocBtnType.reduce && editReduceList.length == 0) {
      setEditReduceList([])
      stopReduceRef.current = false
      stopReduceSingleRef.current = false
      getReduceHandle(defaultContent)
    }
    changeExpandList()
    if (type == DocBtnType.directives && isHasDirectivesList) {
      setDirectivesTagList([])
      setDirectivesInpValue('')
      setDirectivesTagVal([])
      setIsDirectivesLoading(false)
    }

    events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }, [type])

  useEffect(() => {
    classify()
  }, [proofType])

  useEffect(() => {
    events.on(ACTIONS.FROM_REWRITE_CLICK_EDITOR, rewriteClickComment)
    events.on(ACTIONS.FROM_TRANS_CLICK_EDITOR, transClickComment)
    events.on(ACTIONS.FROM_REDUCE_CLICK_EDITOR, reduceClickComment)
    events.on(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, continueClickComment)
    events.on(ACTIONS.FROM_DIRECTIVES_CLICK_EDITOR, directivesClickComment)
    events.on(ACTIONS.REPLACE_CHANGE, replaceChange)
    return () => {
      events.off(ACTIONS.FROM_REWRITE_CLICK_EDITOR, rewriteClickComment)
      events.off(ACTIONS.FROM_TRANS_CLICK_EDITOR, transClickComment)
      events.off(ACTIONS.FROM_REDUCE_CLICK_EDITOR, reduceClickComment)
      events.off(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, continueClickComment)
      events.off(ACTIONS.FROM_DIRECTIVES_CLICK_EDITOR, directivesClickComment)
      events.off(ACTIONS.REPLACE_CHANGE, replaceChange)
    }
  }, [])

  useEffect(() => {
    onIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setIsProofOfficialLoading(isProofOfficialLoading)
  }, [isProofOfficialLoading])

  useEffect(() => {
    onWriteToolLoading(isWriteLoading, editRewriteList.length > 0)
  }, [isWriteLoading])

  useEffect(() => {
    onTransToolLoading(isTransLoading, editTranslateList.length > 0)
  }, [isTransLoading])

  useEffect(() => {
    onReduceToolLoading(isReduceLoading, editReduceList.length > 0)
  }, [isReduceLoading])

  useEffect(() => {
    stopDirectivesRef.current = isDirectivesLoading
  }, [isDirectivesLoading])

  //期间任何一个都得传
  useEffect(() => {
    onCorrectToolLoading(
      isProofBasicLoading ||
        isProofOfficialLoading ||
        isProofCommentLoading ||
        isProofAdLoading ||
        isProofAiLoading ||
        isProofMultilLangLoading,
      true,
    )
  }, [
    isProofBasicLoading,
    isProofOfficialLoading,
    isProofCommentLoading,
    isProofAdLoading,
    isProofAiLoading,
    isProofMultilLangLoading,
  ])

  useEffect(() => {
    onContinueToolLoading(isContinueLoading, continuationList.length > 0)
  }, [isContinueLoading])

  useEffect(() => {
    onDirectivesToolLoading(isDirectivesLoading, isHasDirectivesList)
  }, [isDirectivesLoading])

  const replaceChange = () => {
    setIsReplaceChange(true)
  }

  const directivesCompleteHandle = (isComplete, isHasList) => {
    stopDirectivesRef.current == !isComplete
    setIsDirectivesLoading(!isComplete)
    setIsHasDirectivesList(isHasList)
  }

  const directivesClickComment = (payload) => {
    if (stopDirectivesRef.current) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '正在进行中，请稍后尝试',
      })
      return
    }
    const { tagSelectList, inputValue, tagVal, startPos } = payload
    setDirectivesTagList(tagSelectList)
    setDirectivesInpValue(inputValue)
    setDirectivesStartPos(startPos)
    setDirectivesTagVal([tagVal])
  }

  const changeExpandList = () => {
    if (type != DocBtnType.rewrite || type != DocBtnType.rewriteSingle) {
      const newList = JSON.parse(JSON.stringify(editRewriteList)).map(
        (item, index) => {
          return {
            ...item,

            isOpen: false,
          }
        },
      )
      setEditRewriteList(newList)
    }
    if (type != DocBtnType.translate || type != DocBtnType.translateSingle) {
      const newList = JSON.parse(JSON.stringify(editTranslateList)).map(
        (item, index) => {
          return {
            ...item,
            isOpen: false,
          }
        },
      )
      setEditTranslateList(newList)
    }
  }

  const rewriteClickComment = (payload) => {
    const spanSelectList = payload.spanSelectList
    stopWriteRef.current = true
    setIsWriteLoading(true)
    setEditRewriteList([])
    const getOneReWrite = async () => {
      stopWritSingleRef.current = false
      for (
        let i = 0;
        i < spanSelectList.length && !stopWritSingleRef.current;
        i++
      ) {
        if (spanSelectList[i] == null) {
          return
        }
        const res = await textRewriting({
          text: spanSelectList[i],
        })

        if (res.data.success && JSON.parse(res.data.data)[0][1].length != 0) {
          const newContent = {
            fixContent: JSON.parse(res.data.data)[0][0],
            defaultContent: spanSelectList[i],
            singleRewrite: true,
            isReplace: false,
            nodeIndex: i,
          }

          setEditRewriteList((prevList) => [...prevList, newContent])
        }
      }
      setIsWriteLoading(false)
    }

    getOneReWrite()
  }

  const transClickComment = (payload) => {
    stopTransRef.current = true
    const spanSelectList = payload.spanSelectList
    setEditTranslateList([])

    const getOneTrans = async () => {
      let transCount = transNum
      let retryCount = 0
      const maxRetries = 5
      stopTransSingleRef.current = false
      setIsTransLoading(true)
      let prevConversationId = ''
      let preParentMessageId = ''
      for (
        let i = 0;
        i < spanSelectList.length && !stopTransSingleRef.current;
        i++
      ) {
        if (spanSelectList[i] && spanSelectList[i].trim() !== '') {
          const reg = /[\u4e00-\u9fa5]/g
          outerLoop: while (retryCount <= maxRetries) {
            try {
              const res = await getTextStreamGPT({
                message:
                  spanSelectList[i].trim() === '' ? '' : spanSelectList[i],
                to: reg.test(spanSelectList[i]) ? '英语' : '中文',
                type: TextStreamGPT.translate,
                stream: false,
                conversationId: prevConversationId,
                parentMessageId: preParentMessageId,
              })

              prevConversationId = res.data.conversationId
              preParentMessageId = res.data.messageId
              // if (res.data.data.text == '[OUT_OF_DAY_FLOW]') {
              //   stopTransRef.current = false
              //   setIsTransLoading(false)
              //   return
              // }
              if (res.status === 200 && res.data.response) {
                const newContent = {
                  fixContent: JSON.parse(res.data.response).translate,
                  defaultContent: spanSelectList[i],
                  singleRewrite: true,
                  nodeIndex: i,
                }

                setEditTranslateList((prevList) => [...prevList, newContent])
                setTransNum(transCount)
              } else if (
                res.status === 200 &&
                res.data.conversationId &&
                !res.data.response
              ) {
                // Retry after a delay if response is empty
                await new Promise((resolve) => setTimeout(resolve, 1000))
                continue
              } else {
                stopTransRef.current = false
                setIsTransLoading(false)
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: textStreamGPTError(res.data.data.text),
                })
                return
              }

              break // Break the retry loop if successful
            } catch (err) {
              console.log(err)
              retryCount++
              if (retryCount > maxRetries) {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '接口调用频繁，请重新刷新',
                })
              }
              await new Promise((resolve) => setTimeout(resolve, 1000))
            }
          }
        }
      }
      setIsTransLoading(false)
    }
    getOneTrans()
  }

  const reduceClickComment = (payload) => {
    const spanSelectList = payload.spanSelectList
    stopTransRef.current = true
    setEditReduceList([])

    const getOneReduce = async () => {
      let retryCount = 0
      const maxRetries = 5
      stopReduceSingleRef.current = false
      setIsReduceLoading(true)
      let prevConversationId = ''
      let preParentMessageId = ''
      for (
        let i = 0;
        i < spanSelectList.length && !stopReduceSingleRef.current;
        i++
      ) {
        if (spanSelectList[i] && spanSelectList[i].trim() !== '') {
          outerLoop: while (retryCount <= maxRetries) {
            try {
              const res = await getTextStreamGPT({
                message:
                  spanSelectList[i].trim() === '' ? '' : spanSelectList[i],
                to: '',
                type: TextStreamGPT.reduce,
                stream: false,
                conversationId: prevConversationId,
                parentMessageId: preParentMessageId,
              })

              prevConversationId = res.data.conversationId
              preParentMessageId = res.data.messageId
              // if (res.data.data.text == '[OUT_OF_DAY_FLOW]') {
              //   stopTransRef.current = false
              //   setIsTransLoading(false)
              //   return
              // }
              if (res.status === 200 && res.data.response) {
                const newContent = {
                  fixContent: JSON.parse(res.data.response).rewrite,
                  defaultContent: spanSelectList[i],
                  singleRewrite: true,
                  nodeIndex: i,
                }
                setEditReduceList((prevList) => [...prevList, newContent])
                // setTransNum(transCount)
              } else if (
                res.status === 200 &&
                res.data.conversationId &&
                !res.data.response
              ) {
                // Retry after a delay if response is empty
                await new Promise((resolve) => setTimeout(resolve, 1000))
                continue
              } else {
                stopTransRef.current = false
                setIsReduceLoading(false)
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: textStreamGPTError(res.data.data.text),
                })
                return
              }

              break // Break the retry loop if successful
            } catch (err) {
              console.log(err)
              retryCount++
              if (retryCount > maxRetries) {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '接口调用频繁，请重新刷新',
                })
              }
              await new Promise((resolve) => setTimeout(resolve, 1000))
            }
          }
        }
      }
      setIsReduceLoading(false)
    }
    getOneReduce()
  }

  const continueClickComment = async (payload) => {
    const { setSelectContent } = payload
    setIsContinueLoading(true)
    setContinuationList([])
    setContinuationTmp('')
    getContinueHandle(setSelectContent)
  }

  const getContinueHandle = async (contents) => {
    setIsContinueLoading(true)
    setContinuationList([])
    setContinuationTmp('')
    if (stopContinueRef.current) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '接口调用频繁',
      })
      return
    }

    stopContinueRef.current = true
    let tmpText = ''
    if (!contents) {
      return
    }
    const opts = {
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: contents,
        // message: '你好',
        conversationId: '',
        parentMessageId: '',
        stream: true,
        type: 3,
      }),
    }
    try {
      // let reply = '';
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        process.env.REACT_APP_CHATGPT_URL + '/textStreamGPT',
        {
          ...opts,
          signal: controller.signal,
          onclose() {
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            request
              .post(
                process.env.REACT_APP_CHATGPT_URL + '/textStreamGPT',
                JSON.stringify({
                  message: contents,
                  conversationId: '',
                  parentMessageId: '',
                  stream: true,
                  type: 3,
                }),
              )
              .then((res) => {
                const error = res.data.data.text
                setIsContinueLoading(false)
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: textStreamGPTError(error),
                })
                return
              })
            throw error
          },
          onmessage(message) {
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = JSON.parse(message.data).response
            } else {
              tmpText =
                message.data == '[DONE]'
                  ? tmpText
                  : tmpText + message.data.substring(1, message.data.length - 1)
            }
            let fixContent = tmpText.replaceAll('\\n', ' \n ')
            setContinuationTmp(fixContent)
            if (message.event == 'result') {
              controller.abort()
              if (!tmpText) {
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '接口调用频繁，请重新刷新',
                })
                setIsContinueLoading(false)
                return
              }
              const newContent = {
                fixContent: tmpText,
                defaultContent: contents,
                singleRewrite: false,
                isReplace: false,
              }
              stopContinueRef.current = false
              setContinuationList((prevList) => [...prevList, newContent])
              setIsContinueLoading(false)

              return
            } else if (message.data !== '[DONE]') {
              // 倒数第二条之前给 stream 逻辑
            }
            return
          },
        },
      )
    } catch (err) {
      setIsTransLoading(false)
    }
  }

  const getWriteHandle = async (contents) => {
    setIsWriteLoading(true)
    try {
      for (let i = 0; i < contents.length && !stopWriteRef.current; i++) {
        if (
          contents[i].text == null
          //|| typeRef.current !== DocBtnType.rewrite
        ) {
          // setEditRewriteList([])
          return
        }
        const res = await textRewriting({
          text: contents[i].text,
        })

        if (res.data.success && JSON.parse(res.data.data)[0][1].length != 0) {
          const newContent = {
            fixContent: JSON.parse(res.data.data)[0][0],
            defaultContent: contents[i].text,
            singleRewrite: false,
            isReplace: false,
            isOpen: false,
            nodeIndex: contents[i].nodeIndex,
          }

          setEditRewriteList((prevList) => [...prevList, newContent])
        }
      }
      setIsWriteLoading(false)
    } catch (error) {
      setIsWriteLoading(false)
    }
  }

  const getTransHandle = async (contents) => {
    let transCount = transNum
    let retryCount = 0
    const maxRetries = 5

    setIsTransLoading(true)
    let prevConversationId = ''
    let preParentMessageId = ''
    for (let i = 0; i < contents.length && !stopTransRef.current; i++) {
      if (contents[i].text && contents[i].text.trim() !== '') {
        const reg = /[\u4e00-\u9fa5]/g
        outerLoop: while (retryCount <= maxRetries) {
          try {
            const res = await getTextStreamGPT({
              message: contents[i].text.trim() === '' ? '' : contents[i].text,
              to: reg.test(contents[i].text) ? '英语' : '中文',
              type: TextStreamGPT.translate,
              stream: false,
              conversationId: prevConversationId,
              parentMessageId: preParentMessageId,
            })

            prevConversationId = res.data.conversationId
            preParentMessageId = res.data.messageId
            // if (res.data.data.text == '[OUT_OF_DAY_FLOW]') {
            //   stopTransRef.current = false
            //   setIsTransLoading(false)
            //   return
            // }
            if (res.status === 200 && res.data.response) {
              const newContent = {
                fixContent: JSON.parse(res.data.response).translate,
                defaultContent: contents[i].text,
                singleRewrite: false,
                isReplace: false,
                isOpen: false,
                nodeIndex: contents[i].nodeIndex,
              }

              setEditTranslateList((prevList) => [...prevList, newContent])
              setTransNum(transCount)
            } else if (
              res.status === 200 &&
              res.data.conversationId &&
              !res.data.response
            ) {
              // Retry after a delay if response is empty
              await new Promise((resolve) => setTimeout(resolve, 1000))
              continue
            } else {
              stopTransRef.current = false
              setIsTransLoading(false)
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: textStreamGPTError(res.data.data.text),
              })
              return
            }

            break // Break the retry loop if successful
          } catch (err) {
            console.log(err)
            retryCount++
            if (retryCount > maxRetries) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '接口调用频繁，请重新刷新',
              })
            }
            await new Promise((resolve) => setTimeout(resolve, 1000))
          }
        }
      }
    }
    setIsTransLoading(false)
  }

  const getReduceHandle = async (contents) => {
    let retryCount = 0
    const maxRetries = 5

    setIsReduceLoading(true)
    let prevConversationId = ''
    let preParentMessageId = ''
    for (let i = 0; i < contents.length && !stopReduceRef.current; i++) {
      if (contents[i].text && contents[i].text.trim() !== '') {
        outerLoop: while (retryCount <= maxRetries) {
          try {
            const res = await getTextStreamGPT({
              message: contents[i].text.trim() === '' ? '' : contents[i].text,
              to: '',
              type: TextStreamGPT.reduce,
              stream: false,
              conversationId: prevConversationId,
              parentMessageId: preParentMessageId,
            })

            prevConversationId = res.data.conversationId
            preParentMessageId = res.data.messageId
            // if (res.data.data.text == '[OUT_OF_DAY_FLOW]') {
            //   stopTransRef.current = false
            //   setIsTransLoading(false)
            //   return
            // }
            if (res.status === 200 && res.data.response) {
              const newContent = {
                fixContent: JSON.parse(res.data.response).rewrite,
                defaultContent: contents[i].text,
                singleRewrite: false,
                isReplace: false,
                isOpen: false,
                nodeIndex: contents[i].nodeIndex,
              }
              setEditReduceList((prevList) => [...prevList, newContent])
              // setTransNum(transCount)
            } else if (
              res.status === 200 &&
              res.data.conversationId &&
              !res.data.response
            ) {
              // Retry after a delay if response is empty
              await new Promise((resolve) => setTimeout(resolve, 1000))
              continue
            } else {
              stopTransRef.current = false
              setIsReduceLoading(false)
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: textStreamGPTError(res.data.data.text),
              })
              return
            }

            break // Break the retry loop if successful
          } catch (err) {
            console.log(err)
            retryCount++
            if (retryCount > maxRetries) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '接口调用频繁，请重新刷新',
              })
            }
            await new Promise((resolve) => setTimeout(resolve, 1000))
          }
        }
      }
    }
    setIsReduceLoading(false)
  }

  const getCorrect = async (contents) => {
    const correctList = []
    for (let i = 0; i < contents.length; i++) {
      if (contents[i].text && contents[i].text.trim() != '') {
        correctList.push(contents[i])
      }
    }
    getCorrectionHandle(correctList)
  }

  const getCorrectionHandle = async (contents) => {
    const wrong = []
    let nodeWrong = []
    let nodeAddWrong = []
    if (contents.length == 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '目前没有内容，请填写内容',
      })
      return
    }
    setIsProofBasicLoading(true)
    for (let i = 0; i < contents.length && !stopCorrectRef.current; i++) {
      // if (typeRef.current !== DocBtnType.proof) return
      try {
        const res = await textCorrection({ text: contents[i].text })
        if (
          res.data.success &&
          res.data.data.item.details.length != 0 &&
          res.data.code == '500200'
        ) {
          nodeWrong = [...res.data.data.item.details]
          nodeAddWrong = nodeWrong.map((_) => {
            return {
              ..._,
              content: contents[i].text,
              index: i,
              nodeIndex: contents[i].nodeIndex,
              isReplace: false,
              replaceId: contents[i].nodeIndex + '-' + _.sentenceId,
            }
          })
          wrong.push(...nodeAddWrong)
          proofListRef.current = wrong
          calculate(proofListRef.current)
          classify()
        } else if (res.data.code != '500200') {
          stopCorrectRef.current = false
          setIsProofBasicLoading(false)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content:
              res.data.code == '700001'
                ? '注册用户当天使用次数已达到上限'
                : res.data.code == '700004'
                ? '会员用户当天使用次数已达到上限'
                : '接口报错请重试',
          })
          return
        }
        if (i == contents.length - 1) {
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '通用校对完成',
          })
          setIsProofBasicLoading(false)
        }
      } catch (err) {
        continue // 继续下一次循环
      }
    }
  }

  const classify = () => {
    let wordProofList = []
    let puncProofList = []
    let grammarProofList = []
    if (proofTypeRef.current == ProofType.wordProof) {
      proofListRef.current.forEach((i) => {
        if (
          i.vecFragmentList[0].label === '010100' ||
          i.vecFragmentList[0].label === '010200' ||
          i.vecFragmentList[0].label === '010600'
        ) {
          wordProofList.push(i)
        }
      })
      setProofWordList(wordProofList)
    } else if (proofTypeRef.current == ProofType.puncProof) {
      proofListRef.current.forEach((i) => {
        if (
          i.vecFragmentList[0].label === '020100' ||
          i.vecFragmentList[0].label === '020200' ||
          i.vecFragmentList[0].label === '020300'
        ) {
          puncProofList.push(i)
        }
      })
      setProofPuncList(puncProofList)
    } else if (proofTypeRef.current == ProofType.grammarProof) {
      proofListRef.current.forEach((i) => {
        if (
          i.vecFragmentList[0].label !== '010100' &&
          i.vecFragmentList[0].label !== '010200' &&
          i.vecFragmentList[0].label !== '010600' &&
          i.vecFragmentList[0].label !== '020100' &&
          i.vecFragmentList[0].label !== '020200' &&
          i.vecFragmentList[0].label !== '020300'
        ) {
          grammarProofList.push(i)
        }
      })
      setProofGrammarList(grammarProofList)
    }
  }

  const calculate = (wrong) => {
    let wordCount = 0
    let puncCount = 0
    let grammarCount = 0
    let proofList = []
    wrong.forEach((i) => {
      if (
        i.vecFragmentList[0].label === '010100' ||
        i.vecFragmentList[0].label === '010200' ||
        i.vecFragmentList[0].label === '010600'
      ) {
        setWordNum(++wordCount)
      } else if (
        i.vecFragmentList[0].label === '020100' ||
        i.vecFragmentList[0].label === '020200' ||
        i.vecFragmentList[0].label === '020300'
      ) {
        setPuncNum(++puncCount)
      } else {
        setGrammarNum(++grammarCount)
      }
    })
  }

  switch (type) {
    case DocBtnType.proof:
      title = '校对'
      dotColor = '#ff6a00'
      styleType = 'proof'
      break
    case DocBtnType.rewrite:
      title = '改写'
      // title = '修改'
      dotColor = '#1eb980'
      styleType = 'rewrite'
      break
    case DocBtnType.rewriteSingle:
      title = '改写'
      // title = '修改'
      dotColor = '#1eb980'
      styleType = 'rewrite'
      break
    // case DocBtnType.continuation:
    //   title = '扩写'
    //   // title = '批注'
    //   dotColor = '#3c6ddd'
    //   styleType = 'continuation'
    //   break
    case DocBtnType.continuationSingle:
      title = '扩写'
      // title = '批注'
      dotColor = '#3c6ddd'
      styleType = 'continuation'
      break
    case DocBtnType.reduce:
      title = '降重'
      dotColor = palette.reduceBold
      styleType = 'translate'
      break
    case DocBtnType.reduceSingle:
      title = '降重'
      dotColor = palette.reduceBold
      styleType = 'translate'
      break
    case DocBtnType.translate:
      title = '翻译'
      dotColor = '#874cc3'
      styleType = 'translate'
      break
    case DocBtnType.translateSingle:
      title = '翻译'
      dotColor = '#874cc3'
      styleType = 'translate'
      break
    case DocBtnType.directives:
      title = '指令'
      dotColor = '#C437AE'
      styleType = 'translate'
      break
    case DocBtnType.assistant:
      title = '智能助理'
      dotColor = '#61422d'
      styleType = 'assistant'
      break
    case DocBtnType.material:
      title = '素材'
      break
    case DocBtnType.collect:
      title = '收藏'
      break
    case DocBtnType.comments:
      styleType = 'comments'
      title = '审核意见'
      break
    case DocBtnType.annotate:
      styleType = 'annotate'
      title = '注释'
      break
    case DocBtnType.tools:
      styleType = 'tools'
      title = '工具箱'
  }

  const proofBasicList = [
    {
      title: '标点符号错误',
      num: puncNum,
      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofType.puncProof,
    },
    {
      title: '文法造句错误',
      num: grammarNum,
      boldColor: '#F39210',
      color: '#FFE7C8',
      type: ProofType.grammarProof,
    },
    {
      title: '单词、词句错误',
      num: wordNum,
      boldColor: '#12A969',
      color: '#A2E5C9',
      type: ProofType.wordProof,
    },
  ]

  const handleClickExpand = (i) => {
    let proofList
    if (ProofType.wordProof == proofTypeRef.current) {
      proofList = proofWordList
    } else if (ProofType.puncProof == proofTypeRef.current) {
      proofList = proofPuncList
    } else if (ProofType.grammarProof == proofTypeRef.current) {
      proofList = proofGrammarList
    }
    const newList = JSON.parse(JSON.stringify(proofList)).map((item, index) => {
      if (index === i) {
        events.emit(ACTIONS.REMOVE_EDITOR_TIP)
        return {
          ...item,
          isOpen: !item.isOpen,
        }
      }
      return {
        ...item,
        isOpen: false,
      }
    })
    if (ProofType.wordProof == proofTypeRef.current) {
      setProofWordList(newList)
    } else if (ProofType.puncProof == proofTypeRef.current) {
      setProofPuncList(newList)
    } else if (ProofType.grammarProof == proofTypeRef.current) {
      setProofGrammarList(newList)
    }
  }
  const handleRewriteClickExpand = (i) => {
    if (type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle) {
      const newList = JSON.parse(JSON.stringify(editRewriteList)).map(
        (item, index) => {
          if (index === i) {
            events.emit(ACTIONS.REMOVE_EDITOR_TIP)
            return {
              ...item,

              isOpen: !item.isOpen,
            }
          }
          return {
            ...item,

            isOpen: false,
          }
        },
      )
      setEditRewriteList(newList)
    } else if (
      type == DocBtnType.translate ||
      type == DocBtnType.translateSingle
    ) {
      const newList = editTranslateList.map((item, index) => {
        if (index === i) {
          events.emit(ACTIONS.REMOVE_EDITOR_TIP)

          return {
            ...item,

            isOpen: !item.isOpen,
          }
        }

        return {
          ...item,

          isOpen: false,
        }
      })

      setEditTranslateList(newList)
    } else if (
      type == DocBtnType.continuationSingle
      // ||
      // type == DocBtnType.continuation
    ) {
      const newList = continuationList.map((item, index) => {
        if (index === i) {
          // events.emit(ACTIONS.REMOVE_EDITOR_TIP)

          return {
            ...item,

            isOpen: !item.isOpen,
          }
        }

        return {
          ...item,

          isOpen: false,
        }
      })
      setContinuationList(newList)
    } else if (type == DocBtnType.reduce || type == DocBtnType.reduceSingle) {
      const newList = editReduceList.map((item, index) => {
        if (index === i) {
          events.emit(ACTIONS.REMOVE_EDITOR_TIP)

          return {
            ...item,

            isOpen: !item.isOpen,
          }
        }

        return {
          ...item,

          isOpen: false,
        }
      })

      setEditReduceList(newList)
    }
  }

  const replaceProofHandle = (type, id) => {
    let wordCount = wordNum
    let puncCount = puncNum
    let grammarCount = grammarNum
    let proofList
    if (ProofType.wordProof == proofTypeRef.current) {
      proofList = proofWordList
    } else if (ProofType.puncProof == proofTypeRef.current) {
      proofList = proofPuncList
    } else if (ProofType.grammarProof == proofTypeRef.current) {
      proofList = proofGrammarList
    }
    switch (type) {
      case '010100':
        wordCount--
        setWordNum(wordCount)
        break
      case '010200':
        wordCount--
        setWordNum(wordCount)
        break
      case '010600':
        wordCount--
        setWordNum(wordCount)
        break
      case '020100':
        puncCount--
        setPuncNum(puncCount)
        break
      case '020200':
        puncCount--
        setPuncNum(puncCount)
        break
      case '020300':
        puncCount--
        setPuncNum(puncCount)
        break
      default:
        grammarCount--
        setGrammarNum(grammarCount)
    }
    proofListRef.current.map((i) => {
      if (i.nodeIndex + '-' + i.sentenceId == id) {
        i.isReplace = true
      }
      return {
        ...i,
      }
    })
    classify()
  }

  const DeleteProofHandle = (type, id) => {
    let wordCount = wordNum
    let puncCount = puncNum
    let grammarCount = grammarNum
    let proofList
    if (ProofType.wordProof == proofTypeRef.current) {
      proofList = proofWordList
    } else if (ProofType.puncProof == proofTypeRef.current) {
      proofList = proofPuncList
    } else if (ProofType.grammarProof == proofTypeRef.current) {
      proofList = proofGrammarList
    }
    switch (type) {
      case '010100':
        wordCount--
        setWordNum(wordCount)
        break
      case '010200':
        wordCount--
        setWordNum(wordCount)
        break
      case '010600':
        wordCount--
        setWordNum(wordCount)
        break
      case '020100':
        puncCount--
        setPuncNum(puncCount)
        break
      case '020200':
        puncCount--
        setPuncNum(puncCount)
        break
      case '020300':
        puncCount--
        setPuncNum(puncCount)
        break
      default:
        grammarCount--
        setGrammarNum(grammarCount)
    }
    const deleteItem = proofList.splice(id, 1)
    const newArr = proofList
      .filter((_) => _.sentence != deleteItem[0].sentence)
      .map((i) => {
        return {
          ...i,
          isOpen: false,
        }
      })
    if (ProofType.wordProof == proofTypeRef.current) {
      setProofWordList(proofList)
    } else if (ProofType.puncProof == proofTypeRef.current) {
      setProofPuncList(proofList)
    } else if (ProofType.grammarProof == proofTypeRef.current) {
      setProofGrammarList(proofList)
    }
  }

  const replaceRewriteAndTrans = (type, id) => {
    events.emit(ACTIONS.REPLACE_REWRITE_TO_EDITOR_TIP)
    if (isReplaceChange) return
    if (type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle) {
      const newArr = editRewriteList.map((i) => {
        if (i.nodeIndex == id) {
          i.isReplace = true
        }
        return {
          ...i,
        }
      })
      setEditRewriteList(newArr)
    } else if (type == DocBtnType.translate || DocBtnType.translateSingle) {
      const newArr = editTranslateList.map((i) => {
        if (i.nodeIndex == id) {
          i.isReplace = true
        }
        return {
          ...i,
        }
      })
      setEditTranslateList(newArr)
    } else if (type == DocBtnType.reduce || type == DocBtnType.reduceSingle) {
      const newArr = editReduceList.map((i) => {
        if (i.nodeIndex == id) {
          i.isReplace = true
        }
        return {
          ...i,
        }
      })
      setEditReduceList(newArr)
    }
  }

  const continueHandle = (type, id) => {
    if (isReplaceChange) return
    const newArr = continuationList.map((i) => {
      return {
        ...i,
        isOpen: false,
        isReplace: true,
      }
    })
    setContinuationList(newArr)
    events.emit(ACTIONS.CONTINUE_ADOPT_EDITOR)
  }

  const changeOrderHandle = (type) => {
    setRewriteShowType(type)
    setReduceShowType(type)
  }

  const changeProofOrderHandle = (type) => {
    // stopCorrectRef.current = true
    proofShowTypeRef.current = type
    setProofShowType(type)
    // getCorrect(defaultContent)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  const changeProofTypeHandle = (type) => {
    setProofType(type)
    proofTypeRef.current = type
  }

  const searchHandle = (e) => {
    setSearchInput(e)
  }

  const collectEditHandle = (uuid, type) => {
    setSearchInput('')
    if (type == ContentType.fold) {
      setShowBackTool(true)
    }
  }

  const refreshHandle = () => {
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.basicProof
    ) {
      stopCorrectRef.current = false
      setProofWordList([])
      setProofPuncList([])
      setProofGrammarList([])
      setWordNum(0)
      setPuncNum(0)
      setGrammarNum(0)
      getCorrect(defaultContent)
      setProofType(ProofType.puncProof)
    }
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.officialProof
    ) {
      setProofOfficialData([])
      stopProofCommentRef.current = false
    }
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.commentProof
    ) {
      setCommentProofList([])
      stopProofCommentRef.current = false
    }
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.aiProof
    ) {
      setAiProofList([])
      stopProofAiRef.current = false
    }
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.adProof
    ) {
      setAdProofList([])
      stopProofAdRef.current = false
    }
    if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.multilLangProof
    ) {
      setMultilLangProofList([])
      stopProofMultilLangRef.current = false
    }
    if (type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle) {
      stopWriteRef.current = false
      setEditRewriteList([])
      getWriteHandle(defaultContent)
      // onChangeType(DocBtnType.rewrite)
    }
    if (type == DocBtnType.translate || type == DocBtnType.translateSingle) {
      stopTransRef.current = false
      setEditTranslateList([])
      getTransHandle(defaultContent)
      // onChangeType(DocBtnType.translate)
    }
    if (type == DocBtnType.continuationSingle) {
      events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
      if (!selectContent) {
        setIsContinueLoading(false)
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '暂未选中内容',
        })
        return
      }
      // setContinuationTmp('')
      // setContinuationList([])
      getContinueHandle(selectContent)
    }
    if (type == DocBtnType.reduce || type == DocBtnType.reduceSingle) {
      stopReduceRef.current = false
      setEditReduceList([])
      getReduceHandle(defaultContent)
      // onChangeType(DocBtnType.reduce)
    }
  }

  const stopHandle = (stopType) => {
    if (
      type == DocBtnType.proof &&
      proofShowType == ProofSelectType.basicProof
    ) {
      stopCorrectRef.current = true
      setIsProofBasicLoading(false)
    } else if (
      type == DocBtnType.proof &&
      proofShowType == ProofSelectType.officialProof
    ) {
      stopProofOfficialRef.current = true
    } else if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.commentProof
    ) {
      stopProofCommentRef.current = true
    } else if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.aiProof
    ) {
      stopProofAiRef.current = true
    } else if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.adProof
    ) {
      stopProofAdRef.current = true
    } else if (
      type == DocBtnType.proof &&
      proofShowTypeRef.current == ProofSelectType.tactfulProof &&
      proofTactfulType == ProofTactfulType.multilLangProof
    ) {
      stopProofMultilLangRef.current = true
    } else if (type == DocBtnType.rewrite) {
      stopWriteRef.current = true
      stopWritSingleRef.current = true
      setIsWriteLoading(false)
    } else if (type == DocBtnType.rewriteSingle) {
      stopWritSingleRef.current = true
      stopWriteRef.current = true
      setIsWriteLoading(false)
    } else if (type == DocBtnType.translate) {
      stopTransRef.current = true
      stopTransSingleRef.current = true
      setIsTransLoading(false)
    } else if (type == DocBtnType.translateSingle) {
      stopTransSingleRef.current = true
      stopTransRef.current = true
      setIsTransLoading(false)
    } else if (type == DocBtnType.continuationSingle) {
      // setContinuationList([])

      setContinuationTmp(continuationTmp)
      setIsContinueLoading(false)
    } else if (type == DocBtnType.reduce) {
      stopReduceRef.current = true
      stopReduceSingleRef.current = true
      setIsReduceLoading(false)
    } else if (type == DocBtnType.reduceSingle) {
      stopReduceRef.current = true
      stopReduceSingleRef.current = true
      setIsReduceLoading(false)
    } else if (type == DocBtnType.directives) {
      setDirectivesInpValue('')
      setIsHasDirectivesList(false)
      setIsDirectivesLoading(false)
      setDirectivesTagVal([])
    }
  }

  const copyAllHandle = () => {
    let result
    if (type == DocBtnType.translate) {
      result = editTranslateList.reduce((_, i) => {
        return _ + i.fixContent + '\n'
      }, '')
    } else if (type == DocBtnType.rewrite) {
      result = editRewriteList.reduce((_, i) => {
        return _ + i.fixContent + '\n'
      }, '')
    } else if (type == DocBtnType.reduce) {
      result = editReduceList.reduce((_, i) => {
        return _ + i.fixContent + '\n'
      }, '')
    }
    copy(result, {
      format: 'text/plain',
    })
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  const replaceAllHandle = () => {
    if (type == DocBtnType.translate) {
      showModal('ConfirmModal', {
        title: '全部替换',
        content: `确认全部替换？`,

        onConfirm: async () => {
          events.emit(ACTIONS.REPLACE_ALL_EDITOR, {
            list: editTranslateList,
          })
          setEditTranslateList([])
        },
      })
    } else if (type == DocBtnType.rewrite) {
      showModal('ConfirmModal', {
        title: '全部替换',
        content: `确认全部替换？`,

        onConfirm: async () => {
          events.emit(ACTIONS.REPLACE_ALL_EDITOR, {
            list: editRewriteList,
          })
          setEditReduceList([])
          setEditRewriteList([])
          setEditTranslateList([])
        },
      })
    } else if (type == DocBtnType.reduce) {
      showModal('ConfirmModal', {
        title: '全部替换',
        content: `确认全部替换？`,

        onConfirm: async () => {
          events.emit(ACTIONS.REPLACE_ALL_EDITOR, {
            list: editReduceList,
          })
          setEditReduceList([])
          setEditRewriteList([])
          setEditTranslateList([])
        },
      })
    }
  }

  const correctAllHandle = () => {
    let correctNum = puncNum + grammarNum + wordNum
    if (!correctNum) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '目前没有问题',
      })
      return
    }
    showModal('ConfirmModal', {
      title: '全部替换',
      content: `确认全部替换？`,

      onConfirm: async () => {
        events.emit(ACTIONS.PROOF_REPLACE_ALL, {
          list: proofListRef.current,
        })
        proofListRef.current = []
        setPuncNum(0)
        setGrammarNum(0)
        setWordNum(0)
        setProofGrammarList([])
        setProofPuncList([])
        setProofWordList([])
      },
    })
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  const toolsLoadingHandle = (loading) => {
    setIsToolsLoading(loading)
  }

  const proofOfficialLoadingHandle = (loading) => {
    setIsProofOfficialLoading(loading)
  }

  return (
    <div className={`${style.tool_mod} ${style[theme]}`}>
      <div className={style.tool_header}>
        <div className={style.wp}>
          {/* 增加五个loading状态 */}
          <ToolHeaderMod
            theme={theme}
            type={type}
            proofShowType={proofShowType}
            proofTactfulType={proofTactfulType}
            title={title}
            searchInput={searchInput}
            showBackTool={showBackTool}
            isLoading={isLoading}
            isTransLoading={isTransLoading}
            isWriteLoading={isWriteLoading}
            isCorrectLoading={isProofBasicLoading}
            isContinueLoading={isContinueLoading}
            isReduceLoading={isReduceLoading}
            isDirectivesLoading={isDirectivesLoading}
            isToolsLoading={isToolsLoading}
            isProofOfficialLoading={isProofOfficialLoading}
            isProofCommentLoading={isProofCommentLoading}
            isProofAiLoading={isProofAiLoading}
            isProofAdLoading={isProofAdLoading}
            isProofMultilLangLoading={isProofMultilLangLoading}
            showAllReplace={
              (editTranslateList.length > 0 &&
                type == DocBtnType.translate &&
                !isTransLoading) ||
              (editRewriteList.length > 0 &&
                type == DocBtnType.rewrite &&
                !isWriteLoading) ||
              (editReduceList.length > 0 &&
                type == DocBtnType.reduce &&
                !isReduceLoading)
            }
            closeTool={onClose}
            onSearch={searchHandle}
            onChangeOrder={changeOrderHandle}
            onChangeProofOrder={changeProofOrderHandle}
            onRefresh={refreshHandle}
            onStop={stopHandle}
            onCopyAll={copyAllHandle}
            onReplaceAll={replaceAllHandle}
            onCollectType={(type) => setCollectType(type)}
            onCorrectAll={correctAllHandle}
          />
        </div>
      </div>
      {type == DocBtnType.proof &&
        proofShowType == ProofSelectType.basicProof && (
          <div
            style={{
              paddingTop: '30px',
            }}>
            <ProofNumsMod
              list={proofBasicList}
              activeType={proofType}
              onChangeProofType={(type) =>
                changeProofTypeHandle(type)
              }></ProofNumsMod>
          </div>
        )}

      {type == DocBtnType.proof &&
        proofShowTypeRef.current == ProofSelectType.officialProof && (
          <div
            style={{
              paddingTop: '30px',
            }}>
            <ProofOfficialMod
              onProofOfficialData={(data) => setProofOfficialData(data)}
              proofOfficialData={proofOfficialData}
              defaultContent={defaultContent}
              isProofOfficialLoading={isProofOfficialLoading}
              stopProofOfficial={stopProofOfficialRef.current}
              onProofLoading={(loading) =>
                proofOfficialLoadingHandle(loading)
              }></ProofOfficialMod>
          </div>
        )}
      {type == DocBtnType.proof &&
        proofShowTypeRef.current == ProofSelectType.tactfulProof && (
          <div
            style={{
              paddingTop: '30px',
            }}>
            <ProofTactfulMod
              onProofTactfulType={(type) => setProofTactfulType(type)}
              commentProofListDefalut={commentProofList}
              aiProofListDefalut={aiProofList}
              adProofListDefalut={adProofList}
              multilLangProofListDefalut={multilLangProofList}
              onCommentProofList={(data) => setCommentProofList(data)}
              onAiProofList={(data) => setAiProofList(data)}
              onAdProofList={(data) => setAdProofList(data)}
              onMultilLangProof={(data) => setMultilLangProofList(data)}
              defaultContent={defaultContent}
              stopProofComment={stopProofCommentRef.current}
              stopProofAi={stopProofAiRef.current}
              stopProofAd={stopProofAdRef.current}
              stopProofMultilLang={stopProofMultilLangRef.current}
              onProofTactfulCommentLoading={(loading) =>
                setIsProofCommentLoading(loading)
              }
              onProofTactfulAiLoading={(loading) =>
                setIsProofAiLoading(loading)
              }
              onProofTactfulAdLoading={(loading) =>
                setIsProofAdLoading(loading)
              }
              onProofTactfulMultilLangLoading={(loading) =>
                setIsProofMultilLangLoading(loading)
              }></ProofTactfulMod>
          </div>
        )}

      {/*具体内容*/}
      <div className={style.content}>
        <ScrollToBottom
          className={`${style.wp} ${
            type == DocBtnType.proof && style.wpProof
          }`}>
          {type == DocBtnType.proof &&
          proofType == ProofType.wordProof &&
          proofShowTypeRef.current == ProofSelectType.basicProof &&
          !nonEmptyArray(proofWordList) ? (
            <div style={{ height: 'inherit' }}>
              <EmptyView
                type="proofEmpty"
                title="全面检查您文档中的单词、标点、文法等错误"
              />
            </div>
          ) : (
            type == DocBtnType.proof &&
            proofType == ProofType.wordProof &&
            proofShowTypeRef.current == ProofSelectType.basicProof && (
              <div
                style={{
                  height: '68vh',
                  opacity: isProofBasicLoading ? 0.5 : 1,
                  paddingTop: '30px',
                }}
                className={`${style[`doc-list`]}`}>
                {proofWordList.map((i, index) => {
                  return (
                    <CorrectEditItem
                      key={index}
                      item={i}
                      id={index}
                      color={proofBasicList[proofType].color}
                      boldColor={proofBasicList[proofType].boldColor}
                      replaceId={i.nodeIndex + '-' + i.sentenceId}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      isLoading={isProofBasicLoading}
                      nodeIndex={i.nodeIndex}
                      content={i.content}
                      onReplace={(type, id) => replaceProofHandle(type, id)}
                      onDelete={(type, id) => DeleteProofHandle(type, id)}
                      onExpand={
                        isLoading ? null : (id) => handleClickExpand(id)
                      }
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.proof &&
          proofType == ProofType.puncProof &&
          proofShowTypeRef.current == ProofSelectType.basicProof &&
          !nonEmptyArray(proofPuncList) ? (
            <div style={{ height: 'inherit' }}>
              <EmptyView
                type="proofEmpty"
                title="全面检查您文档中的单词、标点、文法等错误"
              />
            </div>
          ) : (
            type == DocBtnType.proof &&
            proofType == ProofType.puncProof &&
            proofShowTypeRef.current == ProofSelectType.basicProof && (
              <div
                style={{
                  height: '68vh',
                  opacity: isProofBasicLoading ? 0.5 : 1,
                  paddingTop: '30px',
                }}
                className={`${style[`doc-list`]}`}>
                {proofPuncList.map((i, index) => {
                  return (
                    <CorrectEditItem
                      key={index}
                      item={i}
                      id={index}
                      color={proofBasicList[proofType].color}
                      boldColor={proofBasicList[proofType].boldColor}
                      replaceId={i.nodeIndex + '-' + i.sentenceId}
                      nodeIndex={i.nodeIndex}
                      content={i.content}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      isLoading={isProofBasicLoading}
                      onReplace={(type, id) => replaceProofHandle(type, id)}
                      onDelete={(type, id) => DeleteProofHandle(type, id)}
                      onExpand={
                        isLoading ? null : (id) => handleClickExpand(id)
                      }
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.proof &&
          proofType == ProofType.grammarProof &&
          proofShowTypeRef.current == ProofSelectType.basicProof &&
          !nonEmptyArray(proofGrammarList) ? (
            <div style={{ height: 'inherit', opacity: isLoading ? 0.5 : 1 }}>
              <EmptyView
                type="proofEmpty"
                title="全面检查您文档中的单词、标点、文法等错误"
              />
            </div>
          ) : (
            type == DocBtnType.proof &&
            proofType == ProofType.grammarProof &&
            proofShowTypeRef.current == ProofSelectType.basicProof && (
              <div
                style={{
                  height: '68vh',
                  opacity: isProofBasicLoading ? 0.5 : 1,
                  paddingTop: '30px',
                }}
                className={`${style[`doc-list`]}`}>
                {proofGrammarList.map((i, index) => {
                  return (
                    <CorrectEditItem
                      key={index}
                      item={i}
                      id={index}
                      color={proofBasicList[proofType].color}
                      boldColor={proofBasicList[proofType].boldColor}
                      replaceId={i.nodeIndex + '-' + i.sentenceId}
                      content={i.content}
                      nodeIndex={i.nodeIndex}
                      isLoading={isProofBasicLoading}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onDelete={(type, id) => DeleteProofHandle(type, id)}
                      onReplace={(type, id) => replaceProofHandle(type, id)}
                      onExpand={
                        isLoading ? null : (id) => handleClickExpand(id)
                      }
                    />
                  )
                })}
              </div>
            )
          )}

          {type == DocBtnType.rewrite && !nonEmptyArray(editRewriteList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="rewriteEmpty" title="智能AI修改，全面改写润色" />
            </div>
          ) : (
            type == DocBtnType.rewrite && (
              <div className={`${style[`doc-list`]}`}>
                {editRewriteList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                      rewriteShowType={rewriteShowType}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.rewriteSingle &&
          !nonEmptyArray(editRewriteList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="rewriteEmpty" title="智能AI修改，全面改写润色" />
            </div>
          ) : (
            type == DocBtnType.rewriteSingle && (
              <div
                className={`${style[`doc-list`]}`}
                style={{ paddingTop: '20px' }}>
                {editRewriteList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                      rewriteShowType={rewriteShowType}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.reduce && !nonEmptyArray(editReduceList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="reduceEmpty" title="实时多种语言降重文档内容" />
            </div>
          ) : (
            type == DocBtnType.reduce && (
              <div className={`${style[`doc-list`]}`}>
                {editReduceList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      rewriteShowType={reduceShowType}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.reduceSingle && !nonEmptyArray(editReduceList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="reduceEmpty" title="实时多种语言降重文档内容" />
            </div>
          ) : (
            type == DocBtnType.reduceSingle && (
              <div
                className={`${style[`doc-list`]}`}
                style={{ paddingTop: '20px' }}>
                {editReduceList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      rewriteShowType={reduceShowType}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.translate && !nonEmptyArray(editTranslateList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView
                type="translateEmpty"
                title="实时多种语言翻译文档内容"
              />
            </div>
          ) : (
            type == DocBtnType.translate && (
              <div className={`${style[`doc-list`]}`}>
                {editTranslateList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.translateSingle &&
          !nonEmptyArray(editTranslateList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView
                type="translateEmpty"
                title="实时多种语言翻译文档内容"
              />
            </div>
          ) : (
            type == DocBtnType.translateSingle && (
              <div
                className={`${style[`doc-list`]}`}
                style={{ paddingTop: '20px' }}>
                {editTranslateList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={i.isOpen}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      onReplace={(type, id) => replaceRewriteAndTrans(type, id)}
                    />
                  )
                })}
              </div>
            )
          )}
          {type == DocBtnType.continuationSingle &&
          !nonEmptyArray(continuationList) &&
          continuationTmp == '' ? (
            <div style={{ height: '84vh' }}>
              <EmptyView
                type="continueEmpty"
                title="在左侧编辑器选中文字后，在弹出浮窗里，点击“AI工具” -> “扩写”，即可开始扩写哦！"
              />
            </div>
          ) : (
            type == DocBtnType.continuationSingle && (
              <div
                className={`${style[`doc-list`]}`}
                style={{ paddingTop: '20px' }}>
                {stopContinueRef.current && continuationTmp.length > 0 && (
                  <EditorItem
                    id={0}
                    defaultContent={selectContent}
                    fixContent={continuationTmp}
                    expand={true}
                    onExpand={(id) => handleRewriteClickExpand(id)}
                    dotColor={dotColor}
                    type={type}
                    isReplace={false}
                    isContinueLoading={isContinueLoading}
                    nodeInfo={nodeInfo}
                    singleRewrite={true}
                    onReplace={(type, id) => continueHandle(type, id)}
                  />
                )}
                {continuationList.map((i, index) => {
                  return (
                    <EditorItem
                      key={index}
                      id={index}
                      defaultContent={i.defaultContent}
                      fixContent={i.fixContent}
                      expand={true}
                      isReplace={i.isReplace}
                      onExpand={(id) => handleRewriteClickExpand(id)}
                      dotColor={dotColor}
                      type={type}
                      nodeInfo={nodeInfo}
                      nodeIndex={i.nodeIndex}
                      singleRewrite={i.singleRewrite}
                      onReplace={(type, id) => continueHandle(type, id)}
                    />
                  )
                })}
                {/* <p>{continuationTmp}</p> */}
              </div>
            )
          )}
          {type == DocBtnType.collect && (
            <div style={{ height: '84vh', padding: '0 45px' }}>
              <CollectMod
                collectType={collectType}
                searchInput={searchInput}
                onCollectEditHandle={collectEditHandle}
              />
            </div>
          )}
          {type == DocBtnType.assistant && (
            <div style={{ height: '100%', padding: '0 45px' }}>
              <KeepAlive>
                <ChatGPT
                  selectContentPop={selectContent}
                  isEditorialAssistant={true}
                  special={true}
                />
              </KeepAlive>
            </div>
          )}
          {type == DocBtnType.tools && (
            <div style={{ height: '84vh' }}>
              <Tools
                tabs={[]}
                docTitle={docTitle}
                selectContentPop={selectContent}
                setIsLoading={toolsLoadingHandle}
                loading={isLoading}></Tools>
            </div>
          )}
          {type == DocBtnType.directives &&
          !directivesInpValue &&
          !isHasDirectivesList &&
          !isDirectivesLoading &&
          directivesTagVal.length == 0 ? (
            <div style={{ height: '84vh' }}>
              <EmptyView
                type="directivesEmpty"
                title="在左侧写作区域随时输入“/”，即可快捷使用指令哦！"
              />
            </div>
          ) : (
            <>
              {type == DocBtnType.directives && (
                <div style={{ height: '84vh' }}>
                  <DirectivesMod
                    inputValue={directivesInpValue}
                    tagSelectList={directivesTagList}
                    directivesStartPos={directivesStartPos}
                    directivesTagVal={directivesTagVal}
                    onDelDirectivesTagVal={() => setDirectivesTagVal([])}
                    onDirectivesComplete={
                      directivesCompleteHandle
                    }></DirectivesMod>
                </div>
              )}
            </>
          )}
        </ScrollToBottom>
      </div>

      {/*三角装饰*/}
      {/* <div className={`${style['san_' + props.id]}  ${style.san}`}>
        <img src={san} alt=""></img>
      </div> */}
    </div>
  )
}

export default EditPageBox
