import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style/changePageBtn-mod.module.less'
import { GetQueryValue } from '@/utils/common'
import { IconArrowLeft, IconArrowRight } from '@arco-iconbox/react-aidb-v2'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { useLocation } from 'react-router-dom'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
interface Props {
  foldList: any
}
const ChangePageBtnMod = (props: Props) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const { foldList } = props
  const history = useHistory()
  const [docPreNext, setDocPreNext] = useState<any>()
  const locationSearch = useLocation()
  useEffect(() => {
    const res = findDocPreAndNext(
      findDocList(foldList),
      GetQueryValue('selectUuid'),
    )
    setDocPreNext(res)
  }, [foldList, locationSearch])

  function findDocPreAndNext(foldList, givenUuid) {
    const index = foldList.findIndex((item) => item.uuid === givenUuid)
    if (index === -1) {
      return { previousItem: null, nextItem: null }
    }
    const previousItem = foldList[index - 1] || null
    const nextItem = foldList[index + 1] || null
    return { previousItem, nextItem }
  }

  const findDocList = (data) => {
    const docList = []
    const traverse = (item) => {
      if (item.fileType === 0) {
        docList.push(item)
      }
      if (item.children) {
        item.children.forEach(traverse)
      }
    }
    data.forEach(traverse)
    return docList
  }
  const changeDocHandle = (id) => {
    // const urlQuery = {
    //   uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
    //   selectUuid: id,
    // } as any
    // history.push({
    //   pathname: '/documentShare',
    //   search: '?' + new URLSearchParams(urlQuery).toString(),
    // })
    // location.reload()
    events.emit(ACTIONS.SHARE_DOC_SIDE_CLICK, {
      uuid: id,
      fileType: 0,
    })
  }
  return (
    <>
      {(!!docPreNext?.previousItem || !!docPreNext?.nextItem) && (
        <div className={`${styles.changePageBox} ${styles[theme]}`}>
          {docPreNext?.previousItem && (
            <div
              className={`${styles.changePageBtn} ${styles.pre}`}
              onClick={() => changeDocHandle(docPreNext?.previousItem.uuid)}>
              <p>
                <IconArrowLeft fill="#9f9f9f" />
                <span style={{ marginLeft: '10px' }}>上一篇</span>
              </p>
              <p className={styles.title}>{docPreNext?.previousItem?.title}</p>
            </div>
          )}
          {docPreNext?.nextItem && (
            <div
              className={`${styles.changePageBtn} ${styles.next}`}
              onClick={() => changeDocHandle(docPreNext?.nextItem.uuid)}>
              <p style={{ textAlign: 'right' }}>
                <span style={{ marginRight: '10px' }}>下一篇</span>
                <IconArrowRight fill="#9f9f9f" />
              </p>
              <p className={styles.title} style={{ textAlign: 'right' }}>
                {docPreNext?.nextItem?.title}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ChangePageBtnMod
