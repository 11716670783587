import { events } from '@/helpers/event-emitter'
import { ACTIONS } from './constants'

export function showModal(type: string, props?: any) {
  events.emit(ACTIONS.SHOW_MODAL, { type, props })
}

export function hideModal(type: string, props?: any) {
  events.emit(ACTIONS.HIDE_MODAL, { type, props })
}

// 更新弹框内容
export function updateModal(type: string, props?: any) {
  events.emit(ACTIONS.UPDTAE_MODAL, { type, props })
}

export function showDrawer(type: string, props?: any) {
  events.emit(ACTIONS.SHOW_DRAWER, { type, props })
}

export function hideDrawer(type: string, props?: any) {
  events.emit(ACTIONS.HIDE_DRAWER, { type, props })
}

// 更新弹框内容
export function updateDrawer(type: string, props?: any) {
  events.emit(ACTIONS.UPDATE_DRAWER, { type, props })
}

//数据来源于编辑器
export function fromEditor(props?: any) {
  events.emit(ACTIONS.FROM_EDITOR, { props })
}

//给数据编辑器
export function toEditor(props?: any) {
  events.emit(ACTIONS.TO_EDITOR, { props })
}
