import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import palette from '@/helpers/palette'
import {
  EventChannel,
  Inline,
  SylApi,
  SylController,
  SylPlugin,
} from '@syllepsis/adapter'
import Styled from 'styled-components'
import { InnerTool } from '@/share/config'
import { setBlockType } from 'prosemirror-commands'
import {
  IconArrowRight,
  IconClosure,
  IconEdit,
  IconErrorTip,
  IconFillIn,
  IconLink,
  IconLink2,
} from '@arco-iconbox/react-aidb-v2'
import { Checkbox, Input, InputTag, Message } from '@arco-design/web-react'
import btn from '@/components/Ui/btn'
import Btn from '@/components/Ui/btn'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import TipPromptProps from '@/components/DocumentEdit/tipPrompt'
import PromptMod from '@/pages/toolbox/PromptMod'
import request from '@/utils/request'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
const TextArea = Input.TextArea
const MenuStyle = Styled.div<{
  proseMirrorWidth: number
  computedLeft: number
  computedTop: number
  computedIndex: number
  tipPromptOpen: boolean
  theme: string
}>`
    position: absolute;
    z-index:100;
    background: ${({ theme }) => (theme == 'dark' ? 'black' : '#fff')};
    top:${({ computedTop }) => (computedTop ? computedTop + 'px' : '0px')};
    /* left:${({ computedLeft }) =>
      computedLeft ? computedLeft + 'px' : '0px'}; */
    .mainWrapper{
      width:${({ proseMirrorWidth }) =>
        proseMirrorWidth ? proseMirrorWidth + 'px' : '700px'};
      /* height:56px; */
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
      border-radius: 4px;
      border:${({ tipPromptOpen }) =>
        tipPromptOpen
          ? `2px solid ${palette.directivesBold}`
          : '2px solid ##EFEFEF;'}; 
      .tagWrapper{
        background-color:${({ theme }) =>
          theme == 'dark' ? '#1e1e1f' : '#f9f9f9'};
        
        border-radius: 3px 3px 0px 0px;
        .dragBox{
          display: flex;
          height: 48px;
          padding:8px;
          .dragItem{
            top:${({ computedTop }) =>
              computedTop ? computedTop + 'px !important' : '0px'};
            left:${
              ({ computedLeft, proseMirrorWidth, computedIndex }) =>
                177 * computedIndex + 'px !important'
              /* computedLeft || proseMirrorWidth
                ? computedLeft + 'px !important'
                : '0px' */
            };
            .category-item{
              display: flex;
              .tagBox{
                height: 30px;
                border-radius: 3px;
                border: 1px solid #FFDDFA;
                padding:6px 12px;
                display: flex;
                align-items: center;
                background:${palette.directives};
                justify-content: center;
                color: ${palette.directivesBold};
                .tagTitle{
                  font-size: 12px;
                }
                .divider{
                  line-height: 0;
                  margin: auto 10px;
                }
              }
              .link{
                  line-height: 0;
                  height: 30px;
                  margin: auto 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  
              }
              svg{
                fill: ${palette.directivesBold};
              }
            }
          }
          
        }
      }
      .inputWrapper{
        /* height: 46px; */
        background-color:${({ theme }) =>
          theme == 'dark' ? '#19191a' : '#fff'};
        display: flex;
        border-radius: 4px;
        padding: 8px 50px 10px 0px;
      }
    }

    .arco-input-tag {
      background: ${({ theme }) => (theme == 'dark' ? '#19191a' : '#fff')};
    }

    .arco-input-tag.arco-input-tag-focus{
      border: none !important;
      outline: none !important;
    }
    
    input:focus{
      border: none !important;
      outline: none !important;
      border-color:red;
    }

    .arco-input-tag-inner{
      input{
        padding-left:0; 
      }
    }
   
    .arco-input{
      height: auto;
    }
    .arco-input-inner-wrapper{
      background: #FFFFFF;
    }
    .arco-textarea{
      border: none !important;
      outline: none !important;
    }
    .arco-textarea:focus{
      border: none !important;
      outline: none !important;
    }
    .submitBtn{
      position: absolute;
      right: 10px;
      bottom: 8px;
    }
    .menu{
        /* width: 260px;
        height:264px; */
        overflow-x: hidden;
        position: absolute;
        overflow-y:auto;
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
        border-radius: 4px;
        border: ${({ theme }) =>
          theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #efefef'};
        margin-top:8px;
        background-color:${({ theme }) =>
          theme == 'dark' ? '#1e1e1f' : '#fff'};
        padding:18px 0px;
        .category-title{
            display: flex;
            box-sizing: border-box;
            font-weight: 500;
            font-size:14px;
            color: #000;
            line-height: 20px;
            padding: 10px 24px;
        }
        .item{
            display: flex;
            position: relative;
            line-height: 20px;
            cursor: pointer;
            padding: 10px 24px;
            color: #848480;
            font-size:14px;
            transition: .2s;
            justify-content: space-between;
            :hover{
                background: #f7f7f7;
            }
        }
    }
    .selectContent-item{
      display: flex;
      margin-right:6px;
      .tagBox{
        height: 30px;
        border-radius: 3px;
        border:  ${({ theme }) =>
          theme == 'dark'
            ? '1px solid #2c2c2c'
            : `1px solid ${palette.grayEF}`};
        padding:6px 12px;
        display: flex;
        align-items: center;
        background:  ${({ theme }) => (theme == 'dark' ? '#19191a' : 'white')};
        justify-content: center;
        color: ${({ theme }) =>
          theme == 'dark' ? '#969696' : palette.black70};
        .tagTitle{
          font-size: 12px;
        }
        .divider{
          color: #efefef;
          line-height: 0;
          margin: auto 10px;
        }
      }
      .link{
          line-height: 0;
          height: 30px;
          margin: auto 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          
      }
      svg{
        fill: ${palette.black70};
      }
    }
`

interface ILinkModalState {
  open: boolean
  text: string
  href: string
  pos: number
  insertType: number
  errorText: string
  proseMirrorWidth: number
  computedLeft: number
  computedTop: number
  computedIndex: number
  inputValue: string
  tagSelectList: any
  tagList: any
  startPos: number
  tagVal: string[]
  openType: number
  tipPromptOpen: boolean
}

interface ILinkModalProps {
  editor: SylApi
}

class LinkModal extends React.PureComponent<ILinkModalProps, ILinkModalState> {
  constructor(props: ILinkModalProps) {
    super(props)
    this.getList()
    events.on(ACTIONS.DIRECTIVES_ENTER_INNER, this.enterInnerHandle)
  }

  editorWillUnmount() {
    events.off(ACTIONS.DIRECTIVES_ENTER_INNER, this.enterInnerHandle)
  }

  public state = {
    open: false,
    text: '',
    href: '',
    pos: 0,
    insertType: 2,
    errorText: '',
    proseMirrorWidth: 0,
    computedLeft: 0,
    computedTop: 0,
    computedIndex: 0,
    inputValue: '',
    tagSelectList: [],
    tagList: [],
    startPos: 0,
    tagVal: [],
    openType: 0,
    tipPromptOpen: false,
  }
  componentDidUpdate() {
    if (this.state.inputValue) {
      this.setState({
        tipPromptOpen: true,
      })
    }
    if (this.state.openType == 1) {
      let textarea = document.querySelector(
        '.arco-input-tag-input',
      ) as HTMLElement
      textarea && textarea.focus()
    }
  }

  public enterInnerHandle = () => {
    if (this.state.open) {
      let textarea = document.querySelector(
        '.arco-input-tag-input',
      ) as HTMLElement
      textarea && textarea.focus()
      this.setState({
        tipPromptOpen: true,
      })
      // const elementIsInFocus = (el) => el === document.activeElement
      // if (elementIsInFocus(textarea)) {
      //   this.setState({
      //     tipPromptOpen: false,
      //   })
      // }
    }
  }

  public getList = () => {
    let listArr = []
    new Promise((resolve, reject) => {
      request
        .get('https://support.wuz.com.cn/wp-json/acf/v3/options/options')
        .then(async (res) => {
          if (JSON.stringify(res.data.acf) != '{}') {
            const acf = res.data.acf
            const promptsCatArray = acf['prompts_cat'].split('\r\n')
            const promptsCatNameArray = acf['prompts_cat_name'].split('\r\n')

            promptsCatArray.forEach((item, index) => {
              listArr.push({
                id: index,
                title: item,
                category: promptsCatNameArray[index],
                children: [],
              })
            })
            await (listArr.length && resolve(''))
          }
        })
    }).then(() => {
      request
        .get(
          'https://support.wuz.com.cn/wp-json/acf/v3/prompt?per_page=9999&order=asc',
        )
        .then((res) => {
          const result = res.data

          if (JSON.stringify(result.acf) != '{}') {
            result.forEach((item1, index1) => {
              item1.acf.cat.forEach((item2, index2) => {
                listArr.forEach((item3, index3) => {
                  if (item2 == item3.category) {
                    item3.children.push({
                      id: index1,
                      title: item1.acf.title,
                      content: item1.acf.prompt,
                      info: item1.acf.info,
                    })
                  }
                })
              })
            })

            listArr.forEach((item, index) => {
              item.id = index
              item.children.forEach((item1, index1) => {
                item1.id = index1
              })
            })
            this.setState({
              tagList: listArr.map((_) => {
                return {
                  ..._,
                  children: _.children.map((i) => {
                    return {
                      ...i,
                      checked: false,
                    }
                  }),
                }
              }),
            })
          }
        })
    })
  }

  public selectTagHandle = (firstSelectIndex, secondSelectIndex, title) => {
    if (this.state.tagSelectList.length > 3) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '最多选择三个指令',
      })
      return
    } else {
      const selectTag = {
        id: firstSelectIndex + secondSelectIndex,
        name: title,
      }
      this.state.tagSelectList.push(selectTag)
      this.setState({
        tagSelectList: this.state.tagSelectList,
      })
    }
  }

  public submitHandle = () => {
    const { inputValue, startPos, tagSelectList, tagVal } = this.state
    if (!inputValue && tagVal.length == 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请输入内容',
      })
      return
    }
    this.props.editor.delete(startPos, 1)
    events.emit(ACTIONS.FROM_EDITOR, {
      setSelectContent: null,
      barActiveIndex: DocBtnType.directives,
      nodeInfo: null,
    })
    events.emit(ACTIONS.FROM_DIRECTIVES_CLICK_EDITOR, {
      tagSelectList: tagSelectList,
      inputValue: inputValue,
      startPos: startPos,
      tagVal: tagVal,
      // nodeInfo: ,
    })
    this.setState({
      open: false,
      inputValue: '',
      tagSelectList: [],
    })
  }

  public render() {
    const {
      open,
      proseMirrorWidth,
      computedLeft,
      computedTop,
      inputValue,
      tagSelectList,
      tagList,
      startPos,
      tipPromptOpen,
      computedIndex,
    } = this.state

    const inputChangeHandle = (e) => {
      this.setState({
        inputValue: e,
        tipPromptOpen: true,
      })
    }
    const selectTagHandle = (
      firstSelectIndex,
      secondSelectIndex,
      title,
      prompt,
      checked,
    ) => {
      if (checked) {
        if (tagSelectList.length > 2) {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '最多选择三个指令',
          })
          return
        }
        tagSelectList.push({
          id: 'category' + firstSelectIndex + '-' + secondSelectIndex,
          firstSelectIndex: firstSelectIndex,
          secondSelectIndex: secondSelectIndex,
          prompt,
          name: title,
        })
        this.setState({
          tagSelectList: [...tagSelectList],
        })
      } else {
        let arr = tagSelectList.filter((_) => {
          return _.id != 'category' + firstSelectIndex + '-' + secondSelectIndex
        })
        this.setState({
          tagSelectList: [...arr],
        })
      }
    }

    const handleDragEnd = (result) => {
      if (!result.destination) return

      const reorderedCategories = Array.from(tagSelectList)
      const [movedCategory] = reorderedCategories.splice(result.source.index, 1)
      reorderedCategories.splice(result.destination.index, 0, movedCategory)
      this.setState({
        tagSelectList: reorderedCategories,
      })
    }

    const deleteTagHandle = (name) => {
      this.setState({
        tagSelectList: tagSelectList.filter((_) => _.name != name),
      })
    }

    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && e.shiftKey === true) {
        this.submitHandle()
      } else if (e.keyCode === 27 && e.shiftKey === false) {
        this.setState({
          open: false,
        })
      }
    }

    const inputTagChangeHandle = (value, reason) => {
      if (reason == 'remove') {
        this.setState({
          tagVal: [],
        })
      }
    }

    const tagRender = (props) => {
      const { label, value, closable, onClose } = props
      return (
        <div className="selectContent-item">
          <div className="tagBox">
            <div className="tagTitle">
              {label.length > 4 ? label.substring(0, 4) + '...' : label}
            </div>
            <span className="divider">|</span>
            <IconClosure onClick={deleteSelectTagHandle} />
          </div>
        </div>
      )
    }

    const deleteSelectTagHandle = () => {
      this.setState({
        tagVal: [],
      })
    }

    const inputTagFocusHandle = () => {
      this.setState({
        tipPromptOpen: true,
      })
    }

    // 指令底部遮挡解决
    setTimeout(() => {
      // 编辑器内容视口 1130*807
      const sylEditorBoxElement = document.getElementById('sylEditorBox')
      // tool浮窗
      const mainWrapperElement = document.querySelector('.mainWrapper')
      // tool浮窗下指令库
      const mainMenuElement = document.querySelector('.mainMenu')
      // 编辑器所有内容
      const sylEditorElement = document.querySelector('.syl-editor')

      if (open && !tipPromptOpen) {
        // console.log('只打开了tool！', open, tipPromptOpen)
        if (mainWrapperElement && sylEditorBoxElement) {
          // 获取元素的位置和尺寸信息
          const sylEditorBoxRect = sylEditorBoxElement.getBoundingClientRect()
          const mainWrapperRect = mainWrapperElement.getBoundingClientRect()
          const diffBottom = sylEditorBoxRect.bottom - mainWrapperRect.bottom

          if (
            sylEditorBoxRect.bottom &&
            mainWrapperRect.bottom &&
            diffBottom < 0
          ) {
            // 计算 mainWrapperElement 底部距离sylEditorBoxElement底部的差值
            // sylEditorBoxElement.scrollTop -= diffBottom - 10
            setTimeout(() => {
              sylEditorBoxElement.scrollTop -= diffBottom - 20
            }, 10)
          }
        }
      } else if (open && tipPromptOpen) {
        // console.log('tool和指令库都打开：', open, tipPromptOpen)
        if (mainMenuElement && sylEditorBoxElement) {
          // 获取元素的位置和尺寸信息
          const sylEditorBoxRect = sylEditorBoxElement.getBoundingClientRect()
          const mainWrapperRect = mainMenuElement.getBoundingClientRect()
          const diffBottom = sylEditorBoxRect.bottom - mainWrapperRect.bottom

          if (
            sylEditorBoxRect.bottom &&
            mainWrapperRect.bottom &&
            diffBottom < 0
          ) {
            // 计算 mainMenuElement 底部距离sylEditorBoxElement底部的差值
            setTimeout(() => {
              sylEditorBoxElement.scrollTop -= diffBottom - 20
            }, 10)
          }
        }
      }
    }, 0)

    return (
      <>
        {open && (
          <MenuStyle
            theme={localStorage.getItem('theme')}
            proseMirrorWidth={proseMirrorWidth}
            computedLeft={computedLeft}
            computedTop={computedTop}
            computedIndex={computedIndex}
            tipPromptOpen={tipPromptOpen}>
            <div className="mainWrapper">
              {tagSelectList.length > 0 && (
                <div className="tagWrapper">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="categories"
                      direction="horizontal"
                      listType="CARD">
                      {(provided) => (
                        <div
                          className="dragBox"
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          {this.state.tagSelectList.map((category, index) => (
                            <Draggable
                              key={category.id}
                              draggableId={category.id}
                              index={index}>
                              {(provided) => (
                                <div
                                  className="dragItem"
                                  onMouseDown={() => {
                                    this.setState({
                                      computedIndex: index,
                                    })
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <div className="category-item">
                                    <div
                                      className="tagBox"
                                      {...provided.dragHandleProps}>
                                      <div className="tagTitle">
                                        {category.name.length > 8
                                          ? category.name.substring(0, 8) +
                                            '...'
                                          : category.name}
                                      </div>
                                      <span className="divider">|</span>
                                      <IconClosure
                                        onClick={() =>
                                          deleteTagHandle(category.name)
                                        }
                                      />
                                    </div>
                                    {index < tagSelectList.length - 1 && (
                                      <span className="link">
                                        <IconLink2 />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}

              {/* <div className="inputWrapper">
                <InputTag
                  placeholder="选择或直接输入指令（如：写个周报。支持多选，Shift + Enter 聚焦指令输入框）"
                  value={this.state.tagVal}
                  inputValue={this.state.inputValue}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onInputChange={inputChangeHandle}
                  onChange={inputTagChangeHandle}
                  renderTag={tagRender}
                  onFocus={inputTagFocusHandle}
                  style={{ width: proseMirrorWidth - 68, padding: '0 10px' }}
                />

                <div className="submitBtn">
                  <Btn
                    Icon={<IconArrowRight />}
                    width={40}
                    height={40}
                    backgroundColor={
                      'linear-gradient(135deg, #C437AE 0%, #EA3DA8 100%)'
                    }
                    hoverBackgroundColor={
                      'linear-gradient(135deg, #C437AE 0%, #EA3DA8 100%)'
                    }
                    disabled={
                      (this.state.tagVal == null ||
                        this.state.tagVal.length == 0) &&
                      !this.state.inputValue
                    }
                    showBorder={'none'}
                    hoverBorderColor={'transparent'}
                    svgColor={
                      (this.state.tagVal == null ||
                        this.state.tagVal.length == 0) &&
                      !this.state.inputValue
                        ? palette.gray8F
                        : palette.white
                    }
                    onClick={this.submitHandle}
                  />
                </div>
              </div> */}
            </div>
            {tipPromptOpen && (
              <ul className="menu mainMenu">
                <TipPromptProps
                  title={'指令库'}
                  firstTitle={'分类'}
                  secondTitle={'条目'}
                  tagList={tagList}
                  tagSelectList={tagSelectList}
                  onSelectTag={selectTagHandle}
                />
              </ul>
            )}
          </MenuStyle>
        )}
      </>
    )
  }
}

interface InnerToolAttrs {
  class: string
}

const PLUGIN_NAME = 'InnerTool'

class InnerToolSchema extends Inline<InnerToolAttrs> {
  public name = PLUGIN_NAME
}

class InnerToolController extends SylController {
  public name = PLUGIN_NAME
  public modal: LinkModal | null = null
  public modalContainer: HTMLElement
  constructor(editor: SylApi, props) {
    super(editor, props)
    var tempDiv = document.createElement('div') // 创建一个临时的 div 元素
    editor.view.dom.addEventListener('keydown', this.handleKeyDown)
    editor.on(EventChannel.LocalEvent.ON_FOCUS, this.handleTextChange)
    events.on(ACTIONS.OPEN_DIRECTIVES_EDITOR, this.openInnerHandle)
    const extraDom = (
      <LinkModal
        ref={(el) => {
          if (el) {
            this.modal = el
          }
        }}
        editor={editor}
      />
    )
    this.modalContainer = document.createElement('div')
    this.editor.root.appendChild(this.modalContainer)
    ReactDOM.render(extraDom, this.modalContainer)
    events.on(ACTIONS.CLOSE_DIRECTIVES_EDITOR, this.closeModal)
    events.on(ACTIONS.OPEN_DIRECTIVES_EDITOR, this.openInnerHandle)
  }

  editorWillUnmount() {
    events.off(ACTIONS.CLOSE_DIRECTIVES_EDITOR, this.closeModal)
    events.off(ACTIONS.OPEN_DIRECTIVES_EDITOR, this.openInnerHandle)
    ReactDOM.unmountComponentAtNode(this.modalContainer)
  }

  openInnerHandle = (payload) => {
    const { pos, selectedContent } = payload
    var proseMirrorWidth = +getComputedStyle(
      document.querySelector('.ProseMirror'),
    ).width.replace('px', '')

    var editorWidth = +getComputedStyle(
      document.querySelector('.syl-editor'),
    ).width.replace('px', '')
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(pos)
    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const computedTop = headBottom + 8 - editorTop
    const computedLeft = (editorWidth - proseMirrorWidth) / 2
    let textarea = document.querySelector(
      '.arco-input-tag-input',
    ) as HTMLElement
    textarea && textarea.focus()

    this.modal.setState({
      open: true,
      href: '',
      proseMirrorWidth: proseMirrorWidth,
      computedLeft: computedLeft,
      computedTop: computedTop,
      startPos: pos,
      inputValue: this.modal.state.inputValue,
      tagSelectList: [],
      tipPromptOpen: true,
      openType: 1,
      tagVal: selectedContent.length > 0 ? [selectedContent] : null,
    })
  }

  handleTextChange = () => {
    // console.log(this.editor.isFocused)
    // let textarea = document.querySelector(
    //   '.arco-textarea.innerToolInput',
    // ) as HTMLElement
    // textarea && textarea.focus()
    // const elementIsInFocus = (el) => el === document.activeElement
    // elementIsInFocus(textarea)

    // var proseMirrorWidth = +getComputedStyle(
    //   document.querySelector('.ProseMirror'),
    // ).width.replace('px', '')
    if (this.modal && this.modal.state.open && this.editor.isFocused) {
      this.modal.setState({
        open: false,
        href: '',
        proseMirrorWidth: 0,
        computedLeft: 0,
        computedTop: 0,
        inputValue: this.modal.state.inputValue,
        startPos: 0,
        tagSelectList: [],
        tipPromptOpen: false,
      })
    }
  }

  closeModal = () => {
    this.modal.setState({
      open: false,
      href: '',
      proseMirrorWidth: 0,
      computedLeft: 0,
      computedTop: 0,
      inputValue: '',
      startPos: 0,
      tagSelectList: [],
      tipPromptOpen: false,
    })
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === '/' && !event.ctrlKey && !event.metaKey) {
      var proseMirrorWidth = +getComputedStyle(
        document.querySelector('.ProseMirror'),
      ).width.replace('px', '')

      var editorWidth = +getComputedStyle(
        document.querySelector('#sylEditorBox'),
      ).width.replace('px', '')

      const { $from } = this.editor.view.state.selection
      const startPos = $from.pos
      // this.editor.insertText('/', { bolder: false }, startPos)
      // this.editor.setFormat({ bold: false })
      // const oldTextNode = this.editor.view.state.doc.nodeAt(startPos)
      // if (oldTextNode) {
      //   const oldTextStyle = oldTextNode.marks
      //   const tr = this.editor.view.state.tr
      //   tr.replaceWith(
      //     startPos,
      //     startPos,
      //     this.editor.view.state.schema.text('/', oldTextStyle),
      //   )
      //   this.editor.view.dispatch(tr)
      // }
      const { left: headLeft, bottom: headBottom } =
        this.editor.view.coordsAtPos(startPos)
      const {
        top: editorTop,
        left: editorLeft,
        // right: editorRight,
        bottom: editorBottom,
      } = this.editor.root.getBoundingClientRect()
      const computedLeft = (editorWidth - proseMirrorWidth) / 2
      const computedTop = headBottom + 8 - editorTop

      if (headBottom < editorBottom || headBottom > editorTop) {
        if (this.modal) {
          setTimeout(() => {
            this.modal.setState({
              open: true,
              href: '',
              proseMirrorWidth: proseMirrorWidth,
              computedLeft: computedLeft,
              computedTop: computedTop,
              startPos: startPos,
              inputValue: '',
              tagVal: [],
            })
            // this.editor.blur()
            // 获取指令弹出框input
            const mainWrapper = document.querySelector('.arco-input-tag-input')
            mainWrapper &&
              mainWrapper.addEventListener('focus', this.handleMainWrapperFocus)
            // 如果选中段落点击指令，再次输入/会直接打开指令并且不能删除/，现在解决办法：如果mainWrapper已经获得了焦点，强制使其失去焦点再获取焦点
            if (
              document.activeElement === mainWrapper &&
              mainWrapper instanceof HTMLElement
            ) {
              mainWrapper.blur()
              mainWrapper.focus()
            }
          }, 0)
        }
      }
    } else {
      if (
        !(event.key == 'Enter' || (event.key == '/' && event.metaKey)) &&
        !event.shiftKey
      ) {
        this.modal.setState({
          open: false,
          href: '',
          proseMirrorWidth: proseMirrorWidth,
          computedLeft: 0,
          computedTop: 0,
          inputValue: '',
          tagVal: [],
          startPos: 0,
          tagSelectList: [],
          openType: 0,
          tipPromptOpen: false,
        })
        // 获取指令弹出框input
        const mainWrapper = document.querySelector('.arco-input-tag-input')
        // 取消监听mainWrapper
        mainWrapper &&
          mainWrapper.removeEventListener('focus', this.handleMainWrapperFocus)
      }
    }
  }

  // 撤销用户输入的‘/’
  handleMainWrapperFocus = () => {
    // 获取当前选中位置
    const { $from } = this.editor.view.state.selection
    const startPos = $from.pos - 1 // ‘/’的位置

    // 获取要删除的字符
    const charToDelete = this.editor.view.state.doc.textBetween(
      startPos,
      startPos + 1,
    )
    // 检查是否为'/'
    if (charToDelete === '/') {
      let tr = this.editor.view.state.tr.delete(startPos, startPos + 1)
      this.editor.view.dispatch(tr)
    }

    // 撤销操作
    // this.editor.undo()
  }
}

class InnerToolPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = InnerToolController
  public Schema = InnerToolSchema
}

export { InnerToolPlugin }
