import React, { useRef } from 'react'
import { Modal, Button } from '@arco-design/web-react'

interface Props {
  visible: boolean
}
const TimedOutRefresh = (props: Props) => {
  const { visible } = props

  return (
    <div>
      <Modal
        title="连接已断开..."
        visible={visible}
        footer={
          <Button type="primary" onClick={() => window.location.reload()}>
            确认
          </Button>
        }
        closable={false}
        autoFocus={false}
        escToExit={false}
        focusLock={true}>
        <p>
          由于长时间未操作，我们已保存了您的数据并断开连接。继续使用请点击
          “确认” 刷新页面。
        </p>
      </Modal>
    </div>
  )
}

export default TimedOutRefresh
