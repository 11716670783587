/**
 * yjs config info
 */
import { switchProject } from '@/helpers/switchProject'

// wss url
export const WEBSOCKET_URL_YJS = 'wss://yjs.aidb.com.cn'
// const WEBSOCKET_URL_YJS = "ws://localhost"

// wss port
export const PORT_YJS = 443

// YJS Connection Address
export const CONNECTION_ADDRESS = (uuid) =>
  `prosemirror-${switchProject('DFZ') ? 'dfz' : 'aidb'}-` + uuid

// 定义随机颜色
export const RANDOM_COLOR =
  '#' + Math.random().toString(16).split('.')[1].slice(0, 6)
