import React from 'react'
import { IconClear } from '@arco-iconbox/react-aidb-v2'
import { SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import isEqual from 'lodash.isequal'
import { Mark } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

const PLUGIN_NAME = 'format'

const filterMark = (marks: Mark[]) =>
  marks.filter((mark) => !mark.type.spec.notClear)

class FormatController extends SylController {
  public isDisable = false

  public toolbar = {
    className: PLUGIN_NAME,
    tooltip: PLUGIN_NAME,
    icon: <IconClear useCurrentColor={true} />,
    handler(editor: SylApi) {
      const state = editor.view.state
      const tr = state.tr
      const { storedMarks, selection } = editor.view.state
      const { $from, $to } = selection
      const root = editor.view.state.doc
      const currentNode = editor.view.state.doc.nodeAt($from.pos)
      let newClass

      if (
        $from.marks().some((_) => _.type.name == 'comment') ||
        $to.marks().some((_) => _.type.name == 'comment')
      ) {
        let minPos = null
        let maxPos = null
        let maxPosNodeSize = null
        const markType = editor.view.state.schema.marks.comment // Replace 'myMark' with the name of your mark type
        root.descendants((node, pos) => {
          if (node.marks.length > 0) {
            // Check if the mark applies to this text node
            const marksToRemove = node.marks.find((mark) => {
              return (
                mark.type === markType &&
                mark.attrs.class.includes(editor.getFormat().comment.class) !==
                  -1
              )
            })

            if (
              marksToRemove &&
              marksToRemove.attrs.class.includes(
                editor.getFormat().comment.class,
              )
            ) {
              newClass = editor.getFormat().comment.class
              minPos = tr.mapping.map(pos)
              maxPosNodeSize = tr.mapping.map(pos + node.nodeSize)
              return false
            }
          }
          return true
        })
        editor.clearFormat()
        events.emit(ACTIONS.FORMAT_CREATE_COMMENT, {
          minPos: minPos,
          maxPosNodeSize: maxPosNodeSize,
          newClass: newClass,
        })
      } else {
        editor.clearFormat()
      }
    },
  }

  public keymap = {
    'Mod-e': (editor: SylApi) => {
      if (this.isDisable) return false
      const { selection } = editor.view.state
      const { $from, $to } = selection
      if ($from.marks().some((_) => _.type.name == 'comment')) {
        const newClass = $from
          .marks()
          .filter((_) => _.type.name == 'comment')[0].attrs.class
        editor.clearFormat()
        const state = editor.view.state
        const tr = state.tr
        tr.addMark(
          $from.pos,
          $to.pos,
          state.schema.marks.comment.create({
            class: newClass,
          }),
        )
        editor.view.dispatch(tr)
      } else {
        editor.clearFormat()
      }
      return true
    },
  }

  public disable = (editor: SylApi) => {
    const { storedMarks, selection } = editor.view.state
    const { $from } = selection

    if (storedMarks && filterMark([...storedMarks]).length) return false
    if (!storedMarks && filterMark([...$from.marks()]).length) return false
    const marks = []
    editor.nodesBetween((node) => {
      if (marks.length) return false

      if (node.isInline && node.marks.length) {
        marks.push(...filterMark([...node.marks]))
      }
      return undefined
    })
    this.isDisable = !marks.length || marks.includes('comment')
    return this.isDisable
  }
}

class FormatPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = FormatController
}

export { FormatController, FormatPlugin }
