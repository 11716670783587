import React from 'react'
import styles from './style/image-detail-pop.module.less'
import btnStyles from './style/creation-item.module.less'
import {
  IconCollect,
  IconCollectFill,
  IconDownload,
} from '@arco-iconbox/react-aidb-v2'
import { Tooltip } from '@arco-design/web-react'

function ImageDetailPop(props) {
  const {
    desc,
    bigImg,
    onhandleDownLoad,
    collect,
    OnhandleCancelCollect,
    OnhandleCollect,
  } = props
  //是否收藏

  return (
    <div className={styles.detail_pop}>
      <div className={styles.detail_pic}>
        <img src={bigImg} alt="" />
      </div>
      <div className={styles.detail_text}>
        {desc?.length > 0 && (
          <>
            <div className={styles.detail_title}>AI 想象提示：</div>
            <div className={styles.detail_desc}>{desc}</div>
          </>
        )}
        <div className={`${btnStyles.pos} ${btnStyles.btns}`}>
          <Tooltip color={'#222222'} content={'下载'}>
            <div
              className={`${btnStyles.download} ${btnStyles.icon}`}
              onClick={onhandleDownLoad}>
              <IconDownload />
            </div>
          </Tooltip>
          {props.type === 2 && (
            <Tooltip color={'#222222'} content={'收藏'}>
              <div className={btnStyles.icon}>
                {collect ? (
                  <IconCollectFill
                    onClick={(e) => {
                      OnhandleCancelCollect()
                      e.stopPropagation()
                    }}
                  />
                ) : (
                  <IconCollect
                    onClick={(e) => {
                      OnhandleCollect()
                      e.stopPropagation()
                    }}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageDetailPop
