import React from 'react'
import styles from './style/index.module.less'
import { Modal } from '@arco-design/web-react'

function ModalCustom(props) {
  function close() {
    props.close()
  }
const {theme} = props
  return (
    <Modal
      // title={<div className={'modal-aidb-title'}>{props.title}</div>}
      title={
        <div className={`modal-aidb-title ${styles[theme]}`}>{props.title}</div>
      }
      style={{
        width: props.width ? props.width : '800px',
        background: props.black ? '#19191a' : '',
      }}
      className={` modal-aidb type1 ${styles[theme]}
       ${props.black ? 'black' : ''} 
      ${props.titleHeight ? 'titleHeight' : ''} ${styles[theme]}`}
      visible={props.visible}
      onCancel={() => close()}
      footer={props.footer ? props.footer : null}>
      {props.content}
    </Modal>
  )
}

export default ModalCustom
