import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec, Node } from 'prosemirror-model'

interface IFontFamilyProps {
  name: string
  fontFamily: string
}

const PLUGIN_NAME = 'font_family'

const fontName = (fontFamily) => {
  const str = fontFamily?.replace(/'/g, '')
  switch (str) {
    case '黑体':
      return '黑体'
    case 'SimHei':
      return '黑体'
    case '微软雅黑':
      return '微软雅黑'
    case 'Microsoft YaHei':
      return '微软雅黑'
    case '华文仿宋':
      return '华文仿宋'
    case 'STFangsong':
      return '华文仿宋'
    case '宋体':
      return '宋体'
    case 'serif':
      return '宋体'
    case 'Arial':
      return 'Arial'
    case 'Kaiti SC':
      return '楷体'
    case 'KaiTi':
      return '楷体'
    case 'Kaiti SC, KaiTi':
      return '楷体'
    default:
      return '默认字体'
  }
}

class FontFamilySchema extends Inline<IFontFamilyProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'span'

  public attrs = {
    name: {
      default: '默认字体',
    },
    fontFamily: {
      default: '',
    },
  }

  public parseDOM = [
    {
      style: 'font-family',
      getAttrs: (fontFamily: string) => {
        return {
          name: fontName(fontFamily),
          fontFamily,
        }
      },
    },
  ]

  public toDOM = (node: Node) => {
    const fontFamily = node.attrs.fontFamily
    const attrs: { style?: string } = {
      style: `font-family: ${fontFamily}`,
    }

    return ['span', attrs, 0] as DOMOutputSpec
  }
}

class FontFamilyController extends SylController<IFontFamilyProps> {
  public name = PLUGIN_NAME
  public builtInFontFamily = [
    { name: '默认字体', fontFamily: '' },
    { name: 'Arial', fontFamily: 'Arial' },
    { name: '黑体', fontFamily: 'SimHei' },
    { name: '微软雅黑', fontFamily: 'Microsoft YaHei' },
    { name: '华文仿宋', fontFamily: '华文仿宋' },
    { name: '宋体', fontFamily: `serif` },
    {
      name: '楷体',
      fontFamily: 'Kaiti SC, KaiTi',
    },
  ]

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    tooltip: PLUGIN_NAME,
    type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi, attrs: { fontFamily: string; name: string }) => {
      if (attrs) {
        return <>{fontName(attrs.fontFamily)}</>
      } else {
        // 识别未声明的字体
        const { index } = _editor.getSelection()
        let node = _editor.view.domAtPos(index).node
        if (node.nodeType === 3 && node.parentElement) {
          node = node.parentElement
        }
        let fontFamily = getComputedStyle(node as HTMLElement).fontFamily
        // let fontFamily = '黑体'
        let name = '默认字体'
        return name
      }
    },
    value: this.builtInFontFamily.map((item, index) => {
      const { name, fontFamily } = item
      return {
        text: name,
        attrs: {
          name,
          fontFamily,
        },
      }
    }),
  }
}

class FontFamilyPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = FontFamilyController
  public Schema = FontFamilySchema
}

export { FontFamilyPlugin }
