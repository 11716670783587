import React, { useState, useEffect } from 'react'
import { Avatar, Space, Badge, Tooltip } from '@arco-design/web-react'
import { IconArrowRight } from '@arco-iconbox/react-aidb-v2'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { showModal } from '@/share/actions'
import styles from '../style/collaborativeDisplay.module.less'
import { GetQueryValue } from '@/utils/common'
import UserAvatar from '@/assets/UserAvatar'

interface Props {
  employeePermission: number
  synergiaEmployeeList: any[]
}
const CollaborativeDisplay = (props: Props) => {
  const { employeePermission, synergiaEmployeeList } = props
  // 显示人数
  const [displayPeoNum, _] = useState(3)

  // 当前用户、协同成员、在线状态、操作所有信息
  const yjsEditorCoordinationInfo = useSelector(
    (state: GlobalState) => state.yjsEditorCoordinationInfo,
  )

  return (
    <div className={styles['collaborativeDisplay-outContent']}>
      <Space size="small">
        {synergiaEmployeeList.map((employeeListItem, index) => {
          return (
            index < displayPeoNum && (
              <Badge
                dot
                offset={[6, -2]}
                status={
                  yjsEditorCoordinationInfo.isOnline.find((item) => {
                    return item.id === employeeListItem.employeeId
                  })?.online
                    ? 'success'
                    : 'default'
                }
                key={employeeListItem.employeeId}>
                <Tooltip content={employeeListItem.employeeName}>
                  <Avatar size={32}>
                    <div style={{ width: '28px', height: '28px' }}>
                      <UserAvatar
                        name={employeeListItem.employeeName}></UserAvatar>
                    </div>
                  </Avatar>
                </Tooltip>
              </Badge>
            )
          )
        })}
      </Space>
      <Tooltip mini content="展示全部">
        <IconArrowRight
          className={styles.iconArrowRight}
          onClick={() => {
            showModal('AddTeamModal', {
              uuid: GetQueryValue('uuid'),
              fileType: GetQueryValue('fileType'),
              innerType: true,
              currentPermission: employeePermission,
            })
          }}
        />
      </Tooltip>
    </div>
  )
}

export default CollaborativeDisplay
