import {
  ySyncPlugin,
  yCursorPlugin,
  yUndoPlugin,
  undo,
  redo,
} from 'y-prosemirror'
import { CONNECTION_ADDRESS, WEBSOCKET_URL_YJS, PORT_YJS, RANDOM_COLOR } from './config'
import { keymap } from 'prosemirror-keymap'
import yDoc from './lib/yDoc'
import WebsocketProvider from './lib/websocketProvider'
import { switchProject } from '@/helpers/switchProject'
import { trackChangesPlugin } from './plugin/trackChanges'

interface userInfo {
  name: string
}
const yjsPlugin = (dispatch, yjsEditorCoordinationInfo, uuid: string, userInfo: userInfo, editorRef, docContent) => {
  // 定义文档唯一链接
  let uniqueConnectPath = CONNECTION_ADDRESS(uuid)

  // Yjs文档保存共享数据
  const ydoc = new yDoc(uniqueConnectPath);
  // 将Y.XmlFragment定义为顶级类型
  const yXmlFragment = ydoc.yXmlFragment;
  // 连接到公共演示服务器（不在生产中！）
  const provider = WebsocketProvider.getInstance({
    dispatch,
    yjsEditorCoordinationInfo,
    ydoc,
    yXmlFragment,
    connectUrl: `${WEBSOCKET_URL_YJS}:${PORT_YJS}`,
    uniqueConnectPath,
    editorRef,
    docContent,
    userInfo,
    RANDOM_COLOR
  })

  // All of our network providers implement the awareness crdt  所有的网络提供商都实施了感知crdt
  const awareness = provider.awareness

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.example = { provider, ydoc, yXmlFragment };

  // 断开连接
  // provider.destroy()

  return [
    ySyncPlugin(yXmlFragment),
    yCursorPlugin(awareness, {
      // cursorBuilder: provider.cursorBuilder,
    }),
    yUndoPlugin(),
    keymap({
      'Mod-z': undo,
      'Mod-y': redo,
      'Mod-Shift-z': redo
    }),
    trackChangesPlugin(dispatch, yjsEditorCoordinationInfo, () => provider.isLocalUser)
  ]
}

export default yjsPlugin