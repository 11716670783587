import React from 'react'
import ReactMarkdown from 'react-markdown'
// 该配置使markdown支持HTML
import rehypeRaw from 'rehype-raw'
// 可使用表格、删除线、任务列表、引用等操作
import remarkGfm from 'remark-gfm'
// 该组件可以翻译输入的数学公式
import remarkMath from 'remark-math'
// 该组件可以翻译输入的数学公式
import rehypeKatex from 'rehype-katex'
// 自动生成markdown的目录
import remarkToc from 'remark-toc'
// 该组件可以实现代码块的彩色，即高亮
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// 具体高亮的样式选择
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {
  dark,
  duotoneLight,
} from 'react-syntax-highlighter/dist/esm/styles/prism'

import CodeCopyBtn from '../CodeCopyBtn'

import './style/index.less'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

const Markdown = (props) => {
  /*
    content：需要生成markdown的内容
    blogPreStyle：className="blog-pre"的样式
    isCodeCopyBtn：是否需要code的复制按钮
  **/
  const { content, blogPreStyle, isCodeCopyBtn } = props
  const theme = useSelector((state: GlobalState) => state.theme)
  return (
    <div className={`${theme}`}>
      <ReactMarkdown
        className="post-markdown"
        linkTarget="_blank"
        remarkPlugins={[remarkMath, remarkGfm]} //  remarkMath, remarkGfm, remarkToc
        rehypePlugins={[rehypeKatex, rehypeRaw]} //  rehypeKatex, rehypeRaw
        components={{
          pre: ({ children }) => (
            <pre className="blog-pre" style={blogPreStyle ? blogPreStyle : {}}>
              {isCodeCopyBtn ? (
                <CodeCopyBtn theme={theme}>{children}</CodeCopyBtn>
              ) : (
                ''
              )}
              {children}
            </pre>
          ),
          code({ node, inline, className = 'blog-code', children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                style={a11yDark}
                language={match[1]}
                PreTag="div"
                {...props}>
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          },
        }}>
        {content}
      </ReactMarkdown>
    </div>
  )
}
export default Markdown
