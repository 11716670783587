import React, { useEffect } from 'react'
import styles from './style/layout.module.less'
import CreationItem from './CreationItem'
import { Skeleton } from '@arco-design/web-react'
import { IconBaseSync } from '@arco-iconbox/react-aidb-v2'

function ImageListMod(props) {
  //生成图片
  const [creationList, setCreationList] = React.useState(props.imageList)

  useEffect(() => {
    // 在imageList发生变化时执行需要的操作
    console.log('ImageList changed:', creationList)
    setCreationList(props.imageList)
  }, [props.imageList])

  return (
    <>
      {/* 骨架屏 */}
      {props.skeleton && (
        <div className={styles.skeleton}>
          <div className={styles.skeletonDesc}>
            AI 想象提示： {props.stokenTitle}
          </div>
          {/* <div className={styles.skeletonTitle}></div> */}
          <div className={styles.skeletonBox1}>
            <div className={styles.row}>
              <div></div>
              <div></div>
            </div>
            <div className={styles.row}>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      {props.skeletonU1 && (
        <div className={styles.skeleton}>
          <div className={styles.skeletonDesc}>
            AI 想象提示： {props.stokenTitle}
          </div>
          {/* <div className={styles.skeletonTitle}></div> */}
          <div className={styles.skeletonBox}></div>
        </div>
      )}
      {creationList.map((item, index) => {
        return (
          <div className={styles.list_item} key={index}>
            <CreationItem
              buttons={item.buttons}
              link={item.link}
              desc={item.desc}
              type={item.type}
              bigImg={item.bigImg}
              id={item.id}
              collect={item.collect}
              date={item.date}
              baseUrl={item.baseUrl}
              downloadImg={item.downloadImg}
              OnSimpleChange={props.OnSimpleChange}
              close={() => {
                props.close(index)
              }}
              sendSuccess={props.sendSuccess}
              OnhandleAgain={props.OnhandleAgain}
              updateScroolTop={props.updateScroolTop}
              onUpdateCollect={props.onUpdateCollect}
              typePath={props.typePath}
              aspect={props.aspect}
            />
          </div>
        )
      })}
    </>
  )
}

export default ImageListMod
