import React from 'react'
import styles from './style/prompt-input-weight.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'
import { Slider } from '@arco-design/web-react'
import PromptWeight from './PromptWeight'
import PromptSelect from './PromptSelect'
const TextArea = Input.TextArea

function PromptSelectWeight(props) {
  const {
    onInputChange,
    onSliderChange,
    inputValue,
    sliderValue,
    title1,
    title2,
    tooltip1,
    tooltip2,
    showPlus1,
    showMinus1,
    readyonly,
    showSelect,
    placeholder,
    filed_value,
    max,
    min,
    step,
    defaultValue,
  } = props
  const [showSlider, setShowSlider] = React.useState(false)
  function select(item) {
    if (item.englishPart != undefined && item.englishPart != '') {
      setShowSlider(true)
    } else {
      setShowSlider(false)
      onSliderChange('')
    }
  }

  //添加删除
  function plus() {
    if (props.plus) {
      props.plus()
    }
  }
  function minus() {
    if (props.minus) {
      props.minus()
    }
  }

  return (
    <div>
      <PromptSelect
        plus={() => {
          plus()
        }}
        minus={() => {
          minus()
        }}
        openSelect={() => {
          props.openSelect()
        }}
        closeSelect={() => {
          props.closeSelect()
        }}
        filed_value={filed_value}
        showSelect={showSelect}
        title={title1}
        value={inputValue}
        onInputChange={onInputChange}
        tooltip={tooltip1}
        showPlus={showPlus1}
        showMinus={showMinus1}
        placeholder={placeholder}
        readyonly={readyonly}
        select={(item) => {
          select(item)
        }}
      />
      {showSlider && (
        <PromptWeight
          title={title2}
          tooltip={tooltip2}
          max={max}
          min={min}
          step={step}
          // showPlus={false.showPlus2}
          defaultValue={defaultValue}
          value={sliderValue}
          onSliderChange={onSliderChange}
          showMinus={false}
        />
      )}
    </div>
  )
}

export default PromptSelectWeight
