import React from 'react'
import ReactDOM from 'react-dom'
import {
  Block,
  SylPlugin,
  SylApi,
  SylController,
  Types,
  Card,
} from '@syllepsis/adapter'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import CommentMod from '../../component/commentMod'
import { switchProject } from '@/helpers/switchProject'
import { IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { Message } from '@arco-design/web-react'

const NAME = 'CommentBubble'

class CommentBubble {
  private dom: HTMLElement
  // 一键展开全部批注的当前段落临时class
  private HAS_COMMENT_TEMPORARY_CLASS = 'hasComment-margin-bottom'
  // 一键展开全部批注的margin-bottom值
  private MARGIN_BOTTOM = 280
  private editor
  private from
  private to
  private class

  constructor(mountEle: HTMLElement) {
    this.dom = document.createElement('div')
    this.dom.id = 'commentContainer'
    mountEle.appendChild(this.dom)
  }

  public show = (
    permission: number,
    top: number,
    paragraphSign: string,
    isFirst: boolean,
    editor,
    from: number,
    to: number,
    commentList: any,
    node?,
    pos?,
    multipleDisplays?: boolean,
  ) => {
    const commentDom = document.createElement('div')
    commentDom.style.position = 'absolute'
    commentDom.id = 'commentBubble' + paragraphSign
    commentDom.className = 'commentBubbleName'
    this.dom.appendChild(commentDom)

    this.editor = editor
    this.from = from
    this.to = to
    this.class = paragraphSign
    commentDom.style.right =
      localStorage.getItem('isFullDisplay') == 'true' ||
      localStorage.getItem('showRight') == 'true'
        ? `0`
        : '-340px'
    commentDom.style.top = `${top}px`
    commentDom.style.display = 'block'
    commentDom.style.background = '#fff'

    // multipleDisplays为true时，即快捷键一键全部展开批注框
    multipleDisplays && this.obtainOffSetHeight(this.MARGIN_BOTTOM, node, pos)

    ReactDOM.render(
      <CommentMod
        permission={permission}
        domId={'commentBubble' + paragraphSign}
        key={paragraphSign + Date.now()}
        paragraphSign={paragraphSign}
        isFirst={isFirst}
        onFirstSubmit={() => this.firstSubmitHandle(isFirst, commentDom)}
        commentClassList={commentList}></CommentMod>,
      commentDom,
    )
  }

  public firstSubmitHandle = (isFirst, commentDom) => {
    const state = this.editor.view.state
    const tr = state.tr
    tr.addMark(
      this.from,
      this.to,
      state.schema.marks.comment.create({
        class: this.class + ' ' + 'hl',
      }),
    )
    this.hide()
    this.editor.view.dispatch(tr)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '批注成功',
    })
    events.emit(ACTIONS.COMMENT_BUBBLE_DISPATCH)
    events.emit(ACTIONS.SAVE_PIC_EDITOR)
  }

  public hide = () => {
    const bubbles = this.dom.querySelectorAll(`[id^='commentBubble']`)
    bubbles.forEach((bubble) => bubble.remove())
    this.editor?.removeShadow('commentBubbleHighLight')
  }

  // 重置所有段落的margin-bottom
  public resetMargins = () => {
    const editor = this.editor
    if (!editor) return

    const { doc, tr } = editor.view.state
    const markType = editor.view.state.schema.marks.comment
    let modified = false

    doc.descendants((node, offset, index) => {
      if (
        node.type === editor.view.state.schema.nodes.paragraph ||
        node.type === editor.view.state.schema.nodes.header
      ) {
        node.descendants((nodePara, pos, parent) => {
          const marksToRemove = nodePara.marks.find((mark) => {
            return (
              mark.type === markType &&
              mark.attrs.class.includes('comment') !== -1
            )
          })
          if (marksToRemove) {
            if (
              node.attrs.class &&
              node.attrs.class.includes(this.HAS_COMMENT_TEMPORARY_CLASS)
            ) {
              const newAttrs = {
                ...node.attrs,
                class: node.attrs.class
                  .replace(this.HAS_COMMENT_TEMPORARY_CLASS, '')
                  .trim(),
              }
              tr.setNodeMarkup(offset, null, newAttrs)
              modified = true
            }
          }
        })
      }
    })

    if (modified) {
      editor.view.dispatch(tr)
    }
  }

  // 获取批注框高度并设置段落 margin-bottom
  public obtainOffSetHeight = (height: number, node, pos) => {
    const editor = this.editor
    const { doc, tr } = editor.view.state

    this.addCommentClassToParagraph(
      node,
      tr,
      editor.view.state.schema,
      pos,
      height,
    )
    editor.view.dispatch(tr)
  }

  // 打开批注框，并且添加margin-bottom值，防止重叠
  private addCommentClassToParagraph = (node, tr, schema, offset, height) => {
    const editor = this.editor
    if (
      node.type === schema.nodes.paragraph ||
      node.type === schema.nodes.header
    ) {
      const markType = editor.view.state.schema.marks.comment
      node.descendants((nodePara, pos, parent) => {
        const marksToRemove = nodePara.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes('comment') !== -1
          )
        })
        if (marksToRemove) {
          if (schema.nodes.bullet_list !== node.type && nodePara.isText) {
            // 为新 class 编写样式
            this.addDynamicStyle(this.HAS_COMMENT_TEMPORARY_CLASS, height)

            const newAttrs = {
              ...node.attrs,
              class: `hasComment ${this.HAS_COMMENT_TEMPORARY_CLASS}`,
            }

            tr.setNodeMarkup(offset, node.type, newAttrs)

            return false
          }
        }
      })
    }
  }

  // 将动态生成的样式添加到文档头部
  private addDynamicStyle = (className, height) => {
    const styleElement = document.createElement('style')
    styleElement.textContent = `.${className} {margin-bottom: ${height}px !important;}`
    document.head.appendChild(styleElement)
  }
}

class CommentBubbleSchema extends Card<any> {
  public name = NAME
  constructor(editor: SylApi, props) {
    super(editor, props)
  }
}

class CommentBubbleController extends SylController {
  public name = NAME
  private commentBubble: CommentBubble
  private yjsEditorCoordinationInfo
  private curClass
  private newClass

  constructor(editor: SylApi, props: Partial<any>) {
    super(editor, props)
    this.yjsEditorCoordinationInfo = props
    this.commentBubble = new CommentBubble(this.editor.root)
    this.editor.root.addEventListener('click', (e) => this.handleClickOn(e))
    events.on(ACTIONS.COMMENT_BUBBLE, this.commentBubbleHandle)
    events.on(ACTIONS.REMOVE_COMMENT_BUBBLE, this.removeComment)
    events.on(ACTIONS.FORMAT_CREATE_COMMENT, this.formatCreateComment)
    events.on(
      ACTIONS.COMMENT_BUBBLE_HIGH_LIGHT,
      this.commentBubbleHighLightHandle,
    )
    events.on(
      ACTIONS.COMMENT_BUBBLE_HEADER_SHOW,
      this.commentBubbleHeaderShowHandle,
    )
    // 一键展开所有批注
    events.on(
      ACTIONS.COLLAPSE_ALL_ANNOTATIONS,
      this.collapseAllAnnotations.bind(this),
    )

    document.addEventListener('click', (event) => {
      // 判断点击事件是否发生在 myDiv 之外
      var myDiv = document.getElementById('commentContainer')
      var targetNode = event.target as HTMLElement
      if (
        myDiv &&
        !myDiv.contains(targetNode) &&
        !targetNode.classList.contains('hasComment') &&
        !targetNode.classList.contains('button-group') &&
        !targetNode.classList.contains('arco-icon-close') &&
        targetNode.tagName != 'path'
      ) {
        this.commentBubble.hide()
        this.commentBubble.resetMargins()
      }
    })

    // 点击已有的批注框时，将点击的批注框放到最上方
    document
      .getElementById('commentContainer')
      .addEventListener('click', function (event) {
        const target = event.target as HTMLElement // 类型断言

        // 找到最近的具有.commentBubbleName类的祖先元素
        const commentBubble = target.closest(
          '.commentBubbleName',
        ) as HTMLElement

        if (commentBubble) {
          // console.log('Match Found:', commentBubble)

          // 获取所有的子元素
          const comments = document.querySelectorAll(
            '#commentContainer .commentBubbleName',
          )

          // 将所有子元素的z-index值设置为1
          comments.forEach(
            (comment) => ((comment as HTMLElement).style.zIndex = '1'),
          )

          // 将点击的子元素z-index值设置为9
          commentBubble.style.zIndex = '9'
        }
      })
  }

  editorWillUnmount() {
    events.off(ACTIONS.REMOVE_COMMENT_BUBBLE, this.removeComment)
    events.off(ACTIONS.COMMENT_BUBBLE, this.commentBubbleHandle)
    events.off(
      ACTIONS.COMMENT_BUBBLE_HIGH_LIGHT,
      this.commentBubbleHighLightHandle,
    )
    events.off(
      ACTIONS.COMMENT_BUBBLE_HEADER_SHOW,
      this.commentBubbleHeaderShowHandle,
    )
  }

  private formatCreateComment = (payload) => {
    const { minPos, maxPosNodeSize, newClass } = payload
    const state = this.editor.view.state
    const tr = state.tr
    tr.addMark(
      minPos,
      maxPosNodeSize,
      state.schema.marks.comment.create({
        class: newClass,
      }),
    )
    this.editor.view.dispatch(tr)
  }

  private handleClickOn = (
    event,
    position?: { pos: number; inside: number },
  ) => {
    if (!event) return
    if (event.target.classList.contains('hasComment')) this.commentBubble.hide()
    if (!event || !event.target.classList.contains('hasComment')) {
      return
    }
    let commentList = []
    const uniqueClassSet = new Set()
    const targetDiv = this.editor.root.querySelector(
      `.${event.target.className.split(' ')[0]}`,
    )
    const posNode = position
      ? position
      : this.editor.view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })

    if (!posNode) {
      return
    }

    const { top: editorTop } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } = this.editor.view.coordsAtPos(
      posNode.inside,
    )

    const textNode = this.editor.view.state.doc.nodeAt(posNode.inside)

    textNode.descendants((node) => {
      const commentMark = node.marks.find(
        (mark) => mark.type.name === 'comment',
      )
      if (commentMark) {
        const modifiedClass = commentMark.attrs.class
          .replace(new RegExp(`(hl)\\b`, 'g'), '')
          .trim()

        if (!uniqueClassSet.has(modifiedClass)) {
          uniqueClassSet.add(modifiedClass)
          commentList.push(modifiedClass)
        }
      }
    })
    const computedTop = headBottom - editorTop
    if (event.target.classList.contains('hasComment')) {
      this.commentBubble.show(
        this.yjsEditorCoordinationInfo.permission,
        computedTop,
        event.target.className.split(' ')[0],
        false,
        this.editor,
        0,
        0,
        commentList,
      )
    } else {
      this.commentBubble.hide()
    }
  }

  private commentBubbleHeaderShowHandle = (payload) => {
    const { pos, currentNode } = payload
    const { top: editorTop } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(pos)
    let commentList = []
    const uniqueClassSet = new Set()

    currentNode.descendants((node) => {
      const commentMark = node.marks.find(
        (mark) => mark.type.name === 'comment',
      )
      if (commentMark) {
        const modifiedClass = commentMark.attrs.class
          .replace(new RegExp(`(hl)\\b`, 'g'), '')
          .trim()

        if (!uniqueClassSet.has(modifiedClass)) {
          uniqueClassSet.add(modifiedClass)
          commentList.push(modifiedClass)
        }
      }
    })
    const computedTop = headBottom - editorTop
    this.commentBubble.show(
      this.yjsEditorCoordinationInfo.permission,
      computedTop,
      commentList[0],
      false,
      this.editor,
      0,
      0,
      commentList,
    )
  }

  private commentBubbleHandle = (payload) => {
    const { pos } = payload
    const state = this.editor.view.state
    const blockNode = state.doc.nodeAt(pos)
    const $from = pos
    const $to = pos + blockNode.content.size + 1
    const tr = state.tr
    const hasCommentMark = state.doc.rangeHasMark(
      $from,
      $to,
      state.schema.marks.comment,
    )

    const uniqueId = Date.now() // TODO: use uuid or add user id to make it unique
    this.curClass = 'comment-' + uniqueId
    this.newClass = this.curClass

    if (hasCommentMark) {
      for (let start = $from; start < $to; start++) {
        const _marks = this.editor.view.state.doc
          .resolve(start)
          .marksAcross(this.editor.view.state.doc.resolve(start + 1))

        // 先检查 _marks 是否存在
        if (_marks && _marks.length > 0) {
          const commentMark = _marks.find(
            (mark) => mark.type.name === 'comment',
          )
          if (commentMark) {
            const oldClass = commentMark.attrs.class || ''

            // prepend oldClass !!!
            this.newClass = oldClass + ' ' + this.curClass
            tr.removeMark(start, start + 1, commentMark)
            tr.addMark(
              start,
              start + 1,
              state.schema.marks.comment.create({
                class: this.newClass + ' ' + 'hl',
              }),
            )
          } else {
            tr.addMark(
              start,
              start + 1,
              state.schema.marks.comment.create({
                class: this.newClass + ' ' + 'hl',
              }),
            )
          }
        } else {
          tr.addMark(
            start,
            start + 1,
            state.schema.marks.comment.create({
              class: this.newClass + ' ' + 'hl',
            }),
          )
        }
      }
    } else {
      const { top: editorTop } = this.editor.root.getBoundingClientRect()
      const { left: headLeft, bottom: headBottom } =
        this.editor.view.coordsAtPos($from)
      const computedTop = headBottom - editorTop
      this.commentBubble.show(
        this.yjsEditorCoordinationInfo.permission,
        computedTop,
        this.newClass,
        true,
        this.editor,
        $from,
        $to,
        [],
      )
    }
    this.editor.view.dispatch(tr)
  }

  private removeComment = ({
    id,
    className,
  }: {
    id: string
    className: string
  }) => {
    const state = this.editor.view.state
    const tr = state.tr
    const root = state.doc
    const markType = this.editor.view.state.schema.marks.comment
    root.descendants((node, pos) => {
      if (node.marks.length > 0) {
        const marksToRemove = node.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes(id + ' ' + 'hl') !== -1
          )
        })

        if (marksToRemove && marksToRemove.attrs.class.includes(id)) {
          const oldClass = (marksToRemove.attrs.class || '') as string
          const newClass = oldClass.replace(
            new RegExp(`(${className + ' ' + 'hl'}|${className}|hl)\\b`, 'g'),
            '',
          )
          const startPos = tr.mapping.map(pos)
          const endPos = tr.mapping.map(pos + node.nodeSize)
          tr.removeMark(startPos, endPos, marksToRemove)
          if (newClass.trim().length !== 0) {
            tr.addMark(startPos, endPos, markType.create({ class: newClass }))
          }
          return false
        }
      }
      return true
    })
    this.commentBubble.hide()
    this.editor.view.dispatch(tr)
    events.emit(ACTIONS.COMMENT_BUBBLE_DISPATCH)
    events.emit(ACTIONS.SAVE_PIC_EDITOR)
  }

  private commentBubbleHighLightHandle = ({ id }: { id: string }) => {
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    const state = this.editor.view.state
    const tr = state.tr
    const root = state.doc
    let minPos = null
    let maxPos = null
    let maxPosNodeSize = null
    const markType = this.editor.view.state.schema.marks.comment
    root.descendants((node, pos) => {
      if (node.marks.length > 0) {
        const marksToRemove = node.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes(id + ' ' + 'hl') !== -1
          )
        })

        if (marksToRemove && marksToRemove.attrs.class.includes(id)) {
          const startPos = tr.mapping.map(pos)
          const endPos = tr.mapping.map(pos + node.nodeSize)
          if (minPos === null || startPos < minPos) {
            minPos = startPos
          }
          if (maxPos === null || endPos > maxPos) {
            maxPos = endPos
            maxPosNodeSize = endPos
          }
          return false
        }
      }
      return true
    })
    this.editor?.appendShadow(
      {
        index: minPos,
        length: maxPosNodeSize - minPos,
        attrs: {
          style: `background:${!switchProject('DFZ') ? '#FFC7A2' : '#FFDADA'} `,
        },
        spec: {
          key: 'commentBubbleHighLight',
        },
      },
      true,
    )
    this.editor.view.dispatch(tr)
  }

  // 隐藏未提交的批注按钮
  private hideUnCommitCommentBubble = () => {
    const buttonGroup = document.querySelector('.button-group') as HTMLElement
    buttonGroup.style.display = `none`
  }

  // 展开某一段的批注
  public expandCurrentAnnotationKeyMap = () => {
    // 获取当前光标位置
    const state = this.editor.view.state
    const { from: pos } = state.selection

    // 光标所在的段落节点的起始和结束位置
    let position = { node: null, event: null, pos: 0, inside: 0 }
    const buttonGroup = document.querySelector('.button-group') as HTMLElement

    // 获取编辑器状态和文档节点
    const doc = state.doc

    // 查找光标所在的段落节点及其起始和结束位置
    doc.nodesBetween(pos, pos, (node, startPos, parent, index) => {
      if (node.isBlock) {
        position.node = node
        position.pos = startPos
        position.inside = startPos + node.nodeSize

        // 查找有 hasComment 类的元素
        const domNode = this.editor.view.domAtPos(startPos).node as HTMLElement
        const hasCommentElement = domNode
          ? domNode.querySelector('.hasComment')
          : null

        if (hasCommentElement) {
          const coords = hasCommentElement.getBoundingClientRect()

          // 创建一个新的 PointerEvent 对象
          const simulatedEvent = new PointerEvent('pointerdown', {
            bubbles: true,
            cancelable: true,
            view: window,
            clientX: coords.left,
            clientY: coords.top,
            pointerId: 1,
            pointerType: 'mouse',
            isPrimary: true,
          })

          // 设置目标为 hasComment 元素
          Object.defineProperty(simulatedEvent, 'target', {
            value: hasCommentElement,
            enumerable: true,
          })

          position.event = simulatedEvent
        }
        return false // 停止进一步遍历
      }
      return true
    })

    // 若buttonGroup没有显示，则是一个有提交的段落，如果有显示，则是一个没有提交的段落
    if (buttonGroup.style.display === 'none') {
      this.handleClickOn(position.event, {
        pos: position.inside,
        inside: position.pos,
      })
    } else {
      this.commentBubbleHandle(position)
      buttonGroup.style.display = `none`
    }

    return true
  }

  // 展开所有批注
  public expandAllAnnotationKeyMap = () => {
    // 获取编辑器状态和文档节点
    const state = this.editor.view.state
    const doc = state.doc

    // 遍历文档中的所有段落
    doc.descendants((node, pos) => {
      if (node.isBlock) {
        // 获取当前段落的起始位置
        const startPos = pos

        // 获取当前段落的 DOM 元素
        const domNode = this.editor.view.nodeDOM(startPos) as HTMLElement

        if (domNode && domNode.textContent) {
          // console.log(domNode, domNode.classList.contains('hasComment'))
          if (domNode.classList.contains('hasComment')) {
            // 获取批注内容列表
            let commentList = []
            const uniqueClassSet = new Set()

            node.descendants((childNode) => {
              if (childNode.marks) {
                childNode.marks.forEach((mark) => {
                  if (mark.type.name === 'comment') {
                    const modifiedClass = mark.attrs.class
                      .replace(new RegExp(`(hl)\\b`, 'g'), '')
                      .trim()
                    if (!uniqueClassSet.has(modifiedClass)) {
                      uniqueClassSet.add(modifiedClass)
                      commentList.push(modifiedClass)
                    }
                  }
                })
              }
            })

            // 显示批注框
            const { top: editorTop } = this.editor.root.getBoundingClientRect()
            const { top: elementTop } = domNode.getBoundingClientRect() // 使用元素的顶部
            const computedTop = elementTop - editorTop // 确保位置计算相对于编辑器顶部

            // 调用显示批注框前，先检查是否已显示
            this.commentBubble.show(
              this.yjsEditorCoordinationInfo.permission,
              computedTop,
              commentList[0], // 使用第一个评论类作为标识符
              false,
              this.editor,
              startPos,
              startPos + node.nodeSize,
              commentList,
              node,
              pos,
              true,
            )
            // 如果当前有未提交的批注按钮显示，则隐藏
            this.hideUnCommitCommentBubble()
          }
        }
      }
    })

    return true
  }

  // 一键展开所有批注
  public collapseAllAnnotations = () => {
    setTimeout(() => {
      this.expandAllAnnotationKeyMap()
    }, 0)
  }

  // 定义快捷键
  public keymap = {
    // 展开某一段的批注
    'ctrl-e': this.expandCurrentAnnotationKeyMap.bind(this),
    // 展开所有批注
    'ctrl-shift-e': this.expandAllAnnotationKeyMap.bind(this),
  }
}

class CommentBubblePlugin extends SylPlugin {
  public name = NAME
  public Controller = CommentBubbleController
  public Schema = CommentBubbleSchema
}

export { CommentBubblePlugin, CommentBubbleController, CommentBubble }
