import React, { useEffect, useState } from 'react'
import styles from './style/layout.module.less'
import PromptTextarea from './prompt/PromptTextarea'
import PromptInputWeight from './prompt/PromptInputWeight'
import PromptImage from './prompt/PromptImage'
import PromptSelectWeight from './prompt/PromptSelectWeight'
import PromptWeight from './prompt/PromptWeight'
import PromptInput from './prompt/PromptInput'
import PromptDownSelect from './prompt/PromptDownSelect'
import { IconRefresh } from '@arco-design/web-react/icon'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { Tooltip } from '@arco-design/web-react'

function CreationLeftForm(props) {
  const {
    templateData,
    intro,
    onUpdateFormValues,
    onUpdateImg,
    handleDisabledBtn,
  } = props
  // console.log(templateData)

  // 权重json初始数据
  const [descList, setDescList] = React.useState([
    {
      id: 1,
      title: templateData[1].field[0].filed_name,
      title_tooltips: templateData[1].field[0].info,
      sliderTitle: templateData[1].field[1].filed_name,
      sliderTitle_tooltips: templateData[1].field[1].info,
      showPlus: true,
      showMinus: false,
      show: true,
    },
  ])

  //风格描述初始数据
  const [list, setList] = React.useState([
    {
      id: 1,
      title: 'Composition构图',
      title_tooltips: 'Composition构图',
      sliderTitle: '构图权重',
      sliderTitle_tooltips: '构图权重',
      name: 'composition',
      showPlus: false,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[0].filed_value,
    },
    {
      id: 2,
      title: 'Camera视角',
      title_tooltips: 'Camera视角',
      sliderTitle: '视角权重',
      sliderTitle_tooltips: '视角权重',
      name: 'camera',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[1].filed_value,
    },
    {
      id: 3,
      title: 'Lighting灯光',
      title_tooltips: 'Lighting灯光',
      sliderTitle: '灯光权重',
      sliderTitle_tooltips: '灯光权重',
      name: 'lighting',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[2].filed_value,
    },
    {
      id: 4,
      title: 'Medium媒介',
      title_tooltips: 'Medium媒介',
      sliderTitle: '媒介权重',
      sliderTitle_tooltips: '媒介权重',
      name: 'medium',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[3].filed_value,
    },
    {
      id: 5,
      title: 'Color色调',
      title_tooltips: 'Color色调',
      sliderTitle: '色调权重',
      sliderTitle_tooltips: '色调权重',
      name: 'color',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[4].filed_value,
    },
    {
      id: 6,
      title: 'Accuracy精度',
      title_tooltips: 'Accuracy精度',
      sliderTitle: '精度权重',
      sliderTitle_tooltips: '精度权重',
      name: 'accuracy',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[5].filed_value,
    },
    {
      id: 7,
      title: 'Environment环境',
      title_tooltips: 'Environment环境',
      sliderTitle: '环境权重',
      sliderTitle_tooltips: '环境权重',
      name: 'enviornment',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[6].filed_value,
    },
    {
      id: 8,
      title: 'Style风格',
      title_tooltips: 'Style风格',
      sliderTitle: '风格权重',
      sliderTitle_tooltips: '风格权重',
      name: 'style',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[7].filed_value,
    },
    {
      id: 9,
      title: 'Rendering渲染',
      title_tooltips: 'Rendering渲染',
      sliderTitle: '渲染权重',
      sliderTitle_tooltips: '渲染权重',
      name: 'rendering',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[8].filed_value,
    },
    {
      id: 10,
      title: 'National国风元素',
      title_tooltips: 'National国风元素',
      sliderTitle: '国风元素权重',
      sliderTitle_tooltips: '国风元素权重',
      name: 'national',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[9].filed_value,
    },

    {
      id: 11,
      title: 'Photography style摄影风格',
      title_tooltips: 'Photography style摄影风格',
      sliderTitle: '摄影风格权重',
      sliderTitle_tooltips: '摄影风格权重',
      name: 'photography',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[10].filed_value,
    },

    {
      id: 12,
      title: 'Material quality材质',
      title_tooltips: 'Material quality材质',
      sliderTitle: '材质权重',
      sliderTitle_tooltips: '材质权重',
      name: 'material',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[11].filed_value,
    },
    {
      id: 13,
      title: 'Simplified avatar style 极简头像风格',
      title_tooltips: 'Simplified avatar style 极简头像风格',
      sliderTitle: '极简头像风格权重',
      sliderTitle_tooltips: '极简头像风格权重',
      name: 'avatar',
      showPlus: true,
      showMinus: false,
      showSelect: false,
      show: true,
      filed_value: templateData[3].field[12].filed_value,
    },
  ])

  //风格的json数据处理插入整体表单值
  const initialStyle = list.reduce((fields, item) => {
    const contentField = `${item.name}_content`
    const weightField = `${item.name}_weight`
    fields[contentField] = [] // 使用数组作为初始值
    fields[weightField] = [] // 使用数组作为初始值
    return fields
  }, {})

  //整体表单值
  const [formValues, setFormValues] = useState({
    main_content: '', //主题描述textarea
    other_content: [], //其他描述 input
    other_weight: [], //其他权重 滑动条
    ...initialStyle, //风格参数
    Stylize: [], //系统参数风格
    Quality: [], //系统参数质量
    Chaos: [], //系统参数混乱
    seed: [], //系统参数种子
    Aspect: [], //系统参数比例
    Version: [], //系统参数版本
    no: [], //系统参数排除
    imgSilder: [], //图片权重
  })

  // 定义一个回调函数来接收子组件传递的base64Image值
  const handleBase64ImageChange = (value) => {
    onUpdateImg(value)
  }
  // console.log(formValues)

  //更新表单的值
  const updateFormValue = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }))
  }

  const [endList, setEndList] = useState('')
  console.log(endList)
  //监听formvalues数据变化，作为textarea展示数据
  useEffect(() => {
    // 创建一个新对象来存储合并后的结果
    const mergedValues = { ...formValues }

    // 遍历formValues中的每一对键和值
    for (const key in formValues) {
      // 如果当前键是content键
      if (key.endsWith('_content')) {
        // 获取对应的weight键
        const weightKey = key.replace('_content', '_weight')

        // 检查formValues[key]是否是数组
        if (Array.isArray(formValues[key]) && formValues[key].length > 0) {
          // 过滤掉undefined和空值，然后合并content和weight，并添加到mergedValues中
          const filteredContent = formValues[key].filter(
            (item) => item !== undefined && item !== '',
          )

          // 获取对应的weight数组
          const weightArray = formValues[weightKey] || []
          const filteredWeight = weightArray.filter(
            (item) => item !== undefined && item !== '',
          )

          // 合并content和weight，并添加到mergedValues中
          mergedValues[key] = filteredContent.map((content, index) => {
            const weight = filteredWeight[index] || ''
            return content + weight + ', '
          })

          // 删除包含"weight"的键
          delete mergedValues[weightKey]
        } else {
          // 如果没有weight键或没有值，直接复制content到mergedValues
          mergedValues[key] = formValues[key] || []
        }
      }
    }

    // mergedContent现在包含了合并后的content值
    console.log(mergedValues, '-++++_________')

    // 过滤掉空字符串、0 和 undefined，并提取有值的项的值
    const filteredValues = Object.entries(mergedValues)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          if (key == 'other_weight') {
            return formValues.other_weight.length == 0 ? [] : [`::${value}`]
          }
          return value.filter(
            (item) =>
              item !== null &&
              item !== '' &&
              item !== undefined &&
              item !== '--s' &&
              item !== '--q' &&
              item !== '--c',
          )
        }
        if (key == 'main_content') {
          return value !== null && value !== '' && value !== undefined
            ? [`${value}${', '}`]
            : []
        }

        if (
          key == 'Stylize' ||
          key == 'Quality' ||
          key == 'Chaos' ||
          key == 'Aspect' ||
          key == 'Version'
        ) {
          return value !== null && value !== '' && value !== undefined
            ? [`${value}${' '}`]
            : []
        }
        if (key == 'imgSilder') {
          return value !== null && value !== '' && value !== undefined
            ? [`--iw${' '}${value}`]
            : []
        }
        if (key === 'seed' || key === 'no') {
          // Add prefix "--" to values of specific keys
          return value !== null && value !== '' && value !== undefined
            ? [`--${key + ' '}${value}${' '}`]
            : []
        }

        return value !== null && value !== '' && value !== undefined
          ? [value]
          : []
      })
      .join('')

    setEndList(filteredValues.replace(/,\s*$/, '')) //去除末尾逗号，防止粘贴报错
    onUpdateFormValues(filteredValues.replace(/,\s*$/, '')) // 向父组件传递数据
    console.log(filteredValues)

    if (formValues.main_content.length == 0) {
      handleDisabledBtn(true)
    } else {
      handleDisabledBtn(false)
    }
  }, [formValues])

  function openSelect(index) {
    let newList = [...list]
    newList.map((item) => {
      item.showSelect = false
    })
    newList[index].showSelect = true
    setList(newList)
  }

  function closeSelect(index) {
    let newList = [...list]
    newList[index].showSelect = false
    setList(newList)
  }
  //风格的新增
  function plus(item, index) {
    let newList = [...list]
    let obj = { ...item }
    obj.id = newList.length + 1
    obj.showMinus = true
    newList.splice(index + 1, 0, obj)
    setList(newList)

    // 处理风格描述内容的新增
    const contentField = `${item.name}_content`
    const weightField = `${item.name}_weight`
    const newContent = [...formValues[contentField], '']
    const newWeight = [...formValues[weightField], '']
    updateFormValue(contentField, newContent)
    updateFormValue(weightField, newWeight)

    // 更新整体表单值
    setFormValues((prevValues) => ({
      ...prevValues,
      [contentField]: newContent,
      [weightField]: newWeight,
    }))
  }

  //风格的删除
  function minus(item, index) {
    let newList = [...list]
    newList[index].show = false
    setList(newList)

    // 处理风格描述内容的删除
    const contentField = `${item.name}_content`
    const weightField = `${item.name}_weight`
    const newContent = [...formValues[contentField]]
    newContent.splice(index, 1)
    const newWeight = [...formValues[weightField]]
    newWeight.splice(index, 1)
    updateFormValue(contentField, newContent)
    updateFormValue(weightField, newWeight)
    // 更新整体表单值
    setFormValues((prevValues) => ({
      ...prevValues,
      [contentField]: newContent,
      [weightField]: newWeight,
    }))
  }

  // 权重的新增
  function plusDesc(item, index) {
    let newList = [...descList]
    let obj = { ...item }
    obj.id = newList.length + 1
    obj.showMinus = true
    newList.splice(index + 1, 0, obj)
    setDescList(newList)

    // 更新数组值
    const newOtherContent = [...formValues.other_content, '']
    const newOtherContentWeight = [...formValues.other_weight, 0]
    console.log(newOtherContent)

    setFormValues((prevValues) => ({
      ...prevValues,
      other_content: newOtherContent,
      other_weight: newOtherContentWeight,
    }))

    setDescList(newList)
  }

  //权重的删除
  function minusDesc(item, index) {
    let newList = [...descList]
    newList[index].show = false

    // 更新数组值
    const newOtherContent = formValues.other_content.filter(
      (_, i) => i !== index,
    )
    const newOtherContentWeight = formValues.other_weight.filter(
      (_, i) => i !== index,
    )

    setFormValues((prevValues) => ({
      ...prevValues,
      other_content: newOtherContent,
      other_weight: newOtherContentWeight,
    }))

    setDescList(newList)
  }

  //重置表单数据
  function handleReset() {
    setEndList('')
    onUpdateImg('')
    events.emit(ACTIONS.RESET_AIIMG_BASE64)
    setFormValues((prevFormValues) => ({
      ...prevFormValues, // 保留属性
      main_content: '',
      other_content: [],
      imgSilder: [],
      ...initialStyle,
      other_weight: [],
      Stylize: [],
      Quality: [],
      Chaos: [],
      seed: [],
      Aspect: [],
      Version: [],
      no: [],
    }))
  }
  return (
    <>
      {/* 标题 */}
      <div className={styles.title}>{intro}</div>

      {/* 主题描述textarea */}
      <div className={styles.prompt_item}>
        <PromptTextarea
          title={templateData[0].name}
          placeholder={templateData[0].field[0].placeholder}
          tooltip={templateData[0].field[0].info}
          value={formValues.main_content}
          onChange={(value) => updateFormValue('main_content', value)}
        />
      </div>

      {/* 权重描述inputWeight */}
      {descList.map((item, index) => {
        if (item.show) {
          return (
            <div className={styles.prompt_item} key={index}>
              <PromptInputWeight
                plus={() => {
                  plusDesc(item, index)
                }}
                minus={() => {
                  minusDesc(item, index)
                }}
                title1={item.title}
                tooltip1={item.title_tooltips}
                showPlus1={item.showPlus}
                showMinus1={item.showMinus}
                title2={item.sliderTitle}
                tooltip2={item.sliderTitle_tooltips}
                showPlus2={false}
                showMinus2={false}
                inputValue={formValues.other_content[index]}
                sliderValue={Number(
                  formValues.other_weight[index]?.replace('::', ''),
                )}
                max={2}
                min={0}
                step={0.1}
                defaultValue={1}
                onInputChange={(value) => {
                  const newOtherContent = [...formValues.other_content]
                  newOtherContent[index] = value
                  updateFormValue('other_content', newOtherContent)
                }}
                onSliderChange={(value) => {
                  const newOtherContentWeight = [...formValues.other_weight]
                  newOtherContentWeight[index] = '::' + value
                  updateFormValue('other_weight', newOtherContentWeight)
                }}
              />
            </div>
          )
        }
      })}

      {/* 以图生图 */}
      <div className={styles.prompt_item}>
        <PromptImage
          title={'以图生图'}
          tooltip={'请上传一张图片或手动输入链接'}
          onBase64ImageChange={handleBase64ImageChange}
          sliderValue={formValues.imgSilder}
          onSliderChange={(value) => {
            updateFormValue('imgSilder', value)
          }}
          max={2}
          min={0.5}
          defaultValue={0.5}
          step={0.25}
          onUpdateImg={onUpdateImg}
          showPlus={true}
          // showMinus={true}
        />
      </div>
      <div className={styles.title_sub}>风格描述</div>

      {/* 风格描述 */}
      {list.map((item, index) => {
        if (item.show) {
          const contentField = `${item.name}_content`
          const weightField = `${item.name}_weight`
          return (
            <div className={styles.prompt_item} key={index}>
              <PromptSelectWeight
                plus={() => {
                  plus(item, index)
                }}
                minus={() => {
                  minus(item, index)
                }}
                max={100}
                min={1}
                step={1}
                defaultValue={1}
                filed_value={item.filed_value}
                title1={item.title}
                tooltip1={item.title_tooltips}
                showPlus1={item.showPlus}
                showMinus1={item.showMinus}
                title2={item.sliderTitle}
                tooltip2={item.sliderTitle_tooltips}
                showSelect={item.showSelect}
                openSelect={() => {
                  openSelect(index)
                }}
                closeSelect={() => {
                  closeSelect(index)
                }}
                inputValue={formValues[contentField][index]} // 注意这里的绑定
                sliderValue={Number(
                  formValues[weightField][index]?.replace('::', ''),
                )} // 注意这里的绑定
                onInputChange={(value) => {
                  const newContent = [...formValues[contentField]]
                  newContent[index] = value
                  updateFormValue(contentField, newContent)
                }}
                onSliderChange={(value) => {
                  const newWeight = [...formValues[weightField]]
                  newWeight[index] = '::' + value
                  updateFormValue(weightField, newWeight)
                }}
              />
            </div>
          )
        }
      })}

      <div className={styles.title_sub}>系统参数</div>
      <div className={styles.prompt_item}>
        <PromptWeight
          title={'Stylize风格化'}
          tooltip={
            '默认值为 100 (0-1000)，数值越大，越艺术化，和描述关联性越低'
          }
          max={1000}
          min={0}
          defaultValue={100}
          step={10}
          sliderValue={formValues.Stylize}
          onSliderChange={(value) => {
            updateFormValue('Stylize', '--s ' + value)
          }}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptWeight
          title={'Quality质量'}
          tooltip={'默认值为1（0-2），需要更多细节可以加大数值'}
          max={2}
          min={0}
          step={1}
          defaultValue={1}
          sliderValue={formValues.Quality}
          onSliderChange={(value) => {
            updateFormValue('Quality', '--q ' + value)
          }}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptWeight
          title={'Chaos混乱'}
          tooltip={'改变结果的多样性'}
          sliderValue={formValues.Chaos}
          onSliderChange={(value) => {
            updateFormValue('Chaos', '--c ' + value)
          }}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptInput
          title={'Seed种子'}
          tooltip={
            '图片的种子，控制种子可以控制图片的相似性。范围（0–4294967295）'
          }
          value={formValues.seed}
          onInputChange={(value) => {
            updateFormValue('seed', value)
          }}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptDownSelect
          title={'Aspect比例'}
          tooltip={'更改纵横比，图片的比例'}
          value={formValues.Aspect}
          onInputChange={(value) => {
            updateFormValue('Aspect', value)
          }}
          options={[
            { title: '--ar 1:1', id: 1 },
            { title: '--ar 3:4', id: 2 },
            {
              title: '--ar 16:9',
              id: 3,
            },
            { title: '--ar 9:16', id: 4 },
            { title: '--ar 21:9', id: 5 },
          ]}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptInput
          title={'No排除'}
          tooltip={'负面提示词(多条数据用“,”隔开)，排除你不想出现在图里的元素'}
          value={formValues.no}
          onInputChange={(value) => {
            updateFormValue('no', value)
          }}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={styles.prompt_item}>
        <PromptDownSelect
          title={'Version版本'}
          tooltip={'使用不同版本的模型'}
          value={formValues.Version}
          onInputChange={(value) => {
            updateFormValue('Version', value)
          }}
          options={[
            { title: '--v 6', id: 0 },
            { title: '--v 5.2', id: 1 },
            { title: '--v 5.1', id: 2 },
            {
              title: '--niji 5 --style cute',
              id: 3,
            },
            {
              title: '--niji 5 --style scenic',
              id: 4,
            },
            {
              title: '--niji 5 --style expressive',
              id: 5,
            },
          ]}
          showPlus={false}
          showMinus={false}
        />
      </div>
      <div className={`${styles.mb0} ${styles.title_sub}`}>
        提示生成结果
        <div className={styles.title_sub_icon} onClick={handleReset}>
          <IconRefresh />
        </div>
        <Tooltip position="top" trigger="hover" content={'重置'}>
          <div className={styles.title_sub_icon} onClick={handleReset}>
            <IconRefresh />
          </div>
          {/* <div
            className="textSaveBtn"
            onClick={() => onSubmit(ApprovalStatus.PendingApproval)}>
            <IconUploadDocument useCurrentColor={true} className="icon" />
          </div> */}
        </Tooltip>
      </div>
      <PromptTextarea value={endList} disable={true} />
    </>
  )
}

export default CreationLeftForm
