import React, { useRef, useEffect, useState } from 'react'
import styles from './style/add-tags.module.less'
import {
  IconPlus,
  IconCheck,
  IconEditLabel,
  IconEdit,
  IconClosure,
  IconSuccessTip,
  IconErrorTip,
} from '@arco-iconbox/react-aidb-v2'
import { InputTag, Message } from '@arco-design/web-react'
import { addDocTag, addTag, deleteTag } from '@/model/reference'
import { events } from '@/helpers/event-emitter'
import IconMsgSuccess from '../../assets/newImgs/msg-success.svg'
import { IconClose } from '@arco-iconbox/react-aidb'
import { ACTIONS } from '@/share/constants'

function AddTags(props) {
  const [uuidArray, setUuidArray] = useState(props.tags ? props.tags : [])
  const {theme} = props
  
  useEffect(() => {
    setUuidArray(uuidArray);
    AddDoc()
  }, [uuidArray]);
  //添加标签
  function handleAdd(e) {
    if (e.target.value.trim().length === 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '添加标签不能为空'
      })
      return;
    }
    if (e.target.value.length > 15) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '标签字数不得超过15字',
      })
    } else {
      addTag({ tagName: e.target.value }).then((res) => {
        if (res.data.success === true) {
          const { tagName, uuid } = res.data.data
          events.emit(ACTIONS.ADD_TAG, { tagName, uuid })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '添加标签成功',
          })
          setUuidArray((prevArray) => [...prevArray, { uuid, tagName }])

        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
    }
  }
  // useEffect(() => {
  //   events.on(ACTIONS.ADD_DOC_TAG, AddDoc)
  //   return () => {
  //     events.off(ACTIONS.ADD_DOC_TAG, AddDoc)
  //   }
  // })

  //添加对应文档标签
  function AddDoc() {
    const uuids = [...uuidArray.map((item) => item.uuid)]
    addDocTag({ uuid: props.uuid, uuids: uuids }).then((res) => {
      if (res.data.success === true) {
        // Message.success({
        // icon: <IconSuccessTip useCurrentColor={false} />,
        //   content: '标签已保存',
        // })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: res.data.msg,
        })
      }
    })
  }

  function handleDelete(e) {
    const removedTagName = e.value
    setUuidArray((prevArray) =>
      prevArray.filter((item) => item.tagName !== removedTagName),
    )
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '标签删除成功',
    })
  }


  function handleTagClick(item) {
    const { tagName, uuid } = item;
    const isDuplicate = uuidArray.length > 0 && uuidArray.some((item) => item.uuid === uuid);
    console.log(isDuplicate);

    if (!isDuplicate) {
      setUuidArray((prevArray) => [...prevArray, { uuid, tagName }]);
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '添加标签成功',
      })
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '不能添加重复标签',
      })
    }
    console.log(uuidArray);
  }



  return (
    <div className={`${styles['editTags']} ${styles[theme]}`}>
      <div className={styles['title']}>
        标签
        <span>（最多50个标签，输入框内按回车键分隔创建新标签。）</span>
      </div>
      <div className={styles['input']}>
        <InputTag
          value={uuidArray && uuidArray.map((item) => item.tagName)}
          defaultValue={uuidArray && uuidArray.map((item) => item.tagName)}
          onPressEnter={handleAdd}
          onRemove={handleDelete}
        />
      </div>
      <div className={styles['bottom']}>
        <div className={styles['title']}>标签列表</div>
        <div className={styles['tags']}>
          {props.tagsList?.map((item, index) => (
            <div
              className={styles['item']}
              key={index}
              onClick={() => handleTagClick(item)}>
              <span>#{item.tagName}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AddTags
