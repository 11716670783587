import React, { useEffect, useState } from 'react'
import Footer from '@/components/Footer'
import Logo from '@/assets/logo.svg'
import LoginForm from './form'
import LoginBanner from './banner'
import styles from '@/style/index.module.less'
import WxPhone from './wxPhone'
import Modal from '@/components/Modal'
import request from '@/utils/request'
import { useHistory } from 'react-router-dom'
function Login() {
  const [isWx, setIsWx] = useState(false)
  const history = useHistory()
  useEffect(() => {
    document.title = '登录 - 悟智AI'
  }, [])

  useEffect(() => {
    if (navigator.userAgent.includes('miniProgram')) {
      // 在微信小程序环境中
      setIsWx(true)
    } else {
      // 不在微信小程序环境中
      setIsWx(false)
    }
  }, [])
  function getTemJson() {
    let listArr = []
    new Promise((resolve, reject) => {
      request
        .get('https://support.wuz.com.cn/wp-json/acf/v3/options/options')
        .then(async (res) => {
          if (JSON.stringify(res.data.acf) != '{}') {
            const acf = res.data.acf
            const promptsCatArray = acf['templates_cat'].split('\r\n')
            const promptsCatNameArray = acf['templates_cat_name'].split('\r\n')
            const promptsFieldArray = acf['templates_common_field']
            const promptArray = acf['templates_common_field_prompt']
            promptsCatArray.forEach((item, index) => {
              listArr.push({
                id: index,
                title: item,
                category: promptsCatNameArray[index],
                Heightprompt: promptArray,
                Heightcommon: promptsFieldArray,
                children: [],
              })
            })
            await (listArr.length && resolve(''))
          }
        })
    }).then(() => {
      request
        .get(
          'https://support.wuz.com.cn/wp-json/acf/v3/template?per_page=999&orderby=menu_order',
        )
        .then((res) => {
          const result = res.data
          //存储ai绘画json
          const aiImg = result.filter((id) => id.id == 605)
          localStorage.setItem('aiImg', JSON.stringify(aiImg))

          if (JSON.stringify(result.acf) != '{}') {
            result.forEach((item1, index1) => {
              item1.acf.cat.forEach((item2, index2) => {
                listArr.forEach((item3, index3) => {
                  if (item2 == item3.category) {
                    item3.children.push({
                      uuid: item1.id,
                      title: item1.acf.title,
                      info: item1.acf.intro,
                      type: item1.acf.cat,
                      icon: item1.acf.icon,
                      step: item1.acf.step,
                      prompt: item1.acf.prompt,
                      is_use_common_field: item1.acf.is_use_common_field,
                      online: item1.acf.online,
                    })
                  }
                })
              })
            })

            listArr.forEach((item, index) => {
              item.id = index
              item.children.forEach((item1, index1) => {
                item1.id = index1
              })
            })

            localStorage.setItem('templateData', JSON.stringify(listArr))
            localStorage.setItem('cachedTime', String(Date.now()))
            console.log(listArr)

            //全文写作模版单独存储
            const filterAll = listArr[0].children.filter((id) => id.uuid == 715)
            const AllTextTem = {
              Heightcommon: listArr[0].Heightcommon,
              Heightprompt: listArr[0].Heightprompt,
              title: filterAll[0].title,
              info: filterAll[0].info,
              icon: filterAll[0].icon,
              id: filterAll[0].uuid,
              type: filterAll[0].type,
              step: filterAll[0].step,
              prompt: filterAll[0].prompt,
              is_use_common_field: filterAll[0].is_use_common_field,
            }
            localStorage.setItem('AllTextTem', JSON.stringify(AllTextTem))
          }
          // history.push(localStorage.getItem('redirectUrl'))
          location.href = localStorage.getItem('redirectUrl')
          localStorage.removeItem('redirectUrl')
        })
    })
  }
  return (
    <>
      {!isWx ? (
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo
              onClick={() => {
                history.push('/home')
              }}
            />
          </div>
          <div className={styles.content}>
            <div className={styles['content-inner']}>
              <div className={styles.cc}>
                <LoginForm getTemJson={getTemJson} />
              </div>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
          <div></div>
          {/* <ALIYUN></ALIYUN> */}
          <div className={styles.banner}>
            <div className={styles['banner-inner']}>
              <LoginBanner />
            </div>
          </div>
          <Modal></Modal>
        </div>
      ) : (
        <WxPhone></WxPhone>
      )}
    </>
  )
}
Login.displayName = 'LoginPage'

export default Login
