import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconExpand, IconTranslate } from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'
import ReactDOM from 'react-dom'
import { IconClosure, IconRecover } from '@arco-iconbox/react-aidb-v2'
import { Tooltip } from '@arco-design/web-react'

interface ContinueProps {
  name: string
}

const PLUGIN_NAME = 'Continue'
const EDITOR_PADDING_LEFT = 10
class ContinueTool {
  private dom: HTMLElement
  private visible = false
  private editor: SylApi

  constructor(mountEle: HTMLElement) {
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.dom.style.userSelect = 'none'
    this.dom.style.zIndex = '100'
    this.dom.style.backgroundColor = 'white'
    this.dom.style.border = '1px solid var(--color-neutral-3)'
    this.dom.style.borderRadius = '10px'
    this.dom.style.display = 'none'
    this.dom.style.boxShadow = '10px #BCD0FF'
    this.dom.id = 'highlight-tooltip'
    mountEle.appendChild(this.dom)
  }

  public show = (
    left: number,
    top: number,
    correct: string,
    reason: string,
  ) => {
    this.dom.style.left = `${left}px`
    this.dom.style.top = `${top}px`
    this.dom.style.display = 'flex'
    ReactDOM.render(
      <div
        style={{
          display: 'flex',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px #BCD0FF',
        }}>
        <div
          className="continueBox continueAdoptBox"
          style={{
            width: '90px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={this.adoptHandle}>
          <IconRecover className="IconExpand icon" />
          <span>采用</span>
        </div>
        <div
          className="continueBox continueCancelBox"
          style={{
            width: '90px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={this.cancelHandle}>
          <IconClosure className="IconExpand icon" />
          <span>取消</span>
        </div>
      </div>,
      this.dom,
    )
    // this.dom.innerHTML = `
    //   <div style="color: ${palette.proofBold};width:90px;height:48px;"><IconRecover /><span>采用</span></div>
    //   <div style="color: ${palette.proofBold};width:90px;height:48px;">取消</div>
    // `
  }

  public adoptHandle = () => {
    this.dom.style.display = 'none'
    this.visible = false
    events.emit(ACTIONS.CONTINUE_ADOPT_EDITOR)
  }

  public cancelHandle = () => {
    this.dom.style.display = 'none'
    this.visible = false
    events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
  }

  public hoverShow = () => {
    this.dom.style.display = 'block'
    this.visible = true
  }

  public hide = () => {
    this.dom.style.display = 'none'
    this.visible = false
  }

  get isVisible() {
    return this.visible
  }
}

class ContinueSchema extends Inline<ContinueProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class ContinueController extends SylController<ContinueProps> {
  public name = PLUGIN_NAME
  // private continueTool: ContinueTool
  private adoptContent
  constructor(editor: SylApi, props) {
    super(editor, props)
    // this.continueTool = new ContinueTool(this.editor.root)
    events.on(ACTIONS.CONTINUE_EDITOR, this.continueHandle)
    // events.on(ACTIONS.CONTINUE_ADOPT_EDITOR, this.continueHandle)
    events.on(ACTIONS.CONTINUE_CANCEL_EDITOR, this.continueCancelHandle)
  }

  editorWillUnmount() {
    events.off(ACTIONS.CONTINUE_EDITOR, this.continueHandle)
    // events.off(ACTIONS.CONTINUE_ADOPT_EDITOR, this.continueHandle)
    events.off(ACTIONS.CONTINUE_CANCEL_EDITOR, this.continueCancelHandle)
  }

  public continueAdoptHandle = () => {
    this.editor?.removeShadow('continueShadow')
  }

  public continueCancelHandle = () => {
    const shadowArr = this.editor?.getShadows('continueShadow')
    shadowArr.forEach((_) => {
      this.editor.delete(_.from, this.adoptContent.length)
      // const tr = this.editor.view.state.tr
      // tr.replaceWith(
      //   _.to,
      //   _.from,
      //   this.editor.view.state.schema.text(this.correctTxt, oldTextStyle),
      // )
    })
  }

  public updateDocHandle = (_editor: SylApi) => {
    events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    this.clickFromEditor(
      selectedContent,
      DocBtnType.continuationSingle,
      nodeInfo,
    )
  }

  public clickFromEditor = (selectedContent, btnType, nodeInfo) => {
    events.emit(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, {
      setSelectContent: selectedContent,
      barActiveIndex: btnType,
      nodeInfo: nodeInfo,
    })
  }

  public continueHandle = (payload) => {
    const shadowArr = this.editor?.getShadows('continueShadow')
    events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
    if (shadowArr.length > 0) {
      return
    }
    const { content, nodeInfo } = payload
    this.adoptContent = content
    this.editor.pasteContent(content, { plainText: true, scrollIntoView: true })
    // this.editor.insertText(
    //   content,
    //   { bolder: false },
    //   nodeInfo.index + nodeInfo.length,
    // )
    // this.editor.setFormat({ bold: false })

    this.editor?.appendShadow(
      {
        index: nodeInfo.index + nodeInfo.length,
        length: content.length,
        attrs: {
          style: `border-bottom:2px solid #3c6ddd;animation:${
            content && 'continueAnimation 2s'
          } ;`,

          class: 'continueHighlight',
        },
        spec: {
          key: 'continueShadow',
        },
      },
      true,
    )

    setTimeout(() => {
      this.editor?.removeShadow('continueShadow')
    }, 3000)

    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } = this.editor.view.coordsAtPos(
      nodeInfo.index + this.adoptContent.length,
    )

    const computedLeft = headLeft - editorLeft + EDITOR_PADDING_LEFT
    const computedTop = headBottom + 10 - editorTop

    // const highlightDiv = document.querySelector('.continueHighlight')
    // if (highlightDiv) {
    //   // highlightDiv.addEventListener('mouseenter', () => {
    //   //   this.continueTool.hoverShow()
    //   // })
    //   // highlightDiv.addEventListener('mouseleave', () => {
    //   //   this.continueTool.hide()
    //   // })
    // }

    // this.continueTool.show(computedLeft, computedTop, '', '')
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    type: '',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <Tooltip position="bottom" trigger="hover" content="扩写">
          <div
            className="continuationBox iconBox"
            onClick={() => this.updateDocHandle(_editor)}>
            <IconExpand
              className="iconContinue icon"
              useCurrentColor={true}></IconExpand>
            {/* <span className="iconTitle">扩写</span> */}
          </div>
        </Tooltip>
      )
    },
    value: [],
  }
}

class ContinuePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = ContinueController
  public Schema = ContinueSchema
}

export { ContinuePlugin }
