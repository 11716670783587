import React, { useEffect, useRef, useState } from 'react'
import styles from './style/layout.module.less'
import IconDraw1 from '@/assets/imgs/draw2.svg'

import big2 from '../../assets/imgs/g-big2.jpg'
import DrawHead from './DrawHead'
import BorderBtn from '../../components/BorderBtn'
import EmptyMod from './EmptyMod'
import SynthesisLeftForm from './SynthesisLeftForm'
import ImageListMod from './ImageListMod'
import { aiImgBlend, getUserInfo } from '@/model/reference'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import request from '@/utils/request'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
let intervalId = null // 用于存储定时器ID
function ImageSynthesis2(props) {
  //鉴权登录状态
  useEffect(() => {
    getUserInfo({ uid: '' }).then((res) => {
      console.log(res)
    })
  }, [])
  //base64图片链接值
  const [base64Data, setBase64Data] = useState([null, null, null, null, null])
  //比例
  const [aspect, setAspect] = useState(null)
  //按钮置灰
  const [sendSuccess, setSendSuccess] = useState(true)
  //生成图片
  const [synthesisList, setSynthesisList] = useState(
    JSON.parse(localStorage.getItem('synthesisList'))?.slice(0, 10) || [],
  )
  //按钮样式置灰
  const [isContent, setIsContent] = useState(true)
  //让scrooltop回到顶部
  const rightRef = useRef(null)
  //骨架屏
  const [skeleton, setSkeleton] = useState(false)
  const [skeletonU1, setSkeletonU1] = useState(false)
  //数据条数list
  const listNumber = useRef(synthesisList.length)
  //原生url
  const [baseUrl, setBaseUrl] = useState([null, null, null, null, null])

  //子组件的base64回调
  // function updateImg(value, index) {
  //   console.log(index)
  //   if (value.length === 0) {
  //     return
  //   } else {
  //     setBase64Data((prevData) => {
  //       const newData = [...prevData]
  //       if (index <= newData.length) {
  //         // 如果 index 有效，则覆盖值
  //         newData[index] = value
  //       } else {
  //         // 否则，新增值
  //         newData.push(value)
  //       }
  //       return newData
  //     })
  //   }
  // }

  useEffect(() => {
    document.title = 'AI 图片创意合成 - 悟智AI'
  }, [])
  function updateImg(value, index) {
    setBase64Data((prevData) => {
      const newData = [...prevData]
      // 如果 index 在有效范围内，将插入值到指定位置
      newData[index] = value
      return [...newData] // 创建新数组以触发状态更新
    })
  }

  console.log(base64Data.length)
  //删除图片
  function delImg(value) {
    let newImg = [...base64Data]
    newImg[value] = null // Set the element at the specified index to null
    setBase64Data(newImg)
  }

  //   //存储原生url
  useEffect(() => {
    events.on(ACTIONS.BASE64URL, handleBaseUrl)

    return () => {
      events.off(ACTIONS.BASE64URL, handleBaseUrl)
    }
  })
  //存储原生url
  function handleBaseUrl(payload) {
    console.log(payload.index)
    setBaseUrl((prevData) => {
      const newData = [...prevData]
      // 如果 index 在有效范围内，将插入值到指定位置
      newData[payload.index] = payload.file
      return [...newData] // 创建新数组以触发状态更新
    })
    // setBaseUrl((prevData) => [...prevData, payload.file])
  }

  //删除原生url
  function delBaseUrl(value, index) {
    console.log(value)

    setBaseUrl(baseUrl.filter((item) => item != value))
  }
  console.log(baseUrl)

  //监听图片是否超过俩张
  useEffect(() => {
    const nonEmptyValues = base64Data.filter(
      (item) => item !== null && item !== undefined && item !== '',
    )
    if (nonEmptyValues.length >= 2) {
      setIsContent(false)
    } else {
      setIsContent(true)
    }
  }, [base64Data])

  console.log(base64Data)

  //获取当前时间戳
  function nowDate() {
    const currentDate = new Date()
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getSeconds().toString().padStart(2, '0')

    const formattedTime = `${hours}:${minutes}:${seconds}`
    console.log(`当前时间（格式化）：${formattedTime}`)
    return formattedTime
  }

  //监听页面滚动加载数据
  useEffect(() => {
    const handleScroll = () => {
      if (rightRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = rightRef.current
        // 当滚动到底部时触发加载更多数据的操作
        if (scrollTop + clientHeight >= scrollHeight) {
          loadMoreData()
        }
      }
    }

    // 添加滚动事件监听器
    rightRef?.current?.addEventListener('scroll', handleScroll)

    // 在组件卸载时移除滚动事件监听器
    return () => {
      rightRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [rightRef.current])

  //加载数据计算方法
  const loadMoreData = () => {
    //获取目前页面已有state数据

    console.log(listNumber)

    // 获取剩余未加载的数据
    const localStorageData = JSON.parse(localStorage.getItem('synthesisList'))

    // 计算剩余未加载的数据数量
    const number = localStorageData?.length - Number(listNumber?.current)

    // 最多加载10条数据
    const newDataToLoad = Math.min(number, 10)
    console.log(newDataToLoad)

    if (newDataToLoad > 0) {
      // 从本地存储中获取要加载的数据并添加到synthesisList中
      const newData = localStorageData.slice(
        listNumber,
        Number(listNumber.current) + newDataToLoad,
      )
      console.log(newData)

      // 更新synthesisList状态
      setSynthesisList(newData)
    }
  }

  //查询图片生成进度
  function submitFetch(resultId, type, valueImg) {
    const filteredValueImg = valueImg?.filter(
      (item) => item !== null && item !== undefined && item !== '',
    )
    setSendSuccess(false)

    function fetchProgress() {
      request
        .get('/api/api-aidb/submit/fetch', {
          params: { id: resultId },
        })
        .then((res) => {
          if (res.data.data.progress === 'Stopped') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '接口错误或含有敏感词，请重新换张图片尝试',
            })
            setSendSuccess(true)
            setSkeleton(false)
            setSkeletonU1(false)
            return // 停止递归
          }
          if (res.data.data.status === 'FAILURE') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '接口错误或含有敏感词，请重新换张图片尝试。',
            })
            setSendSuccess(true)
            setSkeleton(false)
            setSkeletonU1(false)
            return // 停止递归
          }
          if (res.data.success === true) {
            const data = res.data.data

            if (res.data.data.imageUrl != null) {
              setTimeout(() => {
                setSkeleton(false)
                setSkeletonU1(false)
              }, 1000)
              const data = res.data.data

              const updatedList = [...synthesisList]
              const existingItemIndex = updatedList.findIndex(
                (item) => item.id === resultId,
              )

              const currentTime = nowDate()
              const prefix =
                data.progress === '100%' ? '' : 'https://cors-hk.v-dk.com/'
              if (existingItemIndex !== -1) {
                updatedList[existingItemIndex].bigImg = data.imageUrl
              } else {
                updatedList.unshift({
                  link: prefix + data.imageUrl,
                  bigImg: prefix + data.imageUrl,
                  downloadImg: prefix + data.imageUrl,
                  baseUrl: filteredValueImg,
                  type: type,
                  id: resultId,
                  date: currentTime,
                  collect: false,
                  buttons: data.buttons,
                })
                console.log(updatedList)
                setSynthesisList(updatedList)
              }
              if (data.progress === '100%') {
                localStorage.setItem(
                  'synthesisList',
                  JSON.stringify(updatedList),
                )
                setTimeout(() => {
                  rightRef.current.scrollTop = 0
                  setSendSuccess(true)
                }, 2000)
                return // 停止递归
              }
            }

            // 如果请求成功且未达到100%进度，继续下一个请求
            setTimeout(fetchProgress, 3000) // 等待3秒后继续下一个请求
          }
        })
        .catch(() => {
          setSendSuccess(true)
          setSkeleton(false)
          setSkeletonU1(false)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '接口错误，请重新尝试',
          })
        })
    }

    // 开始第一个请求
    fetchProgress()
  }

  //合成图片
  function createAiImg(valueImg, valueAspect, baseUrl) {
    setSendSuccess(false)
    const filteredValueImg = valueImg?.filter(
      (item) => item !== null && item !== undefined && item !== '',
    )
    aiImgBlend({
      base64Array: filteredValueImg,
      dimensions: valueAspect,
      notifyHook: '',
      state: '',
    })
      .then((res) => {
        if (res.data.success == true) {
          setSkeleton(true)
          if (synthesisList.length > 0) {
            rightRef ? (rightRef.current.scrollTop = 0) : ''
          }
          const resultId = res.data.data.result
          submitFetch(resultId, 1, baseUrl)
        } else {
          setSendSuccess(true)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        setSendSuccess(true)
      })
  }
  //清除生成内容
  function handleClear() {
    localStorage.removeItem('synthesisList')
    setSynthesisList([])
  }
  //重新生成u1v1
  function handleSimple(value, type, valueImg) {
    if (type == 2) {
      setSkeletonU1(true)
    } else {
      setSkeleton(true)
    }
    submitFetch(value, type, valueImg)
  }

  //删除当前图片tab
  function close(index) {
    let newList = [...synthesisList]
    newList.splice(index, 1)
    setSynthesisList(newList)
    localStorage.setItem('synthesisList', JSON.stringify(newList))
  }
  //更新收藏状态
  function handleUpdateCollect(status, id) {
    const updatedList = synthesisList.map((item) => {
      if (item.id === id) {
        return { ...item, collect: status }
      }
      return item
    })
    // 更新state
    setSynthesisList(updatedList)
    localStorage.setItem('synthesisList', JSON.stringify(updatedList))
  }
  //scrooltop拉到顶部且骨架屏出现
  function updateScroolTop() {
    // setSkeleton(true)
    if (synthesisList.length > 0) {
      rightRef ? (rightRef.current.scrollTop = 0) : ''
    }
  }
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <DrawHead
          title={'AI 图片创意合成'}
          hideDoc={true}
          onClear={handleClear}
        />
      </div>
      <div className={styles.contain}>
        <div className={styles.left}>
          <div className={styles.left_contain}>
            <SynthesisLeftForm
              onUpdateImg={updateImg}
              aspect={aspect}
              setAspect={setAspect}
              delImg={delImg}
              delBaseUrl={delBaseUrl}
            />
          </div>
          <div
            className={`${styles['left_bot_btn']} ${
              !sendSuccess || isContent ? styles['disable'] : ''
            }`}
            onClick={() => {
              createAiImg(base64Data, aspect, baseUrl)
            }}>
            <BorderBtn
              height={50}
              fontSize={14}
              gradient={true}
              text={
                sendSuccess ? (
                  '合成图片'
                ) : (
                  <div className={`${styles['loading']}`}>
                    努力加载中
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className={styles.right}>
          {synthesisList && synthesisList.length > 0 && (
            <div className={styles.list}>
              <div className={styles.list_inner} ref={rightRef}>
                <ImageListMod
                  imageList={synthesisList}
                  sendSuccess={sendSuccess}
                  OnSimpleChange={handleSimple}
                  close={close}
                  updateScroolTop={updateScroolTop}
                  skeleton={skeleton}
                  skeletonU1={skeletonU1}
                  onUpdateCollect={handleUpdateCollect}
                  OnhandleAgain={(value1, value2, value3) => {
                    createAiImg(value1, value2, value3)
                  }}
                  aspect={aspect}
                  typePath={'Synthesis'}
                />
              </div>
            </div>
          )}
          {!synthesisList ||
            (synthesisList.length === 0 && (
              <EmptyMod
                icon={<IconDraw1 />}
                text={'请在左侧添加您的创意，创作 AI 智能图片'}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ImageSynthesis2
