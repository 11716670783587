import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react'
import styles from './index.module.less'
import Style from 'styled-components'
import { SylApi, EventChannel } from '@syllepsis/adapter'
import Title from './lib/component/title'
import editorAllPlugins from './lib/plugin'
import getDefaultModule from './lib/module'
import commentPlugins from './lib/plugin-comment'
import getCommentModule from './lib/module-comment'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { SylEditor } from '@syllepsis/access-react'
import { locale } from './lib/utils/index'
import { useHistory } from 'react-router-dom'
import '@syllepsis/plugin-basic/assets/style.css'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { RichEditor } from './lib/utils/richEditor'
import { Reporter } from './lib/utils/reporter'
import { throttle } from 'lodash'
import { EditorConnection, collabPlugin } from './lib/utils/collab'
import { toHTML } from '../../utils/textToMarkdown'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import EditorInitializationLoading from '@/components/Loading/RLLoading'
import { EDITOR_EMOJI_PLUGIN_REMOVE_ARIA_LABEL } from '@/config/editor-config'

const report = new Reporter()
const SylEditorStyle = Style.div<{
  btnWidth: string
  btnHeight: string
  isShare: boolean
  showRight: boolean
  isFullDisplay: boolean
  theme: string
}>`

  overflow-y: scroll;
  overflow-x: hidden;
  color:#000;
  /* padding-top: 28px; */

  .editor-class {
    top: 50px !important;
  }
 
  .syl-editor{
    width: ${({ isFullDisplay }) => (isFullDisplay ? ' 100%' : '')}};
    padding: 0 8%;
    /* display: flex; */
    /* justify-content: center; */
    /* font-size:16px; */
    color:${({ theme }) => (theme == 'dark' ? '#ececec' : '#000')};
    // font-weight: 500;
    position: absolute !important;
    /* left: 50% !important; */
    /* transform: translate(-50%) !important; */
    /* width: 100% !important; */
    font-family: sans-serif !important;
    width:${({ isShare }) => isShare && '700px'};
    margin:${({ isShare }) => isShare && '0 auto'};
   
    .ProseMirror > div {
      width: 100%;
      display: inline-block;
      /* height:300px;
      max-height: 400px;
      min-height:200px; */
    }

    .ProseMirror-yjs-cursor {
      position: absolute;
      /* margin-left: -1px;
      margin-right: -1px; */
      height: 22px;
      border-left: 1px solid black;
      border-right: 1px solid black;
      border-color: orange;
      word-break: normal;
      pointer-events: none;
    }

    .ProseMirror-yjs-cursor>div {
      position: absolute;
      /* top: -1.05em;
      left: -1px; */
      clip-path: polygon(50% 0%, 55% 15%, 100% 15%, 100% 100%, 0 100%, 0 15%, 45% 15%);
      transform: translate(-50%, 0);
      padding: 3px 3px 0px;
      font-size: 10px;
      /* background-color: red; */
      /* font-family: serif; */
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      user-select: none;
      color: white;
      white-space: nowrap;
    }
    
    .tableWrapper {
      display: block !important;
      overflow-x:scroll;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
        height: 6px;
      }
    }
    .ProseMirror-hideselection *::selection{
      color: #3e3e3e;
    }
    .ProseMirror-focused .ProseMirror-selectednode {
        cursor: grab;
        background: #000;
      
      }
        .ProseMirror-focused .ProseMirror-selectednode:active{
          cursor: grabbing;
          opacity: 0.5
        }
    .ProseMirror{
      padding-bottom:40px;
      border:none;
      overflow: visible;
      height: 100%;
      width: 700px !important;
      /* margin: 0 auto; */
      max-width:${({ showRight, isFullDisplay }) =>
        !showRight
          ? isFullDisplay
            ? ' 1300px !important'
            : ' 700px !important'
          : ' 700px !important'};
      padding: 0 !important;
      
      li:has(> div[data-type="todo_item"]  ){
        list-style-type: none;
        padding:4px 0;
      }
      div[data-type="todo_item"] {
        display: flex;
        flex-direction: row;
        line-height: 18px;
        align-items: flex-start;
      }
  
      .todo-checkbox {
        border: 1px solid;
        height: 18px;
        width: 18px;
        box-sizing: border-box;
        margin-right: 10px;
        /* margin-top: 14px; */
        user-select: none;
        background-size: contain;
        -webkit-user-select: none;
        cursor: pointer;
        border-radius: 2px ;
        /* :hover{
          border: 1px solid #FF6500;
        } */
      }
      
      .todo-checkbox-checked {
        background:url('https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/12/taskCheck.svg') no-repeat;
        /* background-color: black; */
        border: none;
        /* background-color:#FF6500; */
      }
      
      .todo-content {
        flex: 1;
      }
      
      div[data-done="true"] {
       
        
        .todo-content {
        text-decoration: line-through;
        flex: 1;
        color:#ececec !important;
        text-decoration-color: #ececec!important;
        /* background-color:#f5f5f5; */
        border-radius: 4px;
        /* padding:0 18px; */
      }
      }

      div[data-done="true"] .todo-checkbox-unchecked {
        display: none;
      }

      div[data-done="false"] .todo-checkbox-checked {
        display: none;
      }

      div[data-done="true"] div[data-done="false"] .todo-checkbox-checked {
        display: inline-block;
      }

      div[data-done="false"] div[data-done="true"] .todo-checkbox-checked {
        display: inline-block;
      }

      div[data-done="false"] {
        text-decoration: none;
      }

      div[__syl_tag="true"] {
        .syl-image-failed {
          width: 100% !important;
        }
      }
    
      blockquote {
        background-color: ${({ theme }) =>
          theme == 'dark' ? '#1e1e1e' : '#fff'};;
        border-left: 4px solid #3C3C3C;
        margin: 0 0 20px;
        padding: 10px 20px;
      }

      /* 代码块左侧数字 */
      .CodeMirror-gutter-wrapper .CodeMirror-linenumber {
        padding: 10px 0;
      }

      /* 代码块光标样式 */
      .CodeMirror-cursor {
        max-height: 22px;
      }
      
      /* 解决代码块最上面和最下面突出 */
      .CodeMirror-line::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: #fafafa;
      }

      .CodeMirror-line::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: #fafafa;
      }

      /* 标题样式 */
      h1 {
        font-size: 36px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      h2 {
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      h3 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

       h4 {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h6 {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h7 {
        font-size: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h8 {
        font-size: 8px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h9 {
        font-size: 6px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      /* 段落样式 */
      p {
        margin-bottom: 15px;
        line-height: 1.5 !important;
      }

      span {
        /* color: ${({ theme }) =>
          theme == 'dark' ? '#c0c0c0 !important' : '#3e3e3e'}; */
        color: ${({ theme }) => (theme == 'dark' ? '#c0c0c0 !important' : '')};
      }
    
      /* 引用样式 */
      blockquote {
        margin: 0 0 20px;
        padding: 10px 20px;
        background-color: ${({ theme }) =>
          theme == 'dark' ? '#1e1e1e' : '#f9f9f9'};
        border-left: ${({ theme }) =>
          theme == 'dark'
            ? '4px solid #3C3C3C !important'
            : '4px solid #ccc !important'}; ;
        p {
          line-height: 1.2;
          span {
            background-color: ${({ theme }) =>
              theme == 'dark' ? '#1e1e1e !important' : '#F9F9F9 !important'};
          }
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    
      /* 无序列表样式 */
      ul {
        margin-bottom: 15px;
        list-style-type: disc;
        padding-left: 40px;
        /* li span{
          span {
            background-color: ${({ theme }) =>
              theme == 'dark' ? '#1e1e1e !important' : '#fff !important'};
          }
        } */
      }
    
      /* 有序列表样式 */
      ol {
        margin-bottom: 15px;
        list-style-type: decimal;
        padding-left: 40px;
      }
    
      /* 链接样式 */
      a {
        color: #e67023;
        text-decoration: none;
      }
    
      a:hover {
        text-decoration: underline;
      }
    
      /* 表格样式 */
      table {
        width: 100% !important;
        border-collapse: collapse;
        margin-bottom: 15px;
      }
    
      th,
      td {
        padding: 18px;
        border: ${({ theme }) =>
          theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #ccc'};
        p{
          margin: 0;
        }
      }
    
      th {
        background-color: #f2f2f2;
      }
    
      /* 图像样式 */
      img {
        height:initial;
        max-width: 100%;
        margin-bottom: 15px;
      }
    
      /* 代码块样式 */
      pre {
        /* background-color: #f5f5f5; */
        padding: 10px;
        border-radius: 4px;
        overflow-x: auto;
      }
    
      code {
        font-family: Consolas, Monaco, monospace;
      }
      .syl-fileUpload-wrapper{
        width: 400px;
        height:66px;
        border-radius: 8px;
        border:2px solid red;
      }
      .headerClass{
        display: block;
        .illustrate{
          width: 10px;
          height:10px;
          background:red;
          position:absolute;
        }
      }
      
    
    }
    
    @media only screen and (max-width: 2256px) {
      .ProseMirror {
        width:${({ showRight, isFullDisplay }) =>
          !showRight
            ? isFullDisplay
              ? ' 1200px !important'
              : ' 700px !important'
            : ' 700px !important'} ;
      }
      
    }

    @media only screen and (max-width: 2056px) {
      .ProseMirror {
        width:${({ showRight, isFullDisplay }) =>
          !showRight
            ? isFullDisplay
              ? ' 1200px !important'
              : ' 700px !important'
            : ' 700px !important'} ;
      }
      
    }
    @media only screen and (max-width: 1800px) {
      .ProseMirror {
        width:${({ showRight, isFullDisplay }) =>
          !showRight
            ? isFullDisplay
              ? ' 1100px !important'
              : ' 600px !important'
            : ' 600px !important'};
      }
      
    }
    @media only screen and (max-width: 1620px) {
      .ProseMirror {
        width: ${({ showRight, isFullDisplay }) =>
          !showRight
            ? isFullDisplay
              ? '100% !important'
              : '560px !important'
            : ' 560px !important'};
      }
      
    }
    // @media only screen and (max-width: 1400px) {
    //   .ProseMirror {
    //     max-width: 430px !important;
    //   }
    //   #toolbarContainer {
    //     width: 430px !important;
    //   }
    // }
  }

 
  .toolbar{
    background-color:#000;
    position: absolute;
    bottom: 0;
  }

  .syl-toolbar{
    background-color:#000 !important;
  }

  
 
  
  .syl-toolbar-tool.active .syl-toolbar-button{
    background:#fff;
    color:${({ theme }) => (theme == 'dark' ? '#fff' : '#3e3e3e')};
  
    
  }

  .syl-toolbar-tool.disable,
  .syl-toolbar-tool.disable .syl-toolbar-button,
  .syl-toolbar-tool.disable,
  .syl-toolbar-tool-horizon.disable,
  .syl-toolbar-tool-horizon.disable .syl-toolbar-tool-horizon-content {
    cursor: not-allowed;
    opacity: 1;
    background-color: unset;

    svg {
      color: #b4b4b4 !important;

      path {
        stroke: #b4b4b4 !important;;
      }
    }
  }


  .syl-toolbar-button > div span {
    font-size: 14px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    color:${({ theme }) => (theme == 'dark' ? '#fff' : '#3e3e3e')};
  }
  
  .syl-toolbar-inline> *:not(:last-child) {
    margin:0;
  }
  /* .syl-toolbar-inline> *:not(:first-child) .syl-toolbar-button:before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    background-color: #EFEFEF;
    height: 24px;
    margin-top: -12px;
    z-index:0;
  } */

  .continueAdoptBox:hover{
    border-radius:4px 0  0 4px;
    background-color:#3C6DDD;
    color:#fff;
    .iconContinue{
      fill:#fff;
    }
  }

  .continueCancelBox:hover{
    border-radius:0 4px  4px  0 ;
    background-color:#3C6DDD;
    color:#fff;
    .iconContinue{
      fill:#fff;
    }
  }
  .syl-image-caption-input{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color:${({ theme }) =>
      theme == 'dark' ? '#19191A !important' : 'transparent !important'};
     color:${({ theme }) =>
       theme == 'dark' ? '#ececec !important' : '#3E3E3E !important'};
  }

  .syl-video-wrapper{
    width: -webkit-fill-available;
  }


  /* .syl-toolbar-inline + div {
    background-image: linear-gradient(99deg, rgba(148, 228, 184, 1), rgba(139, 224, 228, 1), rgba(210, 224, 255, 1), rgba(215, 176, 255, 1)) ;
   } */
 
  .syl-toolbar-inline{
   
    padding:4px;
    /* border: 4px solid #efefef; */

    box-sizing: content-box;
    box-shadow:none;
    /* border: 8px solid; */
    /* box-shadow: 0px 2px 8px linear-gradient(99deg, #E7FDF1 0%, #E8FAFD 33%, #EAF2FF 67%, #F6EEFF 100%); */
    /* background: linear-gradient(99deg, #E7FDF1 0%, #E8FAFD 33%, #EAF2FF 67%, #F6EEFF 100%); */
    /* background-image: linear-gradient(99deg, rgba(148, 228, 184, 1), rgba(139, 224, 228, 1), rgba(210, 224, 255, 1), rgba(215, 176, 255, 1)) ; */
    /* background-image:  linear-gradient(99deg, #E7FDF1 0%, #E8FAFD 33%, #EAF2FF 67%, #F6EEFF 100%); */
    border-radius: ${({ theme }) => (theme == 'dark' ? '0 ' : '4px')};
    background-image: linear-gradient(99deg, rgba(244, 177, 255, 1), rgba(214, 192, 255, 1), rgba(255, 182, 133, 1));
    .task_list .commentText {
      .syl-toolbar-button{
       
        border-radius:0 4px  4px 0 !important;
        margin-right:4px;
        padding-right: 30px;
        padding-left: 6px;
        svg{
          width: 20px !important;
        }
      }
    }

    .task_list {
      .syl-toolbar-button{
       
        border-radius:0 4px  4px 0 !important;
        margin-right:4px;
        padding-right: 30px;
        padding-left: 6px;
        svg{
          width: 20px !important;
        }
      }
    }

    .syl-toolbar-tool{
       .syl-toolbar-button{
        margin:0;
        background-color:${({ theme }) =>
          theme == 'dark' ? '#19191A !important' : '#fff !important'};
        
        border-radius:0;
        .i-icon-color svg path{
          background: ${({ theme }) =>
            theme == 'dark' ? '#fff !important' : '#060606 !important'};
          stroke: ${({ theme }) => theme == 'dark' && '#fff !important'};
        }
        .i-icon-background svg path{
          background: ${({ theme }) =>
            theme == 'dark' ? '#fff !important' : '#060606 !important'};
          stroke: ${({ theme }) => theme == 'dark' && '#fff !important'};
        }
        .aidb-icon-task-list {
          width: :20px;
        }
        .aidb-icon-add-comments{
          width:20px;
        }
        svg{
          color:${({ theme }) => (theme == 'dark' ? '#fff' : '#060606')};
        }
        }
       }

       &.header{
        .syl-toolbar-button{
          padding: 0 0 0 10px;
        }
       }

       .syl-color-picker-container {
          background-color:${({ theme }) =>
            theme == 'dark' ? '#19191a !important' : '#fff !important'}; 
        }
       
      
       /* &:hover{
        background-color:#fff!important;
        border-radius:6px;
       } */
       .syl-toolbar-button {
        width: 30px;
        /* width: ${({ btnWidth }) => (btnWidth ? btnWidth + 'px' : '60px')}; */
        height: ${({ btnHeight }) => (btnHeight ? btnHeight + 'px' : '48px')};
        border:none;
        background-color: transparent;
        color:#606060;
         min-height: 0;
         line-height: 0;
         svg{
          width: 14px;
          color:#606060;
         }
         .iconBox{
          justify-content: center;
          height: 38px;
          line-height: 38px;
          display: flex;
          background-color: #fff;
          font-weight: 500;
            svg{
              margin: initial;
            }
            .icon{
              width:20px;
              height:20px;
              /* margin: 14px 6px 14px 0; */
              display: inline-block !important;
              color:  ${({ theme }) =>
                theme == 'dark' ? '#fff ' : '#3e3e3e '};;
              fill:  ${({ theme }) =>
                theme == 'dark' ? '#fff ' : '#3e3e3e '};;
            }
            .iconTitle{
                color:${({ theme }) =>
                  theme == 'dark' ? '#fff ' : '#3e3e3e '};;
                font-size:14px;
            }
          }
         }
         .rewriteBox{
          border-radius:6px 0  0 6px;
         }
        .rewriteBox:hover{
         border-radius:6px 0  0 6px;
          background-color:#F2FCF8;
          color:#fff;
          .iconParaphrase{
            color:#1eb980;
          }
          .iconTitle{
              color:#fff
          }
         }
         .continuationBox:hover{
           background-color:#F6F9FF;
           .syl-toolbar-inline> *:not(:first-child) .syl-toolbar-button:before{
            background-color:#F6F9FF;
          }
           color:#fff;
           .iconContinue{
             color:#3c6ddd;
           }
           .iconTitle{
              color:#fff
          }
          }
         .commentBox{
          box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
          border-radius:4px;
          .iconContinue{
            // color:#DAC4B6;
          }
          .iconTitle{
              color:#fff
          }
         }
         .commentBox:hover{
          // background-color:#FF6A00;
          // color:#fff;;
          border-radius:4px;
          .iconContinue{
            // color:#fff;
          }
          .iconTitle{
              color:#fff
          }
         }
         
         .toolsBox:hover{
          border-radius: 0 20px 20px  0;
          background-color:#000;
          color:#fff; 
          .iconTools{
            fill: #fff;
          }
          .iconTitle{
              color:#fff
          }
        }
        .chatGPTBox:hover{
          
          background-color:#ff6500;
          color:#fff;
          .iconChatGPT{
            color:#fff;
          }
          .iconTitle{
              color:#fff
          }
        }
        .reduceBox:hover{
          
          background-color:#EDFDFD;
          color:#fff;
          .iconReduce{
            color:#159D9D;
          }
          .iconTitle{
              color:#fff
          }
        }
        .translateBox{
          border-radius: 0 6px 6px  0;
        }
        .translateBox:hover{
          border-radius: 0 6px 6px  0;
          background-color:#FAF6FF;
          color:#fff;
          .iconTranslate{
            color:#874cc3;
          }
          .iconTitle{
              color:#fff
          }
        }
        .AiDirectivesBox{
          justify-content: center;
          align-items: center;
          width: 88px !important;
          margin:auto 6px;

          background: ${({ theme }) =>
            theme == 'dark'
              ? '#3D1F42 !important'
              : 'linear-gradient(270deg, #FEF4FF 0%, #FCE6FF 100%) !important'}; 
          border-radius: 3px;
          
          .iconTitle{
            margin-left: 4px;
            color:#C437AE !important;
          }
          svg{
            color:#C437AE  !important;
          }
        }
        .AiDirectivesBox:hover{
          background:linear-gradient(135deg, #C437AE 0%, #EA3DA8 100%) !important;
          .iconTitle{
            margin-left: 4px;
            color:#fff !important;
          }
          svg{
            color:#fff  !important;
          }
        }
        .AiToolsBox{
          padding: 0 10px;
          justify-content: center;
          align-items: center;
          width: 92px !important;
          margin: 0 5px;
          background-color:${({ theme }) =>
            theme == 'dark' ? '#19191A !important' : '#fff !important'};
          border-radius: 3px;
          .iconTitle{
            margin-left: 4px;
          }
          /* .iconTitle{
            margin-left: 4px;
            color:#C437AE !important;
          }
          svg{
            color:#C437AE  !important;
          } */
        }
        .AiToolsBox:hover{
          background: #F5F5F5;
         
        }
      }  
      .AiDirectives{
        border-radius:  4px 0 0 4px ;
        width: 98px !important;
        background-color:${({ theme }) =>
          theme == 'dark' ? '#19191A !important' : '#fff !important'};;
        .syl-toolbar-button{
          border-radius:6px 0  0 6px !important;
          margin-left: 1px;
        }
      }
      .AiTools{
        background-color:${({ theme }) =>
          theme == 'dark' ? '#19191A !important' : '#fff !important'};
        width: 100px !important;
      }
      .AiToolsBox:before{
        content: '';
        position: absolute;
        right:0;
        top: 50%;
        width: 1px;
        background-color: #F5F5F5;
        height: 24px;
        margin-top: -12px;
        z-index:0;
      }
      
    

`
interface Props {
  userInfo: object
  yjsEditorCoordinationInfo: {
    // 当前客户端ID
    clientID: number
    // 在线状态及个人信息
    isOnline: [
      {
        id: number
        name: string
        online: boolean
      },
    ]
    // 当前用户信息
    userInfo: {
      id: number
    }
    // 当前协同列表
    employeeList: {
      employeeList: Array<any>
      total: number
    }
    // 所有协同用户操作信息
    awarenessMap: Array<any>
  }
  uuid: string
  employeePermission: number
  docContent?: string
  docTitle?: string
  showDocEdit?: boolean
  editTitle?: React.Dispatch<React.SetStateAction<string>>
  editInputTitle?: React.Dispatch<React.SetStateAction<string>>
  editContent?: React.Dispatch<React.SetStateAction<string>>
  editContentHtml?: React.Dispatch<React.SetStateAction<string>>
  changeContent?: React.Dispatch<React.SetStateAction<string>>
  getContentDoc?: any
  onSubmit?: (approvalStatus: number) => void
  isComment?: boolean
  btnWidth?: number
  btnHeight?: number
  changeContentContent?: React.Dispatch<React.SetStateAction<number>>
  contentLen?: number
  isShare?: boolean
  changeHeader?: (header: any) => void
  isAutoSave?: boolean
  showTitle?: boolean
  isDisableEdit?: boolean
  showRight?: boolean
  isFullDisplay?: boolean
  showHeads?: boolean
  ref?: any
  theme?: string
}

const WritingContent = forwardRef((props: Props, ref) => {
  const {
    userInfo,
    yjsEditorCoordinationInfo,
    employeePermission,
    uuid,
    docTitle,
    docContent,
    showDocEdit,
    editTitle,
    editContent,
    editContentHtml,
    changeContent,
    changeContentContent,
    getContentDoc,
    onSubmit,
    // editInputTitle,
    isComment,
    btnWidth,
    btnHeight,
    contentLen,
    isShare,
    changeHeader,
    isAutoSave,
    showTitle,
    showRight,
    isFullDisplay,
    isDisableEdit,
    showHeads,
    theme,
  } = props
  const dispatch = useDispatch()
  const EditorContainerRef = useRef<HTMLDivElement>(null)
  const ToolBarMountRef = useRef(document.createElement('div'))
  const TitleRef = useRef(null)
  const editorRef = useRef<SylApi>(null)
  const [editor, setEditor] = useState(null)
  const [connection, setConnection] = useState(null)
  const plugins = useMemo(
    () =>
      !isComment || !isShare || !isDisableEdit
        ? editorAllPlugins(
            dispatch,
            yjsEditorCoordinationInfo,
            uuid,
            userInfo,
            editorRef,
            docContent,
          )
        : commentPlugins,
    [
      isComment,
      isShare,
      isDisableEdit,
      yjsEditorCoordinationInfo,
      uuid,
      userInfo,
      editorRef,
      docContent,
    ],
  )
  const [module, setModule] = useState(
    !isComment || !isDisableEdit
      ? getDefaultModule(ToolBarMountRef.current, 0)
      : getCommentModule(),
  )
  const headerListRef = useRef([])
  const history = useHistory()
  let editorLastDev = null
  let scrollTop, editorHeight

  // 创建一个内部的 divRef 用于绑定到 div 元素
  const divRef = useRef<HTMLDivElement>()

  // 获取store中粘贴方式
  const editorPasteMethodType = useSelector(
    (state: GlobalState) => state.editorPasteMethodType,
  )

  // 使用 useImperativeHandle 将 divRef 暴露给外部
  useImperativeHandle(ref, () => ({
    divRef, // 暴露 divRef
  }))

  const getId = () => {
    let id = localStorage.getItem('PR_ID')
    if (!id) {
      id = Date.now().toString().substr(6)
      localStorage.setItem('PR_ID', id)
    }
    return id
  }

  let info = {
    doc: 'test',
    id: getId(),
  }
  useEffect(() => {
    if (!editor) return
    if (connection) connection.close()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newConnection = (window.connection = new EditorConnection(
      report,
      '/collab-backend/docs/' + info.doc,
      info.id,
      editor,
    ))

    newConnection.request.then(() => editor.focus())
    setConnection(newConnection)
  }, [editor])

  // 初始化编辑器聚焦
  useEffect(() => {
    if (editorRef.current) editorRef.current.focus()
  }, [editorRef.current])

  useEffect(() => {
    const container = EditorContainerRef.current
    ToolBarMountRef.current.id = `toolbarContainer`
    container.appendChild(ToolBarMountRef.current)

    // document
    //   .querySelector('.syl-editor')
    //   .lastElementChild.setAttribute(
    //     'style',
    //     'background-image: linear-gradient(99deg, #E7FDF1 0%, #E8FAFD 33%, #EAF2FF 67%, #F6EEFF 100%);position: absolute; padding:4px;border-radius:24px;display:none;',
    //   )
    document.querySelector('#toolbarContainer').setAttribute(
      'style',
      `position: absolute;left: 50%;
      transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width:${
        !showRight &&
        (isFullDisplay ? ' 1220px !important' : ' 700px !important')
      };background: ${theme == 'dark' ? '#19191a' : '#fff'}`,
    )
    if (theme == 'dark') {
      document.querySelector('#toolbarContainer').classList.add('darkTheme')
    }

    const ToolbarInlineLeft = EditorContainerRef.current.offsetWidth

    // editor.root.firstElementChild.getBoundingClientRect().left
  }, [])

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (showRight) {
      if (windowWidth <= 2256 && windowWidth >= 1800) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${720}px;background: ${
            theme == 'dark' ? '#19191a' : '#fff'
          } `,
        )
      } else if (windowWidth <= 1800 && windowWidth >= 1620) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${620}px; background: ${
            theme == 'dark' ? '#19191a' : '#fff'
          }`,
        )
      } else if (windowWidth <= 1620 && windowWidth >= 1400) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${560}px; background: ${
            theme == 'dark' ? '#19191a' : '#fff'
          }`,
        )
      } else if (windowWidth <= 1400) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${560}px;background: ${
            theme == 'dark' ? '#19191a' : '#fff'
          } `,
        )
      }
    } else {
      if (windowWidth <= 2256 && windowWidth >= 1800) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${
              isFullDisplay ? 1220 : 720
            }px; background: ${theme == 'dark' ? '#19191a' : '#fff'}`,
        )
      } else if (windowWidth <= 1800 && windowWidth >= 1620) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${
              isFullDisplay ? 1120 : 620
            }px; background: ${theme == 'dark' ? '#19191a' : '#fff'}`,
        )
      } else if (windowWidth <= 1620 && windowWidth >= 1400) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${
              // isFullDisplay ? 1020 : 560
              isFullDisplay ? 1020 : 600
            }px;background: ${theme == 'dark' ? '#19191a' : '#fff'} `,
        )
      } else if (windowWidth <= 1400) {
        document.querySelector('#toolbarContainer').setAttribute(
          'style',
          `position: absolute;left: 50%;
            transform: translate(-50%);bottom:0;border:none;font-family: sans-serif ;width: ${
              // isFullDisplay ? 920 : 560
              isFullDisplay ? '84%' : '650px'
            }; background: ${theme == 'dark' ? '#212121' : '#fff'} `,
        )
      }
    }
  }, [showRight, isFullDisplay])

  useEffect(() => {
    const setEditorMaxHeight = () => {
      const windowWidth = window.innerWidth
      editorHeight = +getComputedStyle(
        document.querySelector('.syl-editor'),
      ).height.replace('px', '')
      document
        .querySelector('.ProseMirror')
        .setAttribute('style', `min-height: ${editorHeight}px; `)
      if (showRight) {
        if (windowWidth <= 2056) {
          document
            ?.querySelector('#toolbarContainer')
            .querySelector('#toolbarContainer')
            ?.setAttribute('style', `width: ${600}px; `)
        }
      }
    }

    setEditorMaxHeight()
    window.addEventListener('resize', setEditorMaxHeight)
    return () => {
      window.removeEventListener('resize', setEditorMaxHeight)
    }
  }, [])

  useEffect(() => {
    let editorSyl = document.querySelector('.syl-editor')
    let editorSylDev = editorSyl.querySelectorAll(':scope > div')
    editorLastDev = editorSylDev[editorSylDev.length - 1] as HTMLElement

    if (editorLastDev) {
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'style'
          ) {
            const displayValue = window
              .getComputedStyle(editorLastDev)
              .getPropertyValue('top')

            const numericValue = parseFloat(displayValue.replace('px', ''))
            // console.log(
            //   `display value changed: ${displayValue} ，value：${numericValue}`,
            // )
            // 判断逻辑
            setTimeout(() => {
              editorLastDev && editorLastDev.classList.remove('editor-class')
            }, 1)
            if (numericValue < 0) {
              setTimeout(() => {
                editorLastDev && editorLastDev.classList.add('editor-class')
              }, 2)
            }
          }
        }
      })

      observer.observe(editorLastDev, { attributes: true })
      return () => observer.disconnect() // 清理函数，在组件卸载时取消监听
    }
  }, [editorLastDev && editorLastDev.classList])

  useEffect(() => {
    const sylEditor = document.getElementsByClassName('syl-editor')[0]
    sylEditor.addEventListener('scroll', () => {
      scrollTop = document.getElementsByClassName('syl-editor')[0].scrollTop
    })
  }, [scrollTop])

  useEffect(() => {
    const editor = editorRef.current

    if (docContent) {
      editContentHtml(docContent)
      // 如果不协同（协同者小于两人），正常渲染，否则在协同中渲染
      if (yjsEditorCoordinationInfo.employeeList.total < 2) {
        editContent(docContent)
        editor.setHTML(`${docContent}`)
        events.emit('GET_WORD_COUNT_EDITOR')
        // 关闭编辑器 Loading 状态
        EditorInitializationLoading.close()
      }
      if (GetQueryValue('upload')) {
        const { doc, selection } = editor.view.state
        const from = 0
        const to = doc.content.size
        const tr = editor.view.state.tr.removeMark(from, to)
        editor.view.dispatch(tr)
        const urlQuery = removeEmpty({
          uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
          filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
          listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
          folderUuid: GetQueryValue('folderUuid')
            ? GetQueryValue('folderUuid')
            : '',
          from: GetQueryValue('from') ? GetQueryValue('from') : '',
        }) as any
        history.replace({
          pathname: '/documentEdit',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })
      }
    } else {
      // 关闭编辑器 Loading 状态
      EditorInitializationLoading.close()
    }
    if (!isDisableEdit) {
      getContentDoc(editor.getContent())
    }

    // if (docContent && !isComment) {
    //   getContentDoc(editor.getContent())
    //   changeContentContent(editor.getText().length)
    // }
    headerListRef.current = generateTableOfContents(editor)
    // if (docContent && showHeads) {
    //   changeHeader(generateTableOfContents(editor))
    // }
    changeHeader && changeHeader(generateTableOfContents(editor))
  }, [docContent])

  // 协同权限管理
  useEffect(() => {
    const editor = editorRef.current

    /**
     * permission：1-可管理，2-可编辑，3-可阅读，4-无任何权限
     */
    switch (employeePermission) {
      case 3:
        editor.disable()
        break
      case 4:
        editor.disable()
        location.href = '/home'
        break

      default:
        break
    }
  }, [employeePermission])

  useEffect(() => {
    const editor = editorRef.current
    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const handleScroll = () => {
      const scrollHeight = sylEditorBox.scrollTop
      processArray(headerListRef.current, scrollHeight)
    }
    sylEditorBox.addEventListener('scroll', handleScroll)

    return () => {
      sylEditorBox.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // 编辑器初始化时还原刷新前的位置
  useEffect(() => {
    const scrollContainer = document.getElementById('sylEditorBox')
    const uuid = GetQueryValue('uuid')

    if (scrollContainer) {
      // 定义监听视频加载的函数
      const watchMediaLoad = () => {
        const videoElements =
          scrollContainer.getElementsByTagName('.ProseMirror video')
        const imgElements =
          scrollContainer.getElementsByTagName('.ProseMirror img')

        const allMediaLoaded = []

        if (videoElements.length > 0) {
          const allVideosLoaded = Array.from(videoElements).map((v) => {
            let video = v as HTMLVideoElement
            return new Promise((resolve) => {
              video.oncanplaythrough = resolve
            })
          })
          allMediaLoaded.push(...allVideosLoaded)
        }

        if (imgElements.length > 0) {
          const allImgsLoaded = Array.from(imgElements).map((i) => {
            let img = i as HTMLVideoElement
            return new Promise((resolve) => {
              img.onload = resolve
            })
          })
          allMediaLoaded.push(...allImgsLoaded)
        }

        Promise.all(allMediaLoaded)
          .then(() => {
            const scrollPosition = localStorage.getItem(
              `editorScrollPosition_${uuid}`,
            )
            if (scrollPosition) {
              setTimeout(() => {
                scrollContainer.scrollTop = parseInt(scrollPosition)
              }, 10)
            }
          })
          .catch(() => {
            const scrollPosition = localStorage.getItem(
              `editorScrollPosition_${uuid}`,
            )
            if (scrollPosition) {
              setTimeout(() => {
                scrollContainer.scrollTop = parseInt(scrollPosition)
              }, 10)
            }
          })
      }

      // 创建 MutationObserver 实例来监听DOM变化
      const observer = new MutationObserver(watchMediaLoad)
      observer.observe(scrollContainer, { childList: true, subtree: true })

      // 滚动事件监听器
      const saveScrollPosition = throttle(() => {
        localStorage.setItem(
          `editorScrollPosition_${uuid}`,
          scrollContainer.scrollTop.toString(),
        )
      }, 1000)
      scrollContainer.addEventListener('scroll', saveScrollPosition)

      return () => {
        // 组件卸载前移除事件监听并保存滚动位置
        scrollContainer.removeEventListener('scroll', saveScrollPosition)
        observer.disconnect() // 停止监听DOM变化
      }
    }
  }, [])

  // 解决代码块光标第一行错位
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (
              node instanceof HTMLElement &&
              node.className === 'CodeMirror-cursor'
            ) {
              // 获取光标所在的段落
              const line = document.elementFromPoint(
                node.getBoundingClientRect().left,
                node.getBoundingClientRect().top,
              )

              // 如果获取到的段落存在
              if (line) {
                const cursorRect = node.getBoundingClientRect()
                const lineRect = line.getBoundingClientRect()

                // 计算光标在视口中的位置和段落顶部的距离
                const distance = Math.abs(cursorRect.top - lineRect.top)

                // 如果距离小于10px，则认为光标处于新段落的开始，增加top值
                if (distance < 10) {
                  const cursorStyle = window.getComputedStyle(node)
                  let cursorTop = parseInt(cursorStyle.top, 10)
                  cursorTop += 10 // 在原有基础上增加10px
                  node.style.top = `${cursorTop}px`
                }
              }
            }
          })
        }
      })
    })

    observer.observe(document, {
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  // 编辑器粘贴处理
  useEffect(() => {
    const handlePaste = async (event) => {
      event.stopPropagation()

      // 文本转换直接粘贴
      if (editorPasteMethodType === 'text_paste') return

      const editor = editorRef.current
      const clipboardData = event.clipboardData || (window as any).clipboardData
      let pasteText = clipboardData.getData('text')
      const markdownTextArr = await toHTML(pasteText, editorPasteMethodType)

      // console.log('After marked:', markdownTextArr)

      // 在当前光标位置插入新的文本 先撤销未转为markdown的文本，再将markdown文本粘贴进去
      editor.undo()
      // console.log('valuexxxxxxxxxxxxx:', editorPasteMethodType)

      /**
       * 第一种方式：
       *    直接将svg转为图片显示，普通markdown则直接显示
       */
      markdownTextArr.forEach((item, index) => {
        if (!item.tag) return

        if (item.type === 'svg') {
          // item.tag.match(/<svg[^>]*>[\s\S]*?<\/svg>/)[0]
          let utf8Svg = encodeURIComponent(item.tag)

          editorRef.current &&
            editorRef.current.insertCard('image', {
              src: 'data:image/svg+xml;utf8,' + utf8Svg,
            })
        } else if (item.type === 'html') {
          editor.pasteContent(item.tag, {
            plainText: false,
          })
        }
      })

      /**
       * 第二种方式：
       *    先生成一个pre代码块，然后去除掉mask中的内容，再将自己的内容放入mask，这样可以显示svg，但是刷新会失效
       */
      // editor.pasteContent(
      //   `<p class="temporary-tags"><pre><code>xx</code></pre></p>`,
      //   {
      //     plainText: false,
      //   },
      // )
      // let targetP = document.querySelector('.ProseMirror .temporary-tags')
      // if (targetP) {
      //   let nextSibling = targetP.nextElementSibling
      //   if (nextSibling && nextSibling.tagName === 'DIV') {
      //     let mask = nextSibling.querySelector('mask')
      //     if (mask) {
      //       // 清空mask下所有子元素
      //       while (mask.firstChild) {
      //         mask.removeChild(mask.firstChild)
      //       }
      //       // 添加新的p元素
      //       let newElement = document.createElement('div')
      //       newElement.className = 'xxx'
      //       markdownTextArr.forEach((item) => {
      //         newElement.innerHTML += item.tag
      //       })
      //       mask.appendChild(newElement)
      //     }
      //   }
      //   // 删除class为"temporary-tags"的p标签
      //   targetP.remove()
      // }
      // document.querySelector('.ProseMirror') &&
      //   editor.setHTML(`${document.querySelector('.ProseMirror').innerHTML}`)

      editor.focus()
    }

    const editor = document.querySelector('.ProseMirror')
    if (editor) {
      editor.addEventListener('paste', handlePaste)

      // 在卸载时移除事件监听器
      return () => {
        editor.removeEventListener('paste', handlePaste)
      }
    }
  }, [editorPasteMethodType])

  // 删除某些表情
  useEffect(() => {
    const observer = new MutationObserver(() => {
      EDITOR_EMOJI_PLUGIN_REMOVE_ARIA_LABEL.forEach((label) => {
        document
          .querySelectorAll(`[aria-label$="${label}"]`)
          .forEach((item) => {
            item.remove()
          })
      })
    })

    const emojiCategoryList = document.querySelector('.emoji-mart-scroll')
    if (emojiCategoryList) {
      observer.observe(emojiCategoryList, { childList: true, subtree: true })
    }

    return () => {
      if (emojiCategoryList) {
        observer.disconnect()
      }
    }
  }, [document.querySelector('.emoji-mart-scroll')])

  const checkRange = (value, obj1, obj2) => {
    return value + 136 >= obj1.headTop && value + 136 <= obj2.headTop
  }

  const checkRangeLast = (value, obj1) => {
    return value + 136 >= obj1.headTop
  }
  const processArray = (data, value) => {
    for (let i = 0; i < data.length; i++) {
      const currentObj = data[i]
      const nextObj = data[i + 1]
      if (i === data.length - 1) {
        if (checkRangeLast(value, currentObj)) {
          events.emit(ACTIONS.NAV_SCROLL_EDITOR, {
            obj: currentObj,
            data: data,
          })
        }
      } else {
        if (checkRange(value, currentObj, nextObj)) {
          events.emit(ACTIONS.NAV_SCROLL_EDITOR, {
            obj: currentObj,
            data: data,
          })
        }
      }
    }
  }

  useEffect(() => {
    events.on(ACTIONS.COMMENT, commentHandle)
    events.on(ACTIONS.TO_EDITOR_UNDERLINE, commentLineHandle)
    events.on(ACTIONS.CANCEL_COMMENT, cancelCommentHandle)
    events.on(ACTIONS.COMMENT_BUBBLE_DISPATCH, editorCommentHandle)
    return () => {
      events.off(ACTIONS.COMMENT, commentHandle)
      events.off(ACTIONS.TO_EDITOR_UNDERLINE, commentLineHandle)
      events.off(ACTIONS.CANCEL_COMMENT, cancelCommentHandle)
      events.off(ACTIONS.COMMENT_BUBBLE_DISPATCH, editorCommentHandle)
    }
  }, [])

  const editorCommentHandle = () => {
    const editor = editorRef.current
    const { doc, tr } = editor.view.state

    // 遍历文档的根节点并深度递归处理每个节点
    doc.descendants((node, offset, index) => {
      addCommentClassToParagraph(node, tr, editor.view.state.schema, offset)
    })
    editor.view.dispatch(tr)
  }

  // const handleTextChange = () => {
  //   if (isComment || isDisableEdit) return
  //   const editor = editorRef.current
  //   editContent(editor.getHTML({ layerType: 'test' }).replace(/<p><\/p>/g, ''))
  //   editContentHtml(editor.getHTML({ layerType: 'test' }))
  //   getContentDoc(editor.getContent())
  // }
  const handleTextChange = () => {
    if (isComment || isDisableEdit) return
    const editor = editorRef.current
    editContent(
      editor
        .getHTML({ layerType: 'test' })
        .replace(/<p><\/p>/g, '')
        .replace(
          /<span\s+class="ProseMirror-yjs-cursor[^"]*"[^>]*>.*?<\/span>/g,
          '',
        )
        .replace(/<div\s+style="background-color:[^"]*"[^>]*>.*?<\/div>/g, ''),
    )
    editContentHtml(editor.getHTML({ layerType: 'test' }))
    getContentDoc(editor.getContent())
  }

  const addCommentClassToParagraph = (node, tr, schema, offset) => {
    // 如果当前节点是段落节点
    let hasComment = false
    let hasListComment = false
    const editor = editorRef.current
    if (
      node.type === schema.nodes.paragraph ||
      node.type === schema.nodes.header
    ) {
      // 检查段落中的 mark
      const markType = editor.view.state.schema.marks.comment
      node.descendants((nodePara, pos, parent) => {
        // Check if the mark applies to this text node

        const marksToRemove = nodePara.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes('comment') !== -1
          )
        })
        if (marksToRemove) {
          if (schema.nodes.bullet_list === node.type && !nodePara.isText) {
            const newAttrs = { ...node.attrs, class: 'hasComment' }
            tr.setNodeMarkup(pos, null, newAttrs)
            hasComment = true
            return false
          } else {
            const newAttrs = { ...node.attrs, class: 'hasComment' }

            tr.setNodeMarkup(offset, null, newAttrs)
            hasComment = true
            return false
          }
        }
      })
      if (!hasComment) {
        const newAttrs = { ...node.attrs, class: '' }
        tr.setNodeMarkup(offset, null, newAttrs)
      }
    } else if (node.type === schema.nodes.list_item) {
      const markType = editor.view.state.schema.marks.comment
      node.descendants((nodePara, pos, parent) => {
        const marksToRemove = nodePara.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes('comment') !== -1
          )
        })
        if (marksToRemove) {
          const newAttrs = { ...node.attrs, class: 'hasComment' }
          const resolvedPos = editor.view.posAtDOM(parent, offset)

          tr.setNodeMarkup(offset, null, newAttrs)
          hasListComment = true
          return true
        }
      })
      if (!hasListComment) {
        const newAttrs = { ...node.attrs, class: ' ' }
        tr.setNodeMarkup(offset, null, newAttrs)
      }
    }
  }

  const handleSelectChange = () => {
    const editor = editorRef.current
    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = editor.root.firstElementChild.getBoundingClientRect()
    const { $anchor, $head } = editor.view.state.selection
    if ($head.pos > $anchor.pos) {
      events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    }
    // if ($head.pos > $anchor.pos && document.querySelector('.visible')) {
    //   document
    //     .querySelector('.syl-editor')
    //     .lastElementChild.setAttribute(
    //       'style',
    //       `background-image: ${
    //         theme == 'dark'
    //           ? '#fff'
    //           : 'linear-gradient(99deg, #FCE9FF 0%, #F0E8FF 50%, #FFF0E6 100%)'
    //       };position: absolute; padding:4px;border-radius:6px;display:none;`,
    //     )
    // } else {
    //   document
    //     .querySelector('.syl-editor')
    //     .lastElementChild.setAttribute(
    //       'style',
    //       `background-image: ${
    //         theme == 'dark'
    //           ? '#fff'
    //           : 'linear-gradient(99deg, #FCE9FF 0%, #F0E8FF 50%, #FFF0E6 100%)'
    //       };position: absolute; padding:4px;border-radius:6px;display:none;`,
    //     )
    // }
  }

  useEffect(() => {
    const editor = editorRef.current
    editor.on(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
    editor.on(EventChannel.LocalEvent.SELECTION_CHANGED, handleSelectChange)
    return () => {
      editor.off(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
      editor.off(EventChannel.LocalEvent.SELECTION_CHANGED, handleSelectChange)
    }
  }, [])

  useEffect(() => {
    if (isComment) {
      const editor = editorRef.current
      const handleTextChange = () => {
        changeContent(editor.getHTML())
      }
      editor.on(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)

      return () => {
        editor.off(EventChannel.LocalEvent.TEXT_CHANGED, handleTextChange)
      }
    }
  }, [])

  const titleHandle = (text) => {
    editTitle(text)
  }

  const cancelCommentHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.background
    const tr = editor.view.state.tr.removeMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create({ color: 'rgb(255, 229, 212)' }),
    )
    editor.view.dispatch(tr)
  }

  const commentHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.background
    const tr = editor.view.state.tr.addMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create({ color: 'rgb(255, 229, 212)' }),
    )
    editor.view.dispatch(tr)
  }

  const commentLineHandle = (payload) => {
    const { nodeInfo } = payload
    const editor = editorRef.current
    const markType = editor.view.state.schema.marks.underline
    const tr = editor.view.state.tr.addMark(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      markType.create(),
    )
    editor.view.dispatch(tr)
  }

  const generateTableOfContents = (editor) => {
    const headings = []
    const state = editor.view.state
    const root = state.doc
    root.descendants((node, pos) => {
      if (node.type.name === 'header' && node.content.content.length > 0) {
        const headingText = node.content?.content[0].text
        const headingLevel = node.attrs.level
        const { bottom: headTop } = editor.view.coordsAtPos(pos)
        const { top: headBottom } = editor.view.coordsAtPos(
          pos + headingText ? headingText.length : 0,
        )
        headings.push({
          text: headingText,
          level: headingLevel,
          pos,
          headTop: headTop,
          headBottom: headBottom,
        })
      }
    })

    return headings
  }

  return (
    <div
      ref={EditorContainerRef}
      className={`${styles.editorContainer} ${
        !showDocEdit && !isShare && styles.onlyDoc
      } ${isComment && styles['isComment']}`}>
      {!showTitle && (
        <Title
          ref={TitleRef}
          value={docTitle}
          setTitle={titleHandle}
          // setInputTitle={inputTitleHandle}
          onSubmit={onSubmit}
          isComment={isComment}
          isAutoSave={isAutoSave}
        />
      )}

      <div
        ref={divRef}
        style={{
          width: `${showRight ? 'calc(50vw)' : '100%'}`,
          height: `${
            isComment && !isShare
              ? 'calc(100vh - 161px)'
              : isShare
              ? 'calc(100vh - 281px)'
              : 'calc(100vh - 150px)'
          }`,
        }}>
        <SylEditorStyle
          className={`${
            isFullDisplay ? 'full_display_true' : 'full_display_false'
          }`}
          style={{
            height: `${
              isComment && !isShare
                ? 'calc(100vh - 161px)'
                : isShare
                ? 'calc(100vh - 281px)'
                : 'calc(100vh - 150px)'
            }`,
            // paddingTop: '80px',
            // width: `${showRight ? 'calc(50vw)' : 'calc(100vw - 90px)'}`,
            width: `${showRight ? 'calc(50vw)' : '100%'}`,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
          id="sylEditorBox"
          btnWidth={btnWidth}
          btnHeight={btnHeight}
          isShare={isShare}
          showRight={showRight}
          theme={theme}
          isFullDisplay={isFullDisplay}>
          <SylEditor
            placeholder="请输入内容，您的文档将会自动保存。"
            getEditor={(editor) => {
              editorRef.current = editor
            }}
            plugins={plugins} // 底部插件
            module={module} // 悬浮弹窗
            locale={locale}
            disable={isDisableEdit}
          />
          {/* <RichEditor
          extraPlugins={[collabPlugin]}
          placeholder="请输入内容，您的文档将会自动保存。"
          getEditor={(e) => {
            setEditor(e)
            editorRef.current = e
          }}
          locale={locale}
          disable={isDisableEdit}
        /> */}
        </SylEditorStyle>
      </div>
    </div>
  )
})

export default WritingContent
