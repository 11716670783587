import React, { useEffect, useRef, useState } from 'react'
import Style from 'styled-components'
import styles from './style/titleEditNav.module.less'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import {
  IconDoubleArrowRight,
  IconDoubleArrowLeft,
} from '@arco-iconbox/react-aidb-v2'
import { Tooltip } from '@arco-design/web-react'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { useHistory } from 'react-router-dom'
import TocHelper from 'toc-helper'

const DocShareStyle = Style.div<{
  isOpen: boolean
  theme: string
}>`
  .bitoc  {
    height: 70vh;
    border: ${({ theme }) =>
      theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #efefef'};
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
    padding: 16px;
    z-index: 10;
    background: ${({ theme }) => (theme == 'dark' ? ' #19191a' : ' #fff')}};
  }
  .bitoc-nav{
    a{
      color:#9f9f9f;
      font-size: 12px;
      position: relative;
      padding-left: 12px;
      cursor: pointer;
      right:${(isOpen) => (!isOpen ? '-50px' : '0')};
      white-space: nowrap;
      /* max-width: 100px; */
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
      padding: 0.2rem 0 0.2rem 12px;
    }
    .bitoc-ml-1{
      max-width: 160px;
    }
    .bitoc-ml-2{
      max-width: 149px;
      margin-left: 10px !important;
    }
    .bitoc-ml-3{
      max-width: 137px;
      margin-left: 20px !important;
    }
    .bitoc-ml-4{
      max-width: 125px;
      margin-left: 30px !important;
    }
    .bitoc-ml-5{
      max-width: 113px;
      margin-left: 40px !important;
    }
    .bitoc-ml-6{
      max-width: 101px;
      margin-left: 50px !important;
    }
    a:before{
      content: '';
      width: 4px;
      height: 4px;
      background:${(isOpen) => (isOpen ? '#9f9f9f' : 'transparent')};
      border-radius: 50%;
      position: absolute;
      left: ${(isOpen) => isOpen && '0px'};
      top: 50%;
      margin-top: -2px;
    }
    a:hover{
      color: ${(isOpen) => (isOpen ? '#454545' : 'transparent')};
      :before{
        background:${(isOpen) => (isOpen ? '#454545' : 'transparent')};
      }
    }
    
    /* .bitoc-ml-1:before{
      content:'';
      width:${(isOpen) => (isOpen ? '4px' : '24px')} ;
      height:${(isOpen) => (isOpen ? '4px' : '2px')} ;
      border-radius: ${(isOpen) => (isOpen ? '50%' : '2px')} ;
      background-color: ${(theme) => (theme == 'dark' ? '#747171' : '#DDDDDD')};
    }
    .bitoc-ml-2:before{
      content: '';
      width: ${(isOpen) => (isOpen ? '2px' : '12px')};
      height: ${(isOpen) => (isOpen ? '50%' : '2px')} ;
      border-radius:  ${(isOpen) => (isOpen ? '0' : '2px')};
    background-color: ${(theme) => (theme == 'dark' ? '#747171' : '#DDDDDD')};
      position: absolute;
      left: 1%;
      top: 35%;
    }
    .bitoc-ml-3:before{
      content: '';
      width: ${(isOpen) => (isOpen ? '4px' : '6px')};
      height: ${(isOpen) => (isOpen ? '4px' : '2px')} ;
      border-radius: ${(isOpen) => (isOpen ? '0' : '2px')};
       background-color: ${(theme) =>
         theme == 'dark' ? '#747171' : '#DDDDDD'};
    } */
  }
  .bitoc-nav a.active {
    color:${(isOpen) => (isOpen ? '#3e3e3e' : 'transparent')} ;
  }
  .bitoc-nav a.active:before {
    background: #3e3e3e;
  }
`

const DocShareCloseStyle = Style.div<{
  isOpen: boolean
  theme: string
}>`
    .bitoc  {
      height: 70vh;
      width:40px !important;
      // right: -100px;
    }
    .bitoc:hover{
        height: 70vh;
    border: ${({ theme }) =>
      theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #efefef'};
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
        padding: 16px;
        right: 0px;
        z-index: 10;
        background:${({ theme }) => (theme == 'dark' ? '#19191a' : ' #fff')};
        width:100% !important;
        .bitoc-nav{
          a{
            color:#9f9f9f;
            font-size: 12px;
            position: relative;
            padding-left: 12px;
            cursor: pointer;
            // right:${(isOpen) => (!isOpen ? '-50px' : '0')};
            white-space: nowrap;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 24px;
            padding: 0.2rem 0 0.2rem 12px;
          }
          a:before{
            content: '';
            width: 4px;
            height: 4px;
            background:${(isOpen) => (isOpen ? '#9f9f9f' : 'transparent')};
            border-radius: 50%;
            position: absolute;
            left: ${(isOpen) => isOpen && '0px'};
            top: 50%;
            margin-top: -2px;
          }
          a:hover{
            color: ${(isOpen) => (isOpen ? '#3e3e3e' : 'transparent')};
            :before{
              background:${(isOpen) => (isOpen ? '#3e3e3e' : 'transparent')};
            }
          }
          .bitoc-ml-1{
            max-width: 160px;
          }
          .bitoc-ml-2{
            max-width: 149px;
            margin-left: 10px !important;
          }
          .bitoc-ml-3{
            max-width: 137px;
            margin-left: 20px !important;
          }
          .bitoc-ml-4{
            max-width: 125px;
            margin-left: 30px !important;
          }
          .bitoc-ml-5{
            max-width: 113px;
            margin-left: 40px !important;
          }
          .bitoc-ml-6{
            max-width: 101px;
            margin-left: 50px !important;
          }
          /* .bitoc-ml-1:before{
            content:'';
            width:${(isOpen) => (isOpen ? '4px' : '24px')} ;
            height:${(isOpen) => (isOpen ? '4px' : '2px')} ;
            border-radius: ${(isOpen) => (isOpen ? '50%' : '2px')} ;
            background-color: #DDDDDD;
          }
          .bitoc-ml-2:before{
            content: '';
            width: ${(isOpen) => (isOpen ? '2px' : '12px')};
            height: ${(isOpen) => (isOpen ? '50%' : '2px')} ;
            border-radius:  ${(isOpen) => (isOpen ? '0' : '2px')};
            background-color: #DDDDDD;
            position: absolute;
            left: 1%;
            top: 35%;
          }
          .bitoc-ml-3:before{
            content: '';
            width: ${(isOpen) => (isOpen ? '4px' : '6px')};
            height: ${(isOpen) => (isOpen ? '4px' : '2px')} ;
            border-radius: ${(isOpen) => (isOpen ? '0' : '2px')};
            background-color: #DDDDDD;
          } */
        }
      .bitoc-nav a.active {
        color:${(isOpen) => (isOpen ? '#3e3e3e' : 'transparent')} ;
      }
      .bitoc-nav a.active:before {
        background: #3e3e3e;
      }
    }
  .bitoc-nav{
   
    a{
      color:transparent;
      font-size: 12px;
      position: relative;
      padding-left: 12px;
      cursor: pointer;
      // right:${(isOpen) => (isOpen ? '-130px' : '0')};
      word-break: keep-all;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      // padding: 0;
      height: 24px;
      // line-height: initial;
    }
    a:before{
      content: '';
      width: 4px;
      height: 4px;
      background:${(isOpen) => (!isOpen ? '#9f9f9f' : 'transparent')};
      border-radius: 50%;
      position: absolute;
      left: ${(isOpen) => isOpen && '0px'};
      top: 50%;
      margin-top: -2px;
    }
    a:hover{
      color: ${(isOpen) => (!isOpen ? '#3e3e3e' : 'transparent')};
      :before{
        background:${(isOpen) => (isOpen ? '#3e3e3e' : 'transparent')};
      }
    }
    .bitoc-ml-1{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 10px!important')};
    }
    
    .bitoc-ml-2{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 10px!important')};
    }
    .bitoc-ml-3{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 18px!important')};
    }

    .bitoc-ml-4{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 26px!important')};
    }
    
    .bitoc-ml-5{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 34px!important')};
    }
    .bitoc-ml-6{
      margin-left:${(isOpen) => (isOpen ? ' 0 !important' : ' 42px!important')};
    }
    
    .bitoc-ml-1:before{
      content:'';
      width:${(isOpen) => (!isOpen ? '4px' : '32px')} ;
      height:${(isOpen) => (!isOpen ? '4px' : '2px')} ;
      border-radius: ${(isOpen) => (!isOpen ? '50%' : '2px')} ;
      background-color: #DDDDDD;
    }
    .bitoc-ml-2:before{
      content: '';
      width: ${(isOpen) => (!isOpen ? '4px' : '20px')};
      height: ${(isOpen) => (!isOpen ? '4px' : '2px')} ;
      border-radius:  ${(isOpen) => (!isOpen ? '50%' : '2px')};
      background-color: #DDDDDD;
    }
    .bitoc-ml-3:before{
      content: '';
      width: ${(isOpen) => (!isOpen ? '4px' : '10px')};
      height: ${(isOpen) => (!isOpen ? '4px' : '2px')} ;
      border-radius: ${(isOpen) => (!isOpen ? '50%' : '2px')};
      background-color: #DDDDDD;
    }
    .bitoc-ml-4:before{
      content: '';
      width: ${(isOpen) => (!isOpen ? '2px' : '6px')};
      height: ${(isOpen) => (!isOpen ? '2px' : '2px')} ;
      border-radius: ${(isOpen) => (!isOpen ? '50%' : '2px')};
      background-color: #DDDDDD;
    }
    .bitoc-ml-5:before{
      content: '';
      width: ${(isOpen) => (!isOpen ? '2px' : '4px')};
      height: ${(isOpen) => (!isOpen ? '2px' : '2px')} ;
      border-radius: ${(isOpen) => (!isOpen ? '50%' : '2px')};
      background-color: #DDDDDD;
    }
    .bitoc-ml-6:before{
      content: '';
      width: ${(isOpen) => (!isOpen ? '2px' : '2px')};
      height: ${(isOpen) => (!isOpen ? '2px' : '2px')} ;
      border-radius: ${(isOpen) => (!isOpen ? '50%' : '2px')};
      background-color: #DDDDDD;
    }
  }
  .bitoc-nav a.active {
    color:${(isOpen) => (!isOpen ? '#3e3e3e' : 'transparent')} ;
  }
  .bitoc-nav a.active:before {
    background: #3e3e3e;
  }
  
`

const TitleNav = (props) => {
  const {
    docContent,
    defaultContent,
    isFullDisplay,
    isAutoSave,
    isContentHtml,
    theme,
  } = props
  const [curId, setCurId] = React.useState(
    GetQueryValue('rightCurId') ? GetQueryValue('rightCurId') : '',
  )
  const sylEditorBox = document.querySelector('#sylEditorBox')
  const curIdRef = useRef(
    GetQueryValue('rightCurId') ? GetQueryValue('rightCurId') : '',
  )
  const [showNav, setShowNav] = useState<boolean>(false)
  const tocRef = useRef(null)
  //折叠
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('editIsOpen') === 'true',
  )
  useEffect(() => {
    events.on(ACTIONS.NAV_SCROLL_EDITOR, navScrollHandle)
    return () => {
      events.off(ACTIONS.NAV_SCROLL_EDITOR, navScrollHandle)
    }
  }, [])

  useEffect(() => {
    const tocContainers = document.querySelector('.bitoc')
    const contentSelector = document.querySelector('.ProseMirror')
    if (tocContainers && !isAutoSave) {
      tocContainers.remove()
    }
    if (contentSelector && docContent && !isAutoSave) {
      tocRef.current = new TocHelper('#toc-edit', {
        // contentSelector: `#sylEditorBox`,
        scrollSelector: '#sylEditorBox',
        contentSelector: `.ProseMirror`,
        // scrollSelector: '.ProseMirror',
        collapsedLevel: 6,
        fixedOffset: 320 - 84,
        fixedSelector: '#toc-wrap',
        scrollOffset: 105,
        idPrefix: `toc-heading-`,
        afterFixed: fixHandle,
        beforeFixed: function (isFixed) {
          console.log(isFixed)
        },
      })
      var elements1 = document.getElementsByClassName('bitoc-ml-1')
      var elements2 = document.getElementsByClassName('bitoc-ml-2')
      var elements3 = document.getElementsByClassName('bitoc-ml-3')
      var elements = Array.prototype.slice
        .call(elements1)
        .concat(Array.prototype.slice.call(elements2))
        .concat(Array.prototype.slice.call(elements3))
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].innerHTML === '') {
          elements[i].setAttribute('style', 'display:none')
        }
      }
      // 修复H标题错位
      changeTitle()
      // var elements1 = document.getElementsByClassName('bitoc-ml-2') // 获取具有bitoc-ml-2类名的所有元素
      // var elements2 = document.getElementsByClassName('bitoc-ml-3') // 获取具有bitoc-ml-3类名的所有元素

      // var elements = Array.prototype.slice
      //   .call(elements1)
      //   .concat(Array.prototype.slice.call(elements2))
      // for (var i = 0; i < elements.length; i++) {
      //   if (elements[i].innerHTML === '') {
      //     // 检查元素内容是否为空
      //     elements[i].setAttribute('style', 'display:none')
      //   }
      // }
    }
  }, [isAutoSave, isOpen, isFullDisplay])

  // 修复H标题错位
  const changeTitle = () => {
    // 获取编辑器中的所有直接子节点H标签
    // const editorHeadings = document.querySelector('.ProseMirror').children
    let parentElement = document.querySelector('.ProseMirror')
    let editorHeadings = parentElement.querySelectorAll(
      'h1, h2, h3, h4, h5, h6',
    )

    let editorHeadingLevels = []
    for (let i = 0; i < editorHeadings.length; i++) {
      let tagName = editorHeadings[i].tagName
      if (tagName && tagName.startsWith('H')) {
        editorHeadingLevels.push(parseInt(tagName.substr(1)))
      }
    }
    if (!editorHeadingLevels.length) return

    // 获取目录中的所有目录标签
    let directoryItems = Array.from(
      document.querySelectorAll('.bitoc-nav [class^="bitoc-ml-"]'),
    ).filter((item) => window.getComputedStyle(item).display !== 'none')
    if (!directoryItems.length) return

    let directoryItemClasses = []
    for (let i = 0; i < directoryItems.length; i++) {
      let item = directoryItems[i]
      // 检查元素是否隐藏
      let style = window.getComputedStyle(item)
      if (style.display !== 'none') {
        directoryItemClasses.push(item.className)
      }
    }
    if (!directoryItemClasses.length) return

    // 将目录中的目录标签按照编辑器中的H标签进行修改
    for (let i = 0; i < editorHeadingLevels.length; i++) {
      if (
        directoryItemClasses[i] &&
        directoryItemClasses[i].startsWith('bitoc-ml-')
      ) {
        // 使用正则表达式替换目录标签的级别
        let prefix = 'bitoc-ml-' //要匹配的前缀

        if (
          directoryItemClasses[i] &&
          directoryItemClasses[i].startsWith(prefix)
        ) {
          let numPart = directoryItemClasses[i].slice(prefix.length) //获取前缀后面的部分（在这个例子中应该是数字）

          if (!isNaN(Number(numPart))) {
            //将字符串转为数字并检查是否为数字
            directoryItemClasses[i] = prefix + editorHeadingLevels[i] //如果是数字，则进行替换
          } else if (!isNaN(Number(numPart.slice(' ')[0]))) {
            directoryItemClasses[i] =
              prefix + editorHeadingLevels[i] + ' active'
          }
        }
      }
    }

    // 应用新的类名到目录项目
    for (let i = 0; i < directoryItems.length; i++) {
      directoryItems[i].className = directoryItemClasses[i]
    }
  }

  const navScrollHandle = (payload) => {
    const navList = payload.data
    const targetNav = payload.obj
    const targetIndex = navList.findIndex(
      (obj) =>
        obj.headTop === targetNav.headTop &&
        obj.headBottom === targetNav.headBottom,
    )
    curIdRef.current = targetIndex
    setCurId(targetIndex)
  }

  const handleIsOpen = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    localStorage.setItem('editIsOpen', JSON.stringify(newIsOpen))
  }

  const fixHandle = () => {
    console.log('oooo')
  }

  return (
    <>
      <div
        className={` ${styles.open} ${styles.title_nav} ${
          props.showRight ? styles.small : ''
        }  ${props.position === 'right' ? styles.right : ''} ${styles[theme]}`}>
        <div className={styles.title_line_box}></div>
        <div className={styles.title_tree_wrap}>
          <Tooltip
            className={'custom-tooltip'}
            position="bottom"
            trigger="hover"
            content={isOpen ? '收起索引' : '展开索引'}>
            <div className={styles.title_tree_arrow} onClick={handleIsOpen}>
              {isOpen && <IconDoubleArrowLeft />}
              {!isOpen && <IconDoubleArrowRight />}
            </div>
          </Tooltip>
          {Boolean(isOpen) ? (
            <DocShareStyle theme={theme} isOpen={Boolean(isOpen)}>
              <div id="toc-container" className={styles.tocContainer}>
                <div id="toc-wrap">
                  <div id="toc-edit"></div>
                </div>
              </div>
            </DocShareStyle>
          ) : (
            <DocShareCloseStyle theme={theme} isOpen={Boolean(isOpen)}>
              <div id="toc-container" className={styles.tocContainer}>
                <div id="toc-wrap">
                  <div id="toc-edit"></div>
                </div>
              </div>
            </DocShareCloseStyle>
          )}
        </div>
      </div>
    </>
  )
}

export default TitleNav
