import { getFoldList } from '@/model/reference'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { Breadcrumb } from '@arco-design/web-react'
import { IconArrowLeft } from '@arco-iconbox/react-aidb-v2'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

function BreadCollectItem(props) {
  const BreadcrumbItem = Breadcrumb.Item
  const [breadList, setBreadList] = useState([])
  const history = useHistory()

  useEffect(() => {
    getFoldListHandle()
  }, [GetQueryValue('collectFoldUuid')])

  const goBreadFoldHandle = (foldUuid, uuid, title) => {
    const urlQuery = removeEmpty({
      uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
      filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
      folderUuid: GetQueryValue('folderUuid')
        ? GetQueryValue('folderUuid')
        : '',
      collectFoldUuid: uuid ? uuid : '',
    }) as any
    history.push({
      pathname: '/documentEdit',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
  }
  const getFoldListHandle = async () => {
    const res = await getFoldList()
    const breadList = findTitleUuidHierarchy(
      res.data.data,
      GetQueryValue('collectFoldUuid'),
    )
    if (props.outCollectList) {
      let targetIndex = breadList.findIndex(
        (item) => item.uuid === GetQueryValue('collectFoldUuid'),
      )
      // 过滤掉目标UUID前面的对象
      let filteredData = breadList.slice(targetIndex)
      setBreadList(filteredData)
    } else {
      setBreadList(breadList)
    }
  }

  function findTitleUuidHierarchy(data, targetUuid) {
    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i]
      if (currentItem.uuid == targetUuid) {
        const parentHierarchy = currentItem.parentId
          ? findTitleUuidHierarchy(
              data,
              data.find((item) => item.id === currentItem.parentId),
            )
          : []
        return [
          { title: currentItem.title, uuid: currentItem.uuid },
          ...parentHierarchy,
        ]
      } else if (currentItem.children) {
        const childHierarchy = findTitleUuidHierarchy(
          currentItem.children,
          targetUuid,
        )
        if (childHierarchy.length) {
          return [
            { title: currentItem.title, uuid: currentItem.uuid },
            ...childHierarchy,
          ]
        }
      }
    }
    return []
  }
  const goHomeHandle = () => {
    const urlQuery = removeEmpty({
      uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
      filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
      folderUuid: GetQueryValue('folderUuid')
        ? GetQueryValue('folderUuid')
        : '',
      from: GetQueryValue('from') ? GetQueryValue('from') : '',
    }) as any

    history.push({
      pathname: '/documentEdit',
      search: '?' + new URLSearchParams(urlQuery).toString(),
    })
    // location.reload()
  }
  return (
    <div>
      <Breadcrumb style={{ fontSize: 14 }} separator={<span>{'>'}</span>}>
        <BreadcrumbItem
          style={{ cursor: 'pointer', color: '#999' }}
          onClick={goHomeHandle}>
          {breadList.length != 0 ? (
            '文档控制台'
          ) : (
            <>
              <IconArrowLeft /> 返回
            </>
          )}
        </BreadcrumbItem>

        {breadList.length != 0 &&
          breadList.map((i, index) => {
            return (
              <BreadcrumbItem
                style={{ cursor: 'pointer' }}
                key={i + index}
                onClick={() =>
                  goBreadFoldHandle(
                    index >= 1 && breadList[index - 1].uuid
                      ? breadList[index - 1].uuid
                      : '',
                    i.uuid ? i.uuid : '',
                    i.title,
                  )
                }>
                {i.title.length > 10 ? i.title.slice(0, 12) + '...' : i.title}
              </BreadcrumbItem>
            )
          })}
      </Breadcrumb>
    </div>
  )
}

export default BreadCollectItem
