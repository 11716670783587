import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import Markdown from '@/components/Markdown'
import { Message, Tree } from '@arco-design/web-react'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import TreeNodeTitle from './dropListMenu'
import {
  DocMove,
  DocMoveSort,
  TeamDocMove,
  DocumentTreeList,
  GetTeamTreeList,
  getDocumentTagList,
} from '@/model/reference'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { throttle } from 'lodash'
import { useHistory } from 'react-router-dom'

import styles from './style/index.module.less'

const FolderTree = (props) => {
  const { onClickMask, updateTreeList, getDocDetailHandle } = props

  // 从 Redux store 中获取主题信息
  const theme = useSelector((state: GlobalState) => state.theme)

  // 更新树组件数据
  const [updateTreeData, setUpdateTreeData] = useState(Date.now())

  // 组件内部状态，用于保存树菜单数据
  const [treeData, setTreeData] = useState<any>([])

  // 复选框
  const [checkedKeys, setCheckedKeys] = useState([])
  // 子节点
  const [expandedKeys, setExpandedKeys] = useState([])
  // 内容数据
  const [docData, setDocData] = useState<any>('')
  // 文档标签
  const [tagsList, setTagsList] = useState<any>('')
  // loading状态
  const [loading, setLoading] = useState(false)
  const [starDrag, setStarDrag] = useState(false)

  const history = useHistory()

  const editorTreeDirectoryRef = useRef(null)

  // 当传入的树菜单数据发生变化时，更新组件内部状态
  useEffect(() => {
    if (localStorage.getItem('docTypeKey') == '共享文档') {
      // 请求共享文档
      getGetTeamTreeList()
    } else {
      // 请求普通文档
      getDocumentTreeList()
    }
    // handleEditBack()
  }, [GetQueryValue('uuid'), updateTreeData, updateTreeList])

  // 给编辑器树目录添加之前的高度
  useEffect(() => {
    const applyScrollTop = () => {
      if (editorTreeDirectoryRef.current)
        editorTreeDirectoryRef.current.scrollTop = localStorage.getItem(
          'editorTreeScrollTop',
        )
    }

    // 使用MutationObserver监听节点变化
    const observer = new MutationObserver(() => {
      applyScrollTop()
    })

    if (editorTreeDirectoryRef.current) {
      observer.observe(editorTreeDirectoryRef.current, {
        childList: true,
        subtree: true,
      })
      // 初始应用滚动高度
      applyScrollTop()
    }

    // 清理observer
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    // 初始化给编辑器树目录添加之前的高度
    const handleScroll = () => {
      if (editorTreeDirectoryRef.current) {
        const currentScrollTop = editorTreeDirectoryRef.current.scrollTop
        saveScrollTopToLocalStorage(currentScrollTop) // 将当前滚动高度存储到localStorage
      }
    }

    // 请求标签数据
    getDocumentTagList().then((res) => {
      setTagsList(res.data.data)
    })

    // 添加滚动事件监听器
    editorTreeDirectoryRef.current.addEventListener('scroll', handleScroll)

    // 在组件卸载时移除事件监听器
    return () => {
      if (editorTreeDirectoryRef.current) {
        editorTreeDirectoryRef.current.removeEventListener(
          'scroll',
          handleScroll,
        )
      }
    }
  }, [])

  // 初始化时，默认展开当前文档所在的文件夹
  useEffect(() => {
    if (!treeData.length) return

    handleEditBack(treeData)

    setTimeout(() => {
      setExpandedKeys((keys) => {
        const expanded = expandCurDocForKey(treeData, GetQueryValue('uuid'))
        const newKeys = expanded?.keys || [] // 如果 expanded 或 expanded.keys 是 undefined，就使用空数组
        return [...keys, ...newKeys]
      })
    }, 100)
  }, [treeData])

  // 请求共享文档
  const getGetTeamTreeList = () => {
    GetTeamTreeList().then((res) =>
      setTreeData(convertDataForTree(res.data.data)),
    )
  }

  // 请求普通文档数据
  const getDocumentTreeList = () => {
    DocumentTreeList({ uuid: '', uuids: [] }).then((res) =>
      setTreeData(convertDataForTree(res.data.data)),
    )
  }

  // 请求编辑器数据以及树目录数据
  const updateEitorAndTreeData = () => {
    getDocDetailHandle()
    setUpdateTreeData(Date.now())
  }

  // 保存滚动高度到localStorage
  const saveScrollTopToLocalStorage = (scrollTop) => {
    localStorage.setItem('editorTreeScrollTop', scrollTop)
  }

  // 编辑器返回刷新页面逻辑处理/展开菜单，默认选中
  function handleEditBack(treeData) {
    setTimeout(() => {
      handleExpand(JSON.parse(localStorage.getItem('treeExpandedKeys')) || [])
      if (treeData?.length) {
        if (GetQueryValue('treeUuid')?.length > 0) {
          updateTreeNode(treeData, GetQueryValue('treeUuid'), (parentNode) => {
            const firstNode = parentNode
            // 调用递归函数，给每个节点添加 parentKey
            const updatedFirstNode = addParentKeyToNode(firstNode, null)
            // 设置 docData
            setDocData(updatedFirstNode)
            console.log(updatedFirstNode)

            // setSelectedKeys([GetQueryValue('treeUuid')])
            setExpandedKeys((prevExpandedKeys) => {
              // 使用函数形式的 setState，确保获取到最新的状态
              const newExpandedKeys = !prevExpandedKeys.includes(
                GetQueryValue('uuid'),
              )
                ? [
                    ...prevExpandedKeys,
                    GetQueryValue('uuid'),
                    GetQueryValue('uuid'),
                  ]
                : [...prevExpandedKeys, GetQueryValue('uuid')]

              localStorage.setItem(
                'treeExpandedKeys',
                JSON.stringify(newExpandedKeys),
              )
              return newExpandedKeys
            })
          })
        } else {
          // const firstNode = treeData[0]
          // // 调用递归函数，给每个节点添加 parentKey
          // const updatedFirstNode = addParentKeyToNode(firstNode, null)
          // console.log(updatedFirstNode)

          treeData.forEach((item) => addParentKeyToNode(item, null))

          // 设置 docData
          // setDocData(updatedFirstNode)
          // setSelectedKeys([treeData[0]?.uuid])
        }
      }
    }, 100)
  }
  // 递归函数，给每个节点添加 parentKey
  const addParentKeyToNode = (node, parentKey) => {
    if (node.children && node.children.length > 0) {
      // 如果有子节点，递归处理每个子节点
      node.children = node.children.map((child) =>
        addParentKeyToNode(child, node.key),
      )
    }

    // 添加 parentKey 到当前节点
    return { ...node, parentKey }
  }

  // 处理树节点拖拽的逻辑
  const handleDrop = async ({ dragNode, dropNode, dropPosition }) => {
    // 递归遍历树数据找到指定键的节点
    const loop = (data, key, callback) => {
      data.some((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr)
          return true
        }

        if (item.children) {
          return loop(item.children, key, callback)
        }
      })
    }

    console.log(dropNode)

    // 创建树数据的副本
    const data = [...treeData]
    let dragItem

    // 获取拖动的节点类型
    const dragNodeType = dragNode.props.fileType

    // 获取放置位置的节点类型
    const dropNodeType = dropNode.props.fileType

    // dropPosition = 0 表示将拖动的节点放在目标节点的子节点列表中，作为子节点。
    // dropPosition = -1 表示将拖动的节点放在目标节点之前。
    // dropPosition = 1 表示将拖动的节点放在目标节点之后。
    try {
      if (dropPosition == 0) {
        if (dragNode.props.fileType == 0 && dropNode.props.fileType == 0) {
          return false
        }
        setLoading(true)
        const moveFile =
          localStorage.getItem('docTypeKey') == '共享文档'
            ? TeamDocMove
            : DocMove
        // 发起异步请求
        const res = await moveFile({
          uuids: [dragNode.props.uuid],
          folderUuid: dropNode.props.uuid,
        })
        if (res.data.success) {
          // // 请求成功，显示成功消息
          // Message.success({
          //   icon: <IconSuccessTip useCurrentColor={false} />,
          //   content: '移动成功',
          // })
          setLoading(false)
          // 从原始位置删除拖动的节点
          loop(data, dragNode.props._key, (item, index, arr) => {
            arr.splice(index, 1)
            dragItem = item
            // 添加拖拽样式的 CSS 类
            dragItem.className = 'tree-node-dropover'
          })

          // 根据放置位置将拖动的节点插入到新位置
          if (dropPosition === 0) {
            loop(data, dropNode.props._key, (item, index, arr) => {
              item.children = item.children || []
              item.children.push(dragItem)
              DocMoveSort({
                moveSortList: item.children.map((item, index) => ({
                  title: item.title,
                  uuid: item.uuid,
                  weight: item.children.length - index, // Adding 1 because index starts from 0
                })),
              }).then((res) => {
                if (res.data.success) {
                  // 请求成功，显示成功消息
                  Message.success({
                    icon: <IconSuccessTip useCurrentColor={false} />,
                    content: '移动成功',
                  })
                  setLoading(false)
                }
              })
            })
          } else {
            loop(data, dropNode.props._key, (item, index, arr) => {
              arr.splice(dropPosition < 0 ? index : index + 1, 0, dragItem)
            })
          }

          // 更新组件内部状态
          setTreeData([...data])

          // 使用 setTimeout 清除拖拽样式
          setTimeout(() => {
            if (dragItem) dragItem.className = ''
            setTreeData([...data])
            setUpdateTreeData(Date.now())
          }, 1000)
        } else {
          // 请求失败，显示错误消息
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '移动失败，请重试',
          })
          setLoading(false)
        }
      } else if (dropPosition != 0) {
        setLoading(true)
        const moveFile =
          localStorage.getItem('docTypeKey') == '共享文档'
            ? TeamDocMove
            : DocMove
        // 发起异步请求
        const res = await moveFile({
          uuids: [dragNode.props.uuid],
          folderUuid:
            dropNode.props.dataRef.parentKey == undefined
              ? ''
              : dropNode.props.dataRef.parentKey,
        })

        if (res.data.success) {
          setTimeout(() => {
            if (dropNode.props.dataRef.parentKey == undefined) {
              DocMoveSort({
                moveSortList: data.map((item, index) => ({
                  title: item.title,
                  uuid: item.uuid,
                  weight: data.length - index, // Adding 1 because index starts from 0
                })),
              }).then((res) => {
                if (res.data.success) {
                  // 请求成功，显示成功消息
                  Message.success({
                    icon: <IconSuccessTip useCurrentColor={false} />,
                    content: '移动成功',
                  })
                  setLoading(false)
                }
              })
            } else {
              setLoading(true)
              const parentKey = dropNode.props.dataRef.parentKey
              updateTreeNode(data, parentKey, (parentNode) => {
                // 在这里执行更新逻辑，例如修改 parentNode 中的某些属性
                console.log(parentNode.children)
                DocMoveSort({
                  moveSortList: parentNode.children.map((item, index) => ({
                    title: item.title,
                    uuid: item.uuid,
                    weight: parentNode.children.length - index, // Adding 1 because index starts from 0
                  })),
                })
                  .then((res) => {
                    if (res.data.success) {
                      // 请求成功，显示成功消息
                      Message.success({
                        icon: <IconSuccessTip useCurrentColor={false} />,
                        content: '移动成功',
                      })
                      setLoading(false)
                    }
                  })
                  .catch(() => {
                    setLoading(false)
                  })
              })
            }
          }, 10)

          // 从原始位置删除拖动的节点
          loop(data, dragNode.props.uuid, (item, index, arr) => {
            arr.splice(index, 1)
            dragItem = item
            // 添加拖拽样式的 CSS 类
            dragItem.className = 'tree-node-dropover'
          })

          // 根据放置位置将拖动的节点插入到新位置
          if (dropPosition === 0) {
            loop(data, dropNode.props.uuid, (item, index, arr) => {
              item.children = item.children || []
              item.children.push(dragItem)
            })
          } else {
            loop(data, dropNode.props.uuid, (item, index, arr) => {
              arr.splice(dropPosition < 0 ? index : index + 1, 0, dragItem)
            })
          }

          // 更新组件内部状态
          setTreeData([...data])

          // 使用 setTimeout 清除拖拽样式
          setTimeout(() => {
            if (dragItem) dragItem.className = ''
            setTreeData([...data])
            setUpdateTreeData(Date.now())
          }, 1000)
        } else {
          // 请求失败，显示错误消息
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '移动失败，请重试',
          })
          setLoading(false)
        }
      }
    } catch (error) {
      // 请求异常，显示错误消息
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '移动失败，发生异常',
      })
      setLoading(false)
      console.log(error.response.data.message)
    }
  }

  //查找父节点
  const updateTreeNode = (tree, targetKey, updateCallback) => {
    for (const node of tree) {
      if (node.key === targetKey) {
        // 找到目标节点，调用更新回调函数
        updateCallback(node)
        return true
      }

      if (node.children) {
        // 如果有子节点，递归查找
        if (updateTreeNode(node.children, targetKey, updateCallback)) {
          return true
        }
      }
    }

    return false // 未找到目标节点
  }

  /**
   * 处理树节点点击事件
   * 创建截流函数
   * */
  const throttledHandleSelect = useCallback(
    throttle(
      (selectedKeys, info) => {
        console.log('Selected Node UUID:', selectedKeys, info.node.props)
        // if (info.node.props.expanded) return

        let propsInfo = info.node.props
        setDocData(info.node.props)

        // 文件夹点击退出
        if (propsInfo.fileType === 1) return

        if (localStorage.getItem('docTypeKey') == '共享文档') {
          if (propsInfo.parentKey == undefined) {
            window.location.href = `/documentEdit?uuid=${propsInfo.uuid}&teamUuid=${propsInfo.teamUuid}&from=intelligentDocument`
          } else {
            getPositionKeyByUuid(treeData, propsInfo.uuid, (parentNode) => {
              if (parentNode.uuid === GetQueryValue('uuid')) return
              window.location.href = `/documentEdit?filePath=${parentNode.title}&uuid=${propsInfo.uuid}&teamUuid=${propsInfo.teamUuid}&folderUuid=${propsInfo.parentKey}&from=intelligentDocument`
            })
          }
        } else {
          if (propsInfo.parentKey == undefined) {
            window.location.href = `/documentEdit?uuid=${propsInfo.uuid}&from=intelligentDocument`
          } else {
            getPositionKeyByUuid(treeData, propsInfo.uuid, (parentNode) => {
              if (parentNode.uuid === GetQueryValue('uuid')) return
              window.location.href = `/documentEdit?filePath=${parentNode.title}&uuid=${propsInfo.uuid}&folderUuid=${propsInfo.parentKey}&from=intelligentDocument`
            })
          }
        }
      },
      500,
      { leading: true, trailing: false },
    ), // 1秒截流，立即触发，不触发结尾调用
    [treeData],
  )

  // 包装事件处理函数以使用截流函数
  const handleSelect = (selectedKeys, info) => {
    throttledHandleSelect(selectedKeys, info)
  }

  /**
   * 处理树节点展开/折叠的逻辑
   * expandedKeys：展开节点数组
   * margeKey：如果有key，则将expandedKeys和key合并
   */
  const handleExpand = (expandedKeys, margeKey?: any) => {
    if (margeKey?.length) {
      setExpandedKeys([...expandedKeys, ...margeKey])
      localStorage.setItem(
        'treeExpandedKeys',
        JSON.stringify([...expandedKeys, ...margeKey]),
      )
    } else {
      setExpandedKeys(expandedKeys)
      localStorage.setItem('treeExpandedKeys', JSON.stringify(expandedKeys))
    }
  }

  // 侧边子组件点击事件
  function onDoubleChange(item) {
    if (item.fileType === 1) {
      setExpandedKeys((prevExpandedKeys) => {
        const newExpandedKeys = prevExpandedKeys.includes(item.uuid)
          ? prevExpandedKeys.filter((key) => key !== item.uuid)
          : [...prevExpandedKeys, item.uuid]
        // 确保父节点始终保持展开状态
        if (item.parentKey && !newExpandedKeys.includes(item.parentKey)) {
          newExpandedKeys.push(item.parentKey)
        }
        localStorage.setItem(
          'treeExpandedKeys',
          JSON.stringify(newExpandedKeys),
        )
        return newExpandedKeys
      })
    } else {
      if (localStorage.getItem('docTypeKey') == '共享文档') {
        window.location.href = `/documentEdit?uuid=${item.uuid}&teamUuid=${item.teamUuid}&folderUuid=${item.parentKey}&from=intelligentDocument`
      } else {
        window.location.href = `/documentEdit?uuid=${item.uuid}&folderUuid=${item.parentKey}&from=intelligentDocument`
      }
    }
  }

  //渲染标题
  const renderTreeNodeTitle = (nodeData, key) => {
    return (
      <TreeNodeTitle
        treeData={treeData}
        setUpdateTreeData={setUpdateTreeData}
        updateEitorAndTreeData={updateEitorAndTreeData}
        onDoubleChange={onDoubleChange}
        starDrag={starDrag}
        tagsList={tagsList}
        nodeData={nodeData}
        key={key}
      />
    )
  }

  const getAllKeys = (data) => {
    // 递归获取所有节点的 key
    let allKeys = []
    data.forEach((node) => {
      allKeys.push(node.key)
      if (node.children) {
        allKeys = allKeys.concat(getAllKeys(node.children))
      }
    })
    return allKeys
  }

  // 将数据转换为Arco Design Tree组件所需的格式
  const convertDataForTree = (data, parentPositionKey = '') => {
    return data.map((item, index) => {
      const positionKey = parentPositionKey
        ? `${parentPositionKey}-${index}`
        : `${index}`

      return {
        positionKey,
        title: item.title,
        key: item.uuid, // 如果需要给节点设置唯一标识，可以使用id
        uuid: item.uuid, // 如果需要给节点设置唯一标识，可以使用id
        children: item.children
          ? convertDataForTree(item.children, positionKey)
          : [],
        fileType: item.fileType,
        content: item.content,
        gmtCreate: item.gmtCreate,
        introduction: item.introduction,
        fileCoverIcon: item.fileCoverIcon,
        tag: item.tag,
        fileCount: item.fileCount,
        folderCount: item.folderCount,
        isCollect: item.isCollect,
        weight: item.weight,
        teamUuid: item.teamUuid,
        permission: item.permission ? item.permission : 1,
        // icon:
        //   item.fileType === 0 ? (
        //     <IconTreeFile />
        //   ) : (
        //     <img
        //       style={{ width: '16px', height: '20px' }}
        //       src={`https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/12/dfz-folder-${
        //         item.fileCoverIcon || 'yellow'
        //       }.svg`}
        //       alt=""
        //     />
        //   ),
      }
    })
  }

  // 根据uuid获取对应的positionKey
  const getPositionKeyByUuid = (data, uuid, callback?: (data) => void) => {
    for (let item of data) {
      if (item.key === uuid) return item // 找到匹配项，直接返回该项

      if (item.children?.length) {
        const found = getPositionKeyByUuid(item.children, uuid, callback)
        if (found) {
          callback && callback(found)
          return found
        }
      }
    }
    return undefined // 如果没有找到，返回 undefined
  }

  // 获取展开当前文档所要的Key
  const expandCurDocForKey = (data, uuid, callback?) => {
    const findItem = (data, uuid, parentKeys) => {
      for (let item of data) {
        const currentKeys = [...parentKeys, item.positionKey]
        if (item.key === uuid) {
          callback && callback(item)
          return { item, keys: currentKeys } // 返回匹配项和其父级的 positionKey 数组
        }
        if (item.children?.length) {
          const found = findItem(item.children, uuid, currentKeys)
          if (found) {
            return found
          }
        }
      }
      return undefined // 如果没有找到，返回 undefined
    }

    return findItem(data, uuid, [])
  }

  // 将当前高亮的key转为默认打开的key
  // const nodeSegmentation = (str) => {
  //   let allKeys = []

  //   if (!str) return allKeys

  //   let parts = str.split('-')
  //   let currentKey = ''

  //   parts.forEach((item, index) => {
  //     if (index === 0) {
  //       // 第一个元素单独处理
  //       currentKey = item
  //     } else {
  //       // 后续元素在前面基础上加 '-'
  //       currentKey += '-' + item
  //     }
  //     allKeys.push(currentKey)
  //   })

  //   return allKeys
  // }

  return (
    <div
      ref={editorTreeDirectoryRef}
      className={`${styles['treeSide']} ${styles[loading ? 'disnone' : '']}`}>
      <Tree
        treeData={treeData && treeData} //数据
        draggable //拖拽
        autoExpandParent={true}
        selectedKeys={[
          getPositionKeyByUuid(treeData, GetQueryValue('uuid'))?.positionKey,
        ]} // 选中的树节点
        // checkable={localStorage.getItem('docTypeKey') !== '共享文档'} //选中
        // checkedKeys={checkedKeys}
        // onCheck={(keys, extra) => {
        //   console.log(keys, extra)
        //   setCheckedKeys(keys)
        //   onClickMask && onClickMask(keys)
        // }}
        onDrop={handleDrop} // 拖拽事件
        onSelect={handleSelect} // 点击事件
        expandedKeys={expandedKeys} // 展开节点
        onExpand={handleExpand} // 展开节点事件
        fieldNames={{
          key: 'positionKey',
        }} // 指定对应的字段
        renderTitle={(nodeData) => renderTreeNodeTitle(nodeData, nodeData._key)}
        onDragStart={() => {
          setStarDrag(true)
        }} // 拖拽开始
        onDragEnd={() => {
          setStarDrag(false)
        }} // 拖拽结束
      />
    </div>
  )
}

export default FolderTree
