import React, { useEffect, useRef, useState } from 'react'
import styles from './styles/pick-item.module.less'
import { Input, Message, Radio } from '@arco-design/web-react'
import IconBtn from '../../components/IconBtn'
import {
  IconEdit,
  IconCopy,
  IconCheck,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '../../components/BorderBtn'
import copy from 'copy-to-clipboard'
import IconAdd from '@/assets/imgs/i-add.svg'
import { saveDocument } from '@/model/reference'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
const TextArea = Input.TextArea

function OutlinePickItem(props) {
  const { content, showBotButtons, theme } = props

  const [inputContent, setInputContent] = useState(content)

  // 使用 useEffect 实现在 content 发生变化时更新 inputContent 的值
  React.useEffect(() => {
    setInputContent(content)
  }, [content])

  function inputChange(e) {
    setInputContent(e)
  }

  function copyContent(inputContent) {
    copy(inputContent)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  return (
    <div className={`${styles.item} ${styles[theme]}`}>
      <Radio value={props.content}>
        {({ checked }) => {
          return (
            <div
              className={`${styles.outlineText} ${styles.inner1} ${
                props.content == props.checked ? styles.checked : ''
              }`}>
              {checked}
              {console.log(checked)}
              <TextArea
                onFocus={() => {
                  localStorage.setItem('selectOutLine', inputContent)
                }}
                onBlur={() => {
                  localStorage.setItem('selectOutLine', inputContent)
                }}
                autoSize
                className={'g-input'}
                value={inputContent}
                onChange={(e) => {
                  inputChange(e)
                }}></TextArea>
            </div>
          )
        }}
      </Radio>
      <div className={styles.iconCopy}>
        {' '}
        <IconBtn
          text={'复制'}
          iconLeft={<IconCopy />}
          onClick={() => copyContent(props.content)}
        />
      </div>
    </div>
  )
}

export default OutlinePickItem
