import React, { useEffect } from 'react'
import styles from './style/layout.module.less'
import DismantleItem from './DismantleItem'

function TextListMod(props) {
  //生成图片
  const [list, setList] = React.useState(props.list)

  useEffect(() => {
    setList(props.list)
  }, [props.list])
  return (
    <>
      {/* 骨架屏 */}
      {props.skeleton && (
        <div className={styles.skeleton}>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
        </div>
      )}
      {props.skeleton && (
        <div className={styles.skeleton}>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
          <div className={styles.skeletonTxt}></div>
        </div>
      )}
      {list.map((item, index) => {
        return (
          <div className={styles.list_item} key={index}>
            <DismantleItem desc={item.enDesc} cndesc={item.cnDesc} />
          </div>
        )
      })}
    </>
  )
}

export default TextListMod
