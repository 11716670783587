import React from 'react'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import { SylApi, Inline, SylController, SylPlugin } from '@syllepsis/adapter'
import { Schema } from 'prosemirror-model'
interface DirectivesAttrs {
  class: string
}

const PLUGIN_NAME = 'Directives'

class DirectivesSchema extends Inline<DirectivesAttrs> {
  public name = PLUGIN_NAME
}

class DirectivesController extends SylController {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.DIRECTIVES_ADOPT_EDITOR, this.adoptHandle)
  }

  editorWillUnmount(): void {
    events.off(ACTIONS.DIRECTIVES_ADOPT_EDITOR, this.adoptHandle)
  }

  public adoptHandle = (payload) => {
    const { content, startPos } = payload
    const oldTextNode = this.editor.view.state.doc.nodeAt(startPos)
    this.editor.pasteContent(content, { plainText: true, scrollIntoView: true })
    // if (oldTextNode) {
    //   this.editor.delete(startPos, 1)
    // } else {
    //   Message.error({
    //     icon: <IconErrorTip useCurrentColor={false} />,
    //     content: '内容位置已改变，请重试',
    //   })
    // }
  }
}

class DirectivesPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = DirectivesController
  public Schema = DirectivesSchema
}

export { DirectivesPlugin }
