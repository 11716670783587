import React, { useEffect, useRef } from 'react'
import styles from './style/right-form-mod.module.less'
import Markdown from '@/components/Markdown'
import { IconRefresh } from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '@/components/BorderBtn'
import { nowDate } from '@/utils/common'
import { IconSync } from '@arco-design/web-react/icon'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import AgentImg from '@/assets/newImgs/aiagent.svg'
import UserAvatar from '@/assets/UserAvatar'

function Answer(props) {
  const {
    initialValues,
    avatar,
    messageContent,
    handleType,
    theme,
    currentMessage,
  } = props
  console.log(messageContent)

  return (
    <div>
      <div
        className={styles['refresh']}
        onClick={() => {
          handleType('inner')
        }}>
        <div className={styles['edit']}>
          <IconSync /> 重置对话
        </div>
      </div>
      {messageContent.map((message, index) => (
        <div key={index} className={styles['contentRight']}>
          <div className={styles['userQuestion']}>
            <div className={styles['userQuestion-top']}>
              <div className={styles['face']}>
                <UserAvatar
                  name={localStorage.getItem('userName')}></UserAvatar>
              </div>
              <div className={styles['my-box']}>
                <span className={styles['me']}>我</span>
                <span className={styles['poi']}></span>
                <span className={styles['date']}>{nowDate()}</span>
              </div>
            </div>
            <div className={`${styles['userQuestion']} `}>
              <span>{message.question}</span>
            </div>
          </div>
          <div className={`${styles['answerContent']} `}>
            <div className={styles['answer-top']}>
              <div className={styles['avatar']}>
                {avatar ? <img src={avatar} alt="" /> : <AgentImg />}
              </div>
              <div className={styles['answer-title']}>
                {initialValues.title}
              </div>
            </div>
            <div className={`${styles['answer']} `}>
              <Markdown isCodeCopyBtn={true} content={message.answer} />
            </div>
          </div>
        </div>
      ))}
      {currentMessage.answer && (
        <div className={styles['contentRight']}>
          <div className={styles['userQuestion']}>
            <div className={styles['userQuestion-top']}>
              <div className={styles['face']}>
                <UserAvatar
                  name={localStorage.getItem('userName')}></UserAvatar>
              </div>
              <div className={styles['my-box']}>
                <span className={styles['me']}>我</span>
                <span className={styles['poi']}></span>
                <span className={styles['date']}>{nowDate()}</span>
              </div>
            </div>
            <div className={`${styles['userQuestion']} `}>
              <span>{currentMessage.question}</span>
            </div>
          </div>
          <div className={`${styles['answerContent']} `}>
            <div className={styles['answer-top']}>
              <div className={styles['avatar']}>
                {avatar ? <img src={avatar} alt="" /> : <AgentImg />}
              </div>
              <div className={styles['answer-title']}>
                {initialValues.title}
              </div>
            </div>
            <div className={`${styles['answer']} `}>
              <Markdown isCodeCopyBtn={true} content={currentMessage.answer} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Answer
