import React from 'react'
import styles from './style/empty-mod.module.less'
import { IconAgent } from '@arco-iconbox/react-aidb-v2'

function EmptyMod(props) {
  return (
    <div className={styles.emptyMod}>
      <div className={styles.icon}>
        <IconAgent />
      </div>
      <div className={styles.text}>点击测试智能体，体验您创建的智能 AI</div>
    </div>
  )
}

export default EmptyMod
