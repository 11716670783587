import { Block, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { Node as ProseMirrorNode } from 'prosemirror-model'

import {
  addAttrsByConfig,
  getFromDOMByConfig,
  IUserAttrsConfig,
  setDOMAttrByConfig,
} from '../../utils/tool'

interface IHeaderProps {
  addAttributes?: IUserAttrsConfig
}
interface IHeaderStructure {
  level: number
}

const NAME = 'header'

class Header extends Block<IHeaderStructure> {
  public name = NAME

  public tagName = (node: any) => `h${(node && node.attrs.level) || 1}`

  public parseFromDOM = (dom: HTMLElement) => {
    const level = dom.tagName.match(/\d/)
    if (!level) return false
    const attrs = { level: +level[0] }
    const attrsComment = { level: +level[0], class: 'hasComment' }
    getFromDOMByConfig(
      this.props.addAttributes,
      dom,
      dom.firstElementChild && dom.firstElementChild.tagName == 'COMMENT'
        ? attrsComment
        : attrs,
    )
    return dom.firstElementChild && dom.firstElementChild.tagName == 'COMMENT'
      ? attrsComment
      : attrs
  }

  constructor(editor: SylApi, props: IHeaderProps) {
    super(editor, props)
    if (props.addAttributes) {
      addAttrsByConfig(props.addAttributes, this)
    }
  }

  public textMatcher = [
    {
      matcher: /^(#{1,6})\s$/,
      handler(match: RegExpExecArray) {
        return {
          level: match[1].length,
        }
      },
    },
  ]

  public attrs = {
    level: {
      default: 1,
    },
    class: {
      default: 'header',
    },
  }

  public parseDOM = Array.from({ length: 6 }, (_, i) => ({
    tag: `h${i + 1}`,
    getAttrs: this.parseFromDOM,
  }))

  public toDOM = (node: ProseMirrorNode) => {
    let hasComment = false
    const markType = this.editor.view.state.schema.marks.comment
    const schema = this.editor.view.state.schema
    const className = ['hasComment', 'header']

    node.descendants((nodePara, pos, parent) => {
      const marksToRemove = nodePara.marks.find((mark) => {
        return (
          mark.type === markType && mark.attrs.class.includes('comment') !== -1
        )
      })
      if (marksToRemove) {
        const newAttrs = { ...node.attrs, class: 'hasComment' }
        hasComment = true
        return false
      }

      return true
    })

    // 将node.attrs.class中没有不包含在className的类名添加进去
    const existingClasses = (node.attrs.class || '').split(' ').filter(Boolean)
    const finalClass = Array.from(
      new Set(existingClasses.concat(className)),
    ).join(' ')

    const attrs = {
      level: node.attrs.level,
      class: hasComment ? finalClass : 'header',
    }
    setDOMAttrByConfig(this.props.addAttributes, node, attrs)
    return [`h${attrs.level}`, { ...attrs }, 0] as const
  }
}

class HeaderController extends SylController {
  public name = NAME
  public toolbar = {
    className: 'header',
    tooltip: 'header',
    type: 'select',
    value: [
      {
        attrs: false,
      },
      ...Array.from({ length: 6 }, (_, i) => ({
        text: `H${i + 1}`,
        attrs: { level: i + 1 },
      })),
    ],
  }
}

class HeaderPlugin extends SylPlugin<IHeaderProps> {
  public name = NAME
  public Controller = HeaderController
  public Schema = Header
}

export { Header, HeaderController, HeaderPlugin }
