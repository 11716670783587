import React, { useEffect, useRef, useState } from 'react'
import Markdown from '../../components/Markdown'
import styles from './style/index.module.less'
import SideMod from '@/components/DocumentShare/sideMod'
import ShareHeader from '@/components/DocumentShare/shareHeader'
import { getToolboxShareShare } from '@/model/documentEdit'
import { GetQueryValue } from '@/utils/common'
import { Spin } from '@arco-design/web-react'
import DocumentMod from '@/components/DocumentShare/docShareMod'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

const DocumentShare = () => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const [foldList, setFoldList] = useState([])
  const [currentItem, setCurrentItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasShare, setHasShare] = useState(false)
  const [close, setClose] = useState(false)
  const [isOpenSide, setIsOpenSide] = useState(false)
  const foldListRef = useRef([])
  const currentItmRef = useRef(null)
  const docContentRef = useRef(null)

  useEffect(() => {
    getDocByShareHandle()
  }, [])

  // 监听路由
  useEffect(() => {
    const handleSearchChange = () => {
      window.location.reload() // 刷新页面
    }

    window.addEventListener('popstate', handleSearchChange)

    return () => {
      window.removeEventListener('popstate', handleSearchChange)
    }
  }, [])

  useEffect(() => {
    foldListRef.current = foldList
  }, [foldList])

  useEffect(() => {
    if (currentItmRef.current) {
      setIsLoading(true)
    }
    currentItmRef.current = currentItem
  }, [currentItem])

  //---接口---
  const getDocByShareHandle = async () => {
    const res = await getToolboxShareShare({ uuid: GetQueryValue('uuid') })
    if (res.data.code == '500200') {
      setFoldList([res.data.data])
      foldListRef.current = res.data.data
      const resDoc = await getToolboxShareShare({
        uuid: GetQueryValue('uuid'),
      })
      if (resDoc.data.success) {
        setHasShare(true)
        setCurrentItem(resDoc.data.data)
        currentItmRef.current = resDoc.data.data
        document.title = resDoc.data
          ? resDoc.data?.data.input + ' - 悟智AI'
          : '悟智AI'
      }
    } else {
      setHasShare(false)
      setErrorMsg(res.data.msg)
    }
  }

  const itemClickHandle = async (item) => {
    const res = await getToolboxShareShare({
      uuid: GetQueryValue('uuid'),
    })
    if (res.data.success) {
      console.log(res.data.success, res.data)
      setCurrentItem([res.data.data])
      currentItmRef.current = res.data.data
    }
  }

  const findObjectTypeZero = (data) => {
    for (const item of data) {
      if (item.fileType === 0) {
        return item
      }
      if (item.children) {
        const result = findObjectTypeZero(item.children)
        if (result !== null) {
          return result
        }
      }
    }
    return null
  }

  const toggleSideHandle = (close) => {
    setClose(close)
  }

  const changeSideHandle = (isOpen) => {
    console.log(isOpen, 'isOpen')
    setIsOpenSide(isOpen)
  }

  const hasSideHandle = (hasSide) => {
    setHasShare(hasSide)
  }

  // 分享的Markdown
  const ShareMarkdown = ({ content }) => {
    return <Markdown content={content} isCodeCopyBtn={true} />
  }

  // 获取渲染后的HTML内容
  useEffect(() => {
    if (docContentRef.current && currentItem) {
      const htmlContent = docContentRef.current.innerHTML
      console.log(htmlContent) // 在这里你可以处理HTML字符串，比如传递给子组件
    }
  }, [currentItem])

  return (
    <React.Fragment>
      {isLoading && hasShare ? (
        <div
          className={`${
            close || currentItem.fileType == 0 ? styles.close : ''
          } ${styles.share_page} ${styles.pageLayout} ${styles[theme]}`}>
          <div
            className={`${styles.sideNav} ${
              isOpenSide && styles.isOpenSideNav
            }`}>
            <SideMod
              foldList={foldList}
              onItemClick={itemClickHandle}
              currentId={GetQueryValue('uuid')}
              toggleSide={(close) => {
                toggleSideHandle(close)
              }}
              isShowSide={(show) => hasSideHandle(show)}
            />
          </div>
          <div className={styles.header}>
            <ShareHeader
              foldList={foldList}
              title={currentItem.input}
              comeFrom={'未知'}
              gmtModified={currentItem.currentTime}
              onChangeSide={changeSideHandle}
              docUuid={GetQueryValue('uuid')}
              docContent={
                docContentRef.current ? docContentRef.current.innerHTML : ''
              }
              isExportPdf={false}
            />
          </div>
          <div className={styles.contain}>
            <DocumentMod
              title={currentItem.input}
              docContent={
                docContentRef.current ? docContentRef.current.innerHTML : ''
              }
              foldList={foldList}></DocumentMod>
          </div>
        </div>
      ) : !hasShare ? (
        <div
          className={` ${styles.share_page} ${styles.pageLayout} ${styles[theme]}`}>
          <div className={styles.sideNav}>
            <SideMod foldList={foldList} />
          </div>
          <div className={styles.header}>
            <ShareHeader isExportPdf={false} />
          </div>
          <div className={styles.contain} style={{ paddingLeft: 0 }}>
            <p className={styles.errorMsg}>{errorMsg}</p>
          </div>
        </div>
      ) : (
        <div className={`${styles.share_page} ${styles.pageLayoutLoading}`}>
          <Spin size={50} className={`${styles[`spin`]}`} />
        </div>
      )}
      <div ref={docContentRef} style={{ display: 'none' }}>
        <ShareMarkdown content={currentItem?.output || ''} />
      </div>
    </React.Fragment>
  )
}

export default DocumentShare
