import {
  BackgroundPlugin,
  ColorPlugin,
  EmojiPlugin,
  ImagePlugin,
  LinkPlugin,
} from '@syllepsis/access-react'

import {
  AlignCenterPlugin,
  AlignJustifyPlugin,
  AlignLeftPlugin,
  AlignRightPlugin,
  // AudioPlugin,
  BlockQuotePlugin,
  BoldPlugin,
  BulletListPlugin,
  FontSizePlugin,
  FormatClearPlugin,
  FormatPainterPlugin,
  HeaderPlugin,
  HrPlugin,
  ItalicPlugin,
  LetterSpacePlugin,
  LineHeightPlugin,
  LineIndentPlugin,
  ListItemPlugin,
  OrderedListPlugin,
  ParagraphPlugin,
  RedoPlugin,
  SpaceAfterPlugin,
  SpaceBeforePlugin,
  SpaceBothPlugin,
  StrikePlugin,
  SubPlugin,
  SupPlugin,
  UnderlinePlugin,
  UndoPlugin,
} from '@syllepsis/plugin-basic'

import { TablePlugin } from '@syllepsis/plugin-table'
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block'
import { CommentMenuPlugin } from './custom/plugins/commentMenu'
import { CommentPlugin } from './custom/plugins/comment'
import request from '@/utils/request'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DisablePlugin } from './custom/plugins/disableEdit'
import { EditorNavPlugin } from './custom/plugins/editorNav'

const defaultPlugins = [
  new EditorNavPlugin(),
  new CommentPlugin(),
  new CommentMenuPlugin(),
  new DisablePlugin(),
  new RedoPlugin(),
  new UndoPlugin(),
  new ColorPlugin(),
  new BoldPlugin(),
  new BlockQuotePlugin(),
  new ListItemPlugin({
    matchInnerTags: ['section', 'p'],
    allowedLineHeights: [],
    allowedSpaceBefores: [],
    allowedSpaceAfters: { default: true, value: 20 },
    allowedSpaceBoths: [],
  }),
  new BulletListPlugin(),
  new OrderedListPlugin(),
  new CodeBlockPlugin({ lineNumbers: false }),
  new HrPlugin(),
  new HeaderPlugin(),
  new BackgroundPlugin(),
  new ItalicPlugin(),
  new SubPlugin(),
  new SupPlugin(),
  new StrikePlugin(),
  new UnderlinePlugin(),
  new ParagraphPlugin({
    addMatchTags: ['section'],
    allowedAligns: ['left', 'center', 'right', 'justify'],
    allowedClass: [],
    allowedLineHeights: [],
    allowedLineIndents: [],
    allowedSpaceBefores: [],
    allowedSpaceAfters: { default: true, value: 20 },
    allowedSpaceBoths: [],
  }),
  new FormatClearPlugin(),
  new FontSizePlugin({
    allowedValues: [12, 14, 16, { value: 17, default: true }, 18, 20, 24, 32],
    values: [12, 14, 16, { value: 17, default: true }, 18, 20, 24, 32],
    unit: 'px',
  }),
  new LetterSpacePlugin({
    allowedValues: [0, 0.5, 1, 1.5],
  }),
  new AlignLeftPlugin(),
  new AlignCenterPlugin(),
  new AlignRightPlugin(),
  new AlignJustifyPlugin(),
  new SpaceBeforePlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new SpaceAfterPlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new SpaceBothPlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new LineHeightPlugin({
    values: [1, 1.5, { value: 1.75, default: true }, 1.88, 2, 3],
  }),
  new LineIndentPlugin(),
  new FormatPainterPlugin(),
  new TablePlugin({
    button: {
      activeColor: '#FF0F0F',
      trigger: 'click',
    },
    // columnResize: { handleWidth: 5, cellMinWidth: 24 },
    table: {
      allowTableNodeSelection: false,
      defaultCellWidth: 100,
      useTableHeader: false,
    },
  }),
  new ImagePlugin({
    uploadType: 'file',
    // uploadBeforeInsert: true,
    deleteFailedUpload: true,
    uploader: (img) =>
      new Promise((resolve) => {
        const formData = new FormData()
        formData.append('file', img)
        request
          .post('/api/api-aidb/upload/uploadFile', formData)
          .then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                resolve({
                  src: res.data.data,
                })
                events.emit(ACTIONS.SAVE_PIC_EDITOR)
              }, 1500)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }),
  }),
  new LinkPlugin(),
  new EmojiPlugin(),
]

export default defaultPlugins
