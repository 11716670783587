import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './style/side.module.less';
import { Button } from '@arco-design/web-react';
import { IconLeft, IconWriting, IconRight } from '@arco-iconbox/react-aidb';
interface Props {
  title: string;
  menuList: AIDB.sectionItem[];
}
const Side = (props: Props) => {
  const { title, menuList } = props;
  const { id } = useParams();
  const btnHandle = () => {
    location.href = '/reference';
  };
  return (
    <div className={styles['book-menu']}>
      <div className={styles['top']}>
        <Button
          onClick={btnHandle}
          className={`${styles['g-btn']} ${styles['white ']} ${styles['small']}`}
          icon={<IconLeft style={{ fontSize: 24, color: '#000' }} />}
        />
        <Button
          className={`${styles['g-btn']} ${styles['white ']} ${styles['small']}`}
          icon={<IconWriting style={{ fontSize: 24, color: '#000' }} />}
        />
        <h3>
          {title}
          {id}
        </h3>
      </div>
      <div className={styles['menu']}>
        <p>目录:</p>
        {menuList.map((item, index) => {
          return (
            <MenuItem key={index} name={item.name} sectionId={item.sectionId} />
          );
        })}
      </div>
    </div>
  );
};

const MenuItem = (props) => {
  const { name, sectionId } = props;
  const selectSection = () => {
    window.location.href = `${location.pathname}?sectionId=${sectionId}`;
  };
  return (
    <div onClick={selectSection} className={styles['item']}>
      <h4>{name}</h4>
      <IconRight />
    </div>
  );
};

export default Side;
