import {
  SylApi,
  Inline,
  SylController,
  SylPlugin,
  EventChannel,
} from '@syllepsis/adapter'
import {
  Decoration,
  DecorationAttrs,
  DecorationSet,
  EditorView,
} from 'prosemirror-view'

interface PlaceholderAttrs {
  class: string
}

const PLUGIN_NAME = 'Placeholder'

class PlaceholderSchema extends Inline<PlaceholderAttrs> {
  public name = PLUGIN_NAME
}

class PlaceholderController extends SylController {
  public name = PLUGIN_NAME
  private placeholderDecoration
  private placeholderDecorationSet: DecorationSet | null = null
  constructor(editor: SylApi, props) {
    super(editor, props)
    this.editor.on(
      EventChannel.LocalEvent.SELECTION_CHANGED,
      this.handleTransaction,
    )
  }

  private handleTransaction = () => {
    const { $anchor } = this.editor.view.state.selection
    if (!$anchor) {
      return
    }

    const { parent } = $anchor
    const isEmptyParagraph =
      parent.type.name === 'paragraph' && parent.content.size === 0

    if (isEmptyParagraph) {
      this.addPlaceholderDecoration($anchor.pos)
    }
    //  else {
    //   this.removePlaceholderDecoration()
    // }
  }

  // private createShadow(
  //   dom: HTMLElement,
  //   view: EditorView,
  //   getPos: () => number,
  // ): HTMLElement {
  //   // 创建并返回阴影元素
  //   const shadowElement = document.createElement('div')
  //   shadowElement.textContent = 'This is a shadow element'
  //   // 可以根据需要添加样式、类名等
  //   return shadowElement
  // }

  private addPlaceholderDecoration(pos: number) {
    const decorations = []
    const { doc, selection } = this.editor.view.state

    doc.descendants((node, pos) => {
      if (!node.isBlock || !!node.textContent) return
      if (selection.empty && selection.from === pos + 1) {
        // The selection is inside the node
        if (node.type.name === 'paragraph') {
          // this.editor?.insertShadow(
          //   {
          //     pos: 10, // 插入位置
          //     shadow: this.createShadow,
          //     spec: { key: 'eee' },
          //   },
          //   false, // 是否内联插入
          // )
          //   decorations.push(
          //     Decoration.node(pos, pos + node.nodeSize, {
          //       class: 'placeholder',
          //       style: "--placeholder-text: 'New paragraph';",
          //     }),
          //   )
        }
      } else if (node.type.name !== 'paragraph') {
        decorations.push(
          Decoration.node(pos, pos + node.nodeSize, {
            class: 'placeholder',
            style: `--placeholder-text: "New ${node.type.name}";`,
          }),
        )
      }
      return false
    })

    return DecorationSet.create(doc, decorations)
  }
}

class PlaceholderPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = PlaceholderController
}

export { PlaceholderPlugin }
