import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Message,
  Modal,
  Tabs,
  Typography,
  Slider,
  Tooltip,
  Switch,
  Radio,
  Button,
} from '@arco-design/web-react'
import { switchProject } from '@/helpers/switchProject'
import { IconQuestionCircle } from '@arco-design/web-react/icon'
import { IconSuccessTip, IconWarnTip } from '@arco-iconbox/react-aidb-v2'
import { getConfig, MenuInterface } from './config'

import './style/set.less'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

type SetProps = {
  updateAssistantConfigInfo: (newConfig: MenuInterface) => void
  width?: string
  isAgent?: boolean
}
const TabPane = Tabs.TabPane
const RadioGroup = Radio.Group

const Set = forwardRef((props: SetProps, ref) => {
  const { updateAssistantConfigInfo, width, isAgent } = props
  const theme = useSelector((state: GlobalState) => state.theme)
  // 显示弹窗
  const [visible, setVisible] = useState(false)
  // 数据
  const [assistantConfig, setAssistantConfig] =
    useState<MenuInterface>(getConfig)

  useImperativeHandle(ref, () => ({
    handleCancel,
    assistantConfigChange,
  }))

  // 将localStorage中的数据作为初始化数据
  useEffect(() => {
    const storedConfig: MenuInterface = JSON.parse(
      window.localStorage.getItem('assistantConfig'),
    )

    // 存储前修改数据
    if (storedConfig && !storedConfig.screen.fullScreen.IS_FULL_SCREEN_FIX) {
      storedConfig.screen.fullScreen.IS_FULL_SCREEN = false
    }

    // 存储数据
    if (compareObjects(getConfig, storedConfig)) {
      setAssistantConfig(storedConfig as MenuInterface)
    } else {
      // 属性改变，不使用storedConfig
      console.log(compareObjects(getConfig, storedConfig))
    }
  }, [])

  // 存储信息
  useEffect(() => {
    window.localStorage.setItem(
      'assistantConfig',
      JSON.stringify(assistantConfig),
    )
    updateAssistantConfigInfo(assistantConfig)
  }, [assistantConfig])

  // 当关闭记住对话列表状态时，应将IS_OPEN也关闭，保证下次刷新时对话列表是关闭状态
  useEffect(() => {
    if (!assistantConfig.screen.dialogueList.IS_MEMORY_OPEN) {
      assistantConfigChange(['screen', 'dialogueList', 'IS_OPEN'], false)
    }
  }, [assistantConfig.screen.dialogueList.IS_MEMORY_OPEN])

  // 打开/关闭当前设置弹窗
  const handleCancel = () => {
    setVisible(!visible)
  }

  // 更新数据
  const assistantConfigChange = (
    path: Array<string>,
    value: number | boolean | string,
  ) => {
    setAssistantConfig((prevConfig) => {
      const updatedConfig = JSON.parse(JSON.stringify({ ...prevConfig }))
      let nestedObject = updatedConfig

      path.forEach((key, index) => {
        if (!(key in nestedObject)) {
          nestedObject[key] = {}
        }

        if (index === path.length - 1) {
          nestedObject[key] = value
        }

        nestedObject = nestedObject[key]
      })

      return updatedConfig
    })
  }

  // 显示详细信息函数
  const ShowDetailedInformation = ({ title }) => {
    return (
      <div className="showDetailedInformationOut">
        <Tooltip content={title}>
          <IconQuestionCircle />
        </Tooltip>
      </div>
    )
  }

  // 判断两个对象是否相等（只判断属性，不判断值）
  function compareObjects(obj1, obj2) {
    try {
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
          return false
        }

        const value1 = obj1[key]
        const value2 = obj2[key]

        if (typeof value1 === 'object' && typeof value2 === 'object') {
          if (!compareObjects(value1, value2)) {
            return false
          }
        }
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <Modal
      onCancel={handleCancel}
      className={`modal ${theme}`}
      style={{ width: width }}
      footer={
        <Button
          className="resDefault"
          type="secondary"
          onClick={() => {
            setAssistantConfig(getConfig)
            window.localStorage.removeItem('assistantConfig')
            if (getConfig === assistantConfig) {
              Message.warning({
                icon: <IconWarnTip useCurrentColor={false} />,
                content: '此设置已是默认设置！',
              })
            } else {
              Message.success({
                icon: <IconSuccessTip useCurrentColor={false} />,
                content: '已恢复到默认设置！',
              })
              setVisible(false)
            }
          }}>
          <span>恢复默认</span>
          <ShowDetailedInformation title={'将所有设置恢复为默认设置'} />
        </Button>
      }
      title={null}
      visible={visible}>
      <Tabs key="card" tabPosition={'left'} size="large" className="tabs">
        <TabPane key="1" title="字体" style={{ height: '300px' }}>
          {/* 内容字体大小 */}
          <Typography.Paragraph>
            <div className="sliderParaItem">
              <div className="sliderParaItemTop">
                内容字体大小
                <ShowDetailedInformation
                  title={`助理消息框内的字体大小，默认${getConfig.font.CONTENT_FONT_SIZE}`}
                />
              </div>
              <div className="sliderParaItemBottom">
                <Slider
                  className="slider"
                  value={assistantConfig.font.CONTENT_FONT_SIZE}
                  onChange={(value: number) => {
                    assistantConfigChange(['font', 'CONTENT_FONT_SIZE'], value)
                  }}
                  min={10}
                  max={26}
                  step={2}
                  showTicks={true}
                  marks={{
                    10: 'A',
                    26: 'A',
                  }}
                />
              </div>
            </div>
          </Typography.Paragraph>
          {/* 输入框字体大小 */}
          <Typography.Paragraph>
            <div className="sliderParaItem">
              <div className="sliderParaItemBottom">
                输入框字体大小
                <ShowDetailedInformation
                  title={`助理输入框内的字体大小，默认${getConfig.font.TEXTAREA_FONT_SIZE}`}
                />
              </div>
              <div className="sliderParaItemBottom">
                <Slider
                  className="slider"
                  value={assistantConfig.font.TEXTAREA_FONT_SIZE}
                  onChange={(value: number) => {
                    assistantConfigChange(['font', 'TEXTAREA_FONT_SIZE'], value)
                  }}
                  min={10}
                  max={26}
                  step={2}
                  showTicks={true}
                  marks={{
                    10: 'A',
                    26: 'A',
                  }}
                />
              </div>
            </div>
          </Typography.Paragraph>
        </TabPane>
        <TabPane key="2" title="接口" style={{ height: '300px' }}>
          {/* 初始对接/海外节点 */}
          <Typography.Paragraph>
            <div className="switchParaItem">
              <div className="switchParaItemInner">
                <div className="switchParaItemTop">
                  初始对接
                  <ShowDetailedInformation
                    title={`初始化或选择时，是否对接到最后一个对话，即自动对接到当前助理的最后一条对话进行回答，默认${
                      getConfig.interface.INTERFACE_CONNECT_LAST_TALK
                        ? '对接'
                        : '不对接'
                    }`}
                  />
                </div>
                <div className="switchParaItemBottom">
                  <Switch
                    checked={
                      assistantConfig.interface.INTERFACE_CONNECT_LAST_TALK
                    }
                    onChange={(value: boolean) =>
                      assistantConfigChange(
                        ['interface', 'INTERFACE_CONNECT_LAST_TALK'],
                        value,
                      )
                    }
                  />
                  <div className="switchParaItemBottomText">
                    {assistantConfig.interface.INTERFACE_CONNECT_LAST_TALK
                      ? '已开启'
                      : '已关闭'}
                  </div>
                </div>
              </div>
              <div className="switchParaItemInner">
                <div className="switchParaItemTop">
                  海外节点
                  <ShowDetailedInformation
                    title={`使用网址抓取时，是否使用海外节点服务，默认${
                      getConfig.interface.INTERFACE_HK_NODE ? '使用' : '不使用'
                    }`}
                  />
                </div>
                <div className="switchParaItemBottom">
                  <Switch
                    checked={assistantConfig.interface.INTERFACE_HK_NODE}
                    onChange={(value: boolean) =>
                      assistantConfigChange(
                        ['interface', 'INTERFACE_HK_NODE'],
                        value,
                      )
                    }
                  />
                  <div className="switchParaItemBottomText">
                    {assistantConfig.interface.INTERFACE_HK_NODE
                      ? '已开启'
                      : '已关闭'}
                  </div>
                </div>
              </div>
            </div>
          </Typography.Paragraph>
          {/* 默认助理 */}
          {!isAgent && (
            <Typography.Paragraph>
              <div className="radioGroupParaItem">
                <div className="radioGroupParaItemInner">
                  <div className="radioGroupParaItemTop">
                    默认助理
                    <ShowDetailedInformation
                      title={`当新建对话时，默认使用哪一个助理，默认${
                        getConfig.interface.INTERFACE_DEFAULT_MODEL ===
                        'XIAO_WU_35'
                          ? switchProject('DFZ')
                            ? '助理小京'
                            : '小悟 3.5'
                          : getConfig.interface.INTERFACE_DEFAULT_MODEL ===
                            'XIAO_WU_40'
                          ? switchProject('DFZ')
                            ? '助理小方'
                            : '小悟 4.0'
                          : getConfig.interface.INTERFACE_DEFAULT_MODEL ===
                            'XIAO_ZHI'
                          ? switchProject('DFZ')
                            ? '助理小志'
                            : '小智一言'
                          : getConfig.interface.INTERFACE_DEFAULT_MODEL ===
                            'XIAO_ZHI_QW'
                          ? switchProject('DFZ')
                            ? '小智千问'
                            : '小智千问'
                          : '暂无'
                      }`}
                    />
                  </div>
                  <RadioGroup
                    value={assistantConfig.interface.INTERFACE_DEFAULT_MODEL}
                    onChange={(value: string) =>
                      assistantConfigChange(
                        ['interface', 'INTERFACE_DEFAULT_MODEL'],
                        value,
                      )
                    }>
                    <Radio value="XIAO_WU_35">
                      {switchProject('DFZ') ? '助理小京' : '小悟 3.5'}
                    </Radio>
                    <Radio value="XIAO_WU_40">
                      {switchProject('DFZ') ? '助理小方' : '小悟 4.0'}
                    </Radio>
                    <>
                      <Radio value="XIAO_ZHI">
                        {switchProject('DFZ') ? '小志' : '小智一言'}
                      </Radio>
                      <Radio value="XIAO_ZHI_QW">
                        {switchProject('DFZ') ? '千问' : '小智千问'}
                      </Radio>
                    </>
                  </RadioGroup>
                </div>
              </div>
            </Typography.Paragraph>
          )}
          {/* 网址抓取 \ 文件上传 */}
          <Typography.Paragraph>
            <div className="switchParaItems">
              <div className="switchParaItemInners">
                <div className="switchParaItemTops">
                  网址抓取 / 文件上传
                  <ShowDetailedInformation
                    title={`当使用网址抓取 / 文件上传时，需要开启哪些服务，默认${
                      getConfig.interface.websiteCrawlingAndFileUpload
                        .INTERFACE_OUTLINES &&
                      getConfig.interface.websiteCrawlingAndFileUpload
                        .INTERFACE_QUESTIONS &&
                      getConfig.interface.websiteCrawlingAndFileUpload
                        .INTERFACE_TAGS
                        ? '全部开启'
                        : '部分开启'
                    }`}
                  />
                </div>
                <div className="switchParaItem">
                  <div className="switchParaItemInner">
                    <div className="switchParaItemTopNoBold">大纲</div>
                    <div className="switchParaItemBottom">
                      <Switch
                        checked={
                          assistantConfig.interface.websiteCrawlingAndFileUpload
                            .INTERFACE_OUTLINES
                        }
                        onChange={(value: boolean) =>
                          assistantConfigChange(
                            [
                              'interface',
                              'websiteCrawlingAndFileUpload',
                              'INTERFACE_OUTLINES',
                            ],
                            value,
                          )
                        }
                      />
                      <div className="switchParaItemBottomText">
                        {assistantConfig.interface.websiteCrawlingAndFileUpload
                          .INTERFACE_OUTLINES
                          ? '已开启'
                          : '已关闭'}
                      </div>
                    </div>
                  </div>
                  <div className="switchParaItemInner">
                    <div className="switchParaItemTopNoBold">相关问题</div>
                    <div className="switchParaItemBottom">
                      <Switch
                        checked={
                          assistantConfig.interface.websiteCrawlingAndFileUpload
                            .INTERFACE_QUESTIONS
                        }
                        onChange={(value: boolean) =>
                          assistantConfigChange(
                            [
                              'interface',
                              'websiteCrawlingAndFileUpload',
                              'INTERFACE_QUESTIONS',
                            ],
                            value,
                          )
                        }
                      />
                      <div className="switchParaItemBottomText">
                        {assistantConfig.interface.websiteCrawlingAndFileUpload
                          .INTERFACE_QUESTIONS
                          ? '已开启'
                          : '已关闭'}
                      </div>
                    </div>
                  </div>
                  <div className="switchParaItemInner">
                    <div className="switchParaItemTopNoBold">关键字</div>
                    <div className="switchParaItemBottom">
                      <Switch
                        checked={
                          assistantConfig.interface.websiteCrawlingAndFileUpload
                            .INTERFACE_TAGS
                        }
                        onChange={(value: boolean) =>
                          assistantConfigChange(
                            [
                              'interface',
                              'websiteCrawlingAndFileUpload',
                              'INTERFACE_TAGS',
                            ],
                            value,
                          )
                        }
                      />
                      <div className="switchParaItemBottomText">
                        {assistantConfig.interface.websiteCrawlingAndFileUpload
                          .INTERFACE_TAGS
                          ? '已开启'
                          : '已关闭'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography.Paragraph>
        </TabPane>
        <TabPane key="3" title="屏幕" style={{ height: '300px' }}>
          {/* 全屏 */}
          <Typography.Paragraph>
            <div className="switchParaItem">
              <div className="switchParaItemInner">
                <div className="switchParaItemTop">
                  记住对话列表状态
                  <ShowDetailedInformation
                    title={`开启时，每次初始化将记住对话列表打开和关闭的状态，默认${
                      getConfig.screen.dialogueList.IS_MEMORY_OPEN
                        ? '打开'
                        : '关闭'
                    }`}
                  />
                </div>
                <div className="switchParaItemBottom">
                  <Switch
                    checked={assistantConfig.screen.dialogueList.IS_MEMORY_OPEN}
                    onChange={(value: boolean) => {
                      assistantConfigChange(
                        ['screen', 'dialogueList', 'IS_MEMORY_OPEN'],
                        value,
                      )
                    }}
                  />
                  <div className="switchParaItemBottomText">
                    {assistantConfig.screen.dialogueList.IS_MEMORY_OPEN
                      ? '已开启'
                      : '已关闭'}
                  </div>
                </div>
              </div>
            </div>
          </Typography.Paragraph>
          {/* 全屏 */}
          <Typography.Paragraph>
            <div className="switchParaItem">
              <div className="switchParaItemInner">
                <div className="switchParaItemTop">
                  切换全屏
                  <ShowDetailedInformation
                    title={`进入全屏模式，默认${
                      getConfig.screen.fullScreen.IS_FULL_SCREEN
                        ? '打开'
                        : '关闭'
                    }`}
                  />
                </div>
                <div className="switchParaItemBottom">
                  <Switch
                    checked={assistantConfig.screen.fullScreen.IS_FULL_SCREEN}
                    onChange={(value: boolean) => {
                      assistantConfigChange(
                        ['screen', 'fullScreen', 'IS_FULL_SCREEN'],
                        value,
                      )
                      setVisible(false)
                    }}
                  />
                  <div className="switchParaItemBottomText">
                    {assistantConfig.screen.fullScreen.IS_FULL_SCREEN
                      ? '已开启'
                      : '已关闭'}
                  </div>
                </div>
              </div>
              <div className="switchParaItemInner">
                <div className="switchParaItemTop">
                  记住全屏
                  <ShowDetailedInformation
                    title={`当开启全屏时，每次进入默认自动打开全屏，默认${
                      getConfig.screen.fullScreen.IS_FULL_SCREEN_FIX
                        ? '打开'
                        : '关闭'
                    }`}
                  />
                </div>
                <div className="switchParaItemBottom">
                  <Switch
                    checked={
                      assistantConfig.screen.fullScreen.IS_FULL_SCREEN_FIX
                    }
                    onChange={(value: boolean) =>
                      assistantConfigChange(
                        ['screen', 'fullScreen', 'IS_FULL_SCREEN_FIX'],
                        value,
                      )
                    }
                  />
                  <div className="switchParaItemBottomText">
                    {assistantConfig.screen.fullScreen.IS_FULL_SCREEN_FIX
                      ? '已开启'
                      : '已关闭'}
                  </div>
                </div>
              </div>
            </div>
          </Typography.Paragraph>
          {/* 聊天区宽度 */}
          <Typography.Paragraph>
            <div className="screenParaItem">
              <div className="sliderParaItemTop">
                聊天区宽度
                <ShowDetailedInformation
                  title={`全屏时，聊天记录区域的宽度，默认${getConfig.screen.screenWidth.CHAT_AREA_WIDTH}%`}
                />
              </div>
              <div className="sliderParaItemBottom">
                <Slider
                  className="slider"
                  disabled={!assistantConfig.screen.fullScreen.IS_FULL_SCREEN}
                  value={assistantConfig.screen.screenWidth.CHAT_AREA_WIDTH}
                  onChange={(value: number) => {
                    assistantConfigChange(
                      ['screen', 'screenWidth', 'CHAT_AREA_WIDTH'],
                      value,
                    )
                  }}
                  min={40}
                  max={100}
                  step={10}
                  showTicks={true}
                  marks={{
                    40: '窄',
                    100: '宽',
                  }}
                />
              </div>
            </div>
          </Typography.Paragraph>
          {/* 输入框宽度 */}
          <Typography.Paragraph>
            <div className="screenParaItem">
              <div className="sliderParaItemTop">
                输入框宽度
                <ShowDetailedInformation
                  title={`全屏时，输入框及底部的宽度，默认${getConfig.screen.screenWidth.TEXTAREA_AREA_WIDTH}%`}
                />
              </div>
              <div className="sliderParaItemBottom">
                <Slider
                  className="slider"
                  disabled={!assistantConfig.screen.fullScreen.IS_FULL_SCREEN}
                  value={assistantConfig.screen.screenWidth.TEXTAREA_AREA_WIDTH}
                  onChange={(value: number) => {
                    assistantConfigChange(
                      ['screen', 'screenWidth', 'TEXTAREA_AREA_WIDTH'],
                      value,
                    )
                  }}
                  min={40}
                  max={100}
                  step={10}
                  showTicks={true}
                  marks={{
                    40: '窄',
                    100: '宽',
                  }}
                />
              </div>
            </div>
          </Typography.Paragraph>
        </TabPane>
      </Tabs>
    </Modal>
  )
})

export default Set
