// import React, { useEffect, useRef, useState } from 'react'
// import ReactToPrint from 'react-to-print'
// import ComponentToPrint from './CompontentToPrint'
// import { Worker } from '@react-pdf-viewer/core'
// import { Viewer } from '@react-pdf-viewer/core'
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
// import '@react-pdf-viewer/core/lib/styles/index.css'
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// import * as mammoth from 'mammoth'

// const Demo = () => {
//   const defaultLayoutPluginInstance = defaultLayoutPlugin()
//   const [htmlContent, setHtmlContent] = useState(null)
//   // useEffect(() => {
//   //   mammoth
//   //     .extractRawText({ arrayBuffer: docxUrl })
//   //     .then((result) => {
//   //       setHtmlContent(result.value)
//   //     })
//   //     .catch((error) => {
//   //       console.error('Error converting DOCX to HTML:', error)
//   //     })
//   // }, [])
//   return (
//     <div>
//       <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
//         <Viewer
//           fileUrl="https://cors-hk.v-dk.com/https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/2023-FIRST-惊喜证件系统1010.pdf"
//           plugins={[defaultLayoutPluginInstance]}
//         />
//       </Worker>
//     </div>
//   )
// }

// export default Demo

import React, { useState } from 'react'

const FileViewerWithPagination = () => {
  // 使用状态来管理当前页数
  const [currentPage, setCurrentPage] = useState(1)

  // 模拟 PDF 和 DOC 文件的 URL
  const pdfFileUrl = 'url_to_pdf_file'
  const docFileUrl = 'url_to_doc_file'

  // 下载文件的函数
  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement('a')
    link.href = fileUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // 切换页数的函数
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div>
      {/* PDF 文件展示 */}
      {/* <div>
        <iframe
          src={`${'https://cors-hk.v-dk.com/https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/2023-FIRST-惊喜证件系统1010.pdf'}#page=${currentPage}`}
          width="600"
          height="800"
          title="PDF Viewer"></iframe>
        <button
          onClick={() =>
            downloadFile(
              'https://cors-hk.v-dk.com/https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/2023-FIRST-惊喜证件系统1010.pdf',
              'example.pdf',
            )
          }>
          Download PDF
        </button>
      </div> */}

      {/* DOC 文件展示 */}
      <div>
        {/* 在实际应用中可能需要使用适当的组件来展示DOC文件 */}
        {/* 这里只是演示用法 */}
        <iframe
          src={
            'https://cors-hk.v-dk.com/https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx'
          }
          width="601"
          height="800"
          title="DOC Viewer"></iframe>
        <button
          onClick={() =>
            downloadFile(
              'https://cors-hk.v-dk.com/https://www-test.firstfilm.org.cn/wp-content/uploads/2023/11/11.27反馈文档.docx',
              'example.doc',
            )
          }>
          Download DOC
        </button>
      </div>

      {/* 分页选择 */}
      <div>
        <span>Page: {currentPage}</span>
        <button onClick={() => handlePageChange(currentPage - 1)}>
          Previous Page
        </button>
        <button onClick={() => handlePageChange(currentPage + 1)}>
          Next Page
        </button>
      </div>
    </div>
  )
}

export default FileViewerWithPagination
