/**
 *
 * yjs记时，超时断开连接
 * chronology：记时函数
 * refreshChronology：刷新记时函数
 * clearChronology：清除计时器
 *
 */
let timer = null

export const chronology = (callback) => {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    callback()
  // }, 1000 * 20) // 20秒
  }, 1000 * 60 * 10) // 10分钟
}

export const refreshChronology = (callback) => {
  // 重新启动计时器
  chronology(callback)
}

export const clearChronology = () => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
}
