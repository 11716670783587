import React, { useState } from 'react'
import styles from './style/draw-head.module.less'
import style from './style/creation-item.module.less'
import { IconArrowLeft, IconClear } from '@arco-iconbox/react-aidb-v2'
import { Popconfirm, Tooltip } from '@arco-design/web-react'
import { showModal } from '@/share/actions'
import ModalCustom from '@/components/ModalCustom'
import BorderBtn from '@/components/BorderBtn'
import { useHistory } from 'react-router-dom'
import { IconQuestionCircle } from '@arco-design/web-react/icon'
function DrawHead(props) {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const handleModal = () => {
    setVisible(true)
  }
  const handleNavigation = () => {
    history.push('/home')
  }
  //弹窗内容
  const clearContent = (
    <div className={style.img_pop}>
      <div
        className={style.img_name}
        style={{ color: 'white', padding: '15px 0' }}>
        确认要清空内容吗?
      </div>
      <BorderBtn
        gradient={true}
        height={50}
        text={'确定'}
        onClick={() => {
          props.onClear()
          setVisible(false)
        }}
      />
    </div>
  )
  return (
    <div className={styles.header}>
      <div className={styles.left} onClick={handleNavigation}>
        <div className={styles.icon}>
          <IconArrowLeft />
        </div>
        <div className={styles.title}>{props.title}</div>
      </div>

      <div className={styles.right}>
        {!props.hideDoc && (
          <div
            className={styles.right1}
            onClick={() => {
              window.open('https://go.wuz.com.cn/767a')
            }}>
            <div className={styles.btn}>
              <IconQuestionCircle
                fontSize={16}
                fill="white"
                useCurrentColor={true}
                style={{ marginRight: '10px' }}
              />
              常见问题
            </div>
          </div>
        )}

        {!props.hideClear && (
          <div className={styles.right2} onClick={handleModal}>
            <div className={styles.btn}>
              <IconClear
                fontSize={16}
                fill="white"
                useCurrentColor={true}
                style={{ marginRight: '10px' }}
              />
              清空内容
            </div>
          </div>
        )}
      </div>
      <ModalCustom
        black={true}
        titleHeight={true}
        width={'485px'}
        title={'清空内容'}
        visible={visible}
        close={() => setVisible(false)}
        content={clearContent}
      />
    </div>
  )
}

export default DrawHead
