import {
  Form,
  Input,
  Button,
  Space,
  Grid,
  Message,
} from '@arco-design/web-react'
import React, { useEffect, useState } from 'react'
import useStorage from '@/utils/useStorage'
import useLocale from '@/utils/useLocale'
import locale from './locale'
import styles from '@/style/index.module.less'
import md5 from 'md5'
const Row = Grid.Row
const Col = Grid.Col
import Aliyun from '../../components/AliyunCheck/aliyunPhone'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { editPassword, sendEmail, sendSMSCode } from '@/model/reference'
import { useHistory } from 'react-router-dom'
let timeChange

export default function Forgotpwd() {
  const FormItem = Form.Item
  const [form] = Form.useForm()
  const emailState = Form.useFormState('email', form)
  const [errorMessage] = useState('')
  useStorage('loginParams')
  const t = useLocale(locale)
  const history = useHistory()

  //倒计时
  const [time, setTime] = useState(60)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [btnContent, setBtnContent] = useState('获取验证码')
  const [emailPhoneBtn, setEmailPhoneBtn] = useState(true)

  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`)
    } else {
      clearInterval(timeChange)
      setBtnDisabled(false)
      setTime(60)
      setBtnContent('获取验证码')
    }
  }, [time])

  //监听手机号或邮箱
  useEffect(() => {
    console.log(form?.getFieldsValue().email)
    const pattern = /^1[3456789]\d{9}$/
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (form?.getFieldsValue()?.email?.includes('@')) {
      if (!regex.test(form?.getFieldsValue().email)) {
        setEmailPhoneBtn(true)
      } else {
        setEmailPhoneBtn(false)
      }
    } else {
      if (!pattern.test(form?.getFieldsValue().email)) {
        setEmailPhoneBtn(true)
      } else {
        setEmailPhoneBtn(false)
      }
    }
  }, [form?.getFieldsValue().email])
  console.log(emailPhoneBtn)

  const getPhoneCaptcha = async () => {
    const { email } = form.getFieldsValue()
    const isEmail = email.includes('@')
    const isPhone = !isEmail

    if (isEmail) {
      if (!isEmailValid(email)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请输入有效的邮箱地址',
        })
        return
      }
    } else if (isPhone) {
      if (!isPhoneValid(email)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请输入有效的手机号',
        })
        return
      }
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请先选择接收验证码方式',
      })
      return
    }
    setBtnDisabled(true)
    setBtnContent('发送中...')
    const sendFunction = isEmail ? sendEmail : sendSMSCode
    try {
      const res = await sendFunction({
        type: isPhone ? 3 : 2,
        email: isEmail ? email : undefined,
        areaCode: isPhone ? '86' : undefined,
        phone: isPhone ? email : undefined,
      })

      const { success, msg } = res.data
      if (success) {
        timeChange = setInterval(() => setTime((t) => --t), 1000)
        setBtnDisabled(true)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '验证码发送成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: msg || '验证码发送失败',
        })
      }
    } catch (error) {
      setBtnDisabled(false)
      setBtnContent('获取验证码')
      if (error.response) {
        let errorMsg = ''
        if (error.response.data.status === 400) {
          errorMsg = error.response.data.errors
            .map((err) => err.defaultMessage)
            .join(' ')
        } else if (error.response.data.status === 500) {
          errorMsg = error.response.data.message
          if (
            errorMsg === '该账号已存在，请联系系统管理员' &&
            !localStorage.getItem('loginParams')
          ) {
            const loginParams = {
              userName: email?.trim(),
              password: '',
              privacyChecked: false,
            }

            localStorage.setItem('loginParams', JSON.stringify(loginParams))
          }
        }
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `${error.response.data.status} - ${errorMsg}`,
        })
      }
    }
  }
  function onLogin() {
    history.push('/login')
  }

  // 验证邮箱格式是否有效
  function isEmailValid(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email)
  }

  // 验证手机号格式是否有效
  function isPhoneValid(phone) {
    const phonePattern = /^1[3456789]\d{9}$/
    return phonePattern.test(phone)
  }

  //修改密码
  function forgotPwd() {
    // md5加密并且转为大写
    const passwordMd5 = md5(
      form.getFieldsValue().password?.trim() + 'avc154*gtd45rge3',
    ).toLocaleUpperCase()

    const repeatPasswordMd5 = md5(
      form.getFieldsValue().repeatPassword?.trim() + 'avc154*gtd45rge3',
    ).toLocaleUpperCase()
    form.validate().then((res) => {
      const type = res.email.includes('@') ? 2 : 1

      editPassword({
        emailOrPhone: res.email.trim(),
        code: res.emailCode?.trim(),
        password: passwordMd5,
        repeatPassword: repeatPasswordMd5,
        type: type,
      })
        .then((res) => {
          const { success } = res.data
          // 业务成功
          if (success === true) {
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: '重置密码成功',
            })
            setTimeout(() => {
              afterRegisterSuccess()
            }, 1000)
          }
        })
        .catch((error) => {
          console.log(error)
          // 服务器或网络错误处理
          // setErrorMessage(t['register.form.register.errMsg']);

          if (error.response) {
            if (error.response.data.status === 400) {
              let msg = ''
              for (
                let index = 0;
                index < error.response.data.errors.length;
                index++
              ) {
                msg =
                  msg + ' ' + error.response.data.errors[index].defaultMessage
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: error.response.data.status + ' - ' + msg,
                })
              }
            }
            if (error.response.data.status === 500) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content:
                  error.response.data.status +
                  ' - ' +
                  error.response.data.message,
              })
            }
          }
        })
    })
  }

  function afterRegisterSuccess() {
    setTimeout(() => {
      history.push('/login')
    }, 1000)
  }

  //阿里云校验通过回调
  const handleBizResult = (bizResult) => {
    if (bizResult === true) {
      // 在这里定义父组件中校验通过的操作
      // getPhoneCaptcha()
      timeChange = setInterval(() => setTime((t) => --t), 1000)
      setBtnDisabled(true)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '验证码发送成功',
      })
    }
  }
  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>
        {t['forgotpwd.form.register.title']}
      </div>
      <div className={styles['login-form-sub-title']}></div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <div className={styles.pwdinput}>
        <Form className={styles['login-form']} layout="vertical" form={form}>
          <FormItem
            field="email"
            rules={[
              {
                required: true,
                message: '邮箱或手机号不能为空',
              },
              {
                validator: (v, cb) => {
                  const pattern = /^1[3456789]\d{9}$/
                  const regex =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                  if (v.includes('@')) {
                    if (!regex.test(v)) {
                      return cb('请输入正确的邮箱')
                    }
                  } else {
                    if (v.length >= 11) {
                      if (!pattern.test(v)) {
                        return cb('请输入正确的手机号')
                      }
                    }
                  }
                },
              },
            ]}>
            <Input placeholder="邮箱/手机号" />
          </FormItem>

          <Row className="grid-demo">
            <Col flex="auto">
              <FormItem
                field="emailCode"
                rules={[{ required: true, message: '验证码不能为空' }]}
                className={styles.email}>
                <Input placeholder="验证码" />
              </FormItem>
            </Col>
            <Col flex="0px">
              <Button
                className={styles.emailCode1}
                id="aliyunButton"
                disabled={
                  btnDisabled ||
                  emailState?.validateStatus !== 'success' ||
                  emailPhoneBtn
                }
                // onClick={getPhoneCaptcha}
              >
                {btnContent}
              </Button>
            </Col>
          </Row>
          <FormItem
            field="password"
            rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password placeholder="密码" visibilityToggle={false} />
          </FormItem>
          <FormItem
            field="repeatPassword"
            rules={[
              {
                validator: (v, cb) => {
                  if (!v) {
                    return cb('确认密码不能为空')
                  } else if (form.getFieldValue('password') !== v) {
                    return cb('两次密码输入不相符')
                  }
                  cb(null)
                },
              },
            ]}>
            <Input.Password visibilityToggle={false} placeholder="确认密码" />
          </FormItem>
          <Space size={16} direction="vertical">
            <div className={styles['login-form-password-actions']}>
              {/* <Link>{t['register.form.forgetPassword']}</Link> */}
            </div>
            <div className={styles.btn}>
              <Button type="primary" long onClick={forgotPwd}>
                {t['forgotpwd.form.register']}
              </Button>
              <Button
                onClick={onLogin}
                type="text"
                long
                className={styles['login-form-register-btn']}>
                {t['forgotpwd.form.login']}
              </Button>
            </div>
          </Space>
        </Form>
      </div>
      <Aliyun
        onSuccessCallback={handleBizResult}
        phoneType={'找回密码'}
        usernamePhone={form?.getFieldsValue().email}></Aliyun>
    </div>
  )
}
