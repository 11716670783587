const i18n = {
  'en-US': {
    'forgotpwd.form.title': 'Login to AIDB - AI写作系统',
    'forgotpwd.form.userName.errMsg': 'Username cannot be empty',
    'forgotpwd.form.password.errMsg': 'Password cannot be empty',
    'forgotpwd.form.login.errMsg': 'Login error, please refresh and try again',
    'forgotpwd.form.userName.placeholder': 'Username: admin',
    'forgotpwd.form.password.placeholder': 'Password: admin',
    'forgotpwd.form.rememberPassword': 'Remember password',
    'forgotpwd.form.forgetPassword': 'Forgot password',
    'forgotpwd.form.login': 'login',
    'forgotpwd.form.register': 'register account',
    'forgotpwd.banner.slogan1': 'Out-of-the-box high-quality template',
    'forgotpwd.banner.subSlogan1':
      'Rich page templates, covering most typical business scenarios',
    'forgotpwd.banner.slogan2': 'Built-in solutions to common problems',
    'forgotpwd.banner.subSlogan2':
      'Internationalization, routing configuration, state management everything',
    'forgotpwd.banner.slogan3': 'Access visualization enhancement tool AUX',
    'forgotpwd.banner.subSlogan3': 'Realize flexible block development',
  },
  'zh-CN': {
    'forgotpwd-form-register-btn': '登录',
    'forgotpwd.form.register.title': '找回密码',
    'forgotpwd.form.title': '登录 AIDB - AI写作系统',
    'forgotpwd.form.slogan': '欢迎回来，产品试用请联系 hi@wuzz.com.cn',
    'forgotpwd.form.userName.errMsg': '用户名不能为空',
    'forgotpwd.form.password.errMsg': '密码不能为空',
    'forgotpwd.form.login.errMsg': '用户名或密码错误，请刷新重试',
    'forgotpwd.form.userName.placeholder': '用户名：admin',
    'forgotpwd.form.password.placeholder': '密码：admin',
    'forgotpwd.form.rememberPassword': '记住密码',
    'forgotpwd.form.forgetPassword': '忘记密码',
    'forgotpwd.form.login': '登录',
    'forgotpwd.form.register': '找回密码',
    'forgotpwd.form.captchaSend': '验证码发送成功',
  },
}

export default i18n
