import {
  BackgroundPlugin,
  ColorPlugin,
  EmojiPlugin,
  ImagePlugin,
  // LinkPlugin,
} from '@syllepsis/access-react'
import { ISylEditorProps } from '@syllepsis/editor'
import { LinkPlugin } from '../lib/custom/plugins/link/index'
import {
  AlignCenterPlugin,
  AlignJustifyPlugin,
  AlignLeftPlugin,
  AlignRightPlugin,
  // AudioPlugin,
  BlockQuotePlugin,
  BoldPlugin,
  BulletListPlugin,
  FontSizePlugin,
  FormatClearPlugin,
  FormatPainterPlugin,
  HeaderPlugin,
  HrPlugin,
  ItalicPlugin,
  LetterSpacePlugin,
  LineHeightPlugin,
  LineIndentPlugin,

  // ListItemPlugin,
  OrderedListPlugin,
  // ParagraphPlugin,
  RedoPlugin,
  SpaceAfterPlugin,
  SpaceBeforePlugin,
  SpaceBothPlugin,
  StrikePlugin,
  UnderlinePlugin,
  UndoPlugin,
  VideoPlugin,
} from '@syllepsis/plugin-basic'

import { TablePlugin } from '@syllepsis/plugin-table'
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block'
import { ToolbarInlineLoader, ToolbarLoader } from '@syllepsis/editor'
import { RewritePlugin } from './custom/plugins/rewrite'
import { TranslatePlugin } from './custom/plugins/translate'
import { FontFamilyPlugin } from './custom/plugins/font-family'
import MoreIcon from './component/more'
import { ChatGPTPlugin } from './custom/plugins/chatGPT'
import { ToolsPlugin } from './custom/plugins/tools'

const getDefaultModule = (mountEle): ISylEditorProps['module'] => ({
  toolbar: {
    Ctor: ToolbarLoader,
    option: {
      mount: mountEle,
      tipDirection: 'up',
      menuDirection: 'up',
      className: 'toolbar',
      tools: [
        FontFamilyPlugin.getName(), //字体
        UndoPlugin.getName(),
        RedoPlugin.getName(),
        FontSizePlugin.getName(), //字体大小
        HeaderPlugin.getName(), //标题
        BoldPlugin.getName(), //加粗
        ItalicPlugin.getName(), //斜体
        UnderlinePlugin.getName(), //下划线
        EmojiPlugin.getName(), //表情
        ColorPlugin.getName(), //颜色
        BackgroundPlugin.getName(), //背景色
        BulletListPlugin.getName(), //无序列表
        OrderedListPlugin.getName(), //有序列表
        TablePlugin.getName(), //表格
        ImagePlugin.getName(), //图片
        BlockQuotePlugin.getName(), //引用

        //字间距
        //
        //
        //
        // CodeBlockPlugin.getName(),//代码块
        // ImagePlugin.getName(), //图片
        // VideoPlugin.getName(), //视频
        // AudioPlugin.getName(),//音频
        //
        LinkPlugin.getName(), //链接
        //
        // SubPlugin.getName(),
        // SupPlugin.getName(),
        //
        [
          AlignLeftPlugin.getName(),
          AlignCenterPlugin.getName(),
          AlignRightPlugin.getName(),
          AlignJustifyPlugin.getName(),
        ],

        {
          name: '更多',
          icon: MoreIcon,
          showName: '更多1',
          content: [
            StrikePlugin.getName(), //删除线
            LineIndentPlugin.getName(), //首行缩进
            LineHeightPlugin.getName(), //行高
            SpaceBothPlugin.getName(), //两段缩进
            SpaceBeforePlugin.getName(), //段前句
            SpaceAfterPlugin.getName(), //段后句
            LetterSpacePlugin.getName(), //字间距
            HrPlugin.getName(), //分割线
            FormatPainterPlugin.getName(), //格式刷
            FormatClearPlugin.getName(), //清除格式
          ],
        },
      ],
      //工具提示语
      tooltips: {
        [FontFamilyPlugin.getName()]: '字体',
        [RedoPlugin.getName()]: '重做',
        [UndoPlugin.getName()]: '撤销',
        [HeaderPlugin.getName()]: '标题',
        [BulletListPlugin.getName()]: '无序列表',
        [OrderedListPlugin.getName()]: '有序列表',
        [CodeBlockPlugin.getName()]: '代码块',
        [BlockQuotePlugin.getName()]: '引用',
        [BoldPlugin.getName()]: '加粗',
        [ItalicPlugin.getName()]: '斜体',
        [UnderlinePlugin.getName()]: '下划线',
        [StrikePlugin.getName()]: '删除线',
        [FontSizePlugin.getName()]: '字体大小',
        [LetterSpacePlugin.getName()]: '字间距',
        [BackgroundPlugin.getName()]: '背景色',
        [FormatPainterPlugin.getName()]: '格式刷',
        [FormatClearPlugin.getName()]: '清除格式',
        [ColorPlugin.getName()]: '颜色',
        [ImagePlugin.getName()]: '图片',
        [VideoPlugin.getName()]: '视频',
        [HrPlugin.getName()]: '分割线',
        [LinkPlugin.getName()]: '超链接',
        [EmojiPlugin.getName()]: 'emoji表情',
        [TablePlugin.getName()]: '表格',
        [SpaceBeforePlugin.getName()]: '段前距',
        [SpaceAfterPlugin.getName()]: '段后距',
        [SpaceBothPlugin.getName()]: '两端缩进',
        [LineHeightPlugin.getName()]: '行高',
        [LineIndentPlugin.getName()]: '首行缩进',
        [AlignLeftPlugin.getName()]: '左对齐',
        [AlignCenterPlugin.getName()]: '居中对齐',
        [AlignRightPlugin.getName()]: '右对齐',
        [AlignJustifyPlugin.getName()]: '两端对齐',
      },
    },
  },
  toolbarInline: {
    Ctor: ToolbarInlineLoader,

    option: {
      zIndex: 999,
      threshold: { top: 100, left: 10, right: 10, bottom: 100 },
      tools: [
        RewritePlugin.getName(),
        TranslatePlugin.getName(),
        ToolsPlugin.getName(),
        ChatGPTPlugin.getName(),
        // CommentMenuPlugin.getName(),
      ],
      tooltips: false,
      trigger: 'click',
    },
  },
})

export default getDefaultModule
