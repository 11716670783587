import React, { useEffect } from 'react'
import Footer from '@/components/Footer'
import LoginForm from './form'
import LoginBanner from '../login/banner'
import styles from '@/style/index.module.less'
import Logo from '@/assets/logo.svg'
import { useHistory } from 'react-router-dom'
function Register() {
  const history = useHistory()
  useEffect(() => {
    // document.body.setAttribute('arco-theme', 'light');
    if (localStorage.getItem('userStatus') == 'login') {
      history.push('/home')
    }
  }, [])
  useEffect(() => {
    document.title = '注册 - 悟智AI'
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <div className={styles.logo}>
          <Logo
            onClick={() => {
              history.push('/home')
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-inner']}>
          <LoginForm />
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
      <div className={styles.banner}>
        <div className={styles['banner-inner']}>
          <LoginBanner />
        </div>
      </div>
    </div>
  )
}

export default Register
