import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { setBlockType } from 'prosemirror-commands'
import { EditorState } from 'prosemirror-state'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { showDrawer } from '@/share/actions'
interface ToolsMobile {
  name: string
}
const PLUGIN_NAME = 'tools-mobile'
class ToolsMobileSchema extends Inline<ToolsMobile> {
  public name = PLUGIN_NAME
}

class ToolsMobileController extends SylController {
  public name = PLUGIN_NAME
  constructor(editor: SylApi, props) {
    super(editor, props)
  }

  // 自定义菜单栏
  public toolbar = {
    icon: (_editor: SylApi, attrs: { fontFamily: string; name: string }) => {
      const openHandle = () => {
        showDrawer('ExportDrawer')
      }
      return (
        <div
          style={{ color: `#999`, background: '#fff', fontSize: '12px' }}
          onClick={openHandle}>
          工具箱
        </div>
      )
    },
  }
}
class ToolsMobilePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = ToolsMobileController
  public Schema = ToolsMobileSchema
}

export { ToolsMobilePlugin }
