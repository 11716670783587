import {
  BackgroundPlugin,
  ColorPlugin,
  EmojiPlugin,
  ImagePlugin,
  // LinkPlugin,
} from '@syllepsis/access-react'
import { SylApi } from '@syllepsis/adapter'
import { ISylEditorProps } from '@syllepsis/editor'
import { LinkPlugin } from '../lib/custom/plugins/link/index'
import {
  AlignCenterPlugin,
  AlignJustifyPlugin,
  AlignLeftPlugin,
  AlignRightPlugin,
  AudioPlugin,
  BlockQuotePlugin,
  BoldPlugin,
  BulletListPlugin,
  FontSizePlugin,
  FormatClearPlugin,
  FormatPainterPlugin,
  // HeaderPlugin,
  HrPlugin,
  ItalicPlugin,
  LetterSpacePlugin,
  LineHeightPlugin,
  LineIndentPlugin,

  // ListItemPlugin,
  OrderedListPlugin,
  // ParagraphPlugin,
  RedoPlugin,
  SpaceAfterPlugin,
  SpaceBeforePlugin,
  SpaceBothPlugin,
  StrikePlugin,
  UnderlinePlugin,
  UndoPlugin,
  VideoPlugin,
} from '@syllepsis/plugin-basic'

import { TablePlugin } from '@syllepsis/plugin-table'
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block'
import { ToolbarInlineLoader, ToolbarLoader } from '@syllepsis/editor'
import { RewritePlugin } from './custom/plugins/rewrite'
import { TranslatePlugin } from './custom/plugins/translate'
import { FontFamilyPlugin } from './custom/plugins/font-family'
import MoreIcon from './component/more'
import { ChatGPTPlugin } from './custom/plugins/chatGPT'
import { ToolsPlugin } from './custom/plugins/tools'
import { CharacterCountPlugin } from './custom/plugins/wordCound'
import { ContinuePlugin } from './custom/plugins/continuation'
import { ReducePlugin } from './custom/plugins/reduce'
import { AiDirectivesPlugin } from './custom/plugins/aiDirectives'
import { AiToolsPlugin } from './custom/plugins/aiTools'
import { useRef } from 'react'
import { TaskListPlugin } from './custom/plugins/taskList'
import { FileUploadPlugin } from './custom/plugins/fileUpload'
import { FormatPlugin } from './custom/plugins/format'
import { CommentMenuPlugin } from './custom/plugins/commentMenu'
import { CommentTextPlugin } from './custom/plugins/commentText'
import { HeaderPlugin } from './custom/plugins/header'
// import { UploadPlugin } from './custom/plugins/upload'
const getDefaultModule = (mountEle, editor): ISylEditorProps['module'] => ({
  toolbar: {
    Ctor: ToolbarLoader,
    option: {
      mount: mountEle,
      tipDirection: 'up',
      menuDirection: 'up',
      className: 'toolbar',
      tools: [
        // FormatPlugin.getName(),
        // FileUploadPlugin.getName(), //上传
        FontFamilyPlugin.getName(), //字体
        UndoPlugin.getName(),
        RedoPlugin.getName(),
        FontSizePlugin.getName(), //字体大小
        HeaderPlugin.getName(), //标题
        BoldPlugin.getName(), //加粗
        ItalicPlugin.getName(), //斜体
        UnderlinePlugin.getName(), //下划线
        EmojiPlugin.getName(), //表情
        ColorPlugin.getName(), //颜色
        // BackgroundPlugin.getName(), //背景色
        BulletListPlugin.getName(), //无序列表
        OrderedListPlugin.getName(), //有序列表
        LinkPlugin.getName(), //链接
        BlockQuotePlugin.getName(), //引用
        TaskListPlugin.getName(), //任务列表
        [
          AlignLeftPlugin.getName(),
          AlignCenterPlugin.getName(),
          AlignRightPlugin.getName(),
          AlignJustifyPlugin.getName(),
        ],
        FormatPlugin.getName(), //清除格式

        {
          name: '更多',
          icon: MoreIcon,
          showName: '更多',
          content: [
            ImagePlugin.getName(), //图片
            VideoPlugin.getName(), //视频
            AudioPlugin.getName(), //音频
            TablePlugin.getName(), //表格

            CodeBlockPlugin.getName(), //代码块

            StrikePlugin.getName(), //删除线
            LineIndentPlugin.getName(), //首行缩进
            // LineHeightPlugin.getName(), //行高
            // SpaceBothPlugin.getName(), //两段缩进
            // SpaceBeforePlugin.getName(), //段前句
            // SpaceAfterPlugin.getName(), //段后句
            // LetterSpacePlugin.getName(), //字间距
            HrPlugin.getName(), //分割线
            FormatPainterPlugin.getName(), //格式刷
          ],
        },
        CharacterCountPlugin.getName(),
      ],
      //工具提示语
      tooltips: {
        [FontFamilyPlugin.getName()]: '字体',
        [RedoPlugin.getName()]: '重做',
        [UndoPlugin.getName()]: '撤销',
        [HeaderPlugin.getName()]: '标题',
        [BulletListPlugin.getName()]: '无序列表',
        [OrderedListPlugin.getName()]: '有序列表',
        [CodeBlockPlugin.getName()]: '代码块',
        [BlockQuotePlugin.getName()]: '引用',
        [BoldPlugin.getName()]: '加粗',
        [ItalicPlugin.getName()]: '斜体',
        [UnderlinePlugin.getName()]: '下划线',
        [StrikePlugin.getName()]: '删除线',
        [FontSizePlugin.getName()]: '字体大小',
        [LetterSpacePlugin.getName()]: '字间距',
        // [BackgroundPlugin.getName()]: '背景色',
        [FormatPainterPlugin.getName()]: '格式刷',
        [FormatClearPlugin.getName()]: '清除格式',
        [FormatPlugin.getName()]: '清除格式',
        [ColorPlugin.getName()]: '颜色',
        [ImagePlugin.getName()]: '图片',
        [VideoPlugin.getName()]: '视频',
        [AudioPlugin.getName()]: '音频',
        [HrPlugin.getName()]: '分割线',
        [LinkPlugin.getName()]: '超链接',
        [TaskListPlugin.getName()]: '任务列表',
        [EmojiPlugin.getName()]: 'emoji表情',
        [TablePlugin.getName()]: '表格',
        [SpaceBeforePlugin.getName()]: '段前距',
        [SpaceAfterPlugin.getName()]: '段后距',
        [SpaceBothPlugin.getName()]: '两端缩进',
        [LineHeightPlugin.getName()]: '行高',
        [LineIndentPlugin.getName()]: '首行缩进',
        [AlignLeftPlugin.getName()]: '左对齐',
        [AlignCenterPlugin.getName()]: '居中对齐',
        [AlignRightPlugin.getName()]: '右对齐',
        [AlignJustifyPlugin.getName()]: '两端对齐',
        [CharacterCountPlugin.getName()]: '字数',
      },
    },
  },
  toolbarInline: {
    Ctor: ToolbarInlineLoader,

    // pop 浮层
    option: {
      zIndex: 999,
      threshold: {
        top: 100,
        // left: editor?.root.firstElementChild.getBoundingClientRect().left,
        // right: editor?.root.firstElementChild.getBoundingClientRect().left,
        bottom: 100,
      },
      menuDirection: 'down',
      tools: [
        // AiDirectivesPlugin.getName(),
        AiToolsPlugin.getName(),
        HeaderPlugin.getName(), //标题
        // FontSizePlugin.getName(), //字体大小
        BoldPlugin.getName(), //加粗
        ItalicPlugin.getName(), //斜体
        UnderlinePlugin.getName(), //下划线
        StrikePlugin.getName(), //删除线
        ColorPlugin.getName(), //颜色
        BlockQuotePlugin.getName(),
        [
          AlignLeftPlugin.getName(),
          AlignCenterPlugin.getName(),
          AlignRightPlugin.getName(),
          AlignJustifyPlugin.getName(),
        ],
        BulletListPlugin.getName(), //无序列表
        OrderedListPlugin.getName(), //有序列表
        CommentTextPlugin.getName(),
        TaskListPlugin.getName(), //任务列表
        // RewritePlugin.getName(),
        // ReducePlugin.getName(),
        // ContinuePlugin.getName(),
        // TranslatePlugin.getName(),
        // ChatGPTPlugin.getName(),
        // ToolsPlugin.getName(),
      ],
      tooltips: {
        [AiDirectivesPlugin.getName()]: 'AI指令',
        [AiToolsPlugin.getName()]: 'AI工具',
        [HeaderPlugin.getName()]: '标题',
        [BulletListPlugin.getName()]: '无序列表',
        [OrderedListPlugin.getName()]: '有序列表',
        [BlockQuotePlugin.getName()]: '引用',
        [BoldPlugin.getName()]: '加粗',
        [ItalicPlugin.getName()]: '斜体',
        [UnderlinePlugin.getName()]: '下划线',
        [StrikePlugin.getName()]: '删除线',
        // [FontSizePlugin.getName()]: '字体大小',
        [LetterSpacePlugin.getName()]: '字间距',
        [ColorPlugin.getName()]: '颜色',
        [HrPlugin.getName()]: '分割线',
        [SpaceBeforePlugin.getName()]: '段前距',
        [SpaceAfterPlugin.getName()]: '段后距',
        [SpaceBothPlugin.getName()]: '两端缩进',
        [LineHeightPlugin.getName()]: '行高',
        [LineIndentPlugin.getName()]: '首行缩进',
        [AlignLeftPlugin.getName()]: '左对齐',
        [AlignCenterPlugin.getName()]: '居中对齐',
        [AlignRightPlugin.getName()]: '右对齐',
        [AlignJustifyPlugin.getName()]: '两端对齐',
        [CharacterCountPlugin.getName()]: '字数',
        [CommentTextPlugin.getName()]: '批注',
        [TaskListPlugin.getName()]: '任务列表',
      },
      trigger: 'hover',
    },
  },
})

export default getDefaultModule
