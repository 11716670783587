import { Plugin } from 'prosemirror-state'
import { ReplaceStep, AddMarkStep, RemoveMarkStep } from 'prosemirror-transform'
import { DOMSerializer } from 'prosemirror-model' // 导入 DOMSerializer
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { SubmitDocumentModifyRecord } from '@/model/reference'
import { v4 as uuidv4 } from 'uuid'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

// 用于存储操作记录的数组
let changeRecords = []
// 记录上一次的光标位置
let lastCursorPos = null
// 从添加更变为删除的标志
let fromInsertToDel = true
// 从删除更变为添加的标志
let fromDelToInsert = true
// 正在输入汉字
let isInputingChinese = false
// 上一次输入是否是中文输入
let isLastInputChinese = false
// 初始化当前文档HTML
let currentDocHTML = ''
// 初始化只执行一次
let isFirstInfo = true
// 中文输入法所输入的字符
let chineseInputEngString = ''
// 是否是撤销操作
let isUndo = false
// 是否是重做操作
let isRedo = false
// updateLastRecordText 更新时，超过一定的时间则保存一次
let updateLastRecordSetTime = null
// updateLastRecordText 更新超过的时间，单位ms
let updateLastRecordTime = 2000
// 保存已发送的数据
let lastSentData = {}
// 是否在保存中
let isSaving = false

/**
 * @description: 监测中文输入法开始
 * */
document.addEventListener('compositionstart', (event) => {
  chineseInputEngString = ''
  // console.log('监测中文输入法开始', event.data)
  isInputingChinese = true
  isLastInputChinese = true
})

/**
 * @description: 监测中文输入法结束
 * */
document.addEventListener('compositionend', (event) => {
  // console.log('监测中文输入法结束:', event.data)
  chineseInputEngString = event.data
  isInputingChinese = false
})

/**
 * 用户撤销/重做操作
 * */
document.addEventListener('keydown', (event) => {
  // 检测 Ctrl+Z 或 Cmd+Z（Windows/Linux: Ctrl+Z，macOS: Cmd+Z）
  if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
    console.log('用户执行了撤销操作')
    isUndo = true
  }

  // 如果需要，还可以检测重做操作（Ctrl+Y 或 Cmd+Shift+Z）
  if (
    (event.ctrlKey && event.key === 'y') ||
    (event.metaKey && event.shiftKey && event.key === 'z')
  ) {
    isRedo = true
    console.log('用户执行了重做操作')
  }
})

/**
 * @description: 获取删除的文本
 *
 * @param {Object} state - 当前编辑器状态
 * @param {number} fromPos - 文本删除的起始位置
 * @param {number} toPos - 文本删除的结束位置
 * @returns {string} - 被删除的文本
 */
function getDeletedText(state, fromPos, toPos) {
  if (fromPos !== toPos) {
    return state.doc.textBetween(fromPos, toPos, '\n')
  }
  return ''
}

/**
 * @description: 获取添加的文本
 *
 * @param {Object} step - 当前的 ReplaceStep
 * @returns {string} - 被添加的文本
 */
function getInsertedText(step) {
  if (step.slice && step.slice.content.size > 0) {
    return step.slice.content.textBetween(0, step.slice.content.size, '\n')
  }
  return ''
}

/**
 * @description: 获取文档的全部内容
 *
 * @param doc - Yjs 文档对象
 * @return {string} - 文档的全部内容
 * */
function getFullDocumentText(doc) {
  return doc.textBetween(0, doc.content.size, '\n')
}

/**
 * @description: 获取文档的 HTML 内容
 *
 * @param doc - Yjs 文档对象
 * @return {string} - 文档的 HTML 内容
 * */
function getDocumentHTML(doc) {
  const fragment = DOMSerializer.fromSchema(doc.type.schema).serializeFragment(
    doc.content,
  )
  const div = document.createElement('div')
  div.appendChild(fragment)
  return div.innerHTML
}

/**
 * @description: 添加新的变更记录
 *
 * @param {Object} userInfo - 用户信息
 * @param {string} type - 变更的类型 ('into' | 'replace' | 'deletion' | 'insertion')
 * @param {string} modify - 变更的文本
 * @param {number} paragraphIndex - 当前段落的索引
 * @param {string} afterModificationData - 修改后的文本
 * @param {string} replaceModify - 被替换的文本（type 为 replace）
 * @returns {void}
 */
function addChangeRecord(
  userInfo: Record<string, any>,
  type: 'into' | 'replace' | 'deletion' | 'insertion',
  modify: string,
  paragraphIndex: number,
  afterModificationData: string,
  replaceModify?: string,
) {
  const { name, id } = userInfo

  let lastRecords = changeRecords[changeRecords.length - 1]
  if (lastRecords && !lastRecords.type) {
    lastRecords.afterModificationData = afterModificationData
    if (replaceModify) lastRecords.replaceModify = replaceModify
    lastRecords.modify = modify
    // lastRecords.userName = name
    // lastRecords.userId = id
    lastRecords.type = type
    lastRecords.paragraphIndex = paragraphIndex
    lastRecords.time = Date.now()
    lastRecords.id = uuidv4()
  } else {
    changeRecords.push({
      id: uuidv4(),
      // userName: name,
      // userId: id,
      type,
      afterModificationData,
      replaceModify: replaceModify || '',
      modify,
      paragraphIndex,
      time: Date.now(),
    })
  }

  // 发送请求
  relaxRequest(userInfo)
}

/**
 * @description: 更新最后一条记录的文本内容
 *
 * @param {Object} userInfo - 用户信息
 * @param {string} type - 变更的类型 ('deletion' | 'insertion')
 * @param {string} modify - 新增的文本，将其附加到最后一条记录中
 * @param {string} afterModificationData - 修改后的文本
 * @param {number} paragraphIndex - 当前段落的索引
 * @returns {void}
 */
function updateLastRecordText(
  userInfo: Record<string, any>,
  type: 'deletion' | 'insertion',
  modify: string,
  afterModificationData: string,
  paragraphIndex: number,
) {
  let lastRecordsText = changeRecords[changeRecords.length - 1]
  if (!lastRecordsText.type) {
    lastRecordsText.type = type
  }
  if (type === 'deletion') {
    lastRecordsText.modify = modify + lastRecordsText.modify
  } else if (type === 'insertion') {
    lastRecordsText.modify = lastRecordsText.modify + modify
  }
  lastRecordsText.afterModificationData = afterModificationData
  lastRecordsText.paragraphIndex = paragraphIndex

  // 清除之前的定时器
  if (updateLastRecordSetTime) {
    clearTimeout(updateLastRecordSetTime)
  }

  // 设置新的定时器
  updateLastRecordSetTime = setTimeout(() => {
    relaxRequest(userInfo)
  }, updateLastRecordTime)
}

/**
 * 深度比较两个对象是否相等
 * @param {Object} obj1 - 第一个对象
 * @param {Object} obj2 - 第二个对象
 * @returns {boolean} - 如果对象相等，返回 true；否则返回 false
 */
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  // Special case: if obj1.data exists and obj2.data exists, check if one is a subset of the other
  // if (Array.isArray(obj1.data) && Array.isArray(obj2.data)) {
  //   // Check if every item in obj1.data matches with some item in obj2.data
  //   const isSubset = obj1.data.every((item1) =>
  //     obj2.data.some((item2) => deepEqual(item1, item2)),
  //   )

  //   // Check if every item in obj2.data matches with some item in obj1.data
  //   const isSubsetReverse = obj2.data.every((item2) =>
  //     obj1.data.some((item1) => deepEqual(item1, item2)),
  //   )

  //   if (isSubset || isSubsetReverse) {
  //     return true
  //   }
  // }

  for (let key of keys1) {
    if (!keys2.includes(key)) return false
    if (!deepEqual(obj1[key], obj2[key])) return false
  }

  return true
}

/**
 * @description: 放松请求
 *
 * @param {Object} userInfo - 用户信息
 * @returns {void}
 * */
const relaxRequest = (userInfo: Record<string, any>) => {
  // 封装好的发送记录
  let editorCollaborativeOperationRecord = encapsulation(
    changeRecords,
    userInfo,
  )

  // 先判断发送的数据是否与上一次发送的数据相同
  if (deepEqual(editorCollaborativeOperationRecord, lastSentData)) return
  else {
    if (
      changeRecords.length > 1 ||
      (changeRecords.length === 1 &&
        changeRecords[changeRecords.length - 1].type)
    ) {
      changeRecords = changeRecords.slice(-1)

      // console.log(changeRecords)

      // new Promise((resolve) => {
      //   setTimeout(() => {
      //     events.emit(ACTIONS.SUBMIT_DOC_HANDEL)
      //     console.log(editorCollaborativeOperationRecord)
      //     resolve('')
      //   }, 2000)
      // })

      SubmitDocumentModifyRecord({
        uuid: editorCollaborativeOperationRecord.uuid,
        usersMessage: editorCollaborativeOperationRecord.usersMessage,
      }).then((res) => {
        // console.log(res)
        console.log(editorCollaborativeOperationRecord.usersMessage)
        if (isSaving) return
        isSaving = true
        events.emit(ACTIONS.SUBMIT_DOC_HANDEL)
        setTimeout(() => {
          isSaving = false
        }, 2000)
      })

      // 更新上一次发送的数据
      lastSentData = editorCollaborativeOperationRecord

      // 清除之前的定时器
      clearTimeout(updateLastRecordSetTime)
    }
    return true
  }
}

/**
 * @description: 数据封装，封装好后可以直接发往后端
 *
 * @param {Array} data - 需要封装的数据
 * @param {Object} userInfo - 用户信息
 * @returns {Object} 封装后的数据
 * */
function encapsulation(data: any[], userInfo: Record<string, any>) {
  // 去掉type为空和into的项
  function addBeforeModificationData(records) {
    // 如果数据大于1，则去掉type为into的记录
    if (data.length > 1) {
      records = records.filter((item) => item.type !== 'into')
    }

    // 去掉type为空的记录
    records = records.filter((item) => item.type)

    return records
  }
  return {
    usersMessage: {
      data: addBeforeModificationData(data),
      userId: userInfo.id,
      userName: userInfo.name,
    },
    uuid: GetQueryValue('uuid'),
  }
}

/**
 * @description: 处理文本变更（删除或插入）
 *
 * @param {(action: { type: string; payload?: any }) => void} dispatch - Redux dispatch 函数
 * @param {Object} userInfo - 用户信息
 * @param {string} modify - 变更的文本
 * @param {string} type - 变更的类型 ('into' | 'replace' | 'deletion' | 'insertion')
 * @param {boolean} shouldMerge - 是否应该合并到上一条记录中
 * @param {number} paragraphIndex - 当前段落的索引
 * @param {number} employeeListTotal - 协同列表总数
 * @param {string} afterModificationData - 修改后的文本
 * @param {string} replaceModify - 被替换的文本（type 为 replace）
 * @param {Function} isLocalUser - 是否是本地用户｜
 * @return {void}
 */
const handleTextChange = (
  dispatch: (action: { type: string; payload?: any }) => void,
  userInfo: Record<string, any>,
  modify: string,
  type: 'into' | 'replace' | 'deletion' | 'insertion',
  shouldMerge: boolean,
  paragraphIndex: number,
  employeeListTotal: number,
  afterModificationData: string,
  replaceModify?: string,
  isLocalUser?: () => any,
) => {
  /**
   * modify：变更的文本
   *
   * @description：所有修改的文本都会被记录下来到modify中，并且除 into、replace 外的所有的操作都需要记录 modify
   * */
  if (modify || type === 'into' || type === 'replace') {
    setTimeout(() => {
      // 如果正在输入中文汉字则不处理
      if (isInputingChinese) return

      // 检查光标是否未移动以决定是否合并
      if (changeRecords.length && shouldMerge) {
        if (type === 'deletion') {
          fromDelToInsert = true
          if (fromInsertToDel) {
            // console.log('111111111111111111111111111')
            addChangeRecord(
              userInfo,
              type,
              modify,
              paragraphIndex,
              afterModificationData,
            )
            fromInsertToDel = false
          } else {
            // console.log('222222222222222222222222222222')
            updateLastRecordText(
              userInfo,
              type,
              modify,
              afterModificationData,
              paragraphIndex,
            )
          }
        } else if (type === 'insertion') {
          fromInsertToDel = true
          if (fromDelToInsert) {
            // console.log('333333333333333333333333333333')
            addChangeRecord(
              userInfo,
              type,
              modify,
              paragraphIndex,
              afterModificationData,
            )
            fromDelToInsert = false
          } else {
            // console.log('444444444444444444444444444444444444')
            updateLastRecordText(
              userInfo,
              type,
              modify,
              afterModificationData,
              paragraphIndex,
            )
          }
        }
      } else {
        if (type === 'replace') {
          console.log('替换文本', isLocalUser())
          if (!isLocalUser() || isUndo) return
          // console.log('5555555555555555555555555555555555555555')
          addChangeRecord(
            userInfo,
            type,
            modify,
            paragraphIndex,
            afterModificationData,
            replaceModify,
          )
        } else {
          // console.log('666666666666666666666666666666666666')
          addChangeRecord(
            userInfo,
            type,
            modify,
            paragraphIndex,
            afterModificationData,
          )
        }
      }

      // 更改撤销操作
      isUndo = false

      // 中文输入时会添加键盘输入的字符，所以需要删除掉
      // if (
      //   (isLastInputChinese && employeeListTotal > 1) ||
      //   (isLastInputChinese &&
      //     employeeListTotal < 2 &&
      //     changeRecords.length > 1)
      // ) {
      //   console.log(
      //     '中文输入法输入的字符被删除了',
      //     isLastInputChinese,
      //     employeeListTotal,
      //   )

      //   fromInsertToDel = true
      //   fromDelToInsert = true

      //   // 删除 changeRecords 最后一条数据
      //   changeRecords.pop()
      //   isLastInputChinese = false
      // }

      // 数据封装完成，下面可直接使用封装好的 changeRecords 数据
      // if (!changeRecords.length) return
      // console.log(
      //   `${
      //     type === 'deletion'
      //       ? 'Deleted'
      //       : type === 'insertion'
      //       ? 'Inserted'
      //       : type === 'into'
      //       ? 'Into'
      //       : type === 'replace'
      //       ? 'Replace'
      //       : ''
      //   } modify:`,
      //   modify,
      // )
      // console.log('Change Records:', changeRecords)
      // console.log('Change Records:', encapsulation(changeRecords, userInfo))
      // dispatch({
      //   type: 'editor-collaborative-operation-record',
      //   payload: {
      //     editorCollaborativeOperationRecord: encapsulation(
      //       changeRecords,
      //       userInfo,
      //     ),
      //   },
      // })
    }, 50)
  }
}

/**
 * @description: 监听文档变化（保存修改记录）
 * @description：通过监听编辑器的 transaction，获取到编辑器的操作记录，然后根据操作记录生成修改记录。
 * @description：isLocalUser需要添加定时器才能获取最新数据
 *
 * @param {(action: { type: string; payload?: any }) => void} dispatch - Redux dispatch 函数
 * @param {any} yjsEditorCoordinationInfo - YjsEditorCoordinationInfo 对象，包含 userInfo 和 employeeList 等信息
 * @param {boolean} isLocalUser - 是否是本地用户
 */
export const trackChangesPlugin = (
  dispatch,
  yjsEditorCoordinationInfo,
  isLocalUser,
) => {
  const {
    employeeList: { total: employeeListTotal },
    userInfo,
  } = yjsEditorCoordinationInfo

  return new Plugin({
    filterTransaction(transaction, state) {
      // 如果事务来自远程用户，跳过处理
      const newCursorPos = transaction.selection.from
      // 修改段落索引
      let paragraphIndex = 0
      // 获取修改后的文档状态
      const afterState = transaction.doc
      // 使用 getDocumentHTML 获取当前文档的 HTML
      const afterStateHTML = getDocumentHTML(transaction.doc)

      /**
       * 单人文档初始化进入添加默认数据，防止单人文档无数据
       *
       * @description: 只有单人文档才会进入，协同文档不会进入
       * */
      if (!changeRecords.length && employeeListTotal < 2) {
        addChangeRecord(userInfo, null, '', paragraphIndex, '')
      }

      // 遍历 transaction 的所有步骤
      transaction.steps.forEach((step) => {
        if (
          step instanceof ReplaceStep ||
          step instanceof AddMarkStep ||
          step instanceof RemoveMarkStep
        ) {
          const fromPos = step.from
          const toPos = step.to

          // 遍历文档中的每个节点，以获取段落的索引
          state.doc.forEach((node, pos) => {
            if (node.isBlock) {
              paragraphIndex++

              // 判断当前位置是否在这个段落范围内
              if (fromPos >= pos && fromPos < pos + node.nodeSize) {
                // 获取添加的文本
                const currentInsertedText = getInsertedText(step)
                // 获取删除的文本
                const currentDeletedText = getDeletedText(state, fromPos, toPos)
                // 获取修改后的全部文档文本
                const afterParagraphText = getFullDocumentText(afterState)

                // console.log(transaction, state, step)
                // console.log('修改后的文本:', afterParagraphText, afterStateHTML)
                // console.log(
                //   currentDeletedText,
                //   '/',
                //   currentInsertedText,
                //   '/',
                //   isLastInputChinese,
                // )
                // console.log('是否是本地用户：', isLocalUser())

                /**
                 * 初始化进入，当文档内容为空时，添加默认数据
                 *
                 * @description: 只有协同文档才会进入，单人文档不会进入
                 * */
                if (!changeRecords.length && currentInsertedText) {
                  // 第一次进入记录当前文档HTML
                  if (isFirstInfo) {
                    currentDocHTML = afterStateHTML
                    isFirstInfo = false
                  }
                  // console.log(
                  //   '初始化进入，当文档内容为空时，添加默认数据',
                  //   // currentDocHTML,
                  // )
                  addChangeRecord(userInfo, null, '', paragraphIndex, '')
                } else {
                  // 判断是否是替换操作（既有删除又有插入）
                  if (
                    currentDeletedText &&
                    currentInsertedText &&
                    !isInputingChinese
                    // !isLastInputChinese
                  ) {
                    // console.log(
                    //   '替换操作',
                    //   currentDeletedText,
                    //   currentInsertedText,
                    // )
                    handleTextChange(
                      dispatch,
                      userInfo,
                      currentInsertedText,
                      'replace',
                      false,
                      paragraphIndex,
                      employeeListTotal,
                      afterStateHTML,
                      currentDeletedText,
                      isLocalUser,
                    )
                  } else {
                    if (!currentInsertedText && !currentDeletedText) return

                    // 添加操作
                    if (currentInsertedText) {
                      setTimeout(() => {
                        // console.log('正在输入汉字', isInputingChinese)
                        if (isInputingChinese) return
                        console.log(
                          '添加操作',
                          currentInsertedText,
                          '-',
                          chineseInputEngString,
                        )
                        handleTextChange(
                          dispatch,
                          userInfo,
                          chineseInputEngString
                            ? chineseInputEngString
                            : currentInsertedText,
                          'insertion',
                          true,
                          paragraphIndex,
                          employeeListTotal,
                          afterStateHTML,
                        )
                        chineseInputEngString = ''
                      }, 10)
                    } else if (currentDeletedText) {
                      // 删除操作
                      if (currentDeletedText && !isInputingChinese) {
                        // console.log('删除操作', currentDeletedText)
                        handleTextChange(
                          dispatch,
                          userInfo,
                          currentDeletedText,
                          'deletion',
                          true,
                          paragraphIndex,
                          employeeListTotal,
                          afterStateHTML,
                        )
                      } else {
                        // console.log('输入中文时回车操作', chineseInputEngString)
                        handleTextChange(
                          dispatch,
                          userInfo,
                          chineseInputEngString,
                          'insertion',
                          true,
                          paragraphIndex,
                          employeeListTotal,
                          afterStateHTML,
                        )
                      }
                    }
                  }
                }
                // 更新光标位置
                lastCursorPos = newCursorPos
              }
            }
          })
        }
      })

      // 处理光标移动，保存当前的删除记录
      if (lastCursorPos !== null && newCursorPos !== lastCursorPos) {
        // console.log('光标移动')

        // 每次光标移动，添加新的记录
        if (changeRecords[changeRecords.length - 1]?.type) {
          addChangeRecord(userInfo, null, '', paragraphIndex, '')
        }
        // 更新光标位置为新的位置
        lastCursorPos = newCursorPos
      }

      // 继续处理 transaction
      return true
    },
  })
}
