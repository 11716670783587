import React, { useEffect, useRef, useState } from 'react'
import styles from '../templatePage/style/golbal-tem.module.less'
import StepHeaderMod from './StepHeaderMod'
import WriteFormMod from './WriteFormMod'
import EmptyMod from '../templatePage/TemEmptyMod'
import WriteRightMod from './WriteRightMod'
import WriteFormResultMod from './WriteFormResultMod'
// import Icon1 from '../../assets/imgs/h-i1.svg'
import { GetQueryValue } from '@/utils/common'
import { Message, Spin } from '@arco-design/web-react'
import { IconErrorTip, IconWarnTip } from '@arco-iconbox/react-aidb-v2'
import { getUserInfo } from '@/model/reference'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import request from '@/utils/request'
import Markdown from '@/components/Markdown'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import Modal from '@/components/Modal/index'

function TemAllText(props) {
  // 从localStorage中获取缓存的模板数据
  const cachedTemplateData = localStorage.getItem('AllTextTem')
  // 解析JSON数据
  const initialTemplateData = cachedTemplateData
    ? JSON.parse(cachedTemplateData)
    : null
  // 新增一个状态用于缓存模板数据
  const [templateData, setTemplateData] = useState(initialTemplateData)
  const theme = useSelector((state: GlobalState) => state.theme)
  const curStepRef = useRef(0)
  const doneStepRef = useRef(0)
  // 新增一个状态用于标记是否后退
  const [listTitle, setListTitle] = useState<any>([]) //标题数据
  const [listIntroduction, setListIntroduction] = useState<any>([]) //简介数据
  const [listOutline, setListOutline] = useState<any>([]) //大纲数据
  const [allText, setAllText] = useState<any>([])
  const [forceUpdate, setForceUpdate] = useState(false) // 新增辅助状态
  const [sendSucces, setSendSucces] = useState(false) //loading
  const [allLoading, setAllLoading] = useState(true)
  const [selectedItemName, setSelectedItemName] = useState(null)
  const [resultDone, setResultDone] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  const [ismarkDownCur, setIsMarkDownCur] = useState(false)

  // 定义回调函数，用于接收子组件传递的 item.name
  const handleSelectedItem = (itemName) => {
    setSelectedItemName(itemName)
  }
  // 定义状态来存储会员卡类型
  const [membershipType, setMembershipType] = useState<any>(
    localStorage.getItem('vipLevel'),
  )
  const [uid, setUid] = useState(null)
  useEffect(() => {
    if (!isLogin) {
      getUserInfo({ uid: '' }).then((res) => {
        console.log(res)
        setIsLogin(true)
        setMembershipType(res.data.data.vipLevel)
        setUid(res.data.data.uid)
      })
    }
  }, [isLogin])

  useEffect(() => {
    document.title = '全文写作 - 悟智AI'
  }, [])

  useEffect(() => {
    if (GetQueryValue('source')?.length > 0) {
      //直接访问链接外部md情况
      if (GetQueryValue('source').slice(0, 4) == 'http') {
        const fetchData = async () => {
          try {
            const response = await request.get(
              `https://cors.v-dk.com/${GetQueryValue('source')}`,
            )
            doneStepRef.current = 4
            curStepRef.current = 4
            const resdata = response.data
            localStorage.setItem('selectTitle', resdata.split('\n')[0])
            // localStorage.setItem('selectIntro', resdata.split('\n')[0])
            localStorage.setItem('selectOutLine', resdata)
            setIsMarkDownCur(true)
            setTimeout(() => {
              events.emit(ACTIONS.TEMALLMARKDOWN)
            }, 200)
          } catch (error) {
            console.error('Error fetching Markdown content:', error)
          }
        }
        fetchData()
      }
      // 脑图模版添加
      else {
        const fetchData = async () => {
          try {
            const response = await request.post(
              '/api/api-aidb/chatGPT/getChatGptDataByMessageID',
              { messageId: GetQueryValue('source') },
            )
            doneStepRef.current = 4
            curStepRef.current = 4
            const resdata = response.data.data.output
            console.log(resdata)

            localStorage.setItem('selectTitle', resdata.split('\n')[0])
            // localStorage.setItem('selectIntro', resdata.split('\n')[0])
            localStorage.setItem('selectOutLine', resdata)
            setIsMarkDownCur(true)
            setTimeout(() => {
              events.emit(ACTIONS.TEMALLMARKDOWN)
            }, 200)
          } catch (error) {
            console.error('Error fetching Markdown content:', error)
          }
        }
        fetchData()
      }
    }
  }, [])
  //更新进度条的状态
  function jumpTo(cur, type) {
    if (ismarkDownCur) {
      return
    } else {
      if (type == 'header') {
        if (!resultDone) {
          Message.warning({
            icon: <IconWarnTip useCurrentColor={false} />,
            content: '请先等待数据加载完毕',
          })
        } else {
          if (cur <= doneStepRef.current) {
            curStepRef.current = cur
            setForceUpdate(!forceUpdate)
          } else {
            // 如果点击的步骤大于已完成的步骤，则不允许跳转，或者可以给用户一些提示
            // 这里可以根据实际需求进行处理
            Message.warning({
              icon: <IconWarnTip useCurrentColor={false} />,
              content: '请先完成当前步骤',
            })
            return
          }
        }
      } else {
        curStepRef.current = cur
        doneStepRef.current = cur
        setForceUpdate(!forceUpdate)
      }
    }
  }

  // 回调函数用于更新sendSucces状态
  function handleSendSuccessChange(value) {
    setSendSucces(value)
  }

  //接口成功且执行完毕可以调整步骤条
  function handleResult(value) {
    setResultDone(value)
  }

  //全文写作的按钮状态
  function handleSetAllLoading(value) {
    setAllLoading(value)
  }
  console.log(selectedItemName)

  //更新值
  function handleUpdateCurStep(newTxt) {
    console.log(newTxt, 'newTxt')
    // 去掉头部和尾部的```json和```
    newTxt = newTxt
      .replace(/```json/, '')
      .replace(/```/, '')
      .trim()

    if (selectedItemName == '添加信息') {
      setListTitle([])
      setListIntroduction([])
      setListOutline([])
    }
    if (curStepRef.current == 1) {
      if (selectedItemName == '添加信息') {
        setListTitle([])
        setListTitle([newTxt])
        setListIntroduction([])
        setListOutline([])
      } else {
        if (newTxt[0] == '[') {
          setListTitle([newTxt, ...listTitle])
          setListIntroduction([])
          setListOutline([])
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '接口返回格式错误，请重新尝试生成',
          })
        }
      }
    } else if (curStepRef.current == 2) {
      if (newTxt[0] == '[') {
        setListIntroduction([newTxt, ...listIntroduction])
        setListOutline([])
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '接口返回格式错误，请重新尝试生成',
        })
      }
    } else if (curStepRef.current == 3) {
      setListOutline([newTxt.replaceAll('\\n', ' \n '), ...listOutline])
    } else if (curStepRef.current == 4) {
      setAllText(newTxt)
    }
    setForceUpdate(!forceUpdate)
  }

  useEffect(() => {
    localStorage.setItem('selectTitle', '')
    localStorage.setItem('selectIntro', '')
    localStorage.setItem('selectOutLine', '')
    const handleBeforeUnload = () => {
      // 检查本地存储是否存在特定的项，如果存在则清空
      localStorage.removeItem('selectTitle')
      localStorage.removeItem('selectIntro')
      localStorage.removeItem('selectOutLine')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <>
      {isLogin && (
        <div className={`${styles.writePage} ${styles[theme]}`}>
          <StepHeaderMod
            membershipType={membershipType}
            curStep={curStepRef.current}
            jumpTo={(cur, type) => jumpTo(cur, type)}
            title={templateData.title}
            step={templateData.step}
            onItemSelected={handleSelectedItem}
            theme={theme}
            ismarkDownCur={ismarkDownCur}
          />
          <div className={styles.content}>
            <div className={styles.content_left}>
              {
                <div
                  style={{
                    display: curStepRef.current < 4 ? 'block' : 'none',
                  }}>
                  <WriteFormMod
                    theme={theme}
                    membershipType={membershipType}
                    title={templateData.info}
                    curStep={curStepRef.current}
                    jumpTo={(cur, type) => jumpTo(cur, type)}
                    templateData={templateData}
                    onUpdateCurStep={handleUpdateCurStep}
                    onSendSuccessChange={handleSendSuccessChange}
                    onResult={handleResult}
                    onSetAllLoading={handleSetAllLoading}
                    uid={uid}
                  />
                </div>
              }
              {curStepRef.current == 4 && (
                <WriteFormResultMod
                  ismarkDownCur={ismarkDownCur}
                  allLoading={allLoading}
                  theme={theme}
                />
              )}
            </div>
            <div className={styles.content_right}>
              {curStepRef.current != 0 && sendSucces == false ? (
                <div className={styles.spinLoading}>
                  {' '}
                  <Spin tip="模型思考中，请稍作等待..." dot />
                </div>
              ) : (
                ''
              )}

              {curStepRef.current == 0 && (
                <EmptyMod
                  icon={
                    <img
                      style={{ width: '135px', height: '140px' }}
                      src="https://oss.aidb.com.cn/pg/202308171653617.svg"
                    />
                  }
                  text={
                    <div>
                      <p>请填写内容或要求</p>
                      <p> AI 为您智能生成全文写作</p>
                    </div>
                  }
                />
              )}
              {curStepRef.current != 0 && sendSucces == true && (
                <WriteRightMod
                  curStep={curStepRef.current}
                  listTitle={listTitle}
                  listIntroductio={listIntroduction}
                  listOutline={listOutline}
                  allText={allText}
                  jumpTo={(cur, type) => jumpTo(cur, type)}
                  allLoading={allLoading}
                  theme={theme}
                  ismarkDownCur={ismarkDownCur}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal></Modal>
    </>
  )
}

export default TemAllText
