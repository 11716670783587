import React from 'react'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import { switchProject } from '@/helpers/switchProject'
import { showModal } from '@/share/actions'

/**
 * 错误异常处理
 * eventSource：推流fetchEventSource返回
 * catchGPTError：异常处理函数（异常触发后执行）
 * errorData：错误text信息
 * errorId：错误id信息
 */
export const plugFlowError = (
  eventSource,
  catchGPTError,
  errorData,
  errorId,
) => {
  if (switchProject('AIDB')) {
    if (errorData == '[NO_PERMISSION]') {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '您还不是 SVIP 用户，请开通 SVIP 继续使用#00-0',
      })
      setTimeout(() => {
        showModal('VipContentModal')
        window.location.hash = 'vip'
      }, 1000)
      catchGPTError(eventSource)
      throw new Error('您还不是 SVIP 用户，请开通 SVIP 继续使用#00-0')
    }

    if (errorData == '[OUT_OF_MIN_FLOW]') {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '1分钟内免费次数已用完，请稍后再试或开通会员#01-0',
      })
      setTimeout(() => {
        showModal('VipContentModal')
        window.location.hash = 'vip'
      }, 1000)
      catchGPTError(eventSource)
      throw new Error('1分钟内免费次数已用完，请稍后再试或开通会员#01-0')
    }

    if (errorData == '[OUT_OF_MIN_FLOW_VIP]') {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '1分钟内 VIP 次数已用完，请稍后再试#01-1',
      })
      catchGPTError(eventSource)
      throw new Error('1分钟内 VIP 次数已用完，请稍后再试#01-1')
    }

    if (errorData == '[OUT_OF_DAY_FLOW]') {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '今日免费次数已用完，请明日再试或开通会员#02-0',
      })
      setTimeout(() => {
        showModal('VipContentModal')
        window.location.hash = 'vip'
      }, 1000)
      catchGPTError(eventSource)
      throw new Error('今日免费次数已用完，请明日再试或开通会员#02-0')
    }

    if (errorData == '[OUT_OF_DAY_FLOW_VIP]') {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '今日 VIP 次数已用完，请明日再试#02-1',
      })
      catchGPTError(eventSource)
      throw new Error('今日 VIP 次数已用完，请明日再试#02-1')
    }
  }

  if (errorData == '[TOO_MANY_REQUESTS]') {
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: '请勿频繁请求#03-0',
    })
    catchGPTError(eventSource)
    throw new Error('请勿频繁请求#03-0')
  }

  //备用
  if (errorData == '[ERROR]') {
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: '接口返回错误，请稍后再试#03-1',
    })
    catchGPTError(eventSource)
    throw new Error('接口返回错误，请稍后再试#03-1')
  }

  if (errorData == '[OUT_OF_3H_FLOW]') {
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: '超出3小时控流限制#03-2',
    })
    catchGPTError(eventSource)
    throw new Error('超出3小时控流限制#03-2')
  }

  if (errorData == '[ERROR]' && errorId == 0) {
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: '描述含有敏感词，请调整描述语句后再试#04-0',
    })
    catchGPTError(eventSource)
    throw new Error('描述含有敏感词，请调整描述语句后再试#04-0')
  }
}
