import React, { useRef, useEffect, useState } from 'react'
import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Message,
  FormInstance,
} from '@arco-design/web-react'
import styles from '@/style/index.module.less'
import style from './styles/index.module.less'
import { IconLock, IconUser } from '@arco-design/web-react/icon'
import WxLogo from '@/assets/imgs/wxLogo.svg'
import AliyunLogin from '../../components/AliyunCheck/aliyunLogin'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import md5 from 'md5'
import request from '@/utils/request'
import { GetQueryValue } from '@/utils/common'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { showModal } from '@/share/actions'
import { useHistory } from 'react-router-dom'
let captcha
export default function EmailLogin({ handleTabChange, getTemJson }) {
  const [loading, setLoading] = useState(false)
  const userNameRef = useRef(localStorage.getItem('email'))
  const emailLoginForm = useRef()
  const [form] = Form.useForm()
  const history = useHistory()
  //跳转忘记密码
  function goForgot() {
    history.push('/forgotpwd')
  }

  //跳转注册页面
  function onRegister() {
    history.push('/register')
  }

  function onSubmitClick() {
    emailLoginForm.current
      .validate()
      .then((values) => {
        console.log(values)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    const loadScript = async () => {
      // 创建一个 script 标签并设置 src
      const script = document.createElement('script')
      script.src =
        'https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js'
      script.async = true

      // 定义加载脚本后的回调函数
      script.onload = initCaptcha

      // 将 script 标签添加到 <head>
      document.head.appendChild(script)
    }

    loadScript()
  }, [])

  function afterLoginSuccess(params) {
    // 记录登录状态
    localStorage.setItem('userStatus', 'login')
    localStorage.setItem('tabSwitch', 'card')
    localStorage.setItem('docTypeKey', '我的文档')

    // 跳转首页
    if (window.screen.width < 450) {
      window.location.href = '/toolbox/index'
    } else {
      if (localStorage.getItem('redirectUrl')?.length > 0) {
        getTemJson()
      } else {
        // history.push('/home')
        location.href = '/home'
      }
    }
  }
  const initCaptcha = async () => {
    initAliyunCaptcha({
      SceneId: 'zn6y4dss', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: 'kh0001', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'embed', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element-login', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#passwordBtn', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: window.screen.width > 450 ? 400 : 350,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    })
  }

  // 绑定验证码实例函数。该函数为固定写法，无需修改
  function getInstance(instance) {
    captcha = instance
  }

  const captchaVerifyCallback = async (captchaVerifyParam) => {
    setLoading(true)
    const result = await new Promise((resolve, reject) => {
      request
        .post('/api/api-sso/login/aliSmsCode', {
          captchaVerifyParam: captchaVerifyParam,
          username: emailLoginForm?.current?.getFieldsValue().userName, //手机号/邮箱/用户名
          userType: 3, //请求类型（1-手机发送验证码、2-邮箱发送验证码、3、用户名密码登录））
          password: md5(
            emailLoginForm?.current?.getFieldsValue().password.trim() +
              'avc154*gtd45rge3',
          ),
        })
        .then((res) => {
          // 假设你的结果中的数据包含在 res.data 中
          const result = res.data
          resolve({
            captchaResult: true,
            bizResult: result.success,
          })
        })
        .catch((error) => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: error.response.data.message,
          })
          resolve({
            captchaResult: true,
            bizResult: error.response.status == 200 ? true : false,
            errorMessage: error.response.data.message,
          })
        })
    })

    // 2.构造标准返回参数
    const verifyResult = {
      // captchaResult: result.captchaVerifyResult, // 验证码验证是否通过，boolean类型，必选
      captchaResult: true, // 验证码验证是否通过，boolean类型，必选
      bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
    }
    return verifyResult
  }

  const onBizResultCallback = (bizResult) => {
    if (bizResult === true) {
      setLoading(false)
      emailLoginForm.current
        .validate()
        .then((values) => {
          setLoading(true)
          getUser()
        })
        .catch((error) => {
          document.getElementById('captcha-element-login').style.display =
            'none'

          showModal('ConfirmModal', {
            title: '请阅读并同意我们的条款',
            content: (
              <div>
                您还未点击“阅读并同意
                <Link
                  target="_blank"
                  hoverable={false}
                  href="https://go.wuz.com.cn/10fa">
                  《服务协议》
                </Link>
                和
                <Link
                  target="_blank"
                  hoverable={false}
                  href="https://go.wuz.com.cn/99e3">
                  《隐私政策》
                </Link>
                ”。为了继续，请先阅读并同意我们的条款。
              </div>
            ),
            attribute: 'login',
            onConfirm: async () => {
              setLoading(true)
              getUser()
              form.setFieldsValue({
                check: true,
              })
            },
            onCancel: async () => {
              document.getElementById('captcha-element-login').style.display =
                'block'
            },
          })
        })
    } else {
      setLoading(false)
    }
  }

  function submitChnage() {
    emailLoginForm.current
      .validate(['check'])
      .then((values) => {
        handleTabChange('wechat')
      })
      .catch((error) => {
        console.log(error)
        if (
          form.getFieldValue('check') === false ||
          form.getFieldValue('check') == null
        ) {
          showModal('ConfirmModal', {
            title: '请阅读并同意我们的条款',
            content: (
              <div>
                您还未点击“阅读并同意
                <Link
                  target="_blank"
                  hoverable={false}
                  href="https://go.wuz.com.cn/10fa">
                  《服务协议》
                </Link>
                和
                <Link
                  target="_blank"
                  hoverable={false}
                  href="https://go.wuz.com.cn/99e3">
                  《隐私政策》
                </Link>
                ”。为了继续，请先阅读并同意我们的条款。
              </div>
            ),
            attribute: 'login',
            onConfirm: async () => {
              form.setFieldsValue({
                check: true,
              })
              submitChnage()
            },
            onCancel: async () => {
              console.log(1)
            },
          })
        }
      })
  }

  function getUser() {
    // 记录登录状态
    localStorage.setItem('userStatus', 'login')
    request
      .post('/api/api-permission/employee/getUserInfo', { uid: '' })
      .then((res) => {
        localStorage.setItem('positionId', res.data.data.positionId)
        const vipEndDate = new Date(res.data.data.vipEndDate)
        localStorage.setItem('userName', res.data.data.name)
        localStorage.setItem('vipEndDate', res.data.data.vipEndDate)
        localStorage.setItem('vipDay', res.data.data.vipDay)
        localStorage.setItem('vipLevel', res.data.data.vipLevel)
        localStorage.setItem('vipToSvipDay', res.data.data.vipToSvipDay)
        localStorage.setItem('userId', res.data.data.id)
        const currentDate = new Date()
        if (currentDate > vipEndDate) {
          localStorage.setItem('isVip', 'false')
        } else {
          localStorage.setItem('isVip', 'true')
        }
        // 跳转首页
        afterLoginSuccess('')
      })
  }

  return (
    <div>
      <Form
        form={form}
        className={styles['login-form']}
        layout="vertical"
        ref={emailLoginForm}>
        <div className={styles.input}>
          <Form.Item
            field="userName"
            rules={[{ required: true, message: '邮箱/手机/用户名不能为空' }]}>
            <Input
              prefix={<IconUser />}
              onPressEnter={onSubmitClick}
              ref={userNameRef}
              placeholder="请输入邮箱/手机/用户名"
            />
          </Form.Item>
          <Form.Item
            field="password"
            rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password
              prefix={<IconLock />}
              onPressEnter={onSubmitClick}
              placeholder="请输入密码"
            />
          </Form.Item>
        </div>

        <Space size={16} direction="vertical">
          <div className={styles.btn}>
            <Button
              type="primary"
              long
              onClick={onSubmitClick}
              id="passwordBtn"
              loading={loading}>
              登录
            </Button>
          </div>
          <div id="captcha-element-login"></div>
          <div className={styles['login-form-password-actions']}>
            <div className={style.registerGo}>
              <span className={style.text1}>还没有账号?</span>
              <span className={style.text2} onClick={onRegister}>
                立即注册
              </span>
            </div>
            <div className={styles.forgotbtn}>
              <Link onClick={goForgot}>忘记密码</Link>
            </div>
          </div>

          <div className={styles.btn} style={{ marginTop: '-10px' }}>
            <div className={style.registerBtn1}>
              <Form.Item
                field="check"
                triggerPropName="checked"
                rules={[
                  {
                    validator: (v, cb) => {
                      if (v !== true) {
                        return cb('请勾选并同意本协议')
                      }
                    },
                  },
                ]}>
                <Checkbox>
                  阅读并同意{' '}
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/10fa">
                    《服务协议》
                  </Link>
                  和
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/99e3">
                    《隐私政策》
                  </Link>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          {window.screen.width > 450 ? (
            <>
              <div className={style.other}>其他</div>
              <div>
                <div className={style.item} onClick={submitChnage}>
                  <WxLogo></WxLogo>
                  微信登录
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </Space>
      </Form>
    </div>
  )
}
