import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import styles from './style/index.module.less'
import SideBar from '../SideBar'
import { GlobalState } from '../../store'
import lazyload from '../../utils/lazyload'
import { useSelector, useDispatch } from 'react-redux'
import useRoute from '@/routes'
import { MENUS } from '@/share/config'
import Modal from '@/components/Modal/index'
import Header from '../Header'
import SideBar1 from '../SideBar1'
import '@/style/editorDefault.less'
import '@/style/lib.less'
import { showModal } from '@/share/actions'
import request from '@/utils/request'

const Layout = () => {
  const { userInfo, theme } = useSelector((state: GlobalState) => state)
  const [headerVisible, setHeaderVisible] = useState(false)
  const [routes, defaultRoute] = useRoute(userInfo?.permissions)
  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes])
  const dispatch = useDispatch()
  // 从localStorage中获取缓存的模板数据
  const cachedTemplateData = localStorage.getItem('templateData')
  // 解析JSON数据
  const initialTemplateData = cachedTemplateData
    ? JSON.parse(cachedTemplateData)
    : null
  // 新增一个状态用于缓存模板数据
  const [templateData, setTemplateData] = useState(initialTemplateData)
  const [list, setList] = useState<any>(initialTemplateData)
  const theme1 = localStorage.getItem('theme')
  document.documentElement.setAttribute('data-theme', theme1 || 'light')
  useEffect(() => {
    headerVisibleHandle()
    if (location.hash == '#vip') {
      showModal('VipContentModal')
    } else if (location.hash == '#draw') {
      showModal('AiDrawModal')
    } else if (location.hash == '#template') {
      showModal('TemplateModal', {
        templateData: templateData,
      })
    } else if (location.hash == '#addDocTem') {
      showModal('AddTemplateModal', {
        Modaltitle: '模版库新建文档',
        title: '模版库新建文档',
      })
    } else if (location.hash == '#FolderCoverModal') {
      showModal('FolderCoverModal', {
        Modaltitle: '新建文件夹',
        title: '',
      })
    }
  }, [])
  function getFlattenRoutes(routes) {
    const res = []
    function travel(_routes) {
      _routes.forEach((route) => {
        const visibleChildren = (route.children || []).filter(
          (child) => !child.ignore,
        )
        if (route.key && (!route.children || !visibleChildren.length)) {
          try {
            route.component = lazyload(() => import(`../../pages/${route.key}`))
            res.push(route)
          } catch (e) {
            console.error(e)
          }
        }
        if (route.children && route.children.length) {
          travel(route.children)
        }
      })
    }
    travel(routes)
    return res
  }

  const headerVisibleHandle = () => {
    MENUS.forEach((i) => {
      if (i.path == location.pathname) {
        setHeaderVisible(i.headerVisible)
      }
    })
  }

  //模版库
  function isCacheExpired() {
    const cachedTime = localStorage.getItem('cachedTime') // 从缓存中获取时间戳
    if (!cachedTime) {
      return true // 如果没有缓存时间，说明缓存不存在，需要重新请求数据
    }
    const currentTime = Date.now() // 获取当前时间的时间戳
    const differenceInHours =
      (currentTime - parseInt(cachedTime, 10)) / (1000 * 60 * 60) // 计算时间差，单位是小时
    return differenceInHours >= 24 // 如果时间差超过等于24小时，则返回true，否则返回false
  }
  useEffect(() => {
    const isCacheExpired1 = isCacheExpired() // 检查缓存是否过期
    if (!templateData || isCacheExpired1) {
      let listArr = []
      new Promise((resolve, reject) => {
        request
          .get('https://support.wuz.com.cn/wp-json/acf/v3/options/options')
          .then(async (res) => {
            if (JSON.stringify(res.data.acf) != '{}') {
              const acf = res.data.acf
              const promptsCatArray = acf['templates_cat'].split('\r\n')
              const promptsCatNameArray =
                acf['templates_cat_name'].split('\r\n')
              const promptsFieldArray = acf['templates_common_field']
              const promptArray = acf['templates_common_field_prompt']
              promptsCatArray.forEach((item, index) => {
                listArr.push({
                  id: index,
                  title: item,
                  category: promptsCatNameArray[index],
                  Heightprompt: promptArray,
                  Heightcommon: promptsFieldArray,
                  children: [],
                })
              })
              await (listArr.length && resolve(''))
            }
          })
      }).then(() => {
        request
          .get(
            'https://support.wuz.com.cn/wp-json/acf/v3/template?per_page=999&orderby=menu_order',
          )
          .then((res) => {
            const result = res.data
            //存储ai绘画json
            const aiImg = result.filter((id) => id.id == 605)
            localStorage.setItem('aiImg', JSON.stringify(aiImg))

            if (JSON.stringify(result.acf) != '{}') {
              result.forEach((item1, index1) => {
                item1.acf.cat.forEach((item2, index2) => {
                  listArr.forEach((item3, index3) => {
                    if (item2 == item3.category) {
                      item3.children.push({
                        uuid: item1.id,
                        title: item1.acf.title,
                        info: item1.acf.intro,
                        type: item1.acf.cat,
                        icon: item1.acf.icon,
                        step: item1.acf.step,
                        prompt: item1.acf.prompt,
                        is_use_common_field: item1.acf.is_use_common_field,
                        online: item1.acf.online,
                      })
                    }
                  })
                })
              })

              listArr.forEach((item, index) => {
                item.id = index
                item.children.forEach((item1, index1) => {
                  item1.id = index1
                })
              })

              setTemplateData(listArr)
              setList(listArr)
              localStorage.setItem('templateData', JSON.stringify(listArr))
              localStorage.setItem('cachedTime', String(Date.now()))

              //全文写作模版单独存储
              const filterAll = listArr[0].children.filter(
                (id) => id.uuid == 715,
              )
              const AllTextTem = {
                Heightcommon: listArr[0].Heightcommon,
                Heightprompt: listArr[0].Heightprompt,
                title: filterAll[0].title,
                info: filterAll[0].info,
                icon: filterAll[0].icon,
                id: filterAll[0].uuid,
                type: filterAll[0].type,
                step: filterAll[0].step,
                prompt: filterAll[0].prompt,
                is_use_common_field: filterAll[0].is_use_common_field,
              }
              localStorage.setItem('AllTextTem', JSON.stringify(AllTextTem))
            }
          })
      })
    }
  }, [])
  return (
    <div
      className={
        window.screen.width < 450 ? styles['phoneLayout'] : styles['pageLayout']
      }>
      {window.screen.width < 450 ? (
        ''
      ) : (
        <div className={styles['sideNav']}>
          <SideBar templateData={templateData}></SideBar>
        </div>
      )}
      {window.screen.width < 450 ? (
        ''
      ) : (
        <div className={styles['header']}>
          <Header></Header>
        </div>
      )}
      <div
        className={`${styles['contain']} ${styles[theme]} ${
          window.screen.width < 450 && styles['contain-phone']
        }`}>
        <Switch>
          {flattenRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={`/${route.key}`}
                component={route.component}
              />
            )
          })}
          <Route exact path="/">
            <Redirect to={`/${defaultRoute}`} />
          </Route>
          <Route
            path="*"
            component={lazyload(
              () => import('../../pages/exception/403/index'),
            )}
          />
        </Switch>
      </div>
      <Modal></Modal>
    </div>
  )
}

export default Layout
