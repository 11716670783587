import React, { ReactElement, useState, useEffect } from 'react'
import { Layout, Tooltip } from '@arco-design/web-react'
import { IconCloseMeun, IconExpandMeun } from '@arco-iconbox/react-aidb-v2'
import {
  EDITOR_LAYOUT_STRETCH_DEFAULT_WIDTH,
  EDITOR_LAYOUT_STRETCH_MAX_WIDTH,
  EDITOR_LAYOUT_STRETCH_MIN_WIDTH,
} from '@/config/editor-config'
import FolderTree from '@/components/FolderTree'
import { debounce } from 'lodash'

import './style/sidebar.less'

const Sider = Layout.Sider
const Content = Layout.Content

interface Props {
  sidebarRef?: any
  updateTreeList?: any
  content: ReactElement<any, any>
  getDocDetailHandle?: () => void
}

const Sidebar = (props: Props) => {
  const { sidebarRef, content, updateTreeList, getDocDetailHandle } = props

  // 当前目录是否展开
  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage.getItem('editor-sider-tree-directory-isOpen')) ||
      false,
  )
  // 目录初始化宽度
  const [editorSiderTreeWidth, setEditorSiderTreeWidth] = useState(
    EDITOR_LAYOUT_STRETCH_DEFAULT_WIDTH,
  )

  // 记住树目录打开/关闭
  useEffect(() => {
    localStorage.setItem(
      'editor-sider-tree-directory-isOpen',
      JSON.stringify(collapsed),
    )
  }, [collapsed])

  // 监听编辑器树目录拉伸变化，并且初始化时回到之前位置
  useEffect(() => {
    // 获取目录元素
    const siderElement = document.querySelector(
      '.editor-sider-tree-directory',
    ) as HTMLElement

    // 如果本地存储中有宽度数据，初始化时先设置该宽度
    const savedWidth = localStorage.getItem('editor-sider-tree-directory-width')

    if (savedWidth) {
      setEditorSiderTreeWidth(Number(savedWidth))

      if (siderElement) {
        siderElement.style.width = `${Number(savedWidth)}px`
      }
    }

    if (siderElement) {
      const observer = new MutationObserver(
        debounce(() => {
          const newWidth = siderElement.offsetWidth
          if (newWidth !== editorSiderTreeWidth) {
            setEditorSiderTreeWidth(newWidth)
            localStorage.setItem(
              'editor-sider-tree-directory-width',
              newWidth.toString(),
            )
          }
        }, 50),
      )

      // 延迟启动 MutationObserver 以避免覆盖初始化宽度
      const timeoutId = setTimeout(() => {
        observer.observe(siderElement, {
          attributes: true,
          attributeFilter: ['style'],
        })
      }, 100) // 延迟100毫秒启动观察器，可以根据需要调整此值

      return () => {
        clearTimeout(timeoutId)
        observer.disconnect()
      }
    }
  }, [])

  return (
    <Layout className="layout-collapse-demo">
      <Sider
        className={'editor-sider-tree-directory'}
        collapsed={collapsed}
        collapsible
        trigger={null}
        breakpoint="xl"
        resizeDirections={collapsed ? null : ['right']}
        width={`${editorSiderTreeWidth}px`}
        style={{
          minWidth: !collapsed && EDITOR_LAYOUT_STRETCH_MIN_WIDTH,
          maxWidth: !collapsed && EDITOR_LAYOUT_STRETCH_MAX_WIDTH,
        }}>
        <div ref={sidebarRef}>
          <div
            className="topContent"
            style={{
              padding: collapsed ? 0 : '0 20px',
              justifyContent: collapsed ? 'center' : 'space-between',
            }}>
            {collapsed ? (
              <Tooltip content={'展开'}>
                <IconExpandMeun
                  style={{ cursor: 'pointer' }}
                  onClick={() => setCollapsed(false)}
                  className="icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip content={'收起'}>
                  <IconCloseMeun
                    style={{ cursor: 'pointer' }}
                    onClick={() => setCollapsed(true)}
                    className="icon"
                  />
                </Tooltip>
                <span className="text">导航目录</span>
              </>
            )}
          </div>
          {!collapsed && (
            <FolderTree
              updateTreeList={updateTreeList}
              getDocDetailHandle={getDocDetailHandle}
            />
          )}
        </div>
      </Sider>
      <Layout>
        <Layout>
          <Content>{content}</Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Sidebar
