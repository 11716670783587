import { Block, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import palette from '@/helpers/palette'
import { ProofOfficialType } from '@/helpers/enums'

interface HighlightProps {
  strike: boolean
}

const PLUGIN_NAME = 'highlight'

class HighlightSchema extends Block<HighlightProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'div'

  // 解析DOM，什么数据会被识别为删除线
  public parseDOM = []

  public toDOM = () => {
    return ['div', 0] as DOMOutputSpec
  }
}

const EDITOR_PADDING_LEFT = 10

class Tooltip {
  private dom: HTMLElement
  private visible = false

  constructor(mountEle: HTMLElement) {
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.dom.style.userSelect = 'none'
    this.dom.style.zIndex = '100'
    this.dom.style.backgroundColor = 'white'
    this.dom.style.border = '1px solid var(--color-neutral-3)'
    this.dom.style.borderRadius = '2px'
    this.dom.style.padding = '5px'
    this.dom.style.display = 'none'
    this.dom.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)'
    this.dom.id = 'highlight-tooltip'
    mountEle.appendChild(this.dom)
  }

  public show = (
    left: number,
    top: number,
    correct: string,
    reason: string,
  ) => {
    this.dom.style.left = `${left}px`
    this.dom.style.top = `${top}px`
    this.dom.style.display = 'none'
    this.dom.innerHTML = `
      <div style="margin-bottom: 5px; background-color:${palette.proof};padding:8px 12px; border-radius:4px;color: ${palette.proofBold};width:fit-content">${correct}</div>
      <div style="color:#999999;">${reason}</div>
    `
  }

  public hoverShow = () => {
    this.dom.style.display = 'block'
    this.visible = true
  }

  public hide = () => {
    this.dom.style.display = 'none'
    this.visible = false
  }

  get isVisible() {
    return this.visible
  }
}

class HighlightController extends SylController<HighlightProps> {
  public name = PLUGIN_NAME
  private tooltip: Tooltip
  // current start position of highlight text
  private curPos = -1
  private curHighlightText = ''
  private correctTxt = '我才是正确的'

  constructor(editor: SylApi, props) {
    super(editor, props)
    this.tooltip = new Tooltip(this.editor.root)
    events.on(ACTIONS.ADD_EDITOR_TIP_PROOF, this.highlightAndShowTip)
    events.on(
      ACTIONS.ADD_EDITOR_TIP_PROOF_OFFICIAL,
      this.highlightAndShowTipOfficial,
    )
    events.on(
      ACTIONS.ADD_EDITOR_TIP_PROOF_TACTFUL,
      this.highlightAndShowTipTactful,
    )
    events.on(ACTIONS.REMOVE_EDITOR_TIP, this.removeHighlightAndTip)
    events.on(
      ACTIONS.REMOVE_EDITOR_TIP_OFFICIAL,
      this.removeHighlightAndTipOfficial,
    )
    events.on(ACTIONS.REPLACE_TO_EDITOR_TIP, this.replaceToEditorTip)
    events.on(ACTIONS.PROOF_REPLACE_ALL, this.replaceAll)
  }

  editorWillUnmount() {
    events.off(ACTIONS.ADD_EDITOR_TIP_PROOF, this.highlightAndShowTip)
    events.off(
      ACTIONS.ADD_EDITOR_TIP_PROOF_OFFICIAL,
      this.highlightAndShowTipOfficial,
    )
    events.off(
      ACTIONS.ADD_EDITOR_TIP_PROOF_TACTFUL,
      this.highlightAndShowTipTactful,
    )
    events.off(ACTIONS.REMOVE_EDITOR_TIP, this.removeHighlightAndTip)
    events.on(
      ACTIONS.REMOVE_EDITOR_TIP_OFFICIAL,
      this.removeHighlightAndTipOfficial,
    )
    events.off(ACTIONS.REPLACE_TO_EDITOR_TIP, this.replaceToEditorTip)
    events.off(ACTIONS.PROOF_REPLACE_ALL, this.replaceAll)
  }

  public replaceAll = (payload) => {
    const { list } = payload
    let startPos = -1
    list.forEach((_, i) => {
      this.editor.view.state.doc.forEach((nodes, offset, index) => {
        if (
          nodes.type.name == 'bullet_list' ||
          nodes.type.name == 'block_quote' ||
          nodes.type.name == 'ordered_list'
        ) {
          nodes.content.descendants((node, pos) => {
            if (node.isText && node.text == _.defaultContent) {
              const foundPos = node.text.indexOf(_.defaultContent)
              if (foundPos >= 0) {
                startPos = offset + foundPos
                const oldTextNode = this.editor.view.state.doc.nodeAt(offset)
                const oldTextStyle = oldTextNode.marks
                const tr = this.editor.view.state.tr

                tr.replaceWith(
                  startPos,
                  startPos + _.defaultContent.length + 2,
                  this.editor.view.state.schema.text(
                    _.fixContent,
                    oldTextStyle,
                  ),
                )
                this.editor.view.dispatch(tr)
                return false // stop iterating
              }
            }
            return true // continue iterating
          })
        } else if (_.nodeIndex === index) {
          let startPos = 0
          // Find the first instance of the search string in the document
          const emojiRegex =
            /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u
          const emojisArray = _.sentence.split(emojiRegex)
          const count = emojisArray ? emojisArray.length - 1 : 0
          startPos = offset + _.beginSentenceOffset + 1 + count

          const oldTextNode = this.editor.view.state.doc.nodeAt(startPos)
          const oldTextStyle = oldTextNode.marks
          // Create a transaction to replace the old text with the new text
          const tr = this.editor.view.state.tr
          tr.replaceWith(
            startPos,
            startPos + _.sentence.length,
            this.editor.view.state.schema.text(_.sentenceFixed, oldTextStyle),
          )
          // Apply the transaction to the editor state
          this.editor.view.dispatch(tr)
        }
      })
    })
  }

  public replaceToEditorTip = () => {
    // Get the style of the old text
    const oldTextNode = this.editor.view.state.doc.nodeAt(this.curPos)
    const oldTextStyle = oldTextNode.marks
    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      this.curPos,
      this.curPos + this.curHighlightText.length,
      this.editor.view.state.schema.text(this.correctTxt, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    this.removeHighlightAndTip()
  }

  public removeHighlightAndTip = () => {
    this.editor.getShadows().forEach((i) => {
      this.editor?.removeShadow(i.type.spec.key)
    })
    // this.editor?.removeShadow('shadow')
    // this.editor?.removeShadow('shadowOfficial')
    // this.editor?.removeShadow('shadowErrOfficial')
  }

  public removeHighlightAndTipOfficial = () => {
    this.editor?.removeShadow('shadowOfficial')
    this.editor?.removeShadow('shadowErrOfficial')
  }

  private findTextPositionInEditor = (
    searchText: string,
    beginSentenceOffset: number,
    nodeIndex: number,
    content: string,
  ) => {
    let startPos = 0
    // Find the first instance of the search string in the document
    const emojiRegex =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u
    const emojisArray = content.split(emojiRegex)
    const count = emojisArray ? emojisArray.length - 1 : 0
    this.editor.view.state.doc.forEach((node, offset, index) => {
      if (nodeIndex === index) {
        startPos = offset + beginSentenceOffset + 1 + count
      }
    })

    return startPos
  }

  public highlightAndShowTipOfficial = (payload) => {
    this.editor?.removeShadow('shadowOfficial')
    this.editor?.removeShadow('shadowErrOfficial')
    const {
      content,
      contentComplete,
      error,
      errorLength,
      nodeIndex,
      position,
      proofOfficialType,
    } = payload
    const startPos = this.findTextPositionInEditor(
      error,
      0,
      nodeIndex,
      contentComplete,
    )
    let boldColor, color
    switch (proofOfficialType) {
      case ProofOfficialType.lanAndTextProof:
        boldColor = '#E33E4A'
        color = '#FFDDE0'
        break
      case ProofOfficialType.tactfulAndNormativeProof:
        boldColor = '#F39210'
        color = '#FFF2D8'
        break
      default:
        boldColor = '#12A969'
        color = '#E0F8EC'
    }
    this.editor?.appendShadow(
      {
        index: startPos,
        length: contentComplete.length,
        attrs: {
          style: `border-bottom: 2px solid ${boldColor}`,
          class: 'highlight',
        },
        spec: {
          key: 'shadowOfficial',
        },
      },
      true,
    )

    this.editor?.appendShadow(
      {
        index: startPos + position + contentComplete.indexOf(content),
        length: errorLength,
        attrs: {
          style: `background-color: ${color}`,
          class: 'highlightErr',
        },
        spec: {
          key: 'shadowErrOfficial',
        },
      },
      true,
    )

    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(startPos)

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)

    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    }
  }

  public findSubstringPositionAndLength(mainString, subString) {
    // 移除主字符串中的空格
    var mainStringWithoutSpaces = mainString.replace(/\s/g, '')

    // 查找子字符串在没有空格的主字符串中的位置
    var positionWithoutSpaces = mainStringWithoutSpaces.indexOf(subString)

    // 如果找到了子字符串
    if (positionWithoutSpaces !== -1) {
      // 查找主字符串中的空格位置
      var spacePosition = mainString.indexOf(' ', positionWithoutSpaces)

      // 如果空格在字符串中间
      if (
        spacePosition !== -1 &&
        spacePosition < positionWithoutSpaces + subString.length
      ) {
        // 返回子字符串加空格的字符串的长度和在主字符串的位置
        console.log(
          '位置:',
          positionWithoutSpaces,
          '含有空格的长度:',
          lengthWithSpaces,
        )
        var lengthWithSpaces =
          subString.length + (spacePosition - positionWithoutSpaces)
        return lengthWithSpaces
      } else {
        console.log('位置:', positionWithoutSpaces, '没有空格')
        return positionWithoutSpaces
      }
    } else {
      console.log('未找到子字符串')
    }
  }

  public highlightAndShowTipTactful = (payload) => {
    this.removeHighlightAndTip()
    const { nodeIndex, riskWords, content, contentComplete } = payload
    console.log(payload, 'payload')
    let startPos
    this.editor.view.state.doc.forEach((node, offset, index) => {
      if (nodeIndex === index) {
        startPos = offset + 1
      }
    })
    console.log(contentComplete.length, ' contentComplete.length', startPos)
    console.log(this.findSubstringPositionAndLength(contentComplete, '秦刚'))
    this.editor?.appendShadow(
      {
        index: startPos,
        length: contentComplete && contentComplete.length,
        attrs: {
          style: `border-bottom: 2px solid #E33E4A`,
          class: 'highlight',
        },
        spec: {
          key: 'shadowOfficial',
        },
      },
      true,
    )
    riskWords.forEach((item) => {
      this.editor.view.state.doc.forEach((node, offset, index) => {
        if (nodeIndex === index) {
          this.editor?.appendShadow(
            {
              index:
                offset + 1 + item.position + contentComplete.indexOf(content),
              length: this.findSubstringPositionAndLength(
                contentComplete,
                item.keyword,
              ),
              attrs: {
                style: `background-color: ${palette.proof}`,
                class: 'highlight',
              },
              spec: {
                key: `shadowTactful-${offset + 1 + item.position}`,
              },
            },
            true,
          )
        }
      })
    })
    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(startPos)

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)

    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    }
  }

  public highlightAndShowTip = (payload) => {
    this.editor?.removeShadow('shadow')
    const searchText = payload.error
    const correctText = payload.correct
    const beginSentenceOffset = payload.beginSentenceOffset
    const nodeIndex = payload.nodeIndex
    const content = payload.content
    const highlightColor = payload.color
    const startPos = this.findTextPositionInEditor(
      searchText,
      beginSentenceOffset,
      nodeIndex,
      content,
    )
    if (startPos === -1) {
      this.tooltip.hide()
      this.curPos = -1
      this.curHighlightText = ''
      this.correctTxt = ''
      return
    } else {
      this.curPos = startPos
      this.curHighlightText = searchText
      this.correctTxt = correctText
    }

    this.editor?.appendShadow(
      {
        index: startPos,
        length: searchText.length,
        attrs: {
          style: `background-color: ${highlightColor}`,
          class: 'highlight',
        },
        spec: {
          key: 'shadow',
        },
      },
      true,
    )

    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(startPos)

    const computedLeft = headLeft - editorLeft + EDITOR_PADDING_LEFT
    const computedTop = headBottom + 8 - editorTop
    const highlightDiv = document.querySelector('.highlight')
    highlightDiv.addEventListener('mouseenter', () => {
      this.tooltip.hoverShow()
    })
    highlightDiv.addEventListener('mouseleave', () => {
      this.tooltip.hide()
    })

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)

    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    }
    if (headBottom < editorBottom || headBottom > editorTop) {
      this.tooltip.show(
        computedLeft,
        computedTop,
        payload.correct,
        payload.reason,
      )
    }
    /* 之前的方法 */
    // if (headBottom > editorBottom || headBottom < editorTop) {
    //   this.editor.view.dom.scrollTo({ top: headBottom - editorTop });
    //   const newBottom = this.editor.view.coordsAtPos(startPos).bottom;
    //   this.tooltip.show(
    //     computedLeft,
    //     newBottom - editorTop + 8,
    //     payload.correct,
    //     payload.reason
    //   );
    // } else {
    //   this.tooltip.show(
    //     computedLeft,
    //     computedTop,
    //     payload.correct,
    //     payload.reason
    //   );
    // }
  }
}

class HighlightPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = HighlightController
  public Schema = HighlightSchema
}

export { HighlightPlugin }
