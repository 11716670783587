import React, { useEffect, useState } from 'react'
import styles from './style/layout.module.less'

import PromptImage from './prompt/PromptImage'
import PromptDownSelect from './prompt/PromptDownSelect'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'

function SynthesisLeftForm(props) {
  const { onUpdateImg, aspect, setAspect, delImg, delBaseUrl } = props
  const [list, setList] = React.useState([
    {
      id: 1,
      title: '以图生图',
      title_tooltips: '请上传一张图片或手动输入链接',
      showPlus: true,
      showMinus: false,
      show: true,
    },
    {
      id: 2,
      title: '以图生图',
      title_tooltips: '请上传一张图片或手动输入链接',
      showPlus: true,
      showMinus: false,
      show: true,
    },
  ])

  function plus(item, index) {
    if (list.length >= 5) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '最多上传五张图片',
      })
      return
    } else {
      let newList = [...list]
      let obj = { ...item }
      obj.id = newList.length + 1
      obj.showMinus = true
      newList.splice(index + 1, 0, obj)
      setList(newList)
    }
  }

  function minus(item, index) {
    let newList = [...list]
    newList.splice(index, 1)
    setList(newList)
  }

  return (
    <>
      <div className={styles.title_sub} style={{ marginTop: 0 }}>
        最多上传5张图片，最少上传2张图片
      </div>
      {list.map((item, index) => {
        if (item.show) {
          return (
            <div className={styles.prompt_item} key={index}>
              <PromptImage
                hideWeight={true}
                plus={() => {
                  plus(item, index)
                }}
                minus={() => {
                  minus(item, index)
                }}
                index={index}
                onBase64ImageChange={(value) => {
                  onUpdateImg(value, index)
                }}
                delImg={delImg}
                onUpdateImg={onUpdateImg}
                title={item.title}
                tooltip={item.title_tooltips}
                showPlus={item.showPlus}
                showMinus={item.showMinus}
                delBaseUrl={delBaseUrl}
                typePath={'Synthesis'}
              />
            </div>
          )
        }
      })}

      <div className={styles.prompt_item}>
        <PromptDownSelect
          title={'Aspect比例'}
          tooltip={'更改纵横比，图片的比例'}
          value={aspect}
          onInputChange={(value) => {
            let aspectValue
            if (value === '1:1') {
              aspectValue = 'SQUARE'
            } else if (value === '2:3') {
              aspectValue = 'PORTRAIT'
            } else if (value === '3:2') {
              aspectValue = 'LANDSCAPE'
            }
            setAspect(aspectValue)
          }}
          options={[
            { title: '1:1', id: 1 },
            { title: '2:3', id: 2 },
            {
              title: '3:2',
              id: 3,
            },
          ]}
          showPlus={false}
          showMinus={false}
        />
      </div>
    </>
  )
}

export default SynthesisLeftForm
