import React, { useEffect, useState } from 'react'
import styles from './style/dismantle-item.module.less'
import {
  IconEdit,
  IconCopy,
  IconCheck,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import IconBtn from '../../components/IconBtn'
import { Input, Message } from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import copy from 'copy-to-clipboard'

const TextArea = Input.TextArea

function DismantleItem(props) {
  const [isDescEdit, setIsDescEdit] = useState(false)
  const [isCndescEdit, setIsCndescEdit] = useState(false)
  const [desc, setDesc] = useState(props.desc)
  const [cndesc, setCndesc] = useState(props.cndesc)
  useEffect(() => {
    setDesc(props.desc)
    setCndesc(props.cndesc)
  }, [props.desc])
  const handleDescChange = (e) => {
    setDesc(e)
  }

  const handleCndescChange = (e) => {
    setCndesc(e)
  }

  const handleDescSave = () => {
    setIsDescEdit(false)
  }

  const handleCndescSave = () => {
    setIsCndescEdit(false)
  }

  function copyContent(inputContent) {
    copy(inputContent)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }
  return (
    <div className={styles.item}>
      {/* 英文默认 */}
      {!isDescEdit ? (
        <div className={styles.desc}>{desc}</div>
      ) : (
        <div className={styles.textarea}>
          <TextArea
            className={'g-input'}
            value={desc}
            onChange={handleDescChange}
            autoSize={true}
          />
        </div>
      )}
      {/* 英文编辑 */}
      {!isDescEdit ? (
        <div className={styles.btn_group}>
          <IconBtn
            onClick={() => {
              setIsDescEdit(true)
            }}
            color={'gray'}
            text={'编辑'}
            iconLeft={<IconEdit />}
          />
          <IconBtn
            onClick={() => copyContent(desc)}
            color={'gray'}
            text={'复制'}
            iconLeft={<IconCopy />}
          />
        </div>
      ) : (
        <div className={styles.btn_group}>
          <BorderBtn
            onClick={handleDescSave}
            gradient={true}
            text={'确定'}
            iconLeft={<IconCheck />}
          />
        </div>
      )}
      {/* 中文默认 */}
      {!isCndescEdit ? (
        <div className={styles.desc}>{cndesc}</div>
      ) : (
        <div className={styles.textarea}>
          <TextArea
            className={'g-input'}
            value={cndesc}
            onChange={handleCndescChange}
            autoSize={true}
          />
        </div>
      )}

      {/* 中文编辑 */}
      {!isCndescEdit ? (
        <div className={styles.btn_group}>
          <IconBtn
            onClick={() => {
              setIsCndescEdit(true)
            }}
            color={'gray'}
            text={'编辑'}
            iconLeft={<IconEdit />}
          />
          <IconBtn
            onClick={() => copyContent(cndesc)}
            color={'gray'}
            text={'复制'}
            iconLeft={<IconCopy />}
          />
        </div>
      ) : (
        <div className={styles.btn_group}>
          <BorderBtn
            onClick={handleCndescSave}
            gradient={true}
            text={'确定'}
            iconLeft={<IconCheck />}
          />
        </div>
      )}
    </div>
  )
}

export default DismantleItem
