import React, { useEffect, useRef, useState } from 'react'
import Style from 'styled-components'
import styles from './style/titleShareNav.module.less'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import {
  IconDoubleArrowRight,
  IconDoubleArrowLeft,
  IconLink,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import { Message, Tooltip } from '@arco-design/web-react'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { useHistory } from 'react-router-dom'
import TocHelper from 'toc-helper'
import ReactDOM from 'react-dom'
import copy from 'copy-to-clipboard'

// import { Css } from '@styled-icons/material'

const DocShareStyle = Style.div<{
  isOpen: boolean
  theme: string
}>`
${({ isOpen, theme }) =>
  isOpen
    ? `.bitoc  {
    height: 70vh;
    border: ${theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #efefef'};
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
    padding: 16px;
  background: ${theme == 'dark' ? ' #19191a' : ' #fff'};
  }
  .bitoc-nav{
    width:160px;
    a{
      color:#9f9f9f;
      font-size: 12px;
      position: relative;
      padding:0.2rem 10px 0.2rem 10px !important;
      cursor: pointer;
      right:0;
      white-space: nowrap;
      // max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
      svg{
        display:none;
      }
      svg:hover{
        fill: #f65500;
      }
    }
    .bitoc-ml-1{
      max-width: 160px;
    }
    .bitoc-ml-2{
      max-width: 149px;
      margin-left: 10px !important;
    }
    .bitoc-ml-3{
      max-width: 137px;
      margin-left: 20px !important;
    }
    .bitoc-ml-4{
      max-width: 125px;
      margin-left: 30px !important;
    }
    .bitoc-ml-5{
      max-width: 113px;
      margin-left: 40px !important;
    }
    .bitoc-ml-6{
      max-width: 101px;
      margin-left: 50px !important;
    }
    a:before{
      content: '';
      width: 4px;
      height: 4px;
      background:#9f9f9f;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2px;
    }
    a:hover{
      color: #3e3e3e;
      :before{
        background:#3e3e3e;
      }
      svg{
        display:block;
        fill: ${theme == 'dark' && ' #fff'};
      }
    }
    /* .bitoc-ml-1:before{
      content:'';
      width:4px ;
      height:4px ;
      border-radius: 50% ;
      background-color: #DDDDDD;
    }
    .bitoc-ml-2:before{
      content: '';
      width: 2px;
      height: 50% ;
      border-radius: 0;
      background-color: #DDDDDD;
      position: absolute;
      left: 1%;
      top: 35%;
    }
    .bitoc-ml-3:before{
      content: '';
      width: 4px;
      height:4px ;
      border-radius:0;
      background-color: #DDDDDD;
    } */
  }
  .bitoc-nav a.active {
    color:#3e3e3e;
  }
  .bitoc-nav a.active:before {
    background: #3e3e3e;
  }`
    : `.bitoc  {
      height: 70vh;
      width: 40px !important;
    }
    .bitoc:hover{
        width:100% !important;
        right: -100px;
        height: 70vh;
          border: ${
            theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #efefef'
          };
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
        padding: 16px;
 background: ${theme == 'dark' ? ' #19191a' : ' #fff'};
        right: 0px;
      .bitoc-nav{
        width:160px;
        a{
          color:#9f9f9f;
          font-size: 12px;
          position: relative;
          padding-left: 12px;
          cursor: pointer;
          right:0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 24px;
          width:100% !important;
          svg{
            display:none;
          }
          svg:hover{
            fill: #f65500;
          }
        }
        .bitoc-ml-1{
          max-width: 160px;
        }
        .bitoc-ml-2{
          max-width: 149px;
          margin-left: 10px !important;
        }
        .bitoc-ml-3{
          max-width: 137px;
          margin-left: 20px !important;
        }
        .bitoc-ml-4{
          max-width: 125px;
          margin-left: 30px !important;
        }
        .bitoc-ml-5{
          max-width: 113px;
          margin-left: 40px !important;
        }
        .bitoc-ml-6{
          max-width: 101px;
          margin-left: 50px !important;
        }
        a:before{
          content: '';
          width: 4px;
          height: 4px;
          background:#9f9f9f;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -2px;
        }
        a:hover{
          color: #3e3e3e;
          :before{
            background:#3e3e3e;
          }
          svg{
            display:block;
            fill: ${theme == 'dark' && ' #fff'};
          }
        }
        /* .bitoc-ml-1:before{
          content:'';
          width:4px ;
          height:4px ;
          border-radius: 50% ;
          background-color: #DDDDDD;
        }
        .bitoc-ml-2:before{
          content: '';
          width: 2px;
          height: 50% ;
          border-radius: 0;
          background-color: #DDDDDD;
          position: absolute;
          left: 1%;
          top: 35%;
        }
        .bitoc-ml-3:before{
          content: '';
          width: 4px;
          height:4px ;
          border-radius:0;
          background-color: #DDDDDD;
        } */
        
      }
      .bitoc-nav a.active {
        color:#3e3e3e;
      }
      .bitoc-nav a.active:before {
        background: #3e3e3e;
      }
    }
  .bitoc-nav{
    
    a{
      color:transparent;
      font-size: 12px;
      position: relative;
      padding-left: 12px;
      cursor: pointer;
      word-break: keep-all;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
    }
    a:before{
      content: '';
      width: 4px;
      height: 4px;
      background:#9f9f9f;
      border-radius: 50%;
      position: absolute;
      left: ${(isOpen) => !isOpen && '0px'};
      top: 50%;
      margin-top: -2px;
    }
    a:hover{
      color:transparent;
      :before{
        background:#3e3e3e;
      }
    }
    
    .bitoc-ml-2{
      margin-left:10px!important;
    }
    .bitoc-ml-3{
      margin-left:20px!important
    }
    .bitoc-ml-4{
      margin-left:30px!important;
    }
    .bitoc-ml-5{
      margin-left:40px!important
    }
    .bitoc-ml-6{
      margin-left:50px!important;
    }

    .bitoc-ml-1:before{
      content:'';
      width:32px ;
      height:2px;
      border-radius: 2px ;
      background-color: #DDDDDD;
    }
    .bitoc-ml-2:before{
      content: '';
      width: 24px;
      height:2px ;
      border-radius:  2px;
      background-color: #DDDDDD;
    }
    .bitoc-ml-3:before{
      content: '';
      width: 10px;
      height: 2px ;
      border-radius: 2px;
      background-color: #DDDDDD;
    }
    .bitoc-ml-4:before{
      content: '';
      width: 6px;
      height: 2px ;
      border-radius: 2px;
      background-color: #DDDDDD;
    }
    .bitoc-ml-5:before{
      content: '';
      width: 4px;
      height: 2px ;
      border-radius: 2px;
      background-color: #DDDDDD;
    }
    .bitoc-ml-6:before{
      content: '';
      width: 2px;
      height: 2px ;
      border-radius: 2px;
      background-color: #DDDDDD;
    }
  }
  .bitoc-nav a.active {
    color:transparent;
  }
  .bitoc-nav a.active:before {
    background: #3e3e3e;
  }`}
  
`

const TitleNav = (props) => {
  const { theme, docContent } = props

  let toc
  const history = useHistory()
  const [curId, setCurId] = React.useState(
    GetQueryValue('rightCurId') ? GetQueryValue('rightCurId') : '',
  )
  const curIdRef = useRef(
    GetQueryValue('rightCurId') ? GetQueryValue('rightCurId') : '',
  )
  const [showNav, setShowNav] = useState<boolean>(false)
  const tocRef = useRef(null)
  useEffect(() => {
    events.on(ACTIONS.NAV_SCROLL_EDITOR, navScrollHandle)
    return () => {
      events.off(ACTIONS.NAV_SCROLL_EDITOR, navScrollHandle)
    }
  }, [])

  const navScrollHandle = (payload) => {
    const navList = payload.data
    const targetNav = payload.obj
    const targetIndex = navList.findIndex(
      (obj) =>
        obj.headTop === targetNav.headTop &&
        obj.headBottom === targetNav.headBottom,
    )
    curIdRef.current = targetIndex
    setCurId(targetIndex)
  }

  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('shareIsOpen') === 'true',
  )

  const handleIsOpen = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    localStorage.setItem('shareIsOpen', JSON.stringify(newIsOpen))
  }

  const copyHandle = (e, link) => {
    e.preventDefault()
    e.stopPropagation()
    let updatedUrl
    const currentUrl = window.location.href
    const idPrefix = GetQueryValue('idPrefix')
    if (idPrefix) {
      updatedUrl =
        window.location.origin +
        '/documentShare?uuid=' +
        GetQueryValue('uuid') +
        `${
          GetQueryValue('selectUuid')
            ? '&selectUuid=' + GetQueryValue('selectUuid')
            : ''
        }` +
        `&idPrefix=${link}`
    } else {
      updatedUrl = window.location.href + `&idPrefix=${link}`
    }
    getDoShareLinkHandle({ url: updatedUrl })
  }

  const getDoShareLinkHandle = async (linkUuid) => {
    console.log(linkUuid, 'linkUuid')
    try {
      const response = await fetch(
        'https://cors.v-dk.com/https://go.wuz.com.cn/api/set.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(linkUuid),
        },
      )

      if (response.ok) {
        const previewData = await response.json()

        copy(previewData.content.url)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '复制成功',
        })
      } else {
        // 请求失败
        console.error('set.php 请求失败')
      }
    } catch (error) {
      console.error('set.php 请求出错', error)
    }
  }

  useEffect(() => {
    const tocContainers = document.querySelector('.bitoc')
    if (tocContainers) {
      tocContainers.remove()
    }
    console.log(props.isShow)
    tocRef.current = new TocHelper('#toc', {
      contentSelector: `#collectContent`,
      scrollSelector: '#scroll-content',
      // headingSelector: 'level',
      collapsedLevel: 6,
      fixedOffset: 320 - 84,
      // levelClassPrefix: 'bitoc-',
      fixedSelector: '#toc-wrap',
      scrollOffset: 120,
      idPrefix: `toc-heading-`,
    })
    var elements1 = document.getElementsByClassName('bitoc-ml-1')
    var elements2 = document.getElementsByClassName('bitoc-ml-2')
    var elements3 = document.getElementsByClassName('bitoc-ml-3')
    var elements = Array.prototype.slice
      .call(elements1)
      .concat(Array.prototype.slice.call(elements2))
      .concat(Array.prototype.slice.call(elements3))
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].innerHTML === '') {
        elements[i].setAttribute('style', 'display:none')
      }
    }
    // 修复H标题错位
    changeTitle()

    if (!props.isShare) {
      console.log(props.isShow)
      const activeLinks = document.querySelectorAll('.bitoc-nav a')

      activeLinks.forEach((link) => {
        const span = document.createElement('span')
        const idPrefix = link.textContent
        span.textContent = 'Your Text Here'
        span.style.color = 'red'
        span.style.fontSize = '16px'
        span.style.height = '24px'
        span.style.position = 'absolute'
        span.style.right = '0px'
        ReactDOM.render(
          <div onClick={(e) => copyHandle(e, idPrefix)}>
            <IconLink />
          </div>,
          span,
        )
        link.appendChild(span)
        link.addEventListener('click', (event) => {
          event.preventDefault()
          const href = link.textContent
          const urlQuery = removeEmpty({
            uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
            selectUuid: GetQueryValue('selectUuid')
              ? GetQueryValue('selectUuid')
              : '',
            idPrefix: decodeURIComponent(href),
          }) as any
          history.replace({
            pathname: '/documentShare',
            search: '?' + new URLSearchParams(urlQuery).toString(),
          })
          // 在这里添加你希望执行的操作
          setShowNav(!showNav)
        })
      })
      for (let i = 0; i < activeLinks.length; i++) {
        const idPrefixFromLink = decodeURIComponent(
          activeLinks[i].textContent.trim(),
        )
        const idPrefixFromRoute = decodeURIComponent(
          GetQueryValue('idPrefix')?.replace(/\+/g, ' '),
        )
        if (idPrefixFromLink === idPrefixFromRoute) {
          const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          activeLinks[i].dispatchEvent(event)
        }
      }
    }
  }, [isOpen, props.isShow])

  // 修复H标题错位
  const changeTitle = () => {
    // 获取编辑器中的所有直接子节点H标签
    // const editorHeadings = document.querySelector('.ProseMirror').children
    let parentElement = document.querySelector('.ProseMirror')
    let editorHeadings = parentElement.querySelectorAll(
      'h1, h2, h3, h4, h5, h6',
    )

    let editorHeadingLevels = []
    for (let i = 0; i < editorHeadings.length; i++) {
      let tagName = editorHeadings[i].tagName
      if (tagName && tagName.startsWith('H')) {
        editorHeadingLevels.push(parseInt(tagName.substr(1)))
      }
    }
    if (!editorHeadingLevels.length) return

    // 获取目录中的所有目录标签
    let directoryItems = Array.from(
      document.querySelectorAll('.bitoc-nav [class^="bitoc-ml-"]'),
    ).filter((item) => window.getComputedStyle(item).display !== 'none')
    if (!directoryItems.length) return

    let directoryItemClasses = []
    for (let i = 0; i < directoryItems.length; i++) {
      let item = directoryItems[i]
      // 检查元素是否隐藏
      let style = window.getComputedStyle(item)
      if (style.display !== 'none') {
        directoryItemClasses.push(item.className)
      }
    }
    if (!directoryItemClasses.length) return

    // 将目录中的目录标签按照编辑器中的H标签进行修改
    for (let i = 0; i < editorHeadingLevels.length; i++) {
      if (
        directoryItemClasses[i] &&
        directoryItemClasses[i].startsWith('bitoc-ml-')
      ) {
        // 使用正则表达式替换目录标签的级别
        let prefix = 'bitoc-ml-' //要匹配的前缀

        if (directoryItemClasses[i].startsWith(prefix)) {
          let numPart = directoryItemClasses[i].slice(prefix.length) //获取前缀后面的部分（在这个例子中应该是数字）

          if (!isNaN(Number(numPart))) {
            //将字符串转为数字并检查是否为数字
            directoryItemClasses[i] = prefix + editorHeadingLevels[i] //如果是数字，则进行替换
          } else if (!isNaN(Number(numPart.slice(' ')[0]))) {
            directoryItemClasses[i] =
              prefix + editorHeadingLevels[i] + ' active'
          }
        }
      }
    }

    // 应用新的类名到目录项目
    for (let i = 0; i < directoryItems.length; i++) {
      directoryItems[i].className = directoryItemClasses[i]
    }
  }

  return (
    <>
      {docContent && (
        <div
          className={` ${styles.open} ${styles.title_nav} ${
            props.showRight ? styles.small : ''
          }  ${props.position === 'right' ? styles.right : ''} ${
            styles[theme]
          }`}>
          <div className={styles.title_line_box}></div>
          <div className={styles.title_tree_wrap}>
            <Tooltip
              className={'custom-tooltip'}
              position="bottom"
              trigger="hover"
              content={isOpen ? '收起索引' : '展开索引'}>
              <div className={styles.title_tree_arrow} onClick={handleIsOpen}>
                {isOpen && <IconDoubleArrowLeft />}
                {!isOpen && <IconDoubleArrowRight />}
              </div>
            </Tooltip>
            <DocShareStyle theme={theme} isOpen={Boolean(isOpen)}>
              <div id="toc-container" className={styles.tocContainer}>
                <div id="toc-wrap">
                  <div id="toc"></div>
                </div>
              </div>
            </DocShareStyle>
          </div>
        </div>
      )}
    </>
  )
}

export default TitleNav
