import { ProofOfficialType, ProofSelectType } from '@/helpers/enums'
import { officialCorrection } from '@/model/documentEdit'
import { nonEmptyArray } from '@/utils/common'
import { Message } from '@arco-design/web-react'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import React, { useEffect, useRef, useState } from 'react'
import ProofNumsMod from './proofNumsMod'
import EmptyView from '@/components/Empty'
import { ProofOfficialItem } from './proofOfficialItem'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

interface Props {
  defaultContent?: any
  proofOfficialData: any
  stopProofOfficial: boolean
  isProofOfficialLoading: boolean
  onProofLoading: (isLoading: boolean) => void
  onProofOfficialData: (data: any) => void
}

const ProofOfficialMod = (props: Props) => {
  const {
    defaultContent,
    stopProofOfficial,
    isProofOfficialLoading,
    onProofLoading,
    onProofOfficialData,
    proofOfficialData,
  } = props
  const [proofOfficialType, setProofOfficialType] = useState(
    ProofOfficialType.lanAndTextProof,
  )
  const [proofOfficialList, setProofOfficialList] = useState([[], [], []])
  const proofOfficialListRef = useRef([[], [], []])
  const stopProofOfficialRef = useRef(false)
  const nodeIndexRef = useRef(0)
  useEffect(() => {
    if (
      proofOfficialData === null ||
      (proofOfficialData && proofOfficialData.flat().length > 0)
    ) {
      if (proofOfficialData === null) {
        setProofOfficialList([[], [], []])
        proofOfficialListRef.current = [[], [], []]
      } else {
        setProofOfficialList(proofOfficialData)
        proofOfficialListRef.current = proofOfficialData
      }
    } else {
      setProofOfficialList([[], [], []])
      proofOfficialListRef.current = [[], [], []]
      getOfficialCorrectionHandle(defaultContent)
    }
  }, [proofOfficialData])

  useEffect(() => {
    stopProofOfficialRef.current = stopProofOfficial
    // if (stopProofOfficial) {
    //   isLoadingRef.current = false
    // }
    onProofLoading(false)
  }, [stopProofOfficial])

  const highlightText = (text, position, length) => {
    let boldColor, color
    switch (proofOfficialType) {
      case ProofOfficialType.lanAndTextProof:
        boldColor = '#E33E4A'
        color = '#FFDDE0'
        break
      case ProofOfficialType.tactfulAndNormativeProof:
        boldColor = '#F39210'
        color = '#FFE7C8'
        break
      default:
        boldColor = '#12A969'
        color = '#A2E5C9'
    }
    return (
      text.slice(0, position) +
      `<span style=background-color:${color}>` +
      text.slice(position, position + length) +
      '</span>' +
      text.slice(position + length)
    )
  }

  const getOfficialCorrectionHandle = async (contents) => {
    const wrong = []
    let nodeWrong = []
    let nodeAddWrong = []
    let nodeIndex
    if (contents.length == 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '目前没有内容，请填写内容',
      })
      return
    }

    for (let i = 0; i < contents.length && !stopProofOfficialRef.current; i++) {
      nodeIndex = i
      const splitString = (str, maxLength) => {
        const result = []
        for (let i = 0; i < str.length; i += maxLength) {
          result.push(str.slice(i, i + maxLength))
        }
        return result
      }
      const stringParts = splitString(contents[i].text, 220000)
      for (
        let j = 0;
        j < stringParts.length && !stopProofOfficialRef.current;
        j++
      ) {
        try {
          const res = await officialCorrection({
            text: String.raw`${stringParts[j]}`,
          })

          if (res.data.success && res.data.data.checklist.length != 0) {
            nodeWrong = [...res.data.data.checklist]

            nodeAddWrong = nodeWrong.map((_) => {
              return {
                ..._,
                index: j,
                contentComplete: contents[i].text,
                content: stringParts[j],
                highlightText: highlightText(
                  stringParts[j],
                  _.position,
                  _.length,
                ),
                isOpen: false,
                nodeIndex: contents[i].nodeIndex,
              }
            })

            wrong.push(...nodeAddWrong)
            proofOfficialListRef.current = wrong
            classifyOfficial(wrong, nodeIndex)
          } else if (res.data.code != '500200') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content:
                res.data.code == '700001'
                  ? '注册用户当天使用次数已达到上限'
                  : res.data.code == '700004'
                  ? '会员用户当天使用次数已达到上限'
                  : '接口报错请重试',
            })

            return
          }
          if (res.data.success) {
            nodeIndexRef.current = i + 1
            onProofLoading(
              nodeIndexRef.current == defaultContent.length ? false : true,
            )
          }
        } catch (err) {
          continue
        }
      }
    }

    onProofOfficialData(
      proofOfficialListRef.current.flat().length > 0
        ? proofOfficialListRef.current
        : null,
    )
  }

  const classifyOfficial = (wrong, nodeIndex) => {
    let groupIds = [
      [9, 31, 32, 35, 34, 39, , 36, 20, 21, 24],
      [101, 123, 19, 124, 8, 122, 240, 119],
      [6, 105, 109, 112, 111, 108, 118],
    ]
    let groups = Array.from({ length: groupIds.length }, () => [])
    wrong.forEach((item) => {
      groupIds.forEach((ids, index) => {
        if (ids.includes(item.id)) {
          groups[index].push(item)
        }
      })
    })
    onProofOfficialData(groups.flat().length > 0 ? groups : null)
    proofOfficialListRef.current = groups
    setProofOfficialList(groups)
  }

  const proofOfficialNumList = [
    {
      title: '语言和文本格式错误',
      num: proofOfficialList
        ? proofOfficialList[ProofOfficialType.lanAndTextProof].length
        : 0,
      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofOfficialType.lanAndTextProof,
    },
    {
      title: '专业术语和名词使用',
      num: proofOfficialList
        ? proofOfficialList[ProofOfficialType.termAndNounProof].length
        : 0,
      boldColor: '#F39210',
      color: '#FFE7C8',
      type: ProofOfficialType.termAndNounProof,
    },
    {
      title: '敏感和规范性内容',
      num: proofOfficialList
        ? proofOfficialList[ProofOfficialType.tactfulAndNormativeProof].length
        : 0,
      boldColor: '#12A969',
      color: '#A2E5C9',
      type: ProofOfficialType.tactfulAndNormativeProof,
    },
  ]

  const expandOfficialProof = (i) => {
    const newArr = proofOfficialListRef.current.map((array, v) => {
      if (v == proofOfficialType) {
        return array.map((item, index) => {
          if (index === i) {
            if (item.isOpen) {
              events.emit(ACTIONS.REMOVE_EDITOR_TIP)
            }
            return {
              ...item,
              isOpen: !item.isOpen,
            }
          } else {
            return {
              ...item,
              isOpen: false,
            }
          }
        })
      } else {
        return array
      }
    })

    proofOfficialListRef.current = newArr
    setProofOfficialList(newArr)
  }

  const proofOfficialTypeHandle = (type) => {
    setProofOfficialType(type)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  return (
    <div>
      <ProofNumsMod
        list={proofOfficialNumList}
        activeType={proofOfficialType}
        onChangeProofType={(type) =>
          proofOfficialTypeHandle(type)
        }></ProofNumsMod>

      <>
        {!nonEmptyArray(proofOfficialListRef.current[proofOfficialType]) ? (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 45px 20px',
              marginTop: '20px',
            }}>
            <EmptyView
              type="proofEmpty"
              title={`全面检查您文档中的${proofOfficialNumList[proofOfficialType].title}`}
            />
          </div>
        ) : (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 38px 20px',
              marginTop: '20px',
              opacity: isProofOfficialLoading ? 0.5 : 1,
            }}>
            {proofOfficialListRef.current[proofOfficialType].map((i, index) => {
              return (
                <ProofOfficialItem
                  key={index}
                  id={index}
                  item={i}
                  isLoading={isProofOfficialLoading}
                  boldColor={proofOfficialNumList[proofOfficialType].boldColor}
                  color={proofOfficialNumList[proofOfficialType].color}
                  proofOfficialType={proofOfficialType}
                  onExpand={expandOfficialProof}
                  expand={i.isOpen}></ProofOfficialItem>
              )
            })}
          </div>
        )}
      </>
    </div>
  )
}

export default React.memo(ProofOfficialMod)
