import React from 'react'
import { Message } from '@arco-design/web-react'
import { IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '../BorderBtn'
import './style/codeCopyBtn.less'

export default function CodeCopyBtn(props) {
  const [copyOk, setCopyOk] = React.useState(false)

  const iconColor = copyOk ? '#0af20a' : '#ddd'
  const icon = copyOk ? 'fa-check-square' : 'fa-copy'

  const handleClick = (e) => {
    navigator.clipboard.writeText(props.children[0].props.children[0])

    setCopyOk(true)
    setTimeout(() => {
      setCopyOk(false)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '已复制',
      })
    }, 50)
  }

  return (
    <div className="code-copy-btn" style={props ? props.style : ''}>
      <BorderBtn
        onClick={handleClick}
        theme={props.theme}
        text={'复制'}></BorderBtn>
    </div>
  )
}
