import React, { useEffect, useRef, useState } from 'react'
import {
  Divider,
  Dropdown,
  Menu,
  Message,
  Tooltip,
} from '@arco-design/web-react'
import {
  IconCollect,
  IconEdit,
  IconEditLabel,
  IconErrorTip,
  IconExport,
  IconFile,
  IconMagicWand,
  IconMoreEdits,
  IconMove,
  IconNewFolder,
  IconNewTemplateLibrary,
  IconOpens,
  IconPlus,
  IconShare,
  IconSuccessTip,
  IconUploadDocument,
  IconWastePaper,
  IconAttributes,
} from '@arco-iconbox/react-aidb-v2'
import { showModal } from '@/share/actions'
import { useHistory } from 'react-router-dom'
import { getDocShareLink } from '@/model/documentEdit'
import {
  getCancelCollect,
  getDocumentCollect,
  getFoldList,
  getPublishDocument,
  getUserInfo,
  removeDocument,
} from '@/model/reference'
import request from '@/utils/request'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import styles from './style/index.module.less'
import ModalCustom from '../ModalCustom'
import AddTags from '@/pages/intelligentDocument/AddTags'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import SubMenu from '@arco-design/web-react/es/Menu/sub-menu'
import { ExportBtn } from '@/share/config'
import axios from 'axios'
import { switchProject } from '@/helpers/switchProject'
import { GetQueryValue, treeCreateToEditor } from '@/utils/common'
import IconTreeFile from '@/assets/newImgs/tree-file.svg'

const TreeNodeTitle = ({
  treeData,
  nodeData,
  tagsList,
  starDrag,
  onDoubleChange,
  setUpdateTreeData,
  updateEitorAndTreeData,
}) => {
  const history = useHistory()
  const [hoveredKey, setHoveredKey] = useState(null)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef(null)
  // 从 Redux store 中获取主题信息
  const theme = useSelector((state: GlobalState) => state.theme)
  //添加标签
  const [showAddTags, setShowAddTags] = React.useState(false)
  const [exportLoading, setExportLoading] = useState(false)

  // 当前用户、协同成员、在线状态、操作所有信息
  const yjsEditorCoordinationInfo = useSelector(
    (state: GlobalState) => state.yjsEditorCoordinationInfo,
  )

  //鼠标hover移入出现编辑按钮
  const handleNodeHover = () => {
    setHoveredKey(nodeData._key)
  }

  //鼠标离开hover效果消失
  const handleNodeLeave = () => {
    setHoveredKey(null)
  }

  //点击编辑按钮
  const handleIconClick = (e) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const handleDocumentClick = (e) => {
    // 点击文档其他地方时隐藏 Dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false)
      setHoveredKey(null)
    }
  }

  useEffect(() => {
    // 添加文档点击事件监听器
    document.addEventListener('click', handleDocumentClick)

    // 组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  //更换封面
  const handleCoverChange = () => {
    showModal('FolderCoverModal', {
      Modaltitle: '更换封面',
      uuid: nodeData.uuid,
      title: nodeData.title,
      // folderColor: folderColor,
      update: setUpdateTreeData,
    })
    // window.location.hash = 'FolderCoverModal'
    setHoveredKey(null)
    setDropdownVisible(false)
  }

  // 分享
  function hanldeShare(event) {
    event.stopPropagation()

    setHoveredKey(null)
    setDropdownVisible(false)
    getDocShareLink({ uuid: nodeData.uuid }).then((res) => {
      if (res.data.success) {
        const postData = {
          url:
            window.location.origin +
            '/documentShare?uuid=' +
            res.data.data.documentShareLink,
        }
        showModal('ShareLinkModal', {
          fileType: nodeData.fileType,
          title: '分享',
          id: nodeData.uuid,
          isOpenShare: res.data.data.isOpenShare,
          documentShareLink: postData,
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: res.data.msg,
        })
      }
    })
  }

  // 移动
  const addFoldHandle = (event) => {
    setHoveredKey(null)
    setDropdownVisible(false)
    event.stopPropagation()
    getFoldList()
  }
  function traverse(data) {
    if (Array.isArray(data)) {
      return data.map(traverse)
    } else if (data.children) {
      return {
        title: data.title,
        id: data.uuid,
        children: traverse(data.children),
      }
    } else {
      return {
        title: data.title,
        id: data.uuid,
      }
    }
  }

  function getFoldList() {
    setHoveredKey(null)
    setDropdownVisible(false)
    request
      .post(
        `/api/api-${
          switchProject('DFZ') ? 'core' : 'aidb'
        }/document/getFolderList`,
      )
      .then((res) => {
        const folderList = traverse(res.data.data)
        // 添加根目录对象
        const root_node = {
          title: '根目录',
          id: '',
          children: folderList,
        }
        showModal('AddToFoldModal', {
          title: '移动',
          foldList: [root_node], // 将根目录对象作为foldList参数传递
          id: nodeData.uuid,
          update: setUpdateTreeData,
        })
        setTimeout(() => setUpdateTreeData(Date.now()), 100)
      })
  }

  //重命名
  const renameDocumentHandle = (event) => {
    setHoveredKey(null)
    setDropdownVisible(false)
    event.stopPropagation()
    showModal('RenameModal', {
      title: '重命名',
      defaultName: nodeData.title,
      id: nodeData.uuid,
      update: updateEitorAndTreeData,
    })
  }

  //发布文档
  function handleSaveDoc(event) {
    event.stopPropagation()
    setHoveredKey(null)
    setDropdownVisible(false)
    getPublishDocument({
      uuid: nodeData.uuid,
      docVersionType: 3,
    }).then((res) => {
      if (res.data.success == true) {
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '发布文档成功',
        })
      }
    })
  }

  // 移动至废纸篓
  const removeDocumentHandle = async (event) => {
    setHoveredKey(null)
    setDropdownVisible(false)
    event.stopPropagation()
    const res = await removeDocument({
      docStatus: 1,
      uuids: [nodeData.uuid],
    })
      .then((res) => {
        if (res.data.success == true) {
          events.emit(ACTIONS.DELETE_DOC, {
            id: nodeData.uuid,
          })
          // 如果删除项为当前编辑器显示项，则切换到最近的文档
          if (nodeData.uuid === GetQueryValue('uuid')) {
            let nextItem = findClosestDocument(treeData, nodeData.uuid)

            if (localStorage.getItem('docTypeKey') == '共享文档') {
              window.location.href = `/documentEdit?uuid=${nextItem.uuid}&teamUuid=${nextItem.teamUuid}&folderUuid=${nextItem.parentKey}&from=intelligentDocument`
            } else {
              window.location.href = `/documentEdit?uuid=${nextItem.uuid}&folderUuid=${nextItem.parentKey}&from=intelligentDocument`
            }
          } else {
            setUpdateTreeData(Date.now())
          }
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '移至废纸篓成功',
          })
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '移至废纸篓失败',
        })
      })
  }

  // 当data中某一项被删除后，找到离它最近的一项，并返回这一项，如果没有文档，则返回被删除项
  function findClosestDocument(data, deletedUuid) {
    function findItemAndParent(data, uuid) {
      for (let item of data) {
        if (item.uuid === uuid) {
          return { item, parent: null }
        }
        if (item.children.length > 0) {
          const result = findItemAndParent(item.children, uuid)
          if (result) {
            if (result.parent === null) {
              result.parent = item
            }
            return result
          }
        }
      }
      return null
    }

    function findClosestSiblingDocument(siblings, deletedUuid) {
      for (let sibling of siblings) {
        if (sibling.uuid !== deletedUuid && sibling.fileType === 0) {
          return sibling // Found closest sibling document
        }
      }
      return null
    }

    function findClosestDocumentRecursive(data, deletedItemUuid, parentUuid) {
      if (!data || data.length === 0) return null

      const siblings = data.filter((item) => item.uuid !== deletedItemUuid)
      const closestSiblingDocument = findClosestSiblingDocument(
        siblings,
        deletedItemUuid,
      )
      if (closestSiblingDocument) return closestSiblingDocument

      let closestDocument = null

      for (let item of data) {
        if (item.children.length > 0) {
          closestDocument = findClosestDocumentRecursive(
            item.children,
            deletedItemUuid,
            item.uuid,
          )
        }
        if (closestDocument) break
      }

      if (!closestDocument && parentUuid) {
        const parent = data.find((item) => item.uuid === parentUuid)
        if (parent) {
          closestDocument = findClosestDocumentRecursive(
            parent.children,
            deletedItemUuid,
            parent.parentUuid,
          )
        }
      }

      return closestDocument
    }

    const { item: deletedItem, parent } = findItemAndParent(data, deletedUuid)

    if (deletedItem) {
      const closestSiblingDocument = findClosestSiblingDocument(
        parent ? parent.children : [],
        deletedUuid,
      )
      if (closestSiblingDocument) return closestSiblingDocument

      const closestDocument = findClosestDocumentRecursive(
        data,
        deletedUuid,
        parent ? parent.uuid : null,
      )
      return closestDocument || deletedItem
    }

    return null
  }

  // 收藏文档
  function handleCollect(event) {
    event.stopPropagation()

    // setIscollect(!iscollect)
    if (nodeData.isCollect != true) {
      getDocumentCollect({ uuid: nodeData.uuid })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_TURE, {
            id: nodeData.uuid,
            isCollect: 1,
          })
          setUpdateTreeData(Date.now())
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '收藏文档成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '收藏文档失败',
          })
        })
    } else {
      getCancelCollect({ uuids: [nodeData.uuid] })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_FALSE, {
            id: nodeData.uuid,
            isCollect: 0,
          })
          setUpdateTreeData(Date.now())
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    }
  }

  //标签
  function handleCloseModal() {
    setShowAddTags(false)
    events.emit(ACTIONS.ADD_DOC_TAG)
  }

  //新窗口打开
  const setHref = (event) => {
    event.stopPropagation()
    if (nodeData.fileType == 0) {
      if (localStorage.getItem('docTypeKey') == '共享文档') {
        let url = `/documentEdit?uuid=${nodeData.uuid}&teamUuid=${nodeData.teamUuid}&from=intelligentDocument`
        if (nodeData.parentKey) {
          url += `&folderUuid=${nodeData.parentKey}`
        }
        window.open(url)
      } else {
        let url = `/documentEdit?uuid=${nodeData.uuid}&from=intelligentDocument`
        if (nodeData.parentKey) {
          url += `&folderUuid=${nodeData.parentKey}`
        }
        window.open(url)
      }
    } else {
      window.open('/intelligentDocument')
    }
  }

  // 文档属性
  const onDocAttributeModal = (event) => {
    event.stopPropagation()
    showModal('AttributeModal', {
      title: nodeData.fileType ? '文件夹属性' : '文档属性',
      uuid: nodeData.uuid,
      fileType: nodeData.fileType,
      // onIsVisible: (visible) => {},
    })
  }

  // 协同管理
  const onCollaborationModal = (event) => {
    event.stopPropagation()
    showModal('AddTeamModal', {
      uuid: nodeData.uuid,
      fileType: nodeData.fileType,
      innerType: yjsEditorCoordinationInfo.permission == 1 ? false : true,
      currentPermission: yjsEditorCoordinationInfo.permission,
    })
  }

  const dropList = (
    <Menu className={'custom-dropdown drop-icon'}>
      <React.Fragment>
        <div className={'dropdown-list'}>
          {nodeData.fileType == 1 && (
            <Menu.Item key="0" onClick={handleCoverChange}>
              <IconMagicWand /> 更换封面
            </Menu.Item>
          )}
          <Menu.Item key="1" onClick={hanldeShare}>
            <IconShare />
            分享
          </Menu.Item>
          <Menu.Item key="2" onClick={handleCollect}>
            {nodeData.isCollect ? (
              <>
                <IconCollect /> 取消收藏
              </>
            ) : (
              <>
                <IconCollect /> 收藏
              </>
            )}
          </Menu.Item>
          {localStorage.getItem('docTypeKey') != '共享文档' && (
            <Menu.Item key="3" onClick={addFoldHandle}>
              <IconMove /> 移动
            </Menu.Item>
          )}
          {localStorage.getItem('docTypeKey') != '共享文档' && (
            <Menu.Item
              key="4"
              onClick={(event) => {
                event.stopPropagation()
                setShowAddTags(true)
              }}>
              <IconEditLabel />{' '}
              {nodeData.tags?.length > 0 ? '编辑标签' : '添加标签'}
            </Menu.Item>
          )}
          <Menu.Item key="5" onClick={renameDocumentHandle}>
            <IconEdit /> 重命名
          </Menu.Item>
          {nodeData.fileType == 1 && (
            <SubMenu
              key={'6'}
              className={'export-drop'}
              style={{ justifyContent: 'flex-start' }}
              title={
                <>
                  <IconExport
                    style={{ fontSize: '19px', marginLeft: '-3px' }}
                  />
                  导出
                </>
              }>
              <Menu.Item
                // style={{ marginBottom: '8px' }}
                disabled={exportLoading}
                key="6-1"
                onClick={handelDocToZip}>
                导出为 ZIP 包
              </Menu.Item>
              <Menu.Item
                // style={{ marginBottom: '8px' }}
                disabled={exportLoading}
                key="6-2"
                onClick={handelExportFolder}>
                整合为单个 Word 文档导出
              </Menu.Item>
            </SubMenu>
          )}
          {nodeData.fileType == 0 && (
            <SubMenu
              key={'7'}
              className={'export-drop'}
              style={{ justifyContent: 'flex-start' }}
              title={
                <>
                  <IconExport
                    style={{ fontSize: '19px', marginLeft: '-3px' }}
                  />
                  导出
                </>
              }>
              {ExportBtn.map(({ key, title, type, interfaceName }) => {
                return (
                  <Menu.Item
                    disabled={exportLoading}
                    key={`7-${key}`}
                    onClick={(event) =>
                      exportHandle(event, type, interfaceName)
                    }>
                    {title}
                  </Menu.Item>
                )
              })}
            </SubMenu>
          )}
          {nodeData.fileType == 0 ? (
            <Menu.Item
              style={{ marginBottom: '8px' }}
              key="8"
              onClick={handleSaveDoc}>
              <IconUploadDocument useCurrentColor={true} color="white" />{' '}
              发布文档
            </Menu.Item>
          ) : (
            ''
          )}
          <Menu.Item style={{ marginBottom: '8px' }} key="9" onClick={setHref}>
            <IconOpens useCurrentColor={true} color="white" />
            新窗口打开
          </Menu.Item>
          <Menu.Item key="10" onClick={onDocAttributeModal}>
            <IconAttributes /> {nodeData.fileType ? '文件夹属性' : '文档属性'}
          </Menu.Item>
          <Menu.Item key="11" onClick={onCollaborationModal}>
            <IconPlus /> 协同管理
          </Menu.Item>
        </div>
        <Divider style={{ margin: '4px 0' }} />
        <div className={'dropdown-list'}>
          <Menu.Item
            disabled={nodeData.permission == 1 ? false : true}
            style={{ opacity: nodeData.permission == 1 ? '' : 0.5 }}
            key="11"
            onClick={removeDocumentHandle}>
            <IconWastePaper /> 移至废纸篓
          </Menu.Item>
        </div>{' '}
      </React.Fragment>
    </Menu>
  )

  const dropAdd = (
    <Menu className={'custom-dropdown drop-icon'}>
      <React.Fragment>
        <div className={'dropdown-list'}>
          {nodeData.fileType == 1 && (
            <Menu.Item
              key="0"
              onClick={(event) => {
                event.stopPropagation()
                treeCreateToEditor({
                  from: 'intelligentDocument',
                  filePath: nodeData.filePath,
                  folderUuid: nodeData.uuid,
                  teamUuid: nodeData.teamUuid,
                  permission: nodeData.permission,
                })
              }}>
              <IconFile /> 新建文档
            </Menu.Item>
          )}
          <Menu.Item key="1" onClick={(event) => createDocument(event, 2)}>
            <IconNewTemplateLibrary /> 新建模版文档
          </Menu.Item>
          <Menu.Item key="3" onClick={(event) => createDocument(event, 1)}>
            <IconNewFolder /> 新建章节
          </Menu.Item>
        </div>
      </React.Fragment>
    </Menu>
  )

  //新建文件夹/模版库新建文档
  const createDocument = (event, type) => {
    event.stopPropagation()

    if (type == 1) {
      showModal('FolderCoverModal', {
        Modaltitle: '新建章节',
        title: '',
        folderUuid: nodeData.uuid,
        update: setUpdateTreeData,
      })
    } else {
      showModal('AddTemplateModal', {
        Modaltitle: '模版库新建文档',
        title: '模版库新建文档',
        uuid: nodeData.uuid,
        update: setUpdateTreeData,
        folderUuid: nodeData.uuid,
      })
    }
  }

  //导出文件夹
  function handelDocToZip(event) {
    event.stopPropagation()

    setExportLoading(true)
    axios
      .post(
        `/api/api-${
          switchProject('DFZ') ? 'core' : 'aidb'
        }/document/folderToZip`,
        { uuid: nodeData.uuid },
        { responseType: 'blob' },
      )
      .then((res) => {
        setExportLoading(false)
        const contentDisposition = res.headers['content-disposition']
        const match = contentDisposition.match(
          /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
        )
        let filename
        if (match && match[1]) {
          filename = decodeURIComponent(match[1])
        } else {
          filename = 'report.doc'
        }
        const fileData = new Blob([res.data], { type: 'application/msword' })
        const fileURL = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = filename
        link.click()
        Message.success({
          id: 'need_update',
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: `导出文件夹成功`,
        })
      })
      .catch(() => {
        setExportLoading(false)
        Message.error({
          id: 'need_update',
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `导出文件夹错误`,
        })
      })
  }

  //导出文件夹整合版本
  function handelExportFolder() {
    setExportLoading(true)
    axios
      .post(
        `/api/api-core/document/batchExportFolderContentToWord`,
        { uuid: nodeData.uuid },
        { responseType: 'blob' },
      )
      .then((res) => {
        setExportLoading(false)
        let filename = nodeData.title + '.docx' // 默认文件名

        const fileData = new Blob([res.data], { type: 'application/msword' })
        const fileURL = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = filename
        link.click()
        Message.success({
          id: 'need_update',
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: `导出成功`,
        })
      })
      .catch(() => {
        setExportLoading(false)
        Message.error({
          id: 'need_update',
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `导出错误`,
        })
      })
  }

  //文档导出/word/pdf/markdown
  const exportHandle = async (event, type, interfaceName) => {
    event.stopPropagation()

    setExportLoading(true)
    const res = await getUserInfo({ uid: '' })
    const vipEndDate = new Date(res.data.data.vipEndDate)
    const currentDate = new Date()

    if (currentDate > vipEndDate && !switchProject('DFZ')) {
      Message.error({
        id: 'need_update',
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请开通Vip会员，方可导出',
      })
      setExportLoading(false)
    } else {
      const positionId = localStorage.getItem('positionId')
      const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null

      axios
        .post(
          `/api/api-${
            switchProject('DFZ') ? 'core' : 'aidb'
          }/document/${interfaceName}`,
          { uuid: nodeData.uuid, docVersionId: 0 },
          { responseType: 'blob' },
        )
        .then((res) => {
          setExportLoading(false)
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.${type}`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          Message.success({
            id: 'need_update',
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `导出 ${type} 成功`,
          })
        })
        .catch(() => {
          setExportLoading(false)
          Message.error({
            id: 'need_update',
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 ${type} 错误`,
          })
        })
    }
  }

  //导出message提示
  useEffect(() => {
    if (exportLoading) {
      Message.loading({
        id: 'need_update',
        content: '正在导出中',
        duration: 100000,
      })
    }
  }, [exportLoading])

  //双击
  const handleDouble = () => {
    onDoubleChange(nodeData)
  }

  return (
    <div
      onDoubleClick={(event) => {
        event.preventDefault()
        handleDouble()
      }}
      ref={dropdownRef}
      onMouseEnter={handleNodeHover}
      onMouseLeave={handleNodeLeave}
      onBlur={() => {
        setDropdownVisible(false)
        setHoveredKey(null)
      }}
      className={styles['tree-node-title']}>
      {starDrag ? (
        <>
          {nodeData.fileType === 0 ? (
            <IconTreeFile />
          ) : (
            <img
              style={{ width: '16px', height: '20px' }}
              src={`https://open.v-dk.com/${
                switchProject('DFZ') ? 'dfz' : 'aidb'
              }/wp-content/uploads/sites/10/2023/12/${
                switchProject('DFZ') ? 'dfz' : 'aidb'
              }-folder-${nodeData.fileCoverIcon || 'yellow'}.svg`}
              alt=""
            />
          )}
          <Tooltip content={nodeData.title}>
            <span className={styles['tree-node-title-span']}>
              {nodeData.title}
            </span>
          </Tooltip>
        </>
      ) : (
        <Tooltip content={nodeData.title.length > 9 ? nodeData.title : ''}>
          <>
            {nodeData.fileType === 0 ? (
              <IconTreeFile />
            ) : (
              <img
                style={{ width: '16px', height: '20px' }}
                src={`https://open.v-dk.com/${
                  switchProject('DFZ') ? 'dfz' : 'aidb'
                }/wp-content/uploads/sites/10/2023/12/${
                  switchProject('DFZ') ? 'dfz' : 'aidb'
                }-folder-${nodeData.fileCoverIcon || 'yellow'}.svg`}
                alt=""
              />
            )}
            <Tooltip content={nodeData.title}>
              <span className={styles['tree-node-title-span']}>
                {nodeData.title}
              </span>
            </Tooltip>
          </>
        </Tooltip>
      )}
      {/* 右边功能键 */}
      {(hoveredKey === nodeData._key || dropdownVisible) && (
        <div className={styles['tree-node-content']}>
          {nodeData.permission != 3 && (
            <Dropdown droplist={dropList} position="bottom" trigger={'click'}>
              <IconMoreEdits
                color="black"
                onClick={(e) => handleIconClick(e)}
                style={{
                  marginLeft: 8,
                  fontSize: '18px',
                  fill: theme == 'dark' ? 'white' : 'black',
                }}
              />
            </Dropdown>
          )}
          {nodeData?.fileType == 1 && nodeData.permission == 1 && (
            <Dropdown droplist={dropAdd} position="bottom" trigger={'click'}>
              <IconPlus
                onClick={(e) => handleIconClick(e)}
                style={{
                  marginLeft: 8,
                  fontSize: '18px',
                  fill: theme == 'dark' ? 'white' : 'black',
                }}
              />
            </Dropdown>
          )}
        </div>
      )}

      <ModalCustom
        theme={theme}
        maskClosable={false}
        title={nodeData.tags?.length > 0 ? '编辑标签' : '添加标签'}
        visible={showAddTags}
        close={handleCloseModal}
        content={
          <AddTags
            theme={theme}
            tagsList={tagsList && tagsList}
            uuid={nodeData.uuid}
            tags={nodeData.tag && nodeData.tag}
          />
        }
      />
    </div>
  )
}

export default TreeNodeTitle
