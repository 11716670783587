import { useEffect, useRef, useState } from 'react'
import styles from './style/index.module.less'
import React from 'react'
import { Button, Form, Input, Message, Modal } from '@arco-design/web-react'
import { IconClose, IconReviewCompleted } from '@arco-iconbox/react-aidb'
import { approvalDocument, getDocumentDetail } from '@/model/reference'
import { GetQueryValue } from '@/utils/common'
import Writing from '../writing'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import IconMsgSuccess from '@/assets/newImgs/msg-success.svg'
import EmptyView from '@/components/Empty'
import CommentItem from '@/components/StudentDetail'
import {
  deleteComment,
  editComment,
  getCommentList,
  saveByTeacher,
  saveComment,
} from '@/model/documentEdit'
import ModalDefault from '@/components/Modal'
import { ApprovalStatus } from '@/helpers/enums'
import SideBar from '@/components/SideBar'
import { showModal } from '@/share/actions'
import { IconSuccessTip } from '@arco-iconbox/react-aidb-v2'

function StudentDetail() {
  const FormItem = Form.Item
  const TextArea = Input.TextArea
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [dataTitle, setDataTitle] = useState('')
  const [dataContent, setDataContent] = useState('')
  const [commentContent, setCommentContent] = useState<string>()
  const [commentId, setCommentId] = useState<string>()
  const [commentClass, setCommentClass] = useState<string>()
  const [contentLen, setContentLen] = useState()
  const [isEditComment, setIsEditComment] = useState(false)
  const textAreaRef = useRef(null)
  const editContentRef = useRef(null)
  const [commentList, setCommentList] = useState([])

  // useEffect(() => {
  //   localStorage.setItem('commentList', JSON.stringify(commentList))
  // }, [commentList.length])

  useEffect(() => {
    events.on(ACTIONS.ADD_COMMENT, handleAddComment)
    return () => {
      events.off(ACTIONS.ADD_COMMENT, handleAddComment)
    }
  }, [])

  // 获取列表数据
  useEffect(() => {
    const uuid = GetQueryValue('uuid')
    getDocumentDetail({
      uuid: uuid,
    }).then((res) => {
      setDataTitle(res.data.data.title)
      setDataContent(res.data.data.content)

      setContentLen(res.data.data.docWords)
    })
    getCommentListHandle()
  }, [])

  const getCommentListHandle = async () => {
    const res = await getCommentList({
      uuid: GetQueryValue('uuid'),
    })
    setCommentList(res.data.data.records)
  }

  //浮窗点击事件
  const handleAddComment = (payload) => {
    setIsEditComment(false)
    setCommentContent('')
    setCommentId(payload.id)
    setCommentClass(payload.class)
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus()
    }
    if (commentId && !isEditComment) {
      const newList = commentList.map((item) => {
        return {
          ...item,
          isEdit: false,
        }
      })
      setCommentList(newList)
    }
  }, [commentId])

  const onOk = () => {
    form.validate().then(() => {
      setVisible(false)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '填写审核意见完成',
      })
    })
  }

  const goBack = () => {
    window.history.go(-1)
  }

  //审核完毕
  const approval = (type) => {
    if (!isEditComment) {
      events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
        id: commentId,
        className: commentClass,
      })
    }
    showModal('ConfirmModal', {
      title: '审核完毕',
      content: '确认是否审核完毕？',
      onConfirm: () => {
        approvalDocument({
          approvalStatus: type,
          remark: form.getFieldsValue().name,
          uuid: GetQueryValue('uuid'),
        }).finally(() => {
          form.resetFields()
        })
        //测试保存
        saveDocHandle(ApprovalStatus.Reviewed)
        setTimeout(() => {
          goBack()
        }, 2000)
      },
    })
  }

  //保存文章内容接口
  const saveDocHandle = async (approvalStatus) => {
    const res = await saveByTeacher({
      title: dataTitle,
      approvalStatus: approvalStatus,
      content: editContentRef.current ? editContentRef.current : dataContent,
      uuid: GetQueryValue('uuid'),
    })
    if (res.data.success && approvalStatus == ApprovalStatus.Reviewed) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '审核完毕',
      })
    }
  }

  //编辑按钮
  const editCommentHandle = (id, content, className) => {
    setIsEditComment(true)
    setCommentContent(content)
    setCommentId(id)
    setCommentClass(className)
    const newList = commentList.map((item) => {
      if (item.uuid === id) {
        return {
          ...item,
          isEdit: true,
        }
      }
      return {
        ...item,
        isEdit: false,
      }
    })
    setCommentList(newList)
  }

  //删除批注
  const cancelCommentHandle = (id, commentClass) => {
    events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
      id: commentClass,
      className: commentClass,
    })
    cancelPostCommentHandle(id)
  }

  const changeCommentHandle = (e) => {
    setCommentContent(e)
  }

  //提交批注按钮
  const submitCommentHandle = () => {
    if (commentContent == '' || !commentId) return
    events.emit(ACTIONS.REMOVE_COMMENT_HL_EDITOR, {
      commentClass: commentClass,
      commentId: commentId,
    })
    const commentIdNew = commentList.filter((obj) => obj.uuid == commentId)

    if (commentIdNew.length == 0) {
      saveCommentHandle()
    } else {
      commentIdNew[0].content = commentContent
      editPostCommentHandle()
    }

    setCommentContent('')
    setCommentId('')
    setCommentClass('')

    const newList = commentList.map((item) => {
      return {
        ...item,
        isEdit: false,
      }
    })
    setCommentList(newList)

    events.emit(ACTIONS.CAN_NEXT_COMMENT, {
      isComment: true,
    })
  }

  const changeContentHandle = (content) => {
    editContentRef.current = content
  }

  //保存批注接口
  const saveCommentHandle = async () => {
    events.emit(ACTIONS.REMOVE_COMMENT_HL_EDITOR, {
      commentClass: commentClass,
      commentId: commentId,
    })
    const res = await saveComment({
      uuid: GetQueryValue('uuid'),
      content: commentContent,
      className: commentClass,
    })
    const { uuid, createDate, className } = res.data.data
    setCommentList((commentList) => {
      return [
        ...commentList,
        {
          uuid: uuid,
          className: className,
          content: commentContent,
          createDate: createDate,
        },
      ]
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '提交批注成功',
      })
      saveDocHandle(ApprovalStatus.PendingApproval)
    }
  }

  //删除批注接口
  const cancelPostCommentHandle = async (id) => {
    const res = await deleteComment({
      uuid: id,
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '删除批注成功',
      })
      saveDocHandle(ApprovalStatus.PendingApproval)
      setCommentList((commentList) => {
        return commentList.filter((item) => item.uuid !== id)
      })
    }
  }

  //编辑批注接口
  const editPostCommentHandle = async () => {
    const res = await editComment({
      uuid: commentId,
      className: commentClass,
      content: commentContent + 'ppp',
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '编辑批注成功',
      })
      saveDocHandle(ApprovalStatus.PendingApproval)
    }
  }

  //取消按钮
  const closeCommentHandle = () => {
    setCommentContent('')
    setCommentId('')
    if (!isEditComment) {
      events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
        id: commentId,
        className: commentClass,
      })
    }
    const newList = commentList.map((item) => {
      return {
        ...item,
        isEdit: false,
      }
    })
    setCommentList(newList)
    setIsEditComment(false)
  }

  return (
    <div
      className={`${styles['workspace-layout']} ${styles['pr0']} ${styles['pb0']}`}>
      <div className={styles['side-bar-wrap']}>
        <SideBar fold={true} onGoBack={goBack} />
      </div>
      <div className={styles['student-note']}>
        <div className={styles['article']}>
          <Writing
            docTitle={dataTitle}
            docContent={dataContent}
            isComment={true}
            changeContent={changeContentHandle}
            btnWidth={116}
            btnHeight={40}
            contentLen={contentLen}
          />
          <div className={styles['article-bot']}>
            <div className={styles['input']} onClick={() => setVisible(true)}>
              填写审核意见
            </div>

            <Button
              onClick={() => approval(3)}
              className={`${styles['btn-color2']} ${styles['g-btn']}`}
              icon={
                <IconReviewCompleted
                  useCurrentColor={true}
                  style={{ fill: '#fff', fontSize: 22 }}
                />
              }>
              审批完毕
            </Button>
          </div>
        </div>
        <div className={styles['note-side']}>
          <div className={styles['textarea-box']}>
            <div className={styles['header']}>
              <div className={styles['title']}>填写批注内容：</div>
              <div className={styles['closeBtn']} onClick={closeCommentHandle}>
                <IconClose />
              </div>
            </div>
            <TextArea
              style={{
                minHeight: 100,
                width: '100%',
              }}
              value={commentContent}
              onChange={changeCommentHandle}
              placeholder="在内容区选中后，输入批注内容"
              disabled={!!!commentId}
              ref={textAreaRef}
            />
            <Button
              className={`${styles['btn-color2']} ${styles['g-btn']} ${!!!commentContent ? `${styles['disabled']}` : ''
                }`}
              disabled={!!!commentContent}
              onClick={submitCommentHandle}>
              提交批注
            </Button>
          </div>

          {commentList.length === 0 ? (
            <div style={{ marginTop: '100px' }}>
              <EmptyView
                type="commentEmpty"
                title="暂无批注内容"
                titleSize={18}
              />
            </div>
          ) : (
            <div className={styles['list-box']}>
              <div className={styles['title']}>已批注内容：</div>
              <div className={styles['doc-list']}>
                {commentList.map(
                  ({ content, uuid, createDate, className, isEdit }, index) => {
                    return (
                      <CommentItem
                        key={uuid + index}
                        id={uuid}
                        content={content}
                        date={createDate}
                        className={className}
                        isEdit={isEdit}
                        onEditComment={editCommentHandle}
                        onCancelComment={cancelCommentHandle}></CommentItem>
                    )
                  },
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/*填写审核意见*/}
      <Modal
        title={<div style={{ textAlign: 'left' }}>填写审核意见</div>}
        className={styles['custom-modal']}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={
          <>
            <div className={styles['pop-btn']} onClick={onOk}>
              确定
            </div>
          </>
        }>
        <Form
          form={form}
          wrapperCol={{
            style: { flexBasis: '100%' },
          }}>
          <FormItem
            label=""
            field="name"
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: '请输入审批意见' }]}>
            <TextArea
              className={`${styles['custom-textarea']} ${styles['border']}`}
              placeholder="内容"
              style={{
                minHeight: 120,
                width: '100%',
              }}
            />
          </FormItem>
        </Form>
      </Modal>
      <ModalDefault></ModalDefault>
    </div>
  )
}

export default StudentDetail
