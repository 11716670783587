import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import {
  IconAiTool,
  IconExpand,
  IconInstruction,
  IconParaphrase,
  IconStrongRewrite,
  IconTranslate,
} from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'
import { Dropdown, Menu, Tooltip } from '@arco-design/web-react'

interface AiToolsProps {
  name: string
}

const PLUGIN_NAME = 'AiTools'

class AiToolsSchema extends Inline<AiToolsProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class AiToolsController extends SylController<AiToolsProps> {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.TO_EDITOR, this.replaceHandle)
  }

  editorWillUnmount() {
    events.off(ACTIONS.TO_EDITOR, this.replaceHandle)
  }

  public updateDocHandle = (_editor: SylApi, type) => {
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    // this.clickFromAiToolsEditor(selectedContent);
    this.clickFromEditor(selectedContent, type, nodeInfo)
  }

  public clickFromEditor = (selectedContent, btnType, nodeInfo) => {
    console.log(btnType, 'btnType', DocBtnType.reduceSingle)
    if (DocBtnType.rewriteSingle == btnType) {
      events.emit(ACTIONS.FROM_REWRITE_CLICK_EDITOR, {
        setSelectContent: selectedContent,
        barActiveIndex: btnType,
        nodeInfo: nodeInfo,
      })
    } else if (DocBtnType.reduceSingle == btnType) {
      events.emit(ACTIONS.FROM_REDUCE_CLICK_EDITOR, {
        setSelectContent: selectedContent,
        barActiveIndex: btnType,
        nodeInfo: nodeInfo,
      })
    } else if (DocBtnType.continuationSingle == btnType) {
      events.emit(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, {
        setSelectContent: selectedContent,
        barActiveIndex: btnType,
        nodeInfo: nodeInfo,
      })
    } else if (DocBtnType.translateSingle == btnType) {
      events.emit(ACTIONS.FROM_TRANS_CLICK_EDITOR, {
        setSelectContent: selectedContent,
        barActiveIndex: btnType,
        nodeInfo: nodeInfo,
      })
    }
  }

  public replaceHandle = (payload) => {
    // Get the style of the old text
    const { nodeInfo, content } = payload
    if (!nodeInfo) return
    const oldTextNode = this.editor.view.state.doc.nodeAt(nodeInfo.index)
    const oldTextStyle = oldTextNode.marks

    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      this.editor.view.state.schema.text(content, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    // this.removeHighlightAndTip();
    events.emit(ACTIONS.FROM_EDITOR, {
      setSelectContent: null,
      barActiveIndex: payload.type,
      nodeInfo: null,
    })
  }

  public dropList = (
    <Menu className={'custom-dropdown'} style={{ padding: '10px 10px' }}>
      <Menu.Item key="1" style={{ padding: '0px' }}>
        <div style={{ display: 'flex', padding: '0 10px' }}>
          <IconParaphrase
            style={{ fontSize: '20px', width: '20px' }}
            className="iconParaphrase icon"
            useCurrentColor={true}></IconParaphrase>
          <span
            style={{ marginLeft: '14px' }}
            onClick={() =>
              this.updateDocHandle(this.editor, DocBtnType.rewriteSingle)
            }>
            改写
          </span>
        </div>
      </Menu.Item>
      <Menu.Item key="2" style={{ padding: '0px' }}>
        <div style={{ display: 'flex', padding: '0 10px' }}>
          <IconStrongRewrite
            style={{ fontSize: '20px', width: '20px' }}
            className="iconParaphrase icon"
            useCurrentColor={true}></IconStrongRewrite>
          <span
            style={{ marginLeft: '14px' }}
            onClick={() =>
              this.updateDocHandle(this.editor, DocBtnType.reduceSingle)
            }>
            降重
          </span>
        </div>
      </Menu.Item>
      <Menu.Item key="3" style={{ padding: '0px' }}>
        <div style={{ display: 'flex', padding: '0 10px' }}>
          <IconExpand
            style={{ fontSize: '20px', width: '20px' }}
            className="iconParaphrase icon"
            useCurrentColor={true}></IconExpand>
          <span
            style={{ marginLeft: '14px' }}
            onClick={() =>
              this.updateDocHandle(this.editor, DocBtnType.continuationSingle)
            }>
            扩写
          </span>
        </div>
      </Menu.Item>
      <Menu.Item key="4" style={{ padding: '0px' }}>
        <div style={{ display: 'flex', padding: '0 10px' }}>
          <IconTranslate
            style={{ fontSize: '20px', width: '20px' }}
            className="iconParaphrase icon"
            useCurrentColor={true}></IconTranslate>
          <span
            style={{ marginLeft: '14px' }}
            onClick={() =>
              this.updateDocHandle(this.editor, DocBtnType.translateSingle)
            }>
            翻译
          </span>
        </div>
      </Menu.Item>
    </Menu>
  )

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    // type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div style={{ display: 'flex' }}>
          <Dropdown
            droplist={this.dropList}
            position="bottom"
            trigger={'hover'}
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }>
            <div className="AiToolsBox iconBox">
              <IconAiTool
                className="iconParaphrase icon"
                useCurrentColor={true}
              />

              <span className="iconTitle">AI工具</span>
            </div>
          </Dropdown>
          |
        </div>
      )
    },
    value: [],
  }
}

class AiToolsPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = AiToolsController
  public Schema = AiToolsSchema
}

export { AiToolsPlugin }
