import React from 'react';
import styles from './style/index.module.less';
import Side from '@/components/ReferenceDetail/side';

export default function ReferenceDetail() {
  const workData = {
    title: '鲁迅自编文集野草',
    menuList: [
      {
        sectionId: 4,
        name: '第一章',
        content:
          '<h1>鲁迅自编文集野草</h1><br><br><br><h3>简介</h3><br><p>本书原为作者在北京大学授课时的讲义，后经修订增补，先后于一九二三年、一九二四年由北京大学新潮社以《中国小说史略》为题分上下册出版，一九二五年由北京北新书局合印一册出版。一九三一年北新书局出修订本初版。一九三五年第十版时又作个别改订，以后各版均与第十版同。</p><br><h3>题记</h3><br><p>第01篇 史家对于小说之著录及论述</p><p>第02篇 神话与传说</p><p>第03篇 《汉书》《艺文志》所载小说</p><p>第04篇 今所见汉人小说</p><p>第05篇 六朝之鬼神志怪书（上）</p><p>第06篇 六朝之鬼神志怪书（下）</p><p>第07篇 《世说新语》与其前后</p><p>第08篇 唐之传奇文（上）</p><p>第09篇 唐之传奇文（下）</p><p>第10篇 唐之传奇集及杂俎</p>',
      },
      {
        sectionId: 5,
        name: '第二章',
        content: '第一章',
      },
      {
        sectionId: 6,
        name: '第三章',
        content: '第一章',
      },
    ],
  };
  return (
    <div className={styles['layout']}>
      <aside className={styles['aside-wrapper']}>
        <Side title={workData.title} menuList={workData.menuList} />
      </aside>
      <section className={styles['page-content']}>
        <div className={styles['article']}>
          <div
            dangerouslySetInnerHTML={{ __html: workData.menuList[0].content }}
          ></div>
        </div>
      </section>
    </div>
  );
}
