import React, { useEffect, useState } from 'react'
import styles from './style/golbal-tem.module.less'
import StepHeaderMod from './StepHeaderMod'
import EmptyMod from './TemEmptyMod'
import TemRightMod from './TemRightMod'
import TemFormMod from './TemFormMod'
import Icon1 from '../../assets/newImgs/h-i3.svg'
import { GetQueryValue } from '@/utils/common'
import { getUserInfo } from '@/model/reference'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import Modal from '@/components/Modal/index'

function GolbalTem(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  const [curStep, setCurStep] = useState(0)
  const [gptText, setGptText] = useState([])
  const [messageId, setMessageId] = useState('')
  const [membershipType, setMembershipType] = useState<any>(
    localStorage.getItem('vipLevel'),
  )

  useEffect(() => {
    getUserInfo({ uid: '' }).then((res) => {
      setMembershipType(res.data.data.vipLevel)
    })
  }, [])

  useEffect(() => {
    document.title = '智能模版 - 悟智AI'
  }, [])

  const [templateData, setTemplateData] = useState(null)

  useEffect(() => {
    const cachedTemplateData = sessionStorage.getItem('selectTem')
      ? sessionStorage.getItem('selectTem')
      : getJson()

    if (cachedTemplateData) {
      setTemplateData(JSON.parse(cachedTemplateData))
    }
  }, [])

  function getJson() {
    const cachedTemplateData = localStorage.getItem('templateData')
    const initialTemplateData = cachedTemplateData
      ? JSON.parse(cachedTemplateData)
      : null
    const id = GetQueryValue('id')
    const children = initialTemplateData[GetQueryValue('tab')].children
    const Heightcommon = initialTemplateData[GetQueryValue('tab')].Heightcommon
    const Heightprompt = initialTemplateData[GetQueryValue('tab')].Heightprompt
    const item = children.find((child) => child.uuid == id)

    const array = {
      Heightcommon: Heightcommon,
      Heightprompt: Heightprompt,
      title: item.title,
      info: item.info,
      icon: item.icon,
      id: item.uuid,
      type: item.type,
      step: item.step,
      prompt: item.prompt,
      is_use_common_field: item.is_use_common_field,
    }
    sessionStorage.setItem('selectTem', JSON.stringify(array))
    return JSON.stringify(array)
  }

  function jumpTo(cur, obj) {
    setCurStep(cur)
  }

  function handleUpdateCurStep(newStep, newTxt) {
    setCurStep(newStep)
    setGptText([newTxt, ...gptText])
  }

  return (
    <div className={`${styles.writePage} ${styles[theme]}`}>
      {templateData && (
        <>
          <StepHeaderMod
            membershipType={membershipType}
            curStep={curStep}
            hideStep={true}
            title={templateData.title}
            theme={theme}
          />
          <div className={styles.content}>
            <div className={styles.content_left}>
              <TemFormMod
                templateData={templateData}
                curStep={curStep}
                jumpTo={(cur, obj) => jumpTo(cur, obj)}
                onUpdateCurStep={handleUpdateCurStep}
                onSetMessageId={(e) => {
                  setMessageId(e)
                }}
                theme={theme}
              />
            </div>
            <div className={styles.content_right}>
              {curStep === 0 && (
                <EmptyMod
                  icon={<Icon1 />}
                  text={
                    <div>
                      <p>请填写内容或要求</p>
                      <p>AI 为您智能生成{templateData.title}</p>
                    </div>
                  }
                />
              )}
              {curStep !== 0 && (
                <TemRightMod
                  curStep={curStep}
                  gptText={gptText}
                  jumpTo={(cur, obj) => jumpTo(cur, obj)}
                  theme={theme}
                  messageId={messageId}
                />
              )}
            </div>
          </div>
        </>
      )}
      <Modal></Modal>
    </div>
  )
}

export default GolbalTem
