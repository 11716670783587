import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconContinue } from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'

interface ContinueProps {
  name: string
}

const PLUGIN_NAME = 'Continue'

class ContinueSchema extends Inline<ContinueProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class ContinueController extends SylController<ContinueProps> {
  public name = PLUGIN_NAME

  public updateDocHandle = (_editor: SylApi) => {
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)

    events.emit(ACTIONS.FROM_EDITOR, {
      setSelectContent: selectedContent,
      barActiveIndex: DocBtnType.continuation,
      nodeInfo: nodeInfo,
    })
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    tooltip: PLUGIN_NAME,
    type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div
          className="continuationBox iconBox"
          onClick={() => this.updateDocHandle(_editor)}>
          <IconContinue
            className="iconContinue icon"
            useCurrentColor={true}></IconContinue>
          <span>扩写</span>
        </div>
      )
    },
    value: [],
  }
}

class ContinuePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = ContinueController
  public Schema = ContinueSchema
}

export { ContinuePlugin }
