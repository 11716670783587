import defaultSettings from '../settings.json'
export interface GlobalState {
  settings?: typeof defaultSettings
  userInfo?: {
    name?: string
    avatar?: string
    job?: string
    organization?: string
    location?: string
    email?: string
    permissions: Record<string, string[]>
  }
  userLoading?: boolean
  theme?: string // 添加 theme 字段
  showQuick?: boolean
  detailData?: any
  tagsList?: any
  agentTabIndex?: any
  // 文本粘贴 | markdown粘贴 | 数学公式粘贴 | 混合粘贴
  editorPasteMethodType:
  | 'text_paste'
  | 'mark_paste'
  | 'formula_paste'
  | 'mix_paste'
  yjsEditorCoordinationInfo: {
    clientID: number
    isOnline: [
      {
        id: number
        name: string
        online: boolean
      },
    ]
    userInfo: {
      id: number
    }
    employeeList: {
      employeeList: Array<any>
      total: number
    }
    awarenessMap: Array<any>
    permission: number
  }
}
//判断系统暗黑模式设置
// function isDarkModeEnabled() {
//   return (
//     window.matchMedia &&
//     window.matchMedia('(prefers-color-scheme: dark)').matches
//   )
// }

// const isMiniProgram = navigator.userAgent.includes('miniProgram')
// const initialTheme =
//   localStorage.getItem('theme') ||
//   (isMiniProgram ? 'light' : isDarkModeEnabled() ? 'dark' : 'light')

const initialState: GlobalState = {
  settings: defaultSettings,
  userInfo: {
    permissions: {},
  },
  theme: 'light',
  showQuick: false,
  detailData: {},
  tagsList: [],
  agentTabIndex: 'home',
  editorPasteMethodType: 'text_paste',
  yjsEditorCoordinationInfo: {
    // 当前客户端ID
    clientID: 0,
    // 是否在线
    isOnline: [
      {
        id: 0,
        name: '',
        online: false,
      },
    ],
    // 当前用户信息
    userInfo: {
      id: 0,
    },
    // 当前协同列表
    employeeList: {
      employeeList: [],
      total: 0,
    },
    // 所有协同用户操作信息
    awarenessMap: [],
    // 当前权限
    permission: -1
  },
}

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings': {
      const { settings } = action.payload
      return {
        ...state,
        settings,
      }
    }
    case 'update-userInfo': {
      const { userInfo = initialState.userInfo, userLoading } = action.payload
      return {
        ...state,
        userLoading,
        userInfo,
      }
    }
    case 'update-theme': {
      // 处理更新主题的 action
      const { theme } = action.payload
      localStorage.setItem('theme', theme) // 更新本地存储中的主题
      return {
        ...state,
        theme,
      }
    }
    case 'update-showQuick': {
      // 处理更新主题的 action
      const { showQuick } = action.payload
      return {
        ...state,
        showQuick,
      }
    }
    case 'update-detailData': {
      // 处理更新主题的 action
      const { detailData } = action.payload
      return {
        ...state,
        detailData,
      }
    }
    case 'update-tagsList': {
      // 处理更新主题的 action
      const { tagsList } = action.payload
      return {
        ...state,
        tagsList,
      }
    }
    case 'update-agentTab': {
      // 处理更新主题的 action
      const { agentTabIndex } = action.payload
      return {
        ...state,
        agentTabIndex,
      }
    }
    case 'editor-paste-method-type': {
      // 粘贴方式
      const { editorPasteMethodType } = action.payload
      return {
        ...state,
        editorPasteMethodType,
      }
    }
    case 'yjs-editor-coordination-info': {
      const { yjsEditorCoordinationInfo } = action.payload

      // 获取当前的状态
      const currentYjsEditorCoordinationInfo = state.yjsEditorCoordinationInfo

      // 确保各字段类型正确
      const safeEmployeeList =
        typeof yjsEditorCoordinationInfo.employeeList === 'object' &&
          yjsEditorCoordinationInfo.employeeList !== null
          ? {
            ...currentYjsEditorCoordinationInfo.employeeList,
            ...yjsEditorCoordinationInfo.employeeList,
          }
          : currentYjsEditorCoordinationInfo.employeeList

      const safeUserInfo =
        typeof yjsEditorCoordinationInfo.userInfo === 'object' &&
          yjsEditorCoordinationInfo.userInfo !== null
          ? {
            ...currentYjsEditorCoordinationInfo.userInfo,
            ...yjsEditorCoordinationInfo.userInfo,
          }
          : currentYjsEditorCoordinationInfo.userInfo

      const safeAwarenessMap = Array.isArray(
        yjsEditorCoordinationInfo.awarenessMap,
      )
        ? [
          ...currentYjsEditorCoordinationInfo.awarenessMap,
          ...yjsEditorCoordinationInfo.awarenessMap,
        ]
        : currentYjsEditorCoordinationInfo.awarenessMap

      return {
        ...state,
        yjsEditorCoordinationInfo: {
          ...currentYjsEditorCoordinationInfo, // 保留已有的数据
          ...yjsEditorCoordinationInfo, // 覆盖或新增传递过来的数据
          employeeList: safeEmployeeList,
          userInfo: safeUserInfo,
          awarenessMap: safeAwarenessMap,
        },
      }
    }
    default:
      return state
  }
}
