import { Block, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

interface EditorNavProps {
  strike: boolean
}

const PLUGIN_NAME = 'editorNav'

class EditorNavSchema extends Block<EditorNavProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'div'

  // 解析DOM，什么数据会被识别为删除线
  public parseDOM = []

  public toDOM = () => {
    return ['div', 0] as DOMOutputSpec
  }
}

class EditorNavController extends SylController<EditorNavProps> {
  public name = PLUGIN_NAME
  //   private sylEditorBox: HTMLElement;
  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.NAV_CLICk_EDITOR, this.navClickScroll)
    events.on(ACTIONS.RETURN_TOP_EDITOR, this.returnBack)
    // this.sylEditorBox.addEventListener('scroll', this.handleScroll);
  }

  editorWillUnmount() {
    events.off(ACTIONS.NAV_CLICk_EDITOR, this.navClickScroll)
    events.off(ACTIONS.RETURN_TOP_EDITOR, this.returnBack)
  }

  private returnBack = () => {
    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    setTimeout(() => {
      sylEditorBox.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 300)
  }

  private navClickScroll = (payload) => {
    const { top: editorTop } = this.editor.root.getBoundingClientRect()
    const { bottom: headBottom } = this.editor.view.coordsAtPos(
      payload.position,
    )

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)

    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop + 40,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop + 40,
          behavior: 'smooth',
        })
      }, 300)
    }
  }
}

class EditorNavPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = EditorNavController
  public Schema = EditorNavSchema
}

export { EditorNavPlugin }
