import React, { useEffect, useState } from 'react'
import OutlinePickItem from './OutlinePickItem'
import { Radio } from '@arco-design/web-react'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

const RadioGroup = Radio.Group

function OutlinePickList(props) {
  const list = props.list ? props.list : []
  const [checked1, setChecked1] = useState('')
  useEffect(() => {
    events.on(ACTIONS.TEM_OUTLINEBTN, checkedOutline)

    return () => {
      events.off(ACTIONS.TEM_OUTLINEBTN, checkedOutline)
    }
  })

  function checkedOutline(payload) {
    console.log(payload, props.checked?.length, '----')
    if (payload && props.checked.length == 0) {
      // 默认选择第一项
      props.changeChecked(list)
    }
  }

  return (
    <div>
      <RadioGroup
        direction="vertical"
        value={props.checked}
        onChange={(value) => {
          props.changeChecked(value)
          console.log(value, 'selectedItems')
        }}
        style={{ display: 'block' }}>
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <OutlinePickItem
                theme={props.theme}
                key={index}
                content={item}
                checked={props.checked}
              />
            )
          })}
      </RadioGroup>
    </div>
  )
}

export default OutlinePickList
