import React, { useEffect, useRef, useState } from 'react'
import style from './style/directivesMod.module.less'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  IconClosure,
  IconErrorTip,
  IconLink2,
} from '@arco-iconbox/react-aidb-v2'
import { Dropdown, InputTag, Message } from '@arco-design/web-react'
import Btn from '../Ui/btn'
import palette from '@/helpers/palette'
import { DirectivesItem } from './editItem'
import { DocBtnType } from '@/helpers/enums'
import request from '@/utils/request'
import TipPromptProps from '@/components/DocumentEdit/tipPrompt'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import { nonEmptyArray, textStreamGPTError } from '@/utils/common'
import EmptyView from '@/components/Empty'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

interface Props {
  nodeInfo?: any
  tagSelectList: any
  inputValue: string
  directivesStartPos: number
  directivesTagVal: string[]
  onDelDirectivesTagVal: () => void
  onDirectivesComplete: (isComplete, isHasList: boolean) => void
}

const DirectivesMod = (props: Props) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const {
    tagSelectList,
    inputValue,
    directivesTagVal,
    directivesStartPos,
    onDelDirectivesTagVal,
    onDirectivesComplete,
  } = props
  const [editTagSelectList, setEditTagSelectList] = useState(tagSelectList)
  const [editInputValue, setEditInputValue] = useState(inputValue)
  const [tagList, setTagList] = useState([])
  const [showTipPrompt, setShowTipPrompt] = useState<boolean>(false)
  const [fixContent, setFixContent] = useState<string>('')
  const [directivesList, setDirectivesList] = useState([])
  const [showExample, setShowExample] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [tagValList, setTagValList] = useState(directivesTagVal)
  const editTagSelectListRef = useRef([])
  const tagValListRef = useRef([])
  const contentRef = useRef('')
  let retryCount = 0
  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    setTagValList(directivesTagVal)
    tagValListRef.current = directivesTagVal
  }, [directivesTagVal])

  useEffect(() => {
    if (editInputValue?.length > 0 || tagValListRef.current?.length > 0) {
      setIsComplete(false)
      setShowExample(false)
      contentRef.current = null
      setTagValList(directivesTagVal)
      setDirectivesList([])
      setEditInputValue(editInputValue)
      setEditTagSelectList(tagSelectList)
      retryCount = 0
      getDirectivesContent(
        tagSelectList.length > 0
          ? tagSelectList[0].prompt +
              `${
                inputValue &&
                tagValListRef.current.length > 0 &&
                tagValListRef.current[0].length > 0
                  ? '我给定的内容是：' +
                    tagValList[0] +
                    '\n\n我的额外需求是：' +
                    inputValue
                  : `${tagValList.length > 0 ? tagValListRef.current[0] : ''}` +
                    `${inputValue ? inputValue : ''}`
              }`
          : `${
              inputValue &&
              tagValList.length > 0 &&
              tagValListRef.current[0].length > 0
                ? '我给定的内容是：' +
                  tagValList[0] +
                  '\n\n我的额外需求是：' +
                  inputValue
                : `${tagValList.length > 0 ? tagValListRef.current[0] : ''}` +
                  `${inputValue ? inputValue : ''}`
            }`,
        tagSelectList,
      )
    }
  }, [tagSelectList, directivesTagVal, inputValue])

  useEffect(() => {
    setIsComplete(false)

    setEditInputValue(inputValue)
    contentRef.current = null
    setShowExample(false)
  }, [inputValue])

  useEffect(() => {
    if (isComplete) {
      contentRef.current = null
      setShowExample(false)
    }
    onDirectivesComplete(isComplete, directivesList.length > 0)
  }, [isComplete, showExample])

  const getDirectivesContent = async (contents, list) => {
    setIsComplete(false)
    setShowExample(false)
    let tmpText = ''
    if (!contents) {
      return
    }
    const opts = {
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: contents,
        // message: '你好',
        conversationId: '',
        parentMessageId: '',
        stream: true,
        type: 3,
      }),
    }
    try {
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        process.env.REACT_APP_CHATGPT_URL + '/streamMessage',
        {
          ...opts,
          signal: controller.signal,
          onclose() {
            setIsComplete(true)
            setShowExample(false)
            // throw new Error(
            //   `Failed to send message. Server closed the connection unexpectedly.`,
            // )
            request
              .post(
                process.env.REACT_APP_CHATGPT_URL + '/streamMessage',
                JSON.stringify({
                  message: contents,
                  conversationId: '',
                  parentMessageId: '',
                  stream: true,
                  type: 3,
                }),
              )
              .then((res) => {
                console.log(res)
                const error = res.data.data.text
                // setIsContinueLoading(false)
                setIsComplete(true)
                setShowExample(false)
                console.log(error, 'error')
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: textStreamGPTError(error),
                })
                return
              })
          },
          onerror(error) {
            request
              .post(
                process.env.REACT_APP_CHATGPT_URL + '/streamMessage',
                JSON.stringify({
                  message: contents,
                  conversationId: '',
                  parentMessageId: '',
                  stream: true,
                  type: 3,
                }),
              )
              .then((res) => {
                console.log(res)
                const error = res.data.data.text
                // setIsContinueLoading(false)
                setIsComplete(true)
                setShowExample(false)
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: textStreamGPTError(error),
                })
                return
              })
            throw error
          },
          onmessage(message) {
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = JSON.parse(message.data).response
            } else {
              if (JSON.parse(message.data).code == 503) {
                setIsComplete(true)
                setShowExample(false)
                Message.error({
                  icon: <IconErrorTip useCurrentColor={false} />,
                  content: '接口调用频繁，请重试1',
                })
                return
              } else {
                tmpText =
                  message.data == '[DONE]'
                    ? tmpText
                    : tmpText +
                      message.data.substring(1, message.data.length - 1)

                // if (tmpText.length > 0) {
                //   setShowExample(true)
                // }
              }
            }
            let fixContent = tmpText.replaceAll('\\n', ' \n ')
            contentRef.current = tmpText.replaceAll('\\n', ' \n ')
            setFixContent(fixContent)
            if (message.event == 'result') {
              controller.abort()
              contentRef.current = null
              console.log(tmpText, 'tmpText')
              // if (!tmpText) {
              //   setIsComplete(true)
              //   setShowExample(false)
              //   Message.error({
              //     icon: <IconErrorTip useCurrentColor={false} />,
              //     content: '接口调用频繁，请重新刷新2',
              //   })
              //   return
              // }
              setShowExample(false)
              const newContent = {
                id: list.length > 0 ? list[retryCount].id : 0,
                fixContent: tmpText,
                defaultContent: contents,
                singleRewrite: false,
                isReplace: false,
              }
              setDirectivesList((prevList) => [...prevList, newContent])
              let nextIndex = ++retryCount

              if (nextIndex == list.length || list.length == 0) {
                setIsComplete(true)
              } else {
                getDirectivesContent(
                  // list.length > 0
                  //   ? list[nextIndex].prompt +
                  //       `${
                  //         tmpText &&
                  //         tagValList.length > 0 &&
                  //         tagValList[0].length > 0
                  //           ? '我的上下文是' +
                  //             tagValList[0] +
                  //             '\n\n我的额外需求是' +
                  //             tmpText
                  //           : `${tagValList.length > 0 ? tagValList[0] : ''}` +
                  //             `${tmpText ? tmpText : ''}`
                  //       }`
                  //   : `${
                  //       tmpText &&
                  //       tagValList.length > 0 &&
                  //       tagValList[0].length > 0
                  //         ? '我的上下文是' +
                  //           tagValList[0] +
                  //           '\n\n我的额外需求是' +
                  //           tmpText
                  //         : `${tagValList.length > 0 ? tagValList[0] : ''}` +
                  //           `${tmpText ? tmpText : ''}`
                  //     }`,
                  list.length > 0 ? list[nextIndex].prompt + tmpText : tmpText,
                  list,
                )
              }

              return
            } else if (message.data !== '[DONE]') {
            }
            return
          },
        },
      )
    } catch (err) {
      console.log(err)
    }
  }
  const handleDragEnd = (result) => {
    if (!result.destination) return

    const reorderedCategories = Array.from(editTagSelectList)
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1)
    reorderedCategories.splice(result.destination.index, 0, movedCategory)
    setEditTagSelectList(reorderedCategories)
    editTagSelectListRef.current = reorderedCategories
  }

  const deleteTagHandle = (name) => {
    setEditTagSelectList(editTagSelectList.filter((_) => _.name != name))
    editTagSelectListRef.current = editTagSelectList.filter(
      (_) => _.name != name,
    )
  }

  const getList = () => {
    let listArr = []
    new Promise((resolve, reject) => {
      request
        .get('https://support.wuz.com.cn/wp-json/acf/v3/options/options')
        .then(async (res) => {
          if (JSON.stringify(res.data.acf) != '{}') {
            const acf = res.data.acf
            const promptsCatArray = acf['prompts_cat'].split('\r\n')
            const promptsCatNameArray = acf['prompts_cat_name'].split('\r\n')

            promptsCatArray.forEach((item, index) => {
              listArr.push({
                id: index,
                title: item,
                category: promptsCatNameArray[index],
                children: [],
              })
            })
            await (listArr.length && resolve(''))
          }
        })
    }).then(() => {
      request
        .get(
          'https://support.wuz.com.cn/wp-json/acf/v3/prompt?per_page=9999&order=asc',
        )
        .then((res) => {
          const result = res.data

          if (JSON.stringify(result.acf) != '{}') {
            result.forEach((item1, index1) => {
              item1.acf.cat.forEach((item2, index2) => {
                listArr.forEach((item3, index3) => {
                  if (item2 == item3.category) {
                    item3.children.push({
                      id: index1,
                      title: item1.acf.title,
                      content: item1.acf.prompt,
                      info: item1.acf.info,
                    })
                  }
                })
              })
            })

            listArr.forEach((item, index) => {
              item.id = index
              item.children.forEach((item1, index1) => {
                item1.id = index1
              })
            })
            listArr.map((_) => {
              return {
                ..._,
                children: _.children.map((i) => {
                  return {
                    ...i,
                    checked: false,
                  }
                }),
              }
            })
            setTagList(listArr)
          }
        })
    })
  }

  const selectTagHandle = (
    firstSelectIndex,
    secondSelectIndex,
    title,
    prompt,
    checked,
  ) => {
    setShowExample(false)
    if (checked) {
      if (editTagSelectList.length > 2) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '最多选择三个指令',
        })
        return
      }
      let newTag = {
        id: 'category' + firstSelectIndex + '-' + secondSelectIndex,
        firstSelectIndex: firstSelectIndex,
        secondSelectIndex: secondSelectIndex,
        prompt,
        name: title,
      }

      setEditTagSelectList([...editTagSelectList, newTag])
      editTagSelectListRef.current = [...editTagSelectList, newTag]
    } else {
      setEditTagSelectList(editTagSelectList.filter((_) => _.name != title))
      editTagSelectListRef.current = editTagSelectList.filter(
        (_) => _.name != title,
      )
      console.log(editTagSelectListRef.current, 'editTagSelectListRef.current')
    }
  }

  const addTagHandle = () => {
    setShowTipPrompt(true)
  }

  const inputChangeHandle = (e) => {
    setEditInputValue(e)
  }

  const createHandle = () => {
    if (
      editInputValue.length > 0 ||
      (tagValList.length > 0 && tagValList[0].length > 0)
    ) {
      setDirectivesList([])
      setShowExample(false)
      retryCount = 0
      setFixContent('')
      getDirectivesContent(
        editTagSelectListRef.current.length > 0
          ? editTagSelectListRef.current[0].prompt +
              `${
                editInputValue &&
                tagValList.length > 0 &&
                tagValList[0].length > 0
                  ? '我给定的内容是：' +
                    tagValList[0] +
                    '\n\n我的额外需求是：' +
                    editInputValue
                  : `${tagValList.length > 0 ? tagValList[0] : ''}` +
                    `${editInputValue ? editInputValue : ''}`
              }`
          : `${
              editInputValue &&
              tagValList.length > 0 &&
              tagValList[0].length > 0
                ? '我给定的内容是：' +
                  tagValList[0] +
                  '\n\n我的额外需求是：' +
                  editInputValue
                : `${tagValList.length > 0 ? tagValList[0] : ''}` +
                  `${editInputValue ? editInputValue : ''}`
            }`,
        editTagSelectListRef.current,
      )
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请选择指令并且输入内容',
      })
      return
    }
  }

  const replaceHandle = (id) => {
    const newArr = directivesList.map((i) => {
      if (i.id == id) {
        i.isReplace = true
      }
      return {
        ...i,
      }
    })
    setDirectivesList(newArr)
  }

  const inputTagChangeHandle = (value, reason) => {
    if (!isComplete) return
    if (reason == 'remove') {
      setTagValList([])
      onDelDirectivesTagVal()
    }
  }

  const deleteSelectTagHandle = () => {
    if (!isComplete) return
    setTagValList([])
    onDelDirectivesTagVal()
  }

  function tagRender(props) {
    const { label, value, closable, onClose } = props
    if (label.length == 0) return
    return (
      <div className={style['selectContent-item']}>
        <div className={style['tagBox']}>
          <div className={style['tagTitle']}>
            {label[0].length > 4 ? label[0].substring(0, 4) + '...' : label[0]}
          </div>
          <span className={style['divider']}>|</span>
          <IconClosure onClick={deleteSelectTagHandle} />
        </div>
      </div>
    )
  }

  return (
    <div className={style[theme]}>
      <div className={style['headerWrapper']}>
        <div className={style['headerContent']}>
          <>
            <span className={style['headerTitle']}>内置指令：</span>
            <div>
              {isComplete ? (
                <div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="categories"
                      direction="horizontal"
                      listType="CARD">
                      {(provided) => (
                        <div
                          className={style['dragBox']}
                          ref={provided.innerRef}
                          style={{
                            marginLeft:
                              editTagSelectList.length > 0 ? '0px' : '0px',
                          }}
                          {...provided.droppableProps}>
                          {editTagSelectList.map((category, index) => (
                            <Draggable
                              key={category.id}
                              draggableId={category.id}
                              index={index}>
                              {(provided) => (
                                <div
                                  className={style['dragItem']}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}>
                                  <div className={style['category-item']}>
                                    <div
                                      className={style['tagBox']}
                                      {...provided.dragHandleProps}>
                                      <div className={style['tagTitle']}>
                                        {category.name}
                                      </div>
                                      <span className={style['divider']}>
                                        |
                                      </span>
                                      <IconClosure
                                        onClick={() =>
                                          deleteTagHandle(category.name)
                                        }
                                      />
                                    </div>
                                    {index < editTagSelectList.length - 1 && (
                                      <span className={style['link']}>
                                        <IconLink2 />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <div className={style['dragBoxDisable']}>
                  {editTagSelectList.map((category, index) => (
                    <div key={category.id}>
                      <div className={style['dragItem']}>
                        <div className={style['category-item']}>
                          <div className={style['tagBox']}>
                            <div className={style['tagTitle']}>
                              {category.name}
                            </div>
                            <span className={style['divider']}>|</span>
                            <IconClosure
                            // onClick={() => deleteTagHandle(category.name)}
                            />
                          </div>
                          {index < editTagSelectList.length - 1 && (
                            <span className={style['link']}>
                              <IconLink2 />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
          <div
            style={{ marginLeft: editTagSelectList.length > 0 ? '10px' : '0' }}>
            {isComplete ? (
              <Dropdown
                droplist={
                  <div className={style['tipPromptWrapper']}>
                    <TipPromptProps
                      title={'指令库'}
                      firstTitle={'分类'}
                      secondTitle={'条目'}
                      tagList={tagList}
                      tagSelectList={editTagSelectList}
                      onSelectTag={selectTagHandle}
                    />
                  </div>
                }
                // popupVisible={true}
                position="bottom"
                trigger={'click'}>
                <Btn
                  title="+"
                  width={30}
                  height={30}
                  backgroundColor={palette.directives}
                  hoverBackgroundColor={palette.directives}
                  // backgroundColor={palette.black555}
                  showBorder={'none'}
                  color={palette.directivesBold}
                  hoverColor={palette.directivesBold}
                  onClick={addTagHandle}
                />
              </Dropdown>
            ) : (
              <Btn
                title="+"
                width={30}
                height={30}
                disabled={true}
                onClick={addTagHandle}
                showBorder={'none'}
                color={palette.grayB4}
                backgroundColor={palette.grayF8}
              />
            )}
          </div>
        </div>
        <div className={style['headerContent']}>
          <span className={style['headerTitle']} style={{ width: '92px' }}>
            输入指令：
          </span>
          {/* <TextArea
            style={{
              background: '#fff',
              // width: proseMirrorWidth - 48,
              padding: '0 10px',
              minHeight: '40px',
            }}
            className={style['textarea']}
            value={editInputValue}
            autoSize={{ minRows: 1, maxRows: 6 }}
            onChange={inputChangeHandle}
            placeholder="输入内容或从下面内容选择"
            disabled={!isComplete}
          /> */}
          <InputTag
            style={{
              background: '#fff',
              maxWidth: 'calc(50vw - 264px)',
              // maxWidth: 'calc(50vw - 260px)',
              padding: '10px',
              minHeight: '40px',
              border: ` 1px solid ${palette.grayEF}`,
            }}
            disabled={!isComplete}
            className={style['textarea']}
            placeholder="您还可输入额外需求，如：提取关键字（额外需求将在第一轮指令的输入中生效）"
            value={tagValList}
            inputValue={editInputValue}
            onInputChange={inputChangeHandle}
            onChange={inputTagChangeHandle}
            renderTag={tagRender}
          />
        </div>
        <div className={style['headerBtn']}>
          <Btn
            title="根据指令生成结果"
            width={300}
            height={50}
            fontWeight={'500'}
            showBorder={'none'}
            disabled={
              !isComplete || (!editInputValue && directivesTagVal.length == 0)
            }
            hoverFontWeight={'500'}
            backgroundColor={
              'linear-gradient(135deg, #C437AE 0%, #EA3DA8 100%)'
            }
            hoverBackgroundColor={
              'linear-gradient(135deg, #C437AE 0%, #EA3DA8 100%)'
            }
            onClick={
              !isComplete || (!editInputValue && directivesTagVal.length == 0)
                ? null
                : createHandle
            }
          />
        </div>
      </div>
      <div className={style['contentWrapper']}>
        {isComplete ? (
          <p className={style['tip']}>输出结果： </p>
        ) : (
          <div className={style['tipLoading']}> 内容正在输出中 </div>
        )}
        {!nonEmptyArray(directivesList) ? (
          <div style={{ height: '54vh' }}>
            <EmptyView type="directivesEmpty" title="暂无内容" />
          </div>
        ) : (
          <div className={style['listContent']}>
            {directivesList.length > 0 &&
              directivesList.map((item, index) => {
                return (
                  <DirectivesItem
                    id={item.id}
                    key={index}
                    defaultContent={'selectContent'}
                    fixContent={item.fixContent}
                    expand={true}
                    type={DocBtnType.directives}
                    isReplace={item.isReplace}
                    singleRewrite={true}
                    onReplace={replaceHandle}
                    startPos={directivesStartPos}
                    disabled={false}
                  />
                )
              })}
            {contentRef.current && showExample && (
              <DirectivesItem
                defaultContent={'selectContent'}
                fixContent={fixContent}
                expand={true}
                type={DocBtnType.directives}
                isReplace={false}
                disabled={true}
                singleRewrite={true}
                onReplace={replaceHandle}
                startPos={directivesStartPos}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default DirectivesMod
