import './style/global.less'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { ConfigProvider } from '@arco-design/web-react'
import zhCN from '@arco-design/web-react/es/locale/zh-CN'
import enUS from '@arco-design/web-react/es/locale/en-US'
import { AliveScope } from 'react-activation'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import JavascriptTimeAgo from 'javascript-time-ago'
import zh from 'javascript-time-ago/locale/zh'
import rootReducer from './store'
import PageLayout from './components/Layout'
import { GlobalContext } from './context'
import Login from './pages/login'
import Register from './pages/register'
import Forgotpwd from './pages/forgotpwd'
import Vip from './pages/vip'
import wxJSAPICallback from './pages/pay/wxJSAPICallback'
import hFivePay from './pages/pay/hFivePay'
import celebrate from './pages/celebrate'
import ReferenceDetail from './pages/referenceDetail/[id]'
import DocumentDetail from './pages/documentEdit/[id]'
import EditDocDetail from './pages/editPage/[id]'
import EditDocMobileDetail from './pages/docEditMobile/[id]'
import DocumentShare from './pages/shareDoc/[id]'
import ToolboxShare from './pages/toolboxShare/[id]'
import StudentDetail from './pages/studentDetail'
import checkLogin from './utils/checkLogin'
import changeTheme from './utils/changeTheme'
import useStorage from './utils/useStorage'
import DemoBox from './pages/Demo'
import TemplatePage from './pages/templatePage/[id]'
import TemplatePageAll from './pages/templateAllText/[id]'
import aiDrawing from './pages/aiDrawing/index'
import aiImageSynthesis from './pages/aiDrawing/index2'
import aiImageDismantel from './pages/aiDrawing/index3'
import MiniPhoneGo from './pages/miniPhoneGo'
import docTemEditor from './pages/docTemEditor/[id]'
import AgentDetail from './pages/agentDetail'
import loadingAuth from './pages/LoadingAuth'
import { GetQueryValue } from './utils/common'
import { GlobalState } from '@/store'
const store = createStore(rootReducer)
function Index() {
  const [lang, setLang] = useStorage('arco-lang', 'zh-CN')
  // const [theme, setTheme] = useStorage('arco-theme', 'light')

  // react-time-ago 初始化中文语言包
  JavascriptTimeAgo.addDefaultLocale(zh)

  function getArcoLocale() {
    switch (lang) {
      case 'zh-CN':
        return zhCN
      case 'en-US':
        return enUS
      default:
        return zhCN
    }
  }

  useEffect(() => {
    if (checkLogin()) {
    } else if (
      window.location.pathname.replace(/\//g, '') !== 'login' &&
      window.location.pathname.replace(/\//g, '') !== 'register' &&
      window.location.pathname.replace(/\//g, '') !== 'forgotpwd' &&
      window.location.pathname.replace(/\//g, '') !== 'toolboxShare' &&
      window.location.pathname.replace(/\//g, '') !== 'documentShare' &&
      window.location.pathname.replace(/\//g, '') !== 'docEditMobile' &&
      window.location.pathname.replace(/\//g, '') !== 'miniphoneGo'
    ) {
      const redirectUrl = location.pathname + location.search + location.hash
      localStorage.setItem('redirectUrl', redirectUrl)
      window.location.pathname = '/login'
    }
  }, [])

  const contextValue = {
    lang,
    setLang,
    // setTheme,
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={getArcoLocale()}
        componentConfig={{
          Card: {
            bordered: false,
          },
          List: {
            bordered: false,
          },
          Table: {
            border: false,
          },
        }}>
        <Provider store={store}>
          <AliveScope>
            <GlobalContext.Provider value={contextValue}>
              <Switch>
                <Route path="/vip" component={Vip} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/Forgotpwd" component={Forgotpwd} />
                <Route path="/demo" component={DemoBox} />
                <Route path="/miniPhoneGo" component={MiniPhoneGo} />
                <Route path="/celebrate" component={celebrate} />
                <Route
                  path="/TemplatePage"
                  loader={async ({ params }) => {
                    return fetch(`/TemplatePage?id=${params.id}`)
                  }}
                  component={TemplatePage}
                />
                <Route
                  path="/TemplatePageAll"
                  loader={async ({ params }) => {
                    return fetch(`/TemplatePageAll?id=${params.id}`)
                  }}
                  component={TemplatePageAll}
                />
                <Route path="/agentDetail" component={AgentDetail} />
                <Route
                  path="/pay/wxJSAPICallback"
                  component={wxJSAPICallback}
                />
                <Route path="/pay/hFivePay" component={hFivePay} />
                <Route
                  path="/referenceDetail/:id"
                  loader={async ({ params }) => {
                    return fetch(`/referenceDetail/${params.id}`)
                  }}
                  component={ReferenceDetail}
                />
                <Route
                  path="/documentEdit"
                  loader={async ({ params }) => {
                    return fetch(`/documentEdit?uuid=${params.id}`)
                  }}
                  component={EditDocDetail}
                />
                <Route
                  path="/docTemEditor"
                  loader={async ({ params }) => {
                    return fetch(`/docTemEditor?uuid=${params.id}`)
                  }}
                  component={docTemEditor}
                />
                <Route
                  path="/docEditMobile"
                  loader={async ({ params }) => {
                    return fetch(`/docEditMobile?uuid=${params.id}`)
                  }}
                  component={EditDocMobileDetail}
                />
                {/* <Route
                  path="/editPage"
                  loader={async ({ params }) => {
                    return fetch(`/editPage?uuid=${params.id}`)
                  }}
                  component={EditDocDetail}
                /> */}
                <Route
                  path="/documentShare"
                  loader={async ({ params }) => {
                    return fetch(`/documentShare?uuid=${params.id}`)
                  }}
                  component={DocumentShare}
                />
                <Route
                  path="/toolboxShare"
                  loader={async ({ params }) => {
                    return fetch(`/toolboxShare?uuid=${params.id}`)
                  }}
                  component={ToolboxShare}
                />
                <Route
                  path="/studentDetail"
                  loader={async ({ params }) => {
                    return fetch(`/studentDetail?uuid=${params.id}`)
                  }}
                  component={StudentDetail}
                />
                <Route path="/aiDrawing" component={aiDrawing} />
                <Route path="/aiImageSynthesis" component={aiImageSynthesis} />
                <Route path="/aiImageDismantel" component={aiImageDismantel} />
                <Route path="/loadingAuth" component={loadingAuth} />
                <Route path="/" component={PageLayout} />
              </Switch>
            </GlobalContext.Provider>
          </AliveScope>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
