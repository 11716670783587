import { mathjax } from 'mathjax-full/js/mathjax.js'
import { TeX } from 'mathjax-full/js/input/tex.js'
import { SVG } from 'mathjax-full/js/output/svg.js'
import { LiteAdaptor } from 'mathjax-full/js/adaptors/liteAdaptor.js'
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html.js'
import { AllPackages } from 'mathjax-full/js/input/tex/AllPackages.js'

// 创建LiteAdaptor，它适用于浏览器环境
const adaptor = new LiteAdaptor()

// 创建和注册HTML处理器
RegisterHTMLHandler(adaptor)

// 创建TeX输入和SVG输出处理器，并使用它们创建一个MathJax文档
const tex = new TeX({ packages: AllPackages })
const svg = new SVG({ fontCache: 'local' })
const html = mathjax.document('', { InputJax: tex, OutputJax: svg })

// 定义一个函数，将TeX数学公式转换为SVG
const mathjaxTex2svg = (text, options = {}) => {
  const node = html.convert(text || '', {
    display: !options.inline,
    em: options.em || 16,
    ex: options.ex || 8,
    containerWidth: options.width || 80 * 16,
  })

  return options.container ? adaptor.outerHTML(node) : adaptor.innerHTML(node)
}

export default mathjaxTex2svg
