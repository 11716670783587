import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { setBlockType } from 'prosemirror-commands'
import { EditorState } from 'prosemirror-state'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
interface HeaderH3 {
  name: string
}
const PLUGIN_NAME = 'header-h3'
class HeaderH3Schema extends Inline<HeaderH3> {
  public name = PLUGIN_NAME
}

class HeaderH3Controller extends SylController {
  public name = PLUGIN_NAME
  constructor(editor: SylApi, props) {
    super(editor, props)
  }

  // 自定义菜单栏
  public toolbar = {
    icon: (_editor: SylApi, attrs: { fontFamily: string; name: string }) => {
      const handleModHeader = (level) => {
        const { state, dispatch } = this.editor.view
        if (
          setBlockType(state.schema.nodes.header, { level })(state, dispatch)
        ) {
          return true
        }
        return false
      }
      return (
        <div
          style={{ color: `#999`, background: '#fff', fontSize: '12px' }}
          onClick={() => handleModHeader(3)}>
          H3
        </div>
      )
    },
  }
}
class HeaderH3Plugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = HeaderH3Controller
  public Schema = HeaderH3Schema
}

export { HeaderH3Plugin }
