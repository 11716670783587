import React, { useEffect, useState } from 'react'
import styles from './style/vip-btn.module.less'
import { getUserInfo } from '@/model/reference'
function VipBtn(props) {
  const handleClick = () => {
    props.onClick() // 调用父组件传递过来的点击事件处理函数
  }

  return (
    <div className={styles['vipBtn']} onClick={handleClick}>
      {props.content}会员
    </div>
  )
}

export default VipBtn
