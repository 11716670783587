import React from 'react'
import styles from './style/nav-item.module.less'

function NavItem(props) {
  const handleClick = (event) => {
    event.preventDefault() // 防止默认的导航行为
    props.onClick() // 调用父组件传递过来的点击事件处理函数
  }

  return (
    <a
      href={props.href} // 链接地址
      className={`${styles['item']} ${styles[props.name]} ${
        styles[props.theme]
      } ${props.isCurrent ? styles['active'] : ''}`}
      onClick={handleClick}>
      <div className={styles['icon']}>{props.icon}</div>
      <div className={styles['title']} style={{ color: props.color }}>
        {props.title}
      </div>
    </a>
  )
}

export default NavItem
