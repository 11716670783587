import palette from '@/helpers/palette'
import React from 'react'
import styled from 'styled-components'

const StyledBtns = styled.div<{
  showBorder: string
  showActiveBorder: boolean
  backgroundColor: string
  hoverBackgroundColor: string
  hoverBorderColor: string
  color: string
  hoverColor: string
  borderRadius: string
  padding: string
  margin: string
  svgSize: number
  svgRight: number
  textLeft: number
  textRight: number
  width: number
  height: number
  align: string
  svgColor: string
  svgHoverColor: string
  dotColor: string
  dotHoverColor: string
  fontWeight: string
  hoverFontWeight: string
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : 'center')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${({ width }) => (width ? width + 'px' : '135px')};
  height: ${({ height }) => (height ? height + 'px' : '54px')};
  background: ${({ backgroundColor, disabled, theme }) =>
    theme == 'dark'
      ? '#19191a'
      : disabled
      ? palette.grayF8
      : backgroundColor || palette.primary};
  color: ${({ color, disabled, theme }) =>
    theme == 'dark'
      ? '#5a5a5a'
      : disabled
      ? palette.grayF8
      : color || palette.white};
  border: ${({ showBorder }) =>
    showBorder ? showBorder : '1px solid #E4E4E4'};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '4px !important'};
  padding: ${({ padding }) => (padding ? padding : '0')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  .title {
    font-size: 14px;
    margin: 0;
    color: ${({ color, disabled }) =>
      disabled ? palette.grayB4 : !disabled && color ? color : palette.white};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  }
  .dot {
    background: ${({ dotColor }) => (dotColor ? dotColor : palette.white)};
  }
  svg {
    display: flex;
    width: ${({ svgSize }) => (svgSize ? svgSize + 'px' : '22px')};
    height: ${({ svgSize }) => (svgSize ? svgSize + 'px' : '22px')};
    color: ${({ svgColor }) => (svgColor ? svgColor : palette.black)};
    fill: ${({ svgColor }) => (svgColor ? svgColor : palette.black)};
  }
  :hover {
    background: ${({ hoverBackgroundColor, disabled }) =>
      disabled
        ? palette.grayF8
        : !disabled && hoverBackgroundColor
        ? hoverBackgroundColor
        : palette.primaryHover};
    .title {
      font-weight: ${({ hoverFontWeight }) =>
        hoverFontWeight ? hoverFontWeight : '400'};
      color: ${({ hoverColor, disabled }) =>
        disabled
          ? palette.grayB
          : !disabled && hoverColor
          ? hoverColor
          : palette.white};
    }
    .dot {
      background: ${({ dotHoverColor }) =>
        dotHoverColor ? dotHoverColor : palette.white};
    }

    border-color: ${({ hoverBorderColor, disabled }) =>
      disabled
        ? palette.grayB
        : !disabled && hoverBorderColor
        ? hoverBorderColor
        : palette.primaryHover};
    svg {
      color: ${({ svgHoverColor, disabled }) =>
        disabled
          ? palette.grayB
          : !disabled && svgHoverColor
          ? svgHoverColor
          : palette.white};
      fill: ${({ svgHoverColor, disabled }) =>
        disabled
          ? palette.grayB
          : !disabled && svgHoverColor
          ? svgHoverColor
          : palette.white};
    }
  }

  @media only screen and (max-width: 1000px) {
    svg {
      display: flex;
      width: ${({ svgSize }) => (svgSize ? svgSize + 'px' : '22px')};
      height: ${({ svgSize }) => (svgSize ? svgSize + 'px' : '22px')};
      color: ${({ svgColor, theme }) =>
        theme == 'dark' ? '#fff' : svgColor ? svgColor : palette.black};
      fill: ${({ svgColor, theme }) =>
        theme == 'dark' ? '#fff' : svgColor ? svgColor : palette.black};
    }
  }
`

export type BtnProps = {
  style?: React.CSSProperties
  Icon?: React.ReactNode //图标
  title?: string //文案
  backgroundColor?: string //背景
  showBorder?: string //边框
  showActiveBorder?: boolean
  hoverBackgroundColor?: string
  hoverBorderColor?: string
  color?: string
  disabled?: boolean
  fontWeight?: string
  hoverColor?: string
  hoverFontWeight?: string
  borderRadius?: string
  padding?: string
  margin?: string
  svgSize?: number
  svgRight?: number
  svgLeft?: number
  textLeft?: number
  textRight?: number
  width?: number | string
  height?: number
  align?: string
  iconPosition?: string
  svgColor?: string
  svgHoverColor?: string
  dotColor?: string
  dotHoverColor?: string
  onClick?: any
  theme?: any
}

const Btn = React.memo((props: BtnProps) => {
  const {
    style = {},
    title,
    Icon,
    padding,
    margin,
    dotColor,
    dotHoverColor,
    backgroundColor,
    showBorder,
    showActiveBorder,
    hoverBackgroundColor,
    hoverBorderColor,
    color,
    hoverColor,
    svgRight,
    svgLeft,
    textLeft,
    textRight,
    width,
    height,
    iconPosition = 'left',
    align,
    svgColor,
    svgHoverColor,
    svgSize,
    fontWeight,
    hoverFontWeight,
    onClick,
    disabled,
    theme,
  } = props

  return (
    <StyledBtns
      showBorder={showBorder}
      backgroundColor={backgroundColor}
      showActiveBorder={showActiveBorder}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverBorderColor={hoverBorderColor}
      width={width}
      height={height}
      color={color}
      disabled={disabled}
      fontWeight={fontWeight}
      hoverFontWeight={hoverFontWeight}
      padding={padding}
      margin={margin}
      hoverColor={hoverColor}
      svgRight={svgRight}
      svgLeft={svgLeft}
      textRight={textRight}
      textLeft={textLeft}
      align={align}
      svgColor={svgColor}
      svgHoverColor={svgHoverColor}
      svgSize={svgSize}
      dotColor={dotColor}
      dotHoverColor={dotHoverColor}
      onClick={onClick ? onClick : null}
      style={style}>
      {iconPosition == 'left' ? Icon : <p className="title">{title}</p>}
      {iconPosition == 'left' ? <p className="title">{title}</p> : Icon}
    </StyledBtns>
  )
})

export { Btn as default }
