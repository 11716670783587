import React, { useState, useEffect } from 'react'
import { Message } from '@arco-design/web-react'
import {
  IconSuccessTip,
  IconErrorTip,
  IconWarnTip,
  IconArrowRight,
} from '@arco-iconbox/react-aidb-v2'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { GetSynergia } from '@/model/reference'
import CollaborativeDisplay from './components/collaborativeDisplay'
import Addition from './components/addition'

import styles from './style/index.module.less'

interface Props {
  userInfo: object
  isDisable: boolean
  employeePermission: number // 协同者权限 1-可管理，2-可编辑，3-可阅读
  employeeList: { employeeList: any[]; total: number }
}
const EditingCollaboration = (props: Props) => {
  const { userInfo, isDisable, employeePermission, employeeList } = props

  return (
    <div className={styles['editingCollaboration-outContent']}>
      <CollaborativeDisplay
        employeePermission={employeePermission}
        synergiaEmployeeList={employeeList.employeeList}
      />
      <Addition isDisable={isDisable} employeePermission={employeePermission} />
    </div>
  )
}
export default EditingCollaboration
