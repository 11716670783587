import React, { useState, useCallback, useRef, useEffect } from 'react'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
import Markdown from '../Markdown'
import Copy from '../Copy'
import { Modal, Message } from '@arco-design/web-react'
import { IconSuccessTip, IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import {
  IconLoading,
  IconDownload,
  IconLink,
} from '@arco-design/web-react/icon'
import wx from 'weixin-js-sdk'
import Logo from '../../assets/logo.svg'
import Robot from '../../assets/imgs/robot.svg'
// import Qr from "../../assets/imgs/qr.svg";
import Line from '../../assets/imgs/line.svg'
import AgentImg from '@/assets/newImgs/aiagent.svg'

import styles from './style/index.module.less'

function SharePop(props) {
  const { theme } = props
  const [list, setList] = useState({})
  const [shareUrl, setShareUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const ref = useRef(null)

  const ua = navigator.userAgent.toLowerCase()

  useEffect(() => {
    console.log(props.sharingConversationsItem)
    setList(props.sharingConversationsItem)
    shareImgUrl(
      props.sharingConversationsItem.messageId ||
        props.sharingConversationsItem.messageId40,
    )
  }, [props.sharingConversationsItem])

  const st0 = {
    fill: '#FF6500',
  }

  const st1 = {
    fill: '#FFFFFF',
  }

  // 图片下载
  const downloadImg = useCallback(() => {
    if (ref.current === null) {
      return
    }
    setLoading(true)

    setTimeout(() => {
      close()
      toPng(ref.current, { cacheBust: true, pixelRatio: 3 })
        .then((base64Data) => {
          getDeviceType()
            .then((dev) => {
              if (dev == 'PC') {
                saveBase64ImageToAlbumPc(
                  base64Data,
                  `aidb-chatbot-${Date.now()}`,
                )
              } else if (dev == 'Mobile') {
                saveBase64ImageToAlbumMobile(
                  base64Data,
                  `aidb-chatbot-${Date.now()}`,
                )
              } else if (dev == 'Applet') {
                saveBase64ImageToAlbumApplet(
                  base64Data,
                  `aidb-chatbot-${Date.now()}`,
                )
              }
              Message.success({
                icon: <IconSuccessTip useCurrentColor={false} />,
                content: '下载成功！',
              })
              setLoading(false)
            })
            .catch((err) => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '当前环境下载出现错误！',
              })
            })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '下载失败！',
          })
        })
    }, 50)
  }, [ref])

  // 分享到朋友圈
  // const shareImg = useCallback(() => {
  //     if (ref.current === null) {
  //         return
  //     }
  //     setShareLoading(true)

  //     setTimeout(() => {
  //         toPng(ref.current, { cacheBust: true, pixelRatio: 3 })
  //             .then((base64Data) => {
  //                 getDeviceType().then((dev) => {
  //                     setSrc(base64Data)
  //                     setShareLoading(false)

  //                     // 向后端发送请求获取微信签名数据
  //                     // 示例API，根据实际需求修改
  //                     // 这里一定要填公众号的appid（不用云函数的话，就一定要用公众号signature），不要本地测试，本地测试会提示 appid 不符合，造成误解
  //                     request.get('https://api.v-dk.com/ajax_getconfig.php?app=aidb', {
  //                         params: {
  //                             url: window.location.href.split('#')[0],
  //                         },
  //                     }).then((response) => {
  //                         console.log(response);
  //                         const { data } = response;

  //                         // 配置微信SDK
  //                         wx.config({
  //                             debug: data.debug,
  //                             appId: data.appId,
  //                             timestamp: data.timestamp,
  //                             nonceStr: data.nonceStr,
  //                             signature: data.signature,
  //                             jsApiList: data.jsApiList,
  //                         });

  //                         // 配置分享信息
  //                         wx.ready(() => {
  //                             // 更新分享到朋友圈的信息
  //                             try {
  //                                 wx.onMenuShareTimeline({
  //                                     title: '智能助理',
  //                                     link: 'https://portal-dev.aidb.com.cn/toolbox/index',
  //                                     imgUrl: 'https://img2.baidu.com/it/u=4061036439,4128632041&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=300',
  //                                     success: () => {
  //                                         // 分享成功回调
  //                                         alert('分享成功');
  //                                     },
  //                                     cancel: () => {
  //                                         // 取消分享回调
  //                                         alert('取消分享');
  //                                     },
  //                                 });
  //                             } catch (error) {
  //                                 alert('err:', errorF)
  //                             }
  //                             wx.error(function (res) {
  //                                 // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  //                                 setA(JSON.stringify(res));
  //                                 alert("error:" + res);
  //                             });
  //                         });
  //                     }).catch((error) => {
  //                         alert('获取微信签名失败', error);
  //                     });
  //                 }).catch((err) => {
  //                     setShareLoading(false)
  //                 })

  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //             })
  //     }, 50);
  // }, [ref])

  // 判断当前环境
  function getDeviceType() {
    const isWeixin = ua.indexOf('micromessenger') !== -1
    const isInApp = /(^|;\s)app\//.test(ua)

    return new Promise((resolve, reject) => {
      if (isWeixin) {
        if (window.__wxjs_environment === 'miniprogram') {
          // 微信小程序
          resolve('Applet')
        } else {
          // 微信浏览器
          resolve('Mobile')
        }
      } else {
        if (!isInApp) {
          // browser
          resolve('PC')
        } else {
          // app
          resolve('Mobile')
        }
      }
    })
  }

  // PC端下载
  function saveBase64ImageToAlbumPc(base64Data, fileName) {
    const link = document.createElement('a')
    link.download = fileName
    link.href = base64Data
    link.click()
  }

  // 移动端浏览器下载
  function saveBase64ImageToAlbumMobile(base64Data, fileName) {
    // 创建一个新的Image对象并设置src属性为base64编码的图像数据
    var img = new Image()
    img.src = base64Data

    img.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight

      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      var base64Data = canvas.toDataURL('image/png')

      // 创建一个<a>标签，并设置其href属性为base64Data
      var link = document.createElement('a')
      link.href = base64Data

      // 设置<a>标签的下载属性为文件名
      link.download = fileName

      // 触发点击事件以下载图像文件
      link.click()
    }
  }

  // 微信小程序下载
  const saveBase64ImageToAlbumApplet = (base64Data, fileName) => {
    wx.miniProgram.navigateTo({
      url: `/pages/saveImag/saveImag?base64Data=${encodeURIComponent(
        base64Data,
      )}&fileName=${encodeURIComponent(fileName)}`,
      success: function (res) {
        // alert('成功！')
      },
      fail: function (err) {
        alert('失败！' + err)
      },
    })
  }

  // 关闭弹窗
  function close() {
    props.close()
  }

  const getDoShareLinkHandle = async (linkUuid) => {
    try {
      const response = await fetch(
        'https://cors.v-dk.com/https://go.wuz.com.cn/api/set.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(linkUuid),
        },
      )

      if (response.ok) {
        const previewData = await response.json()
        // console.log(previewData.content.url);
        // copy(previewData.content.url)
        // navigator.clipboard.writeText(previewData.content.url)
        setShareUrl(previewData.content.url)
      } else {
        // 请求失败
        console.error('set.php 请求失败')
      }
    } catch (error) {
      console.error('set.php 请求出错', error)
    }
  }

  // 图片分享
  function shareImgUrl(uuid) {
    // console.log(props);
    const agentUuid = props.drawerChatHistory[props.currentListIndex]?.agentUuid
    const templateId =
      props.drawerChatHistory[props.currentListIndex]?.templateId
    if (props.drawerChatHistory[props.currentListIndex]?.type == 'agent') {
      getDoShareLinkHandle({
        url: `https://portal.wuz.com.cn/toolbox/index?agentUuid=${agentUuid}&templateId=${templateId}&userName=${localStorage.getItem(
          'userName',
        )}`,
      })
    } else {
      getDoShareLinkHandle({
        url: 'https://portal.wuz.com.cn/toolboxShare?uuid=' + uuid,
      })
    }

    // Message.success({
    //     icon: <IconSuccessTip useCurrentColor={false} />,
    //     content: '复制成功',
    // })

    // close()
  }

  // 在微信小程序中，图片的父元素的样式，有可能读取不到，直接使用内联方式写样式避坑
  return (
    <Modal
      theme={theme}
      title={null}
      style={{
        width: props.width ? props.width : '520px',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
      className={'modal-aidb bgn'}
      visible={props.visible}
      onCancel={() => close()}
      footer={null}>
      {/* 展示的dom */}
      <div className={`${styles.share_pop} ${styles[theme]}`}>
        <div className={styles.top}>
          <div className={styles.mod1}>
            <div
              className={styles.logo}
              style={{ width: '130px', marginBottom: '10px' }}>
              <Logo />
              {/* <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345.61 100" style={{}}>
                                <g id="aidb_logo">
                                    <path d="m145.53,46.24l1.48-3.98c.04-.12-.02-.26-.13-.31-3.75-1.76-5.7-3.07-5.7-3.07v-13.28c0-.14-.11-.25-.25-.25h-4.3c-.14,0-.25.11-.25.25v49.69c0,.14.11.25.25.25h4.3c.14,0,.25-.11.25-.25v-30.75l4.01,1.84c.13.06.29,0,.34-.14Z" />
                                    <path d="m128.41,34.66c0,6.05-.32,12.1-.96,18.12l-.23,2.12c-.01.14.08.26.22.28l4.27.45c.14.01.26-.08.28-.22l.23-2.12c.66-6.19.99-12.41.99-18.63v-.72c0-.14-.11-.25-.25-.25h-4.3c-.14,0-.25.11-.25.25v.72Z" />
                                    <path d="m175.42,46.87v-9.04c0-1.36-1.1-2.45-2.45-2.45h-10.66l1.21-3.96h13.92c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-28.58c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h9.64l-1.21,3.96h-7.21c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h5.75l-2.04,6.7h-5.91c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h30.67c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-3.12Zm-14.58-6.7h9.78v6.7h-11.83l2.04-6.7Z" />
                                    <path d="m174.93,55.83h-23.45c-1.35,0-2.45,1.1-2.45,2.45v14c0,1.35,1.1,2.45,2.45,2.45h23.45c1.35,0,2.45-1.1,2.45-2.45v-14c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,14.11h-18.76v-9.32h18.76v9.32Z" />
                                    <path d="m290.56,28.95v11.84c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-9.5h-38.17v9.5c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-11.84c0-1.36,1.1-2.45,2.45-2.45h42.86c1.36,0,2.45,1.1,2.45,2.45Zm-6.92,20.74h-27.87l.82-6.45h25.73c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-25.12l.4-3.11c.02-.14-.08-.26-.22-.28l-4.26-.54c-.14-.02-.26.08-.28.22l-2.48,19.48c-.02.15.1.28.25.28h30.74v15.08h-9.36c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h11.66c1.38,0,2.5-1.12,2.5-2.5v-19.68c0-1.38-1.12-2.5-2.5-2.5Zm-7.26,10.31h-33.02c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h33.02c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25Z" />
                                    <path d="m233.14,26.99h-15.84c-1.35,0-2.45,1.1-2.45,2.45v17.31c0,1.35,1.1,2.45,2.45,2.45h15.84c1.35,0,2.45-1.1,2.45-2.45v-17.31c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,17.42h-11.15v-12.63h11.15v12.63Z" />
                                    <path d="m231.18,52.27h-38.24c-1.35,0-2.45,1.1-2.45,2.45v17.56c0,1.35,1.1,2.45,2.45,2.45h38.24c1.35,0,2.45-1.1,2.45-2.45v-17.56c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,17.67h-33.55v-12.87h33.55v12.87Z" />
                                    <rect x="199.15" y="61.11" width="25.8" height="4.8" rx=".25" ry=".25" />
                                    <path d="m186.03,47.6l2.37,3.59c.07.11.22.15.33.08,1.65-1.01,13.15-7.64,20.5-.81.1.09.25.09.34,0l2.99-3.09c.1-.1.09-.27,0-.36-3.63-3.41-8.32-4.87-13.74-4.29.76-.8,1.42-1.54,1.96-2.17h10.44c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-8.3v-3.71h7.2c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-16.75l.38-1.23c.03-.15-.07-.29-.22-.3l-4.28-.83c-.13-.03-.27.05-.3.19l-.91,3.99c-.17.73,0,1.49.48,2.07.47.59,1.17.92,1.92.92h7.69v3.71h-11.07c-.14,0-.25.11-.25.25v4.55h7.43s-2.61,3.23-8.13,6.71c-.12.07-.16.23-.08.35Z" />
                                    <path d="m345.36,36.47h-18.71v9.17h17.48c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25h-17.48v9.54h17.48c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25h-17.48v10.38c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-38.68h-.19c-1.94,2.8-4.56,5.69-8.06,7.94-.12.08-.28.04-.35-.08l-2.29-3.64c-.07-.11-.04-.26.08-.34,7.3-4.71,10.19-13.19,10.65-14.66.04-.13.17-.19.3-.16l4.15,1.12c.14.04.22.17.18.31-.19.64-.76,2.43-1.87,4.71h20.91c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25Zm-33.78-6.18l1.93-4.09c.06-.13,0-.3-.15-.34l-4.04-1.38c-.13-.04-.27.02-.32.16l-.64,1.86c-2.8,8.21-6.78,14.5-13.51,19.95-.1.08-.12.21-.06.32l1.99,3.31c.08.13.26.16.38.07,2-1.62,3.85-3.38,5.57-5.26v30.27c0,.14.11.25.25.25h4.3c.14,0,.25-.11.25-.25v-36.28l4.05-8.59Z" />
                                    <path style={st0} d="m100.02,4v85.08c0,1.15-.5,2.25-1.36,3.01l-7.9,6.92c-.73.64-1.67.99-2.64.99H4c-2.21,0-4-1.79-4-4V12.09c0-.95.34-1.87.96-2.6L7.87,1.4c.76-.89,1.87-1.4,3.04-1.4h85.11c2.21,0,4,1.79,4,4Z" />
                                    <path style={st1} d="m16.41,10.87v70.74c0,1.1.9,2,2,2h70.74c1.1,0,2-.9,2-2V10.87c0-1.1-.9-2-2-2H18.41c-1.1,0-2,.9-2,2Z" />
                                    <rect style={st0} x="49.11" y="42.84" width="9.34" height="9.34" rx=".5" ry=".5" />
                                    <rect style={st0} x="28.44" y="21.65" width="9.34" height="21.19" rx=".5" ry=".5" />
                                    <path style={st0} d="m36.78,52.15h-8.34c-.28,0-.5.22-.5.5v8.34c0,.28.22.5.5.5h8.84v-8.84c0-.28-.22-.5-.5-.5Z" />
                                    <path style={st0} d="m37.28,70.33c0,.28.22.5.5.5h32.01c.28,0,.5-.22.5-.5v-8.84h-33.01v8.84Z" />
                                    <path style={st0} d="m79.13,52.15h-8.34c-.28,0-.5.22-.5.5v8.84h8.84c.28,0,.5-.22.5-.5v-8.34c0-.28-.22-.5-.5-.5Z" />
                                    <rect style={st0} x="69.79" y="21.65" width="9.34" height="21.19" rx=".5" ry=".5" />
                                </g>
                            </svg> */}
            </div>
            <div className={styles.slogan}>
              易开即用的<span>AI写作平台</span>
            </div>
          </div>
          <div className={styles.mod2}>
            <div className={styles.mod2_top}>
              <div className={styles.text}>{list.sendMessage}</div>
              <div className={styles.info}>
                <span>我</span>
                <em>{list.meTime}</em>
              </div>
              <Line />
            </div>
            <div className={styles.mod2_bot}>
              <div className={styles.robot}>
                {props.drawerChatHistory[props.currentListIndex].type ==
                'agent' ? (
                  <>
                    <div className={styles.avatar}>
                      {props.drawerChatHistory[props.currentListIndex]
                        .faceImg ? (
                        <img
                          src={
                            props.drawerChatHistory[props.currentListIndex]
                              .faceImg
                          }
                          alt=""
                        />
                      ) : (
                        <AgentImg />
                      )}
                    </div>
                    <div className={styles.name}>
                      {props.drawerChatHistory[props.currentListIndex].title}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.avatar}>
                      <Robot />
                    </div>
                    <div className={styles.name}>智能机器人</div>
                  </>
                )}
              </div>
              <div className={styles.list}>
                <Markdown content={list.content} isCodeCopyBtn={true} />
              </div>
            </div>
          </div>
          <div className={styles.mod3}>
            <div
              className={styles.qr}
              style={{ width: '70px', height: '70px' }}>
              {/* <Qr /> */}
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1280 1280">
                <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
                  <path
                    d="M895,11898c-3-7-4-476-3-1043l3-1030h1040h1040v1040v1040l-1038,3C1112,11910,898,11908,895,11898z M2675,10865v-740h-740
		h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V10865z"
                  />
                  <path d="M1490,10865v-445h445h445v445v445h-445h-445V10865z" />
                  <path d="M3282,11898c-14-14-17-554-4-576c7-9,73-12,298-10l289,3v295v295l-285,3C3366,11910,3291,11907,3282,11898z" />
                  <path
                    d="M5667,11904c-4-4-7-72-7-151v-143h145h145v-150v-150h-145h-146l3-147l3-148h140h140l3,148l3,147h149h149l3-147l3-148l148-3
		l147-3l-2-292l-3-292h-145h-145l-5,145l-5,145h-290h-290l-3-147l-3-147l-147-3l-147-3l-3-147l-3-148h-149h-149l-3,148l-3,147h-145
		h-145l-3-147l-3-147l-146-3l-147-3l-1-138c-1-78-6-142-11-147c-7-7-63-10-145-8l-134,3l-5,145l-5,145l-148,3l-148,3l3,147l3,147
		l295,5l295,5l2,139c0,76,5,142,10,147s204,10,443,11l435,3v140v140l-434,3c-308,1-438,6-448,14c-12,10-13,118-3,280
		c0,4,132,9,293,10l292,3v145v145h-440h-440l-5-440l-5-440h-295h-295l-3,143l-3,143l-147-3l-147-3l-3-579c-1-426,1-582,9-593
		c9-11,41-13,148-11l136,3l5,145l5,145l148,3l147,3v-296v-295h289c224,0,291-3,299-13c7-8,11-63,11-137c0-69,0-131,0-138
		c1-10,63-12,294-10l292,3v145v145l-145,5l-145,5l-3,293l-2,292h150h149l3-147l3-148l148-3l147-3v-144v-145h138c94,0,142-4,150-12
		s12-56,12-150v-137l143-3l142-3v-295v-295h-140h-140l-5,145l-5,145h-145h-145l-5-145l-5-145l-145-5l-145-5l-5-145l-5-145l-205,4
		c-113,2-244,0-292-4l-88-7v-132c0-72-4-136-8-143c-5-9-51-13-147-15l-140-3l-3-147l-3-148h-744h-745v150v150l593,2l592,3l5,144
		l5,144l138,2c75,1,142,6,147,11c7,7,9,168,8,445l-3,434h-145h-145l-3-449l-2-448l-23,6c-12,3-79,6-149,6h-128v295v296l-147-3
		l-148-3l-5-145l-5-145l-142-3l-142-3l-3,148l-3,148h-145h-145v-145v-145l148-3l147-3v-138c0-106-3-141-14-150c-9-8-58-11-147-9
		l-134,3l-3-148l-3-148l-147,3l-147,3l-3,293l-2,292h-150h-150v445v445l-22,5c-13,3-77,4-143,3l-120-3l-5-145l-5-145l-295-5l-295-5
		l-5-145l-5-145l-145-5l-145-5l-3-879c-1-641,1-882,9-892c9-11,45-14,150-14h139l-2-292l-3-293l-145-5l-145-5v-440v-440l147-3l147-3
		l3-147l3-147h145h145l3,139c2,105-1,141-10,148c-7,4-75,8-151,8h-137l2,293l3,292h145h145l5-145l5-145l145-5l145-5l3-142l3-143h294
		h295l-2-297l-3-298h-145h-145l-5,145l-5,145h-140h-140v-295v-295l442-3l442-2l3-146l3-145l145-3l145-3l3-146l3-147l-298-2l-298-3
		l-3-148l-3-148l-147,3l-147,3l-5,145l-5,145h-140h-140l-3-147l-3-148h-299h-299l-3,148l-3,147h-145H895v-145v-145l148-3l148-3
		l-3-147l-3-147l-145-5l-145-5l-3-134c-2-90,1-138,9-147c9-11,64-14,284-14c151,0,280,3,289,6c14,5,16,28,16,150v143l148,3l147,3
		l3,148l3,147h147h147l-3-286c-2-208,1-290,9-300c9-12,40-14,148-12l136,3l3,298l2,297h300h300v-150v-150h-150h-151l3-147l3-148
		l134-3c90-2,138,1,147,9c11,9,14,44,14,150v139h295h296l-3-147l-3-148l-139-2c-76-1-142-5-147-10c-6-6-8-70-7-144l3-134l145-5
		l145-5l5-145l5-145l145-5l145-5l5-145l5-145l145-5l145-5l3-292l2-292l-147-3l-148-3l-5-145l-5-145l-145-5l-145-5v-295V895h295h295
		l3,147l3,148h294h294l3-147l3-148l434-3c312-1,438,1,447,9c11,9,14,45,14,150v139h145h145v-137c0-76,4-144,8-151c7-9,43-12,148-10
		l139,3l5,145l5,145l145,5l145,5l3,147l3,147l147,3l147,3l5,145l5,145l293,3l292,2v145v145l-292,2l-293,3l-5,145l-5,145l-145,5
		l-145,5v145v145l145,5l145,5l3,139c2,105-1,141-10,148c-17,10-859,10-876,0c-9-7-12-43-10-148l3-139l145-5l145-5v-295v-295h-145
		h-145l-3,148l-3,147h-144h-144l-3,147l-3,148h-145h-145v-145v-145l147-3l147-3l3-147l3-147l143-3l142-3v-144v-144l-142-3l-143-3
		l-3-298l-2-297h-450h-450v150v149l148,3l147,3v145v145l-147,3l-148,3v144v145h-139c-95,0-142,4-147,12s-9,219-5,281c1,4,66,7,146,7
		h144l3-147l3-148h145h145v145v145l-147,3l-148,3v149v149l148,3l147,3l3,293l2,292h-139c-105,0-141,3-150,14c-8,9-11,96-9,297l3,284
		h145h145l5-145l5-145l295-5l295-5l5-145l5-145h140h140l5,295l5,295h295h295l3-147l3-147l-148-3l-148-3l-3-139c-2-105,1-141,10-148
		c15-9,249-11,272-2c14,5,16,28,16,150v144h150h149l3-147l3-148l142-3l142-3l3-142l3-142l593-3c470-2,592,0,593,10c0,7,1,72,1,143
		v130l-144,5l-143,5l-3,147l-3,148h596h595l-2-292l-3-293l-147-3l-148-3v146v146l-147-3l-148-3v-140v-140l148-3l147-3v-149v-149
		l-147-3l-148-3l-3-148l-3-147l-292,2l-292,3l-5,145l-5,145h-445h-445v-145v-145l295-5l295-5l3-293l2-292h150h150l2-447l3-448h290
		h290l3,742l2,742l148,3l147,3l3,148l3,148l147-3l147-3l3-297l2-298h-150h-150v-145v-144l148-3l147-3v-145v-145l-145-5l-145-5v-145
		v-145l145-5l145-5l5-145l5-145h440h440l3,147l3,147l147,3l147,3l3,148l3,148l147-3l147-3l3-147l3-148h144h145v150v150h150h149
		l3,148l3,147h145h145l5-145l5-145h145h145v290v290l-145,5l-145,5l-5,145l-5,145l-148,3l-148,3l3,147l3,147l295,5l295,5v290v290
		l-145,5l-145,5l-3,147l-3,147l148,3l148,3l3,1029c1,752-1,1032-9,1042c-9,11-46,14-150,14h-139v145v145h134c74,0,141,3,150,6
		c14,5,16,54,16,445v439l-22,5c-13,3-79,4-148,3l-125-3l-5-145l-5-145h-145h-145l-3,292l-2,292l147,3l148,3l3,147l3,147l147,3l147,3
		l3,1029c1,752-1,1032-9,1042c-9,11-67,14-284,14c-151,0-280-3-289-6c-14-5-16-41-16-300v-294l-442-2l-443-3l-3-295l-2-295h150h150
		v147v148l293,2l292,3l3,148l3,148l147-3l147-3v-440v-440l-147-3l-147-3l-3,148l-3,148h-290h-290l-3-297l-2-298h145h144l3-147l3-148
		l148-3l147-3v-149v-150h-295h-294l-3,148l-3,147l-298,3l-298,2l3,148l3,147l145,5l145,5v294v295l-285,2c-188,2-289-1-297-8
		c-10-8-13-78-13-299v-289h-144h-145l-3-134c-2-73-2-141,0-150c3-14,34-16,298-16h294v-150v-150l-296,2l-296,3l1,147l2,148l-298,2
		l-298,3l-5,145l-5,145l-145,5l-145,5l-3,443l-2,443l-148-3l-147-3l-2-585c-2-322-3-588-3-592c0-5-65-8-145-8h-144l-3,148l-3,147
		l-134,3c-74,1-141,0-148-2c-9-4,1-26,32-71c25-36,45-73,45-83s6-27,14-38s16-38,18-60l3-39l88-3l87-3v-444v-445h145h145v279
		c0,154,3,286,6,295c6,14,68,16,600,16h595l-3-142l-3-143l-145-5l-145-5v-295v-295l139-3c105-2,141,1,148,10c4,7,8,75,8,150v138
		l293,2l292,3l3,293l2,292h300h301l-3-142l-3-143l-145-5l-145-5l-3-147l-3-147l148-3l148-3l3-147l3-148l-298-2l-298-3l-5-145l-5-145
		l-145-5l-145-5l-3-142l-3-142l-145-3l-146-3l1-147l2-147l-148-3l-148-3l-3-297l-2-298h-150h-149l-3,147l-3,148l-297,3l-297,2v-141
		c0-78-4-146-8-150c-5-5-204-10-443-11l-435-3l-5-145l-5-145l-134-2c-74-1-141-5-148-7c-10-4-13-39-13-146v-140h-295h-295v144
		c0,106-3,145-12,149c-7,2-69,5-138,5s-131,1-137,1c-10,1-13,37-13,146v146l148-3l147-3l5-145l5-145l138,2c75,1,142,5,147,8
		c6,4,10,66,10,146v139h145c167,0,158-6,153,110l-3,65h-445h-445l-3-88l-3-88l-146,3l-147,3v85l-1,85l-339,3c-280,2-342,5-359,17
		c-12,8-29,15-39,15c-9,0-48,21-86,46c-51,35-81,65-116,116c-25,38-46,77-46,86c0,10-6,27-14,38s-16,38-18,60l-3,39h-380h-380v-145
		v-145l143-3l142-3l2-32c0-17,1-84,2-147l1-115l148-3l148-3l-3-147l-3-147h-140h-140l-3,147l-3,148h-149h-150v-295v-295h-150h-149
		l-3,146l-3,145h-145h-145l-3-146l-3-146l-147,3c-122,3-147,6-148,18c-1,8-2,74-3,146l-1,131l-145-1l-144-1l-3,145l-3,145l-148,3
		l-148,3l3,147l3,147l145,5l145,5l3,148l3,147h144c131,0,145-2,145-17c1-10,2-76,3-148l2-130h145h145l3,147l3,147l147,3l147,3v145
		v145h-145h-145l-3-147l-3-148h-149h-150l2,293l3,292l295,5l295,5l5,145l5,145l443,3l443,2l-3-147l-3-148l-142-3c-84-1-143-7-143-12
		c0-6-1-70-1-142v-133h233h233v290v290l-87,3l-87,3l-3,147l-3,147l-593,3l-593,2l3,148l3,147l295,5l295,5l3,443l2,442h145
		c80,0,146-3,147-7c0-5,2-135,3-291c1-168,6-287,12-293s66-8,144-7l134,3l3,298l2,297h155h155l66,45c36,25,72,45,80,45
		c9,0,25,5,37,12s40,14,62,17l40,6l3,88l3,87h149h150v-90v-91l143,3l142,3l3,88l3,87h299h299l3-87l3-88l141-3c164-3,154-10,154,111
		v67h145h146l-3,148l-3,147l-90,3c-49,2-114,0-142-3l-53-6v-145v-144h-150h-150v145c0,109-3,145-12,146c-7,1-67,2-133,3
		c-66,2-128,3-138,4c-16,2-17,14-15,145l3,142l145,5l145,5l3,148l3,148l147-3l147-3l5-145l5-145h140h140l5,145l5,145h295h295l3-147
		l3-147l-148-3l-148-3v-290v-290l148-3l147-3v295v295l148,3l147,3l3,147l3,148l292,2l292,3l5,145l5,145l295,5l295,5l3,139
		c2,105-1,141-10,148c-7,4-207,8-444,8c-336,0-433,3-437,13c-2,6-5,139-6,295l-2,282h150h150l3-147l3-148h145h145l5,145l5,145h145
		h145l5-145l5-145l134-3c90-2,138,1,147,9c12,10,14,129,14,733c0,396-4,726-8,733c-5,9-51,13-147,15l-140,3l-5,295l-5,295h-295h-295
		v-145v-145l145-5l145-5v-145v-145l-145-5l-145-5l-5-145l-5-145l-145-5l-145-5l-1-140l-1-140l-447-5l-446-5v147v148l-292,2l-293,3
		l-3,148l-3,147h145h145l3,148l3,147l147,3l147,3l3-148l3-148l441-3c349-2,443,0,446,10c2,7,2,75,0,151l-4,137h-444h-443l-3,148
		l-3,147h-440h-440l-5-295l-5-295l-147-3l-147-3l-3,148l-3,148l-147,3l-147,3l-3,147l-3,147l-136,3
		C5735,11909,5671,11907,5667,11904z M7438,10568l3-148h-151h-150v-150v-150h150h149l3-147l3-148l147-3l148-3l2,298l3,298l293,3
		l292,2v-150v-150h-145h-144l-3-147l-3-148l-147-3l-148-3v-144v-145h295c274,0,295-1,295-17c1-27,1-256,1-271c-1-10-63-12-293-10
		l-293,3l-3,147l-3,147l-285,1c-202,0-290-3-300-11c-11-9-14-44-14-150v-139h-145h-145v138c0,94-4,142-12,150s-56,12-150,12h-138
		v295v295h150h149l3,148l3,147l143,3l142,3v137c0,96,4,142,12,150c9,9,51,12,148,10l135-3L7438,10568z M8925,10570v-145h-145h-145
		l-3,134c-2,91,1,138,9,147c9,12,40,14,148,12l136-3V10570z M5948,10268l3-148h150h150l-3-147l-3-148l-134-3c-90-2-138,1-147,9
		c-11,9-14,44-14,150v139h-145h-145v-138c0-94-4-142-12-150s-56-12-150-12h-138v150v150h150h150v150v151l143-3l142-3L5948,10268z
		 M6548,9077l3-148l-148,3l-148,3l-3,134c-1,74,0,141,2,148c4,11,37,13,148,11l143-3L6548,9077z M1790,8780l5-145l143-3l142-3v-444
		v-445h-145h-145v138c0,94-4,142-12,150s-56,12-150,12h-138l2-297l3-298h440h440l5,145l5,145l147,3l147,3l3-148l3-148l443-3l443-2
		l-3-148l-3-147l-145-5l-145-5l-3-142l-3-143h-144h-145v-150v-150h295h296l-3-147l-3-148l-292-3l-293-2v-134c0-74-3-141-6-150
		c-5-14-28-16-150-16h-144v-145v-145h138c94,0,142-4,150-12s12-56,12-150v-138h-150h-150v150v150h-150h-150v145v145h-138
		c-94,0-142,4-150,12s-12,56-12,150v138h-145h-144l-3,148l-3,147l-148,3l-148,3l3,147l3,147h290h290l3-298l2-298l148,3l147,3l5,145
		l5,145l148,3l147,3v149v149l-147,3l-148,3l-3,143l-3,142h-594h-595l2,448l3,447l148,3l147,3v438c0,241,3,441,7,445c4,3,70,5,147,4
		l141-3L1790,8780z M5353,8331c4-1,7-66,7-146v-146l-147,3l-148,3l-3,146l-3,146l143-3C5281,8333,5348,8331,5353,8331z M5928,8334
		c22-6,22-9,22-150v-144h-145h-145v144v145l53,4C5808,8339,5905,8340,5928,8334z M4758,7888l3-148h-146h-145l-1,130
		c-1,72-2,136-3,143c-1,6-1,15,2,20c2,4,67,6,145,5l142-3L4758,7888z M11310,7890v-149l148-3l147-3l3-147l3-148h-151h-150v150v150
		h-150h-150v134c0,74,3,141,6,150c5,14,28,16,150,16h144V7890z M11010,6112c0-94,4-142,12-150s56-12,150-12h137l3,148l3,147h145h145
		l3-147l3-148h-151h-150v-145v-145h-138c-94,0-142-4-150-12s-12-56-12-150v-138h-445h-446l3-147l3-148l145-5l145-5l5-145l5-145h145
		h145l3,148l3,147l442-2l442-3v-145v-145l-145-5l-145-5l-3-148l-3-148l133,1c73,1,141,1,151,1c16-1,17-29,17-446v-445l-592,2l-593,3
		l-3,147l-3,148l-297,2l-297,3v145v145l298,3l297,2v145v145l-92,2c-80,2-149,5-195,7c-10,1-13,37-13,146v145h-295h-295v-140
		c0-123-2-140-17-148c-10-5-78-10-150-11l-133-2v150v151h150h151l-1,298l-1,297l295,3l295,2l3,148l3,147l295,5l295,5l3,293l2,292
		h145h145V6112z M5058,4913l-3-148l-147-3l-148-3v144c0,79,3,147,7,150c3,4,71,7,150,7h144L5058,4913z M5950,4615v-144l-32-6
		c-18-3-82-5-143-3l-110,3l-3,148l-3,147h146h145V4615z M8930,4615c0-172-15-158,171-153l124,3l3-147l3-148h143c122,0,145-2,150-16
		c8-20,8-548,0-568c-5-14-28-16-150-16h-143l-3,148l-3,147l-145,5l-145,5l-5,145l-5,145h-145h-145l-5-145l-5-145l-142-3l-141-3
		l-7,54c-8,65-3,523,6,532c4,3,69,7,145,8l139,2l3,135c1,74,4,141,7,148c3,9,43,12,150,12h145V4615z M3270,4020v-150h-145h-145v150
		v150h145h145V4020z M4457,4158c11-14,16-512,7-560l-6-28h-144h-144v-150v-150h144c110,0,145-3,149-13c3-7,5-67,5-133
		c0-65,1-125,1-131c1-9,40-13,144-15l142-3l3-147l3-148h-296h-295v295v294l-147,3l-148,3l-3,148l-3,147h-150h-150l3,148l3,147h145
		h145l3-147l3-148h149h150v300v300h139C4408,4170,4450,4166,4457,4158z M7440,4020l5-145h145h145l5,145l5,145h145h145l3-297l2-298
		l-297-2l-298-3l-3-142c-3-169,13-155-172-151l-125,3l-3,148l-3,147h-144h-145v150v150h145h145v150v151l148-3l147-3L7440,4020z
		 M11310,3125v-145h-138c-94,0-142-4-150-12c-9-9-12-89-12-300v-288h150h151l-3-147l-3-148h-145h-145l-3,147l-3,147l-142,3l-142,3
		l-3,435c-1,239,0,440,2,445c2,6,107,9,295,7l291-3V3125z M10418,2828l-3-443h-440h-440l-3,443l-2,442h445h445L10418,2828z
		 M8332,1940c0-74-1-138-1-142c-1-5-66-8-146-8h-145v145v146l147-3l146-3L8332,1940z M9528,1933l3-143h-151h-150v145v146l148-3
		l147-3L9528,1933z M10418,1783l2-292l-147-3l-148-3l-3-147l-3-148h-294h-295v290c0,160,0,293,0,298c0,4,65,8,145,10l145,3v138
		c0,76,3,141,7,144c4,4,137,6,297,5l291-3L10418,1783z M11010,1785v-295h-147h-148l3,295l3,295h144h145V1785z M4760,1640v-150h-295
		h-295v150v150h295h295V1640z M6548,1637l3-147l-298,2l-298,3l-3,134c-1,74,0,141,2,148c4,11,64,13,298,11l293-3L6548,1637z"
                  />
                  <path d="M6852,9973l3-148h140h140l3,148l3,147h-146h-146L6852,9973z" />
                  <path d="M2680,6995v-145h150h151l-3,143l-3,142l-147,3l-148,3V6995z" />
                  <path d="M2682,6403l3-148l148-3l147-3v151v150h-150h-151L2682,6403z" />
                  <path d="M10722,4613l3-148h140h140l3,148l3,147h-146h-146L10722,4613z" />
                  <path d="M11012,4320l3-145l148-3l147-3v145c0,135-1,145-20,151c-11,3-78,5-150,3l-130-3L11012,4320z" />
                  <path d="M10420,4020v-151l148,3l147,3l3,125c4,186,18,170-153,170h-145V4020z" />
                  <path d="M9824,2967c-2-7-3-74-2-148l3-134h145h145v145v145l-143,3C9861,2980,9828,2978,9824,2967z" />
                  <path
                    d="M9825,11898c-3-7-4-476-3-1043l3-1030h1040h1040l3,1034c2,821,0,1036-10,1043c-7,4-476,8-1041,8
		C10044,11910,9828,11907,9825,11898z M11605,10865v-740h-740h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V10865z"
                  />
                  <path d="M10420,10865v-445h445h445v445v445h-445h-445V10865z" />
                  <path d="M2687,9523c-4-3-7-71-7-150v-144l148,3l147,3l3,134c2,90-1,138-9,147c-9,11-43,14-144,14C2753,9530,2690,9527,2687,9523z" />
                  <path d="M10132,9522c-9-7-12-43-10-148l3-139l443-3l443-2l-3,147l-3,148l-430,2C10339,9529,10139,9526,10132,9522z" />
                  <path
                    d="M9536,9222c-3-5-6-71-7-148l-1-139l146-3l145-3l3-147l3-147h145h145v145v145l-147,3l-147,3l-3,147l-3,147l-137,3
		C9603,9229,9539,9227,9536,9222z"
                  />
                  <path d="M4469,8928c-6-18-4-268,3-281c8-16,23-17,146-15l137,3l3,148l3,147h-146C4535,8930,4470,8929,4469,8928z" />
                  <path
                    d="M9230,8780v-150h-150h-150v-295v-295l298,2l297,3l3,137c2,123,1,138-15,146c-10,6-72,10-138,10s-126,0-132,1
		c-10,1-13,37-13,146v145h144c122,0,145,2,150,16c3,9,6,76,6,150v134h-150h-150V8780z"
                  />
                  <path d="M8635,6838c-3-7-4-141-3-298l3-285h145h145v295v295l-143,3C8673,6850,8638,6848,8635,6838z" />
                  <path d="M7864,6237c-2-7-3-74-2-148l3-134l226-3c174-2,228,1,235,10c5,7,8,75,6,151l-4,137h-229C7920,6250,7868,6247,7864,6237z" />
                  <path d="M8634,5937c-2-7-3-71-2-143l3-129l448-3l448-2l-3,142l-3,143l-443,3C8724,5950,8638,5948,8634,5937z" />
                  <path d="M8047,5653c-4-3-7-138-7-300v-294l146,3l146,3v287c-1,159-4,292-7,298C8318,5661,8058,5664,8047,5653z" />
                  <path d="M3277,4753c-9-9-9-265,1-279c4-7,59-12,150-13l142-2v150v151h-143C3348,4760,3280,4757,3277,4753z" />
                  <path
                    d="M897,2973c-4-3-7-471-7-1039V900l23-5c12-3,481-4,1042-3l1020,3v1040v1040l-1036,3C1370,2979,900,2977,897,2973z
		 M2675,1935v-740h-740h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V1935z"
                  />
                  <path d="M1490,1935v-445h445h445v445v445h-445h-445V1935z" />
                  <path d="M3274,2067c-2-7-3-71-2-143l3-129l148-3l147-3v146v145h-145C3315,2080,3278,2077,3274,2067z" />
                  <path d="M7147,1183c-4-3-7-66-7-138c0-101,3-135,14-144c9-8,57-11,147-9l134,3l3,148l3,147h-144C7218,1190,7150,1187,7147,1183z" />
                  <path
                    d="M11314,1177c-2-7-3-74-2-148l3-134l275-3c151-1,285,0,298,3c22,5,23,7,20,148l-3,142l-293,3
		C11378,1190,11318,1188,11314,1177z"
                  />
                  <g id="Layer_2_00000022547425814448459770000010252301187875038644_">
                    <g id="图层_1">
                      <path
                        style={st0}
                        d="M5574.2,7675.5h1654.1c247.6,0,448.4-200.8,448.4-448.4V5572.9c0-247.6-200.8-448.4-448.4-448.4H5574.2
				c-247.6,0-448.4,200.8-448.4,448.4v1654.1C5125.8,7474.7,5326.6,7675.5,5574.2,7675.5z"
                      />
                      <polygon
                        style={st1}
                        points="6469.2,7259 5897.8,7259 5776,7259 5542.1,7024.4 5546.7,5541 5897.8,5541 6469.2,5541 7029.5,5541 
				7260.6,5775.6 7260.6,7259 			"
                      />
                      <path
                        style={st0}
                        d="M5895.9,7114.3V5894.4h1219.9v1219.9H5895.9z M6429.7,6559.8h152.4v-152.4h-152.4V6559.8z M6244.6,6255.3
				h-152.2v152.5h152.4L6244.6,6255.3z M6244.6,6559.8h-152.2v345.9h152.4L6244.6,6559.8z M6767.1,6103.1h-522.5v152.5h522.5V6103.1
				z M6919.5,6255.3h-152.4v152.5h152.4V6255.3z M6919.5,6559.8h-152.4v345.9h152.4V6559.8z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className={styles.info}>
              <p>扫描或长按</p>
              <p>使用智能助理来获取灵感</p>
            </div>
          </div>
        </div>
        <div className={styles.botOut}>
          <div
            className={styles.bot}
            onClick={downloadImg}
            style={{ pointerEvents: loading ? 'none' : 'auto' }}>
            <div className={styles.icon}>
              {loading ? (
                <IconLoading style={{ fill: 'none' }} />
              ) : (
                <IconDownload style={{ fill: 'none' }} />
              )}
            </div>
            <span>保存</span>
          </div>
          {/* <div className={styles.bot} onClick={shareImgUrl}>
                        <div className={styles.icon}>
                            <IconLink style={{ fill: 'none' }} />
                        </div>
                        <span>分享</span>
                    </div> */}
          <div className={styles.bot}>
            <Copy
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              text={shareUrl}
              onCopy={() => {
                Message.success({
                  icon: <IconSuccessTip useCurrentColor={false} />,
                  content: '复制成功',
                })
                close()
              }}
              content={
                <>
                  <div className={styles.icon}>
                    <IconLink style={{ fill: 'none' }} />
                  </div>
                  <span>
                    {props.drawerChatHistory[props.currentListIndex].type ==
                    'agent'
                      ? '分享智能体'
                      : '分享'}
                  </span>
                </>
              }
            />
          </div>
        </div>
      </div>
      {/* 下载的dom */}
      <div
        className={styles.share_pop}
        style={{ position: 'absolute', opacity: 0 }}>
        <div className={styles.top1} ref={ref}>
          <div className={styles.mod1}>
            <div
              className={styles.logo}
              style={{ width: '130px', marginBottom: '10px' }}>
              {/* <Logo /> */}
              <svg
                id="Layer_2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 345.61 100"
                style={{}}>
                <g id="aidb_logo">
                  <path d="m145.53,46.24l1.48-3.98c.04-.12-.02-.26-.13-.31-3.75-1.76-5.7-3.07-5.7-3.07v-13.28c0-.14-.11-.25-.25-.25h-4.3c-.14,0-.25.11-.25.25v49.69c0,.14.11.25.25.25h4.3c.14,0,.25-.11.25-.25v-30.75l4.01,1.84c.13.06.29,0,.34-.14Z" />
                  <path d="m128.41,34.66c0,6.05-.32,12.1-.96,18.12l-.23,2.12c-.01.14.08.26.22.28l4.27.45c.14.01.26-.08.28-.22l.23-2.12c.66-6.19.99-12.41.99-18.63v-.72c0-.14-.11-.25-.25-.25h-4.3c-.14,0-.25.11-.25.25v.72Z" />
                  <path d="m175.42,46.87v-9.04c0-1.36-1.1-2.45-2.45-2.45h-10.66l1.21-3.96h13.92c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-28.58c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h9.64l-1.21,3.96h-7.21c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h5.75l-2.04,6.7h-5.91c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h30.67c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-3.12Zm-14.58-6.7h9.78v6.7h-11.83l2.04-6.7Z" />
                  <path d="m174.93,55.83h-23.45c-1.35,0-2.45,1.1-2.45,2.45v14c0,1.35,1.1,2.45,2.45,2.45h23.45c1.35,0,2.45-1.1,2.45-2.45v-14c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,14.11h-18.76v-9.32h18.76v9.32Z" />
                  <path d="m290.56,28.95v11.84c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-9.5h-38.17v9.5c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-11.84c0-1.36,1.1-2.45,2.45-2.45h42.86c1.36,0,2.45,1.1,2.45,2.45Zm-6.92,20.74h-27.87l.82-6.45h25.73c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-25.12l.4-3.11c.02-.14-.08-.26-.22-.28l-4.26-.54c-.14-.02-.26.08-.28.22l-2.48,19.48c-.02.15.1.28.25.28h30.74v15.08h-9.36c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h11.66c1.38,0,2.5-1.12,2.5-2.5v-19.68c0-1.38-1.12-2.5-2.5-2.5Zm-7.26,10.31h-33.02c-.14,0-.25.11-.25.25v4.3c0,.14.11.25.25.25h33.02c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25Z" />
                  <path d="m233.14,26.99h-15.84c-1.35,0-2.45,1.1-2.45,2.45v17.31c0,1.35,1.1,2.45,2.45,2.45h15.84c1.35,0,2.45-1.1,2.45-2.45v-17.31c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,17.42h-11.15v-12.63h11.15v12.63Z" />
                  <path d="m231.18,52.27h-38.24c-1.35,0-2.45,1.1-2.45,2.45v17.56c0,1.35,1.1,2.45,2.45,2.45h38.24c1.35,0,2.45-1.1,2.45-2.45v-17.56c0-1.35-1.1-2.45-2.45-2.45Zm-2.34,17.67h-33.55v-12.87h33.55v12.87Z" />
                  <rect
                    x="199.15"
                    y="61.11"
                    width="25.8"
                    height="4.8"
                    rx=".25"
                    ry=".25"
                  />
                  <path d="m186.03,47.6l2.37,3.59c.07.11.22.15.33.08,1.65-1.01,13.15-7.64,20.5-.81.1.09.25.09.34,0l2.99-3.09c.1-.1.09-.27,0-.36-3.63-3.41-8.32-4.87-13.74-4.29.76-.8,1.42-1.54,1.96-2.17h10.44c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-8.3v-3.71h7.2c.14,0,.25-.11.25-.25v-4.3c0-.14-.11-.25-.25-.25h-16.75l.38-1.23c.03-.15-.07-.29-.22-.3l-4.28-.83c-.13-.03-.27.05-.3.19l-.91,3.99c-.17.73,0,1.49.48,2.07.47.59,1.17.92,1.92.92h7.69v3.71h-11.07c-.14,0-.25.11-.25.25v4.55h7.43s-2.61,3.23-8.13,6.71c-.12.07-.16.23-.08.35Z" />
                  <path d="m345.36,36.47h-18.71v9.17h17.48c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25h-17.48v9.54h17.48c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25h-17.48v10.38c0,.14-.11.25-.25.25h-4.3c-.14,0-.25-.11-.25-.25v-38.68h-.19c-1.94,2.8-4.56,5.69-8.06,7.94-.12.08-.28.04-.35-.08l-2.29-3.64c-.07-.11-.04-.26.08-.34,7.3-4.71,10.19-13.19,10.65-14.66.04-.13.17-.19.3-.16l4.15,1.12c.14.04.22.17.18.31-.19.64-.76,2.43-1.87,4.71h20.91c.14,0,.25.11.25.25v4.3c0,.14-.11.25-.25.25Zm-33.78-6.18l1.93-4.09c.06-.13,0-.3-.15-.34l-4.04-1.38c-.13-.04-.27.02-.32.16l-.64,1.86c-2.8,8.21-6.78,14.5-13.51,19.95-.1.08-.12.21-.06.32l1.99,3.31c.08.13.26.16.38.07,2-1.62,3.85-3.38,5.57-5.26v30.27c0,.14.11.25.25.25h4.3c.14,0,.25-.11.25-.25v-36.28l4.05-8.59Z" />
                  <path
                    style={st0}
                    d="m100.02,4v85.08c0,1.15-.5,2.25-1.36,3.01l-7.9,6.92c-.73.64-1.67.99-2.64.99H4c-2.21,0-4-1.79-4-4V12.09c0-.95.34-1.87.96-2.6L7.87,1.4c.76-.89,1.87-1.4,3.04-1.4h85.11c2.21,0,4,1.79,4,4Z"
                  />
                  <path
                    style={st1}
                    d="m16.41,10.87v70.74c0,1.1.9,2,2,2h70.74c1.1,0,2-.9,2-2V10.87c0-1.1-.9-2-2-2H18.41c-1.1,0-2,.9-2,2Z"
                  />
                  <rect
                    style={st0}
                    x="49.11"
                    y="42.84"
                    width="9.34"
                    height="9.34"
                    rx=".5"
                    ry=".5"
                  />
                  <rect
                    style={st0}
                    x="28.44"
                    y="21.65"
                    width="9.34"
                    height="21.19"
                    rx=".5"
                    ry=".5"
                  />
                  <path
                    style={st0}
                    d="m36.78,52.15h-8.34c-.28,0-.5.22-.5.5v8.34c0,.28.22.5.5.5h8.84v-8.84c0-.28-.22-.5-.5-.5Z"
                  />
                  <path
                    style={st0}
                    d="m37.28,70.33c0,.28.22.5.5.5h32.01c.28,0,.5-.22.5-.5v-8.84h-33.01v8.84Z"
                  />
                  <path
                    style={st0}
                    d="m79.13,52.15h-8.34c-.28,0-.5.22-.5.5v8.84h8.84c.28,0,.5-.22.5-.5v-8.34c0-.28-.22-.5-.5-.5Z"
                  />
                  <rect
                    style={st0}
                    x="69.79"
                    y="21.65"
                    width="9.34"
                    height="21.19"
                    rx=".5"
                    ry=".5"
                  />
                </g>
              </svg>
            </div>
            <div className={styles.slogan}>
              易开即用的<span>AI写作平台</span>
            </div>
          </div>
          <div className={styles.mod2}>
            <div className={styles.mod2_top}>
              <div className={styles.text1}>{list.sendMessage}</div>
              <div className={styles.info}>
                <span>我</span>
                <em>{list.meTime}</em>
              </div>
              <Line />
            </div>
            <div className={styles.mod2_bot}>
              <div className={styles.robot}>
                {props.drawerChatHistory[props.currentListIndex].type ==
                'agent' ? (
                  <>
                    <div className={styles.avatar}>
                      {props.drawerChatHistory[props.currentListIndex]
                        .faceImg ? (
                        <img
                          src={
                            props.drawerChatHistory[props.currentListIndex]
                              .faceImg
                          }
                          alt=""
                        />
                      ) : (
                        <AgentImg />
                      )}
                    </div>
                    <div className={styles.name}>
                      {props.drawerChatHistory[props.currentListIndex].title}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.avatar}>
                      <Robot />
                    </div>
                    <div className={styles.name}>智能机器人</div>
                  </>
                )}
              </div>
              <div className={styles.list1}>
                <Markdown content={list.content} />
              </div>
            </div>
          </div>
          <div className={styles.mod3}>
            <div
              className={styles.qr}
              style={{ width: '70px', height: '70px' }}>
              {/* <Qr /> */}
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1280 1280">
                <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
                  <path
                    d="M895,11898c-3-7-4-476-3-1043l3-1030h1040h1040v1040v1040l-1038,3C1112,11910,898,11908,895,11898z M2675,10865v-740h-740
		h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V10865z"
                  />
                  <path d="M1490,10865v-445h445h445v445v445h-445h-445V10865z" />
                  <path d="M3282,11898c-14-14-17-554-4-576c7-9,73-12,298-10l289,3v295v295l-285,3C3366,11910,3291,11907,3282,11898z" />
                  <path
                    d="M5667,11904c-4-4-7-72-7-151v-143h145h145v-150v-150h-145h-146l3-147l3-148h140h140l3,148l3,147h149h149l3-147l3-148l148-3
		l147-3l-2-292l-3-292h-145h-145l-5,145l-5,145h-290h-290l-3-147l-3-147l-147-3l-147-3l-3-147l-3-148h-149h-149l-3,148l-3,147h-145
		h-145l-3-147l-3-147l-146-3l-147-3l-1-138c-1-78-6-142-11-147c-7-7-63-10-145-8l-134,3l-5,145l-5,145l-148,3l-148,3l3,147l3,147
		l295,5l295,5l2,139c0,76,5,142,10,147s204,10,443,11l435,3v140v140l-434,3c-308,1-438,6-448,14c-12,10-13,118-3,280
		c0,4,132,9,293,10l292,3v145v145h-440h-440l-5-440l-5-440h-295h-295l-3,143l-3,143l-147-3l-147-3l-3-579c-1-426,1-582,9-593
		c9-11,41-13,148-11l136,3l5,145l5,145l148,3l147,3v-296v-295h289c224,0,291-3,299-13c7-8,11-63,11-137c0-69,0-131,0-138
		c1-10,63-12,294-10l292,3v145v145l-145,5l-145,5l-3,293l-2,292h150h149l3-147l3-148l148-3l147-3v-144v-145h138c94,0,142-4,150-12
		s12-56,12-150v-137l143-3l142-3v-295v-295h-140h-140l-5,145l-5,145h-145h-145l-5-145l-5-145l-145-5l-145-5l-5-145l-5-145l-205,4
		c-113,2-244,0-292-4l-88-7v-132c0-72-4-136-8-143c-5-9-51-13-147-15l-140-3l-3-147l-3-148h-744h-745v150v150l593,2l592,3l5,144
		l5,144l138,2c75,1,142,6,147,11c7,7,9,168,8,445l-3,434h-145h-145l-3-449l-2-448l-23,6c-12,3-79,6-149,6h-128v295v296l-147-3
		l-148-3l-5-145l-5-145l-142-3l-142-3l-3,148l-3,148h-145h-145v-145v-145l148-3l147-3v-138c0-106-3-141-14-150c-9-8-58-11-147-9
		l-134,3l-3-148l-3-148l-147,3l-147,3l-3,293l-2,292h-150h-150v445v445l-22,5c-13,3-77,4-143,3l-120-3l-5-145l-5-145l-295-5l-295-5
		l-5-145l-5-145l-145-5l-145-5l-3-879c-1-641,1-882,9-892c9-11,45-14,150-14h139l-2-292l-3-293l-145-5l-145-5v-440v-440l147-3l147-3
		l3-147l3-147h145h145l3,139c2,105-1,141-10,148c-7,4-75,8-151,8h-137l2,293l3,292h145h145l5-145l5-145l145-5l145-5l3-142l3-143h294
		h295l-2-297l-3-298h-145h-145l-5,145l-5,145h-140h-140v-295v-295l442-3l442-2l3-146l3-145l145-3l145-3l3-146l3-147l-298-2l-298-3
		l-3-148l-3-148l-147,3l-147,3l-5,145l-5,145h-140h-140l-3-147l-3-148h-299h-299l-3,148l-3,147h-145H895v-145v-145l148-3l148-3
		l-3-147l-3-147l-145-5l-145-5l-3-134c-2-90,1-138,9-147c9-11,64-14,284-14c151,0,280,3,289,6c14,5,16,28,16,150v143l148,3l147,3
		l3,148l3,147h147h147l-3-286c-2-208,1-290,9-300c9-12,40-14,148-12l136,3l3,298l2,297h300h300v-150v-150h-150h-151l3-147l3-148
		l134-3c90-2,138,1,147,9c11,9,14,44,14,150v139h295h296l-3-147l-3-148l-139-2c-76-1-142-5-147-10c-6-6-8-70-7-144l3-134l145-5
		l145-5l5-145l5-145l145-5l145-5l5-145l5-145l145-5l145-5l3-292l2-292l-147-3l-148-3l-5-145l-5-145l-145-5l-145-5v-295V895h295h295
		l3,147l3,148h294h294l3-147l3-148l434-3c312-1,438,1,447,9c11,9,14,45,14,150v139h145h145v-137c0-76,4-144,8-151c7-9,43-12,148-10
		l139,3l5,145l5,145l145,5l145,5l3,147l3,147l147,3l147,3l5,145l5,145l293,3l292,2v145v145l-292,2l-293,3l-5,145l-5,145l-145,5
		l-145,5v145v145l145,5l145,5l3,139c2,105-1,141-10,148c-17,10-859,10-876,0c-9-7-12-43-10-148l3-139l145-5l145-5v-295v-295h-145
		h-145l-3,148l-3,147h-144h-144l-3,147l-3,148h-145h-145v-145v-145l147-3l147-3l3-147l3-147l143-3l142-3v-144v-144l-142-3l-143-3
		l-3-298l-2-297h-450h-450v150v149l148,3l147,3v145v145l-147,3l-148,3v144v145h-139c-95,0-142,4-147,12s-9,219-5,281c1,4,66,7,146,7
		h144l3-147l3-148h145h145v145v145l-147,3l-148,3v149v149l148,3l147,3l3,293l2,292h-139c-105,0-141,3-150,14c-8,9-11,96-9,297l3,284
		h145h145l5-145l5-145l295-5l295-5l5-145l5-145h140h140l5,295l5,295h295h295l3-147l3-147l-148-3l-148-3l-3-139c-2-105,1-141,10-148
		c15-9,249-11,272-2c14,5,16,28,16,150v144h150h149l3-147l3-148l142-3l142-3l3-142l3-142l593-3c470-2,592,0,593,10c0,7,1,72,1,143
		v130l-144,5l-143,5l-3,147l-3,148h596h595l-2-292l-3-293l-147-3l-148-3v146v146l-147-3l-148-3v-140v-140l148-3l147-3v-149v-149
		l-147-3l-148-3l-3-148l-3-147l-292,2l-292,3l-5,145l-5,145h-445h-445v-145v-145l295-5l295-5l3-293l2-292h150h150l2-447l3-448h290
		h290l3,742l2,742l148,3l147,3l3,148l3,148l147-3l147-3l3-297l2-298h-150h-150v-145v-144l148-3l147-3v-145v-145l-145-5l-145-5v-145
		v-145l145-5l145-5l5-145l5-145h440h440l3,147l3,147l147,3l147,3l3,148l3,148l147-3l147-3l3-147l3-148h144h145v150v150h150h149
		l3,148l3,147h145h145l5-145l5-145h145h145v290v290l-145,5l-145,5l-5,145l-5,145l-148,3l-148,3l3,147l3,147l295,5l295,5v290v290
		l-145,5l-145,5l-3,147l-3,147l148,3l148,3l3,1029c1,752-1,1032-9,1042c-9,11-46,14-150,14h-139v145v145h134c74,0,141,3,150,6
		c14,5,16,54,16,445v439l-22,5c-13,3-79,4-148,3l-125-3l-5-145l-5-145h-145h-145l-3,292l-2,292l147,3l148,3l3,147l3,147l147,3l147,3
		l3,1029c1,752-1,1032-9,1042c-9,11-67,14-284,14c-151,0-280-3-289-6c-14-5-16-41-16-300v-294l-442-2l-443-3l-3-295l-2-295h150h150
		v147v148l293,2l292,3l3,148l3,148l147-3l147-3v-440v-440l-147-3l-147-3l-3,148l-3,148h-290h-290l-3-297l-2-298h145h144l3-147l3-148
		l148-3l147-3v-149v-150h-295h-294l-3,148l-3,147l-298,3l-298,2l3,148l3,147l145,5l145,5v294v295l-285,2c-188,2-289-1-297-8
		c-10-8-13-78-13-299v-289h-144h-145l-3-134c-2-73-2-141,0-150c3-14,34-16,298-16h294v-150v-150l-296,2l-296,3l1,147l2,148l-298,2
		l-298,3l-5,145l-5,145l-145,5l-145,5l-3,443l-2,443l-148-3l-147-3l-2-585c-2-322-3-588-3-592c0-5-65-8-145-8h-144l-3,148l-3,147
		l-134,3c-74,1-141,0-148-2c-9-4,1-26,32-71c25-36,45-73,45-83s6-27,14-38s16-38,18-60l3-39l88-3l87-3v-444v-445h145h145v279
		c0,154,3,286,6,295c6,14,68,16,600,16h595l-3-142l-3-143l-145-5l-145-5v-295v-295l139-3c105-2,141,1,148,10c4,7,8,75,8,150v138
		l293,2l292,3l3,293l2,292h300h301l-3-142l-3-143l-145-5l-145-5l-3-147l-3-147l148-3l148-3l3-147l3-148l-298-2l-298-3l-5-145l-5-145
		l-145-5l-145-5l-3-142l-3-142l-145-3l-146-3l1-147l2-147l-148-3l-148-3l-3-297l-2-298h-150h-149l-3,147l-3,148l-297,3l-297,2v-141
		c0-78-4-146-8-150c-5-5-204-10-443-11l-435-3l-5-145l-5-145l-134-2c-74-1-141-5-148-7c-10-4-13-39-13-146v-140h-295h-295v144
		c0,106-3,145-12,149c-7,2-69,5-138,5s-131,1-137,1c-10,1-13,37-13,146v146l148-3l147-3l5-145l5-145l138,2c75,1,142,5,147,8
		c6,4,10,66,10,146v139h145c167,0,158-6,153,110l-3,65h-445h-445l-3-88l-3-88l-146,3l-147,3v85l-1,85l-339,3c-280,2-342,5-359,17
		c-12,8-29,15-39,15c-9,0-48,21-86,46c-51,35-81,65-116,116c-25,38-46,77-46,86c0,10-6,27-14,38s-16,38-18,60l-3,39h-380h-380v-145
		v-145l143-3l142-3l2-32c0-17,1-84,2-147l1-115l148-3l148-3l-3-147l-3-147h-140h-140l-3,147l-3,148h-149h-150v-295v-295h-150h-149
		l-3,146l-3,145h-145h-145l-3-146l-3-146l-147,3c-122,3-147,6-148,18c-1,8-2,74-3,146l-1,131l-145-1l-144-1l-3,145l-3,145l-148,3
		l-148,3l3,147l3,147l145,5l145,5l3,148l3,147h144c131,0,145-2,145-17c1-10,2-76,3-148l2-130h145h145l3,147l3,147l147,3l147,3v145
		v145h-145h-145l-3-147l-3-148h-149h-150l2,293l3,292l295,5l295,5l5,145l5,145l443,3l443,2l-3-147l-3-148l-142-3c-84-1-143-7-143-12
		c0-6-1-70-1-142v-133h233h233v290v290l-87,3l-87,3l-3,147l-3,147l-593,3l-593,2l3,148l3,147l295,5l295,5l3,443l2,442h145
		c80,0,146-3,147-7c0-5,2-135,3-291c1-168,6-287,12-293s66-8,144-7l134,3l3,298l2,297h155h155l66,45c36,25,72,45,80,45
		c9,0,25,5,37,12s40,14,62,17l40,6l3,88l3,87h149h150v-90v-91l143,3l142,3l3,88l3,87h299h299l3-87l3-88l141-3c164-3,154-10,154,111
		v67h145h146l-3,148l-3,147l-90,3c-49,2-114,0-142-3l-53-6v-145v-144h-150h-150v145c0,109-3,145-12,146c-7,1-67,2-133,3
		c-66,2-128,3-138,4c-16,2-17,14-15,145l3,142l145,5l145,5l3,148l3,148l147-3l147-3l5-145l5-145h140h140l5,145l5,145h295h295l3-147
		l3-147l-148-3l-148-3v-290v-290l148-3l147-3v295v295l148,3l147,3l3,147l3,148l292,2l292,3l5,145l5,145l295,5l295,5l3,139
		c2,105-1,141-10,148c-7,4-207,8-444,8c-336,0-433,3-437,13c-2,6-5,139-6,295l-2,282h150h150l3-147l3-148h145h145l5,145l5,145h145
		h145l5-145l5-145l134-3c90-2,138,1,147,9c12,10,14,129,14,733c0,396-4,726-8,733c-5,9-51,13-147,15l-140,3l-5,295l-5,295h-295h-295
		v-145v-145l145-5l145-5v-145v-145l-145-5l-145-5l-5-145l-5-145l-145-5l-145-5l-1-140l-1-140l-447-5l-446-5v147v148l-292,2l-293,3
		l-3,148l-3,147h145h145l3,148l3,147l147,3l147,3l3-148l3-148l441-3c349-2,443,0,446,10c2,7,2,75,0,151l-4,137h-444h-443l-3,148
		l-3,147h-440h-440l-5-295l-5-295l-147-3l-147-3l-3,148l-3,148l-147,3l-147,3l-3,147l-3,147l-136,3
		C5735,11909,5671,11907,5667,11904z M7438,10568l3-148h-151h-150v-150v-150h150h149l3-147l3-148l147-3l148-3l2,298l3,298l293,3
		l292,2v-150v-150h-145h-144l-3-147l-3-148l-147-3l-148-3v-144v-145h295c274,0,295-1,295-17c1-27,1-256,1-271c-1-10-63-12-293-10
		l-293,3l-3,147l-3,147l-285,1c-202,0-290-3-300-11c-11-9-14-44-14-150v-139h-145h-145v138c0,94-4,142-12,150s-56,12-150,12h-138
		v295v295h150h149l3,148l3,147l143,3l142,3v137c0,96,4,142,12,150c9,9,51,12,148,10l135-3L7438,10568z M8925,10570v-145h-145h-145
		l-3,134c-2,91,1,138,9,147c9,12,40,14,148,12l136-3V10570z M5948,10268l3-148h150h150l-3-147l-3-148l-134-3c-90-2-138,1-147,9
		c-11,9-14,44-14,150v139h-145h-145v-138c0-94-4-142-12-150s-56-12-150-12h-138v150v150h150h150v150v151l143-3l142-3L5948,10268z
		 M6548,9077l3-148l-148,3l-148,3l-3,134c-1,74,0,141,2,148c4,11,37,13,148,11l143-3L6548,9077z M1790,8780l5-145l143-3l142-3v-444
		v-445h-145h-145v138c0,94-4,142-12,150s-56,12-150,12h-138l2-297l3-298h440h440l5,145l5,145l147,3l147,3l3-148l3-148l443-3l443-2
		l-3-148l-3-147l-145-5l-145-5l-3-142l-3-143h-144h-145v-150v-150h295h296l-3-147l-3-148l-292-3l-293-2v-134c0-74-3-141-6-150
		c-5-14-28-16-150-16h-144v-145v-145h138c94,0,142-4,150-12s12-56,12-150v-138h-150h-150v150v150h-150h-150v145v145h-138
		c-94,0-142,4-150,12s-12,56-12,150v138h-145h-144l-3,148l-3,147l-148,3l-148,3l3,147l3,147h290h290l3-298l2-298l148,3l147,3l5,145
		l5,145l148,3l147,3v149v149l-147,3l-148,3l-3,143l-3,142h-594h-595l2,448l3,447l148,3l147,3v438c0,241,3,441,7,445c4,3,70,5,147,4
		l141-3L1790,8780z M5353,8331c4-1,7-66,7-146v-146l-147,3l-148,3l-3,146l-3,146l143-3C5281,8333,5348,8331,5353,8331z M5928,8334
		c22-6,22-9,22-150v-144h-145h-145v144v145l53,4C5808,8339,5905,8340,5928,8334z M4758,7888l3-148h-146h-145l-1,130
		c-1,72-2,136-3,143c-1,6-1,15,2,20c2,4,67,6,145,5l142-3L4758,7888z M11310,7890v-149l148-3l147-3l3-147l3-148h-151h-150v150v150
		h-150h-150v134c0,74,3,141,6,150c5,14,28,16,150,16h144V7890z M11010,6112c0-94,4-142,12-150s56-12,150-12h137l3,148l3,147h145h145
		l3-147l3-148h-151h-150v-145v-145h-138c-94,0-142-4-150-12s-12-56-12-150v-138h-445h-446l3-147l3-148l145-5l145-5l5-145l5-145h145
		h145l3,148l3,147l442-2l442-3v-145v-145l-145-5l-145-5l-3-148l-3-148l133,1c73,1,141,1,151,1c16-1,17-29,17-446v-445l-592,2l-593,3
		l-3,147l-3,148l-297,2l-297,3v145v145l298,3l297,2v145v145l-92,2c-80,2-149,5-195,7c-10,1-13,37-13,146v145h-295h-295v-140
		c0-123-2-140-17-148c-10-5-78-10-150-11l-133-2v150v151h150h151l-1,298l-1,297l295,3l295,2l3,148l3,147l295,5l295,5l3,293l2,292
		h145h145V6112z M5058,4913l-3-148l-147-3l-148-3v144c0,79,3,147,7,150c3,4,71,7,150,7h144L5058,4913z M5950,4615v-144l-32-6
		c-18-3-82-5-143-3l-110,3l-3,148l-3,147h146h145V4615z M8930,4615c0-172-15-158,171-153l124,3l3-147l3-148h143c122,0,145-2,150-16
		c8-20,8-548,0-568c-5-14-28-16-150-16h-143l-3,148l-3,147l-145,5l-145,5l-5,145l-5,145h-145h-145l-5-145l-5-145l-142-3l-141-3
		l-7,54c-8,65-3,523,6,532c4,3,69,7,145,8l139,2l3,135c1,74,4,141,7,148c3,9,43,12,150,12h145V4615z M3270,4020v-150h-145h-145v150
		v150h145h145V4020z M4457,4158c11-14,16-512,7-560l-6-28h-144h-144v-150v-150h144c110,0,145-3,149-13c3-7,5-67,5-133
		c0-65,1-125,1-131c1-9,40-13,144-15l142-3l3-147l3-148h-296h-295v295v294l-147,3l-148,3l-3,148l-3,147h-150h-150l3,148l3,147h145
		h145l3-147l3-148h149h150v300v300h139C4408,4170,4450,4166,4457,4158z M7440,4020l5-145h145h145l5,145l5,145h145h145l3-297l2-298
		l-297-2l-298-3l-3-142c-3-169,13-155-172-151l-125,3l-3,148l-3,147h-144h-145v150v150h145h145v150v151l148-3l147-3L7440,4020z
		 M11310,3125v-145h-138c-94,0-142-4-150-12c-9-9-12-89-12-300v-288h150h151l-3-147l-3-148h-145h-145l-3,147l-3,147l-142,3l-142,3
		l-3,435c-1,239,0,440,2,445c2,6,107,9,295,7l291-3V3125z M10418,2828l-3-443h-440h-440l-3,443l-2,442h445h445L10418,2828z
		 M8332,1940c0-74-1-138-1-142c-1-5-66-8-146-8h-145v145v146l147-3l146-3L8332,1940z M9528,1933l3-143h-151h-150v145v146l148-3
		l147-3L9528,1933z M10418,1783l2-292l-147-3l-148-3l-3-147l-3-148h-294h-295v290c0,160,0,293,0,298c0,4,65,8,145,10l145,3v138
		c0,76,3,141,7,144c4,4,137,6,297,5l291-3L10418,1783z M11010,1785v-295h-147h-148l3,295l3,295h144h145V1785z M4760,1640v-150h-295
		h-295v150v150h295h295V1640z M6548,1637l3-147l-298,2l-298,3l-3,134c-1,74,0,141,2,148c4,11,64,13,298,11l293-3L6548,1637z"
                  />
                  <path d="M6852,9973l3-148h140h140l3,148l3,147h-146h-146L6852,9973z" />
                  <path d="M2680,6995v-145h150h151l-3,143l-3,142l-147,3l-148,3V6995z" />
                  <path d="M2682,6403l3-148l148-3l147-3v151v150h-150h-151L2682,6403z" />
                  <path d="M10722,4613l3-148h140h140l3,148l3,147h-146h-146L10722,4613z" />
                  <path d="M11012,4320l3-145l148-3l147-3v145c0,135-1,145-20,151c-11,3-78,5-150,3l-130-3L11012,4320z" />
                  <path d="M10420,4020v-151l148,3l147,3l3,125c4,186,18,170-153,170h-145V4020z" />
                  <path d="M9824,2967c-2-7-3-74-2-148l3-134h145h145v145v145l-143,3C9861,2980,9828,2978,9824,2967z" />
                  <path
                    d="M9825,11898c-3-7-4-476-3-1043l3-1030h1040h1040l3,1034c2,821,0,1036-10,1043c-7,4-476,8-1041,8
		C10044,11910,9828,11907,9825,11898z M11605,10865v-740h-740h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V10865z"
                  />
                  <path d="M10420,10865v-445h445h445v445v445h-445h-445V10865z" />
                  <path d="M2687,9523c-4-3-7-71-7-150v-144l148,3l147,3l3,134c2,90-1,138-9,147c-9,11-43,14-144,14C2753,9530,2690,9527,2687,9523z" />
                  <path d="M10132,9522c-9-7-12-43-10-148l3-139l443-3l443-2l-3,147l-3,148l-430,2C10339,9529,10139,9526,10132,9522z" />
                  <path
                    d="M9536,9222c-3-5-6-71-7-148l-1-139l146-3l145-3l3-147l3-147h145h145v145v145l-147,3l-147,3l-3,147l-3,147l-137,3
		C9603,9229,9539,9227,9536,9222z"
                  />
                  <path d="M4469,8928c-6-18-4-268,3-281c8-16,23-17,146-15l137,3l3,148l3,147h-146C4535,8930,4470,8929,4469,8928z" />
                  <path
                    d="M9230,8780v-150h-150h-150v-295v-295l298,2l297,3l3,137c2,123,1,138-15,146c-10,6-72,10-138,10s-126,0-132,1
		c-10,1-13,37-13,146v145h144c122,0,145,2,150,16c3,9,6,76,6,150v134h-150h-150V8780z"
                  />
                  <path d="M8635,6838c-3-7-4-141-3-298l3-285h145h145v295v295l-143,3C8673,6850,8638,6848,8635,6838z" />
                  <path d="M7864,6237c-2-7-3-74-2-148l3-134l226-3c174-2,228,1,235,10c5,7,8,75,6,151l-4,137h-229C7920,6250,7868,6247,7864,6237z" />
                  <path d="M8634,5937c-2-7-3-71-2-143l3-129l448-3l448-2l-3,142l-3,143l-443,3C8724,5950,8638,5948,8634,5937z" />
                  <path d="M8047,5653c-4-3-7-138-7-300v-294l146,3l146,3v287c-1,159-4,292-7,298C8318,5661,8058,5664,8047,5653z" />
                  <path d="M3277,4753c-9-9-9-265,1-279c4-7,59-12,150-13l142-2v150v151h-143C3348,4760,3280,4757,3277,4753z" />
                  <path
                    d="M897,2973c-4-3-7-471-7-1039V900l23-5c12-3,481-4,1042-3l1020,3v1040v1040l-1036,3C1370,2979,900,2977,897,2973z
		 M2675,1935v-740h-740h-740l-3,730c-1,402,0,736,3,743c3,10,157,12,742,10l738-3V1935z"
                  />
                  <path d="M1490,1935v-445h445h445v445v445h-445h-445V1935z" />
                  <path d="M3274,2067c-2-7-3-71-2-143l3-129l148-3l147-3v146v145h-145C3315,2080,3278,2077,3274,2067z" />
                  <path d="M7147,1183c-4-3-7-66-7-138c0-101,3-135,14-144c9-8,57-11,147-9l134,3l3,148l3,147h-144C7218,1190,7150,1187,7147,1183z" />
                  <path
                    d="M11314,1177c-2-7-3-74-2-148l3-134l275-3c151-1,285,0,298,3c22,5,23,7,20,148l-3,142l-293,3
		C11378,1190,11318,1188,11314,1177z"
                  />
                  <g id="Layer_2_00000022547425814448459770000010252301187875038644_">
                    <g id="图层_1">
                      <path
                        style={st0}
                        d="M5574.2,7675.5h1654.1c247.6,0,448.4-200.8,448.4-448.4V5572.9c0-247.6-200.8-448.4-448.4-448.4H5574.2
				c-247.6,0-448.4,200.8-448.4,448.4v1654.1C5125.8,7474.7,5326.6,7675.5,5574.2,7675.5z"
                      />
                      <polygon
                        style={st1}
                        points="6469.2,7259 5897.8,7259 5776,7259 5542.1,7024.4 5546.7,5541 5897.8,5541 6469.2,5541 7029.5,5541 
				7260.6,5775.6 7260.6,7259 			"
                      />
                      <path
                        style={st0}
                        d="M5895.9,7114.3V5894.4h1219.9v1219.9H5895.9z M6429.7,6559.8h152.4v-152.4h-152.4V6559.8z M6244.6,6255.3
				h-152.2v152.5h152.4L6244.6,6255.3z M6244.6,6559.8h-152.2v345.9h152.4L6244.6,6559.8z M6767.1,6103.1h-522.5v152.5h522.5V6103.1
				z M6919.5,6255.3h-152.4v152.5h152.4V6255.3z M6919.5,6559.8h-152.4v345.9h152.4V6559.8z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className={styles.info}>
              <p>扫描或长按</p>
              <p>使用智能助理来获取灵感</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SharePop
