export const switchProject = (env) => {
  return env == process.env.REACT_APP_PROJECT
}

export const switchDocTitle = () => {
  let currentEnv = process.env.REACT_APP_PROJECT
  switch (currentEnv) {
    case 'DFZ':
      return '京网智云'
    case 'AIDB':
      return '悟智AI'
  }
}
