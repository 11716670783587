import { Block, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

interface HighlightProps {
  strike: boolean
}

const PLUGIN_NAME = 'highlight'

class RewriteHighlightSchema extends Block<HighlightProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'div'

  // 解析DOM，什么数据会被识别为删除线
  public parseDOM = []

  public toDOM = () => {
    return ['div', 0] as DOMOutputSpec
  }
}

class Tooltip {
  private dom: HTMLElement
  private visible = false

  constructor(mountEle: HTMLElement) {
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.dom.style.userSelect = 'none'
    this.dom.style.zIndex = '100'
    this.dom.style.backgroundColor = 'white'
    this.dom.style.border = '1px solid var(--color-neutral-3)'
    this.dom.style.borderRadius = '2px'
    this.dom.style.padding = '5px'
    this.dom.style.display = 'none'
    this.dom.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)'
    this.dom.id = 'highlight-tooltip'
    mountEle.appendChild(this.dom)
  }

  public show = (
    left: number,
    top: number,
    correct: string,
    reason: string,
  ) => {
    this.dom.style.left = `${left}px`
    this.dom.style.top = `${top}px`
    this.dom.style.display = 'none'
    this.dom.innerHTML = `
      <div style="margin-bottom: 5px; background-color:#FFEBE8;padding:8px 12px; border-radius:4px;color:#E33E4A;width:fit-content">${correct}</div>
      <div style="color:#999999;">${reason}</div>
    `
  }

  public hoverShow = () => {
    this.dom.style.display = 'block'
    this.visible = true
  }

  public hide = () => {
    this.dom.style.display = 'none'
    this.visible = false
  }

  get isVisible() {
    return this.visible
  }
}

class RewriteHighlightController extends SylController<HighlightProps> {
  public name = PLUGIN_NAME
  private tooltip: Tooltip
  // current start position of highlight text
  private curPos = -1
  private curHighlightText = ''
  private correctTxt = '我才是正确的'
  private singleRewrite = false

  constructor(editor: SylApi, props) {
    super(editor, props)
    this.tooltip = new Tooltip(this.editor.root)
    events.on(ACTIONS.ADD_REWRITE_EDITOR_TIP, this.highlightAndShowTip)
    events.on(ACTIONS.REMOVE_REWRITE_EDITOR_TIP, this.removeHighlightAndTip)
    events.on(ACTIONS.REPLACE_REWRITE_TO_EDITOR_TIP, this.replaceToEditorTip)
  }

  editorWillUnmount() {
    events.off(ACTIONS.ADD_REWRITE_EDITOR_TIP, this.highlightAndShowTip)
    events.off(ACTIONS.REMOVE_EDITOR_TIP, this.removeHighlightAndTip)
    events.off(ACTIONS.REPLACE_TO_EDITOR_TIP, this.replaceToEditorTip)
  }

  public replaceToEditorTip = () => {
    // Get the style of the old text
    const oldTextNode = this.editor.view.state.doc.nodeAt(this.curPos)
    const oldTextStyle = oldTextNode.marks

    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      this.curPos,
      this.curPos + this.curHighlightText.length,
      this.editor.view.state.schema.text(this.correctTxt, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    this.removeHighlightAndTip()
  }

  public removeHighlightAndTip = () => {
    this.editor?.removeShadow('shadow')
  }

  private findTextPositionInEditor = (
    searchText: string,
    nodeIndex: number,
    singleRewrite: boolean,
  ) => {
    let startPos = -1
    if (singleRewrite) {
      // Find the first instance of the search string in the document
      this.editor.view.state.doc.descendants((node, pos) => {
        const oldTextNode = this.editor.view.state.doc.nodeAt(pos)
        const oldTextStyle = oldTextNode.marks
        if (this.singleRewrite) {
          if (node.isText && oldTextStyle.length != 0) {
            const foundPos = node.text.indexOf(searchText)
            if (foundPos >= 0 && oldTextStyle.length != 0) {
              startPos = pos + foundPos

              return false // stop iterating
            }
          }
          return true // continue iterating
        } else {
          if (node.isText) {
            const foundPos = node.text.indexOf(searchText)
            if (foundPos >= 0) {
              startPos = pos + foundPos
              return false // stop iterating
            }
          }
          return true // continue iterating
        }
      })
    } else {
      this.editor.view.state.doc.forEach((node, offset, index) => {
        if (node.type.name == 'bullet_list') {
          this.editor.view.state.doc.descendants((node, pos) => {
            if (node.isText && node.text == searchText) {
              const foundPos = node.text.indexOf(searchText)
              if (foundPos >= 0) {
                startPos = pos + foundPos
                return false // stop iterating
              }
            }
            return true // continue iterating
          })
        } else {
          this.editor.view.state.doc.descendants((node, pos) => {
            if (node.isText && node.text == searchText) {
              startPos = offset + 1
              return false
            }
            // stop iterating
            return true // continue iterating
          })
        }
      })
    }
    // Find the first instance of the search string in the document

    return startPos
  }

  public highlightAndShowTip = (payload) => {
    this.editor?.removeShadow('shadow')
    const searchText = payload.error
    const correctText = payload.correct
    const singleRewrite = payload.singleRewrite
    const nodeInfo = payload.nodeInfo
    const color = payload.color
    const nodeIndex = payload.nodeIndex
    this.singleRewrite = payload.singleRewrite
    const startPos = this.findTextPositionInEditor(
      searchText,
      nodeIndex,
      singleRewrite,
    )
    if (startPos === -1) {
      this.tooltip.hide()
      this.curPos = -1
      this.curHighlightText = ''
      this.correctTxt = ''
      return
    } else {
      this.curPos = startPos
      this.curHighlightText = searchText
      this.correctTxt = correctText
    }

    this.editor?.appendShadow(
      {
        index: singleRewrite ? nodeInfo?.index : startPos,
        length: singleRewrite ? nodeInfo?.length : searchText.length,
        attrs: {
          style: `background-color: ${color};animation:${
            searchText && 'myAnimation 2s'
          } ;`,
          // style: `background-color: ${color}`,
          class: 'rewriteHighlight',
        },
        spec: {
          key: 'shadow',
        },
      },
      true,
    )

    const { top: editorTop } = this.editor.root.getBoundingClientRect()
    const { bottom: headBottom } = this.editor.view.coordsAtPos(startPos)

    // const computedLeft = headLeft - editorLeft + EDITOR_PADDING_LEFT;
    // const computedTop = headBottom + 8 - editorTop;
    // const highlightDiv = document.querySelector('.highlight');
    // highlightDiv.addEventListener('mouseenter', () => {
    //   this.tooltip.hoverShow();
    // });
    // highlightDiv.addEventListener('mouseleave', () => {
    //   this.tooltip.hide();
    // });

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)
    console.log(startPos, boxHeight)
    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 100,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    }
    // if (headBottom < editorBottom || headBottom > editorTop) {
    //   this.tooltip.show(
    //     computedLeft,
    //     computedTop,
    //     payload.correct,
    //     payload.reason
    //   );
    // }
    /* 之前的方法 */
    // if (headBottom > editorBottom || headBottom < editorTop) {
    //   this.editor.view.dom.scrollTo({ top: headBottom - editorTop });
    //   const newBottom = this.editor.view.coordsAtPos(startPos).bottom;
    //   this.tooltip.show(
    //     computedLeft,
    //     newBottom - editorTop + 8,
    //     payload.correct,
    //     payload.reason
    //   );
    // } else {
    //   this.tooltip.show(
    //     computedLeft,
    //     computedTop,
    //     payload.correct,
    //     payload.reason
    //   );
    // }
  }
}

class RewriteHighlightPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = RewriteHighlightController
  public Schema = RewriteHighlightSchema
}

export { RewriteHighlightPlugin }
