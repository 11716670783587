import React from 'react'
import { events } from '@/helpers/event-emitter'

import { ACTIONS } from '@/share/constants'
import {
  SylApi,
  Inline,
  SylController,
  SylPlugin,
  EventChannel,
} from '@syllepsis/adapter'
import { EditorState } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import { render } from 'react-dom'

interface FloatBtnAttrs {
  class: string
}

const PLUGIN_NAME = 'floatBtn'

class FloatBtnSchema extends Inline<FloatBtnAttrs> {
  public name = PLUGIN_NAME

  public tagName() {
    return 'floatBtn'
  }

  attrs?: { class: { default?: string } } = {
    class: { default: '--' },
  }

  parseDOM = [
    {
      tag: 'floatBtn',
      getAttrs: (dom: any) => {
        return {
          class: dom.getAttribute('class'),
        }
      },
    },
  ]
}

class FloatBtnController extends SylController {
  public name = PLUGIN_NAME
  public _editor
  public viewHandle
  private root = document.createElement('div')
  constructor(editor: SylApi, props) {
    super(editor, props)
    this.viewHandle = props.view
    // editor.on(EventChannel.LocalEvent.ON_FOCUS, () =>
    //   this.handleTextChange(editor),
    // )
    this.viewHandle(editor.view, editor).update(editor.view, editor)
  }

  private handleTextChange(editor) {
    this.viewHandle(editor.view, editor).update(editor.view, editor)
  }

  private render(): void {
    render(<div>3333</div>, this.root)
  }

  view(EditorView) {
    const editor = EditorView
    const state = this.editor.view.state
    const view = this.editor.view
    const tr = state.tr
    editor.dom.addEventListener('mouseover', () => {
      console.log('pppp')
    })
  }
}

class FloatBtnPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = FloatBtnController
  public Schema = FloatBtnSchema
  view() {
    console.log('ppp')
  }
}

export { FloatBtnPlugin }
