import { ISylEditorProps } from '@syllepsis/editor'
import { ToolbarInlineLoader } from '@syllepsis/editor'
import { CommentMenuPlugin } from './custom/plugins/commentMenu'

const getDefaultModule = (): ISylEditorProps['module'] => ({
  toolbarInline: {
    Ctor: ToolbarInlineLoader,
    option: {
      tools: [CommentMenuPlugin.getName()],
      tooltips: false,
    },
  },
})

export default getDefaultModule
