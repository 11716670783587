import React from 'react'
import styles from './style/prompt-input-weight.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'
import { Slider } from '@arco-design/web-react'
import PromptWeight from './PromptWeight'
import PromptInput from './PromptInput'
const TextArea = Input.TextArea

function PromptInputWeight(props) {
  //   const [value, setValue] = React.useState(30)
  const {
    onInputChange,
    onSliderChange,
    inputValue,
    sliderValue,
    title1,
    title2,
    tooltip1,
    tooltip2,
    showPlus1,
    showMinus1,
    showPlus2,
    showMinus2,
    placeholder,
    defaultValue,
    max,
    min,
    step,
  } = props

  //添加删除
  function plus() {
    if (props.plus) {
      props.plus()
    }
  }
  function minus() {
    if (props.minus) {
      props.minus()
    }
  }
  return (
    <div>
      <PromptInput
        plus={() => {
          plus()
        }}
        minus={() => {
          minus()
        }}
        title={title1}
        tooltip={tooltip1}
        showPlus={showPlus1}
        showMinus={showMinus1}
        placeholder={placeholder}
        value={inputValue}
        onInputChange={onInputChange}
      />
      <PromptWeight
        min={min} // 最小值
        max={max} // 最大值
        step={step} // 最小调整单元
        defaultValue={defaultValue} // 默认值
        title={title2}
        tooltip={tooltip2}
        showPlus={showPlus2}
        showMinus={showMinus2}
        value={sliderValue}
        onSliderChange={onSliderChange}
      />
    </div>
  )
}

export default PromptInputWeight
