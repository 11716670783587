import React, { useEffect, useRef, useState } from 'react'
import styles from './style/prompt-select.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'
import {
  IconArrowRight,
  IconClosure,
  IconWastePaper,
} from '@arco-iconbox/react-aidb-v2'
import SearchMod from '../../intelligentDocument/SearchMod'

function PromptSelect(props) {
  const { value, onInputChange, filed_value } = props
  function del() {
    if (props.del) {
      props.del()
    }
  }

  //   console.log(filed_value)
  // 使用正则表达式匹配并提取链接和文字部分
  const [extractedData, setExtractedData] = useState([])
  const [originalExtractedData, setOriginalExtractedData] = useState([])
  useEffect(() => {
    const regex = /([^@]+)@([^\s]+)/g
    const matches = [...filed_value.matchAll(regex)]

    const data = matches.map((match) => {
      const chinesePart = match[1].trim()
      const englishPart = chinesePart.match(/.*?(?=[\u4e00-\u9fa5])/)
      const imgurl = match[2].trim()
      const parts = chinesePart.split(englishPart)
      const chineseOnly = parts[0] + parts[1]
      // const chineseOnly = chinesePart.replace(/[^\u4e00-\u9fa5]/g, '')
      return { chineseOnly, englishPart, imgurl }
    })
    setOriginalExtractedData(data)
    setExtractedData(data)
  }, [filed_value])

  //选中的index
  const [current, setCurrent] = React.useState<any>('')

  //是否显示选择弹窗
  const showSelect = props.showSelect

  //选择
  function select(item) {
    if (props.select) {
      props.select(item)
    }
  }

  //打开弹窗
  function openPop() {
    props.openSelect()
  }
  function closePop() {
    props.closeSelect()
  }

  //添加删除
  function plus() {
    if (props.plus) {
      props.plus()
    }
  }
  function minus() {
    if (props.minus) {
      props.minus()
    }
  }

  // Handle search input change
  function handleSearch(e) {
    const searchValue = e

    if (searchValue === '') {
      setExtractedData(originalExtractedData)
    } else {
      const filteredData = originalExtractedData.filter((item) =>
        item.chineseOnly.includes(searchValue),
      )
      setExtractedData(filteredData)
    }
  }

  const popupRef = useRef(null)

  useEffect(() => {
    // 添加点击事件监听器到文档
    document.addEventListener('click', handleDocumentClick)

    // 在组件卸载时移除监听器以防止内存泄漏
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  // 处理文档点击事件
  const handleDocumentClick = (e) => {
    // 检查点击事件的目标是否在弹出窗口之外
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      closePop()
    }
  }
  return (
    <div>
      <PromptTitle
        plus={() => {
          plus()
        }}
        minus={() => {
          minus()
        }}
        title={props.title}
        tooltip={props.tooltip}
        showDel={props.showDel}
        showPlus={props.showPlus}
        del={() => {
          del()
        }}
        showMinus={props.showMinus}
      />
      <div
        className={styles.input}
        onClick={(e) => {
          e.stopPropagation()
          openPop()
        }}>
        <Input
          className={'g-input'}
          readOnly={true}
          value={value}
          placeholder={props.placeholder}></Input>
        {value?.length > 0 ? (
          <div
            className={styles.icon}
            onClick={(e) => {
              onInputChange('')
              closePop()
              select('')
              setCurrent('')
              e.stopPropagation()
            }}>
            <IconWastePaper />
          </div>
        ) : (
          <div className={styles.icon}>
            <IconArrowRight />
          </div>
        )}
      </div>

      {showSelect && (
        <div className={styles.select_wrap} ref={popupRef}>
          <div className={styles.title}>
            <h3>{props.title}</h3>
            <div
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation()
                closePop()
              }}>
              <IconClosure />
            </div>
          </div>
          <div className={styles.contain}>
            <div className={styles.search}>
              <SearchMod
                width={300}
                placeholder={'搜索'}
                onSearch={handleSearch}
                theme={'black'}
              />
            </div>
            <div className={styles.list}>
              <div className={styles.list_inner}>
                {extractedData.map((item, index) => {
                  return (
                    <div
                      className={`${current === index ? styles.active : ''} ${
                        styles.item
                      }`}
                      onClick={(e) => {
                        e.stopPropagation()
                        setCurrent(index)
                        onInputChange(item.englishPart)
                        closePop()
                        select(item)
                      }}
                      key={index}>
                      <div className={styles.pic}>
                        <img
                          src={
                            item.imgurl + '?x-oss-process=image/resize,w_200'
                          }
                          alt=""
                        />
                      </div>
                      <div className={styles.text}>{item.chineseOnly}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PromptSelect
