import React, { useEffect, useState } from 'react'
import styles from './style/creation-item.module.less'
import Face from '../../assets/imgs/chat-face2.svg'
import {
  IconClosure,
  IconDownload,
  IconCollect,
  IconCollectFill,
  IconBaseSync,
  IconErrorTip,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import ModalCustom from '../../components/ModalCustom'
import { Input, Message, Popconfirm, Tooltip } from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import ImageDetailPop from './ImageDetailPop'
import {
  aiImgAction,
  aiImgAgain,
  aiImgCancelCoolect,
  aiImgCoolect,
  aiImgGetCoolectList,
  aiImgSimpleChange,
} from '@/model/reference'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

const TextArea = Input.TextArea

function CreationItem(props) {
  const {
    bigImg,
    close,
    desc,
    type,
    id,
    baseUrl,
    date,
    OnSimpleChange,
    sendSuccess,
    OnhandleAgain,
    updateScroolTop,
    onUpdateCollect,
    collect,
    typePath,
    aspect,
    downloadImg,
    buttons,
  } = props

  const theme = useSelector((state: GlobalState) => state.theme)
  console.log(theme)

  //二次编辑弹窗
  const [visible, setVisible] = React.useState(false)
  //是否收藏
  const [isCollect, setIsCollect] = React.useState(collect)
  //详情弹窗
  const [visibleDet, setVisibleDet] = React.useState(false)
  //弹窗中输入框的值
  const [txtValue, setTxtValue] = useState(desc)
  useEffect(() => {
    setTxtValue(desc)
  }, [desc])

  useEffect(() => {
    setIsCollect(collect)
  }, [collect])

  //生成单张大图U
  function handleUIndex(item) {
    aiImgSimpleChange({
      content: id + ' ' + item.title,
      notifyHook: '',
      state: txtValue,
    })
      .then((res) => {
        console.log(res.data)

        if (res.data.success == true) {
          console.log(res.data.data)

          if (res.data.data?.description == '任务已存在') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '图片已存在',
            })
            console.log('qqqq')
          } else {
            updateScroolTop()
            OnSimpleChange(res.data.data.result, 2, txtValue)
            console.log('dd')
          }
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch((error) => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请求数据错误，请重新尝试',
        })
      })
  }

  //重新生成单张V
  function handleVIndex(item) {
    aiImgSimpleChange({
      content: id + ' ' + item.title,
      notifyHook: '',
      state: txtValue,
    })
      .then((res) => {
        console.log(res.data)
        if (res.data.success == true) {
          updateScroolTop()
          if (typePath == 'Synthesis') {
            OnSimpleChange(res.data.data.result, 1, baseUrl)
          } else {
            OnSimpleChange(res.data.data.result, 1, txtValue)
          }
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch((error) => {
        console.log(error.response)

        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: error.response,
        })
        setVisible(false)
      })
  }

  //重新生成按钮S Z X
  function handleNewImg(item) {
    //传2
    const U = [
      'U1',
      'U2',
      'U3',
      'U4',
      'upscale_1Upscale (2x)',
      '⏫Upscale (4x)',
    ]
    //传1
    const V = [
      'V1',
      'V2',
      'V3',
      'V4',
      '🔄',
      '🪄Vary (Strong)',
      '🪄Vary (Subtle)',
      '🔍Zoom Out 1.5x',
      '🔍Zoom Out 2x',
      '⬆️',
      '⬇️',
      '⬅️',
      '➡️',
      '↕️Make Square',
    ]
    const itemType = item.emoji + item.label
    let type
    if (U.includes(itemType)) {
      type = 2
    } else if (V.includes(itemType)) {
      type = 1
    }
    aiImgAction({
      customId: item.customId,
      taskId: id,
      state: txtValue,
    })
      .then((res) => {
        console.log(res.data)
        if (res.data.success == true) {
          updateScroolTop()
          if (typePath == 'Synthesis') {
            OnSimpleChange(res.data.data.result, type, baseUrl)
          } else {
            OnSimpleChange(res.data.data.result, type, txtValue)
          }
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch((error) => {
        console.log(error.response)
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: error.response,
        })
        setVisible(false)
      })
  }

  //重新生成
  async function fetchImage() {
    if (baseUrl == '' || baseUrl == null || baseUrl == undefined) {
      if (typePath == 'Synthesis') {
        OnhandleAgain('', aspect)
      } else {
        OnhandleAgain('', desc)
      }
    } else {
      try {
        if (typePath == 'Synthesis') {
          const promises = baseUrl.map(async (url) => {
            const response = await fetch(url)
            const blob = await response.blob()
            const reader = new FileReader()

            return new Promise((resolve, reject) => {
              reader.onload = () => {
                const base64Data = reader.result
                resolve(base64Data)
              }
              reader.onerror = (error) => {
                console.error('Error reading image:', error)
                reject(error)
              }
              reader.readAsDataURL(blob)
            })
          })

          const base64Images = await Promise.all(promises)
          OnhandleAgain(base64Images, aspect, baseUrl)
        } else {
          const response = await fetch(baseUrl)
          const blob = await response.blob()
          const reader = new FileReader()

          reader.onload = () => {
            const base64Data = reader.result
            console.log(base64Data)
            OnhandleAgain(base64Data, desc)
          }
          reader.readAsDataURL(blob)
        }
      } catch (error) {
        console.error('Error fetching or converting image:', error)
      }
    }
  }

  //收藏图片
  function handleCollect() {
    aiImgCoolect({
      state: txtValue,
      imageUrl: bigImg,
      imageId: id,
      type: typePath == 'Synthesis' ? 'Synthesis' : 'aiDrawing',
    })
      .then((res) => {
        if (res.data.success == true) {
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '收藏图片成功',
          })
          setIsCollect(true)
          onUpdateCollect(true, id)
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch((error) => {
        console.log(error.response)

        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: error.response,
        })
        setVisible(false)
      })
  }

  //取消收藏图片
  function handleCancelCollect() {
    aiImgCancelCoolect({
      imageId: [id],
    })
      .then((res) => {
        if (res.data.success == true) {
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏图片成功',
          })
          setIsCollect(false)
          onUpdateCollect(false, id)
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch((error) => {
        console.log(error.response)

        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: error.response,
        })
        setVisible(false)
      })
  }

  //下载图片
  function handleDownLoad() {
    fetch(downloadImg)
      .then((response) => response.blob())
      .then((blob) => {
        // 创建一个Blob URL
        const blobUrl = URL.createObjectURL(blob)

        // 创建一个<a>元素用于下载
        const a = document.createElement('a')
        a.href = blobUrl

        const date = new Date()
        const ymdms = `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`
        a.download = `wuz-ai-draw-${ymdms}` // 设置要下载的文件名

        // 模拟点击<a>元素以触发下载
        a.click()

        // 释放Blob URL资源
        URL.revokeObjectURL(blobUrl)
      })
  }

  //tooltip命名
  function getTooltipContent(item) {
    console.log(item.emoji + item.label)

    switch (item.emoji + item.label) {
      case 'U1':
        return '放大第一张'
      case 'U2':
        return '放大第二张'
      case 'U3':
        return '放大第三张'
      case 'U4':
        return '放大第四张'
      case 'V1':
        return '基于第一张重新生成'
      case 'V2':
        return '基于第二张重新生成'
      case 'V3':
        return '基于第三张重新生成'
      case 'V4':
        return '基于第四张重新生成'
      case '🔄':
        return '重新生成'
      case '🪄Vary (Subtle)':
        return '轻度重绘'
      case '🪄Vary (Strong)':
        return '重度重绘'
      case 'upscale_1Upscale (2x)':
        return '放大两倍'
      case '⏫Upscale (4x)':
        return '放大四倍'
      case '🔍Zoom Out 1.5x':
        return '小幅延伸背景'
      case '🔍Zoom Out 2x':
        return '大幅延伸背景'
      case '⬆️':
        return '向上延伸重新生成'
      case '⬇️':
        return '向下延伸重新生成'
      case '⬅️':
        return '向左延伸重新生成'
      case '➡️':
        return '向右延伸重新生成'
      case 'upscale_1Upscale (Subtle)':
        return '轻度放大'
      case 'upscale_1Upscale (Creative)':
        return '重度放大'
      // 添加更多的 case 语句来处理其他的情况
      default:
        return ''
    }
  }
  console.log(bigImg, 'bigImg')

  return (
    <div>
      <div className={`${styles['item']} ${styles[theme]}`}>
        <div className={styles.title}>
          <div className={styles.left}>
            <div className={styles.face}>
              <Face />
            </div>
            <div className={styles.name}>智能机器人</div>
            <div className={styles.date}>{date}</div>
          </div>
          {bigImg?.slice(0, 24) == 'https://oss.aidb.com.cn/' ? (
            <Popconfirm
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              position="bottom"
              focusLock
              title="确定要删除吗？"
              onOk={() => {
                close()
              }}>
              <div className={styles.close}>
                <IconClosure />
              </div>
            </Popconfirm>
          ) : (
            <Tooltip position="top" trigger="hover" content={'努力绘图中...'}>
              <div className={styles.imgLoading}>
                <IconBaseSync />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.content}>
          {desc?.length > 0 && (
            <div className={styles.desc}>AI 想象提示：{desc}</div>
          )}
          {/* 单张u */}
          <div
            className={styles.big_img}
            onClick={() => {
              setVisibleDet(true)
            }}>
            <div
              className={styles.btns}
              onClick={(e) => {
                handleDownLoad()
                e.stopPropagation()
              }}>
              <div className={`${styles.download} ${styles.icon}`}>
                <IconDownload />
              </div>
              <div className={styles.icon}>
                {isCollect ? (
                  <IconCollectFill
                    onClick={(e) => {
                      handleCancelCollect()
                      e.stopPropagation()
                    }}
                  />
                ) : (
                  <IconCollect
                    onClick={(e) => {
                      handleCollect()
                      e.stopPropagation()
                    }}
                  />
                )}
              </div>
            </div>
            <img src={bigImg} alt="" />
          </div>
          {/* 按钮u */}
          {/* 按钮u */}
          {/* 按钮u */}
          <div className={styles.btn_group}>
            <div className={styles.btn_row}>
              {buttons &&
                buttons
                  .filter(
                    (item) =>
                      !(
                        item.emoji === '❤️' ||
                        (item.emoji === '🖌️' &&
                          item.label === 'Vary (Region)') ||
                        (item.emoji === '🔍' && item.label === 'Custom Zoom')
                      ),
                  )
                  .map((item, index) => {
                    // 如果 emoji 是 'upscale_1'，则替换为 '⏫'
                    const emoji = item.emoji === 'upscale_1' ? '⏫' : item.emoji

                    return (
                      <Tooltip key={index} content={getTooltipContent(item)}>
                        <div
                          className={` ${
                            !sendSuccess ? styles.UVdisable : ''
                          } ${styles.btn}`}
                          onClick={() => handleNewImg(item)}>
                          {emoji} {item.label}
                        </div>
                      </Tooltip>
                    )
                  })}
            </div>
          </div>{' '}
        </div>

        <ModalCustom
          black={true}
          width={'1280px'}
          title={null}
          visible={visibleDet}
          close={() => setVisibleDet(false)}
          content={
            <ImageDetailPop
              type={2}
              desc={desc}
              bigImg={bigImg}
              collect={collect}
              OnhandleCollect={handleCollect}
              OnhandleCancelCollect={handleCancelCollect}
              onhandleDownLoad={handleDownLoad}
            />
          }
        />
      </div>
    </div>
  )
}

export default CreationItem
