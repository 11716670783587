import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { SylApi, Inline, SylController, SylPlugin } from '@syllepsis/adapter'

interface CommentAttrs {
  class: string
}

const PLUGIN_NAME = 'comment'

class CommentSchema extends Inline<CommentAttrs> {
  public name = PLUGIN_NAME

  public tagName() {
    return 'comment'
  }

  attrs?: { class: { default?: string } } = {
    class: { default: '--' },
  }

  parseDOM = [
    {
      tag: 'comment',
      getAttrs: (dom: any) => {
        return {
          class: dom.getAttribute('class'),
        }
      },
    },
  ]
}

class CommentController extends SylController {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.REMOVE_COMMENT_EDITBOX, this.removeComment)
    events.on(ACTIONS.REMOVE_ALL_COMMENT_EDITBOX, this.removeAllComment)
    events.on(ACTIONS.REMOVE_COMMENT_HL_EDITOR, this.removeCommentHl)
  }

  editorWillUnmount(): void {
    events.off(ACTIONS.REMOVE_COMMENT_EDITBOX, this.removeComment)
    events.off(ACTIONS.REMOVE_ALL_COMMENT_EDITBOX, this.removeAllComment)
    events.off(ACTIONS.REMOVE_COMMENT_HL_EDITOR, this.removeCommentHl)
  }

  private removeCommentHl = (payload) => {
    document.querySelectorAll('.' + 'hl').forEach((item) => {
      item.classList.remove('hl')
    })
    const state = this.editor.view.state
    const tr = state.tr
    const root = state.doc
    const markType = this.editor.view.state.schema.marks.comment // Replace 'myMark' with the name of your mark type

    root.descendants((node, pos) => {
      if (node.marks.length > 0) {
        // Check if the mark applies to this text node
        const marksToRemove = node.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes(payload.commentId) !== -1
          )
        })

        if (marksToRemove) {
          const oldClass = (marksToRemove.attrs.class || '') as string
          const newClass = oldClass.replace(new RegExp(`(hl)\\b`, 'g'), '')
          // The mark is applied to this text node, so remove it
          const startPos = tr.mapping.map(pos)
          const endPos = tr.mapping.map(pos + node.nodeSize)
          if (newClass) {
            tr.addMark(startPos, endPos, markType.create({ class: newClass }))
          }
          return false
        }
      }
      return true
    })

    this.editor.view.dispatch(tr)
  }

  private removeAllComment = () => {
    const state = this.editor.view.state
    const tr = state.tr
    const root = state.doc
    const markType = this.editor.view.state.schema.marks.comment // Replace 'myMark' with the name of your mark type
    root.descendants((node, pos) => {
      node.marks.forEach((mark) => {
        if (mark.type === markType) {
          tr.removeMark(pos, pos + node.nodeSize, markType)
        }
      })
    })

    this.editor.view.dispatch(tr)
  }

  private removeComment = ({
    id,
    className,
  }: {
    id: string
    className: string
  }) => {
    const state = this.editor.view.state
    const tr = state.tr
    const root = state.doc
    const markType = this.editor.view.state.schema.marks.comment // Replace 'myMark' with the name of your mark type

    root.descendants((node, pos) => {
      if (node.marks.length > 0) {
        // Check if the mark applies to this text node
        const marksToRemove = node.marks.find((mark) => {
          return (
            mark.type === markType &&
            mark.attrs.class.includes(id + ' ' + 'hl') !== -1
          )
        })

        if (marksToRemove) {
          const oldClass = (marksToRemove.attrs.class || '') as string
          const newClass = oldClass.replace(
            new RegExp(`(${className + ' ' + 'hl'}|${className}|hl)\\b`, 'g'),
            '',
          )
          // The mark is applied to this text node, so remove it
          const startPos = tr.mapping.map(pos)
          const endPos = tr.mapping.map(pos + node.nodeSize)
          tr.removeMark(startPos, endPos, marksToRemove)
          if (newClass.trim().length !== 0) {
            tr.addMark(startPos, endPos, markType.create({ class: newClass }))
          }
          return false
        }
      }
      return true
    })

    this.editor.view.dispatch(tr)
  }

  public keymap = {
    Backspace: (_editor: SylApi, state: SylApi['view']['state']) => {
      const { $from, $to } = state.selection
      const fromMarks =
        $from.pos !== $to.pos ? $from.marksAcross($to) : $from.marks()
      const prevCommentMark = state.doc
        // .resolve($from.pos - 1)
        .resolve($from.pos)
        .marks()
        .find((m) => m.type.name === 'comment')
      const curCommentMark = fromMarks?.find((m) => m.type.name === 'comment')
      const prevCommentMarksClassNames = prevCommentMark
        ? (prevCommentMark.attrs.class as string).split(' ')
        : []
      const curCommentMarksClassNames = curCommentMark
        ? (curCommentMark.attrs.class as string).split(' ')
        : []

      if (
        curCommentMarksClassNames.length > 0 &&
        curCommentMarksClassNames.length !== prevCommentMarksClassNames.length
      ) {
        const toReomvedClass = curCommentMark.attrs.class
        // do nothing if there is still the same comment mark in the prev positions
        if (prevCommentMarksClassNames.includes(toReomvedClass)) {
          return
        }
        events.emit(ACTIONS.REMOVE_COMMENT_EDITOR, {
          id: curCommentMark.attrs.class,
        })
      }
      return false
    },
  }
}

class CommentPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = CommentController
  public Schema = CommentSchema
}

export { CommentPlugin }
