import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec, Node } from 'prosemirror-model'

interface IFontSizeProps {
  size: string
  name: string
}

const PLUGIN_NAME = 'font_size'

class FontSizeSchema extends Inline<IFontSizeProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'span'

  public attrs = {
    size: {
      default: '16px',
    },
    name: {
      default: '小四',
    },
  }

  public parseDOM = [
    {
      style: 'font-size',
      getAttrs: (size: string) => ({ size }),
    },
  ]

  public toDOM = (node: Node) => {
    const size = node.attrs.size
    return ['span', { style: `font-size: ${size}` }, 0] as DOMOutputSpec
  }
}

class FontSizeController extends SylController<IFontSizeProps> {
  public name = PLUGIN_NAME

  public allowedValues = [
    { name: '初号', size: '56px' },
    { name: '小初', size: '48px' },
    { name: '一号', size: '34.7px' },
    { name: '小一', size: '32px' },
    { name: '二号', size: '29.3px' },
    { name: '小二', size: '24px' },
    { name: '三号', size: '21.3px' },
    { name: '小三', size: '20px' },
    { name: '四号', size: '18.7px' },
    { name: '小四', size: '16px' },
    { name: '五号', size: '14px' },
    { name: '小五', size: '12px' },
    { name: '六号', size: '10px' },
    { name: '小六', size: '8.7px' },
    { name: '七号', size: '7.3px' },
    { name: '八号', size: '6.7px' },
    { name: '5', size: '5pt' },
    { name: '5.5', size: '5.5pt' },
    { name: '6.5', size: '6.5pt' },
    { name: '7.5', size: '7.5pt' },
    { name: '8', size: '8pt' },
    { name: '9', size: '9pt' },
    { name: '10', size: '10pt' },
    { name: '10.5', size: '10.5pt' },
    { name: '11', size: '11pt' },
    { name: '12', size: '12pt' },
    { name: '14', size: '14pt' },
    { name: '16', size: '16pt' },
    { name: '18', size: '18pt' },
    { name: '20', size: '20pt' },
    { name: '22', size: '22pt' },
    { name: '26', size: '26pt' },
    { name: '28', size: '28pt' },
    { name: '36', size: '36pt' },
    { name: '48', size: '48pt' },
    { name: '56', size: '56pt' },
    { name: '72', size: '72pt' },
  ]

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    tooltip: PLUGIN_NAME,
    type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi, attrs: { size: string; name: string }) => {
      if (attrs) {
        return <>{attrs.name}</>
      } else {
        // 检测未声明的字体大小
        const { index } = _editor.getSelection()
        let node = _editor.view.domAtPos(index).node
        if (node.nodeType === 3 && node.parentElement) {
          node = node.parentElement
        }
        let fontSize = getComputedStyle(node as HTMLElement).fontSize
        return (
          this.allowedValues.find((item) => item.size === fontSize)?.name ||
          '默认'
        )
      }
    },
    value: this.allowedValues.map((item, index) => ({
      text: item.name,
      attrs: {
        ...item,
        key: index,
      },
    })),
  }
}

class FontSizePlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = FontSizeController
  public Schema = FontSizeSchema
}

export { FontSizePlugin }
