import React, { useEffect, useRef, useState } from 'react'
import styles from './style/search-mod.module.less'
import { Input } from '@arco-design/web-react'
const InputSearch = Input.Search

interface Props {
  title?: string
  onSearch?: (inputText: string) => void
  searchInp?: any
  setSearch?: any
  fixedWidth?: number | boolean
  placeholder?: string
  width?: any
  theme?: any
}
function SearchMod(props: Props) {
  const { width } = props
  const { searchInp } = props
  const { onSearch } = props
  const { theme } = props
  const [fixedWidth] = useState(props.fixedWidth)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [searchKey, setSearchKey] = useState(searchInp)
  const [searchIsFocus, setSearchFoucs] = useState(searchInp ? true : false)

  useEffect(() => {
    if (!searchInp) {
      setSearchKey('')
      setSearchFoucs(false)
    }
  }, [searchInp])

  const iptValue = useRef<string>('')
  function searchFocus() {
    setSearchFoucs(true)
  }
  function searchInput(e) {
    setSearchKey(e.target.value)
    if (searchKey) {
      setSearchFoucs(true)
    }
  }
  function searchBlur() {
    if (!searchKey) {
      setSearchFoucs(false)
    }
  }
  function clearSearch() {
    setSearchKey('')
    setSearchFoucs(false)
  }

  function handleInputChange(e) {
    onSearch(e)
    if (timer.current) {
      clearTimeout(timer.current)
    }
  }
  // console.log(searchKey);

  return (
    <div className={`${styles['searchWrap']} ${styles[theme]}`}>
      <InputSearch
        style={{ width: width }}
        autoFocus={false}
        defaultValue={searchKey}
        onFocus={searchFocus}
        onInput={searchInput}
        onBlur={searchBlur}
        onClear={() => clearSearch()}
        onChange={handleInputChange}
        className={[
          styles['search'],
          searchIsFocus ? styles['focus'] : '',
          fixedWidth ? styles['fixedWidth'] : '',
        ]}
        allowClear
        placeholder={props.placeholder}
      />
    </div>
  )
}

export default SearchMod
