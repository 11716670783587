import React, { useEffect, useRef, useState } from 'react'
import request from '@/utils/request'
import PromptMod from '@/pages/toolbox/PromptMod'
import styles from './style/tipPrompt.module.less'

import { Checkbox, Input, Tooltip } from '@arco-design/web-react'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
const InputSearch = Input.Search
interface TipPromptProps {
  title?: string
  tagList: any
  firstTitle: string
  secondTitle: string
  tagSelectList: any
  onSelectTag?: (
    firstSelectIndex,
    secondSelectIndex,
    title,
    prompt,
    checked,
  ) => void
}

const TipPrompt = React.memo((props: TipPromptProps) => {
  // const theme = useSelector((state: GlobalState) => state.theme)
  const {
    title,
    tagList,
    firstTitle,
    secondTitle,
    tagSelectList,
    onSelectTag,
  } = props
  const [list, setList] = useState<any>(tagList)
  const [value, setValue] = useState<string>('')
  const [firstSelectIndex, setFirstSelectIndex] = useState<any>(0)
  const [secondSelectIndex, setSecondSelectIndex] = useState<any>(0)
  const tagListRef = useRef([])

  useEffect(() => {
    let tag
    if (tagSelectList.length > 0) {
      tag = tagList.map((item, index) => {
        if (tagSelectList.some((_) => _.firstSelectIndex == item.id)) {
          return {
            ...item,
            children: item.children.map(
              (child) => {
                if (
                  tagSelectList.some(
                    (_) =>
                      _.secondSelectIndex == child.id && _.name == child.title,
                  )
                ) {
                  return {
                    ...child,
                    checked: true,
                  }
                }
                return {
                  ...child,
                  checked: false,
                }
              },
              // child.content.includes(value) ||
              // child.info.includes(value),
            ),
          }
        }
        return {
          ...item,
        }
      })
    } else {
      tag = tagList.map((item, index) => {
        return {
          ...item,
          children: item.children.map((child) => {
            return {
              ...child,
              checked: false,
            }
          }),
        }
      })
    }
    setList(tag)
  }, [tagSelectList, value])

  // 指令库搜索
  useEffect(() => {
    if (!value) {
      let tag = tagList.map((item, index) => {
        if (tagSelectList.some((_) => _.firstSelectIndex == item.id)) {
          return {
            ...item,
            children: item.children.map((child) => {
              if (
                tagSelectList.some(
                  (_) =>
                    _.secondSelectIndex == child.id && _.name == child.title,
                )
              ) {
                return {
                  ...child,
                  checked: true,
                }
              }
              return {
                ...child,
                checked: false,
              }
            }),
          }
        }
        return {
          ...item,
        }
      })
      setList(tag)
      return
    }

    let flag1 = true
    let flag2 = true

    setFirstSelectIndex(0)
    setSecondSelectIndex(0)
    let firstFilteredList = list
      .filter((item, index) => {
        if (item.title.includes(value)) {
          if (flag1) {
            flag1 = false
          }
          return true
        }
        return false
      })
      .map((item) => ({
        ...item,
        title: item.title.replace(
          value,
          `<strong style="text-decoration: underline">${value}</strong>`,
        ),
      }))

    let secondFilteredList = firstFilteredList.length
      ? firstFilteredList
      : list
          .map((item, index) => ({
            ...item,
            children: item.children
              .filter(
                (child) => child.title.includes(value),
                // child.content.includes(value) ||
                // child.info.includes(value),
              )
              .map((child) => {
                if (
                  tagSelectList.some((_) => _.secondSelectIndex == child.id)
                ) {
                  return {
                    ...child,
                    checked: true,
                  }
                }
                return {
                  ...child,
                  checked: false,
                }
              }),
          }))
          .filter((item, index) => {
            if (item.children.length > 0) {
              // setTimeout(() => {
              if (flag2) {
                flag2 = false
              }
              // }, 10)
              return true
            }
            return false
          })

    setList(secondFilteredList.length > 0 ? secondFilteredList : list)
  }, [value])

  const splitStrTooltip = (str) => {
    if (str.split('<strong style="text-decoration: underline">')[1]) {
      let delStr1 =
        str.split('<strong style="text-decoration: underline">')[0] +
        str.split('<strong style="text-decoration: underline">')[1]
      let delStr2 =
        delStr1.split('</strong>')[0] + delStr1.split('</strong>')[1]

      return delStr2
    } else {
      return str
    }
  }

  const selectTagHandle = (id, title, prompt, checked) => {
    onSelectTag(firstSelectIndex, id, title, prompt, checked)
  }
  return (
    <div className={styles[localStorage.getItem('theme')]}>
      <div className={styles['promptMod']}>
        <div className={styles['promptHeadMod']}>
          <p>{title}</p>
          <InputSearch
            className={styles['promptLibraryInput']}
            allowClear
            placeholder="搜索"
            onChange={(value) => {
              setValue(value)
            }}
            // onPressEnter={(e) => {
            //   try {
            //     props.promptSend(
            //       index_3,
            //       index_4,
            //       1,
            //       props.list[index_3].children[index_4].title,
            //     )
            //   } catch (error) {}
            // }}
            style={{ width: 200 }}
          />
        </div>

        <div className={styles['colContent']}>
          <div className={`${styles['col']} ${styles['col1']}`}>
            {list.length > 0 && (
              <div className={styles['title']}>{firstTitle}：</div>
            )}
            <div className={styles['list1']}>
              {list.length ? (
                list.map((item, index) => {
                  return (
                    <div
                      className={`${styles['item']} ${
                        firstSelectIndex === index ? styles['active'] : ''
                      }`}
                      key={index}
                      onClick={() => {
                        setFirstSelectIndex(index)
                        setSecondSelectIndex(0)
                      }}>
                      {/* <div className={styles['poi']}></div> */}
                      {item.title && (
                        <Tooltip content={splitStrTooltip(item.title)}>
                          <div
                            className={styles['name']}
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}></div>
                        </Tooltip>
                      )}
                    </div>
                  )
                })
              ) : (
                <div className={styles['name']}>暂无数据</div>
              )}
            </div>
          </div>
          <div className={`${styles['col']} ${styles['col2']}`}>
            {tagListRef.current.length > 0 && (
              <div className={styles['title']}>{secondTitle}：</div>
            )}

            <div className={styles['list1']}>
              {list && list[firstSelectIndex]?.children?.length > 0 ? (
                list[firstSelectIndex]?.children.map((item, index) => {
                  return (
                    <div
                      className={`${styles['item']} `}
                      key={index}
                      onClick={() => {
                        setSecondSelectIndex(index)
                      }}>
                      {/* <div className={styles['poi']}></div> */}
                      {item.title ? (
                        <Checkbox
                          checked={item.checked}
                          className={styles['titleContent']}
                          onChange={(checked) =>
                            selectTagHandle(
                              item.id,
                              item.title,
                              item.content,
                              checked,
                            )
                          }>
                          {/* <div className={styles['titleContent']}> */}
                          <Tooltip content={splitStrTooltip(item.title)}>
                            <div
                              className={styles['name']}
                              dangerouslySetInnerHTML={{
                                __html: item.title,
                              }}></div>
                          </Tooltip>
                          {/* </div> */}
                        </Checkbox>
                      ) : (
                        <div className={styles['name']}>暂无数据</div>
                      )}
                    </div>
                  )
                })
              ) : (
                <div className={styles['name']}>暂无数据</div>
                // <div className={`${styles['item']}`}>
                //   <div className={styles['poi']}></div>
                //   <div className={styles['name']}>暂无数据</div>
                // </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className={`${styles['col']} ${styles['col3']}`}> */}
        {/* {secondSelectIndex !== '' && (
            <>
              <div className={styles['title']}>提示内容</div>
              <div
                className={styles['text']}
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html:
                    (list.length &&
                      list[firstSelectIndex].children[secondSelectIndex] &&
                      list[firstSelectIndex].children[secondSelectIndex].content) ||
                    (list[index_3] &&
                      list[index_3].children[index_4] &&
                      list[index_3].children[index_4].content)
                      ? list[firstSelectIndex].children[secondSelectIndex].content ||
                        list[index_3].children[index_4].content
                      : '暂无数据',
                }}></div>
            </>
          )} */}
        {/* {secondSelectIndex !== '' && (
            <>
              <div className={styles['infoTitle']}>
                <div>参考内容</div>
                <div className={styles['copy']}></div>
                {props.copyOrUse ? (
                  <CopyToClipboard
                    text={
                      list[firstSelectIndex] &&
                      list[firstSelectIndex].children[secondSelectIndex] &&
                      list[firstSelectIndex].children[secondSelectIndex].info
                        ? list[firstSelectIndex].children[secondSelectIndex].info
                        : '暂无数据'
                    }
                    onCopy={() => {
                      try {
                        props.promptSend(
                          index_3,
                          index_4,
                          2,
                          props.list[index_3].children[index_4].title,
                        )
                      } catch (error) {}
                    }}>
                    <IconBtn text={'复制'} iconLeft={<IconCopy />}></IconBtn>
                  </CopyToClipboard>
                ) : (
                  <IconBtn
                    text={'使用'}
                    iconLeft={<IconCopy />}
                    onClick={() => {
                      try {
                        props.promptSend(
                          index_3,
                          index_4,
                          3,
                          props.list[firstSelectIndex].children[secondSelectIndex].info,
                        )
                      } catch (error) {}
                    }}></IconBtn>
                )}
              </div>
              <div
                className={styles['info']}
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html:
                    list.length &&
                    list[firstSelectIndex].children[secondSelectIndex] &&
                    list[firstSelectIndex].children[secondSelectIndex].info
                      ? list[firstSelectIndex].children[secondSelectIndex].info
                      : '暂无数据',
                }}></div>
            </>
          )}
          <div className={styles['btnWrap']}>
            <div
              className={styles['btn']}
              // onClick={() => {
              //   try {
              //     props.promptSend(
              //       index_3,
              //       index_4,
              //       1,
              //       props.list[index_3].children[index_4].title,
              //     )
              //   } catch (error) {}
              // }}
            >
              <span>使用该条目</span>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  )
})

export default TipPrompt
