import React, { useEffect, useState } from 'react'
import styles from './style/tem-right-mod.module.less'
import { Input } from '@arco-design/web-react'
import TemItem from './TemItem'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

function TemRightMod(props) {
  const { curStep, gptText, theme } = props

  // 过滤掉空值或无效值
  const filteredGptText = gptText.filter(
    (item) => item !== '' && item !== null && item !== undefined,
  )
  const [showBotButtons, setShowBotButtons] = useState(false)

  useEffect(() => {
    events.on(ACTIONS.TEM_SHOW_BUTTON, showbot)
    return () => {
      events.off(ACTIONS.TEM_SHOW_BUTTON, showbot)
    }
  })
  function showbot(payload) {
    console.log(payload)

    setShowBotButtons(payload)
  }
  function clickSubmit() {
    props.jumpTo(props.curStep + 1)
  }
  console.log(filteredGptText)

  return (
    <div className={`${styles.writeRight} ${styles[theme]}`}>
      <div className={`${styles.pb0} ${styles.content_wrap}`}>
        <div className={styles.content}>
          <div className={styles.wp}>
            <div className={styles.content_title}>选择和编辑您生成的内容</div>
            <div className={styles.xhs_list}>
              {filteredGptText.map((item, index) => {
                return (
                  <TemItem
                    theme={theme}
                    key={index}
                    content={item}
                    showBotButtons={showBotButtons}
                    messageId={props.messageId}
                  />
                ) // 传递有效的内容给子组件
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemRightMod
