import {
  BackgroundPlugin,
  ColorPlugin,
  EmojiPlugin,
  ImagePlugin,
} from '@syllepsis/access-react'
import { LinkPlugin } from '../lib/custom/plugins/link/index'
import { SylApi } from '@syllepsis/adapter'
import {
  AlignCenterPlugin,
  AlignJustifyPlugin,
  AlignLeftPlugin,
  AlignRightPlugin,
  AudioPlugin,
  VideoPlugin,
  BlockQuotePlugin,
  BoldPlugin,
  BulletListPlugin,
  // FontSizePlugin,
  FormatClearPlugin,
  FormatPainterPlugin,
  // HeaderPlugin,
  HrPlugin,
  ItalicPlugin,
  LetterSpacePlugin,
  LineHeightPlugin,
  LineIndentPlugin,
  ListItemPlugin,
  OrderedListPlugin,
  ParagraphPlugin,
  RedoPlugin,
  SpaceAfterPlugin,
  SpaceBeforePlugin,
  SpaceBothPlugin,
  StrikePlugin,
  SubPlugin,
  SupPlugin,
  UnderlinePlugin,
  UndoPlugin,
} from '@syllepsis/plugin-basic'

import { TablePlugin } from '@syllepsis/plugin-table'
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block'

import { FontFamilyPlugin } from './custom/plugins/font-family'
import { FontSizePlugin } from './custom/plugins/font-size'
import { RewritePlugin } from './custom/plugins/rewrite'
import { ContinuePlugin } from './custom/plugins/continuation'
import { TranslatePlugin } from './custom/plugins/translate'
import { HighlightPlugin } from './custom/plugins/highlightMark'
import { RewriteHighlightPlugin } from './custom/plugins/rewriteHighlightMark'
import request from '@/utils/request'
import { CommentMenuPlugin } from './custom/plugins/commentMenu'
import { CommentPlugin } from './custom/plugins/comment'
import { ToolsPlugin } from './custom/plugins/tools'
import { ChatGPTPlugin } from './custom/plugins/chatGPT'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { MapKeyPlugin } from './custom/plugins/mapKey'
import { CharacterCountPlugin } from './custom/plugins/wordCound'
import { EditorNavPlugin } from './custom/plugins/editorNav'
import { ReducePlugin } from './custom/plugins/reduce'
import { InnerToolPlugin } from './custom/plugins/innerTool'
import { DirectivesPlugin } from './custom/plugins/directives'
import { AiDirectivesPlugin } from './custom/plugins/aiDirectives'
import { AiToolsPlugin } from './custom/plugins/aiTools'
import { ToolsInlinePlugin } from './custom/plugins/toolsInline'
import { TaskListPlugin } from './custom/plugins/taskList'
import { FloatBtnPlugin } from './custom/plugins/floatBtn'
import { CommentBubblePlugin } from './custom/plugins/commentBubble'
import { PastePlugin } from './custom/plugins/paste'
import { FileUploadPlugin } from './custom/plugins/fileUpload'
import { FormatPlugin } from './custom/plugins/format'
import { CommentTextPlugin } from './custom/plugins/commentText'
import { HeaderPlugin } from './custom/plugins/header'
import { switchProject } from '@/helpers/switchProject'
import yjsPlugin from '@/utils/yjs/index'
import { trackChangesPlugin } from '@/utils/yjs/plugin/trackChanges'

/**
 * 插件：
 * dispatch：更新store
 * yjsEditorCoordinationInfo：当前用户、协同成员、在线状态、操作所有信息
 * uuid：当前文档uuid
 * userInfo：当前用户信息
 * editorRef：编辑器对象
 * docContent：编辑器内容
 */
const editorAllPlugins = (
  dispatch,
  yjsEditorCoordinationInfo,
  uuid,
  userInfo,
  editorRef,
  docContent,
) => {
  return [
    // 多人协作
    ...(uuid && yjsEditorCoordinationInfo.employeeList.total > 1
      ? yjsPlugin(
        dispatch,
        yjsEditorCoordinationInfo,
        uuid,
        userInfo,
        editorRef,
        docContent,
      )
      : [trackChangesPlugin(dispatch, yjsEditorCoordinationInfo, () => true)]),
    new CommentTextPlugin(yjsEditorCoordinationInfo),
    new FormatPlugin(),
    new FileUploadPlugin({
      uploadBeforeInsert: true,
      uploader: (file) =>
        new Promise((resolve) => {
          const formData = new FormData()
          formData.append('file', file)
          request
            .post(
              `/api/${!switchProject('DFZ') ? 'api-aidb' : 'api-core'
              }/upload/uploadFile`,
              formData,
            )
            .then((res) => {
              if (res.status === 200) {
                resolve({
                  src: res.data.data.url,
                  // width: 0,
                  // height: 0,
                })
                events.emit(ACTIONS.SAVE_PIC_EDITOR)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }),
    }),
    new PastePlugin(),
    new CommentBubblePlugin(yjsEditorCoordinationInfo),
    new FloatBtnPlugin({
      view(EditorView, editor: SylApi) {
        let posNodeClick
        const buttonGroup = document.createElement('div')
        buttonGroup.className = 'button-group'
        const sylEditor = document.querySelector('.syl-editor')
        sylEditor.appendChild(buttonGroup)
        buttonGroup.style.width = '22px'
        buttonGroup.style.height = '22px'
        buttonGroup.style.padding = '10px'
        buttonGroup.style.display = 'block'
        buttonGroup.style.position = 'absolute'
        buttonGroup.style.right = `-50px`
        buttonGroup.style.display = `none`
        buttonGroup.style.cursor = `pointer`
        buttonGroup.style.borderRadius = `4px`
        buttonGroup.style.backgroundSize = `18px`
        buttonGroup.style.backgroundRepeat = `no-repeat`
        buttonGroup.style.backgroundPosition = 'center'
        buttonGroup.style.border = `1px solid #e0e0e0`
        buttonGroup.style.backgroundImage = `url('https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2024/01/addComment.svg')`
        buttonGroup.addEventListener('click', (event) => {
          // events.emit(ACTIONS.COMMENT_BUBBLE, {
          //   pos: posNodeClick,
          // })
          events.emit(ACTIONS.COMMENT_BUBBLE, {
            pos: Number(buttonGroup.getAttribute('pos')),
          })
          buttonGroup.style.display = `none`
        })

        EditorView.dom.addEventListener('click', (event) => {
          // 获取鼠标所在位置的节点
          // buttonGroup.style.display = `block`
          buttonGroup.style.width = '22px'
          buttonGroup.style.height = '22px'
          buttonGroup.style.padding = '10px'
          // buttonGroup.style.display = 'block'
          buttonGroup.style.position = 'absolute'
          buttonGroup.style.right = `-50px`
          buttonGroup.style.cursor = `pointer`
          buttonGroup.style.borderRadius = `4px`
          buttonGroup.style.backgroundSize = `18px`
          buttonGroup.style.backgroundRepeat = 'no-repeat'
          buttonGroup.style.backgroundPosition = 'center'
          buttonGroup.style.border = `1px solid #e0e0e0`
          buttonGroup.style.backgroundImage = `url('https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2024/01/addComment.svg')`
          const posNode = EditorView.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
          if (posNode && posNode.inside > -1) {
            const { top: editorTop } = editor.root.getBoundingClientRect()
            const { left: headLeft, bottom: headBottom } =
              editor.view.coordsAtPos(posNode.inside)

            const computedTop = headBottom + 8 - editorTop
            buttonGroup.style.display = `block`
            buttonGroup.style.top = `${computedTop - 6}px`
            posNodeClick = posNode.inside
            buttonGroup.setAttribute('pos', posNode.inside)
            const currentNode = editor.view.state.doc.nodeAt(posNode.inside)
            // console.log(currentNode, 'currentNode')
            if (
              currentNode.type.name === 'table_cell' ||
              currentNode.type.name === 'image' ||
              currentNode.type.name === 'video' ||
              currentNode.type.name === 'bullet_list' ||
              (currentNode.type.name === 'paragraph' &&
                currentNode.childCount == 0)
            ) {
              buttonGroup.style.display = `none`
            }
            const markType = editor.view.state.schema.marks.comment
            currentNode.descendants((node, pos) => {
              // Check if the mark applies to this text node
              const marksToRemove = node.marks.find((mark) => {
                return (
                  mark.type === markType &&
                  mark.attrs.class.includes('comment') !== -1
                )
              })
              if (marksToRemove) {
                buttonGroup.style.display = `none`
                return false
              }

              return true
            })
          }
          if (posNode.inside == -1) {
            buttonGroup.style.display = `none`
          }
        })

        return {
          update(view, prevState) {
            const { state } = view
            const { doc, selection } = state
          },
          destroy() {
            // buttons.remove()
          },
        }
      },
    }),
    new TaskListPlugin(),
    new AiToolsPlugin(),
    // new AiDirectivesPlugin(),
    new DirectivesPlugin(),
    new InnerToolPlugin(),
    new EditorNavPlugin(),
    new CharacterCountPlugin(),
    new MapKeyPlugin(),
    new CommentPlugin(),
    new CommentMenuPlugin(),
    new RewritePlugin(),
    new ContinuePlugin(),
    new TranslatePlugin(),
    new ReducePlugin(),
    new FontFamilyPlugin(),
    new HighlightPlugin(),
    new RewriteHighlightPlugin(),
    new ToolsPlugin(),
    new ChatGPTPlugin(),
    new RedoPlugin(),
    new UndoPlugin(),
    // 字体高亮
    new ColorPlugin(),
    new BoldPlugin(),
    new BlockQuotePlugin(),
    new ListItemPlugin({
      matchInnerTags: ['section', 'p'],
      allowedLineHeights: [],
      allowedSpaceBefores: [],
      allowedSpaceAfters: { default: true, value: 20 },
      allowedSpaceBoths: [],
      addAttributes: {
        class: {
          default: 'list_item',
          getFromDOM: (dom) =>
            dom.firstElementChild && dom.firstElementChild.tagName == 'COMMENT'
              ? 'hasComment'
              : 'list_item',
          setDOMAttr: (val, attrs) => (attrs.class = val),
        },
      },
    }),
    new BulletListPlugin(),
    new OrderedListPlugin(),
    new CodeBlockPlugin({
      lineNumbers: true,
      lineWrapping: true,
      mode: { name: 'javascript', json: true },
    }),
    new HrPlugin(),
    // 标题
    new HeaderPlugin(),
    new BackgroundPlugin(),
    new ItalicPlugin(),
    new SubPlugin(),
    new SupPlugin(),
    new StrikePlugin(),
    new UnderlinePlugin(),
    new ParagraphPlugin({
      addMatchTags: ['section'],
      allowedAligns: ['left', 'center', 'right', 'justify'],
      allowedClass: [],
      allowedLineHeights: [],
      allowedLineIndents: [],
      allowedSpaceBefores: [],
      allowedSpaceAfters: { default: true, value: 20 },
      allowedSpaceBoths: [],
    }),
    // new FormatClearPlugin(),
    // 原生字体大小插件使用方式
    // new FontSizePlugin({
    //   allowedValues: [18, 20, 24, 32],
    //   values: [12, 14, { value: 16, default: true }, 18, 20, 24, 32],
    //   defaultFontSize: 18,
    //   unit: 'px',
    // }),
    // 自定义字体大小插件使用方式
    new FontSizePlugin(),
    new FontSizePlugin({
      allowedValues: [18, 20, 24, 32],
      values: [12, 14, { value: 16, default: true }, 18, 20, 24, 32],
      defaultFontSize: 18,
      unit: 'px',
    }),
    new LetterSpacePlugin({
      allowedValues: [0, 0.5, 1, 1.5],
    }),
    new AlignLeftPlugin(),
    new AlignCenterPlugin(),
    new AlignRightPlugin(),
    new AlignJustifyPlugin(),
    new SpaceBeforePlugin({
      values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
    }),
    new SpaceAfterPlugin({
      values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
    }),
    new SpaceBothPlugin({
      values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
    }),
    new LineHeightPlugin({
      values: [1, 1.5, { value: 1.75, default: true }, 1.88, 2, 3],
    }),
    new LineIndentPlugin(),
    new FormatPainterPlugin({
      ignoreMarks: ['comment'],
    }),
    new TablePlugin({
      button: {
        activeColor: '#FF0F0F',
        trigger: 'hover',
      },
      // columnResize: { handleWidth: 5, cellMinWidth: 24 },
      table: {
        allowTableNodeSelection: false,
        defaultCellWidth: 100,
        useTableHeader: false,
      },
    }),
    // 图片上传
    new ImagePlugin({
      uploadType: 'file',
      maxLength: 100,
      // uploadBeforeInsert: true,
      deleteFailedUpload: true,
      disableResize: false,
      maxWidth: 0,
      uploader: (img) =>
        new Promise((resolve) => {
          const formData = new FormData()
          formData.append('file', img)
          request
            .post(
              `/api/${!switchProject('DFZ') ? 'api-aidb' : 'api-core'
              }/upload/uploadFile`,
              formData,
            )
            .then((res) => {
              if (res.status === 200) {
                setTimeout(() => {
                  resolve({
                    src: res.data.data.url,
                    width: 0,
                    height: 0,
                  })
                  events.emit(ACTIONS.SAVE_PIC_EDITOR)
                }, 1500)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }),
    }),
    new VideoPlugin({
      // uploader: async (file) =>
      //   Promise.resolve({
      //     src: URL.createObjectURL(file),
      //     size: file.size,
      //     type: file.type,
      //     title: file.name,
      //   }),
      uploadBeforeInsert: true,
      uploader: (file) =>
        new Promise((resolve) => {
          const formData = new FormData()
          formData.append('file', file)
          request
            .post(
              `/api/${!switchProject('DFZ') ? 'api-aidb' : 'api-core'
              }/upload/uploadFile`,
              formData,
            )
            .then((res) => {
              if (res.status === 200) {
                resolve({
                  src: res.data.data.url,
                  // width: 0,
                  // height: 0,
                })
                events.emit(ACTIONS.SAVE_PIC_EDITOR)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }),
    }),
    new LinkPlugin(),
    // new AudioPlugin({
    //   uploader: (file) =>
    //     Promise.resolve({
    //       src: URL.createObjectURL(file),
    //     }),
    // }),
    new AudioPlugin({
      uploadBeforeInsert: true,
      uploader: (file) =>
        new Promise((resolve) => {
          const formData = new FormData()
          formData.append('file', file)
          request
            .post(
              `/api/${!switchProject('DFZ') ? 'api-aidb' : 'api-core'
              }/upload/uploadFile`,
              formData,
            )
            .then((res) => {
              if (res.status === 200) {
                resolve({
                  src: res.data.data.url,
                  title: file.name,
                })
                events.emit(ACTIONS.SAVE_PIC_EDITOR)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }),
    }),
    new EmojiPlugin({
      backgroundImageFn: () => 'https://oss.v-dk.com/img/202312181302874.png',
    }),
  ]
}

export default editorAllPlugins
