import React, { useState, useEffect } from 'react';
import request from '@/utils/request';


// 这里面文件千万不要动，不要格式化此文件，分号会出问题
const HFivePay = () => {
  const [text, setText] = useState('支付中...');

  useEffect(() => {
    queryOrderNo();
    // 禁止用户返回（如果是H5支付，返回刷新会继续跳转到支付）
    if ((window as any).history && (window as any).history.pushState) {
      (window as any).onpopstate = function () {
        (window as any).history.pushState('forward', null, '');
        (window as any).history.forward(1);
      };
    }
    (window as any).history.pushState('forward', null, ''); //在IE中必须得有这两行
    (window as any).history.forward(1);
  }, []);

  const queryOrderNo = () => {
    request
      .post('/api/api-aidb/pay/queryPollOrder', {
        withCredentials: true,
        orderNo: JSON.parse(localStorage.getItem('orderNo')),
      })
      .then((res) => {
        // http://localhost:3000/pay/HFivePay 测试地址
        if (res.data.data.orderStatus == 3) {
          setText('支付成功，正在跳转...');
          setTimeout(() => {
            window.location.replace('/celebrate');
          }, 1500);
        } else {
          setText('支付失败，正在跳转...');
          setTimeout(() => {
            window.location.replace('/userCenter?activeTab=3');
          }, 1500);
        }
      })
      .catch((err) => {
        setText('支付失败，正在跳转...');
        setTimeout(() => {
          window.location.replace('/userCenter?activeTab=3');
        }, 1500);
      });
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20%',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            marginTop: '1rem'
          }}
        >
          {text}
        </p>
      </div>
    </>
  );
};

export default HFivePay;
