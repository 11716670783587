import Logo from '@/components/SideBar/Logo'
import { GetProjectShareContent } from '@/model/reference'
import { GetQueryValue } from '@/utils/common'
import { Spin } from '@arco-design/web-react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
function LoadingAuth() {
  const history = useHistory()
  useEffect(() => {
    localStorage.setItem('docTypeKey', '共享文档')
    if (GetQueryValue('Teamstate') == 'additional') {
      GetProjectShareContent({
        teamUuid: GetQueryValue('teamUuid'),
        uuid: GetQueryValue('uuid'),
        state: GetQueryValue('Teamstate'),
      }).then((res) => {
        if (res.data.success) {
          handleGoDetail()
          localStorage.setItem('tabSwitch', 'card')
        }
      })
    } else {
      history.push('/home')
    }
  }, [])

  const handleGoDetail = () => {
    if (GetQueryValue('fileType') == '1') {
      const docLink = `/intelligentDocument?uuid=${GetQueryValue(
        'uuid',
      )}&teamUuid=${GetQueryValue('teamUuid')}&fileType=${GetQueryValue(
        'fileType',
      )}`
      history.push(docLink)
    } else {
      const folderLink = `/documentEdit?uuid=${GetQueryValue(
        'uuid',
      )}&teamUuid=${GetQueryValue('teamUuid')}&fileType=${GetQueryValue(
        'fileType',
      )}`
      history.push(folderLink)
    }
  }
  return (
    <div
      className={'pageLayout'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <div
        style={{
          position: 'fixed',
          top: '-20px',
          left: '20px',
        }}>
        <Logo></Logo>
      </div>
      <Spin size={30} />
      <span style={{ marginLeft: '10px', marginTop: '-5px', fontSize: '22px' }}>
        正在加入协同...
      </span>
    </div>
  )
}

export default LoadingAuth
