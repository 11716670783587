import React, { useEffect, useRef, useState } from 'react'
import styles from './style/right-form-mod.module.less'
import {
  IconArrowRight,
  IconErrorTip,
  IconRefresh,
  IconSend,
  IconStop,
} from '@arco-iconbox/react-aidb-v2'
import { Input, Message, Upload } from '@arco-design/web-react'
import request from '@/utils/request'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import { getChatGptSave } from '@/model/reference'
import { getCurrentTime } from '@/utils/toolFunction'
import { plugFlowError } from '@/utils/plugFlowError'
import Markdown from '@/components/Markdown'
import Answer from './answer'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { GetQueryValue, nowDate } from '@/utils/common'
import AgentImg from '@/assets/newImgs/aiagent.svg'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'
import { showModal } from '@/share/actions'

function RightFromMod(props) {
  const { avatar, initialValues, theme } = props
  const [sendSuccess, setSendSuccess] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [type, setType] = useState('inner')
  const [messageContent, setMessageContent] = useState([])
  const [currentMessage, setCurrentMessage] = useState<any>('')
  const [conversationId, setConversationId] = useState('')
  const [parentMessageId, setParentMessageId] = useState('')
  const pushFlow = useRef<any>(true)
  const [randomGuides, setRandomGuides] = useState([])
  const hasSetMessageContentRun = useRef(false)

  //切换type
  useEffect(() => {
    events.on(ACTIONS.AIAGENT_TAB_TYPE, handleType1)
    return () => {
      events.off(ACTIONS.AIAGENT_TAB_TYPE, handleType1)
    }
  })

  //抛出gpt错误
  function catchGPTError(tooltip) {
    setSendSuccess(true)
    pushFlow.current = true
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: tooltip,
    })
  }

  const handleSetvalue = (e) => {
    doApi(e)
  }

  const handleType1 = () => {
    handleType('inner')
  }

  const handleType = (e) => {
    setType(e)
    pushFlow.current = false
    setCurrentMessage('')
    setSendSuccess(true)
    setSendList([])
    setTimeout(() => {
      setMessageContent([])
      pushFlow.current = true
    }, 200)
  }

  //发起请求
  function doApi(params?: any) {
    console.log(localStorage.getItem('vipLevel') == 'null')

    if (
      !sendSuccess ||
      (inputValue?.trim().length == 0 && params == undefined)
    ) {
      return false
    }
    if (localStorage.getItem('vipLevel') == 'null') {
      dowenxin(params)
    } else {
      setType('answer')

      let tmpText = ''
      setSendSuccess(false)
      const question = inputValue || params

      setInputValue('')

      const opts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: question,
          conversationId: conversationId,
          parentMessageId: parentMessageId,
          stream: true,
          templateUuid: GetQueryValue('id'),
        }),
      }
      const Gpt_Modle =
        localStorage.getItem('vipLevel') == '2'
          ? '/api/api-aidb/chatGPT/streamMessageGPT4'
          : '/api/api-aidb/chatGPT/streamMessage'
      try {
        const controller = new AbortController()
        const eventSource = fetchEventSource(Gpt_Modle, {
          ...opts,
          signal: controller.signal,
          onclose() {
            catchGPTError('服务器连接错误,请重新尝试#01')
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            console.log(error)

            // 服务器或网络错误处理
            request
              .post(
                Gpt_Modle,
                JSON.stringify({
                  message: question,
                  // message: '你好',
                  conversationId: conversationId,
                  parentMessageId: parentMessageId,
                  stream: true,
                  templateUuid: GetQueryValue('id'),
                }),
              )
              .then((res) => {
                const error = res.data
                // todo 流控等提示
                if (error.data.text) {
                  console.log(error.data.text)
                  // todo 流控等提示
                  if (error?.data?.text) {
                    // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                    // 异常预处理
                    if (error.data.text == '[TOO_MANY_REQUESTS]') {
                      catchGPTError('请勿频繁请求#00-3')
                      return
                    }

                    if (error.data.text == '[OUT_OF_MIN_FLOW]') {
                      catchGPTError(
                        '1分钟内免费次数已用完，请稍后再试或开通会员#00-1#00-3',
                      )
                      setTimeout(() => {
                        showModal('VipContentModal')
                        window.location.hash = 'vip'
                      }, 1000)
                      return
                    }

                    if (error.data.text == '[OUT_OF_DAY_FLOW]') {
                      catchGPTError(
                        '今日免费次数已用完，请明日再试或开通会员#00-2',
                      )
                      setTimeout(() => {
                        showModal('VipContentModal')
                        window.location.hash = 'vip'
                      }, 1000)
                      return
                    }

                    if (error.data.text == '[OUT_OF_MIN_FLOW_VIP]') {
                      catchGPTError('1分钟内 VIP 次数已用完，请稍后再试#00-3')
                      return
                    }

                    if (error.data.text == '[OUT_OF_DAY_FLOW_VIP]') {
                      catchGPTError('今日 VIP 次数已用完，请明日再试#00-4')
                      return
                    }
                    if (error.data.text == '[ERROR]' && error.data.id == 0) {
                      catchGPTError('描述含有敏感词，请调整描述语句后再试#01-3')
                      return
                    }
                    //备用
                    if (error.data.text == '[ERROR]') {
                      catchGPTError('接口返回错误，请稍后再试#01-2')
                      return
                    }
                  }
                }
              })
              .catch((error) => {
                console.log(error.response)
                if (error.response && error.response.status === 500) {
                  // 只在状态码为500时触发错误提示
                  catchGPTError('接口连接错误,请重新尝试#012')
                }
              })
            throw error
          },
          onmessage(message) {
            console.log(message)
            // 异常预处理
            plugFlowError(eventSource, catchGPTError, message.data, message.id)
            if (pushFlow.current == false) {
              controller.abort()
              setTimeout(() => {
                console.log('a')
                setSendSuccess(true)
                setCurrentMessage('')
                pushFlow.current = true
                if (!hasSetMessageContentRun.current) {
                  setMessageContent((prevContent) => [
                    ...prevContent,
                    {
                      question: question,
                      answer: tmpText.replaceAll('\\n', '\n'),
                    },
                  ])
                  hasSetMessageContentRun.current = true
                }
              }, 200)
            } else {
              console.log('qq1')

              events.emit(ACTIONS.TEM_SCROLLTOP)
              if (message.event === 'result' && message.data !== '[DONE]') {
                tmpText = JSON.parse(message.data).response
              } else {
                tmpText =
                  message.data === '[DONE]'
                    ? tmpText
                    : tmpText +
                      message.data.substring(1, message.data.length - 1)
              }
              setCurrentMessage({
                question: question,
                answer: tmpText.replaceAll('\\n', '\n'),
              })
              if (message.data === '[DONE]') {
                console.log(pushFlow.current)

                if (pushFlow.current != false) {
                  setMessageContent((prevContent) => [
                    ...prevContent,
                    {
                      question: question,
                      answer: tmpText.replaceAll('\\n', '\n'),
                    },
                  ])
                  setSendSuccess(true)
                  setCurrentMessage([]) // 清空 currentMessage
                  controller.abort()
                }
              }
              if (message.event == 'result' || message.data == '[DONE]') {
                const tmpJson = JSON.parse(message.data)
                console.log(tmpJson)

                setConversationId(tmpJson.conversationId)
                setParentMessageId(tmpJson.messageId)
                getChatGptSave({
                  input: question,
                  output: tmpJson.response,
                  messageId: tmpJson.messageId,
                  conversationId: tmpJson.conversationId,
                  type: 'openai',
                  model:
                    localStorage.getItem('vipLevel') == '2'
                      ? 'gpt4.0'
                      : 'gpt3.5',
                  agentId: initialValues.id,
                })
              }
            }
          },
        })
      } catch (err) {
        catchGPTError('接口发生错误或超时未响应，请稍后重试#05')
        setSendSuccess(true)
      }
    }
  }

  const [sendList, setSendList] = useState([])

  function dowenxin(params) {
    if (!sendSuccess || (!inputValue?.trim() && !params)) {
      return false
    }

    setType('answer')
    let tmpText = ''
    setInputValue('')
    setSendSuccess(false)

    const question = inputValue || params
    const updatedSendList = [
      ...sendList,
      {
        role: 'user',
        content: question,
      },
    ]
    setSendList(updatedSendList)

    const opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: updatedSendList,
        model: 'ERNIE-Speed-128K',
        stream: true,
        user_id: localStorage.getItem('userId'),
        templateUuid: GetQueryValue('id'),
      }),
    }

    const Gpt_Modle = `${process.env.REACT_APP_WENXIN_URL}/ernieForStream`
    try {
      const controller = new AbortController()
      const eventSource = fetchEventSource(Gpt_Modle, {
        ...opts,
        signal: controller.signal,
        onclose() {
          // catchGPTError('服务器连接错误,请重新尝试#01')
          throw new Error(
            'Failed to send message. Server closed the connection unexpectedly.',
          )
        },
        onerror(error) {
          console.log(error)
          handleSecondaryRequest(Gpt_Modle, updatedSendList)
          catchGPTError(eventSource)
          throw error
        },
        onmessage(message) {
          const messages = JSON.parse(message.data)
          if (message.event === 'result' && message.data !== '[DONE]') {
            tmpText += messages.result
          } else if (!messages.is_end) {
            tmpText += messages.result
          }

          events.emit(ACTIONS.TEM_SCROLLTOP)
          setSendSuccess(true)
          setCurrentMessage({
            question: question,
            answer: tmpText.replaceAll('\\n', '\n'),
          })

          if (messages.is_end) {
            const finalContent = {
              question: question,
              answer: tmpText.replaceAll('\\n', '\n'),
            }
            setMessageContent((prevContent) => [...prevContent, finalContent])
            setSendList((prevList) => [
              ...prevList,
              { role: 'assistant', content: finalContent.answer },
            ])
            setSendSuccess(true)
            setCurrentMessage([])
            controller.abort()
            getChatGptSave({
              input: question,
              output: tmpText,
              messageId: messages.id,
              conversationId: messages.id,
              type: 'openai',
              model: 'wenxin',
              agentId: initialValues.id,
            })
          }
        },
      })
    } catch (err) {
      setSendSuccess(true)
    }
  }

  function handleSecondaryRequest(url, sendList) {
    request
      .post(url, {
        messages: sendList,
        model: 'ERNIE-Speed-128K',
        stream: true,
        user_id: localStorage.getItem('userId'),
        templateUuid: GetQueryValue('id'),
      })
      .then((res) => {
        const data = res.data
        console.log('第二接口返回:', data)
        const regex = /"result":"([^"]+)"/g
        const matches = data.matchAll(regex)
        let concatenatedResult = ''
        for (const match of matches) {
          concatenatedResult += match[1]
        }
        if (concatenatedResult) {
          setSendList((prevList) => [
            ...prevList,
            { role: 'assistant', content: concatenatedResult },
          ])
        } else {
          throw new Error('Empty result from secondary request')
        }
      })
      .catch((err) => {
        console.log('err:', err)
      })
  }

  const botListRef = useRef(null) //滚动
  //控制滚动条
  useEffect(() => {
    events.on(ACTIONS.TEM_SCROLLTOP, scrollBotList)

    return () => {
      events.off(ACTIONS.TEM_SCROLLTOP, scrollBotList)
    }
  })

  // 聊天滚到底部
  function scrollBotList(force) {
    setTimeout(() => {
      botListRef.current.scrollTop = botListRef.current.scrollHeight
    }, 0)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      doApi() // 调用你的API方法
    }
  }
  // 随机渲染引导示例，只在首次渲染时执行一次
  useEffect(() => {
    function getRandomElements(arr, count) {
      const shuffled = arr?.slice().sort(() => 0.5 - Math.random())
      return shuffled?.slice(0, count)
    }
    if (initialValues?.conversationStarters) {
      setRandomGuides(getRandomElements(initialValues.conversationStarters, 3))
    }
  }, [initialValues])

  return (
    <div className={`${styles.writeRight} ${styles[props.theme]}`}>
      <div className={`${styles.pb0} ${styles.content_wrap}`}>
        <div className={styles.content} ref={botListRef}>
          <div className={styles.wp}>
            {type == 'inner' && (
              <>
                <div className={styles['contentWarp']}>
                  <div className={styles['avatar']}>
                    {avatar ? <img src={avatar} alt="" /> : <AgentImg />}
                  </div>
                  <div className={styles['text']}>
                    <div className={styles['title']}>{initialValues.title}</div>
                    <div className={styles['desc']}>{initialValues.info}</div>
                  </div>
                  {/* <div className={styles['icon2']}>
                    <IconRefresh />
                  </div> */}
                </div>
                <div className={styles['contentInner']}>
                  {randomGuides.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          handleSetvalue(item)
                        }}
                        className={`${styles['item']} ${styles[props.theme]}`}
                        key={item.id}>
                        <div className={styles['text']}>
                          <div className={styles['name']}>{item}</div>
                          <div className={styles['desc']}>
                            去试试
                            <IconArrowRight />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
            {type == 'answer' && (
              <div className={styles.wpContent}>
                <Answer
                  handleType={handleType}
                  initialValues={initialValues}
                  avatar={avatar}
                  currentMessage={currentMessage}
                  messageContent={messageContent}></Answer>
              </div>
            )}
            {!sendSuccess && (
              <div className={styles['loading']}>
                努力加载中
                <div className={styles['loading-poi']}></div>
                <div className={styles['loading-poi']}></div>
                <div className={styles['loading-poi']}></div>
              </div>
            )}
            <div className={styles['contentBottom']}>
              <div className={styles['textareaWrap']}>
                <Input.TextArea
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e)
                  }}
                  onKeyDown={handleKeyDown}
                  className={styles['textarea']}
                  placeholder="请输入您的问题"
                />
                <div
                  className={`${styles['sendBtn']} `}
                  onClick={() =>
                    !sendSuccess ? (pushFlow.current = false) : doApi()
                  }>
                  <div className={styles['icon']}>
                    {sendSuccess ? (
                      <IconSend />
                    ) : (
                      <IconStop
                        style={{ marginRight: '3px' }}
                        className={styles['iconStop']}
                        useCurrentColor={true}
                      />
                    )}
                    {/* <IconSend /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightFromMod
