import React from 'react'
import styles from './style/prompt-input.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'
import { Slider } from '@arco-design/web-react'
import PromptWeight from './PromptWeight'
const TextArea = Input.TextArea

function PromptInput(props) {
  const {
    title,
    tooltip,
    showDel,
    showPlus,
    showMinus,
    placeholder,
    onInputChange,
    value,
    imgtype,
  } = props

  function del() {
    if (props.del) {
      props.del()
    }
  }

  //添加删除
  function plus() {
    if (props.plus) {
      props.plus()
    }
  }
  function minus() {
    if (props.minus) {
      props.minus()
    }
  }

  const handleInputValueChange = (newValue) => {
    if (onInputChange) {
      onInputChange(newValue) // 通过传递的回调来更新输入框值
    }
  }
  function handleBlur() {
    if (imgtype == true) {
      props.onblur()
    }
  }
  return (
    <div>
      <PromptTitle
        plus={() => {
          plus()
        }}
        minus={() => {
          minus()
        }}
        title={title}
        tooltip={tooltip}
        showDel={showDel}
        showPlus={showPlus}
        del={() => {
          del()
        }}
        showMinus={showMinus}
      />
      <div className={styles.input}>
        <Input
          className={'g-input'}
          placeholder={placeholder}
          onChange={handleInputValueChange}
          value={value}
          onBlur={handleBlur}
          // disabled={true}
          onPressEnter={handleBlur}></Input>
      </div>
    </div>
  )
}

export default PromptInput
