import React, { useEffect, useState } from 'react'
import styles from './style/index.module.less'
import NavItem from './NavItem'
import VipBtn from './VipBtn'
import ChangeThemeBtn from './ChangeThemeBtn'
import Logo from './Logo'
import {
  IconHome,
  IconDocument,
  IconSmartAssistant,
  IconTemplateLibrary,
  IconDatabase,
  IconCollect,
  IconWastePaper,
  IconGuide,
  IconApplication,
  IconAiDrawing,
  IconAgent,
} from '@arco-iconbox/react-aidb-v2'
import { IconMyOrder } from '@arco-iconbox/react-aidb'
import { getUserInfo } from '@/model/reference'
import ModalCustom from '../ModalCustom'
import TemplateMod from '../templateLibrary/TemplateMod'
import SearchMod from '@/pages/intelligentDocument/SearchMod'
import request from '@/utils/request'
import IconDraw1 from '../../assets/imgs/draw1.svg'
import IconDraw2 from '../../assets/imgs/draw2.svg'
import IconDraw3 from '../../assets/imgs/draw3.svg'
import { useSelector } from 'react-redux'
import { GlobalState } from '../../store'
import { showModal } from '@/share/actions'
import { useHistory } from 'react-router-dom'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { Button } from '@arco-design/web-react'

function SideNav(props) {
  const curIndex = props.curIndex
  const history = useHistory()

  //显示弹窗
  const theme = useSelector((state: GlobalState) => state.theme)
  const [content, setContent] = useState('开通')

  const navList_0 = [
    {
      icon: <IconHome useCurrentColor={false} />,
      title: '控制台',
      index: 0,
      path: '/home',
    },
  ]

  const navList_1 = [
    {
      icon: <IconDocument useCurrentColor={false} />,
      title: '智能文档',
      index: 1,
      path: '/intelligentDocument',
    },
    {
      icon: <IconSmartAssistant useCurrentColor={false} />,
      title: '智能助理',
      index: 3,
      path: '/toolbox/index',
    },
    {
      icon: <IconAgent useCurrentColor={false} />,
      title: '智能体',
      index: 9,
      path: '/aiAgent',
    },
    {
      icon: <IconTemplateLibrary useCurrentColor={false} />,
      title: '智能模版',
      index: 2,
    },
    {
      icon: <IconAiDrawing useCurrentColor={false} />,
      title: '智能绘画',
      index: 4,
    },
  ]
  const navList_2 = [
    {
      icon: <IconCollect useCurrentColor={true} />,
      title: '收藏夹',
      index: 5,
      path: '/collect',
    },
    {
      icon: <IconWastePaper useCurrentColor={true} />,
      title: '废纸篓',
      index: 6,
      path: '/waste',
    },
  ]
  const navList3 = [
    {
      icon: <IconApplication useCurrentColor={true} />,
      title: '支持中心',
      index: 7,
      path: 'https://go.wuz.com.cn/ace0',
    },
    {
      icon: <IconGuide useCurrentColor={true} />,
      title: '用户反馈',
      index: 8,
      path: 'https://support.wuz.com.cn/form/',
    },
  ]

  function handleToPage(item) {
    localStorage.setItem('listIndex', item.index)
    if (item.index == 2) {
      // 检查templateData是否有数据
      if (props.templateData) {
        window.location.hash = 'template'
        // 如果有数据，直接打开模板库弹窗，不再发起请求
        showModal('TemplateModal', {
          templateData: props.templateData,
        })
      }
    } else if (item.index == 4) {
      showModal('AiDrawModal')
      window.location.hash = 'draw'
    } else {
      // location.href = item.path
      history.push(item.path)
    }
  }
  function handleToVip() {
    showModal('VipContentModal')
    window.location.hash = 'vip'
  }
  useEffect(() => {
    if (localStorage.getItem('isVip') == 'true') {
      setContent('续费')
    } else {
      setContent('开通')
    }
  }, [])
  function handleToHome() {
    location.href = '/home'
  }
  function handleToWWW() {
    window.open('https://www.wuz.com.cn/')
  }

  function Home() {
    return <h2>Home</h2>
  }

  function About() {
    return <h2>About</h2>
  }

  return (
    <div className={`${styles['sideNav']} ${styles[theme]}`}>
      <div className={styles['top']}>
        {/*logo*/}
        <div className={styles['topLogo']}>
          <Logo handleToWWW={handleToWWW}></Logo>
        </div>
        {/*导航0*/}
        <div className={styles['navList']}>
          {navList_0.map((item) => {
            return (
              <NavItem
                name={'item0'}
                href={item.path}
                theme={theme}
                onClick={() => handleToPage(item)}
                isCurrent={item.path === location.pathname}
                key={item.title}
                icon={item.icon}
                title={item.title}></NavItem>
            )
          })}
        </div>
        {/*导航1*/}
        <div className={styles['navList']}>
          {navList_1.map((item) => {
            return (
              <NavItem
                name={'item1'}
                href={item.path}
                theme={theme}
                onClick={() => handleToPage(item)}
                isCurrent={item.path === location.pathname}
                key={item.title}
                icon={item.icon}
                title={item.title}></NavItem>
            )
          })}
        </div>
        {/*导航2*/}
        <div className={styles['navList']}>
          {navList_2.map((item) => {
            return (
              <NavItem
                name={'item2'}
                href={item.path}
                theme={theme}
                onClick={() => handleToPage(item)}
                isCurrent={item.path === location.pathname}
                key={item.title}
                icon={item.icon}
                title={item.title}></NavItem>
            )
          })}
        </div>
      </div>
      <div className={styles['bottom']}>
        {/*vip按钮*/}
        <VipBtn onClick={handleToVip} content={content}></VipBtn>
        {/*导航3*/}
        <div className={styles['navList']}>
          {navList3.map((item) => {
            return (
              <NavItem
                onClick={() => {
                  window.open(item.path, '_blank')
                }}
                href={item.path}
                name={'item3'}
                theme={theme}
                isCurrent={item.index === curIndex}
                key={item.title}
                icon={item.icon}
                title={item.title}></NavItem>
            )
          })}
        </div>
        {/*改变主题*/}
        {/* <ChangeThemeBtn></ChangeThemeBtn> */}
      </div>
      {/* tony */}
      {/* <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Button type="primary">
                  <Link to="/about">About</Link>
                </Button>
              </li>
            </ul>
          </nav>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router> */}
    </div>
  )
}

export default SideNav
