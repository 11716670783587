import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconBox } from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'
import { Tooltip } from '@arco-design/web-react'

interface ToolsProps {
  name: string
}

const PLUGIN_NAME = 'tools'

class ToolsSchema extends Inline<ToolsProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class ToolsController extends SylController<ToolsProps> {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    // events.on(ACTIONS.TO_EDITOR, this.replaceHandle);
  }

  // editorWillUnmount() {
  //   events.off(ACTIONS.TO_EDITOR, this.replaceHandle);
  // }

  public updateDocHandle = (_editor: SylApi) => {
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    events.emit(ACTIONS.FROM_TOOLS_CLICK_EDITOR, {
      setSelectContent: selectedContent,
      barActiveIndex: DocBtnType.tools,
      nodeInfo: nodeInfo,
    })
    // events.emit(ACTIONS.TOOL_SELECT, {
    //   setSelectContent: selectedContent,
    //   nodeInfo: nodeInfo,
    //   barActiveIndex: DocBtnType.tools,
    // });
  }

  // public replaceHandle = (payload) => {
  //   // Get the style of the old text

  //   const { nodeInfo, content } = payload;
  //   if (!nodeInfo) return;
  //   const oldTextNode = this.editor.view.state.doc.nodeAt(nodeInfo.index);
  //   const oldTextStyle = oldTextNode.marks;

  //   // Create a transaction to replace the old text with the new text
  //   const tr = this.editor.view.state.tr;
  //   tr.replaceWith(
  //     nodeInfo.index,
  //     nodeInfo.index + nodeInfo.length,
  //     this.editor.view.state.schema.text(content, oldTextStyle)
  //   );

  //   // Apply the transaction to the editor state
  //   this.editor.view.dispatch(tr);
  //   // this.removeHighlightAndTip();
  //   events.emit(ACTIONS.FROM_EDITOR, {
  //     setSelectContent: null,
  //     barActiveIndex: payload.type,
  //     nodeInfo: null,
  //   });
  // };

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    type: '',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <Tooltip position="bottom" trigger="hover" content="工具">
          <div
            className="toolsBox iconBox"
            onClick={() => this.updateDocHandle(_editor)}>
            <IconBox
              className="iconTools icon"
              useCurrentColor={true}></IconBox>
            {/* <span className="iconTitle">工具</span> */}
          </div>
        </Tooltip>
      )
    },
    value: [],
  }
}

class ToolsPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = ToolsController
  public Schema = ToolsSchema
}

export { ToolsPlugin }
