import React, { useCallback, useEffect, useRef, useState } from 'react'
import DocHeader from '@/components/DocumentEdit/docHeader'
import ToolNav from '@/components/DocumentEdit/toolsNav'
import {
  ApprovalStatus,
  DocBtnType,
  DocVersionType,
  EditWidthType,
} from '@/helpers/enums'
import { events } from '@/helpers/event-emitter'
import { showModal } from '@/share/actions'
import Modal from '@/components/Modal'
import { ACTIONS } from '@/share/constants'
import { useHistory } from 'react-router-dom'
import styles from './style/index.module.less'
import { GetQueryValue, removeEmpty, throttle } from '@/utils/common'
import {
  getDocumentDetail,
  saveDocument,
  GetProjectShareContent,
} from '@/model/reference'
import WritingContent from '../writingContent'
import { Message } from '@arco-design/web-react'
import EditPageBox from '@/components/DocumentEdit/editPageBox'
import { GlobalHotKeys } from 'react-hotkeys'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import zhCN from 'antd/lib/locale/zh_CN'
import {
  IconArrowUp,
  IconErrorTip,
  IconSuccessTip,
  IconWarnTip,
} from '@arco-iconbox/react-aidb-v2'
import TitleNav from '@/components/DocumentEdit/titleEditNav'
import TitleShareNav from '@/components/DocumentShare/titleShareNav'
import Sidebar from './sidebar'
// import TitleNav from '@/components/DocumentShare/titleNav'
import { getDocVersionList } from '@/model/documentEdit'
import { ConfigProvider, Tour } from 'antd'
import { GlobalState } from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { switchDocTitle } from '@/helpers/switchProject'
import { getUserInfo, GetSynergia } from '@/model/reference'
import EditorInitializationLoading from '@/components/Loading/RLLoading'
import TimedOutRefresh from '@/components/Modal/timed-out-refresh'
import { debounce } from 'lodash'

const EditorPage = () => {
  const dispatch = useDispatch()
  const [userInfo, setUserInfo] = useState({})
  // 协同者权限 0-新建文档（无权限），1-可管理，2-可编辑，3-可阅读，4-无任何权限
  const [employeePermission, setEmployeePermission] = useState(0)
  // 协同者列表
  const [employeeList, setEmployeeList] = useState({
    employeeList: [],
    total: 0,
  })
  const [type, setType] = useState<number>()
  const [uuid, setUuid] = useState<string>()
  const [showDocEdit, setShowDocEdit] = useState(false)
  const [showRight, setShowRight] = useState(false)
  const [isFullDisplay, setIsFullDisplay] = useState(
    localStorage.getItem('isFullDisplay') == 'true',
  )
  // 是否已保存
  const [isSave, setIsSave] = useState({
    // 内容保存
    isEditContentSave: false,
    // 标题保存
    isEditTitleSave: false,
    // 保存时间
    date: Date.now(),
  })
  // 是否为全宽模式
  const [isImmerseDisplay, setIsImmerseDisplay] = useState(false)
  const [upRightZ, setUpRightZ] = useState(false)
  const [current, setCurrent] = useState({ id: 0, title: '' })
  const [docTitle, setDocTitle] = useState<string>('')
  const [docContent, setDocContent] = useState<string>('')
  // 是否在保存中
  const [isAutoSave, setIsAutoSave] = useState(false)
  const [isEditContent, setIsEditContent] = useState(false)
  const [isEditTitle, setIsEditTitle] = useState(false)
  const [isCollect, setIsCollect] = useState<number>(0)
  const [contentCount, setContentCount] = useState()
  const [gmtModifyDate, setGmtModifyDate] = useState<string>('')
  const [defaultContent, setDefaultContent] = useState<any>()
  const [selectContent, setSelectContent] = useState<string>('')
  const [nodeInfo, setNodeInfo] = useState()
  const [canEdit, setCanEdit] = useState(false)
  const [showNav, setShowNav] = useState<boolean>(false)
  const [toolIsLoading, setToolIsLoading] = useState<boolean>(false)
  const [transToolIsLoading, setTransToolIsLoading] = useState<boolean>(false)
  const [writeToolIsLoading, setWriteToolIsLoading] = useState<boolean>(false)
  const [correctToolIsLoading, setCorrectToolIsLoading] =
    useState<boolean>(false)
  const [continueToolIsLoading, setContinueToolIsLoading] =
    useState<boolean>(false)
  const [directivesToolIsLoading, setDirectivesToolIsLoading] =
    useState<boolean>(false)
  const [reduceToolIsLoading, setReduceToolIsLoading] = useState<boolean>(false)
  const [isHasTransList, setIsHasTransList] = useState()
  const [isHasRewriteList, setIsHasRewriteList] = useState()
  const [isHasCorrectList, setIsHasCorrectList] = useState()
  const [isHasContinueList, setIsHasContinueList] = useState()
  const [isHasReduceList, setIsHasReduceList] = useState()
  const [isHasDirectivesList, setIsHasDirectivesList] = useState()
  const [isContentHtml, setIsContentHtml] = useState(false)
  const [isShowPrint, setIsShowPrint] = useState(false)
  const [isTimedOutRefreshVisible, setIsTimedOutRefreshVisible] =
    useState(false)
  // 更新左边树形列表
  const [updateTreeList, setUpdateTreeList] = useState(Date.now())
  const id = GetQueryValue('uuid')
  const history = useHistory()
  const titleRef = useRef()
  const editTitleRef = useRef()
  const contentRef = useRef()
  const editContentRef = useRef()
  const typeRef = useRef()
  const uuidRef = useRef()

  const sidebarRef = useRef<any>() // 左侧树目录
  const ref1 = useRef<any>() //漫游式引导按钮控制
  const ref2 = useRef<any>(null) //漫游式引导按钮控制
  const ref3 = useRef<any>(null) //漫游式引导按钮控制
  const [open, setOpen] = useState(false)
  const printRef = useRef()
  const theme = useSelector((state: GlobalState) => state.theme)
  // 当前用户、协同成员、在线状态、操作所有信息
  const yjsEditorCoordinationInfo = useSelector(
    (state: GlobalState) => state.yjsEditorCoordinationInfo,
  )
  // 用户步骤指导
  const [steps] = useState([
    {
      title: '文件列表',
      description: '您可以在这里对文件进行操作，如新建、删除、移动、重命名等。',
      target: () => {
        if (sidebarRef.current) {
          return sidebarRef.current
        }
        return null
      },
    },
    {
      title: '文章标题',
      description: '在这里输入标题。',
      target: () => {
        if (ref1.current && ref1.current?.divRef) {
          return ref1.current.divRef.current
        }
        return null
      },
    },
    {
      title: '操作中心',
      description: '在这里完成提交、查看快捷键、探索更多操作和邀请协作。',
      target: () => {
        if (ref1.current && ref1.current.divRef1) {
          return ref1.current.divRef1.current
        }
        return null
      },
    },
    {
      title: '文章内容',
      description: '在这里输入内容。',
      target: () => {
        if (ref2.current && ref2.current.divRef) {
          return ref2.current.divRef.current
        }
        return null
      },
    },
    {
      title: '编辑工具',
      description: '在这里使用各种插件功能，使您的文字更加丰富和个性化。',
      target: () => {
        return document.querySelector('#toolbarContainer')
      },
    },
    {
      title: 'AI工具',
      description: '您可以使用AI工具助手，帮助优化您的写作。',
      target: () => {
        if (ref3.current && ref3.current.divRef) {
          return ref3.current.divRef.current
        }
        return null
      },
    },
  ])

  let timerContent,
    autoTimeContent,
    timerTitle,
    autoTimerTitle,
    isContentHtmlTimer

  // 初始化获取用户信息和协同列表
  useEffect(() => {
    // 开启 Loading
    EditorInitializationLoading.open({ content: 'Loading' })
    // 获取用户信息
    getUserInfo({ uid: '' })
      .then(async (userInfo) => await setUserInfo(userInfo.data.data))
      .catch((error) => console.log(error))

    if (GetQueryValue('uuid')) {
      // 获取协同列表成员
      GetSynergia({ uuid: GetQueryValue('uuid') })
        .then(async (res) => {
          const { code, data } = res.data
          if (code === '500200')
            // 将管理者放到第一个
            await setEmployeeList({
              employeeList: data.employeeList.sort(
                (a, b) => a.permission - b.permission,
              ),
              total: data.total,
            })
        })
        .catch((error) => EditorInitializationLoading.close())
    } else {
      EditorInitializationLoading.close()
      setEmployeeList({
        employeeList: [],
        total: 1,
      })
    }
  }, [GetQueryValue('uuid')])

  useEffect(() => {
    // 更新数据
    dispatch({
      type: 'yjs-editor-coordination-info',
      payload: {
        yjsEditorCoordinationInfo: {
          employeeList,
          userInfo,
          permission: employeePermission,
        },
      },
    })

    if (JSON.stringify(userInfo) === '{}' || !employeeList.employeeList.length)
      return

    // 协同权限管理
    switch (isSelf(userInfo, employeeList)) {
      case 0:
        setEmployeePermission(0)
        break
      case undefined:
        // 防止协同者url地址泄露
        setEmployeePermission(4)
        break

      default:
        setEmployeePermission(isSelf(userInfo, employeeList).permission)
        break
    }
  }, [userInfo, employeeList, employeePermission])

  useEffect(() => {
    getDocDetailHandle()
    setTimeout(() => {
      localStorage.getItem('guide') == 'false' ? setOpen(false) : setOpen(true)
    }, 500)
    document.body.classList.remove('dark')
    document.body.classList.remove('light')
    document.body.classList.add(theme)
  }, [GetQueryValue('uuid')])

  // useEffect(() => {
  //   if (!isEditTitle) return
  //   if (timerTitle) clearTimeout(timerTitle)
  //   if (autoTimerTitle) clearTimeout(autoTimerTitle)
  //   setIsAutoSave(true)
  //   setIsSave((data) => {
  //     return {
  //       ...data,
  //       isEditTitleSave: false,
  //       date: Date.now(),
  //     }
  //   })
  //   timerTitle = setTimeout(() => {
  //     saveDocHandle(
  //       editContentRef.current ? editContentRef.current : contentRef.current,
  //       // editTitleRef.current ? editTitleRef.current : titleRef.current,
  //       editTitleRef.current,
  //       ApprovalStatus.ToSubmit,
  //       false,
  //       null,
  //       DocVersionType.SilentSave,
  //     )
  //     setIsEditTitle(false)
  //   }, 3000)
  //   autoTimerTitle = setTimeout(() => {
  //     saveDocHandle(
  //       editContentRef.current ? editContentRef.current : contentRef.current,
  //       editTitleRef.current ? editTitleRef.current : titleRef.current,
  //       ApprovalStatus.ToSubmit,
  //       false,
  //       null,
  //       DocVersionType.AutoSave,
  //     )
  //     setIsEditTitle(false)
  //   }, 1000 * 60 * 5)
  //   document.title = editTitleRef.current
  //     ? editTitleRef.current + ' - ' + switchDocTitle()
  //     : titleRef.current
  //     ? titleRef.current + ' - ' + switchDocTitle()
  //     : switchDocTitle()
  //   setIsEditTitle(false)
  // }, [editTitleRef.current])

  // 标题变化更新
  useEffect(() => {
    const uuid = GetQueryValue('uuid') // 获取当前文档的uuid
    const storageKey = 'editor-document-title-storage' // 统一存储键名

    const handleBlur = () => {
      setIsAutoSave(true)
      setIsSave((data) => {
        return {
          ...data,
          isEditTitleSave: false,
          date: Date.now(),
        }
      })

      saveDocHandle(
        editContentRef.current ? editContentRef.current : contentRef.current,
        editTitleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.SilentSave,
      )
      // 更新树列表
      debouncedFunction()
      setTimeout(() => {
        document.title =
          editTitleRef.current !== undefined
            ? editTitleRef.current + ' - ' + switchDocTitle()
            : '未命名' + ' - ' + switchDocTitle()
      }, 100)

      setIsAutoSave(false)
      setIsEditTitle(false)
    }

    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleBlur()
      }
    }

    const inputElement = ref1.current?.inputRef.current

    // 确保 inputElement 是一个 DOM 元素并且存在
    if (inputElement instanceof HTMLElement) {
      if (editTitleRef.current !== undefined) {
        // 从localStorage获取数据，解析为数组，若不存在则创建空数组
        const existingTitles = JSON.parse(
          localStorage.getItem(storageKey) || '[]',
        )

        // 查找当前uuid是否已经存在
        const index = existingTitles.findIndex(
          (item: any) => item.uuid === uuid,
        )

        if (index !== -1) {
          // 如果存在，更新该文档的标题
          existingTitles[index].data = editTitleRef.current
        } else {
          // 如果不存在，新增一项
          existingTitles.push({
            uuid,
            data: editTitleRef.current,
          })
        }

        // 更新localStorage
        localStorage.setItem(storageKey, JSON.stringify(existingTitles))
      } else if (titleRef.current) {
        // 从localStorage获取数据，解析为数组，若不存在则创建空数组
        const existingTitles = JSON.parse(
          localStorage.getItem(storageKey) || '[]',
        )

        // 查找当前uuid是否已经存在
        const index = existingTitles.findIndex(
          (item: any) => item.uuid === uuid,
        )

        if (index === -1) {
          existingTitles.push({
            uuid,
            data: titleRef.current,
          })
        }

        // 更新localStorage
        localStorage.setItem(storageKey, JSON.stringify(existingTitles))
      }

      // 如果当前标题与存储的标题不一致，则更新标题
      const storedTitleItem = JSON.parse(
        localStorage.getItem(storageKey) || '[]',
      ).find((item: any) => item.uuid === uuid)
      const storedTitleItemData = storedTitleItem?.data

      if (
        storedTitleItem &&
        editTitleRef.current !== undefined &&
        // (editTitleRef.current !== storedTitleItemData ||
        storedTitleItemData === ''
      ) {
        // setTimeout(() => {
        if (isAutoSave) return
        setIsAutoSave(true)
        saveDocHandle(
          editContentRef.current ? editContentRef.current : contentRef.current,
          // storedTitleItemData || titleRef.current,
          storedTitleItemData,
          ApprovalStatus.ToSubmit,
          false,
          null,
          DocVersionType.SilentSave,
        )
        // 更新 editTitleRef 的值
        editTitleRef.current = storedTitleItemData || '未命名'
        // 更新标题
        setDocTitle(storedTitleItemData)
        // 更新树列表
        debouncedFunction()
        setIsAutoSave(false)
        setTimeout(() => {
          document.title =
            editTitleRef.current !== undefined
              ? editTitleRef.current + ' - ' + switchDocTitle()
              : titleRef.current + ' - ' + switchDocTitle()
        }, 100)

        // }, 500)
      } else {
        // 更新 editTitleRef 的值
        editTitleRef.current = storedTitleItemData || '未命名'
        // 更新树列表
        debouncedFunction()

        setTimeout(() => {
          document.title =
            editTitleRef.current !== undefined
              ? editTitleRef.current + ' - ' + switchDocTitle()
              : titleRef.current + ' - ' + switchDocTitle()
        }, 100)
      }

      // 添加失焦和回车事件监听器
      inputElement.addEventListener('blur', handleBlur)
      inputElement.addEventListener('keydown', handleEnter) // 监听回车键

      setIsEditTitle(false)
    }

    // 清理事件监听器
    return () => {
      if (inputElement instanceof HTMLElement) {
        inputElement.removeEventListener('blur', handleBlur)
        inputElement.removeEventListener('keydown', handleEnter) // 清理回车键监听
      }
    }
  }, [ref1.current?.inputRef.current, editTitleRef.current, titleRef.current])

  // 编辑器标题变化更新左边树形列表数据
  const debouncedFunction = useRef(
    debounce(() => {
      setUpdateTreeList(Date.now())
    }, 500),
  ).current

  // 监听编辑器内容变化
  useEffect(() => {
    setIsSave((data) => {
      return {
        ...data,
        isEditContentSave: false,
        date: Date.now(),
      }
    })
  }, [editContentRef.current])

  useEffect(() => {
    events.on(ACTIONS.FROM_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_REWRITE_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_TRANS_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_TOOLS_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_CHATGPT_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.FROM_REDUCE_CLICK_EDITOR, editorHandle)
    events.on(ACTIONS.ADD_EDITOR_COMMENT, editorHandle)
    events.on(ACTIONS.SAVE_PIC_EDITOR, editorSavePicHandle)
    events.on(ACTIONS.SAVE_TASKLIST_EDITOR, editorSaveTaskListHandle)
    events.on(ACTIONS.MOD_S_SAVE, handleModS)
    events.on(ACTIONS.MOD_P_SAVE, handleModP)
    return () => {
      events.off(ACTIONS.FROM_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_REWRITE_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_TRANS_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_TOOLS_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_CHATGPT_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_CONTINUE_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.FROM_REDUCE_CLICK_EDITOR, editorHandle)
      events.off(ACTIONS.ADD_EDITOR_COMMENT, editorHandle)
      events.off(ACTIONS.SAVE_PIC_EDITOR, editorSavePicHandle)
      events.off(ACTIONS.SAVE_TASKLIST_EDITOR, editorSaveTaskListHandle)
      events.off(ACTIONS.MOD_S_SAVE, handleModS)
      events.off(ACTIONS.MOD_P_SAVE, handleModP)
    }
  }, [])

  // 监测用户在线状态并排序
  useEffect(() => {
    if (
      !yjsEditorCoordinationInfo.clientID ||
      !yjsEditorCoordinationInfo.employeeList.employeeList.length
    )
      return

    // 当前用户状态
    let currentUserStatus = yjsEditorCoordinationInfo.isOnline.find(
      (item) => item.id === yjsEditorCoordinationInfo.userInfo.id,
    )

    // 监测当前用户是否掉线
    if (!currentUserStatus?.online && currentUserStatus?.id)
      setIsTimedOutRefreshVisible(true)

    dispatch({
      type: 'yjs-editor-coordination-info',
      payload: {
        yjsEditorCoordinationInfo: onlineUserSort(yjsEditorCoordinationInfo),
      },
    })

    // console.log(yjsEditorCoordinationInfo)
  }, [JSON.stringify(yjsEditorCoordinationInfo.isOnline)])

  // 判断协同者权限
  const isSelf = (userInfo, employeeList) => {
    return employeeList.employeeList.find((item) => {
      if (item.employeeId === userInfo.id) return item
    })
  }

  const onlineUserSort = (data) => {
    // 创建一个映射，通过 id 来迅速找到对应的 online 状态
    const onlineMap = data.isOnline.reduce((map, obj) => {
      map[obj.id] = obj.online
      return map
    }, {})

    // 对 employeeList 进行排序
    data.employeeList.employeeList.sort((a, b) => {
      if (onlineMap[b?.employeeId] === onlineMap[a?.employeeId]) {
        return a?.employeeId - b?.employeeId // 使用数字比较
      }
      return onlineMap[b?.employeeId] - onlineMap[a?.employeeId]
    })

    // 再依据 employeeList 的新顺序重建 isOnline 列表
    data.isOnline.sort((a, b) => {
      const aIsOnline = onlineMap[a.id]
      const bIsOnline = onlineMap[b.id]
      if (aIsOnline === bIsOnline) {
        return a.id - b.id // 使用数字比较
      }
      return bIsOnline - aIsOnline
    })

    return data
  }

  const getDocDetailHandle = async () => {
    if (!id) return
    const res = await (!GetQueryValue('teamUuid')
      ? getDocumentDetail({
          uuid: id,
        })
      : GetProjectShareContent({
          uuid: id,
          teamUuid: GetQueryValue('teamUuid'),
        }))
    if (res.data.success) {
      if (res.data.data.isPublish == 1) {
        events.emit(ACTIONS.EDITPAGE_APPROVASTATUS)
      }
      // 协同走的另一个接口，包装成了数组
      if (!GetQueryValue('teamUuid')) {
        titleRef.current = res.data.data.title
        editTitleRef.current = res.data.data.title
        contentRef.current = res.data.data.content
        setIsCollect(res.data.data.isCollect)
        setDocTitle(res.data.data.title)
        setDocContent(res.data.data.content)
        setGmtModifyDate(res.data.data.gmtModifyDate)
        setIsFullDisplay(res.data.data.widthShow == EditWidthType.expand)
      } else {
        titleRef.current = res.data.data[0].title
        editTitleRef.current = res.data.data[0].title
        contentRef.current = res.data.data[0].content
        setIsCollect(res.data.data[0].isCollect)
        setDocTitle(res.data.data[0].title)
        setDocContent(res.data.data[0].content)
        setGmtModifyDate(res.data.data[0].gmtModified)
        setIsFullDisplay(res.data.data[0].widthShow == EditWidthType.expand)
      }
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: res.data.msg,
      })
    }
    if (document) {
      if (!GetQueryValue('teamUuid')) {
        document.title =
          res.data.data && res.data.data.title
            ? res.data.data.title + ' - ' + switchDocTitle()
            : ''
      } else {
        document.title =
          res.data.data.length && res.data.data[0].title
            ? res.data.data[0].title + ' - ' + switchDocTitle()
            : ''
      }
    }
  }

  const changeTool = async (data) => {
    if (data.type == DocBtnType.attribute) {
      setType(data.type)
      showModal('AttributeModal', {
        title: '属性',
        onIsVisible: (visible) => {
          setType(null)
          // setShowDocEdit(false)
          // setShowRight(false)
        },
        // onCloseModal:
      })
      return
    }
    if (data.type == DocBtnType.version) {
      setType(data.type)
      showModal('ReviewModal', {
        title: '回顾',
        uuid: GetQueryValue('uuid'),
        onIsVisible: (visible) => {
          setType(null)
          // setShowDocEdit(false)
          // setShowRight(false)
        },
      })
      return
    }
    if (data.type == type) {
      setType(null)
      setShowDocEdit(false)
      setShowRight(false)
      localStorage.setItem('showRight', 'false')
      return
    }
    setType(data.type)
    typeRef.current = data.type
    setCurrent(data)
    setTimeout(function () {
      setUpRightZ(true)
    }, 500)
    setShowDocEdit(true)
    setShowRight(true)
    localStorage.setItem('showRight', 'true')
    setIsFullDisplay(false)
    setSelectContent('')
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
  }

  const closeTool = async () => {
    setShowDocEdit(false)
    setShowRight(false)
    localStorage.setItem('showRight', 'false')
    setUpRightZ(false)
    const res = await getDocumentDetail({
      uuid: id,
    })
    if (res.data.success) {
      setIsFullDisplay(res.data.data.widthShow == EditWidthType.expand)
    }

    setType(null)
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  const changeTitleHandle = (title) => {
    editTitleRef.current = title
    setIsEditTitle(true)
  }

  const editContentHandle = (content) => {
    editContentRef.current = content
    setIsAutoSave(true)
    if (timerContent) clearTimeout(timerContent)
    if (autoTimeContent) clearTimeout(autoTimeContent)
    timerContent = setTimeout(() => {
      saveDocHandle(
        content,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.SilentSave,
      )
    }, 2000)
    autoTimeContent = setTimeout(() => {
      saveDocHandle(
        content,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        ApprovalStatus.ToSubmit,
        false,
        null,
        DocVersionType.AutoSave,
      )
    }, 1000 * 60 * 5)
  }

  const editContentHtmlHandle = (content) => {
    editContentRef.current = content
    // setIsContentHtml(true)
    if (isContentHtmlTimer) clearTimeout(isContentHtmlTimer)
    isContentHtmlTimer = setTimeout(() => {
      setIsContentHtml(true)
    }, 1000)
  }

  //保存
  const saveDocHandle = async (
    content,
    title,
    approvalStatus,
    isBack = false,
    message,
    docVersionType,
    widthShow = isFullDisplay ? EditWidthType.expand : EditWidthType.standard,
  ) => {
    if (
      docVersionType === DocVersionType.ManualSave ||
      docVersionType === DocVersionType.PublishSave
    ) {
      events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    }
    if (!content && !title) return
    if (!isAutoSave) {
      const res = await saveDocument({
        title: !!title ? title : '未命名',
        approvalStatus: approvalStatus,
        content: content ? content : '',
        docType: 0,
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        fileLevel: 1,
        fileType: Number(GetQueryValue('fileType'))
          ? Number(GetQueryValue('fileType'))
          : 0,
        docStatus: 0,
        uuid: uuidRef.current ? uuidRef.current : GetQueryValue('uuid'),
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        docVersionType: docVersionType,
        docWords: isEditContent
          ? contentCount
          : String(content).replace(/<[^>]+>/g, '').length,
        widthShow,
        teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
      })
      if (res.data.success) {
        //发布文档状态
        if (docVersionType === DocVersionType.PublishSave) {
          events.emit(ACTIONS.EDITPAGE_APPROVASTATUS)
        }
        setIsAutoSave(false)
        setIsSave((data) => {
          return {
            isEditContentSave: true,
            isEditTitleSave: true,
            date: Date.now(),
          }
        })
        uuidRef.current = res.data.data
        {
          message &&
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: message,
            })
        }
      }
      if (!res.data.success) {
        setIsAutoSave(false)
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: res.data.msg,
        })
      }
      setUuid(res.data.data)
    }

    if (!GetQueryValue('uuid')) {
      const urlQuery = removeEmpty({
        uuid: uuidRef.current,
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        from: GetQueryValue('from') ? GetQueryValue('from') : '',
        title: GetQueryValue('title') ? GetQueryValue('title') : '',
        collectType: GetQueryValue('collectType')
          ? GetQueryValue('collectType')
          : '',
        collectDocUuid: GetQueryValue('collectDocUuid')
          ? GetQueryValue('collectDocUuid')
          : '',
        collectFoldUuid: GetQueryValue('collectFoldUuid')
          ? GetQueryValue('collectFoldUuid')
          : '',
        teamUuid: GetQueryValue('teamUuid') ? GetQueryValue('teamUuid') : '',
        permission: GetQueryValue('permission')
          ? GetQueryValue('permission')
          : '',
      }) as any
      history.replace({
        pathname: '/documentEdit',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      // if (!isBack && approvalStatus != 1) {
      //   location.reload()
      // }
    }

    if (
      approvalStatus == ApprovalStatus.ToSubmit &&
      isBack &&
      GetQueryValue('uuid') &&
      !isAutoSave
    ) {
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
      }) as any
      history.replace({
        pathname: `${
          GetQueryValue('from') ? '/' + GetQueryValue('from') : '/'
        }`,
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      location.reload()
    }
  }

  //提交保存
  const submitDocHandle = (approvalStatus = ApprovalStatus.ToSubmit) => {
    if (!GetQueryValue('uuid')) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '该文档不存在',
      })
      return
    }
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)

    const content = editContentRef.current
      ? editContentRef.current
      : contentRef.current
    const title = editTitleRef.current ? editTitleRef.current : titleRef.current

    if (!content && !title) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请先输入内容',
      })
      return
    }
    if (approvalStatus == ApprovalStatus.PendingApproval) {
      showModal('ConfirmModal', {
        title: '  发布文档',
        content: `是否确认发布文档？`,
        onConfirm: () => {
          saveDocHandle(
            editContentRef.current
              ? editContentRef.current
              : contentRef.current,
            editTitleRef.current ? editTitleRef.current : titleRef.current,
            approvalStatus,
            false,
            '发布成功',
            DocVersionType.PublishSave,
          )
        },
      })
    } else {
      setIsAutoSave(true)
      saveDocHandle(
        editContentRef.current ? editContentRef.current : contentRef.current,
        editTitleRef.current ? editTitleRef.current : titleRef.current,
        approvalStatus,
        false,
        '保存成功',
        DocVersionType.ManualSave,
      )
    }
  }

  const goBack = (
    isImmerseDisplay,
    teamUuidValue?: string,
    permissionValue?: string | number,
  ) => {
    let content = editContentRef.current
      ? editContentRef.current
      : contentRef.current
    let title = editTitleRef.current ? editTitleRef.current : titleRef.current
    if (isImmerseDisplay) {
      handle.exit
      setIsImmerseDisplay(false)
    } else if (!isAutoSave || employeePermission === 3) {
      // if (GetQueryValue('uuid') && (content || title)) {
      //   saveDocHandle(
      //     editContentRef.current ? editContentRef.current : contentRef.current,
      //     editTitleRef.current ? editTitleRef.current : titleRef.current,
      //     ApprovalStatus.ToSubmit,
      //     true,
      //     // approvalStatus == ApprovalStatus.ToSubmit ? null : '发布文档成功',
      //     null,
      //     DocVersionType.ManualSave,
      //     // approvalStatus == ApprovalStatus.ToSubmit
      //     //   ? DocVersionType.ManualSave
      //     //   : DocVersionType.PublishSave,
      //   )
      //   return
      // }
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
        treeUuid:
          GetQueryValue('uuid') && localStorage.getItem('tabSwitch') == 'tree'
            ? GetQueryValue('uuid')
            : '',
      }) as any

      // 返回时如果编辑器含有teamUuid、permissionValue，返回带上teamUuid、permissionValue
      if (teamUuidValue) urlQuery.teamUuid = teamUuidValue
      if (permissionValue) urlQuery.permission = permissionValue

      history.push({
        pathname: `${
          GetQueryValue('from') ? '/' + GetQueryValue('from') : '/'
        }`,
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
      location.reload()
    } else {
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: '正在保存',
      })

      // !editTitleRef.current && window.history.back()
    }
  }

  const getContentDocHandle = (contentStructure) => {
    const contentArr = contentStructure.doc.content
    const textArray = []
    let nodeIndex = -1
    let headerNum = 0
    contentArr.forEach((obj, index) => {
      if (
        (obj.type === 'paragraph' || obj.type === 'header') &&
        !!obj.content
      ) {
        let text = ''
        nodeIndex += 1
        obj.content.forEach((contentObj) => {
          if (contentObj.type === 'text') {
            text = text + contentObj.text
          } else if (contentObj.type === 'link') {
            text = text + contentObj.attrs.text.trim()
          }
        })
        textArray.push({ text: text, nodeIndex: nodeIndex })
      } else if (obj.type === 'ordered_list') {
        let text = ''
        obj.content.forEach((listItem) => {
          listItem?.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
              nodeIndex += 1
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (obj.type === 'bullet_list') {
        let text = ''
        obj.content.forEach((listItem) => {
          listItem.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
              nodeIndex += 1
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (obj.type === 'block_quote') {
        let text = ''

        obj.content.forEach((listItem) => {
          listItem.content?.forEach((contentObj) => {
            if (contentObj.type === 'text') {
              text = contentObj.text
            } else if (contentObj.type === 'link') {
              text = text + contentObj.attrs.text.trim()
            }
            nodeIndex += 1
            textArray.push({ text: text, nodeIndex: nodeIndex })
          })
        })
      } else if (!obj.content) {
        nodeIndex += 1
      }
    })
    contentArr.forEach((obj) => {
      if (obj.type === 'header') {
        headerNum += 1
      }
    })
    if (headerNum > 0) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
    setDefaultContent(textArray)
  }

  const editorHandle = (payload) => {
    if (payload.barActiveIndex) {
      setType(payload.barActiveIndex)
      setShowRight(true)
      localStorage.setItem('showRight', 'true')
      typeRef.current = payload.barActiveIndex
      setShowDocEdit(true)
      setCanEdit(true)
    }
    setSelectContent(payload.setSelectContent)
    setNodeInfo(payload.nodeInfo)
  }

  const fullDisplayHandle = () => {
    setShowRight(false)
    localStorage.setItem('showRight', 'false')
    setShowDocEdit(false)
    setUpRightZ(false)
    setIsFullDisplay(!isFullDisplay)
    localStorage.setItem('isFullDisplay', isFullDisplay ? 'false' : 'true')
    saveDocHandle(
      editContentRef.current ? editContentRef.current : contentRef.current,
      editTitleRef.current ? editTitleRef.current : titleRef.current,
      ApprovalStatus.ToSubmit,
      false,
      '保存成功',
      DocVersionType.ManualSave,
      isFullDisplay ? EditWidthType.standard : EditWidthType.expand,
    )
    setType(null)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
  }

  const keyMap = {
    SAVE_RELEASE: ['command+p', 'ctrl+p'],
    SHOW_KEY_MAP: ['command+h', 'ctrl+h'],
    SAVE_DOC: ['command+s', 'ctrl+s'],
    UNDO: ['command+z', 'ctrl+z'],
    REDO: ['command+shift+z', 'ctrl+shift+z'],
    ESC: ['esc'],
  }
  const saveKeyHandle = (e) => {
    e.preventDefault()
    if (id) {
      events.emit(ACTIONS.MOD_S_SAVE)
    }
  }

  const showKeyMapHandle = (e) => {
    e.preventDefault()
    showModal('KeyMapModal', {
      title: '快捷键',
    })
  }

  const undoKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_Z_UNDO)
  }

  const redoKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_Z_REDO)
  }

  const releaseKeyHandle = (e) => {
    e.preventDefault()
    events.emit(ACTIONS.MOD_P_SAVE)
  }

  // Mod + s 保存回调
  const handleModS = () => {
    if (isAutoSave) return
    saveDocHandle(
      // editContentRef.current ? editContentRef.current : contentRef.current,
      editContentRef.current,
      editTitleRef.current ? editTitleRef.current : titleRef.current,
      ApprovalStatus.ToSubmit,
      false,
      '保存成功',
      DocVersionType.ManualSave,
    )
  }

  const handleModP = () => {
    if (isAutoSave) return
    showModal('ConfirmModal', {
      title: '  发布文档',
      content: `确认是否发布文档？`,
      onConfirm: () => {
        saveDocHandle(
          editContentRef.current ? editContentRef.current : contentRef.current,
          editTitleRef.current ? editTitleRef.current : titleRef.current,
          ApprovalStatus.PendingApproval,
          false,
          '发布成功',
          DocVersionType.PublishSave,
        )
      },
    })
  }

  const closeToolHandle = (e) => {
    e.preventDefault()
    closeTool()
  }

  const handlers = {
    SAVE_RELEASE: throttle(releaseKeyHandle, 5000),
    SHOW_KEY_MAP: showKeyMapHandle,
    UNDO: undoKeyHandle,
    REDO: redoKeyHandle,
    SAVE_DOC: throttle(saveKeyHandle, 5000),
    ESC: closeToolHandle,
  }

  const editorSavePicHandle = () => {
    saveDocHandle(
      editContentRef.current ? editContentRef.current : contentRef.current,
      editTitleRef.current ? editTitleRef.current : titleRef.current,
      ApprovalStatus.ToSubmit,
      false,
      null,
      DocVersionType.AutoSave,
    )
  }

  const editorSaveTaskListHandle = (payload) => {
    editContentRef.current = payload.content
    setIsAutoSave(true)
    saveDocHandle(
      editContentRef.current ? editContentRef.current : contentRef.current,
      editTitleRef.current ? editTitleRef.current : titleRef.current,
      ApprovalStatus.ToSubmit,
      false,
      null,
      DocVersionType.AutoSave,
    )
  }

  const handle = useFullScreenHandle()

  const reportChange = useCallback(
    (state, handle) => {
      setIsImmerseDisplay(state)
      getDocDetailHandle()
    },

    [handle.active],
  )

  const returnTopHandle = () => {
    // events.emit(ACTIONS.RETURN_TOP_EDITOR)
    const immerseDisplayBox = document.querySelector(
      '#immerseDisplayContent',
    ) as HTMLElement
    setTimeout(() => {
      immerseDisplayBox?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 300)
  }

  const toolLoadingHandle = (toolIsLoading) => {
    setToolIsLoading(toolIsLoading)
  }

  const writeToolLoadingHandle = (toolIsLoading, isHasList) => {
    setWriteToolIsLoading(toolIsLoading)
    setIsHasRewriteList(isHasList)
  }

  const transToolLoadingHandle = (toolIsLoading, isHasList) => {
    setTransToolIsLoading(toolIsLoading)
    setIsHasTransList(isHasList)
  }

  const correctToolLoadingHandle = (toolIsLoading, isHasList) => {
    setCorrectToolIsLoading(toolIsLoading)
    setIsHasCorrectList(isHasList)
  }

  const continueToolLoadingHandle = (toolIsLoading, isHasList) => {
    setContinueToolIsLoading(toolIsLoading)
    setIsHasContinueList(isHasList)
  }

  const directivesToolLoadingHandle = (toolIsLoading, isHasList) => {
    setDirectivesToolIsLoading(toolIsLoading)
    setIsHasDirectivesList(isHasList)
  }

  const reduceToolLoadingHandle = (toolIsLoading, isHasList) => {
    setReduceToolIsLoading(toolIsLoading)
    setIsHasReduceList(isHasList)
  }

  const refreshChangeType = (type) => {
    setType(type)
    typeRef.current = type
  }

  // 回顾历史版本回调
  const versionModalHandle = () => {
    showModal('ReviewModal', {
      title: '回顾',
      uuid: GetQueryValue('uuid'),
      onIsVisible: (visible) => {
        setType(null)
      },
    })
  }

  const attributeModalHandle = () => {
    showModal('AttributeModal', {
      title: '文档属性',
      onIsVisible: (visible) => {
        setType(null)
        // setShowDocEdit(false)
        // setShowRight(false)
      },
      // onCloseModal:
    })
  }

  const componentRef = React.useRef(null)

  const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null)

  const [loading, setLoading] = React.useState(false)
  const [text, setText] = React.useState('Some cool text from the parent')
  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called')
    setIsShowPrint(false) // tslint:disable-line no-console
  }, [])

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called') // tslint:disable-line no-console
  }, [])

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called') // tslint:disable-line no-console
    setLoading(true)
    setIsShowPrint(true)
    setText('Loading new text...')

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setLoading(false)
        setText('New, Updated Text!')
        resolve()
      }, 2000)
    })
  }, [setLoading, setText])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const printHandle = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  })

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <Tour
          open={open}
          onFinish={() => localStorage.setItem('guide', 'false')}
          onClose={() => {
            setOpen(false)
            localStorage.setItem('guide', 'false')
          }}
          steps={steps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      </ConfigProvider>
      {isImmerseDisplay ? (
        <FullScreen
          handle={handle}
          onChange={reportChange}
          className={styles[theme]}>
          <Sidebar
            sidebarRef={sidebarRef}
            getDocDetailHandle={getDocDetailHandle}
            updateTreeList={updateTreeList}
            content={
              <div
                className={`${showDocEdit ? styles.twoCol : ''} ${
                  styles.editor_page
                } `}>
                <div
                  className={styles.page_left}
                  style={{ width: isImmerseDisplay && '100vw' }}>
                  <div className={styles.page_header}>
                    <DocHeader
                      theme={theme}
                      ref={ref1}
                      title={editTitleRef.current}
                      content={
                        editContentRef.current
                          ? editContentRef.current
                          : contentRef.current
                      }
                      isCollect={isCollect}
                      onClickSave={submitDocHandle}
                      onChangeTitle={changeTitleHandle}
                      isSave={isSave}
                      isAutoSave={isAutoSave}
                      uuid={id}
                      onExitImmerseDisplay={handle.exit}
                      gmtModifyDate={gmtModifyDate}
                      isFullDisplay={isFullDisplay}
                      onFullDisplay={fullDisplayHandle}
                      isImmerseDisplay={isImmerseDisplay}
                      onAttributeModal={attributeModalHandle}
                      onVersionModal={versionModalHandle}
                      yjsEditorCoordinationInfo={yjsEditorCoordinationInfo}
                      userInfo={userInfo}
                      employeePermission={employeePermission}
                    />
                  </div>
                  <div
                    className={styles.page_left_content}
                    id="immerseDisplayContent">
                    {/* <Writing
                  isShare={true}
                  isComment={true}
                  docContent={docContent}
                /> */}
                    <div className={`${styles.wp}`} id="scroll-content">
                      <div
                        id={`collectContent`}
                        className={`${
                          isFullDisplay
                            ? styles.immerseDisplayContent
                            : styles.collectContent
                        }`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: editContentRef.current
                              ? editContentRef.current
                              : contentRef.current,
                          }}></div>
                      </div>
                    </div>
                    {showNav && isContentHtml && (
                      <TitleShareNav
                        theme={theme}
                        position={'right'}
                        isShare={true}
                        docContent={
                          editContentRef.current
                            ? editContentRef.current
                            : contentRef.current
                        }
                      />
                    )}
                  </div>
                </div>

                <div className={styles.returnTopBtn} onClick={returnTopHandle}>
                  <div className={styles.icon}>
                    <IconArrowUp />
                  </div>
                </div>
              </div>
            }
          />
        </FullScreen>
      ) : (
        <FullScreen
          handle={handle}
          onChange={reportChange}
          className={styles[theme]}>
          <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
            <Sidebar
              sidebarRef={sidebarRef}
              getDocDetailHandle={getDocDetailHandle}
              updateTreeList={updateTreeList}
              content={
                <div
                  className={`${showDocEdit ? styles.twoCol : ''} ${
                    styles.editor_page
                  }`}>
                  <div className={styles.page_left}>
                    <div className={styles.page_header}>
                      <DocHeader
                        theme={theme}
                        ref={ref1}
                        title={editTitleRef.current}
                        content={docContent}
                        isCollect={isCollect}
                        onClickSave={submitDocHandle}
                        onChangeTitle={changeTitleHandle}
                        isSave={isSave}
                        isAutoSave={isAutoSave}
                        uuid={id}
                        onGoBack={goBack}
                        onPrint={printHandle}
                        showRight={showRight}
                        gmtModifyDate={gmtModifyDate}
                        isFullDisplay={isFullDisplay}
                        isImmerseDisplay={isImmerseDisplay}
                        onFullDisplay={fullDisplayHandle}
                        onExitImmerseDisplay={handle.exit}
                        onImmerseDisplay={handle.enter}
                        onAttributeModal={attributeModalHandle}
                        onVersionModal={versionModalHandle}
                        yjsEditorCoordinationInfo={yjsEditorCoordinationInfo}
                        userInfo={userInfo}
                        employeePermission={employeePermission}
                      />
                    </div>
                    <div className={styles.page_left_content}>
                      {JSON.stringify(userInfo) !== '{}' &&
                      JSON.stringify(yjsEditorCoordinationInfo.userInfo) !==
                        '{}' &&
                      (!GetQueryValue('uuid') ||
                        (employeeList.total &&
                          yjsEditorCoordinationInfo.permission &&
                          yjsEditorCoordinationInfo.employeeList.employeeList
                            .length)) ? (
                        <>
                          {/* {EditorInitializationLoading.close()} */}
                          {!isTimedOutRefreshVisible ? (
                            <WritingContent
                              theme={theme}
                              ref={ref2}
                              showTitle={true}
                              isAutoSave={isAutoSave}
                              docContent={docContent}
                              editContent={editContentHandle}
                              editContentHtml={editContentHtmlHandle}
                              getContentDoc={getContentDocHandle}
                              showRight={showRight}
                              isFullDisplay={isFullDisplay}
                              isDisableEdit={isImmerseDisplay}
                              userInfo={userInfo}
                              yjsEditorCoordinationInfo={
                                yjsEditorCoordinationInfo
                              }
                              uuid={id || uuid}
                              employeePermission={employeePermission}
                            />
                          ) : (
                            <TimedOutRefresh visible={true} />
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {showNav && isContentHtml && (
                        <TitleNav
                          position={'left'}
                          docContent={
                            editContentRef.current
                              ? editContentRef.current
                              : contentRef.current
                          }
                          theme={theme}
                          isAutoSave={isAutoSave}
                          isFullDisplay={isFullDisplay}
                          defaultContent={defaultContent}
                          isContentHtml={isContentHtml}
                        />
                      )}
                    </div>
                    {isShowPrint && (
                      <div style={{ opacity: 0 }}>
                        <div
                          id={`collectContent`}
                          ref={componentRef}
                          className={`${
                            isFullDisplay
                              ? styles.immerseDisplayContent
                              : styles.collectContent
                          }`}>
                          <h1>{docTitle}</h1>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: editContentRef.current
                                ? editContentRef.current
                                : contentRef.current,
                            }}></div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${showDocEdit ? styles.show : ''} ${
                      upRightZ ? styles.upRightZ : ''
                    } ${styles.page_right}`}>
                    <div className={styles.page_right_inner}>
                      <EditPageBox
                        theme={theme}
                        docTitle={docTitle}
                        type={typeRef.current}
                        onClose={closeTool}
                        selectContent={selectContent}
                        isNewDoc={canEdit}
                        nodeInfo={nodeInfo}
                        defaultContent={defaultContent}
                        onChangeType={refreshChangeType}
                        onIsLoading={toolLoadingHandle}
                        onTransToolLoading={transToolLoadingHandle}
                        onWriteToolLoading={writeToolLoadingHandle}
                        onReduceToolLoading={reduceToolLoadingHandle}
                        onCorrectToolLoading={correctToolLoadingHandle}
                        onDirectivesToolLoading={directivesToolLoadingHandle}
                        onContinueToolLoading={
                          continueToolLoadingHandle
                        }></EditPageBox>
                    </div>
                  </div>
                  <div className={styles.page_nav}>
                    <ToolNav
                      theme={theme}
                      ref={ref3}
                      onChangeTool={changeTool}
                      type={type}
                      employeePermission={employeePermission}
                      isHasTransList={isHasTransList}
                      isHasRewriteList={isHasRewriteList}
                      isHasCorrectList={isHasCorrectList}
                      isHasContinueList={isHasContinueList}
                      isHasReduceList={isHasReduceList}
                      isHasDirectivesList={isHasDirectivesList}
                      toolIsLoading={toolIsLoading}
                      transToolIsLoading={transToolIsLoading}
                      reduceToolIsLoading={reduceToolIsLoading}
                      writeToolIsLoading={writeToolIsLoading}
                      correctToolIsLoading={correctToolIsLoading}
                      continueToolIsLoading={continueToolIsLoading}
                      directivesToolIsLoading={directivesToolIsLoading}
                    />
                  </div>
                  <Modal></Modal>
                </div>
              }
            />
          </GlobalHotKeys>
        </FullScreen>
      )}
    </>
  )
}

export default EditorPage
