import { Block, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import palette from '@/helpers/palette'

interface HighlightProps {
  strike: boolean
}

const PLUGIN_NAME = 'highlight'

class HighlightSchema extends Block<HighlightProps> {
  public name = PLUGIN_NAME
  public tagName = () => 'div'

  // 解析DOM，什么数据会被识别为删除线
  public parseDOM = []

  public toDOM = () => {
    return ['div', 0] as DOMOutputSpec
  }
}

const EDITOR_PADDING_LEFT = 10

class Tooltip {
  private dom: HTMLElement
  private visible = false

  constructor(mountEle: HTMLElement) {
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.dom.style.userSelect = 'none'
    this.dom.style.zIndex = '100'
    this.dom.style.backgroundColor = 'white'
    this.dom.style.border = '1px solid var(--color-neutral-3)'
    this.dom.style.borderRadius = '2px'
    this.dom.style.padding = '5px'
    this.dom.style.display = 'none'
    this.dom.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)'
    this.dom.id = 'highlight-tooltip'
    mountEle.appendChild(this.dom)
  }

  public show = (
    left: number,
    top: number,
    correct: string,
    reason: string,
  ) => {
    this.dom.style.left = `${left}px`
    this.dom.style.top = `${top}px`
    this.dom.style.display = 'none'
    this.dom.innerHTML = `
      <div style="margin-bottom: 5px; background-color:${palette.proof};padding:8px 12px; border-radius:4px;color: ${palette.proofBold};width:fit-content">${correct}</div>
      <div style="color:#999999;">${reason}</div>
    `
  }

  public hoverShow = () => {
    this.dom.style.display = 'block'
    this.visible = true
  }

  public hide = () => {
    this.dom.style.display = 'none'
    this.visible = false
  }

  get isVisible() {
    return this.visible
  }
}

class HighlightController extends SylController<HighlightProps> {
  public name = PLUGIN_NAME
  private tooltip: Tooltip
  // current start position of highlight text
  private curPos = -1
  private curHighlightText = ''
  private correctTxt = '我才是正确的'

  constructor(editor: SylApi, props) {
    super(editor, props)
    this.tooltip = new Tooltip(this.editor.root)
    events.on(ACTIONS.ADD_EDITOR_TIP_PROOF, this.highlightAndShowTip)
    events.on(ACTIONS.REMOVE_EDITOR_TIP, this.removeHighlightAndTip)
    events.on(ACTIONS.REPLACE_TO_EDITOR_TIP, this.replaceToEditorTip)
  }

  editorWillUnmount() {
    events.off(ACTIONS.ADD_EDITOR_TIP_PROOF, this.highlightAndShowTip)
    events.off(ACTIONS.REMOVE_EDITOR_TIP, this.removeHighlightAndTip)
    events.off(ACTIONS.REPLACE_TO_EDITOR_TIP, this.replaceToEditorTip)
  }

  public replaceToEditorTip = () => {
    // Get the style of the old text
    const oldTextNode = this.editor.view.state.doc.nodeAt(this.curPos)
    const oldTextStyle = oldTextNode.marks
    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      this.curPos,
      this.curPos + this.curHighlightText.length,
      this.editor.view.state.schema.text(this.correctTxt, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    this.removeHighlightAndTip()
  }

  public removeHighlightAndTip = () => {
    this.editor?.removeShadow('shadow')
  }

  private findTextPositionInEditor = (
    searchText: string,
    beginSentenceOffset: number,
    nodeIndex: number,
    content: string,
  ) => {
    let startPos = -1
    // Find the first instance of the search string in the document

    this.editor.view.state.doc.forEach((node, offset, index) => {
      if (nodeIndex === index) {
        startPos = offset + beginSentenceOffset + 1
      }
    })

    return startPos
  }

  public highlightAndShowTip = (payload) => {
    this.editor?.removeShadow('shadow')
    const searchText = payload.error
    const correctText = payload.correct
    const beginSentenceOffset = payload.beginSentenceOffset
    const nodeIndex = payload.nodeIndex
    const content = payload.content
    const startPos = this.findTextPositionInEditor(
      searchText,
      beginSentenceOffset,
      nodeIndex,
      content,
    )
    console.log(startPos, 'startPos')
    if (startPos === -1) {
      this.tooltip.hide()
      this.curPos = -1
      this.curHighlightText = ''
      this.correctTxt = ''
      return
    } else {
      this.curPos = startPos
      this.curHighlightText = searchText
      this.correctTxt = correctText
    }

    this.editor?.appendShadow(
      {
        index: startPos,
        length: searchText.length,
        attrs: {
          style: `background-color: ${palette.proof}`,
          class: 'highlight',
        },
        spec: {
          key: 'shadow',
        },
      },
      true,
    )

    const {
      top: editorTop,
      left: editorLeft,
      // right: editorRight,
      bottom: editorBottom,
    } = this.editor.root.getBoundingClientRect()
    const { left: headLeft, bottom: headBottom } =
      this.editor.view.coordsAtPos(startPos)

    const computedLeft = headLeft - editorLeft + EDITOR_PADDING_LEFT
    const computedTop = headBottom + 8 - editorTop
    const highlightDiv = document.querySelector('.highlight')
    highlightDiv.addEventListener('mouseenter', () => {
      this.tooltip.hoverShow()
    })
    highlightDiv.addEventListener('mouseleave', () => {
      this.tooltip.hide()
    })

    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)

    if (headBottom > boxHeight) {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    } else {
      setTimeout(() => {
        sylEditorBox.scrollTo({
          top: headBottom - editorTop - 60,
          behavior: 'smooth',
        })
      }, 300)
    }
    if (headBottom < editorBottom || headBottom > editorTop) {
      this.tooltip.show(
        computedLeft,
        computedTop,
        payload.correct,
        payload.reason,
      )
    }
    /* 之前的方法 */
    // if (headBottom > editorBottom || headBottom < editorTop) {
    //   this.editor.view.dom.scrollTo({ top: headBottom - editorTop });
    //   const newBottom = this.editor.view.coordsAtPos(startPos).bottom;
    //   this.tooltip.show(
    //     computedLeft,
    //     newBottom - editorTop + 8,
    //     payload.correct,
    //     payload.reason
    //   );
    // } else {
    //   this.tooltip.show(
    //     computedLeft,
    //     computedTop,
    //     payload.correct,
    //     payload.reason
    //   );
    // }
  }
}

class HighlightPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = HighlightController
  public Schema = HighlightSchema
}

export { HighlightPlugin }
