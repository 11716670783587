import React, { useEffect, useState } from 'react'
// import { useSearchParams } from 'react-router-dom';
import { Input, Message } from '@arco-design/web-react'
import style from './style/tools.module.less'
import {
  IconCopy,
  IconTitleRecommendation,
  IconDictionary,
  IconCheckSentences,
  IconTextSummary,
  IconTextLabel,
  IconKeywords,
  IconDownload,
  IconClose,
} from '@arco-iconbox/react-aidb'
import IconMsgSuccess from '../../assets/newImgs/msg-success.svg'
import CIP from 'react-public-ip'
import Btn from '../Ui/btn'
import { ToolsBtn } from '@/helpers/enums'
import {
  getKeyWords,
  getReveres,
  getSearchSentence,
  getTextAbstarct,
  getTextKey,
  getTitleKey,
  getUserInfo,
} from '@/model/reference'
import Empty from '../Empty'
import copy from 'copy-to-clipboard'
import { GetQueryValue } from '@/utils/common'
import axios from 'axios'
import palette from '@/helpers/palette'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { ExportBtn } from '@/share/config'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { switchProject } from '@/helpers/switchProject'
interface ToolsProps {
  tabs: string[]
  docTitle?: string
  selectContentPop?: string
  setIsLoading?: any
  loading?: any
}

const Tools = React.memo((props: ToolsProps) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const { docTitle, selectContentPop } = props
  const setIsLoading = props.setIsLoading

  const TextArea = Input.TextArea
  const [toolBtnindex, setToolBtnIndex] = useState<any>()
  const [tabIndex, setTabIndex] = useState<any>(0)
  const [Ip, setIp] = useState('')
  const [selectContent, setSelectContent] = useState(selectContentPop)

  useEffect(() => {
    setSelectContent(selectContentPop)
  }, [selectContentPop])

  // 辅助工具按钮btn
  const toolsBtnList = [
    {
      id: ToolsBtn.titleRecommend,
      icon: <IconTitleRecommendation />,
      title: '标题推荐',
    },
    {
      id: ToolsBtn.ReverseDic,
      icon: <IconDictionary />,
      title: '反向词典',
    },
    {
      id: ToolsBtn.SearchSentence,
      icon: <IconCheckSentences />,
      title: '据意查句',
    },
    {
      id: ToolsBtn.TextSummary,
      icon: <IconTextSummary />,
      title: '文本摘要',
    },
    {
      id: ToolsBtn.TextLabel,
      icon: <IconTextLabel />,
      title: '文本标签',
    },
    {
      id: ToolsBtn.KeywordObtain,
      icon: <IconKeywords />,
      title: '关键词抽取',
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      setIp((await CIP.v4()) || '')
    }
    fetchData()
  }, [])

  const trArr = [
    {
      title: '标题',
    },
    {
      title: '置信度',
      width: '10%',
    },
  ]

  const trArr2 = [
    {
      title: '词语',
    },
    {
      title: '置信度',
      width: '10%',
    },
  ]

  const trArr1 = [
    {
      title: '词语',
    },
    {
      title: '词性',
    },
    {
      title: '释义',
    },
  ]

  const trArr3 = [
    {
      title: '语句',
    },
    {
      title: '作者',
      width: '15%',
    },
    {
      title: '出处',
    },
  ]
  const trArr4 = [
    {
      title: '语句',
    },
    {
      title: '释义',
    },
    {
      title: '出处',
    },
  ]
  const trArr5 = [
    {
      title: '语句',
    },
    {
      title: '关键词',
    },
    {
      title: '总结',
    },
  ]
  const [contentArr, setContentArr] = useState([])
  const [contentArr1, setContentArr1] = useState([])
  const [contentArr3, setContentArr3] = useState([])
  const [contentArr4, setContentArr4] = useState([])
  const [contentArr5, setContentArr5] = useState([])
  const [contentArr6, setContentArr6] = useState([])
  const [summary, setSummary] = useState('')

  const toolBtnHandle = (id) => {
    if (selectContent?.length == undefined || selectContent?.length < 1) {
      return
    } else {
      setToolBtnIndex(id)
      if (!selectContent) return
      switch (id) {
        case ToolsBtn.KeywordObtain:
          setIsLoading(true)
          getKeyWords({
            num: '5',
            text: [selectContent],
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr(res.data.data.results)
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case ToolsBtn.TextSummary:
          setIsLoading(true)
          getTextAbstarct({
            content: selectContent,
            maxSummaryLen: 200,
            title: docTitle,
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setSummary(res.data.data.summary)
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case ToolsBtn.TextLabel:
          setIsLoading(true)
          getTextKey({
            content: selectContent,
            title: docTitle,
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr(res.data.data.items)
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case ToolsBtn.titleRecommend:
          setIsLoading(true)
          getTitleKey({
            doc: selectContent,
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr(res.data.data.referenceTitles)
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case ToolsBtn.ReverseDic:
          setIsLoading(true)
          getReveres({
            ip: Ip,
            query: selectContent,
            uuid: GetQueryValue('uuid'),
            userAgent: navigator.userAgent,
            num: 10,
            mode: 'ZhZh',
            isFilter: 1,
          })
            .then((res) => {
              setContentArr1(res.data.data?.slice(0, 10))
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case ToolsBtn.SearchSentence:
          setIsLoading(true)
          getSearchSentence({
            query: selectContent,
            type: '现',
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr3(res.data.data?.quotes)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          getSearchSentence({
            query: selectContent,
            type: '古',
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr4(res.data.data?.quotes)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          getSearchSentence({
            query: selectContent,
            type: '谚',
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr5(res.data.data.quotes)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          getSearchSentence({
            query: selectContent,
            type: '歇',
            uuid: GetQueryValue('uuid'),
          })
            .then((res) => {
              setContentArr6(res.data.data.quotes)
              setIsLoading(false)
            })
            .catch(() => {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: '输出结果错误',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        // 添加更多的case语句来处理其他id的情况
        default:
          // 处理未匹配到的id情况
          break
      }
    }
  }

  const changeSelectContent = (value) => {
    setSelectContent(value)
  }
  const [isVip, setIsVip] = useState(false)
  useEffect(() => {
    getUserInfo({ uid: '' }).then((res) => {
      const vipEndDate = new Date(res.data.data.vipEndDate)
      const currentDate = new Date()

      if (currentDate > vipEndDate) {
        setIsVip(false)
      } else {
        setIsVip(true)
      }
    })
  }, [])

  function copyContent(tit) {
    copy(tit)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }
  const wordCount = localStorage.getItem('wordCount')

  const exportHandle = (type, interfaceName) => {
    if (Number(localStorage.getItem('wordCount')) == 0) {
      return
    } else {
      if (!isVip && !switchProject('DFZ')) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '请开通Vip会员，方可导出',
        })
        return
      } else {
        setIsLoading(true)
        const uuid = GetQueryValue('uuid')
        axios
          .post(
            `/api/api-aidb/document/${interfaceName}`,
            { uuid, docVersionId: 0 },
            { responseType: 'blob' },
          )
          .then((res) => {
            const contentDisposition = res.headers['content-disposition']
            const match = contentDisposition.match(
              /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
            )

            let filename
            if (match && match[1]) {
              filename = decodeURIComponent(match[1])
            } else {
              filename = `report.${type}`
            }

            const fileData = new Blob([res.data], {
              type: 'application/msword',
            })
            const fileURL = URL.createObjectURL(fileData)
            const link = document.createElement('a')
            link.href = fileURL
            link.download = filename
            link.click()
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: `导出 ${type} 成功`,
            })
          })
          .catch(() => {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: `导出 ${type} 错误`,
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  return (
    <div className={`${style['doc-box']} ${style[theme]}`}>
      {/* 导出工具 */}
      <div className={style['doc-tab']}>
        <div
          className={`${style['item']} ${
            tabIndex === 0 ? style['active'] : ''
          } ''`}
          onClick={() => setTabIndex(0)}>
          辅助工具
        </div>
        <div
          className={`${style['item']} ${
            tabIndex === 1 ? style['active'] : ''
          } ''`}
          onClick={() => setTabIndex(1)}>
          导出工具
        </div>
      </div>
      {/*辅助工具*/}
      {tabIndex === 0 && (
        <div>
          <div className={style['textarea-box']}>
            <h3>文本输入框：</h3>
            <TextArea
              defaultValue={selectContent}
              value={selectContent}
              placeholder=""
              style={{
                minHeight: 120,
                width: '100%',
                borderRadius: '4px',
                padding: '5px 10px',
                fontSize: '14px',
              }}
              onChange={changeSelectContent}
            />
          </div>
          <div className={style['type-list']}>
            {toolsBtnList.map((item) => {
              return (
                <div
                  className={`${style['type-item']} ${
                    toolBtnindex === item.id ? style['active'] : ''
                  }`}
                  style={{
                    ...(selectContent?.length < 1 && {
                      opacity: 0.5,
                      cursor: 'default',
                    }),
                    ...(selectContent?.length === undefined
                      ? { opacity: 0.5, cursor: 'default' }
                      : {}),
                  }}
                  key={item.id}
                  onClick={() => toolBtnHandle(item.id)}>
                  <div className={style['icon']}>{item.icon}</div>
                  <div className={style['name']}>{item.title}</div>
                </div>
              )
            })}
          </div>
          {/*标题推荐*/}
          {toolBtnindex === ToolsBtn.titleRecommend && (
            <ResultWrapper
              leftText={'推荐列表（置信度数值越大越相关）'}
              tr={trArr}
              content={contentArr}
              type={toolBtnindex}></ResultWrapper>
          )}
          {/*反向词典*/}
          {toolBtnindex === ToolsBtn.ReverseDic && (
            <ResultWrapper
              leftText={'推荐列表（置信度数值越大越相关）'}
              tr={trArr1}
              contentDic={contentArr1}
              type={toolBtnindex}></ResultWrapper>
          )}
          {/*据意查句*/}
          {toolBtnindex === ToolsBtn.SearchSentence && (
            <div style={{ height: 'calc(96vh - 455px)', overflow: 'auto' }}>
              <ResultWrapper
                // leftText={'推荐列表（置信度数值越大越相关）'}
                rightText={'现代类'}
                tr={trArr3}
                contentProverb={contentArr3}
                type={toolBtnindex}></ResultWrapper>
              <ResultWrapper
                // leftText={'推荐列表（置信度数值越大越相关）'}
                rightText={'古诗文类'}
                tr={trArr4}
                contentProverb={contentArr4}
                type={toolBtnindex}></ResultWrapper>
              <ResultWrapper
                // leftText={'推荐列表（置信度数值越大越相关）'}
                rightText={'熟（谚）语类'}
                tr={trArr4}
                contentProverb={contentArr5}
                type={toolBtnindex}></ResultWrapper>
              <ResultWrapper
                // leftText={'推荐列表（置信度数值越大越相关）'}
                rightText={'歇后语'}
                tr={trArr5}
                contentProverb={contentArr6}
                type={toolBtnindex}></ResultWrapper>
            </div>
          )}
          {/*文本摘要*/}
          {toolBtnindex === ToolsBtn.TextSummary && (
            <div className={style['output-result']}>
              <div className={style['output-result-inner']}>
                <div className={style['title']}>
                  <h3>输出结果：</h3>
                </div>
                <div className={style['tit']}>
                  <TextArea
                    placeholder=""
                    style={{
                      minHeight: 120,
                      width: '100%',
                      borderRadius: '4px',
                      padding: '5px 10px',
                      fontSize: '14px',
                    }}
                    value={summary}
                  />
                  {summary && (
                    <div
                      className={style['icon1']}
                      onClick={() => copyContent(summary)}>
                      <IconCopy useCurrentColor={true} />
                      <span
                        style={{
                          fontSize: '14px',
                          verticalAlign: 'text-top',
                          marginLeft: '5px',
                        }}>
                        复制
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/*文本标签*/}
          {toolBtnindex === ToolsBtn.TextLabel && (
            <ResultWrapper
              leftText={'推荐列表（置信度数值越大越相关）'}
              tr={trArr2}
              content={contentArr}
              type={toolBtnindex}></ResultWrapper>
          )}
          {/*关键词抽取*/}
          {toolBtnindex === ToolsBtn.KeywordObtain && (
            <ResultWrapper
              leftText={'推荐列表（置信度数值越大越相关）'}
              tr={trArr2}
              content={contentArr}
              type={toolBtnindex}></ResultWrapper>
          )}
          {!toolBtnindex && (
            <ResultWrapper
              content={contentArr}
              type={toolBtnindex}></ResultWrapper>
          )}
        </div>
      )}
      {/*导出工具*/}

      {tabIndex === 1 && (
        <div className={style.export_btn}>
          {/* <div className={style.btn}>
            <div className={style.text}>导出写作报告</div>
            <div className={style.icon}><IconDownload /></div>
          </div> */}
          {ExportBtn.map(({ key, title, type, interfaceName }) => {
            return (
              <div
                key={key}
                className={style.btn}
                onClick={() => exportHandle(type, interfaceName)}
                style={{
                  ...(Number(wordCount) === 0 && {
                    opacity: 0.5,
                    cursor: 'default',
                  }),
                }}>
                <div className={style.text}>{title}</div>
                <div className={style.icon}>
                  <IconDownload />
                </div>
              </div>
            )
          })}

          {/* <div
            className={style.btn}
            onClick={getPdf}
            style={{
              ...(Number(wordCount) === 0 && {
                opacity: 0.5,
                cursor: 'default',
              }),
            }}>
            <div className={style.text}>导出 Pdf</div>
            <div className={style.icon}>
              <IconDownload />
            </div>
          </div> */}
        </div>
      )}
    </div>
  )
})

interface content {
  title: string
  score: string
  word: string
  tag: string
}

interface contentProverb {
  quote: string
  work: string
  definition: string
  author: string
  front: string
  back: string
}

interface contentDic {
  word: string
  id: number
  type: string
  explain: string
  w: string
  p: any
  d: string
}
interface Tr {
  title: string
  width?: string
}

interface Props {
  leftText?: string
  rightText?: string
  tr?: Tr[]
  content?: content[]
  contentDic?: contentDic[]
  contentProverb?: contentProverb[]
  type?: number
}

const ResultWrapper = (props: Props) => {
  const { leftText, tr, content, contentDic, rightText, contentProverb, type } =
    props

  function copyContent(tit) {
    copy(tit || tit.word || tit.title || tit.tag)
    if (type === 1) {
      copy(tit.title)
    } else if (type === 5) {
      copy(tit.tag)
    } else if (type === 6) {
      copy(tit.word)
    } else {
      copy(tit)
    }
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  return (
    <div className={type === 3 ? style['result-type3'] : style['result-box']}>
      <div
        className={style['output-result']}
        style={
          type === 3
            ? { height: 'auto' }
            : { height: 'calc(96vh - 455px) !important' }
        }>
        <div className={style['output-result-inner']}>
          {rightText && <div className={style['sub-title']}>{rightText}</div>}
          {!rightText && leftText && (
            <div className={style['title']}>
              <h3>输出结果：</h3>
              <p>{leftText}</p>
            </div>
          )}
          {(content && content.length == 0 && !contentDic && !contentProverb) ||
          (contentDic && contentDic.length == 0) ||
          (contentProverb && contentProverb.length == 0) ? (
            <div className={style['table']}>
              {/* {' '} */}
              <div>
                {' '}
                <Empty type="toolEmpty" titleSize={18} top={0}></Empty>
              </div>
            </div>
          ) : (
            <div className={style['table']}>
              <table>
                <thead>
                  <tr>
                    {tr.map(({ title, width }, index) => {
                      return (
                        <th
                          key={title + index}
                          style={{ width: `${width && width}` }}>
                          {title}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {type == 2 && contentDic
                    ? contentDic.map(({ w, p, d }, index) => {
                        return (
                          <tr key={index}>
                            <td className={style['tdIcon']}>
                              <div className={style['tit']}>
                                {w}
                                <div className={style['icon']}>
                                  <IconCopy
                                    onClick={() => copyContent(w)}
                                    useCurrentColor={true}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>{p}</td>
                            <td>{d}</td>
                          </tr>
                        )
                      })
                    : type == 3 && contentProverb
                    ? contentProverb.map(
                        (
                          { quote, work, definition, author, front, back },
                          index,
                        ) => {
                          return (
                            <tr key={index}>
                              <td className={style['tdIcon']}>
                                <div className={style['tit']}>
                                  {front || quote}
                                  <div className={style['icon']}>
                                    <IconCopy
                                      onClick={() => copyContent(quote)}
                                      useCurrentColor={true}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>{definition || author || back || '暂无'}</td>
                              <td>{work || quote || '暂无'}</td>
                            </tr>
                          )
                        },
                      )
                    : content &&
                      content.map(({ score, word, tag, title }, index) => {
                        var truncatedScore = Number(
                          score.toString().substring(0, 5),
                        )

                        if (truncatedScore === 0) {
                          truncatedScore =
                            Number(score.toString().substring(0, 7)) * 1000
                          var truncatedScore1 = truncatedScore.toFixed(2)
                        }

                        return (
                          <tr key={index}>
                            <td className={style['tdIcon']}>
                              <div className={style['tit']}>
                                {word || tag || title}
                                <div className={style['icon']}>
                                  <IconCopy
                                    onClick={() =>
                                      copyContent({ word, tag, title })
                                    }
                                    useCurrentColor={true}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className={style['num']}>
                                {truncatedScore1 || truncatedScore}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Tools
