import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styles from './style/docHeader.module.less'
import {
  IconArrowLeft,
  IconCollect,
  IconSave,
  IconUploadDocument,
  IconShare,
  IconHotKey,
  IconMoreEdits,
  IconCollectFill,
  IconArrowRight,
  IconSuccessTip,
  IconErrorTip,
  IconNewTemplateLibrary,
  IconWastePaper,
  IconPrint,
  IconExport,
  IconDemo,
  IconWidescreen,
  IconHistoricVersion,
  IconAttributes,
  IconUse,
  IconCheck,
  IconCancel,
  IconCircleCheck,
  IconIllustrate,
  IconModificationRecord,
} from '@arco-iconbox/react-aidb-v2'
import { useHistory } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import {
  Divider,
  Dropdown,
  Menu,
  Message,
  Tooltip,
} from '@arco-design/web-react'
import IconSaveRotate from '@/assets/newImgs/save.svg'
import IconSaveRotate2 from '@/assets/newImgs/rotate-save.svg'
import { ApprovalStatus } from '@/helpers/enums'
import { getDocShareLink } from '@/model/documentEdit'
import { GetQueryValue, removeEmpty, throttle } from '@/utils/common'
import { showModal } from '@/share/actions'
import {
  CancelDocPublish,
  getCancelCollect,
  getDocumentCollect,
  getUserInfo,
  removeDocument,
  saveDocTemplate,
} from '@/model/reference'
import axios from 'axios'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import SubMenu from '@arco-design/web-react/es/Menu/sub-menu'
import { ExportBtn } from '@/share/config'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { ComponentToPrint } from './componentToPrint'
import EditingCollaboration from '../EditingCollaboration'
import { switchProject } from '@/helpers/switchProject'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { COLLABORATION_PERMISSION } from '@/config/editor-collaboration-config'
interface Props {
  title: string
  content: string
  isSave: {
    isEditContentSave: boolean
    isEditTitleSave: boolean
    date: number
  }
  isAutoSave: boolean
  isCollect: number
  uuid: string
  gmtModifyDate: string
  showRight?: boolean
  isImmerseDisplay: boolean
  isFullDisplay: boolean
  onChangeTitle: (title: string) => void
  onClickSave: (approvalStatus: number) => void
  yjsEditorCoordinationInfo: {
    // 当前客户端ID
    clientID: number
    // 在线状态及个人信息
    isOnline: [
      {
        id: number
        name: string
        online: boolean
      },
    ]
    // 当前用户信息
    userInfo: {
      id: number
    }
    // 当前协同列表
    employeeList: {
      employeeList: Array<any>
      total: number
    }
    // 所有协同用户操作信息
    awarenessMap: Array<any>
  }
  userInfo: object
  employeePermission: number
  onGoBack?: (
    isImmerseDisplay: boolean,
    teamUuidValue?: string,
    permissionValue?: string | number,
  ) => void
  onFullDisplay: () => void
  onImmerseDisplay?: () => void
  onExitImmerseDisplay?: () => void
  onVersionModal?: () => void
  onAttributeModal?: () => void
  onPrint?: () => void
  ref?: any
  theme?: any
}

const DocumentHeaderMod = forwardRef((props: Props, ref) => {
  const {
    title,
    content,
    isSave,
    isAutoSave,
    onChangeTitle,
    onClickSave,
    uuid,
    showRight,
    isCollect,
    gmtModifyDate,
    isFullDisplay,
    isImmerseDisplay,
    onGoBack,
    onVersionModal,
    onAttributeModal,
    onFullDisplay,
    onImmerseDisplay,
    onExitImmerseDisplay,
    theme,
    onPrint,
    yjsEditorCoordinationInfo,
    userInfo,
    employeePermission,
  } = props
  const dispatch = useDispatch()
  const [isCollectVal, setIsCollectVal] = useState(isCollect)
  const [titleVal, setTitleVal] = useState(title)
  const [isShowPrint, setIsShowPrint] = useState<boolean>(false)
  const [pasteType, setPasteType] = useState('text_paste')
  const exportArr = useRef([])
  const inputRef = useRef(null)
  const history = useHistory()

  // 创建一个内部的 divRef 用于绑定到 div 元素
  const divRef = useRef<HTMLDivElement>()
  const divRef1 = useRef<HTMLDivElement>()

  // 使用 useImperativeHandle 将 divRef 暴露给外部
  useImperativeHandle(ref, () => ({
    divRef, // 暴露 divRef
    divRef1,
    inputRef,
  }))

  useEffect(() => {
    setTitleVal(title)
  }, [title])

  useEffect(() => {
    setIsCollectVal(isCollect)
  }, [isCollect])

  useEffect(() => {
    const input = document.querySelector('.inputFocus')
    if (!titleVal) {
      inputRef.current.style.width = 200 + 'px'
    } else {
      var len = 0
      for (var i = 0; i < titleVal?.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(titleVal[i])) {
          len += 1.4
        } else {
          len += 1
        }
      }
      // inputRef.current.style.width = (len + 1) * 10 + 'px'
      inputRef.current.style.width =
        (len + 1) * (/[\u4e00-\u9fa5]/.test(titleVal[i]) ? 6 : 16) + 'px'
    }
    // if (input.getBoundingClientRect().width >= 565) {
    //   inputRef.current.style.whiteSpace = 'nowrap'
    //   inputRef.current.style.overflow = 'hidden'
    //   inputRef.current.style.textOverflow = 'ellipsis'
    // }
  }, [titleVal, showRight])

  useEffect(() => {
    events.on(ACTIONS.EDITPAGE_APPROVASTATUS, hanleDocStatus)
    events.on(ACTIONS.SUBMIT_DOC_HANDEL, onClickSave)
    return () => {
      events.off(ACTIONS.EDITPAGE_APPROVASTATUS, hanleDocStatus)
      events.off(ACTIONS.SUBMIT_DOC_HANDEL, onClickSave)
    }
  }, [])

  const [approvalStatus, setApprovalStatus] = useState(false)

  const hanleDocStatus = () => {
    console.log('qqqq')
    setApprovalStatus(true)
  }

  const inputChangeHandle = (e) => {
    setTitleVal(e.target.value)
    throttle(onChangeTitle(e.target.value), 5000)
  }

  //取消发布文档
  const handleCancelDocPublish = () => {
    showModal('ConfirmModal', {
      title: '  取消发布文档',
      content: `是否确认取消发布文档？（取消发布后将无法分享，且之前的分享也会失效，如需分享，需再次发布文档并重新生成分享链接。）`,
      onConfirm: () => {
        CancelDocPublish({ uuid: GetQueryValue('uuid') }).then((res) => {
          if (res.data.success) {
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: '取消发布成功',
            })
            setApprovalStatus(false)
          } else {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: res.data.msg,
            })
          }
        })
      },
    })
  }

  const shareHandle = async () => {
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    const res = await getDocShareLink({ uuid: GetQueryValue('uuid') })
    if (res.data.success) {
      const postData = {
        url:
          window.location.origin +
          '/documentShare?uuid=' +
          res.data.data.documentShareLink,
      }
      showModal('ShareLinkModal', {
        title: '分享',
        id: GetQueryValue('uuid'),
        isOpenShare: res.data.data.isOpenShare,
        documentShareLink: postData,
      })
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: res.data.msg,
      })
    }
  }

  // 快捷键icon点击回调
  const keyMapHandle = () => {
    showModal('KeyMapModal', {
      title: '快捷键',
    })
  }

  // 修改记录icon点击
  const modifyRecordHandle = () => {
    showModal('ModifyRecordModal', {
      title: '修改记录',
      uuid: GetQueryValue('uuid'),
    })
  }

  // 收藏文档
  const collectHandle = async () => {
    events.emit(ACTIONS.CLOSE_DIRECTIVES_EDITOR)
    if (isCollectVal == 0) {
      const res = await getDocumentCollect({ uuid: uuid })
      if (res.data.data) {
        setIsCollectVal(1)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '收藏文档成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '收藏文档失败',
        })
      }
    } else {
      const res = await getCancelCollect({ uuids: [uuid] })
      if (res.data.data) {
        setIsCollectVal(0)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '取消收藏成功',
        })
      } else {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '取消收藏文档失败',
        })
      }
    }
  }

  const removeDocumentHandle = async (event) => {
    event.stopPropagation()
    const res = await removeDocument({
      docStatus: 1,
      uuids: [uuid],
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '移至废纸篓成功',
        duration: 1000,
      })
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        uuid: GetQueryValue('folderUuid') ? GetQueryValue('folderUuid') : '',
      }) as any

      setTimeout(() => {
        history.push({
          pathname: '/intelligentDocument',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })

        location.reload()
      }, 1000)
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '移至废纸篓失败',
      })
    }
  }

  const exportHandle = async (type, interfaceName) => {
    const res = await getUserInfo({ uid: '' })
    const vipEndDate = new Date(res.data.data.vipEndDate)
    const currentDate = new Date()

    if (currentDate > vipEndDate && !switchProject('DFZ')) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请开通Vip会员，方可导出',
      })
    } else {
      const positionId = localStorage.getItem('positionId')
      const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null
      const uuid = GetQueryValue('uuid')
      if (exportArr.current.includes(type)) {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `正在导出 ${type}中`,
        })
        return
      }
      exportArr.current = [...exportArr.current, type]

      axios
        .post(
          `/api/api-aidb/document/${interfaceName}`,
          { uuid, docVersionId: 0 },
          { responseType: 'blob' },
        )
        .then((res) => {
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.${type}`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          exportArr.current = exportArr.current.filter((i) => i != type)
          // setExportArr(exportArr.filter((i) => i == type))
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `导出 ${type} 成功`,
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 ${type} 错误`,
          })
        })
    }
  }

  const immerseDisplayHandle = () => {
    if (isAutoSave) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '正在保存',
      })
    } else {
      onImmerseDisplay()
    }
  }

  const saveMyTem = () => {
    showModal('ConfirmModal', {
      title: '文档模版',
      content: `是否确认另存为自定义文档模版`,
      onConfirm: async () => {
        saveDocTemplate({ uuid: GetQueryValue('uuid') }).then((res) => {
          if (res.data.success) {
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: `保存自定义模版成功`,
            })
          }
        })
      },
    })
  }

  // 初始化回到之前的粘贴模式
  useEffect(() => {
    let editorPasteMode = localStorage.getItem('Editor-Paste-Mode') as
      | 'text_paste'
      | 'mark_paste'
      | 'formula_paste'
      | 'mix_paste'
    if (editorPasteMode) {
      setPasteType(editorPasteMode)
      pasteMethod(editorPasteMode)
    }
  }, [])

  // 用户选择粘贴方式回调
  const pasteMethod = (
    type: 'text_paste' | 'mark_paste' | 'formula_paste' | 'mix_paste',
  ) => {
    setPasteType(type)
    // 保存粘贴模式
    localStorage.setItem('Editor-Paste-Mode', type)
    dispatch({
      type: 'editor-paste-method-type',
      payload: {
        editorPasteMethodType: type,
      },
    })
  }

  const dropList = (
    <Menu className={'custom-dropdown drop-icon'} theme={theme}>
      <div className={'dropdown-list'}>
        <Menu.Item key="1" onClick={collectHandle}>
          {isCollectVal ? (
            <>
              <IconCollect /> 取消收藏
            </>
          ) : (
            <>
              <IconCollect /> 添加到收藏
            </>
          )}
        </Menu.Item>
        <Menu.Item key="8" onClick={saveMyTem}>
          <IconNewTemplateLibrary /> 另存为文档模版
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={removeDocumentHandle}
          disabled={employeePermission !== 1}>
          <IconWastePaper /> 移至废纸篓
        </Menu.Item>
        <SubMenu
          key="10"
          className={'export-drop'}
          style={{ justifyContent: 'flex-start' }}
          title={
            <>
              <IconUse />
              粘贴模式
            </>
          }>
          <Menu.Item key={'10-1'} onClick={() => pasteMethod('text_paste')}>
            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>原文粘贴模式</span>
              <span>{pasteType === 'text_paste' && <IconCheck />}</span>
            </p>
          </Menu.Item>
          <SubMenu
            key="10-0"
            className={'export-drop'}
            style={{ justifyContent: 'flex-start' }}
            // triggerProps={{ position: 'lt', trigger: 'click' }}
            title={
              <p
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <span>智能粘贴模式</span>
                <span>{pasteType === 'mix_paste' && <IconCheck />}</span>
              </p>
            }
            onClick={() => pasteMethod('mix_paste')}>
            <Menu.Item key={'10-0-0'} onClick={() => pasteMethod('mark_paste')}>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Markdown 模式</span>
                <span>{pasteType === 'mark_paste' && <IconCheck />}</span>
              </p>
            </Menu.Item>
            <Menu.Item
              key={'10-0-1'}
              onClick={() => pasteMethod('formula_paste')}>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>科学公式模式</span>
                <span>{pasteType === 'formula_paste' && <IconCheck />}</span>
              </p>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key={'10-3'} onClick={() => pasteMethod('mix_paste')}>
            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>智能粘贴模式</span>
              <span>{pasteType === 'mix_paste' && <IconCheck />}</span>
            </p>
          </Menu.Item> */}
        </SubMenu>
        <SubMenu
          key="11"
          className={'export-drop'}
          style={{ justifyContent: 'flex-start' }}
          title={
            <>
              <IconHotKey />
              快捷操作
            </>
          }>
          <Menu.Item
            key={'11-1'}
            onClick={() => {
              events.emit(ACTIONS.COLLAPSE_ALL_ANNOTATIONS)
            }}>
            展开全部批注
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="9" onClick={onPrint}>
          <IconPrint /> 打印
        </Menu.Item>
        <SubMenu
          key={'7'}
          className={'export-drop'}
          style={{ justifyContent: 'flex-start' }}
          title={
            <>
              <IconExport />
              导出
            </>
          }>
          {ExportBtn.map(({ key, title, type, interfaceName }) => {
            return (
              <Menu.Item
                key={`5-${key}`}
                // disabled={employeePermission === 3}
                onClick={() => exportHandle(type, interfaceName)}>
                {title}
              </Menu.Item>
              // <div
              //   key={key}
              //   className={style.btn}
              //   onClick={() => exportHandle(type, interfaceName)}
              //   style={{
              //     ...(Number(wordCount) === 0 && {
              //       opacity: 0.5,
              //       cursor: 'default',
              //     }),
              //   }}>
              //   <div className={style.text}>{title}</div>
              //   <div className={style.icon}>
              //     <IconDownload />
              //   </div>
              // </div>
            )
          })}
        </SubMenu>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className={'dropdown-list'}>
        <Menu.Item key="5" onClick={onFullDisplay}>
          {!isFullDisplay ? (
            <>
              <IconWidescreen />
              全宽显示
            </>
          ) : (
            <>
              <IconWidescreen />
              标宽显示
            </>
          )}
        </Menu.Item>
        {/* <Menu.Item key="5" className={'dropdown-haveChild'}> */}
        {/* <Dropdown droplist={dropList2} position="tl" trigger={'click'}>
            <div className={'dropdown-child-target'}>
              导出
              <div className={'editPage-dropdown-icon'}>
                <IconArrowRight />
              </div>
            </div>
          </Dropdown> */}

        {/* </Menu.Item> */}
        <Menu.Item key="6" onClick={immerseDisplayHandle}>
          <IconDemo /> 演示模式
        </Menu.Item>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className={'dropdown-list'}>
        <Menu.Item key="3" onClick={onVersionModal}>
          <IconHistoricVersion /> 历史版本
        </Menu.Item>
        <Menu.Item key="4" onClick={onAttributeModal}>
          <IconAttributes /> 文档属性
        </Menu.Item>
      </div>

      {uuid && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <div className={'dropdown-list'}>
            <p>最近修改：</p>
            <p>{gmtModifyDate}</p>
          </div>
        </>
      )}
    </Menu>
  )

  return (
    <div className={`${styles.header} ${styles[theme]}`}>
      <div className={styles.left}>
        {/* 返回箭头 */}
        {isImmerseDisplay ? (
          <div className={styles.icon} onClick={onExitImmerseDisplay}>
            <IconArrowLeft />
          </div>
        ) : (
          <Tooltip
            position="bottom"
            trigger="hover"
            content={isImmerseDisplay ? '退出演示模式' : '返回'}>
            <div
              className={styles.icon}
              onClick={() =>
                onGoBack(
                  isImmerseDisplay,
                  GetQueryValue('teamUuid'),
                  GetQueryValue('permission'),
                )
              }>
              <IconArrowLeft />
            </div>
            {/* <div
            className="textSaveBtn"
            onClick={() => onSubmit(ApprovalStatus.PendingApproval)}>
            <IconUploadDocument useCurrentColor={true} className="icon" />
          </div> */}
          </Tooltip>
        )}
        {/* 标题 */}
        <div ref={divRef}>
          <input
            ref={inputRef}
            onChange={inputChangeHandle}
            defaultValue={titleVal}
            style={{
              // width: isComment && '100%',
              backgroundColor: isImmerseDisplay && 'white',
              fontSize: '20px',
              fontWeight: 'bold',
              border: 'none',
              padding: '10px 10px',
              cursor: 'inherit',
            }}
            disabled={isImmerseDisplay}
            className={`${styles.title} ${showRight && styles.showRightTitle}`}
            type="text"
            placeholder="请输入标题"
            // onKeyDown={handleKeyDown}
          ></input>
        </div>
        {/* 保存信息 */}
        {employeePermission !== COLLABORATION_PERMISSION.read_permission && (
          <span className={styles.save}>
            {isAutoSave ? (
              <span className={styles.iconFalse}>
                <IconCircleCheck />
                保存中 ...
              </span>
            ) : isSave.isEditContentSave && isSave.isEditTitleSave ? (
              <span className={styles.iconTrue}>
                <IconCircleCheck />
                已保存于
                <ReactTimeAgo date={isSave.date} />
              </span>
            ) : (
              <span className={styles.iconFalse}>
                <IconCircleCheck />
                未保存
              </span>
            )}
          </span>
        )}
      </div>
      {/* 右侧图标等 */}
      {!isImmerseDisplay && (
        <div className={styles.right} ref={divRef1}>
          {isCollectVal == 1 && uuid && !isImmerseDisplay ? (
            <Tooltip position="bottom" trigger="hover" content={'取消收藏'}>
              <div
                className={`${styles.faved} ${styles.icon}`}
                onClick={collectHandle}
                style={{ fontSize: '20px' }}>
                <IconCollectFill />
              </div>
            </Tooltip>
          ) : (
            uuid &&
            !isImmerseDisplay && (
              <Tooltip position="bottom" trigger="hover" content={'收藏'}>
                <div
                  className={styles.icon}
                  onClick={collectHandle}
                  style={{ fontSize: '20px' }}>
                  <IconCollect />
                </div>
              </Tooltip>
            )
          )}
          {isAutoSave ? (
            <Tooltip position="bottom" trigger="hover" content={'正在保存'}>
              <div
                className={`${styles.icon}`}
                style={{ cursor: ' not-allowed' }}>
                <IconSaveRotate />
                <div className={styles.rotate}>
                  <IconSaveRotate2 />
                </div>
              </div>
            </Tooltip>
          ) : (
            <Tooltip position="bottom" trigger="hover" content={'保存文档'}>
              <div
                className={styles.icon}
                onClick={() => onClickSave(ApprovalStatus.ToSubmit)}>
                <IconSave />
              </div>
            </Tooltip>
          )}
          <Tooltip
            position="bottom"
            trigger="hover"
            content={isAutoSave ? '正在保存' : '发布文档'}>
            <div
              style={{ cursor: isAutoSave ? 'not-allowed' : 'pointer' }}
              className={styles.icon}
              onClick={
                isAutoSave
                  ? null
                  : () => onClickSave(ApprovalStatus.PendingApproval)
              }>
              <IconUploadDocument />
            </div>
          </Tooltip>
          {approvalStatus == true && (
            <>
              <Tooltip
                position="bottom"
                trigger="hover"
                content={'取消发布文档'}>
                <div
                  className={styles.icon}
                  style={{ cursor: isAutoSave ? 'not-allowed' : 'pointer' }}
                  onClick={handleCancelDocPublish}>
                  <IconCancel />
                </div>
              </Tooltip>
              <Tooltip
                position="bottom"
                trigger="hover"
                content={isAutoSave ? '正在保存' : '分享文档'}>
                <div
                  className={styles.icon}
                  style={{ cursor: isAutoSave ? 'not-allowed' : 'pointer' }}
                  onClick={isAutoSave ? null : shareHandle}>
                  <IconShare />
                </div>
              </Tooltip>
            </>
          )}

          <Tooltip position="bottom" trigger="hover" content={'修改记录'}>
            <div
              className={styles.icon}
              style={{
                cursor: employeePermission === 3 ? 'not-allowed' : 'pointer',
              }}
              onClick={isAutoSave ? null : modifyRecordHandle}>
              <IconModificationRecord />
            </div>
          </Tooltip>
          <Tooltip position="bottom" trigger="hover" content={'快捷键'}>
            <div
              className={styles.icon}
              style={{
                cursor: employeePermission === 3 ? 'not-allowed' : 'pointer',
              }}
              onClick={isAutoSave ? null : keyMapHandle}>
              <IconHotKey />
            </div>
          </Tooltip>
          <Dropdown
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
            droplist={dropList}
            position="br"
            // popupVisible={true}
            trigger={'click'}>
            <div className={`${styles.more} ${styles.icon}`}>
              <IconMoreEdits />
            </div>
          </Dropdown>
          {/* 编辑器多人编辑 */}
          {JSON.stringify(userInfo) !== '{}' &&
          GetQueryValue('uuid') &&
          yjsEditorCoordinationInfo.employeeList.total ? (
            <>
              <Divider
                type="vertical"
                style={{ height: '1.5rem', borderLeft: '1px solid #EFEFEF' }}
              />
              <EditingCollaboration
                userInfo={userInfo}
                isDisable={GetQueryValue('uuid') ? false : true}
                employeeList={yjsEditorCoordinationInfo.employeeList}
                employeePermission={employeePermission}
              />
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
})

export default DocumentHeaderMod
