import { events } from '@/helpers/event-emitter'
import { showModal } from '@/share/actions'
import { ACTIONS } from '@/share/constants'
import { GetQueryValue } from '@/utils/common'
import { SylApi, Inline, SylController, SylPlugin } from '@syllepsis/adapter'
import { setBlockType } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
interface MapKeyAttrs {
  class: string
}

const PLUGIN_NAME = 'mapkey'

class MapKeySchema extends Inline<MapKeyAttrs> {
  public name = PLUGIN_NAME
}

class MapKeyController extends SylController {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.MOD_Z_UNDO, this.undoHandle)
    events.on(ACTIONS.MOD_Z_REDO, this.redoHandle)
    events.on(ACTIONS.INPUT_ENTER, this.inputEnterHandle)
  }

  editorWillUnmount(): void {
    events.off(ACTIONS.MOD_Z_UNDO, this.undoHandle)
    events.off(ACTIONS.MOD_Z_REDO, this.redoHandle)
    events.off(ACTIONS.INPUT_ENTER, this.inputEnterHandle)
  }
  private handleModHeader = (level) => {
    const { state, dispatch } = this.editor.view
    if (setBlockType(state.schema.nodes.header, { level })(state, dispatch)) {
      return true
    }
    return false
  }

  private handleTogglePlainText = () => {
    const { state, dispatch } = this.editor.view
    const isHeader = state.selection.$from.parent.type.name === 'header'
    if (isHeader) {
      return setBlockType(state.schema.nodes.paragraph)(state, dispatch)
    }
    return false
  }

  private undoHandle = () => {
    this.editor.undo()
  }

  private redoHandle = () => {
    this.editor.redo()
  }

  private inputEnterHandle = () => {
    this.editor.focus()
  }

  public keymap = {
    'mod-s': () => this.handleModS(),
    'ctrl-s': () => this.handleModS(),
    'mod-z': () => this.handleModZ(),
    'ctrl-z': () => this.handleModZ(),
    'mod-p': () => this.handleModP(),
    'ctrl-p': () => this.handleModP(),
    'mod-h': () => this.handleModH(),
    'ctrl-h': () => this.handleModH(),
    'shift-Enter': () => this.handleInnerSubmit(),
    'mod-ctrl-0': () => this.handleTogglePlainText(),
    'mod-ctrl-1': () => this.handleModHeader(1),
    'mod-ctrl-2': () => this.handleModHeader(2),
    'mod-ctrl-3': () => this.handleModHeader(3),
    'mod-ctrl-4': () => this.handleModHeader(4),
    'mod-ctrl-5': () => this.handleModHeader(5),
    'mod-ctrl-6': () => this.handleModHeader(6),
    'mod-ctrl-9': () => this.handleTask(),
    'mod-/': () => this.handleModInnerTool(),
    'ctrl-alt-0': () => this.handleTogglePlainText(),
    'ctrl-alt-1': () => this.handleModHeader(1),
    'ctrl-alt-2': () => this.handleModHeader(2),
    'ctrl-alt-3': () => this.handleModHeader(3),
    'ctrl-alt-4': () => this.handleModHeader(4),
    'ctrl-alt-5': () => this.handleModHeader(5),
    'ctrl-alt-6': () => this.handleModHeader(6),
  }

  private handleModInnerTool = () => {
    events.emit(ACTIONS.MOD_INNER_TOOL)
    return true
  }

  private handleTask = () => {
    console.log('yyyy')
    events.emit(ACTIONS.MOD_CTRL_9_TASK)
    return true
  }

  private handleEnterInner = () => {
    events.emit(ACTIONS.DIRECTIVES_ENTER_INNER)
    return true
  }

  private handleInnerSubmit = () => {
    events.emit(ACTIONS.DIRECTIVES_ENTER_INNER)
    // events.emit(ACTIONS.DIRECTIVES_SUBMIT_EDITOR)
    return true
  }

  private handleModH = () => {
    showModal('KeyMapModal', {
      title: '快捷键',
    })
    return true
  }

  private handleModZ = () => {
    this.editor.undo()
    const bottomGroup = document.querySelector('.button-group')
    bottomGroup.setAttribute('style', 'display:none')
    return true
  }

  private handleModS = () => {
    if (GetQueryValue('uuid')) {
      events.emit(ACTIONS.MOD_S_SAVE)
    }
    return true
  }

  private handleModP = () => {
    events.emit(ACTIONS.MOD_P_SAVE)
    return true
  }
}

class MapKeyPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = MapKeyController
  public keymap = keymap(MapKeyController.prototype.keymap)
  public Schema = MapKeySchema
}

export { MapKeyPlugin }
