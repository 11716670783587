import styles from './style/doc-share-mod.module.less'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TitleNav from './titleShareNav'
import EmptyView from '@/components/Empty'
import ChangePageBtnMod from './changePageBtnMod'
import { showModal } from '@/share/actions'
import Btn from '../Ui/btn'
import palette from '@/helpers/palette'
import { IconReport } from '@arco-iconbox/react-aidb-v2'
import $ from 'jquery'
import { Helmet } from 'react-helmet'
// import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import ChangeThemeBtn from '../SideBar/ChangeThemeBtn'
import { throttle } from 'lodash'
import { GetQueryValue } from '@/utils/common'
import { useSelector } from 'react-redux'
// import { useLocation } from 'react-router-dom'
interface Props {
  title: string
  docContent: string
  foldList: any
}

const DocumentMod = (props: Props) => {
  console.log(props)

  const theme = useSelector((state: GlobalState) => state.theme)
  const { title, docContent, foldList } = props
  const history = useHistory()
  const [showNav, setShowNav] = useState<boolean>(false)
  const documentRef = useRef(null)
  const [isShow, setIsShow] = useState(false)
  const locationSearch = location

  let isContentHtmlTimer

  const getContentHtmlHandle = () => {
    setIsShow(!isShow)
    // if (isContentHtmlTimer) clearTimeout(isContentHtmlTimer)
    // isContentHtmlTimer = setTimeout(() => {
    //   setIsShow(false)
    // }, 2000)
  }

  useEffect(() => {
    const documentElement = documentRef.current
    const handleImageLoad = () => {
      setIsShow(false)
    }
    console.log(documentElement)
    if (documentElement) {
      const images = documentElement?.querySelectorAll('img')
      let loadedImageCount = 0

      images.forEach((img) => {
        img.addEventListener('load', () => {
          loadedImageCount++
          if (loadedImageCount === images.length) {
            handleImageLoad()
          }
        })
      })
      return () => {
        images.forEach((img) => {
          img.removeEventListener('load', handleImageLoad)
        })
      }
    }
  }, [docContent])

  useEffect(() => {
    setShowNav(hasHeadingTags(docContent))
    const box = document.querySelectorAll('#collectContent')[0]
    const imgBox = box && box.querySelectorAll('img')
    let imgSrcList = []

    imgBox &&
      imgBox.forEach((_) => {
        if (_.src) {
          imgSrcList.push(_.src)
        }
      })
    // setIsShow(!isShow)
    imgBox &&
      imgBox.forEach((wrapper, index) => {
        wrapper.addEventListener('click', function (event) {
          const src = wrapper.src
          if (imgSrcList.length > 0) {
            showModal('ImageModal', {
              src: src,
              defaultCurrent: index,
              imgSrcList: imgSrcList,
            })
          }
        })
      })
    getContentHtmlHandle()
  }, [title, docContent, documentRef])

  // 编辑器初始化时还原刷新前的位置
  useEffect(() => {
    const scrollContainer = document.getElementById('scroll-content')
    const key = 'editorScrollPosition'

    if (scrollContainer) {
      // 定义监听视频加载的函数
      const watchMediaLoad = () => {
        const videoElements = scrollContainer.getElementsByTagName('video')
        const imgElements = scrollContainer.getElementsByTagName('img')

        const allMediaLoaded = []

        if (videoElements.length > 0) {
          const allVideosLoaded = Array.from(videoElements).map((video) => {
            return new Promise((resolve) => {
              video.oncanplaythrough = resolve
            })
          })
          allMediaLoaded.push(...allVideosLoaded)
        }

        if (imgElements.length > 0) {
          const allImgsLoaded = Array.from(imgElements).map((img) => {
            return new Promise((resolve) => {
              img.onload = resolve
            })
          })
          allMediaLoaded.push(...allImgsLoaded)
        }

        Promise.all(allMediaLoaded).then(() => {
          let currentUrl = new URL(window.location.href)
          let value = currentUrl.searchParams.get(key)

          if (value) {
            setTimeout(() => {
              scrollContainer.scrollTop = parseInt(value)
            }, 50)
          }
        })
      }
      // 创建 MutationObserver 实例来监听DOM变化
      const observer = new MutationObserver(watchMediaLoad)
      observer.observe(document.body, { childList: true, subtree: true })
      if (GetQueryValue(key) == '0') {
        scrollContainer.scrollTop = 0
      }
      // 滚动事件监听器
      const saveScrollPosition = throttle(() => {
        let currentUrl = new URL(window.location.href)
        let value = scrollContainer.scrollTop.toString()

        if (currentUrl.searchParams.has(key)) {
          // 修改现有的键值对
          currentUrl.searchParams.set(key, value)
        } else {
          // 添加新的键值对
          currentUrl.searchParams.append(key, value)
        }

        window.history.pushState({}, '', currentUrl.toString())
      }, 1000)
      scrollContainer.addEventListener('scroll', saveScrollPosition)

      return () => {
        // 组件卸载前移除事件监听并保存滚动位置
        scrollContainer.removeEventListener('scroll', saveScrollPosition)
        observer.disconnect() // 停止监听DOM变化
      }
    }
  }, [locationSearch])

  const hasHeadingTags = (htmlString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const hasH1 = doc.querySelector('h1') !== null
    const hasH2 = doc.querySelector('h2') !== null
    const hasH3 = doc.querySelector('h3') !== null
    const hasH4 = doc.querySelector('h4') !== null
    return hasH1 || hasH2 || hasH3 || hasH4
  }

  const reportHandle = () => {
    location.href =
      'https://support.wuz.com.cn/form/?type=产品缺陷反馈&content=违规内容：' +
      window.location.href
  }

  // use state for Chatweb config instead origin js in React to avoid some potential problems.
  const [isActive, setIsActive] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)

  const handleClick = () => {
    const src = document.getElementById('chatweb-bot').getAttribute('pre-src')
    if (!isActive) {
      setIsActive(true)
      document.getElementById('chatweb-bot').setAttribute('src', src)
    } else if (isMinimized) {
      setIsMinimized(false)
    } else {
      setIsMinimized(true)
    }
  }

  const iframeUrl = `https://chatweb-widget.aidb.com.cn/widget.php?theme=yellow&url=${encodeURIComponent(
    window.location.href,
  )}`

  return (
    <div className={styles.document}>
      <div className={styles.document_content}>
        {docContent.length == 0 ? (
          <div className={`${styles.noContent}`}>
            <EmptyView type="docEmpty" titleSize={18} />
            <div className={styles.changePageBtnMod}>
              <ChangePageBtnMod foldList={foldList}></ChangePageBtnMod>
              <div
                className={`${styles.mobileReport} ${
                  docContent.length == 0 && styles.noContentMobileReport
                }`}>
                <IconReport />
                <span>举报</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles.wp}`} id="scroll-content">
            <div id={`collectContent`} className={styles.collectContent}>
              <div
                className="ProseMirror"
                ref={documentRef}
                // id={`collectContent`}
                // className={styles.collectContent}
                dangerouslySetInnerHTML={{ __html: docContent }}></div>
            </div>
            <div className={styles.changePageBtnMod}>
              <ChangePageBtnMod foldList={foldList}></ChangePageBtnMod>
            </div>
            <div
              className={`${styles.mobileReport} ${
                docContent.length == 0 && styles.noContentMobileReport
              }`}
              onClick={reportHandle}>
              <IconReport />
              <span>举报</span>
            </div>
          </div>
        )}
        {showNav && docContent.length > 0 && (
          <TitleNav
            theme={theme}
            position={'right'}
            docContent={docContent}
            isShow={isShow}
          />
        )}
      </div>
      {/*改变主题*/}
      {/* <div className={styles.changeTheme}>
        <ChangeThemeBtn></ChangeThemeBtn>
      </div> */}
      <div className={styles.report}>
        <Btn
          onClick={reportHandle}
          title={'举报'}
          width={72}
          height={32}
          svgSize={15}
          svgColor={palette.grayE4}
          showBorder={
            theme == 'dark' ? '1px solid #2c2c2c' : '1px solid #e4e4e4'
          }
          hoverBorderColor={theme == 'dark' ? '#2c2c2c' : palette.black}
          hoverColor={palette.black70}
          svgHoverColor={palette.black70}
          backgroundColor={theme == 'dark' ? '#19191a' : palette.white}
          hoverBackgroundColor={theme == 'dark' ? '#19191a' : palette.white}
          color={palette.grayE4}
          Icon={<IconReport />}
        />
      </div>
      <Helmet>
        {/* 在这里插入你的JavaScript代码 */}
        {/* <script src="https://chatweb-widget.aidb.com.cn/js/jquery.js"></script> */}
        {/* <script src="https://chatweb-widget.aidb.com.cn/js/ref.js"></script> */}
        <link
          rel="stylesheet"
          href="https://chatweb-widget.aidb.com.cn/css/ref.css"
        />
      </Helmet>
      {/* 在这里插入你的iframe */}
      <div id="chatweb-container">
        <iframe
          id="chatweb-btn"
          src="https://chatweb-widget.aidb.com.cn/btn.php?theme=yellow"></iframe>
        <div id="chatweb-btn-layer" onClick={handleClick}></div>
        <iframe
          id="chatweb-bot"
          pre-src={iframeUrl}
          className={`${isActive ? 'active' : ''} ${
            isMinimized ? 'min' : ''
          }`}></iframe>
      </div>
    </div>
  )
}

export default DocumentMod
