import React from 'react'
import styles from './index.module.less'
import { IconEdit, IconWastePaper } from '@arco-iconbox/react-aidb'

interface Props {
  content: string
  date: string
  id: string
  nodeInfo?: any
  className: any
  isEdit?: boolean
  onEditComment?: (id: string, content: string, className: string) => void
  onCancelComment?: (id: string, content: string) => void
}

const CommentItem = (props: Props) => {
  const {
    className,
    content,
    id,
    date,
    onEditComment,
    onCancelComment,
    isEdit,
  } = props

  const hightLightElement = (e) => {
    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    e.stopPropagation()
    document.querySelectorAll('.' + className).forEach((item) => {
      item.classList.add('hl')
      const target = document.querySelector('.hl') as HTMLElement
      const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)
      var distance = 0
      var parent = target.offsetParent as HTMLElement
      while (parent && parent !== sylEditorBox) {
        distance += parent.offsetTop
        parent = parent.offsetParent as HTMLElement
      }
      if (distance < boxHeight) {
        setTimeout(() => {
          sylEditorBox.scrollTo({
            top: distance - boxHeight + boxHeight,
            behavior: 'smooth',
          })
        }, 300)
      } else {
        setTimeout(() => {
          sylEditorBox.scrollTo({
            top: distance - sylEditorBox.offsetTop,
            behavior: 'smooth',
          })
        }, 300)
      }
    })
  }

  const removeHightLight = (e) => {
    e.stopPropagation()
    document.querySelectorAll('.' + className).forEach((item) => {
      item.classList.remove('hl')
    })
  }

  return (
    <div
      className={styles['item']}
      style={{ position: 'relative' }}
      onMouseOver={hightLightElement}
      onMouseOut={removeHightLight}>
      <div className={styles['top']}>
        <div
          className={styles['poi']}
          style={{ backgroundColor: '#ff6a00' }}></div>
        <div
          style={{
            fontWeight: isEdit ? 'bold' : '400',
            wordBreak: 'break-word',
          }}>
          {content}
        </div>
        <div className={styles['date']}>{date}</div>
      </div>

      <div className={styles['bot']}>
        <div className={styles['btn-group']}>
          <div
            className={styles['g-btn']}
            onClick={() => onEditComment(id, content, className)}>
            <div className={styles['icon']}>
              <IconEdit useCurrentColor={true} />
            </div>
            <span>编辑</span>
          </div>
          <div
            onClick={() => onCancelComment(id, className)}
            className={styles['g-btn']}>
            <div className={styles['icon']}>
              <IconWastePaper useCurrentColor={true} />
            </div>
            <span>删除</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentItem
