import React, { useEffect, useRef, useState } from 'react'
import styles from './style/index.module.less'
import {
  Checkbox,
  Divider,
  Dropdown,
  Menu,
  Message,
} from '@arco-design/web-react'
import {
  IconMoreEdits,
  IconCollectFill,
  IconSuccessTip,
  IconErrorTip,
  IconShow,
  IconExport,
  IconMagicWand,
  IconShare,
  IconMove,
  IconCollect,
  IconEditLabel,
  IconEdit,
  IconWastePaper,
  IconUploadDocument,
  IconRecover,
  IconPlus,
} from '@arco-iconbox/react-aidb-v2'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SubMenu from '@arco-design/web-react/es/Menu/sub-menu'
import { ExportBtn } from '@/share/config'
import FolderBg0 from '../../assets/imgs/folder-big0.svg'
import FolderBg1 from '../../assets/imgs/folder-big1.svg'
import FolderBg2 from '../../assets/imgs/folder-big2.svg'
import FolderBgChecked0 from '../../assets/imgs/folder-big-checked0.svg'
import FolderBgChecked1 from '../../assets/imgs/folder-big-checked1.svg'
import FolderBgChecked2 from '../../assets/imgs/folder-big-checked2.svg'
import Tags from './Tags'
import AddTags from '../../pages/intelligentDocument/AddTags'
import { ContentType, PageType } from '@/helpers/enums'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { showDrawer, showModal } from '@/share/actions'
import {
  GetDocFolderList,
  GetProjectShareContent,
  GetTeamTreeList,
  getCancelChat,
  getCancelCollect,
  getDocumentCollect,
  getDocumentDetail,
  getPublishDocument,
  getUserInfo,
  removeDocument,
  saveDocument,
} from '@/model/reference'
import { IconClose } from '@arco-iconbox/react-aidb'
import IconMsgSuccess from '../../assets/newImgs/msg-success.svg'
import request from '@/utils/request'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { getDocShareLink } from '@/model/documentEdit'
import ModalCustom from '../ModalCustom'
import CollectModal from '../Modal/collect-modal'
import SharePop from '../SharePop'
import ShowQuickDrawer from '../Drawer/show-quick-drawer'
import axios from 'axios'
import { switchProject } from '@/helpers/switchProject'

interface Props {
  onClickMask?: (checked: boolean, id: string, index: number) => void
  type?: string | number
  id?: any
  hideCheckbox?: string | boolean
  title?: string
  wasteDate?: string
  isFav?: boolean
  date?: string
  content: string
  tags?: string[]
  hideBottom?: boolean
  isComplete?: boolean
  uuid?: any
  uuids?: string[]
  pageType?: number
  selectedItems?: any
  isCollect?: any //代表是否收藏文档，true false
  tagsList?: any
  collectType?: any //collectType代表收藏的类型，例如1代表收藏文档，2代表收藏对话，3代表收藏模板
  outCollectList?: boolean //判断true是第一次收藏夹，false 是收藏夹里面
  onToCollectEdit?: (
    uuid: string,
    type: number | string,
    outCollectList: boolean,
  ) => void //编辑器收藏卡片点击返回事件
  onGoFold?: () => void
  isChecked?: boolean
  index?: number
  theme?: any
  onShowChange?: any
  folderColor?: any
  fileType?: any
  teamUuid?: any
  permission?: any
}

const DocCardItem = (props: Props) => {
  const history = useHistory()
  const {
    theme,
    onClickMask,
    id,
    isComplete,
    uuids = [],
    type,
    pageType,
    selectedItems,
    wasteDate,
    isCollect,
    onToCollectEdit,
    onGoFold,
    outCollectList,
    isChecked,
    index,
    onShowChange,
    folderColor,
    fileType,
    teamUuid,
    permission,
  } = props
  const [checked, setChecked] = useState(false)
  const [iscollect, setIscollect] = useState<boolean>(isCollect)
  const [exportLoading, setExportLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setChecked(uuids.some((_) => _ == id))
  }, [uuids.some((_) => _ == id)])

  const clickMaskHandle = (e) => {
    setChecked(!checked)
    onClickMask(!checked, id, index)
  }

  useEffect(() => {
    setChecked(false)
  }, [isComplete])

  useEffect(() => {
    if (isChecked) {
      setChecked(true)
    }
  }, [isChecked])

  const deleteDocHandle = () => {
    events.emit(ACTIONS.DELETE_DOCUMENT, {
      id: [id],
    })
  }

  const returnDocHandle = () => {
    events.emit(ACTIONS.RETURN_DOCUMENT, {
      id: [id],
    })
  }
  //重命名
  const renameDocumentHandle = (event) => {
    event.stopPropagation()
    showModal('RenameModal', {
      title: '重命名',
      defaultName: props.title,
      id: props.uuid,
      teamUuid: props.teamUuid,
    })
  }

  // 移动至废纸篓
  const removeDocumentHandle = async (event) => {
    event.stopPropagation()
    const res = await removeDocument({
      docStatus: 1,
      uuids: [props.uuid],
    })
      .then((res) => {
        if (res.data.success == true) {
          events.emit(ACTIONS.DELETE_DOC, {
            id: props.uuid,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '移至废纸篓成功',
          })
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '移至废纸篓失败',
        })
      })
  }

  //发布文档
  function handleSaveDoc() {
    getPublishDocument({
      uuid: props.uuid,
      docVersionType: 3,
    }).then((res) => {
      if (res.data.success == true) {
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '发布文档成功',
        })
      }
    })
  }

  // 移动
  const addFoldHandle = (event) => {
    event.stopPropagation()
    getFoldList()
  }
  function traverse(data) {
    if (Array.isArray(data)) {
      return data.map(traverse)
    } else if (data.children) {
      return {
        title: data.title,
        id: data.uuid,
        children: traverse(data.children),
      }
    } else {
      return {
        title: data.title,
        id: data.uuid,
      }
    }
  }
  function getFoldList() {
    const docFolderList =
      localStorage.getItem('docTypeKey') == '共享文档'
        ? GetTeamTreeList
        : GetDocFolderList
    docFolderList().then((res) => {
      const folderList = traverse(res.data.data)
      // 添加根目录对象
      const root_node = {
        title: '根目录',
        id: '',
        children: folderList,
      }
      showModal('AddToFoldModal', {
        title: '移动',
        foldList: [root_node], // 将根目录对象作为foldList参数传递
        id: props.uuid,
      })
    })
  }

  // 进入文件夹
  const goFoldHandle = () => {
    event.preventDefault() // 防止默认的导航行为
    if (location.href.includes('waste')) return
    if (PageType.collectEdit == pageType) {
      onToCollectEdit(id, type, outCollectList)
    } else {
      if (localStorage.getItem('docTypeKey') == '共享文档') {
        const urlQuery = removeEmpty({
          filePath: props.title,
          uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
          folderUuid: GetQueryValue('filePath')
            ? GetQueryValue('uuid')
            : props.uuid,
          teamUuid: GetQueryValue('teamUuid')
            ? GetQueryValue('teamUuid')
            : props.teamUuid,
          permission: props.permission,
        }) as any
        history.push({
          pathname: '/intelligentDocument',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })
        // location.reload()
      } else {
        const urlQuery = removeEmpty({
          filePath: props.title,
          uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
          folderUuid: GetQueryValue('filePath')
            ? GetQueryValue('uuid')
            : props.uuid,
        }) as any
        history.push({
          pathname: '/intelligentDocument',
          search: '?' + new URLSearchParams(urlQuery).toString(),
        })
      }
    }
  }
  const [visible1, setVisible1] = useState(false)
  // 进入详情
  function handleToEdit() {
    event.preventDefault() // 防止默认的导航行为
    if (location.href.includes('waste')) return
    if (pageType == PageType.collectEdit) {
      onToCollectEdit(id, type, outCollectList)
    } else {
      if (props.collectType == 2) {
        setVisible1(true)
      } else {
        const urlQuery = removeEmpty({
          filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
          listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
          uuid: props.uuid,
          folderUuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
          teamUuid: GetQueryValue('teamUuid')
            ? GetQueryValue('teamUuid')
            : props.teamUuid,
          title: props.title,
          from: location.pathname.substring(1),
          fileType: props.fileType,
          // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
          ...(localStorage.getItem('docTypeKey') == '共享文档'
            ? { permission: props.permission }
            : {}),
        }) as any
        const queryString = '?' + new URLSearchParams(urlQuery).toString()
        window.location.href = '/documentEdit' + queryString
      }
    }
  }
  // 收藏文档
  function handleCollect() {
    setIscollect(!iscollect)
    if (iscollect == false) {
      getDocumentCollect({ uuid: props.uuid })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_TURE, {
            id: props.uuid,
            isCollect: 1,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '收藏文档成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '收藏文档失败',
          })
        })
    } else {
      getCancelCollect({ uuids: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.COLLECT_FALSE, {
            id: props.uuid,
            isCollect: 0,
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    }
  }
  // 取消收藏
  function cancleCollect() {
    if (props.collectType == 1 && type != ContentType.assistant) {
      getCancelCollect({ uuids: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.CANCEL_COLLECT, {
            id: [props.uuid],
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    } else if (props.collectType == 2 || type == ContentType.assistant) {
      getCancelChat({ messageIds: [props.uuid] })
        .then((res) => {
          events.emit(ACTIONS.CANCEL_COLLECT, {
            id: [props.uuid],
          })
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: '取消收藏成功',
          })
        })
        .catch(() => {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '取消收藏文档失败',
          })
        })
    }
  }

  const [shareVisible, setShareVisible] = useState(false)
  // 分享
  function hanldeShare() {
    if (GetQueryValue('typeKey') == '2') {
      setShareVisible(true)
      {
        /* 对话分享框 */
      }
    } else {
      getDocShareLink({ uuid: props.uuid }).then((res) => {
        if (res.data.success) {
          const postData = {
            url:
              window.location.origin +
              '/documentShare?uuid=' +
              res.data.data.documentShareLink,
          }
          showModal('ShareLinkModal', {
            fileType: props.type,
            title: '分享',
            id: props.uuid,
            isOpenShare: res.data.data.isOpenShare,
            documentShareLink: postData,
          })
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
    }
  }

  const hanldeAddTeam = () => {
    console.log(1)
  }
  //更换封面
  const handleCoverChange = () => {
    showModal('FolderCoverModal', {
      Modaltitle: '更换封面',
      uuid: props.uuid,
      title: props.title,
      folderColor: folderColor,
    })
    window.location.hash = 'FolderCoverModal'
  }

  //协同管理
  const handleAddPeople = () => {
    showModal('AddTeamModal', {
      uuid: props.uuid,
      fileType: props.fileType,
      currentPermission: props.permission,
      innerType: props.permission == 1 ? false : true,
    })
  }
  //发布文档
  const dropList = (
    <Menu className={'custom-dropdown drop-icon'}>
      {pageType === PageType.intelligentDoc ? (
        <React.Fragment>
          <div className={'dropdown-list'}>
            {/* {props.type == 0 ? '文档' : '文件夹'} */}
            {props.type == 1 && (
              <Menu.Item key="0" onClick={handleCoverChange}>
                <IconMagicWand /> 更换封面
              </Menu.Item>
            )}
            <Menu.Item key="1" onClick={hanldeShare}>
              <IconShare />
              分享
            </Menu.Item>
            <Menu.Item key="1" onClick={handleAddPeople}>
              <IconPlus />
              协同管理
            </Menu.Item>
            <Menu.Item key="2" onClick={handleCollect}>
              {iscollect ? (
                <>
                  <IconCollect /> 取消收藏
                </>
              ) : (
                <>
                  <IconCollect /> 收藏
                </>
              )}
            </Menu.Item>
            {localStorage.getItem('docTypeKey') != '共享文档' && (
              <Menu.Item key="3" onClick={addFoldHandle}>
                <IconMove /> 移动
              </Menu.Item>
            )}
            {localStorage.getItem('docTypeKey') != '共享文档' && (
              <Menu.Item
                key="4"
                onClick={() => {
                  setShowAddTags(true)
                }}>
                <IconEditLabel />{' '}
                {props.tags?.length > 0 ? '编辑标签' : '添加标签'}
              </Menu.Item>
            )}
            {/* <Menu.Item key="5">创建副本</Menu.Item> */}
            <Menu.Item key="6" onClick={renameDocumentHandle}>
              <IconEdit /> 重命名
            </Menu.Item>
            {props.type == 1 && (
              <Menu.Item
                style={{ marginBottom: '8px' }}
                disabled={exportLoading}
                key="11"
                onClick={handelDocToZip}>
                <IconExport />
                导出文件夹
              </Menu.Item>
            )}
            {props.type == 0 && (
              <SubMenu
                key={'7'}
                className={'export-drop'}
                style={{ justifyContent: 'flex-start' }}
                title={
                  <>
                    <IconExport
                      style={{ fontSize: '19px', marginLeft: '-3px' }}
                    />
                    导出
                  </>
                }>
                {ExportBtn.map(({ key, title, type, interfaceName }) => {
                  return (
                    <Menu.Item
                      disabled={exportLoading}
                      key={`5-${key}`}
                      onClick={() => exportHandle(type, interfaceName)}>
                      {title}
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )}
            {props.type == 0 ? (
              <Menu.Item
                style={{ marginBottom: '8px' }}
                key="10"
                onClick={handleSaveDoc}>
                <IconUploadDocument /> 发布文档
              </Menu.Item>
            ) : (
              ''
            )}
          </div>
          <Divider style={{ margin: '4px 0' }} />
          <div className={'dropdown-list'}>
            <Menu.Item
              disabled={permission == 1 ? false : true}
              style={{ marginTop: '7px', opacity: permission == 1 ? '' : 0.5 }}
              key="7"
              onClick={removeDocumentHandle}>
              <IconWastePaper /> 移至废纸篓
            </Menu.Item>
          </div>{' '}
        </React.Fragment>
      ) : pageType === PageType.collect || pageType === PageType.collectEdit ? (
        <React.Fragment>
          <div className={'dropdown-list'}>
            {type !== ContentType.assistant && (
              <Menu.Item key="1" onClick={hanldeShare}>
                <IconShare /> 分享
              </Menu.Item>
            )}

            {outCollectList && (
              <Menu.Item key="2" onClick={cancleCollect}>
                <IconCollect /> 取消收藏
              </Menu.Item>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={'dropdown-list'}>
            <Menu.Item key="8" onClick={returnDocHandle}>
              <IconRecover /> 还原文档
            </Menu.Item>
            <Menu.Item key="3" onClick={deleteDocHandle}>
              <IconWastePaper /> 删除文档
            </Menu.Item>
          </div>
        </React.Fragment>
      )}
    </Menu>
  )

  // 是否隐藏复选框
  const [hideCheckbox, setHideCheckbox] = useState(props.hideCheckbox)

  //添加标签
  const [showAddTags, setShowAddTags] = React.useState(false)

  //新建文档
  const [showAddDoc, setShowAddDoc] = React.useState(false)

  //重命名
  const [showRename, setShowRename] = React.useState(false)
  function handleCloseModal() {
    setShowAddTags(false)
    events.emit(ACTIONS.ADD_DOC_TAG)
  }

  var sharingConversationsItem = {
    sendMessage: props.title,
    meTime: props.date,
    content: props.content,
  }
  useEffect(() => {
    setHideCheckbox(props.hideCheckbox)
  }, [props.hideCheckbox])

  //快速预览
  const handleShowEdit = (e) => {
    e.stopPropagation()
    if (localStorage.getItem('docTypeKey') == '共享文档') {
      GetProjectShareContent({
        teamUuid: props.teamUuid,
        uuid: props.uuid,
      }).then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'update-showQuick',
            payload: { showQuick: true },
          })
          dispatch({
            type: 'update-detailData',
            payload: { detailData: res.data.data[0] },
          })
          dispatch({
            type: 'update-tagsList',
            payload: { tagsList: props.tags },
          })
        }
      })
    } else {
      getDocumentDetail({ uuid: props.uuid }).then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'update-showQuick',
            payload: { showQuick: true },
          })
          dispatch({
            type: 'update-detailData',
            payload: { detailData: res.data.data },
          })
          dispatch({
            type: 'update-tagsList',
            payload: { tagsList: props.tags },
          })
        }
      })
    }
  }

  //导出文件夹
  function handelDocToZip() {
    setExportLoading(true)
    axios
      .post(
        '/api/api-aidb/document/folderToZip',
        { uuid: props.uuid },
        { responseType: 'blob' },
      )
      .then((res) => {
        setExportLoading(false)
        const contentDisposition = res.headers['content-disposition']
        const match = contentDisposition.match(
          /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
        )
        let filename
        if (match && match[1]) {
          filename = decodeURIComponent(match[1])
        } else {
          filename = 'report.doc'
        }
        const fileData = new Blob([res.data], { type: 'application/msword' })
        const fileURL = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = filename
        link.click()
        Message.success({
          id: 'need_update',
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: `导出文件夹成功`,
        })
      })
      .catch(() => {
        setExportLoading(false)
        Message.error({
          id: 'need_update',
          icon: <IconErrorTip useCurrentColor={false} />,
          content: `导出文件夹错误`,
        })
      })
  }

  //文档导出/word/pdf/markdown
  const exportHandle = async (type, interfaceName) => {
    setExportLoading(true)
    const res = await getUserInfo({ uid: '' })
    const vipEndDate = new Date(res.data.data.vipEndDate)
    const currentDate = new Date()

    if (currentDate > vipEndDate && !switchProject('DFZ')) {
      Message.error({
        id: 'need_update',
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请开通Vip会员，方可导出',
      })
      setExportLoading(false)
    } else {
      const positionId = localStorage.getItem('positionId')
      const isTeacher = positionId === '1' ? 1 : positionId === '2' ? 0 : null

      axios
        .post(
          `/api/api-aidb/document/${interfaceName}`,
          { uuid: props.uuid, docVersionId: 0 },
          { responseType: 'blob' },
        )
        .then((res) => {
          setExportLoading(false)
          const contentDisposition = res.headers['content-disposition']
          const match = contentDisposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i,
          )

          let filename
          if (match && match[1]) {
            filename = decodeURIComponent(match[1])
          } else {
            filename = `report.${type}`
          }

          const fileData = new Blob([res.data], {
            type: 'application/msword',
          })
          const fileURL = URL.createObjectURL(fileData)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = filename
          link.click()
          Message.success({
            id: 'need_update',
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `导出 ${type} 成功`,
          })
        })
        .catch(() => {
          setExportLoading(false)
          Message.error({
            id: 'need_update',
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `导出 ${type} 错误`,
          })
        })
    }
  }

  //导出message提示
  useEffect(() => {
    if (exportLoading) {
      Message.loading({
        id: 'need_update',
        content: '正在导出中',
        duration: 100000,
      })
    }
  }, [exportLoading])

  const setHref = () => {
    if (location.pathname == '/waste') {
      return '/waste'
    } else {
      // 第一个 urlQuery 对象
      const urlQuery1 = removeEmpty({
        filePath: props.title,
        uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
        folderUuid: GetQueryValue('filePath')
          ? GetQueryValue('uuid')
          : props.uuid,
        teamUuid: GetQueryValue('teamUuid')
          ? GetQueryValue('teamUuid')
          : props.teamUuid,
        // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
        ...(localStorage.getItem('docTypeKey') == '共享文档'
          ? { permission: props.permission }
          : {}),
      }) as any

      // 第二个 urlQuery 对象
      const urlQuery2 = removeEmpty({
        filePath: props.title,
        uuid: GetQueryValue('uuid') ? props.uuid : props.uuid,
        folderUuid: GetQueryValue('filePath')
          ? GetQueryValue('uuid')
          : props.uuid,
      }) as any

      // 获取本地存储中的 docTypeKey
      const docTypeKey = localStorage.getItem('docTypeKey')

      // 判断 docTypeKey 并设置 href
      if (docTypeKey === '共享文档') {
        return `?${new URLSearchParams(urlQuery1).toString()}`
      } else {
        return `?${new URLSearchParams(urlQuery2).toString()}`
      }
    }
  }
  const setHrefDoc = () => {
    if (GetQueryValue('typeKey') == '2') {
      return
    } else {
      if (location.pathname == '/waste') {
        return '/waste'
      } else {
        const urlQuery = removeEmpty({
          filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
          listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
          uuid: props.uuid,
          folderUuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
          teamUuid: GetQueryValue('teamUuid')
            ? GetQueryValue('teamUuid')
            : props.teamUuid,
          title: props.title,
          from: location.pathname.substring(1),
          fileType: props.fileType,
          // 只有当 localStorage 中的 docTypeKey 为 '共享文档' 时才添加 permission 参数
          ...(localStorage.getItem('docTypeKey') == '共享文档'
            ? { permission: props.permission }
            : {}),
        }) as any

        return `/documentEdit?${new URLSearchParams(urlQuery).toString()}`
      }
    }
  }
  return (
    <div className={`${styles['docCardItem']} ${styles[theme]}`}>
      {/* <ShowQuickDrawer data={dataDetail} show={show}></ShowQuickDrawer> */}
      {props.type === 1 && (
        <div
          className={`${
            pageType !== PageType.collectEdit && styles['folder']
          } ${checked ? styles['checked'] : ''}`}>
          <div
            className={`${styles['folder_top']} ${
              styles[folderColor ? folderColor : 'yellow']
            }`}>
            {/*文字*/}
            <a href={setHref()} onClick={goFoldHandle}>
              <div className={styles['folder_text']}>
                {props.title.length > 39
                  ? props.title.slice(0, 39) + '...'
                  : props.title}
              </div>
            </a>
            {/*多选框*/}
            {!hideCheckbox && (
              <div
                className={`${styles['folder_checkbox']} ${
                  checked ? styles['show'] : ''
                }`}>
                <Checkbox
                  checked={checked}
                  onChange={clickMaskHandle}></Checkbox>
              </div>
            )}
            {/*标签*/}
            {props.tags && props.tags.length > 0 && (
              <Tags folderColor={folderColor} tags={props.tags} />
            )}
          </div>
          <div className={styles['item_bottom']}>
            <div className={styles['dateWrap']}>
              {/*回收站日期*/}
              {wasteDate && (
                <div className={styles['wasteDate']}>{wasteDate}</div>
              )}
              {/*是否收藏*/}
              {iscollect == true ? (
                <div className={styles['isFav']}>
                  <IconCollectFill />
                </div>
              ) : (
                ''
              )}
              <div className={styles['date']}>{props.date}</div>
            </div>
            <div className={styles['edit']}>
              {permission != 3 && (
                <Dropdown
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  droplist={dropList}
                  position="br"
                  trigger={'click'}>
                  <div className={styles['icon']}>
                    <IconMoreEdits useCurrentColor={true} />
                  </div>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      )}
      {props.type !== 1 && (
        <div
          className={`${pageType !== PageType.collectEdit && styles['doc']} ${
            checked ? styles['checked'] : ''
          }`}>
          <div className={styles['doc_top']}>
            {/*文字*/}
            <a href={setHrefDoc()} onClick={handleToEdit}>
              <div className={styles['doc_text']}>
                <div className={styles['doc_title']}>{props.title}</div>
                <div className={styles['doc_content']}>{props.content}</div>
              </div>
            </a>
            {/*多选框*/}
            {!hideCheckbox && (
              <div
                className={`${styles['doc_checkbox']} ${
                  checked ? styles['show'] : ''
                }`}>
                <Checkbox
                  checked={checked}
                  onChange={clickMaskHandle}></Checkbox>
              </div>
            )}
            {/*标签*/}
            {props.tags && props.tags?.length > 0 && (
              <Tags type={'doc'} tags={props.tags} />
            )}
          </div>
          {!props.hideBottom && (
            <div className={styles['item_bottom']}>
              <div className={styles['dateWrap']}>
                {/*回收站日期*/}
                {props.wasteDate && (
                  <div className={styles['wasteDate']}>{props.wasteDate}</div>
                )}
                {/*是否收藏*/}
                {iscollect == true ? (
                  <div className={styles['isFav']}>
                    <IconCollectFill />
                  </div>
                ) : (
                  ''
                )}
                <div className={styles['date']}>{props.date}</div>
              </div>

              <div className={styles['edit']}>
                {window.location.search !== '?typeKey=2' && (
                  <div className={styles['show']} onClick={handleShowEdit}>
                    <IconShow />
                  </div>
                )}
                {permission != 3 && (
                  <Dropdown
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    droplist={dropList}
                    position="br"
                    trigger={'click'}>
                    <div className={styles['icon']}>
                      <IconMoreEdits useCurrentColor={true} />
                    </div>
                  </Dropdown>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <CollectModal
        visible={visible1}
        title={props.title}
        content={props.content}
        date={props.date}
        uuid={props.uuid}
        close={() => {
          setVisible1(false)
        }}
      />

      <ModalCustom
        theme={theme}
        maskClosable={false}
        title={props.tags?.length > 0 ? '编辑标签' : '添加标签'}
        visible={showAddTags}
        close={handleCloseModal}
        content={
          <AddTags
            theme={theme}
            tagsList={props.tagsList && props.tagsList}
            uuid={props.uuid}
            tags={props.tags && props.tags}
          />
        }
      />
    </div>
  )
}

export default DocCardItem
