import { ProofTactfulType } from '@/helpers/enums'
import { tactfulCorrection } from '@/model/documentEdit'
import { nonEmptyArray } from '@/utils/common'
import React, { useEffect, useRef, useState } from 'react'
import ProofNumsMod from './proofNumsMod'
import EmptyView from '@/components/Empty'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import ProffTactfulItem from './proofTactfulItem'
import { Message } from '@arco-design/web-react'
import { IconErrorTip, IconSuccessTip } from '@arco-iconbox/react-aidb-v2'
import { XfaLayer } from 'pdfjs-dist'

interface Props {
  defaultContent?: any
  commentProofListDefalut: any
  aiProofListDefalut: any
  adProofListDefalut: any
  multilLangProofListDefalut: any
  stopProofComment: boolean
  stopProofAi: boolean
  stopProofAd: boolean
  stopProofMultilLang: boolean
  onProofTactfulType: (type: string) => void
  onCommentProofList: (data: any) => void
  onAiProofList: (data: any) => void
  onAdProofList: (data: any) => void
  onMultilLangProof: (data: any) => void
  onProofTactfulCommentLoading: (isLoading: boolean) => void
  onProofTactfulAiLoading: (isLoading: boolean) => void
  onProofTactfulAdLoading: (isLoading: boolean) => void
  onProofTactfulMultilLangLoading: (isLoading: boolean) => void
}

const ProofTactfulMod = (props: Props) => {
  const {
    stopProofComment,
    stopProofAi,
    stopProofAd,
    stopProofMultilLang,
    commentProofListDefalut,
    aiProofListDefalut,
    adProofListDefalut,
    multilLangProofListDefalut,
    defaultContent,
    onProofTactfulType,
    onProofTactfulCommentLoading,
    onProofTactfulAiLoading,
    onProofTactfulAdLoading,
    onProofTactfulMultilLangLoading,
    onCommentProofList,
    onAiProofList,
    onAdProofList,
    onMultilLangProof,
  } = props
  const [proofTactfulType, setProofTactfulType] = useState(
    ProofTactfulType.commentProof,
  )
  const [commentProofList, setCommentProofList] = useState(
    commentProofListDefalut,
  )
  const [aiProofList, setAiProofList] = useState(aiProofListDefalut)
  const [adProofList, setAdProofList] = useState(adProofListDefalut)
  const [multilLangProofList, setMultilLangProof] = useState(
    multilLangProofListDefalut,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [commentIsLoading, setCommentIsLoading] = useState(false)
  const [aiIsLoading, setAiIsLoading] = useState(false)
  const [adIsLoading, setAdIsLoading] = useState(false)
  const [multilLangIsLoading, setMultilLangIsLoading] = useState(false)
  const commentProofListRef = useRef([])
  const aiProofListRef = useRef([])
  const adProofListRef = useRef([])
  const multilLangProofListRef = useRef([])
  const stopProofCommentRef = useRef(false)
  const stopProofAiRef = useRef(false)
  const stopProofAdRef = useRef(false)
  const stopProofMultilLangRef = useRef(false)
  useEffect(() => {
    onProofTactfulCommentLoading(commentIsLoading)
  }, [commentIsLoading])

  useEffect(() => {
    onProofTactfulAiLoading(aiIsLoading)
  }, [aiIsLoading])

  useEffect(() => {
    onProofTactfulAdLoading(adIsLoading)
  }, [adIsLoading])

  useEffect(() => {
    onProofTactfulMultilLangLoading(multilLangIsLoading)
  }, [multilLangIsLoading])

  const getTactfulCorrectionHandle = async (contents) => {
    if (contents.length == 0) {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '目前没有内容，请填写内容',
      })
      return
    }
    if (proofTactfulType == ProofTactfulType.commentProof) {
      setCommentIsLoading(true)
    } else if (proofTactfulType == ProofTactfulType.aiProof) {
      setAiIsLoading(true)
    } else if (proofTactfulType == ProofTactfulType.adProof) {
      setAdIsLoading(true)
    } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
      setMultilLangIsLoading(true)
    }
    let arr = []
    const splitString = (str, maxLength) => {
      const result = []
      for (let i = 0; i < str.length; i += maxLength) {
        result.push(str.slice(i, i + maxLength))
      }
      return result
    }
    for (let i = 0; i < contents.length; i++) {
      const stringParts = splitString(contents[i].text, 600)
      for (let j = 0; j < stringParts.length; j++) {
        if (
          proofTactfulType == ProofTactfulType.commentProof &&
          stopProofComment
        ) {
          setCommentIsLoading(false)
          return
        } else if (
          proofTactfulType == ProofTactfulType.aiProof &&
          stopProofAi
        ) {
          setAiIsLoading(false)
          return
        } else if (
          proofTactfulType == ProofTactfulType.adProof &&
          stopProofAd
        ) {
          setAdIsLoading(false)
          return
        } else if (
          proofTactfulType == ProofTactfulType.multilLangProof &&
          stopProofMultilLang
        ) {
          setMultilLangIsLoading(false)
          return
        }
        try {
          const res = await tactfulCorrection({
            content: String.raw`${stringParts[j]}`,
            service: proofTactfulType,
          })

          if (res.data.data.data.reason && res.data.data.data.labels) {
            const proofObj = {
              riskTips: JSON.parse(res.data.data.data.reason).riskTips,
              words: JSON.parse(res.data.data.data.reason).riskWords,
              riskWords: riskWordsSelect(
                stringParts[j].replace(/\s/g, ''),
                JSON.parse(res.data.data.data.reason).riskWords,
              ),
              contentComplete: contents[i].text,
              content: stringParts[j],
              expand: false,
              nodeIndex: i,
              indexNum: i + '-' + j,
            }
            console.log(proofObj, 'proofObj')

            if (proofTactfulType == ProofTactfulType.commentProof) {
              arr.push(proofObj)
              commentProofListRef.current = arr
              setCommentProofList(arr)
            } else if (proofTactfulType == ProofTactfulType.aiProof) {
              arr.push(proofObj)
              aiProofListRef.current = arr
              setAiProofList(arr)
            } else if (proofTactfulType == ProofTactfulType.adProof) {
              arr.push(proofObj)
              adProofListRef.current = arr
              console.log(arr, 'arr')
              setAdProofList(arr)
            } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
              arr.push(proofObj)
              multilLangProofListRef.current = arr
              setMultilLangProof(arr)
            }
          }
        } catch (err) {
          continue // 继续下一次循环
        }
      }

      if (proofTactfulType == ProofTactfulType.commentProof) {
        if (i == contents.length - 1) {
          setCommentIsLoading(false)
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `敏感词通用内容检测完成`,
          })
        }
        // onCommentProofList(commentProofListRef.current)
      } else if (proofTactfulType == ProofTactfulType.aiProof) {
        if (i == contents.length - 1) {
          setAiIsLoading(false)
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `敏感词AIGC类文字检测完成`,
          })
        }

        // onAiProofList(aiProofListRef.current)
      } else if (proofTactfulType == ProofTactfulType.adProof) {
        if (i == contents.length - 1) {
          setAdIsLoading(false)
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `敏感词广告法合规检测完成`,
          })
        }

        // onAdProofList(adProofListRef.current)
      } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
        if (i == contents.length - 1) {
          setMultilLangIsLoading(false)
          Message.success({
            icon: <IconSuccessTip useCurrentColor={false} />,
            content: `敏感词多语言内容检测完成`,
          })
        }
      }
    }
    if (proofTactfulType == ProofTactfulType.commentProof) {
      onCommentProofList(
        commentProofListRef.current.length > 0
          ? commentProofListRef.current
          : null,
      )
    } else if (proofTactfulType == ProofTactfulType.aiProof) {
      onAiProofList(
        aiProofListRef.current.length > 0 ? aiProofListRef.current : null,
      )
    } else if (proofTactfulType == ProofTactfulType.adProof) {
      // setAdIsLoading(false)
      onAdProofList(
        adProofListRef.current.length > 0 ? adProofListRef.current : null,
      )
    } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
      // setMultilLangIsLoading(false)
      onMultilLangProof(
        multilLangProofListRef.current.length > 0
          ? multilLangProofListRef.current
          : null,
      )
    }
  }

  const riskWordsSelect = (content, riskWords) => {
    console.log(content.replace(/\s/g, ''), 'ddd', riskWords)
    const keywords = riskWords.split(',')
    const keywordPositions = []
    keywords.forEach((keyword) => {
      const keywordRegExp = new RegExp(keyword, 'g')
      let match
      while ((match = keywordRegExp.exec(content)) !== null) {
        // 记录关键词的起始位置
        const position = match.index
        keywordPositions.push({ keyword, position })
      }
    })
    return keywordPositions
  }

  const proofTactfulSelect = (list) => {
    list.forEach((item) => {
      events.emit(ACTIONS.ADD_EDITOR_TIP_PROOF_TACTFUL, {
        riskWords: item.riskWords,
        nodeIndex: item.nodeIndex,
      })
    })
  }

  useEffect(() => {
    if (proofTactfulType == ProofTactfulType.commentProof) {
      if (
        commentProofListDefalut === null ||
        (commentProofListDefalut && commentProofListDefalut.length > 0)
      ) {
        if (commentProofListDefalut == null) {
          setCommentProofList([])
        } else {
          setCommentProofList(commentProofListDefalut)
        }
      } else {
        setCommentProofList([])
        getTactfulCorrectionHandle(defaultContent)
      }
    }
  }, [commentProofListDefalut])

  useEffect(() => {
    console.log(aiProofListDefalut, 'aiProofListDefalut')
    if (proofTactfulType == ProofTactfulType.aiProof) {
      if (
        aiProofListDefalut === null ||
        (aiProofListDefalut && aiProofListDefalut.length > 0)
      ) {
        if (aiProofListDefalut === null) {
          setAiProofList([])
        } else {
          setAiProofList(aiProofListDefalut)
        }
      } else {
        setAiProofList([])
        getTactfulCorrectionHandle(defaultContent)
      }
    }
  }, [aiProofListDefalut])

  useEffect(() => {
    if (proofTactfulType == ProofTactfulType.adProof) {
      if (
        adProofListDefalut === null ||
        (adProofListDefalut && adProofListDefalut.length > 0)
      ) {
        if (adProofListDefalut === null) {
          setAdProofList([])
        } else {
          console.log(adProofListDefalut, 'adProofListDefalut')
          setAdProofList(adProofListDefalut)
        }
      } else {
        setAdProofList([])
        getTactfulCorrectionHandle(defaultContent)
      }
    }
  }, [adProofListDefalut])

  useEffect(() => {
    if (proofTactfulType == ProofTactfulType.multilLangProof) {
      if (
        multilLangProofListDefalut === null ||
        (multilLangProofListDefalut && multilLangProofListDefalut.length > 0)
      ) {
        if (multilLangProofListDefalut === null) {
          setMultilLangProof([])
        } else {
          setMultilLangProof(multilLangProofListDefalut)
        }
      } else {
        setMultilLangProof([])
        getTactfulCorrectionHandle(defaultContent)
      }
    }
  }, [multilLangProofListDefalut])

  useEffect(() => {
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
    onProofTactfulType(proofTactfulType)
    expandCloseHandle()
    if (proofTactfulType == ProofTactfulType.commentProof) {
      if (
        commentProofListDefalut === null ||
        (commentProofListDefalut && commentProofListDefalut.length > 0)
      ) {
        if (commentProofListDefalut == null) {
          proofTactfulSelect([])
        } else {
          setCommentProofList(commentProofListDefalut)
        }
      }
    } else if (proofTactfulType == ProofTactfulType.aiProof) {
      if (
        aiProofListDefalut === null ||
        (aiProofListDefalut && aiProofListDefalut.length > 0)
      ) {
        if (aiProofListDefalut === null) {
          setAiProofList([])
        } else {
          setAiProofList(aiProofListDefalut)
        }
      } else {
        getTactfulCorrectionHandle(defaultContent)
      }
    } else if (proofTactfulType == ProofTactfulType.adProof) {
      if (
        adProofListDefalut === null ||
        (adProofListDefalut && adProofListDefalut.length > 0)
      ) {
        if (aiProofListDefalut === null) {
          setAdProofList([])
        } else {
          setAdProofList(adProofListDefalut)
        }
      } else {
        getTactfulCorrectionHandle(defaultContent)
      }
    } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
      if (
        multilLangProofListDefalut === null ||
        (multilLangProofListDefalut && multilLangProofListDefalut.length > 0)
      ) {
        if (multilLangProofListDefalut === null) {
          setMultilLangProof([])
        } else {
          setMultilLangProof(multilLangProofListDefalut)
        }
      } else {
        getTactfulCorrectionHandle(defaultContent)
      }
    }
  }, [proofTactfulType])

  console.log(aiProofListDefalut, commentProofList)

  const proofTactfulNumList = [
    {
      title: '通用内容检测',
      num:
        commentProofListDefalut === null
          ? 0
          : commentProofList
          ? commentProofList.length
          : '-',
      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofTactfulType.commentProof,
    },
    {
      title: 'AIGC类文字检测',
      num:
        aiProofListDefalut == null
          ? 0
          : aiProofList.length > 0
          ? aiProofList.length
          : '-',

      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofTactfulType.aiProof,
    },
    {
      title: '广告法合规检测',
      num:
        adProofListDefalut == null
          ? 0
          : adProofList.length > 0
          ? adProofList.length
          : '-',

      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofTactfulType.adProof,
    },
    {
      title: '多语言内容检测',
      num:
        multilLangProofListDefalut == null
          ? 0
          : multilLangProofList.length > 0
          ? multilLangProofList.length
          : '-',

      boldColor: '#E33E4A',
      color: '#FFDDE0',
      type: ProofTactfulType.multilLangProof,
    },
  ]

  const proofTactfulTypeHandle = (type) => {
    setProofTactfulType(type)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  const expandFilter = (list, indexNum) => {
    console.log(list, indexNum)
    return list.map((v, i) => {
      if (v.indexNum == indexNum) {
        return {
          ...v,
          expand: true,
        }
      } else {
        return {
          ...v,
          expand: false,
        }
      }
    })
  }

  const expandClose = (list) => {
    return (
      list &&
      list.map((v, i) => {
        return {
          ...v,
          expand: false,
        }
      })
    )
  }

  const expandCloseHandle = () => {
    if (proofTactfulType == ProofTactfulType.commentProof) {
      setCommentProofList(expandClose(commentProofList))
    } else if (proofTactfulType == ProofTactfulType.aiProof) {
      setAiProofList(expandClose(aiProofList))
    } else if (proofTactfulType == ProofTactfulType.adProof) {
      setAdProofList(expandClose(adProofList))
    } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
      setMultilLangProof(expandClose(multilLangProofList))
    }
  }

  const expandHandle = (nodeIndex) => {
    console.log(nodeIndex, 'nodeIndex')
    if (proofTactfulType == ProofTactfulType.commentProof) {
      setCommentProofList(expandFilter(commentProofList, nodeIndex))
    } else if (proofTactfulType == ProofTactfulType.aiProof) {
      setAiProofList(expandFilter(aiProofList, nodeIndex))
    } else if (proofTactfulType == ProofTactfulType.adProof) {
      setAdProofList(expandFilter(adProofList, nodeIndex))
    } else if (proofTactfulType == ProofTactfulType.multilLangProof) {
      setMultilLangProof(expandFilter(multilLangProofList, nodeIndex))
    }
  }

  return (
    <div>
      <ProofNumsMod
        list={proofTactfulNumList}
        activeType={proofTactfulType}
        onChangeProofType={(type) =>
          proofTactfulTypeHandle(type)
        }></ProofNumsMod>

      <>
        {!nonEmptyArray(commentProofList) &&
        proofTactfulType == ProofTactfulType.commentProof ? (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 45px 20px',
              marginTop: '20px',
            }}>
            <EmptyView
              type="proofEmpty"
              title={`全面检查您文档中的敏感词错误`}
            />
          </div>
        ) : (
          proofTactfulType == ProofTactfulType.commentProof && (
            <div
              style={{
                height: '68vh',
                overflowY: 'scroll',
                padding: '0 38px 20px',
                marginTop: '20px',
              }}>
              {commentProofList.map((v, i) => {
                return (
                  <ProffTactfulItem
                    key={i}
                    words={v.words}
                    riskTips={v.riskTips}
                    nodeIndex={v.nodeIndex}
                    expand={v.expand}
                    onExpand={expandHandle}
                    content={v.content}
                    indexNum={v.indexNum}
                    contentComplete={v.contentComplete}
                    riskWords={v.riskWords}></ProffTactfulItem>
                )
              })}
            </div>
          )
        )}
        {!nonEmptyArray(aiProofList) &&
        proofTactfulType == ProofTactfulType.aiProof ? (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 45px 20px',
              marginTop: '20px',
            }}>
            <EmptyView
              type="proofEmpty"
              title={`全面检查您文档中的敏感词错误`}
            />
          </div>
        ) : (
          proofTactfulType == ProofTactfulType.aiProof && (
            <div
              style={{
                height: '68vh',
                overflowY: 'scroll',
                padding: '0 38px 20px',
                marginTop: '20px',
              }}>
              {aiProofList.map((v, i) => {
                return (
                  <ProffTactfulItem
                    key={i}
                    words={v.words}
                    riskTips={v.riskTips}
                    nodeIndex={v.nodeIndex}
                    expand={v.expand}
                    onExpand={expandHandle}
                    content={v.content}
                    indexNum={v.indexNum}
                    contentComplete={v.contentComplete}
                    riskWords={v.riskWords}></ProffTactfulItem>
                )
              })}
            </div>
          )
        )}
        {!nonEmptyArray(adProofList) &&
        proofTactfulType == ProofTactfulType.adProof ? (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 45px 20px',
              marginTop: '20px',
            }}>
            <EmptyView
              type="proofEmpty"
              title={`全面检查您文档中的敏感词错误`}
            />
          </div>
        ) : (
          proofTactfulType == ProofTactfulType.adProof && (
            <div
              style={{
                height: '68vh',
                overflowY: 'scroll',
                padding: '0 38px 20px',
                marginTop: '20px',
              }}>
              {adProofList.map((v, i) => {
                return (
                  <ProffTactfulItem
                    key={i}
                    words={v.words}
                    indexNum={v.indexNum}
                    riskTips={v.riskTips}
                    nodeIndex={v.nodeIndex}
                    expand={v.expand}
                    onExpand={expandHandle}
                    content={v.content}
                    contentComplete={v.contentComplete}
                    riskWords={v.riskWords}></ProffTactfulItem>
                )
              })}
            </div>
          )
        )}
        {!nonEmptyArray(multilLangProofList) &&
        proofTactfulType == ProofTactfulType.multilLangProof ? (
          <div
            style={{
              height: '68vh',
              overflowY: 'scroll',
              padding: '0 45px 20px',
              marginTop: '20px',
            }}>
            <EmptyView
              type="proofEmpty"
              title={`全面检查您文档中的敏感词错误`}
            />
          </div>
        ) : (
          proofTactfulType == ProofTactfulType.multilLangProof && (
            <div
              style={{
                height: '68vh',
                overflowY: 'scroll',
                padding: '0 38px 20px',
                marginTop: '20px',
              }}>
              {multilLangProofList.map((v, i) => {
                return (
                  <ProffTactfulItem
                    key={i}
                    words={v.words}
                    indexNum={v.indexNum}
                    riskTips={v.riskTips}
                    riskWords={v.riskWords}
                    expand={v.expand}
                    nodeIndex={v.nodeIndex}
                    content={v.content}
                    contentComplete={v.contentComplete}
                    onExpand={expandHandle}></ProffTactfulItem>
                )
              })}
            </div>
          )
        )}
      </>
    </div>
  )
}

export default ProofTactfulMod
