import React from 'react'
import styles from './style/index.module.less'

function IconBtn(props) {
  const { theme } = props
  return (
    <div
      className={`${styles[theme]}
        ${props.className ? props.className : styles['btn']} 
        ${styles[props.color]}
        ${props.bg ? styles['bg'] : ''}
      `}
      onClick={props.onClick}
      style={{
        pointerEvents: props.disable ? 'none' : 'auto',
      }}>
      {props.iconLeft && (
        <div className={styles['btnIconLeft']}>{props.iconLeft}</div>
      )}
      {props.text && <div className={styles['btnText']}>{props.text}</div>}
      {props.iconRight && (
        <div className={styles['btnIconRight']}>{props.iconRight}</div>
      )}
    </div>
  )
}

export default IconBtn
