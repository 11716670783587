import { removeEmpty } from '@/utils/common'
import Request from '@/utils/request'
import { EDITOR_MODIFY_RECORD_REQUEST_COUNT } from '@/config/editor-config'
import {
  API_POST_DOCUMENT_LIST,
  API_POST_MOVE_DOCUMENT,
  API_POST_REMOVE_DOCUMENT,
  API_POST_RENAME_DOCUMENT,
  API_POST_SAVE_DOCUMENT,
  API_POST_SAVE_SYNERGIA,
  API_POST_GET_DOCUMENT_GETSTULIST,
  API_POST_DOCUMENT_EMPTY,
  API_POST_CLEAR_DOCUMENT,
  API_POST_RETURN_DOCUMENT,
  API_POST_FOLD_LIST,
  API_POST_GET_DOCUMENT_GETSTUPOSTLIST,
  API_POST_MAKE_COPY_DOCUMENT,
  API_POST_GET_DOCUMENT_DETAIL,
  API_POST_APPROVALDOCUMENT,
  API_POST_GETWASTEBASKETLIST,
  API_POST_GET_DOC_APPROVAL_LIST,
  API_POST_SEARCH_DOCUMENT_LIST,
  API_POST_KEY_WORDS,
  API_POST_TEXT_ABSTRACT,
  API_POST_TEXT_KEY,
  API_POST_TITLE_KEY,
  API_POST_REVERES,
  API_POST_SEARCH_SENTENCE,
  API_POST_HTMLWORD,
  API_POST_WRITING_REPORT,
  API_POST_SENTIMENT_CLASSIFY,
  API_POST_GET_DOCUMENT_PROPERTY,
  API_POST_GET_USERINFO,
  API_POST_EDIT_USERINFO,
  API_POST_EDIT_PASSWORD,
  API_POST_SEND_EMAIL,
  API_POST_RECOMMENDLIST,
  API_POST_DOCUMENT_COLLECT,
  API_POST_CANCEL_DOCUMENT_COLLECT,
  API_POST_DOCUMENT_COLLECT_LIST,
  API_POST_SEARCH_COLLECT_LIST,
  API_POST_GET_DOCUMENT_TAGLIST,
  API_POST_SAVE_TAG,
  API_POST_EDIT_TAG,
  API_POST_DELETE_TAG,
  API_POST_ADD_DOC_TAG,
  API_POST_COLLECT_LIST_TAG,
  API_POST_CHAT_LIST_COLLECT,
  API_POST_CANCEL_CHAT,
  API_POST_TEXT_STREAM_GPT,
  API_POST_CHATGPT_SAVE,
  API_POST_PUBLISH_DOCUMENT,
  API_POST_SEND_SMS_CODE,
  API_POST_MODIFY_EMAIL,
  API_POST_MODIFY_PHONE,
  API_POST_REGISTER_PHONE,
  API_POST_REGISTER_EMAIL,
  API_POST_EMAIL_LOGIN,
  API_POST_PHONE_LOGIN,
  API_POST_CREATE_QRCODE,
  API_POST_QUERY_QRCODE,
  API_POST_QUERY_QRCODE_LOGIN,
  API_POST_WX_BIND_PHONE,
  API_POST_WX_JUMP_TO,
  API_POST_WX_UNBIND,
  API_POST_ALIYUN_SMSCODRE,
  API_POST_SUBMIT_AIIMG,
  API_POST_AIIMG_FETCH_ID,
  API_POST_AIIMG_SIMPLE_CHANGE,
  API_POST_AIIMG_AGAIN,
  API_POST_AIIMG_COOLECT,
  API_POST_AIIMG_CANCEL_COOLECT,
  API_POST_AIIMG_LISTPAGE,
  API_POST_AIIMG_CARMI,
  API_POST_AIIMG_SUBMIT_BLEND,
  API_POST_AIIMG_SUBMIT_DESCRIBE,
  API_POST_GET_USER_TOKEN,
  API_POST_BIND_USER_WX,
  API_POST_WX_OAUTH_LINK,
  API_POST_LOGIN_INVITATION_QRCODE,
  API_POST_AIIMG_ACTION,
  API_POST_DOCUMENT_REPLACECOVER,
  API_POST_SAVE_DOC_TEM,
  API_POST_GET_MY_TEM_LIST,
  API_POST_DOC_LIST_BY_TREE,
  API_POST_DOC_MOVE,
  API_POST_DOC_MOVE_SORT,
  API_POST_USER_SOURCE,
  API_GET_ALL_AGENT,
  API_POST_CREATE_AGENT,
  API_POST_SAVE_AGENT_DRAFT,
  API_POST_MY_AGENT_LIST,
  API_POST_DELETE_AGENT,
  API_POST_AGENT_DETAIL,
  API_POST_AGENT_SHOW_TYPE_LIST,
  API_POST_AGENT_PLUBLISH,
  API_POST_GET_TEAM_LIST,
  API_POST_ADD_TEAM_PEOPLE,
  API_POST_GET_SYNERGIA_LIST,
  API_POST_EDIT_TEAM_PEOPLE_AUTHORITY,
  API_POST_CANCEL_TEAM_PEOPLE,
  API_POST_SYNERGIA_SHARE_LINK,
  API_POST_REFRESH_SHARE_LINK,
  API_POST_GET_PROJECT_CONTENT,
  API_POST_GET_TEAM_RENAME_DOC,
  API_POST_GET_SEARCH_TEAM_LIST,
  API_POST_GET_TEAM_FOLDER_LIST_BY_UUID,
  API_POST_GET_TEAM_TREE_LIST,
  API_POST_TEAM_DOCUMENT_REPLACECOVER,
  API_POST_TEAM_MOVE_DOCUMENT,
  API_POST_DOC_FOLDER_LIST,
  API_POST_CANCEL_DOC_PUBLISH,
  API_POST_AGENT_AVTAR,
  API_POST_CREATE_AGENT_DARFT,
  API_POST_GET_MY_COLLECT_AGENT,
  API_POST_COLLECT_AGENT,
  API_POST_CANCEL_COLLECT_AGENT,
  API_POST_GET_TEAM_PEOPLE_STATUS_DOC,
  API_POST_DELETE_TEAM_PEOPLE_STATUS_DOC,
  ADD_DOCUMENT_MODIFY_RECORD,
  PAGE_QUERY_ADD_DOCUMENT_MODIFY_RECORD,
  QUERY_DOCUMENT_CONTRIBUTION,
  QUERY_FOLDER_CONTRIBUTION,
  GET_FOLDER_PROPERTY
} from './endpoints'

export function getDocumentList({
  docStatus,
  orderBy,
  filePath,
  folderUuid,
  uuids,
}: // 私信的内容
  {
    docStatus?: number
    orderBy?: string
    filePath?: string
    folderUuid?: string
    uuids?: any
  }) {
  const params = removeEmpty({
    docStatus, //（0-正常，1-放入废纸篓）
    orderBy,
    filePath,
    folderUuid,
    uuids,
  })
  return Request.post(API_POST_DOCUMENT_LIST, { ...params })
}

export function removeDocument({
  docStatus,
  uuids,
}: {
  docStatus?: number
  uuids: any
}) {
  const params = removeEmpty({
    docStatus, //（0-正常，1-放入废纸篓）
    uuids,
  })
  // 移除时将localStorage中保存的文章初识位置移除，不然会越积越多
  if (docStatus === 1) {
    uuids.forEach((uuid) => {
      localStorage.removeItem(`editorScrollPosition_${uuid}`)
    })
  }
  return Request.post(API_POST_REMOVE_DOCUMENT, { ...params })
}

export function renameDocument({
  title,
  uuid,
}: {
  title: string
  uuid: string
}) {
  const params = removeEmpty({
    title,
    uuid,
  })
  return Request.post(API_POST_RENAME_DOCUMENT, { ...params })
}

// 编辑器文档保存
export function saveDocument({
  content,
  docType,
  docWords,
  fileCoverIcon,
  fileLevel,
  filePath,
  fileType,
  title,
  uuid,
  folderUuid,
  introduction,
  approvalStatus,
  docVersionType,
  widthShow,
  folderColor,
  teamUuid,
}: {
  approvalStatus?: number
  content?: string
  docStatus?: number
  docType?: number
  docWords?: number
  fileCoverIcon?: string
  fileLevel?: number
  filePath?: string
  fileType?: number
  title?: string
  uuid?: string
  folderUuid?: string
  introduction?: string
  docVersionType: number
  widthShow?: number
  folderColor?: string
  teamUuid?: string
}) {
  const params = {
    approvalStatus,
    content,
    docStatus: 0,
    docType,
    docWords,
    fileCoverIcon,
    fileLevel,
    filePath,
    fileType,
    title,
    uuid,
    folderUuid,
    introduction,
    docVersionType,
    widthShow,
    folderColor,
    teamUuid,
  }

  return Request.post(
    !teamUuid ? API_POST_SAVE_DOCUMENT : API_POST_SAVE_SYNERGIA,
    { ...params },
  )
}

export function moveDocument({
  filePath,
  uuids,
  folderUuid,
}: {
  filePath?: string
  uuids: any
  folderUuid?: any
}) {
  const params = removeEmpty({
    filePath,
    uuids,
    folderUuid,
  })
  return Request.post(API_POST_MOVE_DOCUMENT, { ...params })
}
// 获取学生列表
export function getStuList({
  status,
  searchKey,
}: {
  status: number
  searchKey?: any
}) {
  const params = removeEmpty({
    status,
    searchKey,
  })
  return Request.post(API_POST_GET_DOCUMENT_GETSTULIST, { ...params })
}
// 获取学生文档列表
export function getStuPostList({ stuId }: { stuId: any }) {
  const params = removeEmpty({
    stuId,
  })
  return Request.post(API_POST_GET_DOCUMENT_GETSTUPOSTLIST, { ...params })
}
// 获取学生文档详情
export function getDocumentDetail({ uuid }: { uuid: any }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_GET_DOCUMENT_DETAIL, { ...params })
}
// 审批学生文档
export function approvalDocument({
  uuid,
  approvalStatus,
  remark,
}: {
  uuid: any
  approvalStatus: any
  remark: any
}) {
  const params = removeEmpty({
    uuid,
    approvalStatus,
    remark,
  })
  return Request.post(API_POST_APPROVALDOCUMENT, { ...params })
}

//获取废纸篓列表
export function getWasteBasketList({ searchKey }: { searchKey?: any }) {
  const params = removeEmpty({
    searchKey,
  })
  return Request.post(API_POST_GETWASTEBASKETLIST, { ...params })
}
// 清空废纸篓
export function emptyWaste() {
  return Request.post(API_POST_DOCUMENT_EMPTY)
}

//删除废纸篓
export function clearDocument({ uuids }: { uuids: any }) {
  const params = removeEmpty({
    uuids,
  })
  return Request.post(API_POST_CLEAR_DOCUMENT, { ...params })
}

//还原废纸篓
export function returnDocument({
  uuids,
  docStatus, //0-正常，1-放入废纸篓
}: {
  uuids: any
  docStatus: number
}) {
  const params = removeEmpty({
    uuids,
    docStatus,
  })
  return Request.post(API_POST_RETURN_DOCUMENT, { ...params })
}

//获取文件夹列表
export function getFoldList() {
  return Request.post(API_POST_FOLD_LIST)
}

//生成副本
export function makeCopyDocument({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_MAKE_COPY_DOCUMENT, { ...params })
}

//获取审批日志
export function getDocApprovalList({ uuid }: { uuid: string }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_GET_DOC_APPROVAL_LIST, { ...params })
}
// 搜索AI写作文档列表
export function getSearchList({
  searchKey,
  pageIndex,
  pageSize,
}: {
  searchKey: any
  pageIndex?: any
  pageSize?: any
}) {
  const params = removeEmpty({
    searchKey,
    pageIndex,
    pageSize,
  })
  return Request.post(API_POST_SEARCH_DOCUMENT_LIST, { ...params })
}

// 工具关键词提取
export function getKeyWords({
  num,
  text,
  uuid,
}: {
  num?: any
  text: any
  uuid: any
}) {
  const params = removeEmpty({
    num,
    text,
    uuid,
  })
  return Request.post(API_POST_KEY_WORDS, { ...params })
}

// 工具文本摘要
export function getTextAbstarct({
  content,
  maxSummaryLen,
  title,
  uuid,
}: {
  content: any
  maxSummaryLen: any
  title?: any
  uuid: any
}) {
  const params = removeEmpty({
    content,
    maxSummaryLen,
    title,
    uuid,
  })
  return Request.post(API_POST_TEXT_ABSTRACT, { ...params })
}

// 工具文章标签
export function getTextKey({
  content,
  title,
  uuid,
}: {
  content: any
  title: any
  uuid: any
}) {
  const params = removeEmpty({
    content,
    title,
    uuid,
  })
  return Request.post(API_POST_TEXT_KEY, { ...params })
}

// 工具标题推荐
export function getTitleKey({ doc, uuid }: { doc: any; uuid: any }) {
  const params = removeEmpty({
    doc,
    uuid,
  })
  return Request.post(API_POST_TITLE_KEY, { ...params })
}

// 工具反向词典
export function getReveres({
  ip,
  isFilter,
  uuid,
  mode,
  num,
  query,
  userAgent,
}: {
  isFilter: any
  uuid: any
  ip: any
  mode: any
  num: any
  query: any
  userAgent: any
}) {
  const params = removeEmpty({
    isFilter,
    uuid,
    ip,
    num,
    mode,
    query,
    userAgent,
  })
  return Request.post(API_POST_REVERES, { ...params })
}

// 工具据意查句
export function getSearchSentence({
  query,
  type,
  uuid,
}: {
  query: string
  type: any
  uuid: any
}) {
  const params = removeEmpty({
    query,
    type,
    uuid,
  })
  return Request.post(API_POST_SEARCH_SENTENCE, { ...params })
}

// 工具导出word
export function getHtmlWord({
  uuid,
  docVersionId,
  isTeacher,
}: {
  uuid: any
  docVersionId: any
  isTeacher: any
}) {
  const params = removeEmpty({
    uuid,
    docVersionId,
    isTeacher,
  })
  return Request.post(API_POST_HTMLWORD, { ...params })
}

// 工具导出写作报告
export function getWritingReport({ uuid }: { uuid: any }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_WRITING_REPORT, { ...params })
}

// 工具情感分析
export function sentimentClassify({ text }: { text: any }) {
  const params = removeEmpty({
    text,
  })
  return Request.post(API_POST_SENTIMENT_CLASSIFY, { ...params })
}

//工具  属性
export function getDocumentProperty({ uuid }: { uuid: any }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_GET_DOCUMENT_PROPERTY, { ...params })
}

//获取用户个人信息
export function getUserInfo({ uid }: { uid: any }) {
  const params = removeEmpty({
    uid,
  })
  return Request.post(API_POST_GET_USERINFO, { ...params })
}

//修改个人信息
export function editUserInfo({
  name,
  mobile,
  email,
  uid,
}: {
  name?: any
  mobile?: any
  email?: any
  uid?: any
}) {
  const params = removeEmpty({
    name,
    mobile,
    email,
    uid,
  })
  return Request.post(API_POST_EDIT_USERINFO, { ...params })
}

//修改密码
export function editPassword({
  code,
  emailOrPhone,
  password,
  repeatPassword,
  type,
}: {
  code
  emailOrPhone
  password
  repeatPassword
  type
}) {
  const params = removeEmpty({
    code,
    emailOrPhone,
    password,
    repeatPassword,
    type,
  })
  return Request.post(API_POST_EDIT_PASSWORD, { ...params })
}

//发送邮箱验证码
export function sendEmail({ email, type }: { email: any; type: any }) {
  const params = removeEmpty({
    email,
    type,
  })
  return Request.post(API_POST_SEND_EMAIL, { ...params })
}

//智能文档推荐
export function getRecommendDocumentList() {
  return Request.post(API_POST_RECOMMENDLIST)
}

//获取收藏文档列表
export function getCollectList({
  orderBy,
  uuids,
}: {
  orderBy?: any
  uuids?: any
}) {
  const params = {
    orderBy,
    uuids,
  }
  return Request.post(API_POST_DOCUMENT_COLLECT_LIST, { ...params })
}
//收藏文档
export function getDocumentCollect({ uuid }: { uuid: any }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_DOCUMENT_COLLECT, { ...params })
}

//取消收藏文档
export function getCancelCollect({ uuids }: { uuids: any }) {
  const params = removeEmpty({
    uuids,
  })
  return Request.post(API_POST_CANCEL_DOCUMENT_COLLECT, { ...params })
}

//搜索收藏列表
export function getSearchCollect({ searchKey }: { searchKey: any }) {
  const params = removeEmpty({
    searchKey,
  })
  return Request.post(API_POST_SEARCH_COLLECT_LIST, { ...params })
}

// 获取标签列表
export function getDocumentTagList() {
  return Request.post(API_POST_GET_DOCUMENT_TAGLIST)
}

// 新建标签
export function addTag({ tagName }: { tagName: any }) {
  const params = removeEmpty({
    tagName,
  })
  return Request.post(API_POST_SAVE_TAG, { ...params })
}

// 编辑文档标签
export function editTag({ tagName, uuid }: { tagName: any; uuid: any }) {
  const params = removeEmpty({
    tagName,
    uuid,
  })
  return Request.post(API_POST_EDIT_TAG, { ...params })
}

// 删除文档标签
export function deleteTag({ uuid }: { uuid: any }) {
  const params = removeEmpty({
    uuid,
  })
  return Request.post(API_POST_DELETE_TAG, { ...params })
}

// 新建文档标签
export function addDocTag({ uuid, uuids }: { uuid: any; uuids: any }) {
  const params = {
    uuid,
    uuids,
  }
  return Request.post(API_POST_ADD_DOC_TAG, { ...params })
}

// 获取收藏列表标签
export function getCollectTagList() {
  return Request.post(API_POST_COLLECT_LIST_TAG)
}

// 获取聊天记录收藏列表
export function getChatCollectList({
  pageIndex,
  pageSize,
  searchKey,
}: {
  pageIndex?: any
  pageSize?: any
  searchKey?: any
}) {
  const params = {
    pageIndex,
    pageSize,
    searchKey,
  }
  return Request.post(API_POST_CHAT_LIST_COLLECT, { ...params })
}

// 取消聊天记录收藏
export function getCancelChat({ messageIds }: { messageIds: any }) {
  const params = {
    messageIds,
  }
  return Request.post(API_POST_CANCEL_CHAT, { ...params })
}

// 取消聊天记录收藏
export function getTextStreamGPT({
  conversationId,
  message,
  parentMessageId,
  stream,
  to,
  type,
}: {
  conversationId?: string
  message: string
  parentMessageId?: string
  stream?: boolean
  to?: string
  type?: number
}) {
  const params = {
    conversationId,
    message,
    parentMessageId,
    stream,
    to,
    type,
  }
  return Request.post(API_POST_TEXT_STREAM_GPT, { ...params })
}

//记录chatgpt记录
export function getChatGptSave({
  conversationId,
  input,
  messageId,
  model,
  output,
  type,
  agentId,
}: {
  conversationId?: any
  input: any
  messageId?: any
  model?: any
  output?: any
  agentId?: any
  type?: any
}) {
  const params = {
    conversationId,
    input,
    messageId,
    model,
    output,
    type,
    agentId,
  }
  return Request.post(API_POST_CHATGPT_SAVE, { ...params })
}

//发布文档
export function getPublishDocument({ uuid, docVersionType }) {
  const params = {
    uuid,
    docVersionType,
  }
  return Request.post(API_POST_PUBLISH_DOCUMENT, { ...params })
}

//发送手机验证码
export function sendSMSCode({ areaCode, phone, type }) {
  const params = {
    areaCode,
    phone,
    type,
  }
  return Request.post(API_POST_SEND_SMS_CODE, { ...params })
}

//修改邮箱
export function modifyEmail({ code, email }) {
  const params = {
    code,
    email,
  }
  return Request.post(API_POST_MODIFY_EMAIL, { ...params })
}

//修改手机号
export function modifyPhone({ code, phone }) {
  const params = {
    code,
    phone,
  }
  return Request.post(API_POST_MODIFY_PHONE, { ...params })
}

//手机号注册
export function registerPhone({ code, phone, password, repeatPassword }) {
  const params = {
    code,
    phone,
    password,
    repeatPassword,
  }
  return Request.post(API_POST_REGISTER_PHONE, { ...params })
}

//邮箱注册
export function registerEmail({ emailCode, email, password, repeatPassword }) {
  const params = {
    emailCode,
    email,
    password,
    repeatPassword,
  }
  return Request.post(API_POST_REGISTER_EMAIL, { ...params })
}

//邮箱登录
export function emailLogin({ password, uid }) {
  const params = {
    password,
    uid,
  }
  return Request.post(API_POST_EMAIL_LOGIN, { ...params })
}

//手机号登录
export function phoneLogin({ code, phone }) {
  const params = {
    code,
    phone,
  }
  return Request.post(API_POST_PHONE_LOGIN, { ...params })
}

//获取登陆二维码
export function getCreateQrCode({ sceneId }) {
  const params = {
    sceneId,
  }
  return Request.post(API_POST_CREATE_QRCODE, { ...params })
}

//轮询查询登陆二维码是否失效
export function getQueryQrCode({ sceneId }) {
  const params = {
    sceneId,
  }
  return Request.post(API_POST_QUERY_QRCODE, { ...params })
}

//轮询查询登陆状态
export function getQueryQrCodeLogin({ sceneId }) {
  const params = {
    sceneId,
  }
  return Request.post(API_POST_QUERY_QRCODE_LOGIN, { ...params })
}

//微信绑定手机号
export function wxBindPhone({ sceneId, code, phone }) {
  const params = {
    code,
    phone,
    sceneId,
  }
  return Request.post(API_POST_WX_BIND_PHONE, { ...params })
}

//跳过绑定手机号
export function wxJumpTo({ sceneId }) {
  const params = {
    sceneId,
  }
  return Request.post(API_POST_WX_JUMP_TO, { ...params })
}

//解除微信手机号绑定
export function wxUnBindPhone() {
  return Request.post(API_POST_WX_UNBIND)
}

//阿里云校验
export function aliyunSmsCode(captchaVerifyParam) {
  const params = {
    captchaVerifyParam,
  }
  return Request.post(API_POST_ALIYUN_SMSCODRE, { ...params })
}

//ai绘图任务提交
export function aiImgSubmit({ base64Array, prompt, notifyHook, state }) {
  const params = {
    base64Array,
    prompt,
    notifyHook,
    state,
  }
  return Request.post(API_POST_SUBMIT_AIIMG, { ...params })
}

//ai绘图根据id查询结果
export function aiImgGetId({ id }) {
  const params = {
    id,
  }
  return Request.post(API_POST_AIIMG_FETCH_ID, { ...params })
}

//ai绘图重新生成大图U1V1
export function aiImgSimpleChange({ content, notifyHook, state }) {
  const params = {
    content,
    notifyHook,
    state,
  }
  return Request.post(API_POST_AIIMG_SIMPLE_CHANGE, { ...params })
}

//ai绘图重新生成
export function aiImgAgain({ action, taskId, notifyHook, state, index }) {
  const params = {
    action,
    notifyHook,
    state,
    taskId,
    index,
  }
  return Request.post(API_POST_AIIMG_AGAIN, { ...params })
}

//ai绘图收藏
export function aiImgCoolect({ imageUrl, imageId, state, type }) {
  const params = {
    state,
    imageId,
    imageUrl,
    type,
  }
  return Request.post(API_POST_AIIMG_COOLECT, { ...params })
}

//ai绘图取消收藏
export function aiImgCancelCoolect({ imageId }) {
  const params = {
    imageId,
  }
  return Request.post(API_POST_AIIMG_CANCEL_COOLECT, { ...params })
}

//ai绘图查询收藏列表
export function aiImgGetCoolectList({ searchKey, pageIndex, pageSize }) {
  const params = {
    searchKey,
    pageIndex,
    pageSize,
  }
  return Request.post(API_POST_AIIMG_LISTPAGE, { ...params })
}

//ai图生图
export function aiImgBlend({ base64Array, dimensions, notifyHook, state }) {
  const params = {
    base64Array,
    dimensions,
    notifyHook,
    state,
  }
  return Request.post(API_POST_AIIMG_SUBMIT_BLEND, { ...params })
}

//ai图生图
export function aiImgDescribe({ base64, notifyHook, state }) {
  const params = {
    base64,
    notifyHook,
    state,
  }
  return Request.post(API_POST_AIIMG_SUBMIT_DESCRIBE, { ...params })
}

/* 卡密验证码 */
export function aiImgGetCarMi({ code }) {
  const params = {
    code,
  }
  return Request.post(API_POST_AIIMG_CARMI, { ...params })
}

/* 小程序获取token */
export function getUserToken({ code }) {
  const params = {
    code,
  }
  return Request.post(API_POST_GET_USER_TOKEN, { ...params })
}

/* 第三方使用网站应用授权登录链接 */
export function wxOauthLink() {
  return Request.get(API_POST_WX_OAUTH_LINK)
}

/* 小程序获取token */
export function registerQrcode() {
  return Request.post(API_POST_LOGIN_INVITATION_QRCODE)
}

//ai绘图重新生成SVZ
export function aiImgAction({ customId, taskId, state }) {
  const params = {
    customId,
    taskId,
    state,
  }
  return Request.post(API_POST_AIIMG_ACTION, { ...params })
}

//更换封面
export function replaceCover({ fileCoverIcon, title, uuid }) {
  const params = {
    fileCoverIcon,
    title,
    uuid,
  }
  return Request.post(API_POST_DOCUMENT_REPLACECOVER, { ...params })
}

//保存为自定义模版
export function saveDocTemplate({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(API_POST_SAVE_DOC_TEM, { ...params })
}

//获取自定义模版列表
export function getMyDocTem({ pageIndex, pageSize }) {
  const params = {
    pageIndex,
    pageSize,
  }
  return Request.post(API_POST_GET_MY_TEM_LIST, { ...params })
}

//获取自定义文档树列表
export function DocumentTreeList({ uuid, uuids }: { uuid; uuids?: any }) {
  const params = {
    uuid,
    uuids,
  }
  return Request.post(API_POST_DOC_LIST_BY_TREE, { ...params })
}

//移动文档
export function DocMove({
  uuids,
  folderUuid,
}: {
  uuids: any
  folderUuid?: any
}) {
  const params = {
    uuids,
    folderUuid,
  }
  return Request.post(API_POST_DOC_MOVE, { ...params })
}

//移动文档排序
export function DocMoveSort({ moveSortList }) {
  const params = {
    moveSortList,
  }
  return Request.post(API_POST_DOC_MOVE_SORT, { ...params })
}

//记录用户来源埋点
export function GetUserSource({
  utmSource,
  type,
  employeeId,
  applyId,
}: {
  utmSource
  type
  employeeId
  applyId?: any
}) {
  const params = {
    utmSource,
    type,
    employeeId,
    applyId,
  }
  return Request.post(API_POST_USER_SOURCE, { ...params })
}

//所有智能体列表
export function AgentAllList({ description, name }) {
  const params = {
    description,
    name,
  }
  return Request.post(API_GET_ALL_AGENT, { ...params })
}

//所有智能体列表
export function MyAgentList() {
  return Request.get(API_POST_MY_AGENT_LIST)
}

//智能体列表类型
export function AgentTypeList() {
  return Request.get(API_POST_AGENT_SHOW_TYPE_LIST)
}

//新建智能体
export function CreateAgent({ message }) {
  const params = {
    message,
  }
  return Request.post(API_POST_CREATE_AGENT, { ...params })
}

//删除智能体
export function DeleteAgent({
  templateUuid,
  uuid,
}: {
  templateUuid?: string
  uuid?: string
}) {
  const params = {
    templateUuid,
    uuid,
  }
  return Request.post(API_POST_DELETE_AGENT, { ...params })
}

//保存智能体草稿
export function SaveAgent({
  conversationStarters,
  description,
  faceImg,
  instructions,
  name,
  uuid,
}) {
  const params = {
    conversationStarters,
    description,
    faceImg,
    instructions,
    name,
    uuid,
  }
  return Request.post(API_POST_SAVE_AGENT_DRAFT, { ...params })
}

//查看智能体详情
export function ShowAgentDetail({
  templateUuid,
  uuid,
}: {
  templateUuid?: string
  uuid?: string
}) {
  const params = {
    templateUuid,
    uuid,
  }
  return Request.post(API_POST_AGENT_DETAIL, { ...params })
}

//发布智能体
export function PublishAgent({
  link,
  type,
  uuid,
  agentTypeUuid,
}: {
  link?: string
  agentTypeUuid?: string
  uuid: string
  type: string
}) {
  const params = {
    link,
    uuid,
    type,
    agentTypeUuid,
  }
  return Request.post(API_POST_AGENT_PLUBLISH, { ...params })
}

//获取分享协同文档列表
export function GetTeamList({ folderUuid, teamUuid, orderBy }) {
  const params = {
    folderUuid,
    teamUuid,
    orderBy,
  }
  return Request.post(API_POST_GET_TEAM_LIST, { ...params })
}

//协同管理项目成员
export function AddTeamPeople({ employeeIds, permission, uuid }) {
  const params = {
    employeeIds,
    permission,
    uuid,
  }
  return Request.post(API_POST_ADD_TEAM_PEOPLE, { ...params })
}

//获取协同管理成员列表
export function GetSynergia({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(API_POST_GET_SYNERGIA_LIST, { ...params })
}

//修改协同管理成员权限
export function EditPeopleAuthority({ uuid, permission }) {
  const params = {
    uuid,
    permission,
  }
  return Request.post(API_POST_EDIT_TEAM_PEOPLE_AUTHORITY, { ...params })
}

//取消协同人员
export function CancelTeamPeople({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(API_POST_CANCEL_TEAM_PEOPLE, { ...params })
}

//获取协同管理分享链接
export function GetTeamShareLink({ uuid, permission }) {
  const params = {
    uuid,
    permission,
  }
  return Request.post(API_POST_SYNERGIA_SHARE_LINK, { ...params })
}

//获取协同管理分享链接
export function RefreshTeamShareLink({ uuid, permission }) {
  const params = {
    uuid,
    permission,
  }
  return Request.post(API_POST_REFRESH_SHARE_LINK, { ...params })
}

//根据分享链接获取协同管理内容
export function GetProjectShareContent({
  uuid,
  teamUuid,
  state,
}: {
  uuid: any
  teamUuid: any
  state?: any
}) {
  const params = {
    uuid,
    teamUuid,
    state,
  }
  return Request.post(API_POST_GET_PROJECT_CONTENT, { ...params })
}

//重命名共享文档
export function RenameDoc({ uuid, title }) {
  const params = {
    uuid,
    title,
  }
  return Request.post(API_POST_GET_TEAM_RENAME_DOC, { ...params })
}

//搜索共享列表
export function GetSearchTeamList({
  searchKey,
  pageIndex,
  pageSize,
}: {
  searchKey: any
  pageIndex?: any
  pageSize?: any
}) {
  const params = removeEmpty({
    searchKey,
    pageIndex,
    pageSize,
  })
  return Request.post(API_POST_GET_SEARCH_TEAM_LIST, { ...params })
}

//根据文档uuid获取当前共享文件夹列表
export function GetTeamFodlerUuidList({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(API_POST_GET_TEAM_FOLDER_LIST_BY_UUID, { ...params })
}

//智能体列表类型
export function GetTeamTreeList() {
  return Request.post(API_POST_GET_TEAM_TREE_LIST)
}

//更换共享文档封面
export function ReplaceTeamCover({ fileCoverIcon, title, uuid }) {
  const params = {
    fileCoverIcon,
    title,
    uuid,
  }
  return Request.post(API_POST_TEAM_DOCUMENT_REPLACECOVER, { ...params })
}

//移动共享文档
export function TeamDocMove({
  uuids,
  folderUuid,
}: {
  uuids: any
  folderUuid?: any
}) {
  const params = {
    uuids,
    folderUuid,
  }
  return Request.post(API_POST_TEAM_MOVE_DOCUMENT, { ...params })
}

//获取智能文档文件夹列表
export function GetDocFolderList() {
  return Request.post(API_POST_DOC_FOLDER_LIST)
}

//取消发布文档
export function CancelDocPublish({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(API_POST_CANCEL_DOC_PUBLISH, { ...params })
}

//生成智能体头像
export function AgentAvatar({ prompt }) {
  const params = {
    prompt,
  }
  return Request.post(API_POST_AGENT_AVTAR, { ...params })
}

//生成智能体内容/保存智能体草稿
export function CreateAgentDarft() {
  return Request.post(API_POST_CREATE_AGENT_DARFT)
}

//获取收藏智能体列表
export function GetMyCollectAgent() {
  return Request.post(API_POST_GET_MY_COLLECT_AGENT)
}

//收藏智能体
export function AgentCollect({ templateId }) {
  const params = {
    templateId,
  }
  return Request.post(API_POST_COLLECT_AGENT, { ...params })
}

//取消收藏智能体
export function CancelAgentCollect({ templateId }) {
  const params = {
    templateId,
  }
  return Request.post(API_POST_CANCEL_COLLECT_AGENT, { ...params })
}

//获取被协同分享的成员状态
export function GetTeamDocStatus() {
  return Request.get(API_POST_GET_TEAM_PEOPLE_STATUS_DOC)
}

//获取被协同分享的成员状态
export function RemoveTeamDocStatus() {
  return Request.post(API_POST_DELETE_TEAM_PEOPLE_STATUS_DOC)
}

interface SubmitDocumentModifyRecordParams {
  data: Array<{
    beforeModificationData: string;
    afterModificationData: string;
    type: string;
    modify: string;
    paragraphIndex: number;
    time: Date;
  }>,
  userId: number,
  userName: string
}
// 提交编辑器修改记录
export function SubmitDocumentModifyRecord({ uuid, usersMessage }: { uuid: string, usersMessage: SubmitDocumentModifyRecordParams }) {
  const params = {
    uuid,
    usersMessage,
  }
  return Request.post(ADD_DOCUMENT_MODIFY_RECORD, { ...params })
}

// 获取编辑器修改记录
export function getDocumentModifyRecord({ uuid, pageIndex, pageSize = EDITOR_MODIFY_RECORD_REQUEST_COUNT }) {
  const params = {
    uuid,
    pageIndex,
    pageSize
  }
  return Request.post(PAGE_QUERY_ADD_DOCUMENT_MODIFY_RECORD, { ...params })
}

// 文档管理接口
export function getQueryDocumentContribution({ uuid }) {
  const params = {
    uuid,
  }
  return Request.post(QUERY_DOCUMENT_CONTRIBUTION, { ...params })
}

// 文档管理接口
export function getQueryFolderContribution({ uuid, type }: { uuid: string, type: 1 | 2 }) {
  const params = {
    type,
    uuid,
  }
  return Request.post(QUERY_FOLDER_CONTRIBUTION, { ...params })
}

// 获取文件夹属性
export function getFolderProperty({ uuid }: { uuid: string }) {
  const params = {
    uuid,
  }
  return Request.post(GET_FOLDER_PROPERTY, { ...params })
}
