import React from 'react'
import styles from './style/logo.module.less'
import LogoSvg from '@/assets/logo.svg'
import { Tooltip } from '@arco-design/web-react'

function Logo(props) {
  function handleToWWW() {
    props.handleToWWW()
  }
  return (
    <div className={styles['logo']} onClick={handleToWWW}>
      <Tooltip
        className={'custom-tooltip'}
        position="bottom"
        trigger="hover"
        content={'易开即用的AI写作平台'}>
        <LogoSvg />
      </Tooltip>
    </div>
  )
}

export default Logo
