import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styles from './style/toolsNav.module.less'

import {
  IconCorrect,
  IconParaphrase,
  IconExpand,
  IconTranslate,
  IconCollectList,
  IconBox,
  IconAttributes,
  IconStrongRewrite,
  IconChat,
  IconInstruction,
} from '@arco-iconbox/react-aidb-v2'
import { DocBtnType } from '@/helpers/enums'
import { IconRecapHistory } from '@arco-iconbox/react-aidb'
import { Spin } from '@arco-design/web-react'

interface Props {
  onChangeTool: (data: AIDB.ToolClickData) => void
  type: number
  employeePermission: number
  toolIsLoading?: boolean
  isHasTransList?: boolean
  isHasRewriteList?: boolean
  isHasCorrectList?: boolean
  isHasContinueList?: boolean
  isHasReduceList?: boolean
  isHasDirectivesList?: boolean
  transToolIsLoading?: boolean
  writeToolIsLoading?: boolean
  correctToolIsLoading?: boolean
  continueToolIsLoading?: boolean
  reduceToolIsLoading?: boolean
  directivesToolIsLoading?: boolean
  theme?: any
}

const ToolNav = forwardRef((props: Props, ref) => {
  const {
    onChangeTool,
    type,
    employeePermission, // 协同者权限 0-新建文档（无权限），1-可管理，2-可编辑，3-可阅读
    isHasTransList,
    isHasRewriteList,
    isHasCorrectList,
    isHasContinueList,
    isHasReduceList,
    isHasDirectivesList,
    reduceToolIsLoading,
    correctToolIsLoading,
    transToolIsLoading,
    writeToolIsLoading,
    continueToolIsLoading,
    directivesToolIsLoading,
    theme,
  } = props
  const [current, setCurrent] = useState(type)
  const [translateDot, setTranslateDot] = useState<boolean>(false)
  const [reWriteDot, setRewriteDot] = useState<boolean>(false)
  const [correctDot, setCorrectDot] = useState<boolean>(false)
  const [reduceDot, setReduceDot] = useState<boolean>(false)
  const [continueDot, setContinueDot] = useState<boolean>(false)
  const [directivesDot, setDirectivesDot] = useState<boolean>(false)
  // const [toolBtnList, setToolBtnList] = useState<any>()
  // const toolBtnListRef = useRef<any>(list)

  // 创建一个内部的 divRef 用于绑定到 div 元素
  const divRef = useRef<HTMLDivElement>()
  // 使用 useImperativeHandle 将 divRef 暴露给外部
  useImperativeHandle(ref, () => ({
    divRef, // 暴露 divRef
  }))
  useEffect(() => {
    setCurrent(type)
    if (type == DocBtnType.translate && !transToolIsLoading) {
      setTranslateDot(false)
    } else if (type == DocBtnType.rewrite && !writeToolIsLoading) {
      setRewriteDot(false)
    } else if (type == DocBtnType.reduce && !reduceToolIsLoading) {
      setReduceDot(false)
    } else if (type == DocBtnType.proof && !correctToolIsLoading) {
      setCorrectDot(false)
    } else if (
      type == DocBtnType.continuationSingle &&
      !continueToolIsLoading
    ) {
      setContinueDot(false)
    } else if (type == DocBtnType.directives && !directivesToolIsLoading) {
      setDirectivesDot(false)
    }
  }, [type])

  useEffect(() => {
    if (type != DocBtnType.translate && !transToolIsLoading && isHasTransList) {
      setTranslateDot(true)
    }
  }, [transToolIsLoading])
  useEffect(() => {
    if (type != DocBtnType.rewrite && !writeToolIsLoading && isHasRewriteList) {
      setRewriteDot(true)
    }
  }, [writeToolIsLoading])

  useEffect(() => {
    if (type != DocBtnType.reduce && !reduceToolIsLoading && isHasReduceList) {
      setReduceDot(true)
    }
    console.log(type, reduceToolIsLoading, isHasReduceList)
  }, [reduceToolIsLoading])

  useEffect(() => {
    if (type != DocBtnType.proof && !correctToolIsLoading && isHasCorrectList) {
      setCorrectDot(true)
    }
  }, [correctToolIsLoading])

  useEffect(() => {
    if (
      type != DocBtnType.continuationSingle &&
      !continueToolIsLoading &&
      isHasContinueList
    ) {
      console.log(type, isHasContinueList, continueToolIsLoading)

      setContinueDot(true)
    }
  }, [continueToolIsLoading])

  useEffect(() => {
    if (
      type != DocBtnType.directives &&
      !directivesToolIsLoading &&
      isHasDirectivesList
    ) {
      setDirectivesDot(true)
    }
  }, [directivesToolIsLoading])

  // useEffect(() => {
  //   setToolBtnList(list)
  //   toolBtnListRef.current = list
  // }, [
  //   correctToolIsLoading,
  //   transToolIsLoading,
  //   writeToolIsLoading,
  //   continueToolIsLoading,
  // ])

  const list = [
    {
      type: DocBtnType.proof,
      icon: correctToolIsLoading ? (
        <Spin
          className={
            current == DocBtnType.proof ? styles.spinLoading : styles.spinProof
          }
        />
      ) : (
        <IconCorrect useCurrentColor={current === DocBtnType.proof} />
      ),
      text: '校对',
      color: theme == 'dark' ? '#303030' : '#FFEBE8',
      activeColor: '#E33E4A',
    },
    {
      type: DocBtnType.rewrite,
      icon: writeToolIsLoading ? (
        <Spin
          className={
            current == DocBtnType.rewrite || current == DocBtnType.rewriteSingle
              ? styles.spinLoading
              : styles.spinRewrite
          }
        />
      ) : (
        <IconParaphrase
          useCurrentColor={
            current === DocBtnType.rewrite ||
            current === DocBtnType.rewriteSingle
          }
        />
      ),
      text: '改写',
      color: theme == 'dark' ? '#303030' : '#E0F8EC',
      activeColor: '#1EB980',
    },
    {
      type: DocBtnType.reduce,
      icon: reduceToolIsLoading ? (
        <Spin
          className={
            current === DocBtnType.reduce || current === DocBtnType.reduceSingle
              ? styles.spinLoading
              : styles.spinReduce
          }
        />
      ) : (
        <IconStrongRewrite
          useCurrentColor={
            current === DocBtnType.reduce || current === DocBtnType.reduceSingle
          }
        />
      ),
      text: '降重',
      color: theme == 'dark' ? '#303030' : '#D9F5F6',
      activeColor: '#159D9D',
    },
    {
      type: DocBtnType.continuationSingle,
      icon: continueToolIsLoading ? (
        <Spin className={styles.spinLoading} />
      ) : (
        <IconExpand
          useCurrentColor={current === DocBtnType.continuationSingle}
        />
      ),
      text: '扩写',
      color: theme == 'dark' ? '#303030' : '#DAEDFF',
      activeColor: '#3C6DDD',
    },

    {
      type: DocBtnType.translate,
      icon: transToolIsLoading ? (
        <Spin
          className={
            current === DocBtnType.translate ||
            current === DocBtnType.translateSingle
              ? styles.spinLoading
              : styles.spinTranslate
          }
        />
      ) : (
        <IconTranslate
          useCurrentColor={
            current === DocBtnType.translate ||
            current === DocBtnType.translateSingle
          }
        />
      ),
      text: '翻译',
      color: theme == 'dark' ? '#303030' : '#F2E4FF',
      activeColor: '#874CC3',
    },
    // {
    //   type: DocBtnType.directives,
    //   icon: directivesToolIsLoading ? (
    //     <Spin
    //       className={
    //         current === DocBtnType.directives
    //           ? styles.spinLoading
    //           : styles.spinTranslate
    //       }
    //     />
    //   ) : (
    //     <IconInstruction useCurrentColor={current === DocBtnType.directives} />
    //   ),
    //   text: '指令',
    //   color: theme == 'dark' ? '#303030' : '#FFE6FB',
    //   activeColor: '#C437AE',
    // },
    {
      type: DocBtnType.assistant,
      icon: <IconChat useCurrentColor={current === DocBtnType.assistant} />,
      text: '助理',

      color: theme == 'dark' ? '#303030' : '#FFF2D8',
      activeColor: '#FF8C00',
    },
    {
      type: DocBtnType.collect,
      icon: (
        <IconCollectList
          useCurrentColor={false}
          fill={theme == 'dark' ? '#181818' : ''}
        />
      ),
      text: '收藏',
      border: true,
      color: theme == 'dark' ? '' : '#ffffff',
      activeColor: theme == 'dark' ? '#0f0f0f' : '#f5f5f5',
    },

    {
      type: DocBtnType.tools,
      icon: (
        <IconBox
          useCurrentColor={false}
          fill={theme == 'dark' ? '#181818' : ''}
        />
      ),
      text: '工具箱',
      border: true,
      color: theme == 'dark' ? '' : '#ffffff',
      activeColor: theme == 'dark' ? '#0f0f0f' : '#f5f5f5',
    },
    // {
    //   type: DocBtnType.version,
    //   icon: <IconRecapHistory useCurrentColor={false} />,
    //   text: '版本',
    //   border: true,
    //   color: '#ffffff',
    //   activeColor: '#f5f5f5',
    // },

    // {
    //   type: DocBtnType.attribute,
    //   icon: <IconAttributes useCurrentColor={false} />,
    //   text: '属性',
    //   border: true,
    //   color: '#ffffff',
    //   activeColor: '#f5f5f5',
    // },
  ]

  function clickTool(data) {
    setCurrent(data.type)
    onChangeTool(data)
  }

  return (
    <div className={`${styles.tool_nav} ${styles[theme]}`} ref={divRef}>
      {list.map((item, index) => {
        return (
          <div
            className={` ${
              (current == 22
                ? 2
                : // : current == 33
                // ? 3
                current == 44
                ? 4
                : current) === item.type && type
                ? styles.active
                : ''
            } ${styles.item} ${employeePermission === 3 && styles.disabled}`}
            onClick={() => {
              clickTool(item)
            }}
            key={index}>
            <div
              className={`${item.border ? styles.border : ''}  ${styles.icon}`}
              style={{
                backgroundColor:
                  (current == 22
                    ? 2
                    : // : current == 33
                    // ? 3
                    current == 44
                    ? 4
                    : current == 14
                    ? 13
                    : current) === item.type && type
                    ? item.activeColor
                    : item.color,
              }}>
              {/* {transToolIsLoading &&
              (current == 22
                ? 2
                : current == 33
                ? 3
                : current == 44
                ? 4
                : current) === item.type ? (
                <Spin className={styles.spinLoading} />
              ) : (
                item.icon
              )} */}
              {item.icon}
              {/* {type != DocBtnType.proof &&
                !correctToolIsLoading &&
                item.type == DocBtnType.proof &&
                correctDot && <div className={styles.dot}></div>}
              {type != DocBtnType.translate &&
                !transToolIsLoading &&
                item.type == DocBtnType.translate &&
                translateDot && <div className={styles.dot}></div>}
              {type != DocBtnType.rewrite &&
                !writeToolIsLoading &&
                item.type == DocBtnType.rewrite &&
                reWriteDot && <div className={styles.dot}></div>}
              {type != DocBtnType.reduce &&
                !reduceToolIsLoading &&
                item.type == DocBtnType.reduce &&
                reduceDot && <div className={styles.dot}></div>}
              {type != DocBtnType.continuationSingle &&
                !continueToolIsLoading &&
                item.type == DocBtnType.continuationSingle &&
                continueDot && <div className={styles.dot}></div>}
              {type != DocBtnType.directives &&
                !directivesToolIsLoading &&
                item.type == DocBtnType.directives &&
                directivesDot && <div className={styles.dot}></div>} */}
            </div>
            <div
              className={styles.text}
              style={{
                color:
                  (current == 22
                    ? 2
                    : // : current == 33
                    // ? 3
                    current == 44
                    ? 4
                    : current == 14
                    ? 13
                    : current) === item.type && type
                    ? item.activeColor
                    : '#3e3e3e',
              }}>
              {item.text}
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default ToolNav
