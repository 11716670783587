import React, { useEffect } from 'react'

interface CopyProps {
  onCopy?: () => void
  text: string
  content: React.ReactNode
  style?: React.CSSProperties
}

const Copy = ({ onCopy, text, content, style }: CopyProps) => {
  const handleCopy = () => {
    // 执行复制操作
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log('复制成功')
        if (onCopy) onCopy()
      })
      .catch((error) => {
        console.error('复制失败:', error)
      })
  }

  return (
    <div style={style ? style : {}} onClick={() => handleCopy()}>
      {content}
    </div>
  )
}

export default Copy
