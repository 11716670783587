import React, { useEffect, useRef } from 'react'
import styles from './styles/index.module.less'
import { getUserToken } from '@/model/reference'
import Logo from '@/assets/logo.svg'
import { Checkbox, Form, Link } from '@arco-design/web-react'
import wx from 'weixin-js-sdk'
function WxPhone() {
  // const formPhoneRef = useRef(null)
  // function handleGetToken() {
  //   formPhoneRef.current.validate(['check1']).then((res) => {
  //     //       // 构建微信网页授权链接
  //     //       const redirectUri = encodeURIComponent('https://portal.wuz.com.cn/') // 设置授权后的回调链接
  //     //       const scope = 'snsapi_userinfo' // 授权作用域，获取用户信息
  //     //       const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx335bf1398dfd21bb
  //     // &redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
  //     //       // 跳转到微信授权页面
  //     //       window.location.href = authUrl
  //     //       //   getUserToken().then((res) => {
  //     //       //     console.log(res)
  //     //       //   })
  //     //wxlogin

  //     wx.miniProgram.navigateTo({
  //       url: '../wxLogin/wxLogin',
  //     })
  //   })
  // }
  useEffect(() => {
    wx.miniProgram.navigateTo({
      url: '../wxLogin/wxLogin',
    })
  }, [])
  return (
    // <div className={styles.container}>
    //   <div className={styles.logo}>
    //     <Logo />
    //   </div>
    //   <div className={styles.info}>易用易开的智能写作平台</div>
    //   <div className={styles.loginButton} onClick={handleGetToken}>
    //     一键登录
    //   </div>

    //   <div className={styles.loginTxt}>
    //     <Form ref={formPhoneRef}>
    //       <Form.Item
    //         field="check1"
    //         triggerPropName="checked"
    //         rules={[
    //           {
    //             validator: (v, cb) => {
    //               if (v !== true) {
    //                 return cb('请勾选并同意本协议')
    //               }
    //             },
    //           },
    //         ]}>
    //         <Checkbox>
    //           阅读并同意{' '}
    //           <Link
    //             target="_blank"
    //             hoverable={false}
    //             href="https://go.wuz.com.cn/10fa">
    //             《服务协议》
    //           </Link>
    //           和
    //           <Link
    //             target="_blank"
    //             hoverable={false}
    //             href="https://go.wuz.com.cn/99e3">
    //             《隐私政策》
    //           </Link>
    //         </Checkbox>
    //       </Form.Item>
    //     </Form>
    //   </div>
    // </div>
    <div></div>
  )
}

export default WxPhone
