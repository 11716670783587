import React, { useEffect, useState } from 'react'
import style from './style/editItem.module.less'
import {
  // IconReplace,
  // IconCopy,
  IconRecapHistory,
  IconCheck,
  IconProcessAll,
  IconUse,
} from '@arco-iconbox/react-aidb'
import copy from 'copy-to-clipboard'
import DiffMatchPatch from 'diff-match-patch'
import { Message } from '@arco-design/web-react'
import { IconArrowRight } from '@arco-design/web-react/icon'
import IconMsgSuccess from '@/assets/newImgs/msg-success.svg'
import { GetQueryValue } from '@/utils/common'
import { DocBtnType, RewriteSelectType } from '@/helpers/enums'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { showModal } from '@/share/actions'
import { getDocVersionList, setCommentRead } from '@/model/documentEdit'
import palette from '@/helpers/palette'
import {
  IconReplace,
  IconCopy,
  IconClosure,
  IconSuccessTip,
  IconRollRight,
} from '@arco-iconbox/react-aidb-v2'
import IconBtn from '../../components/IconBtn'
import { Transition } from 'react-transition-group'
import {
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
} from '../Animate/toggle'
import Btn from '../Ui/btn'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

interface RewriteProps {
  id?: number
  content?: string
  expand?: boolean
  dotColor?: string
  type?: number
  onExpand?: (id?: number) => void
  nodeInfo?: any
  nodeIndex?: number
  fixContent?: string
  defaultContent?: string
  singleRewrite: boolean
  rewriteShowType?: number
  onReplace: (type: number, id: number) => void
}

export const RewriteEditItem = (props: RewriteProps) => {
  const {
    id,
    expand,
    onExpand,
    dotColor,
    type,
    nodeInfo,
    fixContent,
    defaultContent,
    singleRewrite,
    rewriteShowType,
    onReplace,
    nodeIndex,
  } = props
  const dmp = new DiffMatchPatch()
  const diff = dmp.diff_main(defaultContent, fixContent)
  const theme = useSelector((state: GlobalState) => state.theme)
  useEffect(() => {
    if (type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle) {
      var newDiv = document.querySelector(`.fixContent${id}`)
      if (newDiv.children.length === 0) {
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == 0) {
            span.innerText = item[1]
          } else if (rewriteShowType == 1) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == 2) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = '#1eb980'
          }
          newDiv.appendChild(span)
        })
      } else {
        var oldSpans = document.querySelectorAll(`.fixContent${id} span`)
        for (var i = 0; i < oldSpans.length; i++) {
          oldSpans[i].remove()
        }
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == 0) {
            span.innerText = item[1]
          } else if (rewriteShowType == 1) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == 2) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = '#1eb980'
          }
          newDiv.appendChild(span)
        })
      }
    }
  }, [rewriteShowType, fixContent])

  useEffect(() => {
    if (expand) {
      events.emit(ACTIONS.ADD_REWRITE_EDITOR_TIP, {
        error: defaultContent,
        correct: fixContent,
        nodeInfo: nodeInfo,
        reason: '',
        position: 0,
        color:
          type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle
            ? palette.rewrite
            : type == DocBtnType.translate || type == DocBtnType.translateSingle
            ? palette.trans
            : palette.reduce,
        singleRewrite: singleRewrite,
        nodeIndex: nodeIndex,
      })
    }
  }, [expand])

  const expandHandle = () => {
    onExpand(id)
  }

  const copyHandle = () => {
    copy(fixContent, {
      format: 'text/plain',
    })
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  const replaceHandle = (e, type, id) => {
    e.stopPropagation()
    onReplace(type, id)
  }

  return (
    <div
      className={`${style['item']} ${style[theme]} ${
        !expand ? style['dan'] : ''
      }`}
      onClick={type != DocBtnType.continuation ? expandHandle : null}>
      {expand ? (
        <div className={style['top']}>
          <div
            className={`${style['poi']}`}
            style={{ backgroundColor: dotColor }}></div>
          {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
            <div className={`fixContent${id}`}></div>
          ) : (
            <div>{fixContent}</div>
          )}
        </div>
      ) : (
        <div className={style['top']}>
          <div
            className={style['poi']}
            style={{ backgroundColor: dotColor }}></div>
          {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
            <div className={`fixContent${id}`}></div>
          ) : (
            <div>{fixContent}</div>
          )}
        </div>
      )}
      {expand && (
        <div className={style['bot']}>
          {type != DocBtnType.continuation && (
            <div className={style['btn-group']}>
              <div
                className={`${style['g-btn']} ${style[`g-btn${type}`]}`}
                onClick={(e) => replaceHandle(e, type, id)}>
                <div className={style['icon']}>
                  <IconReplace useCurrentColor={true} />
                </div>
                <span>替换</span>
              </div>
              <div
                className={`${style['g-btn']} ${style[`g-btn${type}`]}`}
                onClick={copyHandle}>
                <div className={style['icon']}>
                  <IconCopy />
                </div>
                <span>复制</span>
              </div>
            </div>
          )}

          <div className={style['right']}></div>
        </div>
      )}
    </div>
  )
}

interface EditorItemProps {
  id?: number
  content?: string
  expand?: boolean
  dotColor?: string
  type?: number
  onExpand?: (id?: number) => void
  nodeInfo?: any
  nodeIndex?: number
  fixContent?: string
  isReplace?: boolean
  isContinueLoading?: boolean
  defaultContent?: string
  singleRewrite: boolean
  rewriteShowType?: number
  onReplace: (type: number, id: number) => void
}

export const EditorItem = (props: EditorItemProps) => {
  // const showBtn = props._key === props.showBtnIndex
  const theme = useSelector((state: GlobalState) => state.theme)
  const showBtn = false
  const {
    id,
    expand,
    onExpand,
    dotColor,
    type,
    nodeInfo,
    fixContent,
    defaultContent,
    singleRewrite,
    rewriteShowType,
    isContinueLoading,
    onReplace,
    nodeIndex,
    isReplace,
  } = props
  const dmp = new DiffMatchPatch()
  const diff = dmp.diff_main(defaultContent, fixContent)
  const [content, setContent] = useState(fixContent)
  useEffect(() => {
    let isRewrite =
      type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle
    if (
      type == DocBtnType.rewrite ||
      type == DocBtnType.rewriteSingle ||
      type == DocBtnType.reduce ||
      type == DocBtnType.reduceSingle
    ) {
      var newDiv = document.querySelector(`.fixContent${id}`)
      if (newDiv.children.length === 0) {
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == RewriteSelectType.all) {
            span.innerText = item[1]
          } else if (rewriteShowType == RewriteSelectType.onlyAdd) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == RewriteSelectType.onlyDel) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = isRewrite
              ? palette.rewriteBold
              : palette.reduceBold
          }
          newDiv.appendChild(span)
        })
      } else {
        var oldSpans = document.querySelectorAll(`.fixContent${id} span`)
        for (var i = 0; i < oldSpans.length; i++) {
          oldSpans[i].remove()
        }
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == RewriteSelectType.all) {
            span.innerText = item[1]
          } else if (rewriteShowType == RewriteSelectType.onlyAdd) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == RewriteSelectType.onlyDel) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = isRewrite
              ? palette.rewriteBold
              : palette.reduceBold
          }
          newDiv.appendChild(span)
        })
      }
    }
  }, [rewriteShowType, fixContent])

  useEffect(() => {
    if (
      fixContent &&
      type === DocBtnType.continuationSingle &&
      isContinueLoading &&
      id == 0
    ) {
      setContent(fixContent)
    }
  }, [fixContent])

  // function displayTextWithTypingEffect(text) {
  //   let currentIndex = 0
  //   const continueElement = document.getElementById('continue')
  //   const typingInterval = setInterval(() => {
  //     if (currentIndex < text.length) {
  //       continueElement.textContent += text[currentIndex]
  //       currentIndex++
  //     } else {
  //       setIsRenderOver(true)
  //       clearInterval(typingInterval)
  //     }
  //   }, 100)
  // }

  useEffect(() => {
    if (expand) {
      // if (type == DocBtnType.continuationSingle) {
      //   events.emit(ACTIONS.CONTINUE_EDITOR, {
      //     content: fixContent,
      //     nodeInfo: nodeInfo,
      //   })
      // } else
      if (
        type == DocBtnType.rewrite ||
        type == DocBtnType.rewriteSingle ||
        type == DocBtnType.translate ||
        type == DocBtnType.translateSingle ||
        type == DocBtnType.reduce ||
        type == DocBtnType.reduceSingle
      ) {
        events.emit(ACTIONS.ADD_REWRITE_EDITOR_TIP, {
          error: defaultContent,
          correct: fixContent,
          nodeInfo: nodeInfo,
          reason: '',
          position: 0,
          color:
            type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle
              ? palette.rewriteBold
              : type == DocBtnType.translate ||
                type == DocBtnType.translateSingle
              ? palette.transBold
              : palette.reduceBold,
          singleRewrite: singleRewrite,
          nodeIndex: nodeIndex,
        })
      }
    }
    // else {
    //   if (type == DocBtnType.continuationSingle) {
    //     events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
    //   }
    // }
  }, [expand])

  const expandHandle = () => {
    onExpand(id)
  }

  const copyHandle = () => {
    copy(fixContent)

    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  const replaceHandle = (e, type, id) => {
    // console.log(type, id)
    e.stopPropagation()
    onReplace(type, id)
  }

  const continueHandle = (e, type, id) => {
    e.stopPropagation()
    events.emit(ACTIONS.CONTINUE_EDITOR, {
      content: fixContent,
      nodeInfo: nodeInfo,
    })
    onReplace(type, id)
  }

  const cancelHandle = () => {
    events.emit(ACTIONS.CONTINUE_CANCEL_EDITOR)
  }

  return (
    <>
      {fixContent.length > 0 && (
        <div
          className={`
          ${expand ? style.showAll : style.click} 
          ${style[theme]}
          ${
            singleRewrite && type != DocBtnType.continuationSingle
              ? style.editorSingleItem
              : style.editorItem
          }
          
      `}
          onClick={expandHandle}>
          <div
            className={
              singleRewrite && !expand ? style.title : style.singleTitle
            }>
            <div
              className={style.poi}
              style={{
                background: isReplace ? palette.grayB4 : dotColor,
              }}></div>
            {/* {expand ? (
          <div className={style['top']}>
            <div
              className={`${style['poi']}`}
              style={{ backgroundColor: dotColor }}></div>
            {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
              <div className={`fixContent${id}`}></div>
            ) : (
              <div>{fixContent}</div>
            )}
          </div>
        ) : (
          <div className={style['top']}>
            <div
              className={style['poi']}
              style={{ backgroundColor: dotColor }}></div>
            {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
              <div className={`fixContent${id}`}></div>
            ) : (
              <div>{fixContent}</div>
            )}
          </div>
        )} */}
            {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}
                className={`fixContent${id}`}></div>
            ) : type == DocBtnType.translate ||
              type == DocBtnType.translateSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}>
                {fixContent}
              </div>
            ) : type == DocBtnType.reduce || type == DocBtnType.reduceSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}
                className={`fixContent${id}`}></div>
            ) : (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}
                id="continue">
                {content}
              </div>
            )}
            {/* <div dangerouslySetInnerHTML={{ __html: fixContent }}></div> */}
          </div>
          <Transition
            in={expand}
            timeout={300}
            unmountOnExit
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}>
            <div>
              {(type === DocBtnType.rewrite ||
                type === DocBtnType.rewriteSingle) &&
                expand && (
                  <div className={style.bot}>
                    <Btn
                      theme={theme}
                      title={isReplace ? '已替换' : '替换'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconReplace />}
                      width={70}
                      height={30}
                      disabled={isReplace}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.rewrite}
                      hoverColor={palette.rewriteBold}
                      svgHoverColor={palette.rewriteBold}
                      onClick={(e) => replaceHandle(e, type, nodeIndex)}
                    />
                    <Btn
                      theme={theme}
                      title={'复制'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconCopy />}
                      width={70}
                      height={30}
                      margin={'0 0 0 10px'}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.rewrite}
                      hoverColor={palette.rewriteBold}
                      svgHoverColor={palette.rewriteBold}
                      onClick={copyHandle}
                    />
                  </div>
                )}
              {(type === DocBtnType.continuation ||
                type === DocBtnType.continuationSingle) &&
                expand && (
                  <div className={style.bot}>
                    <Btn
                      title={'采用'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconReplace />}
                      width={70}
                      height={30}
                      disabled={isContinueLoading}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.continue}
                      hoverColor={palette.continueBold}
                      svgHoverColor={palette.continueBold}
                      onClick={
                        isContinueLoading
                          ? null
                          : (e) => continueHandle(e, type, nodeIndex)
                      }
                    />
                    <Btn
                      title={'复制'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconCopy />}
                      width={70}
                      height={30}
                      margin={'0 0 0 10px'}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.continue}
                      hoverColor={palette.continueBold}
                      svgHoverColor={palette.continueBold}
                      onClick={copyHandle}
                    />
                  </div>
                )}
              {(type === DocBtnType.translate ||
                type === DocBtnType.translateSingle) &&
                expand && (
                  <div className={style.bot}>
                    <Btn
                      title={isReplace ? '已替换' : '替换'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconReplace />}
                      width={70}
                      height={30}
                      disabled={isReplace}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.trans}
                      hoverColor={palette.transBold}
                      svgHoverColor={palette.transBold}
                      onClick={
                        isReplace
                          ? null
                          : (e) => replaceHandle(e, type, nodeIndex)
                      }
                    />
                    <Btn
                      title={'复制'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconCopy />}
                      width={70}
                      height={30}
                      margin={'0 0 0 10px'}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.trans}
                      hoverColor={palette.transBold}
                      svgHoverColor={palette.transBold}
                      onClick={copyHandle}
                    />
                  </div>
                )}
              {(type === DocBtnType.reduce ||
                type === DocBtnType.reduceSingle) &&
                expand && (
                  <div className={style.bot}>
                    <Btn
                      title={isReplace ? '已替换' : '替换'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconReplace />}
                      width={70}
                      height={30}
                      disabled={isReplace}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.reduce}
                      hoverColor={palette.reduceBold}
                      svgHoverColor={palette.reduceBold}
                      onClick={
                        isReplace
                          ? null
                          : (e) => replaceHandle(e, type, nodeIndex)
                      }
                    />
                    <Btn
                      title={'复制'}
                      showBorder={'none'}
                      color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                      Icon={<IconCopy />}
                      width={70}
                      height={30}
                      margin={'0 0 0 10px'}
                      backgroundColor={
                        theme == 'dark' ? '#19191a' : palette.white
                      }
                      hoverBackgroundColor={palette.reduce}
                      hoverColor={palette.reduceBold}
                      svgHoverColor={palette.reduceBold}
                      onClick={copyHandle}
                    />
                  </div>
                )}
            </div>
          </Transition>
        </div>
      )}
    </>
  )
}

interface Props {
  id?: number
  content?: string
  expand?: boolean
  dotColor?: string
  type?: number
  onExpand?: (id?: number) => void
  nodeInfo?: any
}

export const EditItem = (props: Props) => {
  const { id, content, expand, onExpand, dotColor, type, nodeInfo } = props
  const expandHandle = () => {
    onExpand(id)
  }

  const copyHandle = () => {
    copy(content)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  const replaceHandle = () => {
    events.emit(ACTIONS.TO_EDITOR, {
      content: content,
      type: type,
      nodeInfo: nodeInfo,
    })
  }

  return (
    <div
      className={`${style['item']} ${!expand ? style['dan'] : ''}`}
      onClick={type != DocBtnType.continuation ? expandHandle : null}>
      {expand ? (
        <div className={style['top']}>
          <div
            className={`${style['poi']}`}
            style={{ backgroundColor: dotColor }}></div>
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </div>
      ) : (
        <div className={style['top']}>
          <div
            className={style['poi']}
            style={{ backgroundColor: dotColor }}></div>
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </div>
      )}
      {expand && (
        <div className={style['bot']}>
          {type != DocBtnType.continuation && (
            <div className={style['btn-group']}>
              <div
                className={`${style['g-btn']} ${style[`g-btn${type}`]}`}
                onClick={replaceHandle}>
                <div className={style['icon']}>
                  <IconReplace useCurrentColor={true} />
                </div>
                <span>替换</span>
              </div>
              <div
                className={`${style['g-btn']} ${style[`g-btn${type}`]}`}
                onClick={copyHandle}>
                <div className={style['icon']}>
                  <IconCopy />
                </div>
                <span>复制</span>
              </div>
            </div>
          )}

          <div className={style['right']}></div>
        </div>
      )}
    </div>
  )
}

interface ProofEdit {
  id: number
  item?: any
  expand: number
  onExpand?: (id?: number) => void
  onReplace: (type: string, id: number) => void
  isLoading: boolean
  nodeIndex?: number
  content?: string
}

export const ProofEditItem = (props: ProofEdit) => {
  const {
    expand,
    onExpand,
    id,
    item,
    onReplace,
    isLoading,
    nodeIndex,
    content,
  } = props
  useEffect(() => {
    if (expand) {
      events.emit(ACTIONS.ADD_EDITOR_TIP_PROOF, {
        error: item.sentence,
        correct: item.sentenceFixed,
        reason: item.vecFragmentList[0].explain,
        beginSentenceOffset: item.beginSentenceOffset,
        position: 0,
        content: content,
        nodeIndex: nodeIndex,
      })
    }
  }, [expand])
  const expandHandle = (id) => {
    onExpand(id)
  }

  const replace = (e, type, id) => {
    e.stopPropagation()
    onReplace(type, id)
    events.emit(ACTIONS.REPLACE_TO_EDITOR_TIP)
  }

  const separate = (type) => {
    switch (type) {
      case '010100':
        return '错别字'
      case '010200':
        return '错别词'

      case '010600':
        return '诗词错误'

      case '020100':
        return '标点误用'

      case '020200':
        return '标点缺失'

      case '020300':
        return '标点冗余'

      case '030100':
        return '语法错误-冗余'

      case '030200':
        return '语法错误-缺失'

      case '030300':
        return '语法错误-乱序'

      case '030400':
        return '语法错误-语句不通'

      case '040101':
        return '人名专名错误'

      case '040102':
        return '职务专名错误'

      case '040200':
        return '专名搭配错误'

      case '040300':
        return '专名排序错误'

      case '040400':
        return '术语错误'

      case '060100':
        return '地址别字'

      case '060200':
        return '地址搭配错误'

      case '060300':
        return '地址缺失错误'
    }
  }

  return (
    <>
      {expand ? (
        <div
          className={style['proofItem']}
          style={{ cursor: isLoading ? 'alias' : 'pointer' }}
          onClick={() => expandHandle(id)}>
          <div className={style['title']}>
            <div className={style['poi']}></div>
            <span style={{ flex: 4 }}>{item?.sentence}</span>
            <div className={style['icon']}>
              <IconArrowRight />
            </div>
            <div style={{ flex: 4 }} className={style['color']}>
              {item.sentenceFixed}
            </div>
          </div>
          <div className={style['text']} style={{ fontStyle: 'italic' }}>
            {item.vecFragmentList[0].explain}
          </div>
          <div className={style['tools']}>
            <div
              className={style['g-btn']}
              onClick={(e) => replace(e, item.vecFragmentList[0].label, id)}>
              <div className={style['icon']}>
                <IconReplace style={{ fontSize: 24, fill: '#aaa' }} />
              </div>
              <span>替换</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={style['proofItem']} onClick={() => expandHandle(id)}>
          <div className={style['title']}>
            <div className={style['poi']}></div>
            <span style={{ flex: 6 }}>{item?.sentence}</span>
            <div className={style['line']}></div>
            <div style={{ flex: 4 }} className={style['gray']}>
              {separate(item.vecFragmentList[0].label)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

interface CorrectEdit {
  id: number
  replaceId: string
  item?: any
  expand: number
  isReplace: boolean
  onExpand?: (id?: number) => void
  onReplace: (type: string, id: string) => void
  onDelete: (type: string, id: number) => void
  color: string
  boldColor: string
  isLoading: boolean
  nodeIndex?: number
  content?: string
}

export const CorrectEditItem = (props: CorrectEdit) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const {
    expand,
    replaceId,
    onExpand,
    id,
    item,
    onReplace,
    onDelete,
    color,
    boldColor,
    isLoading,
    nodeIndex,
    content,
    isReplace,
  } = props
  useEffect(() => {
    if (expand && !isReplace) {
      events.emit(ACTIONS.ADD_EDITOR_TIP_PROOF, {
        error: item.sentence,
        correct: item.sentenceFixed,
        reason: item.vecFragmentList[0].explain,
        beginSentenceOffset: item.beginSentenceOffset,
        color: color,
        position: 0,
        content: content,
        nodeIndex: nodeIndex,
      })
    }
  }, [expand])
  const expandHandle = (id) => {
    onExpand(id)
  }

  const replace = (e, type, id) => {
    if (isReplace) return
    e.stopPropagation()
    onReplace(type, id)
    events.emit(ACTIONS.REPLACE_TO_EDITOR_TIP)
  }

  const deleteHandle = (e, type, id) => {
    e.stopPropagation()
    onDelete(type, id)
    events.emit(ACTIONS.REMOVE_EDITOR_TIP)
  }

  const separate = (type) => {
    switch (type) {
      case '010100':
        return '错别字'
      case '010200':
        return '错别词'

      case '010600':
        return '诗词错误'

      case '020100':
        return '标点误用'

      case '020200':
        return '标点缺失'

      case '020300':
        return '标点冗余'

      case '030100':
        return '语法错误-冗余'

      case '030200':
        return '语法错误-缺失'

      case '030300':
        return '语法错误-乱序'

      case '030400':
        return '语法错误-语句不通'

      case '040101':
        return '人名专名错误'

      case '040102':
        return '职务专名错误'

      case '040200':
        return '专名搭配错误'

      case '040300':
        return '专名排序错误'

      case '040400':
        return '术语错误'

      case '060100':
        return '地址别字'

      case '060200':
        return '地址搭配错误'

      case '060300':
        return '地址缺失错误'
    }
  }

  return (
    <div className={style[theme]}>
      <div
        className={` ${style.suggest_item}`}
        style={{ cursor: isLoading ? 'default' : 'pointer' }}
        onClick={() => {
          !isLoading && expandHandle(id)
        }}>
        {/* <div
          className={style.close}
          onClick={(e) => deleteHandle(e, item.vecFragmentList[0].label, id)}>
          <IconClosure />
        </div> */}
        {isReplace && (
          <div className={style.replaceBox}>
            <IconCheck
              style={{
                backgroundColor: boldColor,
              }}
            />
            <span
              className={style.replaceText}
              style={{
                color: boldColor,
              }}>
              已修改
            </span>
          </div>
        )}
        {expand && (
          <div className={style.suggest_top_expand}>
            <div className={style.suggest_top_expand_box}>
              <p className={style.label}>
                {' '}
                {separate(item.vecFragmentList[0].label)}
              </p>
              <p className={style.sentence}> {item?.sentence}</p>
            </div>
          </div>
        )}
        {!expand && (
          <div className={style.suggest_top}>
            <div
              className={
                !expand ? style.suggest_title : style.suggest_title_expand
              }>
              {item?.sentence}
            </div>

            <div className={style.suggest_type}>
              {separate(item.vecFragmentList[0].label)}
            </div>
          </div>
        )}
        <Transition
          in={expand}
          timeout={300}
          unmountOnExit
          onEnter={onEnter}
          onEntering={onEntering}
          onEntered={onEntered}
          onExit={onExit}
          onExiting={onExiting}
          onExited={onExited}>
          <div>
            <div className={style.suggest_content}>
              <div className={style.suggest_text}>
                <p className={style.suggest_replace}>
                  {!isReplace ? '修改建议' : '已修改'}
                </p>
                <div
                  className={style.suggest_replace}
                  style={{
                    cursor: isReplace && 'default',
                  }}>
                  <IconRollRight />
                  <span
                    className={style.sentenceFixed}
                    onClick={(e) =>
                      !isLoading &&
                      !isReplace &&
                      replace(e, item.vecFragmentList[0].label, replaceId)
                    }
                    style={{
                      background: !isReplace ? boldColor : 'transparent',
                      color: !isReplace ? '#fff' : '#000',
                      padding: !isReplace ? '12px' : '0',
                      cursor: !isReplace ? 'pointer' : 'default',
                    }}>
                    {item?.sentenceFixed}
                  </span>
                </div>
              </div>
              {!isReplace && (
                <div className={style.suggest_desc}>
                  {item.vecFragmentList[0].explain}
                </div>
              )}
            </div>
          </div>
        </Transition>
      </div>
      {/* {expand ? (
        <div
          className={style['proofItem']}
          style={{ cursor: isLoading ? 'alias' : 'pointer' }}
          onClick={() => expandHandle(id)}>
          <div className={style['title']}>
            <div className={style['poi']}></div>
            <span style={{ flex: 4 }}>{item?.sentence}</span>
            <div className={style['icon']}>
              <IconArrowRight />
            </div>
            <div style={{ flex: 4 }} className={style['color']}>
              {item.sentenceFixed}
            </div>
          </div>
          <div className={style['text']} style={{ fontStyle: 'italic' }}>
            {item.vecFragmentList[0].explain}
          </div>
          <div className={style['tools']}>
            <div
              className={style['g-btn']}
              onClick={(e) => replace(e, item.vecFragmentList[0].label, id)}>
              <div className={style['icon']}>
                <IconReplace style={{ fontSize: 24, fill: '#aaa' }} />
              </div>
              <span>替换</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={style['proofItem']} onClick={() => expandHandle(id)}>
          <div className={style['title']}>
            <div className={style['poi']}></div>
            <span style={{ flex: 6 }}>{item?.sentence}</span>
            <div className={style['line']}></div>
            <div style={{ flex: 4 }} className={style['gray']}>
              {separate(item.vecFragmentList[0].label)}
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

export const CommentEditItem = (props: AIDB.ApprovalItem) => {
  const { remark, gmtCreate } = props
  return (
    <div className={style.item} style={{ cursor: 'default' }}>
      <div className={style.top} style={{ padding: 0 }}>
        {remark}
        <div className={style.author}>{gmtCreate}</div>
      </div>
    </div>
  )
}

interface CommentEditItemV2Props {
  className?: string
  content?: string
  id?: string
  docContent?: string
  createDate?: string
  isReview?: boolean
  isRead?: boolean
  isAllAnnotate?: boolean
  reviewCommentClassName?: string
  reviewId?: string
  onIsEdit?: (id) => void
  isHovered?: boolean
  handleHover?: (id) => void
  currentCommentId?: string
}

export const CommentEditItemV2 = (props: CommentEditItemV2Props) => {
  const {
    className,
    content,
    id,
    createDate,
    isReview,
    isRead,
    isAllAnnotate,
    reviewCommentClassName,
    onIsEdit,
    isHovered,
    handleHover,
    currentCommentId,
  } = props
  const [isEdit, setIsEdit] = useState(isRead)
  const isCurrentId = currentCommentId === id
  useEffect(() => {
    if (isReview && reviewCommentClassName) {
      const contentWrapper = document.querySelector(
        '#contentWrapper',
      ) as HTMLElement

      contentWrapper
        .querySelectorAll('.' + reviewCommentClassName)
        .forEach((item) => {
          item.classList.add('hl-review')
          const target = contentWrapper.querySelector(
            '.hl-review',
          ) as HTMLElement

          const boxHeight = parseFloat(getComputedStyle(contentWrapper).height)
          if (target.offsetTop > boxHeight) {
            setTimeout(() => {
              contentWrapper.scrollTo({
                top: target.offsetTop - boxHeight + boxHeight / 4,
                behavior: 'smooth',
              })
            }, 300)
          } else {
            setTimeout(() => {
              contentWrapper.scrollTo({
                top: target.offsetTop - contentWrapper.offsetTop - 20,
                behavior: 'smooth',
              })
            }, 300)
          }
        })
    }
  }, [])
  const hightLightElement = (e) => {
    const sylEditorBox = document.querySelector('#sylEditorBox') as HTMLElement
    e.stopPropagation()
    document.querySelectorAll('.' + className).forEach((item) => {
      item.classList.add('hl')
      const target = document.querySelector('.hl') as HTMLElement
      const boxHeight = parseFloat(getComputedStyle(sylEditorBox).height)
      var distance = 0
      var parent = target.offsetParent as HTMLElement
      while (parent && parent !== sylEditorBox) {
        distance += parent.offsetTop
        parent = parent.offsetParent as HTMLElement
      }
      if (distance < boxHeight) {
        setTimeout(() => {
          sylEditorBox.scrollTo({
            top: distance - boxHeight + boxHeight,
            behavior: 'smooth',
          })
        }, 300)
      } else {
        setTimeout(() => {
          sylEditorBox.scrollTo({
            top: distance - sylEditorBox.offsetTop,
            behavior: 'smooth',
          })
        }, 300)
      }
    })
  }

  const hightLightReviewElement = (e) => {
    if (!isCurrentId && isReview) {
      handleHover(id)
    }
    const contentWrapper = document.querySelector(
      '#contentWrapper',
    ) as HTMLElement
    e.stopPropagation()
    contentWrapper.querySelectorAll('.' + className).forEach((item) => {
      item.classList.add('hl')

      const target = document.querySelector('.hl') as HTMLElement
      const boxHeight = parseFloat(getComputedStyle(contentWrapper).height)
      if (target.offsetTop > boxHeight) {
        setTimeout(() => {
          contentWrapper.scrollTo({
            top: target.offsetTop - boxHeight + boxHeight / 4,
            behavior: 'smooth',
          })
        }, 300)
      } else {
        setTimeout(() => {
          contentWrapper.scrollTo({
            top: target.offsetTop - contentWrapper.offsetTop - 20,
            behavior: 'smooth',
          })
        }, 300)
      }
    })
    document.querySelectorAll('.' + 'hl-review').forEach((item) => {
      item.classList.remove('hl-review')
    })
  }
  const removeHightLight = (e) => {
    if (!isCurrentId && isReview) {
      handleHover(null)
    }
    e.stopPropagation()
    document.querySelectorAll('.' + className).forEach((item) => {
      item.classList.remove('hl')
    })
  }

  const editHandle = async () => {
    events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
      id: className,
      className: className,
    })
    const res = await setCommentRead({ uuids: [id] })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '已处理',
      })
      setIsEdit(true)
      onIsEdit(id)
    }
  }

  const reviewHandle = async () => {
    showModal('ReviewModal', {
      title: '回顾',
      reviewId: id,
      className: className,
      // versionList: res.data.data,
    })

    // const res = await getDocVersionList({ uuid: GetQueryValue('uuid') })
    // if (res.data.success) {
    // }
  }

  return (
    <div
      style={{
        position: 'relative',
        cursor: isEdit && !isReview ? 'default' : 'pointer',
      }}
      className={`${style.item} ${style['annotateItem']}`}
      onMouseOver={!isReview ? hightLightElement : hightLightReviewElement}
      onMouseOut={removeHightLight}>
      <div className={style['top']}>
        <div
          className={style['poi']}
          style={{
            backgroundColor: isEdit || isAllAnnotate ? 'gray' : '#ff6a00',
          }}></div>
        <span
          style={{
            textDecoration: isEdit || isAllAnnotate ? 'line-through' : 'none',
            wordBreak: 'break-word',
          }}
          className={`${style['commentContent']}
           ${
             isCurrentId
               ? style['isReviewCommentContentBold']
               : isHovered && isCurrentId
               ? style['isReviewCommentContentBold']
               : style['isReviewCommentContentNormal']
           } `}
          dangerouslySetInnerHTML={{ __html: content }}></span>
        <div className={style.author}>{createDate}</div>
      </div>
      {!isReview && (
        <div className={style['bot']}>
          <div className={style['btn-group']}>
            <div
              className={`${style['g-btn5']} ${style['g-btn']}`}
              onClick={isEdit || isAllAnnotate ? null : editHandle}
              style={{
                cursor: isEdit || isAllAnnotate ? 'default' : 'pointer',
              }}>
              <div className={style['icon']}>
                {isEdit || isAllAnnotate ? (
                  <IconProcessAll useCurrentColor={true} />
                ) : (
                  <IconCheck useCurrentColor={true} />
                )}
              </div>
              <span>{isEdit || isAllAnnotate ? '已处理' : '处理'}</span>
            </div>
            <div
              className={`${style['g-btn5']} ${style['g-btn']}`}
              onClick={reviewHandle}>
              <div className={style['icon']}>
                <IconRecapHistory useCurrentColor={true} />
              </div>
              <span>回顾</span>
            </div>
          </div>
          <div className={style['right']}></div>
        </div>
      )}
    </div>
  )
}

interface DirectivesItemProps {
  id?: number
  content?: string
  expand?: boolean
  dotColor?: string
  type?: number
  onExpand?: (id?: number) => void
  nodeInfo?: any
  nodeIndex?: number
  fixContent?: string
  isReplace?: boolean
  isContinueLoading?: boolean
  defaultContent?: string
  singleRewrite: boolean
  rewriteShowType?: number
  startPos?: number
  disabled: boolean
  onReplace: (id: number) => void
}

export const DirectivesItem = (props: DirectivesItemProps) => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const showBtn = false
  const {
    id,
    expand,
    onExpand,
    dotColor,
    type,
    nodeInfo,
    fixContent,
    defaultContent,
    singleRewrite,
    rewriteShowType,
    isContinueLoading,
    onReplace,
    nodeIndex,
    isReplace,
    startPos,
    disabled,
  } = props
  const dmp = new DiffMatchPatch()
  const diff = dmp.diff_main(defaultContent, fixContent)
  const [content, setContent] = useState(fixContent)
  useEffect(() => {
    let isRewrite =
      type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle
    if (
      type == DocBtnType.rewrite ||
      type == DocBtnType.rewriteSingle ||
      type == DocBtnType.reduce ||
      type == DocBtnType.reduceSingle
    ) {
      var newDiv = document.querySelector(`.fixContent${id}`)
      if (newDiv.children.length === 0) {
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == RewriteSelectType.all) {
            span.innerText = item[1]
          } else if (rewriteShowType == RewriteSelectType.onlyAdd) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == RewriteSelectType.onlyDel) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = isRewrite
              ? palette.rewriteBold
              : palette.reduceBold
          }
          newDiv.appendChild(span)
        })
      } else {
        var oldSpans = document.querySelectorAll(`.fixContent${id} span`)
        for (var i = 0; i < oldSpans.length; i++) {
          oldSpans[i].remove()
        }
        diff.forEach(function (item) {
          var span = document.createElement('span')
          if (rewriteShowType == RewriteSelectType.all) {
            span.innerText = item[1]
          } else if (rewriteShowType == RewriteSelectType.onlyAdd) {
            if (item[0] == 1 || item[0] == 0) {
              span.innerText = item[1]
            }
          } else if (rewriteShowType == RewriteSelectType.onlyDel) {
            if (item[0] == -1 || item[0] == 0) {
              span.innerText = item[1]
            }
          }
          // 删除
          if (item[0] === -1) {
            span.style.textDecoration = 'line-through'
            span.style.background = 'rgb(255, 230, 230)'
          }

          // 插入
          if (item[0] === 1) {
            span.style.fontWeight = 'bold'
            span.style.color = isRewrite
              ? palette.rewriteBold
              : palette.reduceBold
          }
          newDiv.appendChild(span)
        })
      }
    }
  }, [rewriteShowType, fixContent])

  useEffect(() => {
    setContent(fixContent)
  }, [fixContent])

  useEffect(() => {
    if (expand) {
      if (
        type == DocBtnType.rewrite ||
        type == DocBtnType.rewriteSingle ||
        type == DocBtnType.translate ||
        type == DocBtnType.translateSingle ||
        type == DocBtnType.reduce ||
        type == DocBtnType.reduceSingle
      ) {
        events.emit(ACTIONS.ADD_REWRITE_EDITOR_TIP, {
          error: defaultContent,
          correct: fixContent,
          nodeInfo: nodeInfo,
          reason: '',
          position: 0,
          color:
            type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle
              ? palette.rewriteBold
              : type == DocBtnType.translate ||
                type == DocBtnType.translateSingle
              ? palette.transBold
              : palette.reduceBold,
          singleRewrite: singleRewrite,
          nodeIndex: nodeIndex,
        })
      }
    }
  }, [expand])

  // const expandHandle = () => {
  //   onExpand(id)
  // }

  const copyHandle = () => {
    copy(fixContent, {
      format: 'text/plain',
    })
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }

  const adoptHandle = (e, id) => {
    e.stopPropagation()
    events.emit(ACTIONS.DIRECTIVES_ADOPT_EDITOR, {
      content: fixContent,
      startPos: startPos,
    })
    onReplace(id)
  }

  return (
    <>
      {fixContent.length > 0 && (
        <div className={`${style['directivesItem']} ${style[theme]}`}>
          <div
            style={{ paddingLeft: '0' }}
            className={
              singleRewrite && !expand ? style.title : style.singleTitle
            }>
            {/* <div
              className={style.poi}
              style={{
                background: isReplace ? palette.grayB4 : dotColor,
              }}></div> */}
            {type == DocBtnType.rewrite || type == DocBtnType.rewriteSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}
                className={`fixContent${id}`}></div>
            ) : type == DocBtnType.translate ||
              type == DocBtnType.translateSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}>
                {fixContent}
              </div>
            ) : type == DocBtnType.reduce || type == DocBtnType.reduceSingle ? (
              <div
                style={{
                  opacity: theme === 'dark' ? '1' : isReplace ? '0.4' : '1',
                }}
                className={`fixContent${id}`}></div>
            ) : (
              <div
                style={{
                  opacity: isReplace ? '0.4' : '1',
                  whiteSpace: 'pre-wrap',
                }}
                id="continue"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
          <Transition
            in={expand}
            timeout={300}
            unmountOnExit
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}>
            <div>
              {(type === DocBtnType.directives ||
                type === DocBtnType.directives) && (
                <div className={style.bot}>
                  <Btn
                    title={'采用'}
                    showBorder={'none'}
                    color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                    svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                    Icon={<IconReplace />}
                    width={70}
                    height={30}
                    disabled={disabled}
                    backgroundColor={
                      theme == 'dark' ? '#19191a' : palette.white
                    }
                    hoverBackgroundColor={palette.directives}
                    hoverColor={palette.directivesBold}
                    svgHoverColor={palette.directivesBold}
                    onClick={disabled ? null : (e) => adoptHandle(e, id)}
                  />
                  <Btn
                    title={'复制'}
                    showBorder={'none'}
                    color={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                    svgColor={theme == 'dark' ? '#5a5a5a' : palette.grayA}
                    Icon={<IconCopy />}
                    width={70}
                    height={30}
                    margin={'0 0 0 10px'}
                    disabled={disabled}
                    backgroundColor={
                      theme == 'dark' ? '#19191a' : palette.white
                    }
                    hoverBackgroundColor={palette.directives}
                    hoverColor={palette.directivesBold}
                    svgHoverColor={palette.directivesBold}
                    onClick={disabled ? null : copyHandle}
                  />
                </div>
              )}
            </div>
          </Transition>

          {/* <div className={style['box']}>
            <span className={style['line']}></span>
            <span className={style['text']}>我是文字</span>
            <span className={style['line']}></span>
          </div> */}
        </div>
      )}
      {/* <p className={style['dis']}>分割线</p> */}
    </>
  )
}
