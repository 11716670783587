import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { EditorState } from 'prosemirror-state'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
interface WordCount {
  name: string
}
const PLUGIN_NAME = 'wordCound'
class WordCountSchema extends Inline<WordCount> {
  public name = PLUGIN_NAME
}

class CharacterCountController extends SylController {
  public name = 'character_count'
  public wordCount
  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.GET_WORD_COUNT_EDITOR, this.getWordCount)
  }

  editorWillUnmount(): void {
    events.off(ACTIONS.GET_WORD_COUNT_EDITOR, this.getWordCount)
  }

  public getWordCount = () => {
    const tr = this.editor.view.state.tr
    this.editor.view.dispatch(tr)
  }

  // 自定义菜单栏
  public toolbar = {
    className: 'character-count',
    tooltip: 'Character Count',
    icon: (_editor: SylApi, attrs: { fontFamily: string; name: string }) => {
      const state = _editor.view.state as EditorState
      const name = state.doc.textContent.length
      localStorage.setItem('wordCount', String(name))

      return (
        <div
          className="wordCount"
          style={{ color: `#999`, background: '#fff', fontSize: '12px' }}>
          字数: {name}
        </div>
      )
    },
  }
}
class CharacterCountPlugin extends SylPlugin {
  public name = 'character_count'
  public Controller = CharacterCountController
  public Schema = WordCountSchema
}

export { CharacterCountPlugin }
