import React from 'react'
import { Divider, Dropdown, Menu } from '@arco-design/web-react'
import './style/index.less'
import {
  IconArrowDown,
  IconMoreEdits,
  IconOrder,
} from '@arco-iconbox/react-aidb-v2'
import palette from '@/helpers/palette'
import BorderBtn from '@/components/BorderBtn'
import { DropdownType } from '@/helpers/enums'

interface Props {
  menuList: any
  onMenuClick: (key: number) => void
  activeKey: number
  fontSize?: number
  fontWeight?: string
  fontColor?: string
  isSelect: boolean
  iconSize?: number
  iconColor?: string
  iconNode?: React.ReactNode
  dropdownType: number
  isHasDivider?: boolean
}

const DropMenu = (props: Props) => {
  const {
    menuList,
    onMenuClick,
    activeKey,
    fontSize,
    fontWeight,
    fontColor,
    isSelect,
    iconSize,
    iconColor,
    iconNode,
    dropdownType,
    isHasDivider,
  } = props
  const onClickType = (e) => {
    onMenuClick(~~e)
  }

  const dropList = (
    <Menu
      className={'custom-dropdown'}
      onClickMenuItem={(e) => {
        onClickType(e)
      }}>
      <div className={'dropdown-list'}>
        {isSelect ? (
          <React.Fragment>
            {menuList.map((item) => {
              return (
                <React.Fragment key={item.key}>
                  <Menu.Item
                    key={item.key}
                    className={`${activeKey == item.key ? 'active' : ''}`}>
                    {item.title}
                    <div
                      className={`dropdown-list-poi ${
                        activeKey == item.key ? 'active' : ''
                      }`}></div>
                  </Menu.Item>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {menuList.map((item, index) => {
              const isLastTwoItems = index >= menuList.length - 1
              return (
                <React.Fragment key={item.key}>
                  {isLastTwoItems && (
                    <React.Fragment>
                      {isHasDivider && <Divider style={{ margin: '4px 0' }} />}
                      {/* 在倒数第二个元素后添加 Divider */}
                    </React.Fragment>
                  )}
                  <Menu.Item key={item.key}>{item.title}</Menu.Item>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )}
      </div>
    </Menu>
  )
  return (
    <Dropdown droplist={dropList} position="bl" trigger={'click'}>
      {dropdownType == DropdownType.titleDown ? (
        <div className={'type'}>
          <div
            className={'title'}
            style={{
              fontSize: fontSize ? fontSize + 'px' : '12px',
              fontWeight: fontWeight ? fontWeight : 'normal',
              color: fontColor ? fontColor : palette.black,
            }}>
            {menuList.find((d) => d.key === activeKey).title}
          </div>
          <div
            className={'icon'}
            style={{
              fontSize: iconSize ? iconSize + 'px' : '22px',
              color: iconColor ? iconColor : palette.black,
            }}>
            <IconArrowDown />
          </div>
        </div>
      ) : dropdownType == DropdownType.iconDown ? (
        <div style={{ marginRight: '12px' }}>
          <BorderBtn
            color={'black'}
            size={'onlyIcon'}
            iconLeft={iconNode}
            text={''}></BorderBtn>
        </div>
      ) : (
        <div
          className={'icon'}
          style={{
            fontSize: iconSize ? iconSize + 'px' : '22px',
            color: iconColor ? iconColor : palette.black,
          }}>
          <IconMoreEdits useCurrentColor={true} />
        </div>
      )}
    </Dropdown>
  )
}

export default DropMenu
