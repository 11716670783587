import React, { useState, useEffect, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Input, Message, Tooltip } from '@arco-design/web-react'
import { IconCopy } from '@arco-design/web-react/icon'
import request from '@/utils/request'
import IconBtn from '../../components/IconBtn'
import IconMsgSuccess from '../../assets/newImgs/msg-success.svg'
import styles from './style/prompt-mod.module.less'

const InputSearch = Input.Search

function PromptMod(props) {
  const [list, setList] = useState<any>(props.list)
  const [value, setValue] = useState<any>('')
  const [index_1, setIndex] = useState<any>(0)
  const [index_2, setIndex2] = useState<any>(0)
  const [index_3, setIndex3] = useState<any>(0)
  const [index_4, setIndex4] = useState<any>(0)

  const inputSearchRef = useRef(null)

  const { theme } = props

  // 指令库搜索
  useEffect(() => {
    if (!value) {
      setList(props.list)
      return
    }

    let flag1 = true
    let flag2 = true

    setIndex(0)
    setIndex2(0)

    let filteredList1 = props.list
      .filter((item, index) => {
        if (item.title.includes(value)) {
          if (flag1) {
            setIndex3(item.id)
            setIndex4(0)
            flag1 = false
          }
          return true
        }
        return false
      })
      .map((item) => ({
        ...item,
        title: item.title.replace(
          value,
          `<strong style="text-decoration: underline">${value}</strong>`,
        ),
      }))

    let filteredList2 = filteredList1.length
      ? filteredList1
      : props.list
          .map((item, index) => ({
            ...item,
            children: item.children
              .filter(
                (child) =>
                  child.title.includes(value) ||
                  child.content.includes(value) ||
                  child.info.includes(value),
              )
              .map((child) => ({
                ...child,
                title: value
                  ? child.title.replace(
                      value,
                      `<strong style="text-decoration: underline">${value}</strong>`,
                    )
                  : child.title,
                content: value
                  ? child.content.replace(
                      value,
                      `<strong style="text-decoration: underline">${value}</strong>`,
                    )
                  : child.content,
                info: value
                  ? child.info.replace(
                      value,
                      `<strong style="text-decoration: underline">${value}</strong>`,
                    )
                  : child.info,
              })),
          }))
          .filter((item, index) => {
            if (item.children.length > 0) {
              // setTimeout(() => {
              if (flag2) {
                setIndex3(item.id)
                setIndex4(item.children[0].id)
                flag2 = false
              }
              // }, 10)
              return true
            }
            return false
          })

    setList(filteredList2.length > 0 ? filteredList2 : props.list)
  }, [value])

  useEffect(() => {
    setTimeout(() => {
      if (inputSearchRef.current && props.mobile) {
        inputSearchRef.current.focus()
      } else {
        inputSearchRef.current.blur()
      }
    }, 0)
  }, [props.visiblePrompt])

  function splitStrTooltip(str) {
    if (str.split('<strong style="text-decoration: underline">')[1]) {
      let delStr1 =
        str.split('<strong style="text-decoration: underline">')[0] +
        str.split('<strong style="text-decoration: underline">')[1]
      let delStr2 =
        delStr1.split('</strong>')[0] + delStr1.split('</strong>')[1]

      return delStr2
    } else {
      return str
    }
  }

  return (
    <div className={`${styles['promptMod']} ${styles[theme]}`}>
      <InputSearch
        ref={inputSearchRef}
        className={styles['promptLibraryInput']}
        allowClear
        placeholder="搜索"
        onChange={(value) => {
          setValue(value)
        }}
        onPressEnter={(e) => {
          try {
            props.promptSend(
              index_3,
              index_4,
              1,
              props.list[index_3].children[index_4].title,
            )
          } catch (error) {}
        }}
        style={{ width: 200 }}
      />
      <div className={styles['colContent']}>
        <div className={`${styles['col']} ${styles['col1']}`}>
          <div className={styles['title']}>分类</div>
          <div className={styles['list1']}>
            {list.length &&
              list.map((item, index) => {
                return (
                  <div
                    className={`${styles['item']} ${
                      index_1 === index ? styles['active'] : ''
                    }`}
                    key={index}
                    onClick={() => {
                      setIndex(index)
                      setIndex2(0)
                      setIndex3(item.id)
                      setIndex4(item.children[0].id)
                    }}>
                    {/* <div className={styles['poi']}></div> */}
                    {item.title ? (
                      <Tooltip content={splitStrTooltip(item.title)}>
                        <div
                          className={styles['name']}
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}></div>
                      </Tooltip>
                    ) : (
                      <div className={styles['name']}>暂无数据</div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <div className={`${styles['col']} ${styles['col2']}`}>
          <div className={styles['title']}>条目</div>
          <div className={styles['list1']}>
            {list.length && list[index_1].children.length ? (
              list[index_1].children.map((item, index) => {
                return (
                  <div
                    className={`${styles['item']} ${
                      index_2 === index ? styles['active'] : ''
                    }`}
                    key={index}
                    onClick={() => {
                      setIndex2(index)
                      setIndex4(item.id)
                    }}>
                    {/* <div className={styles['poi']}></div> */}
                    {item.title ? (
                      <Tooltip content={splitStrTooltip(item.title)}>
                        <div
                          className={styles['name']}
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}></div>
                      </Tooltip>
                    ) : (
                      <div className={styles['name']}>暂无数据</div>
                    )}
                  </div>
                )
              })
            ) : (
              <div className={`${styles['item']}`}>
                <div className={styles['poi']}></div>
                <div className={styles['name']}>暂无数据</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles['col']} ${styles['col3']}`}>
        {index_2 !== '' && (
          <>
            <div className={styles['title']}>提示内容</div>
            <div
              className={styles['text']}
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html:
                  (list.length &&
                    list[index_1].children[index_2] &&
                    list[index_1].children[index_2].content) ||
                  (list[index_3] &&
                    list[index_3].children[index_4] &&
                    list[index_3].children[index_4].content)
                    ? list[index_1].children[index_2].content ||
                      list[index_3].children[index_4].content
                    : '暂无数据',
              }}></div>
          </>
        )}
        {index_2 !== '' && (
          <>
            <div className={styles['infoTitle']}>
              <div>参考内容</div>
              <div className={styles['copy']}></div>
              {props.copyOrUse ? (
                <CopyToClipboard
                  text={
                    list[index_1] &&
                    list[index_1].children[index_2] &&
                    list[index_1].children[index_2].info
                      ? list[index_1].children[index_2].info
                      : '暂无数据'
                  }
                  onCopy={() => {
                    try {
                      props.promptSend(
                        index_3,
                        index_4,
                        2,
                        props.list[index_3].children[index_4].title,
                      )
                    } catch (error) {}
                  }}>
                  <IconBtn text={'复制'} iconLeft={<IconCopy />}></IconBtn>
                </CopyToClipboard>
              ) : (
                <IconBtn
                  text={'使用'}
                  iconLeft={<IconCopy />}
                  onClick={() => {
                    try {
                      props.promptSend(
                        index_3,
                        index_4,
                        3,
                        props.list[index_1].children[index_2].info,
                      )
                    } catch (error) {}
                  }}></IconBtn>
              )}
            </div>
            <div
              className={styles['info']}
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html:
                  list.length &&
                  list[index_1].children[index_2] &&
                  list[index_1].children[index_2].info
                    ? list[index_1].children[index_2].info
                    : '暂无数据',
              }}></div>
          </>
        )}
        <div className={styles['btnWrap']}>
          <div
            className={styles['btn']}
            onClick={() => {
              try {
                props.promptSend(
                  index_3,
                  index_4,
                  1,
                  props.list[index_3].children[index_4].title,
                )
              } catch (error) {}
            }}>
            <span>使用该条目</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromptMod
