import React, { useRef, useState } from 'react'
import styles from './style/tem-form-mod.module.less'
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Tooltip,
  Collapse,
  Message,
  Radio,
} from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import { FormInstance } from '@arco-design/web-react/es/Form'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea
const CollapseItem = Collapse.Item
import IconFromValue from '@/assets/newImgs/form-value.svg'
import request from '@/utils/request'
import {
  IconArrowDown,
  IconArrowUp,
  IconErrorTip,
} from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { getChatGptSave } from '@/model/reference'
import { useHistory } from 'react-router-dom'
import { showModal } from '@/share/actions'
function TemFormMod(props) {
  const formRef = useRef<FormInstance>()
  const avdenRef = useRef<FormInstance>()
  const { onUpdateCurStep, templateData, theme, onSetMessageId } = props
  const dataRef = useRef<any>()
  const heightdataRef = useRef<any>()
  const [editTexValue, setEditTexValue] = useState({})
  const text = useRef('')
  const textQ = useRef('')
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [textareaAgain, setTextareaAgain] = useState([])
  const history = useHistory()

  // 点击按钮触发展开/折叠
  const togglePanel = () => {
    setIsCollapsed(!isCollapsed)
  }

  // 提交表单
  async function clickSubmit() {
    try {
      // 步骤1: 主表单验证
      const mainFormData = await formRef.current.validate()
      dataRef.current = mainFormData
      const text_more = editTexValue // 创作内容

      // 步骤2: 过滤和验证文本域字段
      const filteredTextareaFields = templateData.step[0].field.filter(
        (item) => item.type === 'textarea' && item.required === true,
      )

      // 步骤3: 循环验证文本域字段
      for (const field of filteredTextareaFields) {
        // 步骤3a: 检查字段值
        const fieldName = field.name
        const fieldValue = editTexValue[fieldName]

        if (fieldValue == undefined || fieldValue.length === 0) {
          // 提示用户输入
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: `请输入${field.filed_name}`,
          })
          return // 结束执行
        }

        // 步骤3b: 二次校验（avdenRef）
        if (templateData.is_use_common_field == false) {
          console.log(2)
          const mergedData = {
            ...dataRef.current,
            ...text_more,
          }
          const baseText = templateData.prompt
          // 步骤3d: 替换文本
          const replacedText = baseText.replace(
            /\$\{([^}]+)\}/g,
            (match, placeholder) => {
              return mergedData[placeholder] || ''
            },
          )
          textQ.current = replacedText
        } else {
          avdenRef.current.validate().then(async (values) => {
            // 步骤3c: 合并数据
            heightdataRef.current = values
            const mergedData = {
              ...dataRef.current,
              ...heightdataRef.current,
              ...text_more,
            }

            const mergedData1 = { ...heightdataRef.current }
            const baseText = templateData.prompt
            const baseText1 = templateData.Heightprompt

            // 步骤3d: 替换文本
            const replacedText = baseText.replace(
              /\$\{([^}]+)\}/g,
              (match, placeholder) => {
                return mergedData[placeholder] || ''
              },
            )

            const replacedText1 = baseText1.replace(
              /\$\{([^}]+)\}/g,
              (match, placeholder) => {
                return mergedData1[placeholder] || ''
              },
            )

            textQ.current =
              replacedText +
              replacedText1.replace(
                /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu,
                '',
              )
          })
        }

        // 步骤3d: 定时器（doApi()）
        setTimeout(() => {
          doApi()
          console.log(textQ.current)
        }, 100)
      }

      // 步骤4: 文本域字段不存在或不需要验证的情况
      if (filteredTextareaFields.length === 0) {
        // 步骤4a: 二次校验（avdenRef）
        // 步骤3b: 二次校验（avdenRef）
        if (templateData.is_use_common_field == false) {
          console.log(2)
          const mergedData = {
            ...dataRef.current,
            ...text_more,
          }
          const baseText = templateData.prompt
          // 步骤3d: 替换文本
          const replacedText = baseText.replace(
            /\$\{([^}]+)\}/g,
            (match, placeholder) => {
              return mergedData[placeholder] || ''
            },
          )
          textQ.current = replacedText
        } else {
          avdenRef.current.validate().then(async (values) => {
            // 步骤3c: 合并数据
            heightdataRef.current = values
            const mergedData = {
              ...dataRef.current,
              ...heightdataRef.current,
              ...text_more,
            }

            const mergedData1 = { ...heightdataRef.current }
            const baseText = templateData.prompt
            const baseText1 = templateData.Heightprompt

            // 步骤3d: 替换文本
            const replacedText = baseText.replace(
              /\$\{([^}]+)\}/g,
              (match, placeholder) => {
                return mergedData[placeholder] || ''
              },
            )

            const replacedText1 = baseText1.replace(
              /\$\{([^}]+)\}/g,
              (match, placeholder) => {
                return mergedData1[placeholder] || ''
              },
            )

            textQ.current =
              replacedText +
              replacedText1.replace(
                /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu,
                '',
              )
          })
        }

        // 步骤4d: 定时器（doApi()）
        setTimeout(() => {
          console.log(textQ.current)
          doApi()
        }, 100)
      }
    } catch (error) {
      console.error('表单验证失败', error)
    }
  }

  const [messageId, setMessageId] = useState('')
  const [conversationId, setConversationId] = useState('')
  const [sendSuccess, setSendSuccess] = useState(true)
  function catchGPTError(tooltip) {
    setSendSuccess(true)
    events.emit(ACTIONS.TEM_SHOW_BUTTON, {
      isTure: true,
    })
    Message.error({
      icon: <IconErrorTip useCurrentColor={false} />,
      content: tooltip,
    })
  }
  function doApi() {
    // textQ.current = textQ.current.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu, '')//过滤emjoi
    let tmpText = ''

    setSendSuccess(false)
    events.emit(ACTIONS.TEM_SHOW_BUTTON, {
      isTure: false,
    })
    const opts = {
      openWhenHidden: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: textQ.current,
        // message: '你好',
        conversationId: '',
        parentMessageId: '',
        stream: true,
        type: 4,
      }),
    }

    try {
      // let reply = '';
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        process.env.REACT_APP_CHATGPT_URL + '/textStreamGPT',
        {
          ...opts,
          signal: controller.signal,
          onclose() {
            // catchGPTError('服务器连接错误,请重新尝试#01')
            throw new Error(
              `Failed to send message. Server closed the connection unexpectedly.`,
            )
          },
          onerror(error) {
            console.log(error)
            // 服务器或网络错误处理
            request
              .post(
                process.env.REACT_APP_CHATGPT_URL + '/textStreamGPT',
                JSON.stringify({
                  message: textQ.current,
                  // message: '你好',
                  conversationId: '',
                  parentMessageId: '',
                  stream: true,
                  type: 4,
                }),
              )
              .then((res) => {
                const error = res.data
                // todo 流控等提示
                if (error.data.text) {
                  // 这里是原生 POST CATCH，此时的登录失效401、接口无权限等424，都将被下层接管，并正确掌控，遂这里不需要再次控制。

                  // 异常预处理
                  if (error.data.text == '[TOO_MANY_REQUESTS]') {
                    catchGPTError('请勿频繁请求#00-3')
                    return
                  }

                  if (error.data.text == '[OUT_OF_3H_FLOW]') {
                    // 获取当前时间
                    const currentTime = new Date()

                    // 计算3小时后的时间
                    const retryTime = new Date(
                      currentTime.getTime() + 3 * 60 * 60 * 1000,
                    )

                    // 格式化时间
                    const hours = retryTime
                      .getHours()
                      .toString()
                      .padStart(2, '0')
                    const minutes = retryTime
                      .getMinutes()
                      .toString()
                      .padStart(2, '0')

                    // 创建新的错误信息
                    const newErrorMessage = `对话次数已经达到上限，请在 ${hours}:${minutes} 后重试。`

                    catchGPTError(newErrorMessage)
                    return
                  }

                  if (error.data.text == '[OUT_OF_MIN_FLOW]') {
                    catchGPTError(
                      '1分钟内免费次数已用完，请稍后再试或开通会员#00-1#00-3',
                    )
                    setTimeout(() => {
                      showModal('VipContentModal')
                      window.location.hash = 'vip'
                    }, 1000)
                    return
                  }

                  if (error.data.text == '[OUT_OF_DAY_FLOW]') {
                    catchGPTError(
                      '今日免费次数已用完，请明日再试或开通会员#00-2',
                    )
                    setTimeout(() => {
                      showModal('VipContentModal')
                      window.location.hash = 'vip'
                    }, 1000)
                    return
                  }

                  if (error.data.text == '[OUT_OF_MIN_FLOW_VIP]') {
                    catchGPTError('1分钟内 VIP 次数已用完，请稍后再试#00-3')
                    return
                  }

                  if (error.data.text == '[OUT_OF_DAY_FLOW_VIP]') {
                    catchGPTError('今日 VIP 次数已用完，请明日再试#00-4')
                    return
                  }
                  if (error.data.text == '[ERROR]' && error.data.id == 0) {
                    catchGPTError('描述含有敏感词，请调整描述语句后再试#01-3')
                    return
                  }
                  //备用
                  if (error.data.text == '[ERROR]') {
                    catchGPTError('接口返回错误，请稍后再试#01-2')
                    return
                  }
                }
              })
              .catch((error) => {
                console.log(error.response)
                if (error.response && error.response.status === 500) {
                  // 只在状态码为500时触发错误提示
                  catchGPTError('接口连接错误,请重新尝试#012')
                }
              })
            throw error
          },
          onmessage(message) {
            console.log(message)
            if (
              message.event == 'error' &&
              message.data ==
                '{"code":503,"error":"There was an error communicating with ChatGPT."}'
            ) {
              setSendSuccess(true)
              events.emit(ACTIONS.TEM_SHOW_BUTTON, {
                isTure: true,
              })
              return
            }
            // 倒数第二句话，即全文
            // 等待解决 workaround，早晚摘除 message.data !== '[DONE]' 后重新捋逻辑
            if (message.event == 'result' && message.data !== '[DONE]') {
              tmpText = JSON.parse(message.data).response
            } else {
              tmpText =
                message.data == '[DONE]'
                  ? tmpText
                  : tmpText + message.data.substring(1, message.data.length - 1)
            }

            text.current = tmpText.replaceAll('\\n', ' \n ')
            console.log(text.current)
            // if (
            //   text.current.includes(
            //     'code":503,"error":"There was an error communicating with ChatGPT.',
            //   )
            // ) {
            //   catchGPTError('接口返回错误，请重新尝试#503')
            // } else {
            //   onUpdateCurStep(1, text.current)
            // }
            onUpdateCurStep(1, text.current)

            if (message.data == '[ERROR]') {
              catchGPTError('接口返回错误，请稍后重试#02')
              return
            }

            // 倒数第二条时给 done 逻辑
            if (message.event == 'result') {
              controller.abort()
              console.log(message)

              // 结束时再设置相关 id
              const tmpJson = JSON.parse(message.data)

              setSendSuccess(true)
              events.emit(ACTIONS.TEM_SHOW_BUTTON, {
                isTure: true,
              })
              console.log(tmpJson)
              if (tmpJson.conversationId) {
                setConversationId(tmpJson.conversationId)
              }

              if (tmpJson.messageId) {
                setMessageId(tmpJson.messageId)
              }
              getChatGptSave({
                conversationId: tmpJson.conversationId,
                input: textQ.current,
                messageId: tmpJson.messageId,
                model: 'gpt-3.5',
                output: text.current,
                type: 'openai',
              })
              onSetMessageId(tmpJson.messageId)
              return
            } else if (message.data !== '[DONE]') {
              // 倒数第二条之前给 stream 逻辑
            }
          },
        },
      )
    } catch (err) {
      catchGPTError('接口发生错误或超时未响应，请稍后重试#05')
      setSendSuccess(true)
      events.emit(ACTIONS.TEM_SHOW_BUTTON, {
        isTure: true,
      })
    }
  }

  // 高级设置默认值
  const initialValues = {}
  templateData.Heightcommon.forEach((field) => {
    initialValues[field.name] = field.default
  })

  // 渲染表单默认值
  let FrominitialValues = {}
  templateData.step[0].field.forEach((item) => {
    if (item.type === 'select') {
      FrominitialValues[item.name] = item.default_value
    } else {
      FrominitialValues[item.name] = ''
    }
  })

  // 点击提示按钮设置默认值
  function handleInputDefault(e) {
    formRef.current.setFieldValue(e.name, e.default_value)
  }
  // 柯里化函数，接收 field 作为参数，返回处理默认值的函数
  const handleButtonClickCurried = (field) => () => {
    console.log(field) // 这里可以访问到 field
    console.log(field.default_value) // 这里可以访问到默认值

    // 在这里处理设置默认值的逻辑
    setEditTexValue((prevValues) => ({
      ...prevValues,
      [field.name]: field.default_value,
    }))
  }
  const handleBetter = (name, filed_name) => () => {
    console.log(name) // 这里可以访问到 field
    console.log(filed_name) // 这里可以访问到默认值

    // 在这里处理设置默认值的逻辑
    setTextareaAgain((prevValues) => ({
      ...prevValues,
      [name]: name,
      [filed_name]: filed_name,
    }))
  }
  return (
    <div className={`${styles.write_form} ${styles[theme]}`}>
      <div className={`${styles.content_wrap}`}>
        <div className={styles.content}>
          <div className={styles.wp}>
            <div className={styles.title}>{templateData.info}</div>
            {templateData && (
              <Form
                layout={'vertical'}
                size={'large'}
                requiredSymbol={{ position: 'end' }}
                ref={formRef}
                initialValues={FrominitialValues}>
                {templateData.step[0]?.field?.map((field) => (
                  <Form.Item
                    tooltip={field.info}
                    key={field.filed_name}
                    label={field.filed_name}
                    field={field.name}
                    rules={
                      field.required && field.type !== 'textarea' // 添加判断，当type为textarea时不添加校验规则
                        ? [
                            {
                              required: true,
                              message: `请输入${field.filed_name}`,
                            },
                          ]
                        : []
                    }>
                    {field.type === 'input' ? (
                      <Input
                        style={{ paddingTop: '8px', paddingBottom: '8px' }}
                        placeholder={field.placeholder}
                        suffix={
                          <Tooltip content="示例">
                            <div
                              className={`${styles['renderIcon']} ${styles['renderIconIpt']}`}
                              onClick={() => handleInputDefault(field)}>
                              <IconFromValue />
                            </div>
                          </Tooltip>
                        }
                      />
                    ) : field.type === 'textarea' ? (
                      <div style={{ position: 'relative' }}>
                        <Input.TextArea
                          value={editTexValue[field.name] || ''}
                          style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            minHeight: 100,
                            paddingRight: '42px',
                          }}
                          autoSize
                          placeholder={field.placeholder}
                          onChange={(e) => {
                            setEditTexValue({
                              ...editTexValue,
                              [field.name]: e,
                            })
                          }}
                        />
                        <Tooltip content="示例">
                          <div
                            className={`${styles['renderIcon']} ${styles['renderIconTex']}`}
                            onClick={handleButtonClickCurried(field)}>
                            <IconFromValue />
                          </div>
                        </Tooltip>
                      </div>
                    ) : field.type === 'select' ? (
                      <Select>
                        {' '}
                        {/* Set the default value here */}
                        {field.value.split('\r\n').map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : field.type === 'radio' ? (
                      <Radio.Group>
                        {field.value.split('\r\n').map((option) => (
                          <Radio key={option} value={option}>
                            {option}
                          </Radio>
                        ))}
                      </Radio.Group>
                    ) : null}
                  </Form.Item>
                ))}
              </Form>
            )}

            {/* 高级设置 */}
            {templateData.is_use_common_field ? (
              <div>
                <div className={styles['panel']} onClick={togglePanel}>
                  <span className={styles['left']}>高级设置</span>
                  <span className={styles['right']}>
                    {isCollapsed ? <IconArrowDown /> : <IconArrowUp />}
                  </span>
                </div>
                <div
                  className={`${styles['panel-content']} ${
                    isCollapsed ? styles['open'] : ''
                  }`}>
                  <Form
                    layout={'vertical'}
                    size={'large'}
                    requiredSymbol={{ position: 'end' }}
                    initialValues={initialValues}
                    ref={avdenRef}>
                    {templateData.Heightcommon.map((field) => (
                      <Form.Item
                        key={field.filed_name}
                        label={field.filed_name}
                        field={field.name}
                        rules={
                          field.required
                            ? [
                                {
                                  required: true,
                                  message: `请输入${field.filed_name}`,
                                },
                              ]
                            : []
                        }>
                        {field.type === 'radio' ? (
                          <Radio.Group>
                            {field.value.split('\r\n').map((option) => (
                              <Radio key={option} value={option}>
                                {option}
                              </Radio>
                            ))}
                          </Radio.Group>
                        ) : field.type === 'select' ? (
                          <Select>
                            {field.value.split('\r\n').map((option) => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        ) : null}
                      </Form.Item>
                    ))}
                  </Form>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={`${styles.bot_btn} ${styles.poss}`}>
            <div
              className={`${styles['wp']} ${
                !sendSuccess ? styles['disable'] : ''
              }`}>
              <div className={styles.btn}>
                <BorderBtn
                  onClick={() => {
                    clickSubmit()
                  }}
                  gradient={true}
                  size={'big'}
                  text={
                    sendSuccess ? (
                      `生成${templateData.title}`
                    ) : (
                      <div className={styles['loading']}>
                        努力加载中
                        <div className={styles['loading-poi']}></div>
                        <div className={styles['loading-poi']}></div>
                        <div className={styles['loading-poi']}></div>
                      </div>
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemFormMod
