import React from 'react'
import { SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { IconAnnotation } from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'

const PLUGIN_NAME = 'commentMenu'

class CommentMenuController extends SylController {
  public name = PLUGIN_NAME
  private isComment = true
  private curClass
  private newClass

  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.CAN_NEXT_COMMENT, this.nextComment)
  }

  editorWillUnmount(): void {
    events.on(ACTIONS.CAN_NEXT_COMMENT, this.nextComment)
  }
  private nextComment = (payload) => {
    this.isComment = payload.isComment
  }

  private handleChildrensCommentId = (_editor: SylApi) => {
    if (!this.isComment) {
      // Message.warning('您还有一条未批注');
      // return;
      events.emit(ACTIONS.REMOVE_COMMENT_EDITBOX, {
        id: this.newClass,
        className: this.curClass,
      })
    }

    const state = _editor.view.state

    const { $from, $to } = state.selection
    const tr = state.tr

    // check if the selection has a comment Mark
    const hasCommentMark = state.doc.rangeHasMark(
      $from.pos,
      $to.pos,
      state.schema.marks.comment,
    )

    const uniqueId = Date.now() // TODO: use uuid or add user id to make it unique
    this.curClass = 'comment-' + uniqueId
    this.newClass = this.curClass

    if (hasCommentMark) {
      // iterate through the selection marks
      for (let start = $from.pos; start < $to.pos; start++) {
        const _marks = _editor.view.state.doc
          .resolve(start)
          .marksAcross(_editor.view.state.doc.resolve(start + 1))
        const commentMark = _marks.find((mark) => mark.type.name === 'comment')
        if (commentMark) {
          const oldClass = commentMark.attrs.class || ''

          // prepend oldClass !!!
          this.newClass = oldClass + ' ' + this.curClass
          tr.removeMark(start, start + 1, commentMark)
          tr.addMark(
            start,
            start + 1,
            state.schema.marks.comment.create({
              class: this.newClass + ' ' + 'hl',
            }),
          )
        } else {
          tr.addMark(
            start,
            start + 1,
            state.schema.marks.comment.create({
              class: this.newClass + ' ' + 'hl',
            }),
          )
        }
      }
    } else {
      tr.addMark(
        $from.pos,
        $to.pos,
        state.schema.marks.comment.create({
          class: this.newClass + ' ' + 'hl',
        }),
      )
    }

    this.isComment = false
    // events.emit(ACTIONS.FROM_COMMENT_CLICK_EDITOR, {
    //   setSelectContent: '',
    //   barActiveIndex: DocBtnType.annotate,
    //   nodeInfo: '',
    // })

    events.emit(ACTIONS.ADD_EDITOR_COMMENT, {
      id: this.newClass,
      class: this.curClass,
      content: state.doc.textBetween($from.pos, $to.pos),
      setSelectContent: '',
      barActiveIndex: DocBtnType.annotate,
      nodeInfo: '',
    })
    _editor.view.dispatch(tr)
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    // type: 'dropdown',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div
          className="commentBox iconBox"
          onClick={() => this.handleChildrensCommentId(_editor)}>
          <IconAnnotation
            className="iconCommentBox icon"
            useCurrentColor={true}></IconAnnotation>
          <span>注释</span>
        </div>
      )
    },
    value: [],
  }
}

class CommentMenuPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = CommentMenuController
}

export { CommentMenuPlugin }
