import React from 'react'
import styles from './style/index.module.less'
import { Message, Modal } from '@arco-design/web-react'
import { ReactComponent as Logo } from '../../assets/imgs/logo2.svg'
import Robot from '@/assets/imgs/robot.svg'
// import Qr from "../../assets/imgs/qr.svg";
import Line from '@/assets/imgs/line2.svg'
import IconMsgSuccess from '@/assets/newImgs/msg-success.svg'
import {
  IconCopy,
  IconCollectFill,
  IconCollect,
  IconSuccessTip,
} from '@arco-iconbox/react-aidb-v2'
import IconBtn from '../../IconBtn'
import copy from 'copy-to-clipboard'
import ReactMarkdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import SyntaxHighlighter from 'react-syntax-highlighter'

import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { getCancelChat } from '@/model/reference'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

function CollectModal(props) {
  const theme = useSelector((state: GlobalState) => state.theme)
  function close() {
    props.close()
  }

  var content1 = props.content
  function handleCopy() {
    copy(props.content)
    Message.success({
      icon: <IconSuccessTip useCurrentColor={false} />,
      content: '复制成功',
    })
  }
  function handleCancelCollect() {
    console.log(props.uuid)
    getCancelChat({ messageIds: [props.uuid] }).then((res) => {
      events.emit(ACTIONS.CANCEL_COLLECT, {
        id: [props.uuid],
      })
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '取消收藏成功',
      })
      props.close()
    })
  }

  return (
    <Modal
      title={null}
      style={{ width: '540px' }}
      className={'modal-aidb bgn'}
      visible={props.visible}
      onCancel={() => close()}
      footer={null}>
      <div className={`${styles.fav_pop} ${styles[theme]}`}>
        <div className={styles.top}>
          <div className={styles.title}>智能助理</div>
          <div className={styles.mod2}>
            <div className={styles.mod2_top}>
              <div className={styles.text}>{props.title}</div>
              <div className={styles.info}>
                <span>我</span>
                <em>{props.date}</em>
              </div>
              <Line style={{ marginTop: '25px' }}></Line>
            </div>
            <div className={styles.mod2_bot}>
              <div className={styles.robot}>
                <div className={styles.avatar}>
                  <Robot />
                </div>
                <div className={styles.name}>智能机器人</div>
              </div>
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /```(\w+)/.exec(content1 || '')
                    // console.log(match, inline)
                    return !inline && match ? (
                      <SyntaxHighlighter
                        // eslint-disable-next-line react/no-children-prop
                        children={String(children).replace(/\n$/, '')}
                        style={a11yDark}
                        language={
                          <code className={className} {...props}>
                            {match[1]}
                          </code>
                        }
                        PreTag="div"
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  },
                }}>
                {/* 这里会把\n吃掉，得重复两次，原因未知 */}
                {/* {item.title.replace(/\n/g, '\n\n')} */}
                {content1}
              </ReactMarkdown>
            </div>
          </div>
          <div className={styles.btn_wrap}>
            <IconBtn
              text={'复制'}
              iconLeft={<IconCopy />}
              onClick={handleCopy}></IconBtn>
            <IconBtn
              onClick={handleCancelCollect}
              color={'active'}
              text={'取消收藏'}
              iconLeft={<IconCollectFill />}></IconBtn>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CollectModal
