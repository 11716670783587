import React, { useState } from 'react'
import styles from '../templatePage/style/tem-form-mod.module.less'
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Message,
} from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import OutlinePickResultItem from './OutlinePickResultItem'
import { events } from '@/helpers/event-emitter'
import { IconErrorTip, IconWarnTip } from '@arco-iconbox/react-aidb-v2'
import { ACTIONS } from '@/share/constants'
import request from '@/utils/request'

const FormItem = Form.Item
const Option = Select.Option
const TextArea = Input.TextArea

function WriteFormResultMod(props) {
  const [sendSuccess, setSendSuccess] = useState(true)
  const [form] = Form.useForm()
  const { allLoading, theme, ismarkDownCur } = props

  //提交表单
  function clickSubmit(e) {
    const curId = e
    const selectTitle = form.getFieldValue('s1')
    const selectIntro = form.getFieldValue('s2')
    const selectOutLine = form.getFieldValue('s3')

    if (selectTitle && (ismarkDownCur ? true : selectIntro) && selectOutLine) {
      // Save values to local storage
      localStorage.setItem('selectTitle', selectTitle)
      localStorage.setItem('selectIntro', selectIntro)
      localStorage.setItem('selectOutLine', selectOutLine)

      if (ismarkDownCur) {
        events.emit(ACTIONS.TEMALLMARKDOWN)
      } else {
        events.emit(ACTIONS.TEM_SENDMSG, { curId })
      }
    } else {
      let warningMessage = ''
      if (!selectTitle) {
        warningMessage = '请先输入标题'
      } else if (!selectIntro) {
        warningMessage = '请先输入简介'
      } else if (!selectOutLine) {
        warningMessage = '请先输入大纲'
      }

      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: warningMessage,
      })
    }
  }

  return (
    <div className={`${styles.write_form} ${styles[theme]}`}>
      <div className={`${styles.pb}  ${styles.content_wrap}`}>
        <div className={styles.content}>
          <div className={styles.wp}>
            <div className={styles.title}>生成文章</div>
            <Form
              form={form}
              initialValues={{
                s1: localStorage.getItem('selectTitle'),
                s2: localStorage.getItem('selectIntro'),
                s3: localStorage.getItem('selectOutLine'),
              }}
              layout={'vertical'}
              requiredSymbol={{ position: 'end' }}
              size={'large'}
              autoComplete="off">
              <FormItem
                label="文章标题"
                field="s1"
                rules={[{ required: true, message: '请输入标题' }]}>
                <TextArea
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  autoSize
                />
              </FormItem>
              <FormItem label="文章简介" field="s2" tooltip="文章简介">
                <TextArea
                  autoSize
                  maxLength={1000}
                  style={{ minHeight: 100 }}
                />
              </FormItem>
              <FormItem label="大纲" field="s3">
                <TextArea
                  autoSize
                  maxLength={10000}
                  style={{ minHeight: 100 }}
                />
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div className={`${styles.bot_btn} ${styles.poss}`}>
        <div
          className={`${styles['wp']} ${
            !sendSuccess ? styles['disable'] : ''
          }`}>
          <div className={styles.btn}>
            <BorderBtn
              theme={theme}
              onClick={() => {
                clickSubmit(4)
              }}
              color={true}
              disable={!allLoading}
              size={'big'}
              text={
                allLoading ? (
                  '重新生成文章'
                ) : (
                  <div className={`${styles['loading']} ${styles['isMore']}`}>
                    努力加载中
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WriteFormResultMod
