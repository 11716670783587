import React, { useEffect, useState } from 'react'
import styles from './style/step-header-mod.module.less'
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
} from '@arco-iconbox/react-aidb-v2'
import BorderBtn from '@/components/BorderBtn'
import { Tooltip } from '@arco-design/web-react'
import { showModal } from '@/share/actions'
import { useHistory } from 'react-router-dom'

function StepHeaderMod(props) {
  const history = useHistory()

  const step = [
    {
      title: '添加信息',
    },
    {
      title: '生成标题',
    },
    {
      title: '生成简介',
    },
    {
      title: '生成大纲',
    },
    {
      title: '生成文章',
    },
  ]
  const curStep = props.curStep
  const theme = props.theme
  const { membershipType } = props
  function handleStepClick(index) {
    if (index <= props.curStep) {
      props.jumpTo(index, {})
    }
  }

  function handleToVip() {
    showModal('VipContentModal')
  }
  const handleNavigation = () => {
    history.push('/home')
  }
  return (
    <div className={`${styles.header} ${styles[theme]}`}>
      <div className={styles.header_left}>
        <div className={styles.backIcon} onClick={handleNavigation}>
          <IconArrowLeft />
        </div>
        <span>{props.title}</span>
      </div>
      <div className={styles.header_right}>
        {membershipType == 2 ? (
          <Tooltip
            content={
              '您是SVIP用户，当前模型由小悟 4.0 强力驱动，小悟一路为您保驾护航'
            }>
            <div
              className={`${styles.header_right_btn} ${styles.SvipBtn}`}
              onClick={handleToVip}>
              <div className={styles.header_btn_img}>
                <img
                  src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/年卡.svg"
                  alt=""
                />
              </div>
              <div className={styles.header_btn_txt}>SVIP 用户</div>
            </div>
          </Tooltip>
        ) : membershipType == 1 ? (
          <Tooltip
            content={
              '您是VIP用户，当前模型由小悟 3.5 强力驱动，升级 SVIP 享受小悟 4.0 更强生成能力'
            }>
            <div
              className={`${styles.header_right_btn} ${styles.vipBtn}`}
              onClick={handleToVip}>
              <div className={styles.header_btn_img}>
                <img
                  src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/月卡.svg"
                  alt=""
                />
              </div>
              <div className={styles.header_btn_txt}>VIP 用户</div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip content={'升级 VIP 享受更多使用次数'}>
            {/* 普通卡 */}
            <div className={styles.header_right_btn} onClick={handleToVip}>
              <div className={styles.header_btn_img}>
                <img
                  src="https://open.v-dk.com/aidb/wp-content/uploads/sites/10/2023/11/emptyCard.svg"
                  alt=""
                />
              </div>
              <div className={styles.header_btn_txt}>普通用户</div>
            </div>
          </Tooltip>
        )}
      </div>
      {!props.hideStep && (
        <div className={styles.header_step}>
          {step.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleStepClick(index)}
                className={`${styles.step_item} ${
                  curStep >= index ? styles.active : ''
                }`}>
                {index === 0 ? null : (
                  <div className={styles.step_item_icon}>
                    <IconArrowRight />
                  </div>
                )}
                <div className={styles.step_item_index}>
                  {curStep > index ? <IconCheck /> : index + 1}
                </div>
                <div className={styles.step_item_title}>{item.title}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default StepHeaderMod
