import React, { lazy, Suspense } from 'react'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'

const DRAWER_COMPONENTS = {
  DemoDrawer: lazy(() => import('./demo')),
  MoreDrawer: lazy(() => import('./more-drawer')),
  ToolDrawer: lazy(() => import('./tool-drawer')),
  ExportDrawer: lazy(() => import('./export-drawer')),
  ToolBoxDrawer: lazy(() => import('./tool-box-drawer')),
}

const initialDrawers = []

type State = {
  type: string
  props: any
}

export default class DrawerContainer extends React.Component {
  state = {
    drawers: initialDrawers,
  }

  componentDidMount() {
    events.on(ACTIONS.SHOW_DRAWER, this.showDrawer)
    events.on(ACTIONS.HIDE_DRAWER, this.hideDrawer)
    events.on(ACTIONS.UPDATE_DRAWER, this.updateDrawer)
  }

  componentWillUnmount() {
    events.off(ACTIONS.SHOW_DRAWER, this.showDrawer)
    events.off(ACTIONS.HIDE_DRAWER, this.hideDrawer)
    events.off(ACTIONS.UPDATE_DRAWER, this.updateDrawer)
  }

  showDrawer = (payload: State) => {
    this.setState({
      drawers: this.state.drawers.concat(payload),
    })
  }

  // 更新弹框数据
  updateDrawer = (payload: State & { drawerIndex?: number }) => {
    const drawers = this.state.drawers || []
    const index = payload.drawerIndex || null
    drawers.forEach((item) => {
      if (item.type === payload.type && (index === null || index === item)) {
        item.props = payload.props
      }
    })
    this.setState({
      drawers: [...drawers],
    })
  }

  hideDrawer = (data: { type: string; drawerIndex?: number }) => {
    const type = data.type
    const index = data.drawerIndex || null

    // 多个同类型弹窗
    const sameDrawers = this.state.drawers.filter(
      (drawer) => drawer.type === type,
    ).length
    if (typeof index === 'number' && sameDrawers > 1) {
      this.setState({
        drawers: type
          ? this.state.drawers.filter((_, i) => i !== index)
          : initialDrawers,
      })

      return
    }

    this.setState({
      drawers: type
        ? this.state.drawers.filter((drawer) => drawer.type !== type)
        : initialDrawers,
    })
  }
  render() {
    const Targets = this.state.drawers.map(({ type, props }) => ({
      SpecificDrawer: DRAWER_COMPONENTS[type],
      props,
      type,
    }))

    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <Suspense fallback={<></>}>
        {Targets.map(({ SpecificDrawer, type, props }, index) => (
          <SpecificDrawer
            key={type + index}
            isOpen={true}
            hide={() => this.hideDrawer({ type, drawerIndex: index })}
            drawerProps={props}
          />
        ))}
      </Suspense>
    )
  }
}
