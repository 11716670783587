import React from 'react'
import styles from './style/prompt-weight.module.less'
import PromptTitle from './PromptTitle'
import { Input, Space } from '@arco-design/web-react'
import { Slider } from '@arco-design/web-react'

const TextArea = Input.TextArea

function PromptWeight(props) {
  const {
    title,
    tooltip,
    value,
    showPlus,
    showMinus,
    onSliderChange,
    defaultValue,
    max,
    min,
    step,
  } = props
  if (isNaN(value)) {
    console.log(1)
  } else {
    console.log(value)
  }

  const handleSliderChange = (newValue) => {
    if (onSliderChange) {
      onSliderChange(newValue) // 通过传递的回调来更新滑动条值
    }
  }
  return (
    <div>
      <div className={styles.title}>
        <PromptTitle
          title={title}
          tooltip={tooltip}
          showPlus={showPlus}
          showMinus={showMinus}
        />
        <div className={styles.slider_value}>{isNaN(value) ? '' : value}</div>
      </div>
      <div className={styles.slider}>
        <Slider
          min={min} // 最小值
          max={max} // 最大值
          step={step} // 最小调整单元
          defaultValue={defaultValue} // 默认值
          className={'g-slider'}
          value={value}
          onChange={handleSliderChange}
        />
      </div>
    </div>
  )
}

export default PromptWeight
