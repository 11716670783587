import { DocBtnType } from '@/helpers/enums'
import {
  IconChat,
  IconExpand,
  IconCorrect,
  IconInstruction,
  IconParaphrase,
  IconStrongRewrite,
  IconTranslate,
} from '@arco-iconbox/react-aidb-v2'
import React from 'react'
import style from './style/toolsMod.module.less'
interface Props {
  onToolClick: (typeObj) => void
}

const ToolsMod = (props: Props) => {
  const { onToolClick } = props
  const list = [
    {
      type: DocBtnType.proof,
      icon: <IconCorrect />,
      text: '校对',
      color: '#FFEBE8',
      name: 'proof',
      activeColor: '#E33E4A',
    },
    {
      type: DocBtnType.rewrite,
      icon: <IconParaphrase />,
      text: '改写',
      color: '#E0F8EC',
      name: 'rewrite',
      activeColor: '#1EB980',
    },
    {
      type: DocBtnType.reduce,
      icon: <IconStrongRewrite />,
      text: '降重',
      color: '#D9F5F6',
      name: 'reduce',
      activeColor: '#159D9D',
    },
    {
      type: DocBtnType.continuationSingle,
      icon: <IconExpand />,
      text: '扩写',
      color: '#DAEDFF',
      name: 'continuation',
      activeColor: '#3C6DDD',
    },
    {
      type: DocBtnType.translate,
      icon: <IconTranslate />,
      text: '翻译',
      color: '#F2E4FF',
      name: 'translate',
      activeColor: '#874CC3',
    },
    // {
    //   type: DocBtnType.directives,
    //   icon: <IconInstruction />,
    //   text: '指令',
    //   color: '#FFE6FB',
    //   activeColor: '#C437AE',
    // },
    // {
    //   type: DocBtnType.assistant,
    //   icon: <IconChat />,
    //   text: '助理',

    //   color: '#FFF2D8',
    //   activeColor: '#FF8C00',
    // },
  ]

  return (
    <div className={style.content}>
      {list.map((item) => {
        return (
          <div
            key={item.type}
            className={style.toolItem}
            onClick={() => onToolClick({ item })}>
            <div
              className={style.icon}
              style={{ color: `${item.activeColor}` }}>
              {item.icon}
            </div>
            <div className={style.text}>{item.text}</div>
          </div>
        )
      })}
    </div>
  )
}

export default ToolsMod
