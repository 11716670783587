import {
  BackgroundPlugin,
  ColorPlugin,
  EmojiPlugin,
  ImagePlugin,
  // LinkPlugin,
} from '@syllepsis/access-react'
import { LinkPlugin } from '../lib/custom/plugins/link/index'
import {
  AlignCenterPlugin,
  AlignJustifyPlugin,
  AlignLeftPlugin,
  AlignRightPlugin,
  AudioPlugin,
  VideoPlugin,
  BlockQuotePlugin,
  BoldPlugin,
  BulletListPlugin,
  FontSizePlugin,
  FormatClearPlugin,
  FormatPainterPlugin,
  HeaderPlugin,
  HrPlugin,
  ItalicPlugin,
  LetterSpacePlugin,
  LineHeightPlugin,
  LineIndentPlugin,
  ListItemPlugin,
  OrderedListPlugin,
  ParagraphPlugin,
  RedoPlugin,
  SpaceAfterPlugin,
  SpaceBeforePlugin,
  SpaceBothPlugin,
  StrikePlugin,
  SubPlugin,
  SupPlugin,
  UnderlinePlugin,
  UndoPlugin,
} from '@syllepsis/plugin-basic'

import { TablePlugin } from '@syllepsis/plugin-table'
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block'

import { FontFamilyPlugin } from './custom/plugins/font-family'
import { RewritePlugin } from './custom/plugins/rewrite'
import { ContinuePlugin } from './custom/plugins/continuation'
import { TranslatePlugin } from './custom/plugins/translate'
import { HighlightPlugin } from './custom/plugins/highlightMark'
import { RewriteHighlightPlugin } from './custom/plugins/rewriteHighlightMark'
import request from '@/utils/request'
import { CommentMenuPlugin } from './custom/plugins/commentMenu'
import { CommentPlugin } from './custom/plugins/comment'
import { ToolsPlugin } from './custom/plugins/tools'
import { ChatGPTPlugin } from './custom/plugins/chatGPT'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import { MapKeyPlugin } from './custom/plugins/mapKey'
import { EditorNavPlugin } from './custom/plugins/editorNav'
import { ReducePlugin } from './custom/plugins/reduce'
import { InnerToolPlugin } from './custom/plugins/innerTool'
import { PlaceholderPlugin } from './custom/plugins/placeholderPlugin'
import { DirectivesPlugin } from './custom/plugins/directives'
import { AiDirectivesPlugin } from './custom/plugins/aiDirectives'
import { AiToolsPlugin } from './custom/plugins/aiTools'
import { ToolsInlinePlugin } from './custom/plugins/toolsInline'
import { TaskListPlugin } from './custom/plugins/taskList'
import { HeaderH1Plugin } from './custom/plugins/headerH1'
import { HeaderH2Plugin } from './custom/plugins/headerH2'
import { HeaderH3Plugin } from './custom/plugins/headerH3'
import { ToolsMobilePlugin } from './custom/plugins/toolsMobile'
const defaultPlugins = [
  // new ToolsInlinePlugin(),
  // new TaskListPlugin(),
  new ToolsMobilePlugin(),
  new AiToolsPlugin(),
  new AiDirectivesPlugin(),
  new DirectivesPlugin(),
  new PlaceholderPlugin(),
  new InnerToolPlugin(),
  new EditorNavPlugin(),
  new MapKeyPlugin(),
  new CommentPlugin(),
  new CommentMenuPlugin(),
  new RewritePlugin(),
  new ContinuePlugin(),
  new TranslatePlugin(),
  new ReducePlugin(),
  new FontFamilyPlugin(),
  new HighlightPlugin(),
  new RewriteHighlightPlugin(),
  new ToolsPlugin(),
  new ChatGPTPlugin(),
  new RedoPlugin(),
  new UndoPlugin(),
  new ColorPlugin(),
  new BoldPlugin(),
  new BlockQuotePlugin(),
  new ListItemPlugin({
    matchInnerTags: ['section', 'p'],
    allowedLineHeights: [],
    allowedSpaceBefores: [],
    allowedSpaceAfters: { default: true, value: 20 },
    allowedSpaceBoths: [],
  }),
  new BulletListPlugin(),
  new OrderedListPlugin(),
  new CodeBlockPlugin({
    lineNumbers: true,
    lineWrapping: true,
    mode: { name: 'javascript', json: true },
  }),
  new HrPlugin(),
  new HeaderPlugin(),
  new HeaderH1Plugin(),
  new HeaderH2Plugin(),
  new HeaderH3Plugin(),
  new BackgroundPlugin(),
  new ItalicPlugin(),
  new SubPlugin(),
  new SupPlugin(),
  new StrikePlugin(),
  new UnderlinePlugin(),
  new ParagraphPlugin({
    addMatchTags: ['section'],
    allowedAligns: ['left', 'center', 'right', 'justify'],
    allowedClass: [],
    allowedLineHeights: [],
    allowedLineIndents: [],
    allowedSpaceBefores: [],
    allowedSpaceAfters: { default: true, value: 20 },
    allowedSpaceBoths: [],
  }),
  new FormatClearPlugin(),
  new FontSizePlugin({
    allowedValues: [12, 14, { value: 16, default: true }, 17, 18, 20, 24, 32],
    values: [12, 14, { value: 16, default: true }, 18, 20, 24, 32],
    unit: 'px',
    defaultFontSize: 18,
  }),
  new LetterSpacePlugin({
    allowedValues: [0, 0.5, 1, 1.5],
  }),
  new AlignLeftPlugin(),
  new AlignCenterPlugin(),
  new AlignRightPlugin(),
  new AlignJustifyPlugin(),
  new SpaceBeforePlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new SpaceAfterPlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new SpaceBothPlugin({
    values: [0, 4, 8, 12, 16, { value: 20, default: true }, 24, 28, 30],
  }),
  new LineHeightPlugin({
    values: [1, 1.5, { value: 1.75, default: true }, 1.88, 2, 3],
  }),
  new LineIndentPlugin(),
  new FormatPainterPlugin(),
  new TablePlugin({
    button: {
      activeColor: '#FF0F0F',
      trigger: 'click',
    },
    // columnResize: { handleWidth: 5, cellMinWidth: 24 },
    table: {
      allowTableNodeSelection: false,
      defaultCellWidth: 100,
      useTableHeader: false,
    },
  }),
  new ImagePlugin({
    uploadType: 'file',
    maxLength: 100,
    // uploadBeforeInsert: true,
    deleteFailedUpload: true,
    disableResize: false,
    maxWidth: 0,
    uploader: (img) =>
      new Promise((resolve) => {
        const formData = new FormData()
        formData.append('file', img)
        request
          .post('/api/api-aidb/upload/uploadFile', formData)
          .then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                resolve({
                  src: res.data.data,
                  width: 0,
                  height: 0,
                })
                events.emit(ACTIONS.SAVE_PIC_EDITOR)
              }, 1500)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }),
  }),

  new VideoPlugin({
    // uploader: async (file) =>
    //   Promise.resolve({
    //     src: URL.createObjectURL(file),
    //     size: file.size,
    //     type: file.type,
    //     title: file.name,
    //   }),
    uploadBeforeInsert: true,
    uploader: (file) =>
      new Promise((resolve) => {
        const formData = new FormData()
        formData.append('file', file)
        request
          .post('/api/api-aidb/upload/uploadFile', formData)
          .then((res) => {
            if (res.status === 200) {
              resolve({
                src: res.data.data,
                // width: 0,
                // height: 0,
              })
              events.emit(ACTIONS.SAVE_PIC_EDITOR)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }),
  }),
  new LinkPlugin(),
  // new AudioPlugin({
  //   uploader: (file) =>
  //     Promise.resolve({
  //       src: URL.createObjectURL(file),
  //     }),
  // }),
  new AudioPlugin({
    uploadBeforeInsert: true,
    uploader: (file) =>
      new Promise((resolve) => {
        const formData = new FormData()
        formData.append('file', file)
        request
          .post('/api/api-aidb/upload/uploadFile', formData)
          .then((res) => {
            if (res.status === 200) {
              resolve({
                src: res.data.data,
                title: file.name,
              })
              events.emit(ACTIONS.SAVE_PIC_EDITOR)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }),
  }),
  new EmojiPlugin(),
  PlaceholderPlugin,
]

export default defaultPlugins
