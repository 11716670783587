import { BlockAtom, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec, Node } from 'prosemirror-model'

import {
  addAttrsByConfig,
  createFileInput,
  getFromDOMByConfig,
  IUserAttrsConfig,
  setDOMAttrByConfig,
  simpleUploadHandler,
} from '../../utils/tool'

interface IFileUploadAttrs {
  src: string
  title: string
  type: string
  size: number | string
}

interface IFileUploadProps {
  uploader: (
    file: File,
    editor: SylApi,
  ) => Promise<{
    src: string
    width?: number
    height?: number
    [key: string]: any
  }>
  uploadBeforeInsert?: boolean
  addAttributes?: IUserAttrsConfig
  accept?: string
  isInline?: boolean
}

const NAME = 'fileUpload'

class FileUploadController extends SylController<IFileUploadProps> {
  public name = NAME
  private input: HTMLInputElement

  public uploader: IFileUploadProps['uploader'] = async () =>
    Promise.reject('please provide uploader in controllerProps for audio')

  constructor(editor: SylApi, props: IFileUploadProps) {
    super(editor, props)
    if (props.uploader) this.uploader = props.uploader
    this.input = createFileInput({
      multiple: false,
      accept: props.accept || '*.pdf',
      onChange: this.onChange,
      getContainer: () => editor.root,
    })
  }

  private onChange = async (e: Event) =>
    simpleUploadHandler({
      name: NAME,
      target: e.target as HTMLInputElement,
      editor: this.editor,
      uploadBeforeInsert: this.props.uploadBeforeInsert !== false,
      uploader: this.uploader,
      getAttrs: (src, file) => ({
        src,
        type: file.type,
        size: file.size,
        name: file.name,
      }),
    })

  public toolbar = {
    icon: '1122',
    handler: (editor: SylApi) => this.input.click(),
  }

  public editorWillUnmount = () => {
    this.editor.root.removeChild(this.input)
  }
}

class FileUpload extends BlockAtom<IFileUploadAttrs> {
  public props: IFileUploadProps
  constructor(editor: SylApi, props: IFileUploadProps) {
    super(editor, props)
    this.props = props
    addAttrsByConfig(props.addAttributes, this)
    if (props.isInline) {
      this.inline = true
      this.group = 'inline'
    }
  }

  public name = 'fileUpload'

  public parseDOM = [
    {
      tag: 'div.syl-fileUpload-wrapper',
      getAttrs: (dom: HTMLBaseElement) => {
        let src = dom.getAttribute('src') || ''
        const title = dom.getAttribute('title') || ''
        const size = dom.dataset.size || 0
        let type
        // const sourceEls = dom.querySelectorAll(
        //   'p',
        // ) as NodeListOf<HTMLBaseElement>
        // ;([].slice.call(sourceEls) as HTMLSourceElement[]).some((el) => {
        //   src = el.getAttribute('src') || ''
        //   type = el.getAttribute('type') || ''
        //   if (src) return true
        // })
        if (!src) return false
        const formattedAttrs = { src, title, type, size }
        getFromDOMByConfig(this.props.addAttributes, dom, formattedAttrs)
        return formattedAttrs
      },
    },
  ]
  public attrs = {
    src: {
      default: '',
    },
    type: {
      default: '',
    },
    title: {
      default: '',
    },
    size: {
      default: 0,
    },
  }
  public toDOM = (node: Node) => {
    const { src, type, size, title } = node.attrs
    const attrs = {
      controls: 'true',
      'data-size': size,
      title,
    }
    setDOMAttrByConfig(this.props.addAttributes, node, attrs)
    const renderSpec = [
      'div',
      attrs,
      [
        'templ',
        { style: 'display:none' },
        ['p', { type, href: src, target: '__blank', download: src }, src],
      ],
      [
        'mask',
        ['a', { type, href: src, target: '__blank', download: src }, src],
      ],
    ] as DOMOutputSpec
    if (this.inline) return renderSpec

    return [
      'div',
      {
        class: 'syl-fileUpload-wrapper',
        align: 'center',
        __syl_tag: 'true',
        src,
      },
      renderSpec,
    ] as DOMOutputSpec
  }
}

class FileUploadPlugin extends SylPlugin<IFileUploadProps> {
  public name = NAME
  public Controller = FileUploadController
  public Schema = FileUpload
}

export { FileUpload, FileUploadController, FileUploadPlugin }
