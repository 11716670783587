import React, { useEffect, useRef, useState } from 'react'
import styles from './style/left-form-mod.module.less'
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Tooltip,
  Collapse,
  Message,
  Radio,
  Upload,
  Dropdown,
  Menu,
  Spin,
} from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import { FormInstance } from '@arco-design/web-react/es/Form'
import { fetchEventSource } from '@waylaidwanderer/fetch-event-source'
import IconFromValue from '@/assets/newImgs/form-value.svg'
import AgentImg from '@/assets/newImgs/aiagent.svg'
import AgentImgEdit from '@/assets/newImgs/aiagentEdit.svg'
import request from '@/utils/request'
import {
  IconAiTool,
  IconCamera,
  IconClosure,
  IconErrorTip,
  IconPlus,
  IconUpload,
  IconWarnTip,
} from '@arco-iconbox/react-aidb-v2'
import { AgentAvatar, CreateAgentDarft, SaveAgent } from '@/model/reference'
import { GetQueryValue } from '@/utils/common'
import { useHistory } from 'react-router-dom'
import { showModal } from '@/share/actions'
import Markdown from '@/components/Markdown'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
const FormItem = Form.Item
function LeftFormMod(props) {
  const {
    theme,
    avatar,
    initialValues,
    showDetail,
    handleCreateImg,
    imgLoading,
  } = props
  const history = useHistory()
  //上传图片的文件
  const [file, setFile] = useState<any>(avatar)
  const uploadInputRef = useRef(null)
  const [form] = Form.useForm()
  const fileRef = useRef(file)

  const [formItems, setFormItems] = useState([
    { id: 1, name: 'example1', value: '' },
    { id: 2, name: 'example2', value: '' },
    { id: 3, name: 'example3', value: '' },
  ])

  const droplist = (
    <Menu className={'custom-dropdown drop-icon'}>
      <div className={'dropdown-list'}>
        <Menu.Item
          key="upload"
          onClick={() => {
            uploadInputRef.current.click()
          }}>
          <IconUpload />
          手动上传
        </Menu.Item>
        <Menu.Item
          disabled={initialValues?.title?.length > 0 ? false : true}
          style={{ opacity: initialValues?.title?.length > 0 ? 1 : 0.5 }}
          key="ai"
          onClick={() => {
            handleCreateImg(initialValues?.title)
          }}>
          <IconAiTool />
          AI 生成头像
        </Menu.Item>
      </div>
    </Menu>
  )

  useEffect(() => {
    events.on(ACTIONS.AIAGENT_SUBMIT, handleSubmit)
    return () => {
      events.off(ACTIONS.AIAGENT_SUBMIT, handleSubmit)
    }
  }, [])

  useEffect(() => {
    // 设置初始表单值
    form.setFieldsValue(initialValues)
    console.log(initialValues?.conversationStarters)

    // 更新formItems状态
    const updatedFormItems =
      initialValues?.conversationStarters?.map((item, index) => ({
        id: index + 1,
        name: `example${index + 1}`,
        value: item,
      })) || []

    setFormItems(updatedFormItems)

    // 更新example相关的表单字段
    const formItemValues = {}
    updatedFormItems.forEach((item) => {
      formItemValues[item.name] = item.value
    })
    form.setFieldsValue(formItemValues)
  }, [initialValues])

  const updateFormItem = (id, newValue) => {
    const updatedItems = formItems.map((item) =>
      item.id === id ? { ...item, value: newValue } : item,
    )
    setFormItems(updatedItems)

    // 更新表单中的对应字段值
    const currentFormValues = form.getFields()
    console.log(currentFormValues)
    console.log(id, newValue)

    const foundItem = updatedItems.find((item) => item.id === id)
    if (foundItem) {
      const newFormValues = {
        ...currentFormValues,
        [foundItem.name]: newValue,
      }
      form.setFieldsValue(newFormValues)
    }
  }

  useEffect(() => {
    setFile(avatar)
    console.log(file)
  }, [avatar])

  useEffect(() => {
    fileRef.current = file // 更新 ref 的值
  }, [file])

  //添加引导
  const addFormItem = () => {
    if (formItems.length < 6) {
      const newItem = {
        id: formItems.length + 1,
        name: `example${formItems.length + 1}`,
        value: '',
      }
      setFormItems([...formItems, newItem])
    } else {
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: '最多只允许有6条引导',
      })
    }
  }

  //删除引导
  const removeFormItem = (idToRemove) => {
    const updatedItems = formItems.filter((item) => item.id !== idToRemove)
    setFormItems(updatedItems)
  }

  //上传图片
  function handleUpload(info) {
    const { file } = info
    new Promise((resolve) => {
      const formData = new FormData()
      formData.append('file', file)
      request
        .post('/api/api-aidb/upload/uploadFile', formData)
        .then((res) => {
          setFile(res.data.data.url)
        })
        .catch((error) => {
          console.log(error)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '上传图片失败，请重新尝试',
          })
        })
    })
  }

  //测试智能体
  const handleSubmit = () => {
    //ai生成且已有id内容的情况
    if (GetQueryValue('id')?.length > 0) {
      form
        .validate()
        .then(() => {
          const formData = form.getFieldsValue()
          console.log('Form data:', formData)
          console.log(file)

          const conversationStarters = []
          Object.keys(formData).forEach((key) => {
            if (key.startsWith('example')) {
              conversationStarters.push(formData[key])
            }
          })
          console.log(conversationStarters)

          SaveAgent({
            name: formData.title,
            description: formData.info,
            faceImg: fileRef.current || avatar,
            instructions: formData.instruct,
            uuid: GetQueryValue('id'),
            conversationStarters: conversationStarters,
          }).then((res) => {
            console.log(res.data)

            if (res.data.success != true) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content: res.data.msg,
              })
            }
            showDetail()
            events.emit(ACTIONS.AIAGENT_TAB_TYPE)
          })
        })
        .catch((errorInfo) => {
          console.log('Validation Failed:', errorInfo)
        })
    } else {
      //手动输入生成
      CreateAgentDarft().then((res) => {
        if (res.data.success) {
          history.push(`/agentDetail?id=${res.data.data.uuid}`)
          form
            .validate()
            .then(() => {
              const formData = form.getFieldsValue()
              console.log('Form data:', formData)
              console.log(file)

              const conversationStarters = []
              Object.keys(formData).forEach((key) => {
                if (key.startsWith('example')) {
                  conversationStarters.push(formData[key])
                }
              })

              SaveAgent({
                name: formData.title,
                description: formData.info,
                faceImg: fileRef.current || avatar,
                instructions: formData.instruct,
                uuid: GetQueryValue('id'),
                conversationStarters: conversationStarters,
              }).then((res) => {
                console.log(res.data)
                if (res.data.success != true) {
                  Message.error({
                    icon: <IconErrorTip useCurrentColor={false} />,
                    content: res.data.msg,
                  })
                }
                showDetail()
                events.emit(ACTIONS.AIAGENT_TAB_TYPE)
              })
            })
            .catch((errorInfo) => {
              console.log('Validation Failed:', errorInfo)
            })
        }
      })
    }
  }

  return (
    <div className={`${styles.write_form} ${styles[theme]}`}>
      <div className={`${styles.content_wrap}`}>
        <div className={styles.content}>
          <div className={styles.wp}>
            <div className={styles.top}>
              <div className={styles.avatr}>
                <Upload
                  fileList={file ? [file] : []}
                  showUploadList={false}
                  accept="image/png, image/jpeg"
                  customRequest={handleUpload}
                  style={{ display: 'none' }}>
                  <input ref={uploadInputRef} style={{ display: 'none' }} />
                </Upload>
                {file ? <img src={file} alt="" /> : <AgentImg />}
                {imgLoading ? (
                  <div className={styles.avatrSpin}>
                    <Spin></Spin>
                  </div>
                ) : (
                  <Dropdown droplist={droplist} trigger={['click']}>
                    <AgentImgEdit className={styles.editImg} />
                  </Dropdown>
                )}
              </div>
              <BorderBtn
                onClick={() => {
                  showModal('AddAgentModal')
                }}
                iconLeft={<IconFromValue></IconFromValue>}
                gradient={true}
                width={150}
                size={'big'}
                text={'AI 生成智能体'}></BorderBtn>
            </div>
            <Form
              form={form}
              layout={'vertical'}
              size={'large'}
              initialValues={initialValues}
              requiredSymbol={{ position: 'end' }}>
              <Form.Item
                rules={[{ required: true, message: '智能体名称不能为空' }]}
                label={'智能体名称'}
                field={'title'}>
                <Input
                  placeholder="起一个名字,例：起名助手"
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: '开场白不能为空' }]}
                tooltip={
                  '第一次与用户交流时，你的智能体会用这段文字来告诉用户应该如何操作和输入来使用AI智能体'
                }
                label={'开场白'}
                field={'info'}>
                <Input.TextArea
                  placeholder="例：你好，我能结合中国古典文化为你起名"
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: '指令不能为空' }]}
                tooltip={
                  '指令（prompt）直接决定你所创造的AI智能体的效果，最简单的公式是：你想让AI智能体扮演的角色+希望ta根据用户什么样输入信息来生成什么样的结果+对智能体所生成的内容的详细要求'
                }
                label={'普通指令'}
                field={'instruct'}>
                <Input.TextArea
                  placeholder="例：你是一位精通中国传统文化的起名大师，你要结合中国古典名著起名，给出姓名、名字寓意和引用的典故诗句原文，要求风格文雅，至少给出5个名字"
                  style={{ minHeight: '150px', maxHeight: '300px' }}
                  autoSize
                />
                {/* <Markdown content={}></Markdown> */}
              </Form.Item>
              {formItems?.map((item) => (
                <Form.Item
                  rules={[{ required: true, message: '引导不能为空' }]}
                  style={{ position: 'relative' }}
                  key={item.id}
                  initialValue={item.value}
                  label={item.id === 1 ? '引导示例' : ''}
                  field={item.name}>
                  <Input
                    placeholder={`请输入示例 ${item.id} 内容`}
                    suffix={
                      formItems.length > 3 &&
                      item.id > 3 && (
                        <IconClosure
                          style={{
                            position: 'absolute',
                            right: '10px',
                          }}
                          onClick={() => removeFormItem(item.id)}
                        />
                      )
                    }
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <BorderBtn
                  theme={theme}
                  onClick={addFormItem}
                  text={'添加'}
                  iconLeft={<IconPlus />}
                />
              </Form.Item>
            </Form>
          </div>
          {/* 底部按钮 */}
          <div className={`${styles.bot_btn} ${styles.poss}`}>
            <div className={`${styles['wp']} `}>
              <div className={styles.btn} onClick={handleSubmit}>
                <BorderBtn gradient={true} size={'big'} text={'测试智能体'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftFormMod
