import { TextStreamGPTError } from '@/share/config'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'

//判断是不是空数组
export function nonEmptyArray(obj: any) {
  return Array.isArray(obj) && !!obj.length
}

// 过滤对象空值
export function removeEmpty(obj: object) {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === '' ||
      (obj[key] && obj[key].toString().trim() === '') ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      delete obj[key]
    }
  })

  return obj
}

/* 
获取url中的链接参数
*/
export function GetQueryValue(queryName: string) {
  // 直接获取查询字符串部分并去掉开头的 '?'
  const queryString = window.location.search.substring(1);
  const vars = queryString.split('&'); // 将查询字符串按 '&' 分割成数组

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('='); // 将每个键值对以 '=' 分割

    try {
      const key = decodeURIComponent(pair[0]); // 解码键
      const value = decodeURIComponent(pair[1]); // 解码值

      if (key === queryName) {
        return value; // 如果找到对应的参数名，返回值
      }
    } catch (error) {
      console.error("Error decoding URI component:", error);
    }
  }

  return null; // 没有找到匹配的参数名，返回 null
}


/**
 * 在当前url中添加查询参数和值
 * @param queryName 查询参数名
 * @param queryValue 查询参数值
 */
export function AddQueryParameter(queryName, queryValue) {
  const url = new URL(window.location.href)
  const existingQuery = url.searchParams.get(queryName)

  if (existingQuery) {
    url.searchParams.delete(queryName)
  }

  url.searchParams.set(queryName, queryValue)
  return url.toString()
}

interface urlQueryValProps {
  uuid?: string
  filePath?: string
  folderUuid?: string
  title?: string
  from?: string
  collectType?: string
  collectDocUuid?: string
  collectFoldUuid?: string
  // docApprovalList: AIDB.ApprovalItem[]
}

export function urlQueryVal(props: urlQueryValProps) {
  const { uuid, filePath, folderUuid, title, from } = props
  return removeEmpty({
    uuid: uuid
      ? uuid
      : GetQueryValue('uuid') && uuid != null
        ? GetQueryValue('uuid')
        : '',
    filePath: filePath
      ? filePath
      : GetQueryValue('filePath') && filePath != null
        ? GetQueryValue('filePath')
        : '',
    folderUuid: folderUuid
      ? folderUuid
      : GetQueryValue('folderUuid') && folderUuid != null
        ? GetQueryValue('folderUuid')
        : '',
    title: title
      ? title
      : GetQueryValue('title') && title != null
        ? GetQueryValue('title')
        : '',
    from: from
      ? from
      : GetQueryValue('from') && from != null
        ? GetQueryValue('from')
        : 'intelligentDocument',
  }) as any
}

export function parseUrl(url: string) {
  if (!url) {
    throw new Error('invalid url')
  }

  const match = url.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
  )

  if (match) {
    return {
      href: url,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  }

  return {}
}

//去除转义字符
export function htmlEscape(str) {
  return String(str)
    .replace(/&amp;/g, '')
    .replace(/&quot;/g, '')
    .replace(/&#39;/g, '')
    .replace(/&lt;/g, '')
    .replace(/&gt;/g, '')
}

export function debounce(fn, delay) {
  let handle
  return function () {
    clearTimeout(handle)
    handle = setTimeout(() => {
      fn()
    }, delay)
  }
}

export function throttle(func, delay) {
  let timeoutId
  let lastExecTime = 0

  return function (...args) {
    const currentTime = Date.now()

    if (currentTime - lastExecTime < delay) {
      // 在延迟期间，取消上一次的执行
      clearTimeout(timeoutId)

      // 在延迟结束后执行
      timeoutId = setTimeout(() => {
        func.apply(this, args)
        lastExecTime = currentTime
      }, delay)
    } else {
      // 延迟期间已过，立即执行
      func.apply(this, args)
      lastExecTime = currentTime
    }
  }
}

//textStreamGPT 错误判断
export function textStreamGPTError(text) {
  const errorEntry = TextStreamGPTError.find((entry) => entry.text === text)
  return errorEntry ? errorEntry.message : '接口返回错误，请稍后再试#01-2'
}

//路由跳转编辑器
export function createToEditor(params) {
  const { from, uuid, update, folderUuid } = params // from 参数手动传入

  const filePath = GetQueryValue('filePath')
  const newFolderUuid = folderUuid || GetQueryValue('uuid') // 文件夹的 uuid
  const teamUuid = GetQueryValue('teamUuid') // 协同 uuid
  const permission = GetQueryValue('permission') // 协同权限 （1-可管理，2-可编辑，3-可阅读）

  const buildQueryParam = (paramName, paramValue) => {
    return paramValue !== undefined && paramValue !== '' && paramValue !== null
      ? `${paramName}=${paramValue}`
      : ''
  }

  const queryParams = [
    buildQueryParam('filePath', filePath), // 文件夹路径
    buildQueryParam('folderUuid', newFolderUuid), // 文件夹的 uuid
    buildQueryParam('teamUuid', teamUuid), // 协同 uuid
    buildQueryParam('permission', permission), // 协同权限 （1-可管理，2-可编辑，3-可阅读）
    buildQueryParam('from', from), // 来自哪个页面
    buildQueryParam('uuid', uuid), // 文档uuid
  ]
    .filter(Boolean)
    .join('&')

  const url = `/documentEdit${queryParams ? `?${queryParams}` : ''}`

  window.location.href = url
}

//路由跳转编辑器1
export function treeCreateToEditor(params) {
  const { from, filePath, folderUuid, teamUuid, permission } = params // from 参数手动传入

  const buildQueryParam = (paramName, paramValue) => {
    return paramValue !== undefined && paramValue !== '' && paramValue !== null
      ? `${paramName}=${paramValue}`
      : ''
  }

  const queryParams = [
    buildQueryParam('filePath', filePath), // 文件夹路径
    buildQueryParam('folderUuid', folderUuid), // 文件夹的 uuid
    buildQueryParam('teamUuid', teamUuid), // 协同 uuid
    buildQueryParam('permission', permission), // 协同权限 （1-可管理，2-可编辑，3-可阅读）
    buildQueryParam('from', from), // 来自哪个页面
  ]
    .filter(Boolean)
    .join('&')

  const url = `/documentEdit${queryParams ? `?${queryParams}` : ''}`

  window.location.href = url
}

//获取当前时间戳
export function nowDate() {
  const currentDate = new Date()
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')

  const formattedTime = `${hours}:${minutes}:${seconds}`
  return formattedTime
}
