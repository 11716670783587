import React, { useEffect, useRef, useState } from 'react'
import styles from './style/index.module.less'
import SideMod from '@/components/DocumentShare/sideMod'
import ShareHeader from '@/components/DocumentShare/shareHeader'
import { getDocByShare } from '@/model/documentEdit'
import { GetQueryValue } from '@/utils/common'
import { Spin } from '@arco-design/web-react'
import DocumentMod from '@/components/DocumentShare/docShareMod'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'
import IconBtn from '@/components/IconBtn'
import { IconReport } from '@arco-iconbox/react-aidb-v2'
import Btn from '@/components/Ui/btn'
import palette from '@/helpers/palette'
import Modal from '@/components/Modal'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
import { useLocation } from 'react-router-dom'
const DocumentShare = () => {
  const theme = useSelector((state: GlobalState) => state.theme)
  const [foldList, setFoldList] = useState([])
  const [currentItem, setCurrentItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasShare, setHasShare] = useState(false)
  const [close, setClose] = useState(false)
  const [isOpenSide, setIsOpenSide] = useState(false)
  const [isHasSide, setIsHasSide] = useState(false)
  const foldListRef = useRef([])
  const currentItmRef = useRef(null)
  const [docLoading, setdocLoading] = useState(false)

  useEffect(() => {
    getDocByShareHandle()
    document.body.classList.remove('dark')
    document.body.classList.remove('light')
    document.body.classList.add(theme)
  }, [])

  // 监听路由
  useEffect(() => {
    const handleSearchChange = () => {
      // window.location.reload() // 刷新页面
      events.emit(ACTIONS.SHARE_DOC_SIDE_CLICK, {
        uuid: GetQueryValue('selectUuid'),
        fileType: 0,
      })
    }

    window.addEventListener('popstate', handleSearchChange)

    return () => {
      window.removeEventListener('popstate', handleSearchChange)
    }
  }, [])

  useEffect(() => {
    foldListRef.current = foldList
  }, [foldList])

  useEffect(() => {
    if (currentItmRef.current) {
      setIsLoading(true)
    }
    currentItmRef.current = currentItem
  }, [currentItem])

  const getDocByShareHandle = async () => {
    const res = await getDocByShare({ uuid: GetQueryValue('uuid') })
    if (res.data.success) {
      console.log(res.data.data[0].fileType)
      setFoldList(res.data.data)
      foldListRef.current = res.data.data

      // 如果 fileType 不等于 0，则发送第二个请求
      if (res.data.data[0].fileType !== 0) {
        const selectUuid = GetQueryValue('selectUuid')
          ? GetQueryValue('selectUuid')
          : findObjectTypeZero(res.data.data)?.uuid
        const resDoc = await getDocByShare({
          uuid: GetQueryValue('uuid'),
          docUuid: selectUuid,
        })
        if (resDoc.data.success) {
          setHasShare(true)
          setCurrentItem(resDoc.data.data[0])
          currentItmRef.current = resDoc.data.data[0]
          document.title = resDoc.data
            ? resDoc.data?.data[0].title + ' - 悟智AI'
            : '悟智AI'
        }
      } else {
        // 如果 fileType 等于 0，则不发送第二个请求
        setHasShare(true)
        setCurrentItem(res.data.data[0])
        currentItmRef.current = res.data.data[0]
        document.title = res.data
          ? res.data?.data[0].title + ' - 悟智AI'
          : '悟智AI'
      }
    } else {
      setHasShare(false)
      setErrorMsg(res.data.msg)
    }
  }

  const itemClickHandle = async (item) => {
    setdocLoading(true)
    const res = await getDocByShare({
      uuid: GetQueryValue('uuid'),
      docUuid: item.uuid,
    })
    if (res.data.success) {
      setCurrentItem(res.data.data[0])
      currentItmRef.current = res.data.data[0]
      document.title = res.data
        ? res.data?.data[0].title + ' - 悟智AI'
        : '悟智AI'
      setdocLoading(false)
    }
  }

  const findObjectTypeZero = (data) => {
    for (const item of data) {
      if (item.fileType === 0) {
        return item
      }
      if (item.children) {
        const result = findObjectTypeZero(item.children)
        if (result !== null) {
          return result
        }
      }
    }
    return null
  }

  const toggleSideHandle = (close) => {
    setClose(close)
  }

  const changeSideHandle = (isOpen) => {
    setIsOpenSide(isOpen)
  }

  const hasSideHandle = (hasSide) => {
    setHasShare(hasSide)
  }

  return (
    <React.Fragment>
      {isLoading && hasShare ? (
        <div
          className={`${
            close || currentItem.fileType == 0 ? styles.close : ''
          } ${styles.share_page} ${styles.pageLayout} ${styles[theme]}`}>
          <div
            className={`${styles.sideNav} ${
              isOpenSide && styles.isOpenSideNav
            }`}>
            <SideMod
              foldList={foldList}
              onItemClick={itemClickHandle}
              currentId={currentItem.uuid}
              toggleSide={(close) => {
                toggleSideHandle(close)
              }}
              isShowSide={(show) => hasSideHandle(show)}
            />
          </div>
          <div className={styles.header}>
            <ShareHeader
              foldList={foldList}
              title={currentItem.title}
              comeFrom={'未知'}
              gmtModified={currentItem.gmtModified}
              onChangeSide={changeSideHandle}
              docUuid={currentItem.uuid}
              docContent={currentItem.content}
              isExportPdf={true}
            />
          </div>
          <div className={styles.contain}>
            {docLoading ? (
              <div className={styles['doc-loading']}>
                <Spin />
                <span style={{ marginLeft: 5 }}>加载中...</span>
              </div>
            ) : (
              <DocumentMod
                title={currentItem.title}
                docContent={currentItem.content}
                foldList={foldList}></DocumentMod>
            )}
          </div>
        </div>
      ) : !hasShare ? (
        <div
          className={` ${styles.share_page} ${styles[theme]} ${styles.pageLayout}`}>
          <div className={styles.sideNav}>
            <SideMod foldList={foldList} />
          </div>
          <div className={styles.header}>
            <ShareHeader isExportPdf={true} />
          </div>
          <div className={styles.contain} style={{ paddingLeft: 0 }}>
            <p className={styles.errorMsg}>{errorMsg}</p>
          </div>
        </div>
      ) : (
        <div className={`${styles.share_page} ${styles.pageLayoutLoading}`}>
          <Spin size={50} className={`${styles[`spin`]}`} />
        </div>
      )}
      <Modal></Modal>
    </React.Fragment>
  )
}

export default DocumentShare
