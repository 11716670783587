import React from 'react'
import styles from './style/index.module.less'

function BorderBtn(props) {
  const { theme } = props
  // primary 彩色线框按钮
  // disable 禁用
  // gradient 渐变色按钮
  //size big：高40，文字14 图标22 onlyIcon 只有图标 34*34
  //fixedWidth 固定宽度180
  return (
    <div
      className={`${styles['btn']} ${styles[theme]}
         ${props.btn1 ? styles['btn1'] : ''}
         ${props.primary ? styles['primary'] : ''}
         ${props.markdownWidth ? styles['markdownWidth'] : ''}
          ${props.disable ? styles['disable'] : ''}  
          ${props.gradient ? styles['gradient'] : ''} 
          ${props.gray ? styles['gray'] : ''} 
          ${styles[props.size]} ${styles[props.fixedWidth]} 
           ${props.color ? styles['color'] : ''} 
           ${props.orangeWhite ? styles['orangeWhite'] : ''}
           ${props.active ? styles['btn-active'] : ''}
           ${props.ACTIVE_COLOR_1 ? styles['btn-active-color-1'] : ''}
          `}
      style={{
        width: props.width + 'px',
        height: props.height + 'px',
        fontSize: props.fontSize + 'px',
        padding: props.padding,
      }}
      onClick={props.onClick}>
      {props.iconLeft && (
        <div className={`${styles['btnIconLeft']} ${styles['btnIconLeft']}`}>
          {props.iconLeft}
        </div>
      )}
      {props.text && (
        <div
          className={`${styles['btnText']} ${styles['btnText']}`}
          style={{
            width: props.width + 'px',
            height: props.height + 'px',
          }}>
          {props.text}
        </div>
      )}
      {props.iconRight && (
        <div className={`${styles['btnIconRight']} ${styles['btnIconRight']}`}>
          {props.iconRight}
        </div>
      )}
    </div>
  )
}

export default BorderBtn
