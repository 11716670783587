import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconInstruction, IconParaphrase } from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'
import { Tooltip } from '@arco-design/web-react'

interface AiDirectivesProps {
  name: string
}

const PLUGIN_NAME = 'AiDirectives'

class AiDirectivesSchema extends Inline<AiDirectivesProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class AiDirectivesController extends SylController<AiDirectivesProps> {
  public name = PLUGIN_NAME
  constructor(editor: SylApi, props) {
    super(editor, props)
    events.on(ACTIONS.TO_EDITOR, this.replaceHandle)
    events.on(ACTIONS.MOD_INNER_TOOL, () => this.modInnerHandle(this.editor))
  }

  editorWillUnmount() {
    events.off(ACTIONS.TO_EDITOR, this.replaceHandle)
    events.off(ACTIONS.MOD_INNER_TOOL, this.updateDocHandle)
  }

  public modInnerHandle = (_editor: SylApi) => {
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    console.log(selectedContent, nodeInfo)
    events.emit(ACTIONS.OPEN_DIRECTIVES_EDITOR, {
      pos: nodeInfo.index + nodeInfo.length,
      selectedContent,
    })
    _editor.setSelection({ index: nodeInfo.index + nodeInfo.length, length: 0 })
    _editor.command.toolbarInline.hide()
    _editor.blur()
  }

  public updateDocHandle = (_editor: SylApi) => {
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    console.log(selectedContent, nodeInfo)
    events.emit(ACTIONS.OPEN_DIRECTIVES_EDITOR, {
      pos: nodeInfo.index + nodeInfo.length,
      selectedContent,
    })
    _editor.setSelection({ index: nodeInfo.index + nodeInfo.length, length: 0 })
    _editor.command.toolbarInline.hide()
    _editor.blur()
  }

  public replaceHandle = (payload) => {
    // Get the style of the old text
    const { nodeInfo, content } = payload
    if (!nodeInfo) return
    const oldTextNode = this.editor.view.state.doc.nodeAt(nodeInfo.index)
    const oldTextStyle = oldTextNode.marks

    // Create a transaction to replace the old text with the new text
    const tr = this.editor.view.state.tr
    tr.replaceWith(
      nodeInfo.index,
      nodeInfo.index + nodeInfo.length,
      this.editor.view.state.schema.text(content, oldTextStyle),
    )

    // Apply the transaction to the editor state
    this.editor.view.dispatch(tr)
    // this.removeHighlightAndTip();
    events.emit(ACTIONS.FROM_EDITOR, {
      setSelectContent: null,
      barActiveIndex: payload.type,
      nodeInfo: null,
    })
  }

  // 自定义菜单栏
  // public toolbar = {
  //   className: PLUGIN_NAME,
  //   // type: 'dropdown',
  //   // 自定义显示按钮
  //   icon: (_editor: SylApi) => {
  //     return (
  //       // <Tooltip position="bottom" trigger="hover" content="改写">
  //       <div
  //         className="AiDirectivesBox iconBox"
  //         onClick={() => this.updateDocHandle(_editor)}>
  //         <IconInstruction
  //           className="iconParaphrase icon"
  //           useCurrentColor={true}></IconInstruction>
  //         <span className="iconTitle">AI指令</span>
  //       </div>
  //       // </Tooltip>
  //     )
  //   },
  //   value: [],
  // }
}

class AiDirectivesPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = AiDirectivesController
  public Schema = AiDirectivesSchema
}

export { AiDirectivesPlugin }
