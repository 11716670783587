import React from 'react'
import { Inline, SylApi, SylController, SylPlugin } from '@syllepsis/adapter'
import { DOMOutputSpec } from 'prosemirror-model'
import { IconRobot } from '@arco-iconbox/react-aidb'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { DocBtnType } from '@/helpers/enums'
import { getSelectionInfo, getTextSelection } from '../../utils'

interface chatGPTProps {
  name: string
}

const PLUGIN_NAME = 'chatGPT'

class chatGPTSchema extends Inline<chatGPTProps> {
  public name = PLUGIN_NAME

  //解析DOM 什么数据会被识别为删除线
  public parseDOM = []

  //修改DOM
  public toDOM = () => {
    return ['span', 0] as DOMOutputSpec
  }
}

class chatGPTController extends SylController<chatGPTProps> {
  public name = PLUGIN_NAME

  constructor(editor: SylApi, props) {
    super(editor, props)
    // events.on(ACTIONS.TO_EDITOR, this.replaceHandle);
  }

  // editorWillUnmount() {
  //   events.off(ACTIONS.TO_EDITOR, this.replaceHandle);
  // }

  public updateDocHandle = (_editor: SylApi) => {
    // prepare the text selection to delimiter (period or end of node)
    const newTextSelection = getTextSelection(_editor)
    // set it to editor
    _editor.view.dispatch(_editor.view.state.tr.setSelection(newTextSelection))
    // then get selection info based on new text selection
    const { selectedContent, nodeInfo } = getSelectionInfo(_editor)
    events.emit(ACTIONS.FROM_CHATGPT_CLICK_EDITOR, {
      setSelectContent: selectedContent,
      barActiveIndex: DocBtnType.assistant,
      nodeInfo: nodeInfo,
    })
  }

  // 自定义菜单栏
  public toolbar = {
    className: PLUGIN_NAME,
    type: '',
    // 自定义显示按钮
    icon: (_editor: SylApi) => {
      return (
        <div
          className="chatGPTBox iconBox"
          onClick={() => this.updateDocHandle(_editor)}>
          <IconRobot
            className="iconChatGPT icon"
            useCurrentColor={true}></IconRobot>
          <span>助理</span>
        </div>
      )
    },
    value: [],
  }
}

class ChatGPTPlugin extends SylPlugin {
  public name = PLUGIN_NAME
  public Controller = chatGPTController
  public Schema = chatGPTSchema
}

export { ChatGPTPlugin }
