import React, { useEffect, useRef, useState } from 'react'
import styles from './style/layout.module.less'
import IconDraw1 from '../../assets/imgs/draw3.svg'
import DrawHead from './DrawHead'
import BorderBtn from '../../components/BorderBtn'
import EmptyMod from './EmptyMod'
import PromptImage from './prompt/PromptImage'
import TextListMod from './TextListMod'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import request from '@/utils/request'
import { aiImgDescribe, getTextStreamGPT, getUserInfo } from '@/model/reference'
let intervalId = null // 用于存储定时器ID
function ImageDismantle2(props) {
  //鉴权登录状态
  useEffect(() => {
    getUserInfo({ uid: '' }).then((res) => {
      console.log(res)
    })
  }, [])
  const [list, setList] = useState([])
  const [base64Data, setBase64Data] = useState([])
  //按钮置灰
  const [sendSuccess, setSendSuccess] = useState(true)
  //按钮样式置灰
  const [isContent, setIsContent] = useState(true)
  //骨架屏
  const [skeleton, setSkeleton] = useState(false)

  //控状态
  const [empty, setEmpty] = useState(true)

  //子组件的base64回调
  function updateImg(value) {
    setBase64Data(value)
  }

  useEffect(() => {
    base64Data?.length > 0 ? setIsContent(false) : setIsContent(true)
  }, [base64Data])

  useEffect(() => {
    document.title = 'AI 图片拆解 - 悟智AI'
  }, [])

  //生成图片
  function createAiImg(base64Data) {
    setSendSuccess(false)
    aiImgDescribe({
      base64: base64Data,
      notifyHook: '',
      state: '',
    })
      .then((res) => {
        if (res.data.success == true) {
          setList([])
          setEmpty(false)
          setSkeleton(true)
          const resultId = res.data.data.result
          submitFetch(resultId)
        } else {
          setSendSuccess(true)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        setSendSuccess(true)
      })
  }

  //查询图片生成进度
  function submitFetch(resultId) {
    setSendSuccess(false)

    function fetchProgress() {
      request
        .get('/api/api-aidb/submit/fetch', {
          params: { id: resultId },
        })
        .then((res) => {
          if (res.data.success === true) {
            const data = res.data.data
            if (data.progress === '100%') {
              getTextStreamGPT({
                message: data.prompt,
                to: '中文',
                type: 1,
                stream: false,
                conversationId: '',
                parentMessageId: '',
              })
                .then((res) => {
                  setList([
                    {
                      enDesc: data.prompt,
                      cnDesc: res.data.response,
                    },
                  ])
                  setSkeleton(false)
                  setTimeout(() => {
                    setSendSuccess(true)
                  }, 1000)
                })
                .catch((error) => {
                  console.error('获取文本流失败:', error)
                  Message.error({
                    icon: <IconErrorTip useCurrentColor={false} />,
                    content: '获取文本流失败，请重新尝试',
                  })
                })
              return // 停止递归
            } else {
              setTimeout(fetchProgress, 3000) // 等待3秒后继续下一个请求
            }
          }
        })
        .catch((error) => {
          console.error('接口错误:', error)
          setSkeleton(false)
          setSendSuccess(true)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '接口错误，请重新尝试',
          })
        })
    }

    // 开始第一个请求
    fetchProgress()
  }

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <DrawHead title={'AI 图片拆解'} hideDoc={true} hideClear={true} />
      </div>
      <div className={styles.contain}>
        <div className={styles.left}>
          <div className={styles.left_contain}>
            <PromptImage
              // hideWeight={true}
              onBase64ImageChange={(value) => {
                updateImg(value)
              }}
              onUpdateImg={updateImg}
              title={'上传图片'}
              tooltip={'上传图片'}
              hideClear={true}
            />
          </div>
          <div
            className={`${styles['left_bot_btn']} ${
              !sendSuccess || isContent ? styles['disable'] : ''
            }`}
            onClick={() => {
              createAiImg(base64Data)
            }}>
            <BorderBtn
              height={50}
              fontSize={14}
              gradient={true}
              text={
                sendSuccess ? (
                  '图片拆解'
                ) : (
                  <div className={`${styles['loading']}`}>
                    努力加载中
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                    <div className={styles['loading-poi']}></div>
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className={styles.right}>
          {!empty && (
            <div className={styles.list}>
              <div className={styles.list_inner}>
                <TextListMod list={list} skeleton={skeleton} />
              </div>
            </div>
          )}
          {empty && (
            <EmptyMod
              icon={<IconDraw1 />}
              text={'请在左侧添加图片，智能生成图片信息'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageDismantle2
