import React from 'react';
import styles from './style/empty-mod.module.less';

function TemEmptyMod(props) {
    return (
        <div className={styles.emptyMod}>
            <div className={styles.icon}>
                {props.icon}
            </div>
            <div className={styles.text}>
                {props.text}
            </div>
        </div>
    );
}

export default TemEmptyMod;
