import React from 'react'
import styles from './style/index.module.less'
import ReferencesEmpty from '@/assets/newImgs/document-empty.svg'
import MessageEmpty from '@/assets/newImgs/pic-message-empty.svg'
import WasteEmpty from '@/assets/newImgs/waste-empty.svg'
import ImgEmpty from '@/assets/newImgs/document-empty.svg'
import SearchEmpty from '@/assets/newImgs/search-empty.svg'
import FileEmpty from '../../assets/newImgs/file-empty.svg'
import CollectEmpty from '@/assets/newImgs/collect-empty.svg'
import AiImgEmpty from '@/assets/newImgs/empty-aiImg.svg'
import AgentEmpty from '@/assets/newImgs/agent-empty.svg'
import BlackDocEmpty from '@/assets/newImgs/black-doc-empty.svg'
import BlackDocsEmpty from '@/assets/newImgs/black-docs-empty.svg'
import BlackCollectEmpty from '@/assets/newImgs/black-collect-empty.svg'
import BlackImgEmpty from '@/assets/newImgs/black-img-empty.svg'
import BlackWasteEmpty from '@/assets/newImgs/black-waste-empty.svg'
import BlackSearchEmpty from '@/assets/newImgs/black-search-empty.svg'
import BlackAgentEmpty from '@/assets/newImgs/agent-empty-black.svg'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
interface Props {
  type?: string
  tips?: string
  title?: string
  titleSize?: number
  top?: number
  divTop?: number
  titleColor?: any
}
export default React.memo(
  ({ type, tips, title, titleSize, top, divTop, titleColor }: Props) => {
    const theme = useSelector((state: GlobalState) => state.theme)

    let isEditEmpty
    const imgSvg = (type) => {
      switch (type) {
        case 'reference':
          return theme == 'dark' ? (
            <BlackDocEmpty
              style={{ width: '200px', height: '200px' }}></BlackDocEmpty>
          ) : (
            <ReferencesEmpty
              style={{ width: '200px', height: '200px' }}></ReferencesEmpty>
          )
        case 'aiImg':
          return theme == 'dark' ? (
            <BlackImgEmpty
              style={{ width: '200px', height: '200px' }}></BlackImgEmpty>
          ) : (
            <AiImgEmpty
              style={{ width: '200px', height: '200px' }}></AiImgEmpty>
          )
        case 'home':
          return theme == 'dark' ? (
            <BlackDocEmpty
              style={{ width: '200px', height: '200px' }}></BlackDocEmpty>
          ) : (
            <ReferencesEmpty
              style={{ width: '200px', height: '200px' }}></ReferencesEmpty>
          )
        case 'message':
          return <MessageEmpty></MessageEmpty>
        case 'search':
          return theme == 'dark' ? (
            <BlackSearchEmpty
              style={{ width: '200px', height: '200px' }}></BlackSearchEmpty>
          ) : (
            <SearchEmpty
              style={{ width: '200px', height: '200px' }}></SearchEmpty>
          )
        case 'dustbin':
          return theme == 'dark' ? (
            <BlackWasteEmpty
              style={{ width: '200px', height: '200px' }}></BlackWasteEmpty>
          ) : (
            <WasteEmpty
              style={{ width: '200px', height: '200px' }}></WasteEmpty>
          )
        case 'docEdit':
          return <ImgEmpty></ImgEmpty>
        case 'collect':
          return theme == 'dark' ? (
            <BlackCollectEmpty
              style={{ width: '200px', height: '200px' }}></BlackCollectEmpty>
          ) : (
            <CollectEmpty
              style={{ width: '200px', height: '200px' }}></CollectEmpty>
          )
        case 'docEmpty':
          return (
            <FileEmpty style={{ width: '200px', height: '200px' }}></FileEmpty>
          )
        case 'proofEmpty':
          return (
            <img
              style={{ maxWidth: 340 }}
              src={require('../../assets/newImgs/proof-noContent.png')}></img>
          )
        case 'proofMobileEmpty':
          return (
            <img
              style={{ width: '40%' }}
              src={require('../../assets/newImgs/proof-noContent.png')}></img>
          )
        case 'rewriteEmpty':
          return (
            <img
              style={{ maxWidth: 360 }}
              src={require('../../assets/newImgs/rewrite-noContent.png')}></img>
          )
        case 'rewriteMobileEmpty':
          return (
            <img
              style={{ width: '40%' }}
              src={require('../../assets/newImgs/rewrite-noContent.png')}></img>
          )
        case 'translateEmpty':
          return (
            <img
              style={{ maxWidth: 360 }}
              src={require('../../assets/newImgs/translate-noContent.png')}></img>
          )
        case 'translateMobileEmpty':
          return (
            <img
              style={{ width: '40%' }}
              src={require('../../assets/newImgs/translate-noContent.png')}></img>
          )
        case 'proofAll':
          return (
            <img
              style={{ maxWidth: 280 }}
              src={require('../../assets/newImgs/proofed.png')}></img>
          )
        case 'commentEmpty':
          return (
            <img
              style={{ maxWidth: 200 }}
              src={require('../../assets/newImgs/empty-note.png')}></img>
          )
        case 'continueEmpty':
          return (
            <img
              style={{ maxWidth: 360 }}
              src={require('../../assets/newImgs/continue-noContent.png')}></img>
          )
        case 'continueMobileEmpty':
          return (
            <img
              style={{ width: '40%' }}
              src={require('../../assets/newImgs/continue-noContent.png')}></img>
          )
        case 'reduceEmpty':
          return (
            <img
              style={{ maxWidth: 360 }}
              src={require('../../assets/newImgs/reduce-noContent.png')}></img>
          )
        case 'reduceMobileEmpty':
          return (
            <img
              style={{ width: '40%' }}
              src={require('../../assets/newImgs/reduce-noContent.png')}></img>
          )
        case 'directivesEmpty':
          return (
            <img
              style={{ maxWidth: 360 }}
              src={require('../../assets/newImgs/directives-noContent.png')}></img>
          )
        case 'toolEmpty':
          return theme == 'dark' ? (
            <BlackDocsEmpty
              style={{ width: '200px', height: '200px' }}></BlackDocsEmpty>
          ) : (
            <FileEmpty style={{ width: '200px', height: '200px' }}></FileEmpty>
          )
        case 'AgentEmpty':
          return theme == 'dark' ? (
            <BlackAgentEmpty
              style={{ width: '200px', height: '200px' }}></BlackAgentEmpty>
          ) : (
            <AgentEmpty
              style={{ width: '200px', height: '200px' }}></AgentEmpty>
          )
        default:
          return <ImgEmpty></ImgEmpty>
      }
    }
    switch (type) {
      case 'reference':
        title = '数据为空'
        break
      case 'aiImg':
        title
        break
      case 'home':
        title = '即刻开始创作吧~'
        break
      case 'search':
        title = '搜索数据为空'
        break
      case 'collect':
        title = '收藏夹为空'
        break
      case 'message':
        title = '暂无通知信息'
        break
      case 'dustbin':
        title = '废纸篓为空'
        tips = '保留文件30天，之后彻底清除'
        break
      case 'docEdit':
        title = '数据为空'
        break
      case 'docEmpty':
        title = '暂无文档内容'
        break
      case 'proofEmpty':
        isEditEmpty = true
        break
      case 'rewriteEmpty':
        isEditEmpty = true
        break
      case 'translateEmpty':
        isEditEmpty = true
        break
      case 'reduceEmpty':
        isEditEmpty = true
        break
      case 'directivesEmpty':
        isEditEmpty = true
        break
      case 'proofAll':
        isEditEmpty = true
        break
      case 'toolEmpty':
        title = '暂无输出结果'
        break
      case 'AgentEmpty':
        title = '暂无数据'
        break
    }

    return (
      <div
        className={`${styles['data-empty']} ${styles[theme]}`}
        style={{ marginTop: `${divTop}px` }}>
        <div className={styles['pic']}>{imgSvg(type)}</div>
        <p
          className={`${styles['title']} ${
            isEditEmpty && styles['editEmptyTitle']
          }`}
          style={{
            fontSize: `${titleSize}px`,
            margin: `${top}px`,
            color: titleColor,
          }}>
          {title}
        </p>
        <p className={styles['tip']}>{tips}</p>
      </div>
    )
  },
)
