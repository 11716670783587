import { marked } from 'marked'
import mathjaxTex2svg from './tex2svg'

const replaceAll = function (find, replace, str) {
  var find = find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  return str.replace(new RegExp(find, 'g'), replace)
}

const mathTex2svgService = (formula) => {
  let mtext = replaceAll('$', '', formula)
  if (!mtext) {
    return ''
  }
  return mathjaxTex2svg(mtext)
}

const tokenizer = {
  // 复杂公式会导致页面卡死
  // code(src) {
  //   // 匹配块
  //   const match = src.match(/\$((?:.*|\n)*?)(\n.*)\$/)
  //   if (match) {
  //     const leftText = match['input'].substring(0, match['index'])
  //     let raw = match['input']
  //     let firstIdx = raw.indexOf('$')
  //     raw = raw.substring(0, raw.indexOf('$', firstIdx + 1) + 1)
  //     return {
  //       type: 'html',
  //       raw: raw,
  //       text: leftText + mathTex2svgService(match[1]),
  //     }
  //   }
  //   return false
  // },
  codespan(src) {
    // 匹配行
    let match = src.match(/\$([^\$\n]+?)\$/)
    if (match) {
      const leftText = match['input'].substring(0, match['index'])
      return {
        type: 'html',
        raw: leftText + match[0],
        text: leftText + mathTex2svgService(match[1]),
      }
    }
    // return false to use original codespan tokenizer
    return false
  },
}

marked.use({ tokenizer })
// /^\$.*?\$/.test(htmlString.trim()) ||
//       /^\$\$.*?\$\$/.test(htmlString.trim())
// 将字符串按 $ 或 $$ 分割成数组      此处要注意文字中出现$怎么处理
const spliceOfFormula = (str) => {
  let result = []

  while (str.length > 0) {
    let dollarIndex = str.indexOf('$')

    if (dollarIndex === -1) {
      result.push({
        text: str,
        type: 'marked',
      })
      break
    } else if (dollarIndex > 0) {
      result.push({
        text: str.slice(0, dollarIndex),
        type: 'marked',
      })
    }

    let isDoubleDollar = str.charAt(dollarIndex + 1) === '$'
    let endDollarIndex = isDoubleDollar
      ? str.indexOf('$$', dollarIndex + 2)
      : str.indexOf('$', dollarIndex + 1)

    // 检查是否找到结束标记
    if (endDollarIndex === -1) {
      result.push({
        text: str.slice(dollarIndex),
        type: 'marked',
      })
      break
    }

    endDollarIndex += isDoubleDollar ? 2 : 1 // 加上匹配字符的长度

    let innerStr = str.slice(dollarIndex, endDollarIndex)
    let validCases = [
      /^\$.*?\$/, // '$'开头和结尾
      /^\$\$.*?\$\$/, // '$$'开头和结尾
      /^\n\$.*?\$\n$/, // '\n$'开头'$\n'结尾
      /^\n\$.*?\$/, // '\n$'开头'$'结尾
      /^\$.*?\$\n$/, // '$'开头'$\n'结尾
      /^\n\$\$.*?\$\$\n$/, // '\n$$'开头'$$\n'结尾
      /^\n\$\$.*?\$\$/, // '\n$$'开头'$$'结尾
      /^\$\$.*?\$\$\n$/, // '$$'开头'$$\n'结尾
    ]

    let isPureMath = validCases.some((pattern) => {
      return pattern.test(innerStr)
    })

    // 如果是纯数学公式，去掉开始和结束的 "$" 或 "$$"
    if (isPureMath) {
      innerStr = innerStr.slice(
        isDoubleDollar ? 2 : 1,
        -(isDoubleDollar ? 2 : 1),
      )
    }

    result.push({
      text: innerStr,
      type: isPureMath ? 'mathjaxTex2svg' : 'marked',
    })

    str = str.slice(endDollarIndex)
  }

  return result
}

// 去除spliceOfFormula中无效html标签
const fixInvalidHTML = (htmlArr) => {
  const fixHTML = (htmlString) => {
    // 替换转义的 "<" 和 ">"
    const unescapedHtmlString = htmlString
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')

    const parser = new DOMParser()
    const doc = parser.parseFromString(unescapedHtmlString.trim(), 'text/html')
    return doc.body.innerHTML
  }

  // 使用 map 对每个元素的 tag 属性进行修复，并使用 filter 移除空字符串
  let fixedArr = htmlArr
    .map((item) => ({
      ...item,
      tag: fixHTML(item.tag),
      type:
        item.tag.includes('<svg') && item.tag.includes('</svg>')
          ? 'svg'
          : item.type,
    }))
    .filter((item) => item.tag !== '')

  return fixedArr
}

// 将数学公式进行分割
const splitAndRemoveDollars = (inputStr) => {
  let result = []
  let start = 0,
    end = 0
  while (start < inputStr.length) {
    // 判断是一个 $ 还是两个 $$
    let isDouble = inputStr[start + 1] === '$'
    end = isDouble
      ? inputStr.indexOf('$$', start + 2)
      : inputStr.indexOf('$', start + 1)

    // 如果找不到结束的标志，跳出循环
    if (end === -1) {
      break
    }

    // 处理含有三个 $ 的情况
    if (inputStr[end + 1] === '$' && !isDouble) {
      end++
    }

    var segment = inputStr.slice(start + (isDouble ? 2 : 1), end).trim()
    // 去除可能存在于字符串开始和结尾的$
    while (segment.startsWith('$')) {
      segment = segment.substring(1)
    }
    while (segment.endsWith('$')) {
      segment = segment.slice(0, -1)
    }
    result.push({
      tag: segment,
      type: 'mathjaxTex2svg',
    })
    start = end + (isDouble ? 2 : 1)
  }
  return result
}

// export const toMath = (formula) => mathTex2svgService(formula)
/**
 * markdown 转 html
 *  markdownData：需要转换的字符串
 *  editorPasteMethodType：需要对字符串以哪种类型转换   text_paste：直接文本转换 mark_paste：markdown转换 formula_paste：数学公式转换  mix_paste：文本和公式混合转换
 */
export const toHTML = (markdownData, editorPasteMethodType) => {
  let resultMarkdownSpliceArr = []

  switch (editorPasteMethodType) {
    case 'mark_paste':
      resultMarkdownSpliceArr.push({
        tag: marked(markdownData),
        type: 'html',
      })
      return resultMarkdownSpliceArr

    case 'formula_paste':
      const formulaNativeMarkdownSpliceArr = splitAndRemoveDollars(
        markdownData.trim(),
      )

      formulaNativeMarkdownSpliceArr.forEach((item, index) => {
        item.type === 'mathjaxTex2svg' &&
          resultMarkdownSpliceArr.push({
            tag: mathjaxTex2svg(item.tag),
            type: 'svg',
          })
      })
      return resultMarkdownSpliceArr

    case 'mix_paste':
      const mixNativeMarkdownSpliceArr = spliceOfFormula(markdownData.trim())

      mixNativeMarkdownSpliceArr.forEach((item, index) => {
        if (item.type === 'mathjaxTex2svg') {
          resultMarkdownSpliceArr.push({
            tag: mathjaxTex2svg(item.text),
            type: 'svg',
          })
        } else if (item.type === 'marked') {
          resultMarkdownSpliceArr.push({
            tag: marked(item.text),
            type: 'html',
          })
        }
      })
      return fixInvalidHTML(resultMarkdownSpliceArr)

    default:
      break
  }

  // if (editorPasteMethodType === 'mark_paste') {
  //   return resultMarkdownSpliceArr
  // } else if (editorPasteMethodType === 'formula_paste') {
  //   return resultMarkdownSpliceArr
  // } else if (editorPasteMethodType === 'mix_paste') {
  //   return fixInvalidHTML(resultMarkdownSpliceArr)
  // }

  /**
   * 功能：将所有文本分为数学公式和普通markdown
   * 优点：
   *      1、可以将多个普通markdown直接解析
   *      2、可以将普通markdown生成html标签，数学公式生成svg，两者不冲突
   * 缺点：
   *      1、不能一次性生成数学公式和普通markdown，会造成卡死
   *      2、不能一次性生成多个数学公式，会造成卡死
   *      3、如果是复杂性数学公式，会直接造成卡死
   * 总结：
   *      对普通markdown生成很友好，对数学公式不太友好
   */
  // return marked(markdownData)
  /**
   * 功能：将所有文本生成为一个svg
   * 优点：
   *      1、可以解析markdown数学公式
   *      2、结果只生成一个svg
   * 缺点：
   *      1、不能去除数学公式前的$
   *      2、多个数学公式不会换行
   *      3、不能解析普通markdown，只能解析数学公式
   * 总结：
   *      对数学公式很友好，对普通markdown生成不太友好
   */
  // return mathjaxTex2svg(markdownData)
}
