import React, { useEffect, useRef, useState } from 'react'
import styles from './style/layout.module.less'
import DrawHead from './DrawHead.jsx'
import BorderBtn from '../../components/BorderBtn'
import IconDraw1 from '../../assets/imgs/draw1.svg'
import EmptyMod from './EmptyMod'
import CreationLeftForm from './CreationLeftForm'
import { aiImgGetId, aiImgSubmit, getUserInfo } from '@/model/reference'
import request from '@/utils/request'
import ImageListMod from './ImageListMod'
import { Message } from '@arco-design/web-react'
import { IconErrorTip } from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
let intervalId = null // 用于存储定时器ID

function ImageCreation(props) {
  const [isLogin, setIsLogin] = useState(false)
  //鉴权登录状态
  useEffect(() => {
    if (!isLogin) {
      getUserInfo({ uid: '' }).then((res) => {
        setIsLogin(true)
      })
    }
  }, [])
  useEffect(() => {
    document.title = 'AI 图片创作 - 悟智AI'
  }, [])
  // 从localStorage中获取缓存的模板数据
  const cachedTemplateData = localStorage.getItem('aiImg')
  // 解析JSON数据
  const initialTemplateData = cachedTemplateData
    ? JSON.parse(cachedTemplateData)
    : null
  // 新增一个状态用于缓存模板数据
  const [templateData, setTemplateData] = useState(
    initialTemplateData && initialTemplateData[0].acf,
  )
  //接收子组件的数据
  const [formValues, setFormValues] = useState({})
  //base64图片链接值
  const [base64Data, setBase64Data] = useState(null)
  //list数据
  // 初始化状态并从LocalStorage中获取数据（如果存在）
  // const [creationList, setCreationList] = useState(
  //   JSON.parse(localStorage.getItem('creationList'))?.slice(0, 10) || [],
  // )
  const [creationList, setCreationList] = useState(
    JSON.parse(localStorage.getItem('creationList')) || [],
  )
  //按钮置灰
  const [sendSuccess, setSendSuccess] = useState(true)
  //数据条数list
  const listNumber = useRef(creationList.length)
  //按钮样式置灰
  const [isContent, setIsContent] = useState(true)
  //让scrooltop回到顶部
  const rightRef = useRef(null)
  //骨架屏
  const [skeleton, setSkeleton] = useState(false)
  const [skeletonU1, setSkeletonU1] = useState(false)
  //原生url
  const [baseUrl, setBaseUrl] = useState('')

  //存储原生url
  useEffect(() => {
    events.on(ACTIONS.BASE64URL, handleBaseUrl)

    return () => {
      events.off(ACTIONS.BASE64URL, handleBaseUrl)
    }
  })
  //存储原生url
  function handleBaseUrl(payload) {
    setBaseUrl(payload)
  }

  //生成图片
  function createAiImg(base64Data, formValues) {
    setSendSuccess(false)
    aiImgSubmit({
      base64Array:
        base64Data !== null && base64Data !== undefined && base64Data != ''
          ? [base64Data]
          : undefined,
      notifyHook: '',
      prompt: formValues,
      state: '',
    })
      .then((res) => {
        if (res.data.success == true) {
          if (res.data.data.code == 24) {
            setSendSuccess(true)
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '接口错误或含有敏感词，请调整描述语句后再试。',
            })
          } else {
            setSkeleton(true)
            if (creationList.length > 0) {
              rightRef ? (rightRef.current.scrollTop = 0) : ''
            }
            const resultId = res.data.data.result
            submitFetch(resultId, 1, formValues)
          }
        } else {
          setSendSuccess(true)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: res.data.msg,
          })
        }
      })
      .catch(() => {
        setSendSuccess(true)
      })
  }

  //获取当前时间戳
  function nowDate() {
    const currentDate = new Date()
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getSeconds().toString().padStart(2, '0')

    const formattedTime = `${hours}:${minutes}:${seconds}`
    console.log(`当前时间（格式化）：${formattedTime}`)
    return formattedTime
  }
  //scrooltop拉到顶部且骨架屏出现
  function updateScroolTop() {
    // setSkeleton(true)
    if (creationList.length > 0) {
      rightRef ? (rightRef.current.scrollTop = 0) : ''
    }
  }
  //子组件表单数据回调函数
  function updateFormValues(newFormValues) {
    setFormValues(newFormValues)
  }

  //子组件的base64回调
  function updateImg(value) {
    setBase64Data(value)
  }

  //样式按钮置灰
  function handleDisabledBtn(value) {
    setIsContent(value)
  }
  const [stokenTitle, setStokenTitle] = useState('')
  //查询图片生成进度
  function submitFetch(resultId, type, formValues1) {
    setStokenTitle(formValues1)
    setSendSuccess(false)

    function fetchProgress() {
      request
        .get('/api/api-aidb/submit/fetch', {
          params: { id: resultId },
        })
        .then((res) => {
          if (res.data.data.progress === 'Stopped') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '图片生成错误，请重新换张图片尝试',
            })
            setSendSuccess(true)
            setSkeleton(false)
            setSkeletonU1(false)
            return // 停止递归
          }
          if (res.data.data.status === 'FAILURE') {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '接口错误或含有敏感词，请调整描述语句后再试。',
            })
            setSendSuccess(true)
            setSkeleton(false)
            setSkeletonU1(false)
            return // 停止递归
          }
          if (res.data.success === true) {
            const data = res.data.data

            if (res.data.data.imageUrl != null) {
              setTimeout(() => {
                setSkeleton(false)
                setSkeletonU1(false)
              }, 1000)
              const updatedList = [...creationList]
              const existingItemIndex = updatedList.findIndex(
                (item) => item.id === resultId,
              )

              const currentTime = nowDate()
              const prefix =
                data.progress == '100%' ? '' : 'https://cors-hk.v-dk.com/'
              if (existingItemIndex !== -1) {
                updatedList[existingItemIndex].bigImg = data.imageUrl
              } else {
                updatedList.unshift({
                  link: prefix + data.imageUrl,
                  desc: formValues1,
                  bigImg: prefix + data.imageUrl,
                  downloadImg: prefix + data.imageUrl,
                  baseUrl: baseUrl,
                  type: type,
                  id: resultId,
                  date: currentTime,
                  collect: false,
                  buttons: data.buttons,
                })
              }
              setCreationList(updatedList)

              if (data.progress === '100%') {
                localStorage.setItem(
                  'creationList',
                  JSON.stringify(updatedList),
                )
                setTimeout(() => {
                  rightRef.current.scrollTop = 0
                  setSendSuccess(true)
                }, 2000)
                return // 停止递归
              }
            }

            // 如果请求成功且未达到100%进度，继续下一个请求
            setTimeout(fetchProgress, 2000) // 等待3秒后继续下一个请求
          }
        })
        .catch((error) => {
          console.log(error)
          setSendSuccess(true)
          setSkeleton(false)
          setSkeletonU1(false)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '接口错误，请重新尝试',
          })
        })
    }

    // 开始第一个请求
    fetchProgress()
  }

  //重新生成u1v1
  function handleSimple(value, type, e) {
    console.log(type, 'type')

    if (type == 2) {
      setSkeletonU1(true)
    } else {
      setSkeleton(true)
    }
    submitFetch(value, type, e)
  }

  //删除当前图片tab
  function close(index) {
    let newList = [...creationList]
    newList.splice(index, 1)
    setCreationList(newList)
    localStorage.setItem('creationList', JSON.stringify(newList))
  }

  //清除生成内容
  function handleClear() {
    localStorage.removeItem('creationList')
    setCreationList([])
  }

  //监听页面滚动加载数据
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (rightRef.current) {
  //       const { scrollTop, scrollHeight, clientHeight } = rightRef.current
  //       // 当滚动到底部时触发加载更多数据的操作
  //       if (scrollTop + clientHeight >= scrollHeight) {
  //         loadMoreData()
  //       }
  //     }
  //   }

  //   // 添加滚动事件监听器
  //   rightRef?.current?.addEventListener('scroll', handleScroll)

  //   // 在组件卸载时移除滚动事件监听器
  //   return () => {
  //     rightRef?.current?.removeEventListener('scroll', handleScroll)
  //   }
  // }, [rightRef.current])
  // console.log(creationList)

  // //加载数据计算方法
  // const loadMoreData = () => {
  //   const localStorageData =
  //     JSON.parse(localStorage.getItem('creationList')) || []

  //   // 防止 listNumber.current 超出 localStorage 数据长度
  //   if (listNumber.current >= localStorageData.length) {
  //     console.log('没有更多数据可加载')
  //     return
  //   }

  //   const number = localStorageData.length - listNumber.current
  //   const newDataToLoad = Math.min(number, 10)

  //   if (newDataToLoad > 0) {
  //     const newData = localStorageData.slice(
  //       listNumber.current,
  //       listNumber.current + newDataToLoad,
  //     )

  //     console.log(newData, '加载的数据')
  //     // 过滤掉重复的项目
  //     setCreationList((prevList) => {
  //       const combinedList = [...prevList, ...newData]
  //       const uniqueList = combinedList.filter(
  //         (item, index, self) =>
  //           index === self.findIndex((t) => t.id === item.id),
  //       )
  //       return uniqueList
  //     })

  //     listNumber.current += newDataToLoad
  //   }
  // }

  //更新收藏状态
  function handleUpdateCollect(status, id) {
    const updatedList = creationList.map((item) => {
      if (item.id === id) {
        return { ...item, collect: status }
      }
      return item
    })
    // 更新state
    setCreationList(updatedList)
    localStorage.setItem('creationList', JSON.stringify(updatedList))
  }
  return (
    <>
      {isLogin && (
        <div className={styles.layout}>
          <div className={styles.header}>
            <DrawHead title={templateData.title} onClear={handleClear} />
          </div>
          <div className={styles.contain}>
            <div className={styles.left}>
              <div className={styles.left_contain}>
                <CreationLeftForm
                  templateData={templateData.step}
                  intro={templateData.intro}
                  onUpdateFormValues={updateFormValues}
                  onUpdateImg={updateImg}
                  handleDisabledBtn={handleDisabledBtn}
                />
              </div>
              <div
                className={`${styles['left_bot_btn']} ${
                  !sendSuccess || isContent ? styles['disable'] : ''
                }`}
                onClick={() => {
                  createAiImg(base64Data, formValues)
                }}>
                <BorderBtn
                  height={50}
                  fontSize={14}
                  gradient={true}
                  text={
                    sendSuccess ? (
                      '智能绘图'
                    ) : (
                      <div className={`${styles['loading']}`}>
                        努力加载中
                        <div className={styles['loading-poi']}></div>
                        <div className={styles['loading-poi']}></div>
                        <div className={styles['loading-poi']}></div>
                      </div>
                    )
                  }
                />
              </div>
            </div>
            <div className={styles.right}>
              {creationList.length > 0 ? (
                <div className={styles.list}>
                  <div className={styles.list_inner} ref={rightRef}>
                    <ImageListMod
                      stokenTitle={stokenTitle}
                      imageList={creationList}
                      OnSimpleChange={handleSimple}
                      close={close}
                      sendSuccess={sendSuccess}
                      OnhandleAgain={(value1, value2) => {
                        createAiImg(value1, value2)
                      }}
                      updateScroolTop={updateScroolTop}
                      skeleton={skeleton}
                      skeletonU1={skeletonU1}
                      onUpdateCollect={handleUpdateCollect}
                      typePath={'aiDrawing'}
                      aspect={''}
                    />
                  </div>
                </div>
              ) : (
                <EmptyMod
                  icon={<IconDraw1 />}
                  text={'请在左侧输入您的创意，创作 AI 智能图片'}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ImageCreation
