import request from '@/utils/request'
import { Message } from '@arco-design/web-react'
import React, { useEffect, useRef, useState } from 'react'
import { IconCheckYellow, IconVip } from '@arco-iconbox/react-aidb'
import styles from './style/index.module.less'
import { IconClose } from '@arco-design/web-react/icon'
import { useDispatch } from 'react-redux'
import IP from 'react-public-ip'
import LogoSvg from '@/assets/logo.svg'
import RLLoading from '@/components/Loading/RLLoading'
import { showModal } from '@/share/actions'
import Modal from '@/components/Modal/index'
import IconMonth from '@/assets/newImgs/monthCard.svg'
import IconSeaon from '@/assets/newImgs/seaonCard.svg'
import IconYear from '@/assets/newImgs/yearCard.svg'
import {
  IconErrorTip,
  IconSuccessTip,
  IconWarnTip,
} from '@arco-iconbox/react-aidb-v2'
import wx from 'weixin-js-sdk'
import { useHistory } from 'react-router-dom'

function VipPage() {
  const history = useHistory()
  const ua = navigator.userAgent.toLowerCase()
  const dispatch = useDispatch()
  const [codeImg, setCodeimg] = useState('')
  const [money, setMoney] = useState('')
  const [time, setTime] = useState('月')
  const [timeCard, setTimeCard] = useState('月')
  const [visible, setVisible] = useState('none')
  const [visible2, setVisible2] = useState('none')
  // 定义一个变量存储定时器的ID
  const timerId = useRef(undefined)
  const [vipBtn, setVipBtn] = useState(false)
  const [bgk, setBgk] = useState(1)
  const [vipactive, setVipActive] = useState('pointer')
  const [envir, setEnvir] = useState('pcBrowser')
  const [isByCard, setIsbycard] = useState(true)
  const [ip, setIp] = useState('')
  const [content, setContent] = useState('开通')
  const [isVip, setIsVip] = useState()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // console.log(isOpen,"isOpen")
    const fetchData = async () => {
      setIp((await IP.v4()) || '')
    }
    fetchData()
    getENVIR()
  }, [])

  // 获取个人资料
  useEffect(() => {
    // 显示DOM
    // RLLoading.open({
    //   content: 'Loading',
    // })
    request
      .post('/api/api-permission/employee/getUserInfo', { uid: '' })
      .then((res) => {
        const vipEndDate = new Date(res.data.data.vipEndDate)
        const currentDate = new Date()
        if (currentDate > vipEndDate) {
          setContent('开通')
        } else {
          setContent('续费')
        }
        console.log(res.data.data.isBuyTYCard)
        // setIsbycard(res.data.data.isBuyTYCard)
      })
      .finally(() => {
        // 销毁DOM
        // setTimeout(() => {
        //   RLLoading.close()
        // }, 400)
      })
  }, [])

  // 判断当前环境——>这里是代码入口
  const getENVIR = () => {
    const ua = navigator.userAgent.toLowerCase()
    const isWeixin = ua.indexOf('micromessenger') !== -1
    const isInApp = /(^|;\s)app\//.test(ua)
    let plat = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    )

    if (isWeixin) {
      if (window.__wxjs_environment === 'miniprogram') {
        // 微信小程序
        setEnvir('applet')
      } else {
        // 微信浏览器
        setEnvir('wxBrowser')
      }
    } else {
      if (!isInApp) {
        // browser
        if (plat) {
          // 移动端浏览器
          setEnvir('phoneBrowser')
        } else {
          // PC端浏览器
          setEnvir('pcBrowser')
        }
      } else {
        // app
        setEnvir('app')
      }
    }
  }

  // 获取所有的cookie
  const getAllCookie = () => {
    const allstr = document.cookie

    return allstr
  }
  //数据埋点支付触发
  function pointCheck(payId) {
    if (
      localStorage.getItem('bd_vid_by') !== '' &&
      localStorage.getItem('bd_vid_by') !== null
    ) {
      request
        .post(
          'https://cors.v-dk.com/https://ocpc.baidu.com/ocpcapi/api/uploadConvertData',
          {
            token:
              'fNTSXZtws4Z5YMf1yRKPEKW7CXPeclST@CzAQEdOtUQsyzOdGDSmu3ibwOr9N9xIu',
            conversionTypes: [
              {
                logidUrl: `https://www.wuz.com.cn/?bd_vid=${localStorage.getItem(
                  'bd_vid_by',
                )}`,
                newType: 10,
              },
            ],
          },
        )
        .then((res) => {
          // 登录成功
          if (res.data.header.desc == 'success') {
            //清除token
            localStorage.removeItem('bd_vid_by')
            setTimeout(() => {
              history.push('/userCenter?activeTab=order')
            }, 1000)
          }
        })
    } else if (
      localStorage.getItem('wwadsCode_by') !== '' &&
      localStorage.getItem('wwadsCode_by') !== null
    ) {
      request
        .post('https://cors.v-dk.com/https://portal.wuz.com.cn/wwads/report/', {
          clickCode: localStorage.getItem('wwadsCode_by'),
          convertId: payId,
        })
        .then((res) => {
          if (res.data.message == 'Success') {
            localStorage.removeItem('wwadsCode_by')
            setTimeout(() => {
              history.push('/userCenter?activeTab=order')
            }, 1000)
          }
        })
    } else {
      setTimeout(() => {
        history.push('/userCenter?activeTab=order')
      }, 1000)
    }
  }
  // 开通会员
  function joinVip(e) {
    setBgk(0.3)
    setVipActive('auto')
    setVipBtn(true)
    // 根据不同会员卡类型，设置不同的价格
    if (e == 1) {
      setMoney(25)
      setTime('月')
      setTimeCard('月卡')
    } else if (e == 2) {
      setMoney(60)
      setTime('季度')
      setTimeCard('季卡')
    } else if (e == 3) {
      setTime('年')
      setMoney(168)
      setTimeCard('年卡')
    } else if (e == 4) {
      setMoney(9.9)
      setTime('月')
      setTimeCard('月卡')
    }

    // 创建订单
    if (envir == 'pcBrowser') {
      //  pc端
      request
        .post('/api/api-aidb/apply/createApply', { formId: e })
        .then((res) => {
          if (res.data.code == '-600610') {
            Message.warning({
              icon: <IconWarnTip useCurrentColor={false} />,
              content: res.data.msg,
            })
            setTimeout(() => {
              history.push('/userCenter?activeTab=order')
            }, 1000)
          }
          // 订单号
          let orderNo = res.data.data.orderNo
          // 获取二维码
          request
            .post('/api/api-aidb/pay/createQRCode', { orderNo })
            .then((res) => {
              setCodeimg(res.data.data)
              setVisible('block')
              // 判断支付是否成功，每隔五秒发送一次请求
              function sendRequest() {
                request
                  .post('/api/api-aidb/pay/queryPollOrder', {
                    withCredentials: true,
                    orderNo: orderNo,
                  })
                  .then((res) => {
                    console.log(res.data.data.orderNo)
                    const payId = 'payId' + res.data.data.orderNo
                    if (res.data.data.orderStatus == 3) {
                      Message.success({
                        icon: <IconSuccessTip useCurrentColor={false} />,
                        content: '支付成功！',
                      })
                      localStorage.setItem('isVip', true)
                      pointCheck(payId)
                    } else if (res.data.data.orderStatus == 4) {
                      Message.error({
                        icon: <IconErrorTip useCurrentColor={false} />,
                        content: '支付失败',
                      })
                      setTimeout(() => {
                        history.push('/userCenter?activeTab=order')
                      }, 1500)
                    }
                  })
                  .catch((error) => {
                    error.response
                      ? console.log(error.response.data)
                      : Message.error({
                          icon: <IconErrorTip useCurrentColor={false} />,
                          content: '网络错误,请刷新页面重试',
                        }) && clearInterval(timerId.current)
                  })
              }
              // 使用setInterval函数启动定时器，并在指定的时间间隔内调用sendRequest函数
              timerId.current = setInterval(sendRequest, 2000)
              console.log(timerId)
            })
        })
        .catch((err) => {
          console.log(err)
          // 已改为 request 底层统一接管，之后所有 424 等不在单独处理
          // if (error.response) {
          //   console.log(error.response.data.msg);
          //   if (error.response.data.status !== 200) {
          //     Message.error(error.response.data.msg);
          //   }
          // }
        })
        .finally(() => {
          setTimeout(() => {
            setVipBtn(false)
            setBgk(1)
            setVipActive('pointer')
          }, 1000)
        })
    } else {
      //  移动端
      if (envir == 'phoneBrowser') {
        // 如果当前为移动端浏览器
        request
          .post('/api/api-aidb/apply/createApply', { formId: e })
          .then((res) => {
            if (res.data.code == '-600610') {
              Message.warning({
                icon: <IconWarnTip useCurrentColor={false} />,
                content: res.data.msg,
              })
              setTimeout(() => {
                history.push('/userCenter?activeTab=order')
              }, 1000)
            } else {
              // 清除prepayId和订单号
              localStorage.removeItem('prepayId')
              localStorage.removeItem('orderNo')
              // 将订单号存储到本地
              localStorage.setItem(
                'orderNo',
                JSON.stringify(res.data.data.orderNo),
              )
              request
                .post('/api/api-aidb/pay/createMWebUrl', {
                  ip,
                  orderNo: res.data.data.orderNo,
                })
                .then((res) => {
                  console.log(res)
                  if (res.data.code == '500200') {
                    setTimeout(() => {
                      // 跳转到微信支付页面
                      window.location.replace(
                        res.data.data +
                          '&redirect_url=' +
                          encodeURIComponent(
                            'https://portal.wuz.com.cn/pay/HFivePay',
                          ),
                      )
                    }, 200)
                  } else {
                    Message.error({
                      icon: <IconErrorTip useCurrentColor={false} />,
                      content: '请求失败！',
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
        return
      } else if (envir == 'app') {
        // 如果当前为app，弹出提示
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '暂未开放，请使用微信浏览器或小程序或PC端支付！',
        })
        return
      } else if (envir == 'wxBrowser') {
        // 如果当前为微信浏览器
        request
          .post('/api/api-aidb/apply/createApply', { formId: e })
          .then((res) => {
            if (res.data.code == '-600610') {
              Message.warning({
                icon: <IconWarnTip useCurrentColor={false} />,
                content: res.data.msg,
              })
              setTimeout(() => {
                history.push('/userCenter?activeTab=order')
              }, 1000)
            } else {
              // 清除prepayId和订单号
              localStorage.removeItem('prepayId')
              localStorage.removeItem('orderNo')
              // 将订单号存储到本地
              localStorage.setItem(
                'orderNo',
                JSON.stringify(res.data.data.orderNo),
              )
              setTimeout(() => {
                // 跳转到微信支付页面
                window.location.href =
                  'https://portal.wuz.com.cn/api/api-aidb/pay/wxLogin'
              }, 200)
            }
          })
        return
      } else if (envir == 'applet') {
        // 如果当前为微信小程序
        request
          .post('/api/api-aidb/apply/createApply', { formId: e })
          .then((res) => {
            if (res.data.code == '-600610') {
              Message.warning({
                icon: <IconWarnTip useCurrentColor={false} />,
                content: res.data.msg,
              })
              setTimeout(() => {
                history.push('/userCenter?activeTab=order')
              }, 1000)
            } else {
              // 清除prepayId和订单号
              localStorage.removeItem('prepayId')
              localStorage.removeItem('orderNo')
              // 将订单号存储到本地
              localStorage.setItem(
                'orderNo',
                JSON.stringify(res.data.data.orderNo),
              )

              setTimeout(() => {
                //  对应小程序项目里建立的wePay.js的路径
                const url = `../wePay/wePay?cookie=${encodeURIComponent(
                  getAllCookie(),
                )}&orderNo=${encodeURIComponent(
                  JSON.parse(localStorage.getItem('orderNo')),
                )}`

                // 跳转到微信小程序页面，然后在小程序内完成支付代码操作
                wx.miniProgram.navigateTo({
                  url,
                  success: function (res) {
                    // alert('成功！')
                    //  alert('跳转成功')
                  },
                  fail: function (err) {
                    // alert('跳转失败')
                    // alert('失败！' + err)
                  },
                })
              }, 200)
            }
          })
        return
      }
    }
  }

  const ok = () => {
    console.log(ok, 'ok')
    setIsOpen(false)
  }

  const noOk = (value) => {
    setIsOpen(false)
  }

  // 控制弹窗
  function modalClose() {
    clearInterval(timerId.current) //.current
    setVisible('none')
    setVipBtn(false)
    setBgk(1)
    setVipActive('pointer')
    Message.warning({
      icon: <IconWarnTip useCurrentColor={false} />,
      content: '支付已取消',
    })
  }
  function setClose() {
    history.push('/userCenter?activeTab=order')
  }

  return (
    <div className={styles['vip-page']}>
      <div className={styles.iconClose} onClick={setClose}>
        <IconClose style={{ color: '#fff' }}></IconClose>
      </div>

      <div className={styles['vip-page-top']}>
        <div className={styles['image-wrapper_1 flex-row']}>
          <LogoSvg
            className={styles['img_1']}
            onClick={() => {
              history.push('/home')
            }}
          />
          {/* <img
            className={styles['img_1']}
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8b9c95e98b6bd00eeaf42e49b968b8f56f0eb69e26a422aeb0a5be8f4c7e2503"
          /> */}
        </div>
        <div className={styles['box_1']}>
          <span className={styles['box1_text']}>易开即用的智能写作平台</span>
        </div>
        <div className={styles['box_2']}>
          <span className={styles['box2_text']}>{content}会员</span>
        </div>
        <div className={styles['content']}>
          <div className={styles['table']}>
            <div className={`${styles['item']} ${styles['tit']}`}>
              <div className={`${styles['col']} ${styles['col1']}`}></div>
              <div className={`${styles['col']} ${styles['col2']}`}>
                免费用户
              </div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                <IconVip />
                会员用户
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                智能助理
              </div>
              <div className={`${styles['col']} ${styles['col2']}`}>3次/分</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                12次/分
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                智能助理
              </div>
              <div className={`${styles['col']} ${styles['col2']}`}>3次/日</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                360次/日
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>校对</div>
              <div className={`${styles['col']} ${styles['col2']}`}>6次/天</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                999次/天
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>改写</div>
              <div className={`${styles['col']} ${styles['col2']}`}>6次/天</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                999次/天
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>续写</div>
              <div className={`${styles['col']} ${styles['col2']}`}>6次/天</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                999次/天
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>翻译</div>
              <div className={`${styles['col']} ${styles['col2']}`}>6次/天</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                999次/天
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>AI生成</div>
              <div className={`${styles['col']} ${styles['col2']}`}>6次/天</div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                {/* <IconCheckYellow style={{ fontSize: 24 }} /> */}
                100次/天
              </div>
            </div>
            {/* <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                文档分享
              </div>
              <div
                className={`${styles['col']} ${styles['col2']} ${styles['empty']}`}
              ></div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                <IconCheckYellow style={{ fontSize: 24 }} />
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                文章编辑器
              </div>
              <div
                className={`${styles['col']} ${styles['col2']} ${styles['empty']}`}
              ></div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                <IconCheckYellow style={{ fontSize: 24 }} />
              </div>
            </div> */}
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                内测新功能
              </div>
              <div
                className={`${styles['col']} ${styles['col2']} ${styles['empty']}`}></div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                <IconCheckYellow style={{ fontSize: 24 }} />
              </div>
            </div>
            <div className={styles['item']}>
              <div className={`${styles['col']} ${styles['col1']}`}>
                在线客服支持
              </div>
              <div
                className={`${styles['col']} ${styles['col2']} ${styles['empty']}`}></div>
              <div className={`${styles['col']} ${styles['col3']}`}>
                <IconCheckYellow style={{ fontSize: 24 }} />
              </div>
            </div>
          </div>
          <div className={styles['vip-type']}>
            <div className={`${styles['item']} ${styles['color1']}`}>
              <h3>月卡账户</h3>
              <div className={styles['pic']}>
                {/* <img src={require('../../assets/imgs/month.png')} alt=""></img> */}
                <IconMonth></IconMonth>
              </div>
              {!isByCard ? (
                <div>
                  <div>
                    <h4 className={styles['pic-text']}>￥25/月</h4>
                    <p className={styles['monthP1']}>新人体验价￥9.9/月</p>
                  </div>
                  <div
                    className={`${styles['g-btn']} ${styles['month-card']}`}
                    style={{ cursor: vipactive, opacity: bgk }}
                    disabled={vipBtn}
                    onClick={() => joinVip('4')}>
                    开通月卡
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <h4>￥25/月</h4>
                    <p className={styles['monthP']}>¥29/月</p>
                  </div>
                  <div
                    className={`${styles['g-btn']} ${styles['month-card1']}`}
                    style={{ cursor: vipactive, opacity: bgk }}
                    disabled={vipBtn}
                    onClick={() => joinVip('1')}>
                    {content}月卡
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles['item']} ${styles['color2']}`}>
              <div className={styles['tag']}>
                {' '}
                推<br />荐<br /> 购<br /> 买
              </div>
              <h3>季卡账户</h3>
              <div className={styles['pic']}>
                {/* <img src={require('../../assets/imgs/season.png')} alt=""></img> */}
                <IconSeaon></IconSeaon>
              </div>
              <h4>￥60/季</h4>
              <p className={styles['monthP']}>￥99/季</p>
              <div
                className={styles['g-btn']}
                style={{ cursor: vipactive, opacity: bgk }}
                disabled={vipBtn}
                onClick={() => joinVip('2')}>
                {content}季卡
              </div>
            </div>
            <div className={`${styles['item']} ${styles['color3']}`}>
              <h3>年卡账户</h3>
              <div className={styles['pic']}>
                {/* <img src={require('../../assets/imgs/year.png')} alt=""></img> */}
                <IconYear></IconYear>
              </div>
              <h4>￥168/年</h4>
              <p className={styles['monthP']}>￥360/年</p>
              <div
                className={styles['g-btn']}
                style={{ cursor: vipactive, opacity: bgk }}
                disabled={vipBtn}
                onClick={() => joinVip('3')}>
                {content}年卡
              </div>
            </div>
          </div>
        </div>
        {/* 支付弹窗 */}
        <div className={styles.modalBox} style={{ display: visible }}>
          <div className={styles.vipBox}>
            <div className={styles.iconClose} onClick={modalClose}>
              <IconClose style={{ color: '#fff' }}></IconClose>
            </div>
            <div className={styles.price_wrap}>
              <span className={styles.desc_txt}>
                {content}
                {timeCard}会员:
              </span>
              <span className={styles.price3}>￥{money}</span>
            </div>
            <div className={styles.price_wrap1}>
              <span className={styles.price4}>
                {/* 每{time}向您收取¥{money}
                订阅费用，直至您取消订阅。 */}
                如需了解详情，请前往支持中心。
              </span>
            </div>
            <img src={codeImg} alt="" className={styles.codeImg} />
            <div>
              <img
                style={{ marginBottom: '2px' }}
                src="//img11.360buyimg.com/img/jfs/t1/141526/16/30623/685/63f44cfdFacdfd575/c922dc0e8c413386.png"
                className="icon4"></img>
              <span className={styles.instruction}>微信支付</span>
            </div>
          </div>
        </div>
      </div>
      <Modal></Modal>
      <div className={styles['link']}>
        <a
          target="_blank"
          without
          rel="noreferrer"
          onClick={() => {
            // setIsOpen(true)
            // console.log("111111")
            showModal('PayMantModal', {
              title: '卡密支付',
            })
          }}
          // className={`${styles['g-btn']} ${styles['month-card']}`}
          //         style={{ cursor: vipactive, opacity: bgk }}
          //         disabled={vipBtn}
          //         onClick={() => joinVip('4')}
        >
          卡密支付
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          target="_blank"
          without
          rel="noreferrer"
          href="https://go.wuz.com.cn/4124">
          使用限制
        </a>
        &nbsp;&nbsp;&nbsp;
        <a without rel="noreferrer" href="/userCenter?activeTab=order">
          购买记录
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          without
          target="_blank"
          rel="noreferrer"
          href="https://support.wuz.com.cn/form/?type=%E5%95%86%E5%8A%A1%E5%90%88%E4%BD%9C%E5%92%A8%E8%AF%A2&content=%E6%94%BF%E4%BC%81%E5%AE%A2%E6%88%B7%E8%B4%AD%E4%B9%B0%E5%92%A8%E8%AF%A2%EF%BC%9A">
          政企采购咨询
        </a>
      </div>
      {/* // <PayMantModal flag={isOpen} off={ok} submit={noOk}></PayMantModal> */}
    </div>
  )
}

export default VipPage
