import React from 'react'
import { useState, useEffect } from 'react'
import styles from './style/tags.module.less'
import { IconDoubleArrowDown, IconClosure } from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'

function Tags(props) {
  const [tags, setTags] = useState(props.tags)
  const [toggle, setToggle] = useState(tags.length > 2)
  const [showAll, setShowAll] = useState(false)
  const theme = useSelector((state: GlobalState) => state.theme)
  useEffect(() => {
    setTags(props.tags) // 每次props.tags变化时更新tags的值
    if (props.tags.length > 2) {
      setToggle(true)
    } else {
      setToggle(false)
      setShowAll(false)
    }
  }, [props.tags])

  function handleFilter(item) {
    events.emit(ACTIONS.DOC_CHECK_TAG, {
      uuid: item.uuid,
      tagName: item.tagName,
    })
  }

  return (
    <div
      className={`${styles['tags']} ${styles[theme]} ${
        showAll ? styles['fullHeight'] : ''
      } ${styles[props.type]} `}>
      {/*最多显示2个，超过的折叠*/}
      {tags.map((item, index) => {
        if (index <= 1) {
          return (
            <div
              className={styles['item']}
              key={index}
              onClick={() => handleFilter(item)}>
              {/* {item.tagName} */}
              {item.tagName.length > 5
                ? item.tagName.slice(0, 4) + '...'
                : item.tagName}
            </div>
          )
        }
      })}
      {/*折叠箭头 和 全部标签的弹窗*/}
      {toggle ? (
        <>
          {/*折叠箭头*/}
          <div
            className={`${styles['item']} ${styles['arrow']}`}
            onClick={() => {
              setShowAll(!showAll)
            }}>
            <IconDoubleArrowDown fontSize={12} useCurrentColor={true} />
          </div>
          {/*是否显示弹窗*/}
          {showAll ? (
            <div className={styles['allTags_wrap']}>
              <div
                className={styles['close']}
                onClick={() => {
                  setShowAll(!showAll)
                }}>
                <IconClosure useCurrentColor={true} />
              </div>
              <div
                className={`${styles[props.folderColor]} ${styles['allTags']}`}>
                {tags.map((item, index) => {
                  return (
                    <div
                      className={styles['item']}
                      key={index}
                      onClick={() => handleFilter(item)}>
                      {item.tagName}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Tags
