import React, { useEffect } from 'react'
import style from './style/proofOfficialItem.module.less'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { Transition } from 'react-transition-group'
import {
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
} from '../Animate/toggle'
import { IconRollRight } from '@arco-iconbox/react-aidb-v2'
interface Props {
  id?: number
  item?: any
  expand?: boolean
  proofOfficialType: number
  onExpand?: (id?: number) => void
  boldColor: string
  color: string
  content?: string
  isLoading: boolean
}

export const ProofOfficialItem = (props: Props) => {
  const {
    expand,
    onExpand,
    id,
    item,
    proofOfficialType,
    boldColor,
    isLoading,
  } = props
  const {
    action,
    explanation,
    word,
    type,
    context,
    content,
    suggest,
    position,
    nodeIndex,
    highlightText,
    contentComplete,
    length: errorLength,
  } = item
  useEffect(() => {
    if (expand) {
      events.emit(ACTIONS.ADD_EDITOR_TIP_PROOF_OFFICIAL, {
        error: word,
        errorLength: errorLength,
        nodeIndex: nodeIndex,
        position: position,
        contentComplete: contentComplete,
        content: content,
        proofOfficialType: proofOfficialType,
      })
    }
  }, [expand])
  const expandHandle = (id) => {
    onExpand(id)
  }
  return (
    <div>
      <div
        className={` ${style.suggest_item}`}
        style={{ cursor: isLoading ? 'default' : 'pointer' }}
        onClick={() => {
          !isLoading && expandHandle(id)
        }}>
        {/* <div
          className={style.close}
          onClick={(e) => deleteHandle(e, item.vecFragmentList[0].label, id)}>
          <IconClosure />
        </div> */}
        {!expand && (
          <div className={style.suggest_expand}>
            <p
              className={style.content}
              dangerouslySetInnerHTML={{ __html: highlightText }}></p>
            <span className={style.separate}></span>
            <p className={style.type}>{type.desc}</p>
          </div>
        )}

        <Transition
          in={expand}
          timeout={300}
          unmountOnExit
          onEnter={onEnter}
          onEntering={onEntering}
          onEntered={onEntered}
          onExit={onExit}
          onExiting={onExiting}
          onExited={onExited}>
          <div className={style.suggest_content}>
            <div className={style.suggest_top}>
              <p className={style.type}>{type.desc}</p>
              <p
                className={style.content}
                dangerouslySetInnerHTML={{ __html: highlightText }}></p>
            </div>
            {action.id == 1 && (
              <div className={style.suggest_bottom}>
                <p className={style.explanation}>{explanation}</p>
              </div>
            )}
            {action.id == 2 && (
              <div className={style.suggest_bottom}>
                <p className={style.replaceErr}>{word}</p>
                <div className={style.suggestBox}>
                  <IconRollRight />

                  <span className={style.suggest} style={{ color: boldColor }}>
                    {suggest[0]}
                  </span>
                </div>
              </div>
            )}
            {action.id == 4 && (
              <div className={style.suggest_bottom}>
                <p>{word}</p>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </div>
  )
}
