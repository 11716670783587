import React, { useEffect, useState } from 'react'
import styles from './style/prompt-image.module.less'
import PromptTitle from './PromptTitle'
import { Input, Message, Space } from '@arco-design/web-react'
import { Slider } from '@arco-design/web-react'
import {
  IconUpload,
  IconLink,
  IconWastePaper,
  IconBaseSync,
  IconSuccessTip,
  IconErrorTip,
} from '@arco-iconbox/react-aidb-v2'
import { Upload } from '@arco-design/web-react'
import PromptWeight from './PromptWeight'
import BorderBtn from '../../../components/BorderBtn'
import PromptInput from './PromptInput'
import { IconLoading } from '@arco-design/web-react/icon'
import request from '@/utils/request'
import { ACTIONS } from '@/share/constants'
import { events } from '@/helpers/event-emitter'

const TextArea = Input.TextArea

function PromptImage(props) {
  const {
    max,
    min,
    step,
    defaultValue,
    sliderValue,
    onSliderChange,
    onUpdateImg,
    delImg,
    hideWeight,
    index,
    delBaseUrl,
    typePath,
  } = props
  //图片权重
  const [value, setValue] = useState(50)
  //上传图片image 图片链接link
  const [type, setType] = useState('')
  //上传图片的文件
  const [file, setFile] = useState<any>()
  //是否显示图片预览框
  const [showImg, setShowImg] = useState(false)
  //是否显示图片链接输入框
  const [isShowLink, setIsShowLink] = useState(false)
  //base64图片存储地址
  const [inputValue, setInputValue] = useState(null)
  const [onblurType, setOnblurType] = useState(true)

  //添加
  function plus() {
    if (props.plus) {
      props.plus()
    }
  }

  //删除
  function minus() {
    if (props.minus) {
      props.minus()
    }
  }
  //传递原生url
  useEffect(() => {
    if (typePath == 'Synthesis') {
      events.emit(ACTIONS.BASE64URL, { file, index })
    } else {
      events.emit(ACTIONS.BASE64URL, file)
    }
  }, [file])

  //上传图片
  function handleUpload(info) {
    const { file } = info
    new Promise((resolve) => {
      const formData = new FormData()
      formData.append('file', file)
      request
        .post('/api/api-aidb/upload/uploadFile', formData)
        .then((res) => {
          setType('image')
          setFile(res.data.data.url)
          fetchImage(res.data.data.url)
        })
        .catch((error) => {
          console.log(error)
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '上传图片失败，请重新尝试',
          })
        })
    })
  }

  //转换base64
  async function fetchImage(imageUrl) {
    try {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const reader = new FileReader()

      reader.onload = () => {
        const base64Data = reader.result
        props.onBase64ImageChange(base64Data)

        setShowImg(true)
        // setIsShowLink(false)
        // setType('image')
        // setFile(base64Data)
        Message.success({
          icon: <IconSuccessTip useCurrentColor={false} />,
          content: '上传图片成功',
        })
      }
      reader.readAsDataURL(blob)
    } catch (error) {
      console.error('Error fetching or converting image:', error)
    }
  }

  //检查输入框内的链接是否真实存在
  async function checkAndFetchImage(imageUrl) {
    const checkImageExists = (url) =>
      new Promise((resolve) => {
        const img = new Image()
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
        img.src = url
      })

    const imageExists = await checkImageExists(imageUrl)

    if (imageExists) {
      setIsShowLink(false)
      setType('image')
      setFile(inputValue)
      fetchImage(imageUrl) // 图片存在，调用 fetchImage 方法
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请输入真实有效的图片链接',
      })
    }
  }

  //重置数据清空图片
  useEffect(() => {
    events.on(ACTIONS.RESET_AIIMG_BASE64, handleRestImg)

    return () => {
      events.off(ACTIONS.RESET_AIIMG_BASE64, handleRestImg)
    }
  })
  //删除图片
  function handleRestImg() {
    if (hideWeight) {
      delImg(index)
      delBaseUrl(file, index)
    } else {
      onUpdateImg('')
      if (!props.hideClear) {
        onSliderChange('')
      }
    }
    setFile('')
    setType('')
    setIsShowLink(false)
    setInputValue('')
  }

  return (
    <div>
      <PromptTitle
        plus={() => {
          plus()
        }}
        minus={() => {
          minus()
        }}
        title={props.title}
        tooltip={props.tooltip}
        showPlus={props.showPlus}
        showMinus={props.showMinus}
      />
      <div className={styles.btn_wrap}>
        {/* 上传图片按钮 */}
        <div
          className={`${styles.btn} ${type === 'link' ? styles.disable : ''}`}>
          <div className={styles.icon}>
            <IconUpload />
          </div>
          <div className={styles.text}>上传图片</div>
          <div className={styles.upload_wrap}>
            <Upload
              fileList={file ? [file] : []}
              showUploadList={false}
              accept="image/png, image/jpeg"
              customRequest={handleUpload}
            />
          </div>
        </div>
        {/* 图片链接按钮 */}
        <div
          className={`${styles.btn} ${type === 'image' ? styles.disable : ''}`}
          onClick={() => {
            setIsShowLink(true)
            setType('link')
            setOnblurType(true)
          }}>
          <div className={styles.icon}>
            <IconLink />
          </div>
          <div className={styles.text}>图片链接</div>
        </div>
      </div>
      {/*图片编辑，上传，权重内容*/}
      {file && (
        <div className={styles.img_wrap}>
          <div className={styles.img_inner}>
            {!showImg && (
              <div className={styles.loading}>
                <IconLoading />
              </div>
            )}
            {showImg && (
              <>
                <img src={file ? file : ''} alt="" />
                <div className={styles.img_btn_wrap}>
                  <div className={styles.img_btn} onClick={handleRestImg}>
                    <IconWastePaper />
                  </div>
                  <div className={styles.img_btn}>
                    <IconBaseSync />
                    <div className={styles.upload_wrap}>
                      <Upload
                        fileList={file ? [file] : []}
                        showUploadList={false}
                        accept="image/png, image/jpeg"
                        customRequest={handleUpload}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* 权重图片 */}
          {!props.hideWeight && !props.hideClear && (
            <div className={styles.weight}>
              <PromptWeight
                min={min} // 最小值
                max={max} // 最大值
                step={step} // 最小调整单元
                defaultValue={defaultValue} // 默认值
                title={'图片构图权重'}
                tooltip={'数值越高,图片权重越高(范围0.5-2)'}
                value={sliderValue}
                onSliderChange={onSliderChange}
                showPlus={false}
                showMinus={false}
              />
            </div>
          )}
        </div>
      )}
      {/* {file && props.hideWeight && (
        <div className={styles.img_wrap}>
          <div className={styles.img_inner}>
            {!showImg && (
              <div className={styles.loading}>
                <IconLoading />
              </div>
            )}
            {showImg && (
              <>
                <img src={file ? file.url : ''} alt="" />
                <div className={styles.img_btn_wrap}>
                  <div
                    className={styles.img_btn}
                    onClick={() => {
                      setFile('')
                      setType('')
                    }}>
                    <IconWastePaper />
                  </div>
                  <div className={styles.img_btn}>
                    <IconBaseSync />
                    <div className={styles.upload_wrap}>
                      <Upload
                        action="/api/api-aidb/upload/uploadFile"
                        customRequest={handleFileUpload}
                        fileList={file ? [file] : []}
                        showUploadList={false}
                        accept={'image/*'}
                        onChange={(_, currentFile) => {
                          setShowImg(false)
                          setFile({
                            ...currentFile,
                            url: URL.createObjectURL(currentFile.originFile),
                          })
                          setTimeout(() => {
                            setShowImg(true)
                          }, 500)
                          setType('image')
                        }}
                        onProgress={(currentFile) => {
                          setShowImg(false)
                          setFile(currentFile)
                          setTimeout(() => {
                            setShowImg(true)
                          }, 500)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )} */}

      {/*图片链接输入内容*/}
      {isShowLink && (
        <div className={styles.input_link}>
          <PromptInput
            title={'图片链接'}
            showDel={true}
            tooltip={'回车键确认或鼠标离开输入框点击别处'}
            min={min} // 最小值
            max={max} // 最大值
            step={step} // 最小调整单元
            defaultValue={defaultValue} // 默认值
            del={handleRestImg}
            onInputChange={(value) => {
              setInputValue(value)
            }}
            onblur={() => {
              checkAndFetchImage(inputValue)
            }}
            imgtype={onblurType}
            value={inputValue}
          />
        </div>
      )}
    </div>
  )
}

export default PromptImage
