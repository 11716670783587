import React, { useState, useEffect } from 'react'
import { Button, Switch } from '@arco-design/web-react'
import { IconPlus } from '@arco-iconbox/react-aidb-v2'
import { GetQueryValue, removeEmpty } from '@/utils/common'
import { showModal } from '@/share/actions'

import styles from '../style/addition.module.less'

const Addition = (props) => {
  const { isDisable, employeePermission } = props

  // 打开协同管理
  const handleAddPeople = () => {
    showModal('AddTeamModal', {
      uuid: GetQueryValue('uuid'),
      fileType: 0,
      innerType: employeePermission == 1 ? false : true,
      currentPermission: employeePermission,
    })
  }

  return (
    <div className={styles['addition-outContent']}>
      <Button
        className={styles.addButton}
        status="warning"
        disabled={isDisable}
        icon={<IconPlus />}
        onClick={handleAddPeople}>
        管理
      </Button>
    </div>
  )
}
export default Addition
