import { CollectType, ContentType, PageType } from '@/helpers/enums'
import { events } from '@/helpers/event-emitter'
import {
  getChatCollectList,
  getCollectList,
  getDocumentDetail,
  getDocumentList,
  getSearchCollect,
} from '@/model/reference'
import WritingContent from '@/pages/writingContent'
import { ACTIONS } from '@/share/constants'
import { GetQueryValue, nonEmptyArray, removeEmpty } from '@/utils/common'
import React, { useEffect, useRef, useState } from 'react'
import DocCardItem from '../DocCardItem'
import DocTextItem from '../DocTextItem'
import EmptyView from '@/components/Empty'
import styles from './style/collectMod.module.less'
import { useHistory } from 'react-router-dom'
import BreadCollectItem from './collectBread'
import { showModal } from '@/share/actions'
import { useSelector } from 'react-redux'
import { GlobalState } from '@/store'
interface Props {
  searchInput: string
  collectType: number
  onCollectEditHandle: (uuid: string, type: number | string) => void
}

const CollectMod = (props: Props) => {
  const {
    // collectType,
    searchInput,
    onCollectEditHandle,
  } = props
  // const [collectType, setCollectType] = useState(
  //   Number(GetQueryValue('collectType')),
  // )
  const collectType = Number(GetQueryValue('collectType')) || CollectType.doc
  const [collectList, setCollectList] = useState<any>('')
  const collectRef = useRef<any>([])
  const [docTitle, setDocTitle] = useState<string>('')
  const [docContent, setDocContent] = useState<string>('')
  const [outCollectList, setOutCollectList] = useState<boolean>(false)
  const [searchList, setSearchList] = useState([])
  const timer = useRef<NodeJS.Timeout | null>(null)
  const history = useHistory()
  const theme = useSelector((state: GlobalState) => state.theme)
  useEffect(() => {
    console.log(collectType, 'collectType')
    setCollectList([])
    if (collectType == CollectType.assistant) {
      getChatCollect()
      const urlQuery = removeEmpty({
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        listMode: GetQueryValue('listMode') ? GetQueryValue('listMode') : '',
        uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        collectType: GetQueryValue('collectType')
          ? GetQueryValue('collectType')
          : '',
        title: GetQueryValue('title') ? GetQueryValue('title') : '',
        from: GetQueryValue('from') ? GetQueryValue('from') : '',
      }) as any
      history.replace({
        pathname: '/documentEdit',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
    } else if (collectType == CollectType.doc) {
      getCollect([])
    }
  }, [collectType])

  useEffect(() => {
    events.on(ACTIONS.COLLECT_EDIT_RETURN, collectEditReturn)
    events.on(ACTIONS.CANCEL_COLLECT, deleteDocHandle)
    return () => {
      events.off(ACTIONS.CANCEL_COLLECT, deleteDocHandle)
      events.off(ACTIONS.COLLECT_EDIT_RETURN, collectEditReturn)
    }
  }, [])

  useEffect(() => {
    if (GetQueryValue('collectFoldUuid') && collectType == CollectType.doc) {
      getDocumentListHandle(GetQueryValue('collectFoldUuid'))
    } else if (collectType == CollectType.doc) {
      getCollect([])
    }
  }, [GetQueryValue('collectFoldUuid')])

  useEffect(() => {
    searchHandle(searchInput)
  }, [searchInput])

  useEffect(() => {
    collectRef.current = collectList
    setCollectList(collectList)
    console.log(collectRef.current, 'collectRef.current ')
  }, [collectList, collectType])

  useEffect(() => {
    getDocDetailHandle(GetQueryValue('collectDocUuid'))
  }, [GetQueryValue('collectDocUuid')])

  const searchHandle = (searchInput) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      if (searchInput === '' || searchInput === null) {
        return false
      } else {
        if (collectType == CollectType.doc) {
          getSearchCollect({ searchKey: searchInput }).then((res) => {
            const newSearchData = res.data.data.records.map((_) => {
              return {
                ..._,
                outCollectList: true,
              }
            })
            setSearchList(newSearchData)
          })
        } else {
          getChatCollectList({
            pageIndex: 0,
            pageSize: 100,
            searchKey: searchInput,
          }).then((res) => {
            let chatSearchList
            chatSearchList = res.data.data.map((_, i) => {
              return {
                collectDate: _.currentTime,
                content: _.output,
                fileType: ContentType.assistant,
                title: _.input,
                uuid: _.messageId,
                outCollectList: true,
              }
            })
            setSearchList(chatSearchList)
          })
        }
      }
    }, 500)
  }

  const deleteDocHandle = (payload) => {
    const newdocList = collectRef.current.filter(
      (_) => !payload.id.includes(_.uuid),
    )
    setCollectList(newdocList)
  }

  const collectEditReturn = (payload) => {
    getDocumentListHandle(payload.collectFoldIdPrev)
  }
  //获取列表
  const getCollect = (tagUuid) => {
    getCollectList({
      orderBy: '',
      uuids: tagUuid,
    }).then((res) => {
      const newCollectData = res.data.data.records.map((_) => {
        return {
          ..._,
          outCollectList: true,
        }
      })
      collectRef.current = newCollectData
      setCollectList(newCollectData)
    })
  }

  const getChatCollect = async () => {
    console.log(getChatCollect, 'getChatCollect')
    const res = await getChatCollectList({
      pageIndex: 0,
      pageSize: 100,
      searchKey: '',
    })
    if (res.data.success) {
      let chatCollectList = []
      chatCollectList = res.data.data.map((_, i) => {
        return {
          collectDate: _.currentTime,
          fileType: 2,
          id: _.employeeId,
          introduction: _.output,
          tagList: null,
          title: _.input,
          uuid: _.messageId,
          outCollectList: true,
        }
      })
      setCollectList(chatCollectList)
      collectRef.current = chatCollectList
    }
  }

  const toCollectEditHandle = (toCollectId, type, outCollectList) => {
    if (type == ContentType.doc) {
      getDocDetailHandle(toCollectId)
      onCollectEditHandle(toCollectId, type)
      const urlQuery = removeEmpty({
        uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
        collectDocUuid: toCollectId ? toCollectId : '',
        collectFoldUuid: GetQueryValue('collectFoldUuid')
          ? GetQueryValue('collectFoldUuid')
          : '',
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        from: GetQueryValue('from') ? GetQueryValue('from') : '',
        title: GetQueryValue('title') ? GetQueryValue('title') : '',
      }) as any
      history.push({
        pathname: '/documentEdit',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
    } else if (type == ContentType.fold) {
      setOutCollectList(outCollectList)
      onCollectEditHandle(toCollectId, type)
      getDocumentListHandle(toCollectId)
      const urlQuery = removeEmpty({
        uuid: GetQueryValue('uuid') ? GetQueryValue('uuid') : '',
        filePath: GetQueryValue('filePath') ? GetQueryValue('filePath') : '',
        folderUuid: GetQueryValue('folderUuid')
          ? GetQueryValue('folderUuid')
          : '',
        from: GetQueryValue('from') ? GetQueryValue('from') : '',
        title: GetQueryValue('title') ? GetQueryValue('title') : '',
        collectFoldUuid: toCollectId ? toCollectId : '',
      }) as any
      history.push({
        pathname: '/documentEdit',
        search: '?' + new URLSearchParams(urlQuery).toString(),
      })
    } else if (type == ContentType.assistant) {
      const collectChatItem = collectList.filter(
        (_) => _.uuid == toCollectId,
      )[0]
      showModal('CollectChatModal', {
        title: collectChatItem.title,
        content: collectChatItem.introduction,
        date: collectChatItem.collectDate,
        uuid: toCollectId,
      })
    }
  }

  const getDocumentListHandle = (folderUuid) => {
    getDocumentList({
      docStatus: 0,
      orderBy: GetQueryValue('order')
        ? `${GetQueryValue('order')}`
        : 'FILE_TYPE',
      folderUuid: folderUuid ? folderUuid : '',
    }).then((res) => {
      const newCollectData = res.data.data.map((_) => {
        return {
          ..._,
          outCollectList: false,
        }
      })
      collectRef.current = newCollectData

      setCollectList(newCollectData)
    })
  }

  const getDocDetailHandle = async (uuid) => {
    const res = await getDocumentDetail({
      uuid: uuid,
    })
    if (res.data.success) {
      setDocTitle(res.data.data.title)
      setDocContent(res.data.data.content)
    }
  }

  return (
    <div className={styles[theme]}>
      {searchInput ? (
        <div className={styles.searchResult}>
          {!nonEmptyArray(searchList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="search" />
            </div>
          ) : (
            <>
              <div className={styles.count}>{searchList.length}条搜索结果</div>

              <DocTextItem
                hideCheckbox={true}
                hideEdit={true}
                // top0={true}
                id={'all'}
                dateText={'收藏时间'}
                head={true}
                theme={theme}
              />
              <div style={{ height: '74vh', overflowY: 'scroll' }}>
                {searchList.map((item, index) => {
                  return (
                    <DocTextItem
                      folderColor={item.fileCoverIcon}
                      theme={theme}
                      hideCheckbox={true}
                      id={item.uuid || item.messageId}
                      uuid={item.uuid || item.messageId}
                      title={item.title?.replace(
                        new RegExp(searchInput ? searchInput : '', 'gi'),
                        `<span style="color:#FF6500">$&</span>`,
                      )}
                      titleDefault={item.title}
                      type={item.fileType}
                      tags={[]}
                      date={item.collectDate}
                      content={item.content?.replace(
                        new RegExp(searchInput ? searchInput : '', 'gi'),
                        `<span style="color:#FF6500">$&</span>`,
                      )}
                      showContent={true}
                      key={index}
                      searchInput={searchInput}
                      pageType={PageType.collectEdit}
                      searchType={true}
                      hideEdit={true}
                      outCollectList={item.outCollectList}
                      onToCollectEdit={toCollectEditHandle}
                    />
                  )
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {collectType == CollectType.doc &&
            (GetQueryValue('collectDocUuid') ||
              GetQueryValue('collectFoldUuid')) && (
              <div className={styles.breadCollectBox}>
                <BreadCollectItem
                  outCollectList={outCollectList}></BreadCollectItem>
              </div>
            )}

          {!nonEmptyArray(collectList) ? (
            <div style={{ height: '84vh' }}>
              <EmptyView type="collect" />
            </div>
          ) : (
            <>
              <div className={styles.list}></div>
              {GetQueryValue('collectDocUuid') ? (
                <>
                  {docContent ? <h1>{docTitle}</h1> : null}
                  {docContent ? (
                    // <WritingContent
                    //   isDisableEdit={true}
                    //   docContent={docContent}
                    //   showTitle={true}
                    // />
                    <div
                      id="collectContent"
                      className={styles.collectContent}
                      dangerouslySetInnerHTML={{ __html: docContent }}></div>
                  ) : (
                    <div className={styles.data_empty_container}>
                      <EmptyView title="暂无内容" />
                    </div>
                  )}
                </>
              ) : (
                <div style={{ height: '84vh', overflowY: 'scroll' }}>
                  <div className={styles.list_wrap}>
                    <div className={styles.list}>
                      {collectList &&
                        collectList?.map((item, index) => {
                          return (
                            <div className={styles.item} key={index}>
                              {
                                <DocCardItem
                                  folderColor={item.fileCoverIcon}
                                  theme={theme}
                                  id={item.uuid}
                                  uuid={item.uuid}
                                  type={item.fileType}
                                  title={item.title}
                                  content={item.introduction}
                                  date={item.collectDate}
                                  pageType={PageType.collectEdit}
                                  collectType={1}
                                  tags={item.tagList}
                                  onToCollectEdit={toCollectEditHandle}
                                  outCollectList={item.outCollectList}
                                />
                              }
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default CollectMod
