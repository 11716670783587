import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from '../templatePage/style/tem-right-mod.module.less'
import PickList from './PickList'
import { Input, Message } from '@arco-design/web-react'
import BorderBtn from '../../components/BorderBtn'
import OutlinePickList from './OutlinePickList'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import { IconErrorTip, IconWarnTip } from '@arco-iconbox/react-aidb-v2'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { saveDocument } from '@/model/reference'
import { GetQueryValue } from '@/utils/common'

const TextArea = Input.TextArea

function WriteRightMod(props) {
  const {
    listTitle,
    curStep,
    listIntroductio,
    listOutline,
    allText,
    allLoading,
    theme,
    ismarkDownCur,
  } = props
  const [titleValue, setTitleValue] = useState(undefined) //标题输入框
  const [introValue, setIntroValue] = useState(undefined) //简介输入框
  const [outLineValue, setOutLineValue] = useState(undefined) //大纲输入框
  const markDownRef = useRef(null) // markdown的内容，作为参数传递给编辑器
  const botListRef = useRef(null) //滚动
  const titleTextarea = useRef<any>('')
  const introTextarea = useRef<any>('')
  const lineTextarea = useRef<any>('')

  //选择标题
  const [titleChecked, setTitleChecked] = React.useState(
    localStorage.getItem('selectTitle'),
  )

  //选择简介
  const [introChecked, setIntroChecked] = React.useState(
    localStorage.getItem('selectIntro'),
  )

  //选择大纲
  const [outlineChecked, setOutlineChecked] = React.useState(
    localStorage.getItem('selectOutLine'),
  )

  //设置选择的标题/简介/大纲
  useEffect(() => {
    localStorage.setItem('selectTitle', titleChecked)
    localStorage.setItem('selectIntro', introChecked)
    localStorage.setItem('selectOutLine', outlineChecked)
  }, [titleChecked, introChecked, outlineChecked])

  //确认前往下一步校验
  function clickSubmit(e) {
    const curId = e
    let valueToCheck = ''
    let warningMessage = ''

    if (curId === 1) {
      valueToCheck = localStorage.getItem('selectTitle')
      warningMessage = '请先选择标题或输入自定义标题'
    } else if (curId === 2) {
      valueToCheck = localStorage.getItem('selectIntro')
      warningMessage = '请先选择简介或输入自定义简介'
    } else if (curId === 3) {
      valueToCheck = localStorage.getItem('selectOutLine')
      warningMessage = '请先选择大纲或输入自定义大纲'
    }

    if (
      valueToCheck.length > 0 &&
      valueToCheck != 'null' &&
      valueToCheck != 'undefined'
    ) {
      events.emit(ACTIONS.TEM_SENDMSG, { curId })
    } else {
      Message.warning({
        icon: <IconWarnTip useCurrentColor={false} />,
        content: warningMessage,
      })
    }
  }

  //转为可渲染格式合并数组
  // function mergeJSONArrays(jsonList) {
  //   let mergedArray = []
  //   for (const jsonString of jsonList) {
  //     try {
  //       const jsonArray = JSON.parse(jsonString)
  //       mergedArray = mergedArray.concat(jsonArray)
  //     } catch (error) {
  //       console.error('Invalid JSON string:格式转换失败', jsonList)
  //     }
  //   }
  //   return mergedArray
  // }

  function mergeJSONArrays(jsonList) {
    console.log(jsonList)

    let mergedArray = []
    // 替换 "description" 和 "title" 前后的中文双引号为英文双引号
    const fixQuotes = (str) =>
      str
        .replace(/“description”/g, '"description"')
        .replace(/“title”/g, '"title"')

    for (const jsonString of jsonList) {
      try {
        // 替换中文双引号为英文双引号
        const fixedString = fixQuotes(jsonString)
        const jsonArray = JSON.parse(fixedString)
        mergedArray = mergedArray.concat(jsonArray)
      } catch (error) {
        console.error('Invalid JSON string: 格式转换失败', jsonString)
      }
    }
    return mergedArray
  }

  //调用处理json方法
  if (curStep === 1) {
    var mergedArray1 = mergeJSONArrays(listTitle)
  } else if (curStep === 2) {
    var mergedArray2 = mergeJSONArrays(listIntroductio)
  } else if (curStep === 3) {
    console.log(listOutline)
  }

  //跳转编辑器
  function handleToPage() {
    const content = markDownRef.current?.innerHTML // 获取 div 内容
    saveDocument({
      approvalStatus: 1,
      content: content,
      docStatus: 0,
      docType: 0,
      filePath: '/',
      fileType: 0,
      folderUuid: '',
      title: markDownRef.current?.textContent.split('\n')[0],
      uuid: '',
      docVersionType: 1,
    })
      .then((res) => {
        if (res.data.success == true && res.data.data) {
          const newUrl = window.location.origin
          window.open(
            `${newUrl}/documentEdit?uuid=${res.data.data}&title=${
              allText.split('\n')[0]
            }`,
          )
        } else {
          Message.error({
            icon: <IconErrorTip useCurrentColor={false} />,
            content: '添加失败，请重新尝试',
          })
        }
      })
      .catch(() => {
        Message.error({
          icon: <IconErrorTip useCurrentColor={false} />,
          content: '添加失败，请重新尝试',
        })
      })
  }

  //转为脑图跳转
  function handleToMindMap() {
    window.open(`https://mind-map.v-dk.com/?source=${GetQueryValue('source')}`)
  }

  //编辑回显标题内容
  function handleSetTitle(e) {
    localStorage.setItem('selectTitle', e)
    setTitleValue(e)
    setTitleChecked(e)
  }

  //编辑回显简介内容
  function handleSetIntro(e) {
    localStorage.setItem('selectIntro', e)
    setIntroValue(e)
    setIntroChecked(e)
  }

  //编辑回显大纲内容
  function handleSetOutlineValue(e) {
    localStorage.setItem('selectOutLine', e)
    setOutLineValue(e)
    setOutlineChecked(e)
  }

  //控制滚动条
  useEffect(() => {
    events.on(ACTIONS.TEM_SCROLLTOP, scrollBotList)

    return () => {
      events.off(ACTIONS.TEM_SCROLLTOP, scrollBotList)
    }
  })

  // 聊天滚到底部
  function scrollBotList(force) {
    setTimeout(() => {
      botListRef.current.scrollTop = botListRef.current.scrollHeight
    }, 0)
  }

  useEffect(() => {
    events.on(ACTIONS.TEM_OUTLINEBTN, outlineBtn)

    return () => {
      events.off(ACTIONS.TEM_OUTLINEBTN, outlineBtn)
    }
  })
  function outlineBtn(payload) {
    setLineBtn(payload)
  }
  //控制下一步的按钮状态
  const [btn1, setBtn1] = useState(false)
  const [btn2, setBtn2] = useState(false)
  const [btn3, setBtn3] = useState(false)
  const [lineBtn, setLineBtn] = useState(false)
  useEffect(() => {
    const selectTitle = localStorage.getItem('selectTitle')
    const selectIntro = localStorage.getItem('selectIntro')
    const selectOutLine = localStorage.getItem('selectOutLine')
    setBtn1(
      selectTitle !== null && selectTitle !== 'undefined' && selectTitle !== '',
    )
    setBtn2(
      selectIntro !== null && selectIntro !== 'undefined' && selectIntro !== '',
    )
    setBtn3(
      selectOutLine !== null &&
        selectOutLine !== 'undefined' &&
        selectOutLine != '' &&
        lineBtn,
    )

    // if (selectTitle == titleValue && curStep == 1) {
    //   titleTextarea.current.focus()
    // } else if (selectIntro == introValue && curStep == 2) {
    //   introTextarea.current.focus()
    // } else if (selectOutLine == outLineValue && curStep == 3) {
    //   lineTextarea.current.focus()
    // }
  })

  return (
    <div className={`${styles.writeRight} ${styles[theme]}`}>
      <div className={styles.content_wrap}>
        {curStep < 4 && (
          <div className={styles.content}>
            <div className={styles.wp}>
              <div className={styles.content_title}>
                {curStep === 1 && '选择和编辑您生成的标题'}
                {curStep === 2 && '选择和编辑您生成的简介'}
                {curStep === 3 && '选择和编辑您生成的大纲'}
              </div>
              {/*选择标题*/}
              {curStep === 1 && (
                <PickList
                  theme={theme}
                  curStep={curStep}
                  changeChecked={(checked) => {
                    setTitleChecked(checked)
                  }}
                  checked={titleChecked}
                  list={mergedArray1}
                  onEditTitle={handleSetTitle}
                />
              )}
              {/*选择简介*/}
              {curStep === 2 && (
                <PickList
                  theme={theme}
                  curStep={curStep}
                  changeChecked={(checked) => {
                    setIntroChecked(checked)
                  }}
                  checked={introChecked}
                  list={mergedArray2}
                  onEditIntro={handleSetIntro}
                />
              )}
              {/*选择大纲*/}
              {curStep === 3 && (
                <OutlinePickList
                  theme={theme}
                  changeChecked={(checked) => {
                    setOutlineChecked(checked)
                  }}
                  checked={outlineChecked}
                  list={listOutline}
                />
              )}
              <div style={{ height: curStep != 3 ? '25px' : 0 }}></div>
              <div className={styles.content_title}>
                {curStep === 1 && '在这里手动写下您的标题'}
                {curStep === 2 && '在这里手动写下您的文章简介'}
                {curStep === 3 && '在这里手动写下您的大纲'}
              </div>
              <div className={styles.input}>
                {/*输入标题*/}
                {curStep === 1 && (
                  <TextArea
                    ref={titleTextarea}
                    onFocus={() => {
                      setTitleChecked(titleValue)
                    }}
                    onBlur={(event) => {
                      setTitleChecked(titleValue)
                    }}
                    style={{ paddingTop: '8px', paddingBottom: '8px' }}
                    autoSize
                    className={'g-input'}
                    value={titleValue}
                    onChange={(e) => {
                      handleSetTitle(e)
                    }}
                  />
                )}
                {/*输入简介*/}
                {curStep === 2 && (
                  <TextArea
                    ref={introTextarea}
                    onFocus={() => {
                      setIntroChecked(introValue)
                    }}
                    onBlur={() => {
                      setIntroChecked(introValue)
                    }}
                    className={'g-input'}
                    value={introValue}
                    onChange={(e) => {
                      handleSetIntro(e)
                    }}
                  />
                )}
                {/*输入大纲*/}
                {curStep === 3 && (
                  <TextArea
                    ref={lineTextarea}
                    onFocus={() => {
                      setOutlineChecked(outLineValue)
                    }}
                    onBlur={() => {
                      setOutlineChecked(outLineValue)
                    }}
                    className={'g-input'}
                    value={outLineValue}
                    onChange={(e) => {
                      handleSetOutlineValue(e)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {curStep === 4 && (
          <div ref={botListRef} className={styles.content} id="2222">
            <div
              ref={markDownRef}
              className={`${styles['wp']} ${styles['markdown']}`}>
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /```(\w+)/.exec(allText || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        // eslint-disable-next-line react/no-children-prop
                        children={String(children).replace(/\n$/, '')}
                        style={a11yDark}
                        language={
                          <code className={className} {...props}>
                            {match[1]}
                          </code>
                        }
                        PreTag="div"
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  },
                }}>
                {/* 这里会把\n吃掉，得重复两次，原因未知 */}
                {/* {item.title.replace(/\n/g, '\n\n')} */}
                {allText}
              </ReactMarkdown>
            </div>
          </div>
        )}
      </div>
      <div className={styles.btn}>
        <div className={`${styles.wp} `}>
          {curStep === 1 && (
            <>
              <BorderBtn
                onClick={() => {
                  clickSubmit(1)
                }}
                disable={!btn1}
                gradient={true}
                size={'big'}
                text={'前往下一步，生成简介'}
              />
            </>
          )}
          {curStep === 2 && (
            <BorderBtn
              onClick={() => {
                clickSubmit(2)
              }}
              disable={!btn2}
              gradient={true}
              size={'big'}
              text={'前往下一步，生成大纲'}
            />
          )}
          {curStep === 3 && (
            <BorderBtn
              onClick={() => {
                clickSubmit(3)
              }}
              disable={!btn3}
              gradient={true}
              size={'big'}
              text={'前往下一步，生成文章'}
            />
          )}
          {curStep === 4 && !allLoading && (
            <BorderBtn
              theme={theme}
              onClick={handleToPage}
              gradient={true}
              disable={true}
              size={'big'}
              text={'添加到编辑器'}
            />
          )}
          {curStep === 4 && allLoading && (
            <BorderBtn
              theme={theme}
              onClick={handleToPage}
              gradient={true}
              size={'big'}
              text={'添加到编辑器'}
            />
          )}
          {/* {ismarkDownCur ? (
            <>
              {curStep === 4 && !allLoading && (
                <BorderBtn
                  theme={theme}
                  onClick={handleToMindMap}
                  gradient={false}
                  disable={true}
                  markdownWidth={true}
                  size={'big'}
                  text={'将文章转化为脑图'}
                />
              )}
              {curStep === 4 && allLoading && (
                <BorderBtn
                  theme={theme}
                  onClick={handleToMindMap}
                  gradient={false}
                  size={'big'}
                  text={'将文章转化为脑图'}
                  markdownWidth={true}
                />
              )}
              {curStep === 4 && !allLoading && (
                <BorderBtn
                  markdownWidth={true}
                  theme={theme}
                  onClick={handleToPage}
                  gradient={true}
                  disable={true}
                  size={'big'}
                  text={'添加到编辑器'}
                />
              )}
              {curStep === 4 && allLoading && (
                <BorderBtn
                  markdownWidth={true}
                  theme={theme}
                  onClick={handleToPage}
                  gradient={true}
                  size={'big'}
                  text={'添加到编辑器'}
                />
              )}
            </>
          ) : (
            <>
              {curStep === 4 && !allLoading && (
                <BorderBtn
                  theme={theme}
                  onClick={handleToPage}
                  gradient={true}
                  disable={true}
                  size={'big'}
                  text={'添加到编辑器'}
                />
              )}
              {curStep === 4 && allLoading && (
                <BorderBtn
                  theme={theme}
                  onClick={handleToPage}
                  gradient={true}
                  size={'big'}
                  text={'添加到编辑器'}
                />
              )}
            </>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default WriteRightMod
