import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Message,
  Alert,
} from '@arco-design/web-react'
import { FormInstance } from '@arco-design/web-react/es/Form'
import { IconLock, IconUser } from '@arco-design/web-react/icon'
import React, { useEffect, useRef, useState } from 'react'
import useStorage from '@/utils/useStorage'
import useLocale from '@/utils/useLocale'
import locale from './locale'
import styles from '@/style/index.module.less'
import style from './styles/index.module.less'
// 封装axios请求
import request from '@/utils/request'
import WxLogo from '@/assets/imgs/wxLogo.svg'
import EmailLogin from './emailLogin'
// md5加密
import md5 from 'md5'
import {
  GetUserSource,
  emailLogin,
  getCreateQrCode,
  getQueryQrCode,
  getQueryQrCodeLogin,
  getUserInfo,
  phoneLogin,
  sendSMSCode,
} from '@/model/reference'
import {
  IconErrorTip,
  IconPhone,
  IconSuccessTip,
  IconVerify,
} from '@arco-iconbox/react-aidb-v2'
import { Grid } from '@arco-design/web-react'
import { showModal } from '@/share/actions'
import AliyunPhone from '../../components/AliyunCheck/aliyunPhone'
import AliyunLogin from '../../components/AliyunCheck/aliyunLogin'
import { useHistory } from 'react-router-dom'

import { GetQueryValue } from '@/utils/common'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
const Row = Grid.Row
const Col = Grid.Col
let timeChange
let qrCodeTimer
let loginStatusTimer
export default function LoginForm(props) {
  const formRef = useRef<FormInstance>()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const phoneState = Form.useFormState('phone', form)
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams')
  const t = useLocale(locale)
  const [rememberPassword, setRememberPassword] = useState(!!loginParams)
  const userNameRef = useRef<any>(localStorage.getItem('email'))
  const [activeTab, setActiveTab] = useState(
    GetQueryValue('activeTab') ? GetQueryValue('activeTab') : 'quick',
  )
  const [btnContent, setBtnContent] = useState('获取验证码')
  const [time, setTime] = useState(60)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [wxQrCode, setWxQrCode] = useState('')
  const formPhoneRef = useRef<FormInstance>()
  const history = useHistory()
  const handleTabChange = (tab) => {
    setActiveTab(tab)
    history.push(`/login?activeTab=${tab}`)
  }

  //按钮定时器
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  //按钮定时器
  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`)
    } else {
      clearInterval(timeChange)
      setBtnDisabled(false)
      setTime(60)
      setBtnContent('获取验证码')
    }
  }, [time])

  //拉取个人信息校验权限
  useEffect(() => {
    getUserInfo({ uid: '' })
      .then(() => {
        if (localStorage.getItem('userStatus') === 'login') {
          history.push('/home')
        }
      })
      .catch(() => {
        console.log('未登录')
      })
  }, [])

  useEffect(() => {
    if (activeTab == 'wechat') {
      handleToWX1()
    }
  }, [activeTab])

  //埋点token存储本地session
  useEffect(() => {
    if (GetQueryValue('bd_vid') !== '' && GetQueryValue('bd_vid') !== null) {
      localStorage.setItem('bd_vid_reg', GetQueryValue('bd_vid'))
      localStorage.setItem('bd_vid_by', GetQueryValue('bd_vid'))
    }
    if (
      GetQueryValue('wwadsCode') !== '' &&
      GetQueryValue('wwadsCode') !== null
    ) {
      localStorage.setItem('wwadsCode_reg', GetQueryValue('wwadsCode'))
      localStorage.setItem('wwadsCode_by', GetQueryValue('wwadsCode'))
    }
    if (
      GetQueryValue('utm_source') !== '' &&
      GetQueryValue('utm_source') !== null
    ) {
      localStorage.setItem('utm_source_reg', GetQueryValue('utm_source'))
      localStorage.setItem('utm_source_by', GetQueryValue('utm_source'))
    }
  }, [])

  //推广埋点
  function pointCheck(uid) {
    // 百度
    if (
      localStorage.getItem('bd_vid_reg') !== '' &&
      localStorage.getItem('bd_vid_reg') !== null
    ) {
      request
        .post(
          'https://cors.v-dk.com/https://ocpc.baidu.com/ocpcapi/api/uploadConvertData',
          {
            token:
              'fNTSXZtws4Z5YMf1yRKPEKW7CXPeclST@CzAQEdOtUQsyzOdGDSmu3ibwOr9N9xIu',
            conversionTypes: [
              {
                logidUrl: `https://www.wuz.com.cn/?bd_vid=${localStorage.getItem(
                  'bd_vid_reg',
                )}`,
                newType: 25,
              },
            ],
          },
        )
        .then((res) => {
          // 登录成功
          if (res.data.header.desc == 'success') {
            localStorage.removeItem('bd_vid_reg')
            afterLoginSuccess('')
          }
        })
    }
    // 万维
    else if (
      localStorage.getItem('wwadsCode_reg') !== '' &&
      localStorage.getItem('wwadsCode_reg') !== null
    ) {
      request
        .post('https://cors.v-dk.com/https://portal.wuz.com.cn/wwads/report/', {
          clickCode: localStorage.getItem('wwadsCode_reg'),
          convertId: uid,
        })
        .then((res) => {
          if (res.data.message == 'Success') {
            localStorage.removeItem('wwadsCode_reg')
            afterLoginSuccess('')
          }
        })
    } else if (
      localStorage.getItem('utm_source_reg') !== '' &&
      localStorage.getItem('utm_source_reg') !== null
    ) {
      GetUserSource({
        utmSource: localStorage.getItem('utm_source_reg'), //来源
        type: 1, //注册操作,
        employeeId: uid, //用户id
      })
        .then((res) => {
          if (res.data.success) {
            localStorage.removeItem('utm_source_reg')
            afterLoginSuccess('')
          }
        })
        .catch(() => {
          afterLoginSuccess('')
        })
    } else {
      // 登录成功
      afterLoginSuccess('')
    }
  }

  function afterLoginSuccess(params) {
    // 记录登录状态
    localStorage.setItem('userStatus', 'login')
    localStorage.setItem('tabSwitch', 'card')
    localStorage.setItem('docTypeKey', '我的文档')
    // 跳转首页
    if (window.screen.width < 450) {
      window.location.href = '/toolbox/index'
    } else {
      if (localStorage.getItem('redirectUrl')?.length > 0) {
        props.getTemJson()
      } else {
        // history.push('/home')
        location.href = '/home'
      }
    }
  }

  //跳转忘记密码
  function goForgot() {
    history.push('/forgotpwd')
  }

  // 跳转微信登录  校验通过后再跳转
  function handleToWX(type) {
    if (type == 'phone') {
      formPhoneRef.current
        .validate(['check1'])
        .then((res) => {
          handleTabChange('wechat')
        })
        .catch((error) => {
          console.log(error)
          if (
            form.getFieldValue('check1') === false ||
            form.getFieldValue('check1') == null
          ) {
            showModal('ConfirmModal', {
              title: '请阅读并同意我们的条款',
              content: (
                <div>
                  您还未点击“阅读并同意
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/10fa">
                    《服务协议》
                  </Link>
                  和
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/99e3">
                    《隐私政策》
                  </Link>
                  ”。为了继续，请先阅读并同意我们的条款。
                </div>
              ),
              attribute: 'login',
              onConfirm: async () => {
                form.setFieldsValue({
                  check1: true,
                })
                handleToWX('phone')
              },
              onCancel: async () => {
                console.log(1)
              },
            })
          }
        })
    } else {
      handleTabChange('wechat')
    }
  }

  function handleToWX1() {
    var randomNumber = Math.floor(Math.random() * 10001)
    localStorage.setItem('randomNumber', randomNumber as any)
    // 获取二维码
    getCreateQrCode({ sceneId: randomNumber }).then((res) => {
      setWxQrCode(res.data.data)
      // handleTabChange('wechat')
      // 启动定时器
      qrCodeTimer = setInterval(() => {
        getQueryQrCode({
          sceneId: Number(localStorage.getItem('randomNumber')),
        }).then((res) => {
          if (res.data.data === true) {
            console.log('生效中')
          } else {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '二维码过期，请重新扫码',
            })
            clearInterval(qrCodeTimer) // 停止定时器
            clearInterval(loginStatusTimer) // 停止登录状态定时器
            handleToWX('')
          }
        })
      }, 30000) // 30秒
      loginStatusTimer = setInterval(() => {
        getQueryQrCodeLogin({
          sceneId: Number(localStorage.getItem('randomNumber')),
        }).then((res) => {
          //判断是否登陆成功
          if (res.data.data.isFirstLogin) {
            clearInterval(loginStatusTimer)
            showModal('BindPhoneModal', {
              title: '手机号',
            })
          } else if (res.data.data.isLoginSuccess) {
            request
              .post('/api/api-permission/employee/getUserInfo', { uid: '' })
              .then((res) => {
                localStorage.setItem('positionId', res.data.data.positionId)
                localStorage.setItem('userName', res.data.data.name)
                localStorage.setItem('vipEndDate', res.data.data.vipEndDate)
                localStorage.setItem('vipDay', res.data.data.vipDay)
                localStorage.setItem('vipLevel', res.data.data.vipLevel)
                localStorage.setItem('vipToSvipDay', res.data.data.vipToSvipDay)
                localStorage.setItem('userId', res.data.data.id)

                const vipEndDate = new Date(res.data.data.vipEndDate)
                const currentDate = new Date()
                if (currentDate > vipEndDate) {
                  localStorage.setItem('isVip', 'false')
                } else {
                  localStorage.setItem('isVip', 'true')
                }
                afterLoginSuccess('')
              })
          }
        })
      }, 3000) // 3秒
    })
  }
  // 其他方式登录
  function handleToOther() {
    clearInterval(qrCodeTimer)
    clearInterval(loginStatusTimer)
    handleTabChange('quick')
  }

  //登录
  function login(params) {
    setErrorMessage('')
    setLoading(true)
    if (params.emailCode) {
      //数据埋点
      phoneLogin({
        code: params.emailCode,
        phone: params.phone,
      })
        .then((response) => {
          const { success } = response.data
          if (success === true) {
            request
              .post('/api/api-permission/employee/getUserInfo', { uid: '' })
              .then((res) => {
                localStorage.setItem('positionId', res.data.data.positionId)
                localStorage.setItem('userName', res.data.data.name)
                localStorage.setItem('vipEndDate', res.data.data.vipEndDate)
                localStorage.setItem('vipDay', res.data.data.vipDay)
                localStorage.setItem('vipLevel', res.data.data.vipLevel)
                localStorage.setItem('vipToSvipDay', res.data.data.vipToSvipDay)
                localStorage.setItem('userId', res.data.data.id)
                const vipEndDate = new Date(res.data.data.vipEndDate)
                const currentDate = new Date()
                if (currentDate > vipEndDate) {
                  localStorage.setItem('isVip', 'false')
                } else {
                  localStorage.setItem('isVip', 'true')
                }
                const uid = res.data.data.id
                if (response.data.data.isFirstLogin == true) {
                  pointCheck(uid)
                } else {
                  afterLoginSuccess('')
                }
              })
          }
        })
        .catch((error) => {
          // 服务器或网络错误处理
          if (error.response) {
            setLoading(false)
            if (error.response.data.status === 500) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content:
                  error.response.data.status +
                  ' - ' +
                  error.response.data.message,
              })
              if (
                error.response.data.message === '该账号未注册，请先进行注册'
              ) {
                localStorage.setItem('email', params.phone.trim())
              }
            }
          }
        })
    } else {
      // md5加密并且转为大写
      const passwordMd5 = md5(
        params.password.trim() + 'avc154*gtd45rge3',
      ).toLocaleUpperCase()
      emailLogin({
        uid: params.userName.trim(),
        password: passwordMd5,
      })
        .then((res) => {
          const { success } = res.data
          if (success === true) {
            request
              .post('/api/api-permission/employee/getUserInfo', { uid: '' })
              .then((res) => {
                localStorage.setItem('positionId', res.data.data.positionId)
                localStorage.setItem('userName', res.data.data.name)
                localStorage.setItem('vipEndDate', res.data.data.vipEndDate)
                localStorage.setItem('vipDay', res.data.data.vipDay)
                localStorage.setItem('vipLevel', res.data.data.vipLevel)
                localStorage.setItem('vipToSvipDay', res.data.data.vipToSvipDay)
                localStorage.setItem('userId', res.data.data.id)
                const vipEndDate = new Date(res.data.data.vipEndDate)
                const currentDate = new Date()
                if (currentDate > vipEndDate) {
                  localStorage.setItem('isVip', 'false')
                } else {
                  localStorage.setItem('isVip', 'true')
                }
                // 登录成功
                afterLoginSuccess(params)
                if (window.screen.width < 450) {
                  window.location.href = '/toolbox/index'
                }
              })
              .finally(() => {
                // setLoading(false)
              })
          }
        })
        .catch((error) => {
          // 服务器或网络错误处理
          if (error.response) {
            setLoading(false)
            if (error.response.data.status === 500) {
              Message.error({
                icon: <IconErrorTip useCurrentColor={false} />,
                content:
                  error.response.data.status +
                  ' - ' +
                  error.response.data.message,
              })
              if (
                error.response.data.message === '该账号未注册，请先进行注册'
              ) {
                localStorage.setItem('email', params.userName.trim())
              }
            }
          }
        })
    }
  }

  //确定按钮调用login
  function onSubmitClick(type) {
    if (type == 'phone') {
      formPhoneRef.current
        .validate()
        .then((values) => {
          login(values)
          console.log(1)
        })
        .catch((error) => {
          console.log(error)
          console.log()
          if (
            (form.getFieldValue('check1') === false ||
              form.getFieldValue('check1') == null) && // 检查 false, undefined, null
            form.getFieldValue('phone') != null &&
            form.getFieldValue('phone') !== '' &&
            /^[1][3-9][0-9]{9}$/.test(form.getFieldValue('phone')) && // 验证手机号格式
            form.getFieldValue('emailCode') != null &&
            form.getFieldValue('emailCode') !== '' // 检查 undefined, null, 空字符串
          ) {
            showModal('ConfirmModal', {
              title: '请阅读并同意我们的条款',
              content: (
                <div>
                  您还未点击“阅读并同意
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/10fa">
                    《服务协议》
                  </Link>
                  和
                  <Link
                    target="_blank"
                    hoverable={false}
                    href="https://go.wuz.com.cn/99e3">
                    《隐私政策》
                  </Link>
                  ”。为了继续，请先阅读并同意我们的条款。
                </div>
              ),
              attribute: 'login',
              onConfirm: async () => {
                form.setFieldsValue({
                  check1: true,
                })
                onSubmitClick('phone')
              },
              onCancel: async () => {
                console.log(1)
              },
            })
          }
        })
    } else {
      formRef.current.validate().then((values) => {
        login(values)
        // setIsLoggedIn(true)
      })
    }
  }

  //跳转注册页面
  function onRegister() {
    history.push('/register')
  }

  //校验手机号
  function isPhoneNumber(input) {
    // 使用正则表达式匹配手机号格式
    const pattern = /^1[3456789]\d{9}$/
    return pattern.test(input)
  }

  //默认路由手机号注册
  useEffect(() => {
    if (GetQueryValue('phone')) {
      formPhoneRef.current.setFieldValue('phone', GetQueryValue('phone'))
      getPhoneCaptcha()
    }
  }, [])

  //发送验证码
  function getPhoneCaptcha() {
    if (isPhoneNumber(formPhoneRef.current.getFieldsValue().phone)) {
      setBtnDisabled(true)
      setBtnContent('发送中...')
      sendSMSCode({
        areaCode: '86',
        type: 2,
        phone: formPhoneRef.current.getFieldsValue().phone.trim(),
      })
        .then((res) => {
          const { success } = res.data
          if (success === true) {
            timeChange = setInterval(() => setTime((t) => --t), 1000)
            setBtnDisabled(true)
            Message.success({
              icon: <IconSuccessTip useCurrentColor={false} />,
              content: '验证码发送成功',
            })
          } else {
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: '验证码发送失败',
            })
          }
        })
        .catch((error) => {
          setBtnContent('获取验证码')
          setBtnDisabled(false)
          if (error.response) {
            let errorMsg = ''
            if (error.response.data.status === 400) {
              errorMsg = error.response.data.errors
                .map((err) => err.defaultMessage)
                .join(' ')
            } else if (error.response.data.status === 500) {
              errorMsg = error.response.data.message
            }
            Message.error({
              icon: <IconErrorTip useCurrentColor={false} />,
              content: `${error.response.data.status} - ${errorMsg}`,
            })
          }
        })
    } else {
      Message.error({
        icon: <IconErrorTip useCurrentColor={false} />,
        content: '请输入正确的手机号',
      })
    }
  }

  // 读取 localStorage，设置初始值
  useEffect(() => {
    const rememberPassword = !!loginParams
    setRememberPassword(rememberPassword)
    if (formPhoneRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams)
      formPhoneRef.current.setFieldsValue(parseParams)
    }
  }, [loginParams])

  //阿里云校验通过回调
  const handleBizResult = (bizResult) => {
    if (bizResult === true) {
      timeChange = setInterval(() => setTime((t) => --t), 1000)
      setBtnDisabled(true)
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '验证码发送成功',
      })
    }
  }

  // 自定义手机号校验规则
  const validatePhone = (_, value) => {
    const pattern = /^1[0-9]{10}$/
    if (!value || pattern.test(value)) {
      return ''
    }
    return '请输入正确的手机号格式'
  }

  return (
    <div
      className={`${styles['login-form-wrapper']} ${
        activeTab === 'wechat' ? styles['wechat'] : ''
      }`}>
      {activeTab === 'wechat' && (
        <>
          <div className={style.wxTitle}>微信登录</div>
          <div className={style.wxQrBox}>
            <div className={style.wxBox}>
              <img src={wxQrCode} alt="" />
            </div>
          </div>
          <div className={style.wxTxt}>
            请使用微信扫一扫
            <br />
            关注公众号后一键登录
          </div>
          <div className={style.wxOhter} onClick={handleToOther}>
            其他方式登录 {'>'}{' '}
          </div>
        </>
      )}
      {activeTab !== 'wechat' && (
        <>
          {window.screen.width < 450 && window.innerHeight >= 500 && (
            <div className={style.msgAlert}>
              <Alert content="为了解锁全部智能写作能力，我们推荐您使用 PC 端。" />
            </div>
          )}
          {window.innerHeight >= 500 && (
            <div className={styles['login-form-title']}>
              {t['login.form.title']}
            </div>
          )}
          <div className={styles['login-form-error-msg']}>{errorMessage}</div>
          <div className={style['tab-header']}>
            <div
              className={`${style.tab} ${
                activeTab === 'quick' ? style.active : ''
              }`}
              onClick={() => handleTabChange('quick')}>
              手机快捷登录
            </div>
            <div
              className={`${style.tab} ${
                activeTab === 'account' ? style.active : ''
              }`}
              onClick={() => handleTabChange('account')}>
              账号密码登录
            </div>
          </div>
        </>
      )}
      {activeTab === 'quick' && (
        <Form
          className={styles['login-form']}
          layout="vertical"
          form={form}
          ref={formPhoneRef}>
          <div className={styles.input}>
            <Form.Item
              field="phone"
              // triggerPropName="change"
              rules={[
                {
                  required: true,
                  message: '手机号不能为空',
                },
                {
                  validator: (v, cb) => {
                    if (v.length >= 11) {
                      const pattern = /^1[3456789]\d{9}$/
                      if (!pattern.test(v)) {
                        return cb('请输入正确的手机号')
                      }
                    }
                  },
                },
              ]}>
              <Input
                prefix={
                  <IconPhone
                    fill="var(--color-text-2)"
                    style={{ fontSize: '18px' }}
                  />
                }
                onPressEnter={() => {
                  onSubmitClick('phone')
                }}
                placeholder="请输入手机号，未注册将自动为您注册"
                // addBefore="+86"
              />
            </Form.Item>
            <Row className="grid-demo">
              <Col flex="auto">
                <Form.Item
                  field="emailCode"
                  rules={[{ required: true, message: '验证码不能为空' }]}
                  className={styles.email}>
                  <Input
                    onPressEnter={() => {
                      onSubmitClick('phone')
                    }}
                    prefix={
                      <IconVerify
                        fill="var(--color-text-2)"
                        style={{ fontSize: '18px' }}
                      />
                    }
                    placeholder="请输入验证码"
                    style={{ height: '50px', borderRadius: '4px' }}
                  />
                </Form.Item>
              </Col>
              <Col flex="0px">
                <Button
                  id="aliyunButton"
                  className={styles.emailCode2}
                  // onClick={getPhoneCaptcha}
                  disabled={
                    btnDisabled ||
                    phoneState?.validateStatus !== 'success' ||
                    formPhoneRef?.current?.getFieldsValue()?.phone?.length !==
                      11
                  }>
                  {btnContent}
                </Button>
              </Col>
            </Row>
          </div>
          <Space size={16} direction="vertical">
            <div className={styles.btn}>
              <Button
                type="primary"
                long
                onClick={() => {
                  onSubmitClick('phone')
                }}
                loading={loading}>
                登录
              </Button>
            </div>
            <div className={styles['login-form-password-actions']}>
              <div className={style.registerGo}>
                <span className={style.text1}>还没有账号?</span>
                <span className={style.text2} onClick={onRegister}>
                  立即注册
                </span>
              </div>
              <div className={styles.forgotbtn}>
                <Link onClick={goForgot}>{t['login.form.forgetPassword']}</Link>
              </div>
            </div>

            <div className={styles.btn} style={{ marginTop: '-10px' }}>
              <div className={style.registerBtn1}>
                <Form.Item
                  field="check1"
                  triggerPropName="checked"
                  rules={[
                    {
                      validator: (v, cb) => {
                        if (v !== true) {
                          return cb('请勾选并同意本协议')
                        }
                      },
                    },
                  ]}>
                  <Checkbox>
                    阅读并同意{' '}
                    <Link
                      target="_blank"
                      hoverable={false}
                      href="https://go.wuz.com.cn/10fa">
                      《服务协议》
                    </Link>
                    和
                    <Link
                      target="_blank"
                      hoverable={false}
                      href="https://go.wuz.com.cn/99e3">
                      《隐私政策》
                    </Link>
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            {window.screen.width > 450 ? (
              <>
                <div className={style.other}>其他</div>
                <div>
                  <div
                    className={style.item}
                    onClick={() => {
                      handleToWX('phone')
                    }}>
                    <WxLogo></WxLogo>
                    微信登录
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </Space>
        </Form>
      )}
      {activeTab === 'account' && (
        <EmailLogin
          getTemJson={props.getTemJson}
          handleTabChange={handleTabChange}></EmailLogin>

        // <Form className={styles['login-form']} layout="vertical" ref={formRef}>
        //   <div className={styles.input}>
        //     <Form.Item
        //       field="userName"
        //       rules={[{ required: true, message: '邮箱/手机/用户名不能为空' }]}>
        //       <Input
        //         prefix={<IconUser />}
        //         onPressEnter={() => {
        //           onSubmitClick('email')
        //         }}
        //         ref={userNameRef}
        //         placeholder="请输入邮箱/手机/用户名"
        //       />
        //     </Form.Item>
        //     <Form.Item
        //       field="password"
        //       rules={[
        //         { required: true, message: t['login.form.password.errMsg'] },
        //       ]}>
        //       <Input.Password
        //         prefix={<IconLock />}
        //         onPressEnter={() => {
        //           onSubmitClick('email')
        //         }}
        //         placeholder="请输入密码"
        //       />
        //     </Form.Item>
        //   </div>

        //   <Space size={16} direction="vertical">
        //     <div className={styles.btn}>
        //       <Button
        //         type="primary"
        //         long
        //         onClick={() => {
        //           onSubmitClick('email')
        //         }}
        //         // id="aliyunLogin"
        //         loading={loading}>
        //         登录
        //       </Button>
        //     </div>
        //     <div className={styles['login-form-password-actions']}>
        //       <div className={style.registerGo}>
        //         <span className={style.text1}>还没有账号?</span>
        //         <span className={style.text2} onClick={onRegister}>
        //           立即注册
        //         </span>
        //       </div>
        //       <div className={styles.forgotbtn}>
        //         <Link onClick={goForgot}>{t['login.form.forgetPassword']}</Link>
        //       </div>
        //     </div>

        //     <div className={styles.btn} style={{ marginTop: '-10px' }}>
        //       <div className={style.registerBtn1}>
        //         <Form.Item
        //           field="check"
        //           triggerPropName="checked"
        //           rules={[
        //             {
        //               validator: (v, cb) => {
        //                 if (v !== true) {
        //                   return cb('请勾选并同意本协议')
        //                 }
        //               },
        //             },
        //           ]}>
        //           <Checkbox>
        //             阅读并同意{' '}
        //             <Link
        //               target="_blank"
        //               hoverable={false}
        //               href="https://go.wuz.com.cn/10fa">
        //               《服务协议》
        //             </Link>
        //             和
        //             <Link
        //               target="_blank"
        //               hoverable={false}
        //               href="https://go.wuz.com.cn/99e3">
        //               《隐私政策》
        //             </Link>
        //           </Checkbox>
        //         </Form.Item>
        //       </div>
        //     </div>
        //     <div className={style.other}>其他</div>
        //     <div>
        //       <div
        //         className={style.item}
        //         onClick={() => {
        //           handleToWX('email')
        //         }}>
        //         <WxLogo></WxLogo>
        //         微信登录
        //       </div>
        //     </div>
        //   </Space>
        // </Form>
      )}
      {activeTab == 'quick' ? (
        <AliyunPhone
          onSuccessCallback={handleBizResult}
          phoneType={2}
          usernamePhone={
            formPhoneRef?.current?.getFieldsValue().phone
          }></AliyunPhone>
      ) : (
        ''
      )}
    </div>
  )
}
