import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import {
  deleteComment,
  getCommentListByParagraph,
  saveComment,
} from '@/model/documentEdit'
import { GetQueryValue } from '@/utils/common'
import { Dropdown, Input, Menu, Message, Tag } from '@arco-design/web-react'
import {
  IconArrowLeft,
  IconArrowRight,
  IconMoreEdits,
  IconSuccessTip,
  IconWastePaper,
} from '@arco-iconbox/react-aidb-v2'
import { events } from '@/helpers/event-emitter'
import { ACTIONS } from '@/share/constants'
import palette from '@/helpers/palette'
import { switchProject } from '@/helpers/switchProject'
import { COLLABORATION_PERMISSION } from '@/config/editor-collaboration-config'
const TextArea = Input.TextArea
interface Props {
  permission: number
  paragraphSign: string
  isFirst: boolean
  commentClassList: string[]
  domId: string
  onFirstSubmit: (isFirst: boolean) => void
}

const CommentMod = (props: Props) => {
  const {
    permission,
    paragraphSign,
    isFirst,
    onFirstSubmit,
    commentClassList,
    domId,
  } = props
  const [firstComment, setFirstComment] = useState(isFirst)
  const [commentList, setCommentList] = useState([])
  const [inputVal, setInputVal] = useState('')
  const uuid = GetQueryValue('uuid')
  const [currentClassIndex, setCurrentClassIndex] = useState(0)
  const [referenceLabels, setReferenceLabels] = useState('')
  const [commentUuid, setCommentUuid] = useState('')
  useEffect(() => {
    setInputVal('')
    setCommentList([])
    if (!isFirst) {
      getCommentList(0)
    }

    let textarea = document.querySelector(`.${domId}`) as HTMLElement
    textarea && textarea.focus()
  }, [paragraphSign])

  const getCommentList = async (newIndex) => {
    const res = await getCommentListByParagraph({
      className: commentClassList[newIndex],
      uuid: uuid,
    })

    if (!res.data.success) {
      setCommentList([])
    } else {
      if (res.data.data.length > 0) {
        events.emit(ACTIONS.COMMENT_BUBBLE_HIGH_LIGHT, {
          id: commentClassList[newIndex],
        })
        const newData = res.data.data.map((i) => {
          return {
            uuid: i.uuid,
            createDate: i.createDate,
            className: i.className,
            content: i.content,
            name: i.name,
            paragraphSign: i.paragraphSign,
            isParagraph: i.isParagraph,
            parentContent: i.parentContent,
          }
        })
        setCommentList(newData)
      }
    }
  }

  const inputChange = (e) => {
    setInputVal(e)
  }

  const submitHandle = async () => {
    if (inputVal.trim().length == 0) return
    const res = await saveComment({
      className:
        commentClassList.length > 0
          ? commentClassList[currentClassIndex]
          : paragraphSign,
      content: inputVal,
      uuid: uuid,
      isParagraph: '1',
      commentUuid: commentUuid,
    })
    if (res.data.success) {
      if (isFirst) {
        onFirstSubmit(firstComment)
        setFirstComment(!firstComment)
      }

      const {
        uuid,
        createDate,
        className,
        content,
        name,
        paragraphSign,
        isParagraph,
        parentContent,
      } = res.data.data
      setCommentList((commentList) => {
        return [
          ...commentList,
          {
            uuid: uuid,
            createDate: createDate,
            className: className,
            content: content,
            name: name,
            paragraphSign: paragraphSign,
            isParagraph: isParagraph,
            parentContent: referenceLabels,
          },
        ]
      })
      setReferenceLabels('')
      setInputVal('')
    }
  }

  const cancelPostCommentHandle = () => {
    events.emit(ACTIONS.REMOVE_COMMENT_BUBBLE, {
      id: commentClassList[currentClassIndex],
      className: commentClassList[currentClassIndex],
    })
  }

  const updateIndex = (newIndex) => {
    setCurrentClassIndex(newIndex)
    getCommentList(newIndex)
  }

  const preClassHandle = () => {
    if (currentClassIndex > 0) {
      updateIndex(currentClassIndex - 1)
    }
  }

  const nextClassHandle = () => {
    if (commentClassList && currentClassIndex < commentClassList.length - 1) {
      updateIndex(currentClassIndex + 1)
    }
  }

  const removeCommentHandle = async (id) => {
    const res = await deleteComment({
      uuid: id,
    })
    if (res.data.success) {
      Message.success({
        icon: <IconSuccessTip useCurrentColor={false} />,
        content: '删除批注成功',
      })
      setCommentList((commentList) => {
        return commentList.filter((item) => item.uuid !== id)
      })
      if (commentList.length == 1) {
        events.emit(ACTIONS.REMOVE_COMMENT_BUBBLE, {
          id: commentClassList[currentClassIndex],
          className: commentClassList[currentClassIndex],
        })
      }
    }
  }

  const replyCommentHandle = (id, content) => {
    setReferenceLabels(content)
    setCommentUuid(id)
  }

  return (
    <div className={style.commentWrapper}>
      {(commentClassList.length > 1 || commentList.length > 0) && (
        <div className={style.topMod}>
          <div className={style.topLeftMod}>
            {commentClassList.length > 1 && (
              <div style={{ display: 'flex' }}>
                <span onClick={preClassHandle}>
                  <IconArrowLeft />
                </span>
                <span style={{ margin: '0 10px' }}>
                  {currentClassIndex + 1}/{commentClassList.length}
                </span>
                <span onClick={nextClassHandle}>
                  <IconArrowRight />
                </span>
              </div>
            )}
          </div>
          {commentList.length > 0 &&
            permission !== COLLABORATION_PERMISSION.read_permission && (
              <div
                className={style.topRightMod}
                onClick={cancelPostCommentHandle}>
                <IconWastePaper /> <span>删除</span>
              </div>
            )}
        </div>
      )}

      {commentList.length > 0 && (
        <div className={style.contentMod}>
          {commentList.map(
            ({
              uuid,
              createDate,
              className,
              content,
              name,
              paragraphSign,
              isParagraph,
              parentContent,
            }) => {
              return (
                <CommentItem
                  permission={permission}
                  key={paragraphSign + uuid}
                  uuid={uuid}
                  createDate={createDate}
                  className={className}
                  content={content}
                  name={name}
                  parentContent={parentContent}
                  paragraphSign={paragraphSign}
                  onRemoveComment={removeCommentHandle}
                  onReplayComment={replyCommentHandle}
                  isParagraph={isParagraph}></CommentItem>
              )
            },
          )}
        </div>
      )}

      <div className={style.bottomMod}>
        <div>
          <TextArea
            disabled={permission === COLLABORATION_PERMISSION.read_permission}
            placeholder="添加批注"
            className={domId}
            autoSize={{ minRows: 1, maxRows: 6 }}
            style={{ width: 200 }}
            value={inputVal}
            onChange={inputChange}
            onPressEnter={submitHandle}
          />
          {referenceLabels && (
            <div className={style.referenceLabels}>
              <Tag
                size="medium"
                closable
                onClose={() => setReferenceLabels('')}>
                {referenceLabels}
              </Tag>
            </div>
          )}
        </div>
        <div
          className={style.submitBtn}
          style={{
            background:
              inputVal.length == 0
                ? '#DEDEDE'
                : !switchProject('DFZ')
                ? palette.primary
                : '#9e2d26',
            cursor: inputVal.length == 0 ? 'not-allowed' : 'pointer',
          }}
          onClick={submitHandle}>
          提交
        </div>
      </div>
    </div>
  )
}

interface CommentItemProps {
  permission: number
  uuid: string
  createDate: string
  className: string
  content: string
  name: string
  paragraphSign: string
  isParagraph: string
  parentContent: string
  onRemoveComment: (id: string) => void
  onReplayComment: (id: string, content: string) => void
}

const CommentItem = (props: CommentItemProps) => {
  const {
    permission,
    uuid,
    createDate,
    className,
    content,
    name,
    paragraphSign,
    isParagraph,
    parentContent,
    onRemoveComment,
    onReplayComment,
  } = props

  const dropList = (
    <Menu className={'custom-dropdown'}>
      <div className={'dropdown-list'}>
        <Menu.Item
          disabled={permission === COLLABORATION_PERMISSION.read_permission}
          key="1"
          onClick={() => onReplayComment(uuid, content)}>
          回复批注
        </Menu.Item>
        <Menu.Item
          disabled={permission === COLLABORATION_PERMISSION.read_permission}
          key="2"
          onClick={() => onRemoveComment(uuid)}>
          删除批注
        </Menu.Item>
      </div>
    </Menu>
  )

  return (
    <div className={style.commentItem}>
      <div className={style.right}>
        <p>
          <span className={style.name}>
            {name.length > 12 ? name.substring(0, 12) + '...' : name}
          </span>
          <span className={style.createDate}>{createDate}</span>
        </p>
        {parentContent && <Tag size="medium">{parentContent}</Tag>}
        <div className={style.contentBox}>
          <div
            className={style.avatar}
            style={{
              background: !switchProject('DFZ')
                ? palette.primary
                : palette.primaryDFZ,
            }}>
            {name.substring(0, 1).toLocaleUpperCase()}
          </div>
          <div className={style.content}>{content}</div>
        </div>
      </div>

      <div className={style.left}>
        <Dropdown
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentNode as HTMLElement
          }
          droplist={dropList}
          position="br"
          // popupVisible={true}
          trigger={'click'}>
          <div className={`${style.more}`}>
            <IconMoreEdits />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default CommentMod
